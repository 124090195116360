import { ChatTeardropDots } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import MultiStep from "components/layouts/MultiStep";
import { useNavigate } from "react-router-dom";
import CapitalAccountIcon from "resources/capital-accounts/components/CapitalAccountIcon";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";

import LineOfferDetailsSection from "../components/LineOfferDetailsSection";
import { useReviewLineOfferContext } from "../context/ReviewLineOfferProvider";

import styles from "./ReviewLineOfferViews.module.scss";

const HOW_IT_WORKS_FORM_ID = "how-it-works-form";

const HowItWorksViewContent = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const chat = useChatWidget();

  return (
    <>
      <Divider />
      <div className={styles.subtext}>
        <div className={styles.subtextItem}>
          <CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} />
          <Text size={16} color={colors.grey[900]} weight="medium">
            {capitalAccountSummary.name}
          </Text>
        </div>
        <div className={styles.subtextItem}>
          <Text size={14} color={colors.grey[600]}>
            Limit:
          </Text>
          <MoneyAmount weight="medium" size={14} cents={capitalAccountSummary.details.limit} />
        </div>
      </div>
      <Divider />

      <LineOfferDetailsSection capitalAccount={capitalAccountSummary} />

      <Divider />
      <div className={styles.supportContainer}>
        <Text size={14} color={colors.grey[600]}>
          Have questions?
        </Text>
        <div className={styles.support} onClick={() => chat.show()}>
          <ChatTeardropDots size={14} color={colors.purple[500]} />
          <Text size={12} color={colors.purple[500]}>
            Contact support
          </Text>
        </div>
      </div>
    </>
  );
};

const HowItWorksView: React.FC = () => {
  const navigate = useNavigate();
  const { nextPathname } = useReviewLineOfferContext();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  return (
    <MultiStep.Form id={HOW_IT_WORKS_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>How this line works</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>
      </MultiStep.Section>

      <MultiStep.Section>
        <HowItWorksViewContent />
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.NextButton form={HOW_IT_WORKS_FORM_ID}>
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default HowItWorksView;
