import {
  AchCounterparty,
  CreateCounterpartyRequest,
  Unit,
  UnitResponse,
} from "@highbeam/unit-node-sdk";
import { CreateRecurringPaymentRequest } from "@highbeam/unit-node-sdk/dist/types/recurringPayment";
import { ArrowLineUpRight } from "@phosphor-icons/react";
import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import RecurringPaymentConfirmDetails from "components/RecurringPaymentConfirmDetails";
import env from "env";
import {
  getInternationalWireCountry,
  getInternationalWirePrefillInfo,
} from "pages/SendMoneyPage/internationalWires";
import SendMoneyConfirmDetails from "pages/SendMoneyPage/SendMoneyConfirmDetails";
import PaymentInfo from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo";
import TransferScheduled from "pages/SendMoneyPage/SendMoneySteps/TransferScheduled";
import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import InternationalWirePaymentRep from "reps/InternationalWirePaymentRep";
import InternationalWireQuoteRep from "reps/InternationalWireQuote";
import { BANK_ACCOUNTS_QUERY_KEY } from "resources/bank-accounts/queries/useBankAccountsQueryOptions";
import useInternationalAccountQuery from "resources/bank-accounts/queries/useInternationalAccountQuery";
import useOpenBankAccounts from "resources/bank-accounts/queries/useOpenBankAccounts";
import mapTransferOption from "resources/bank-accounts/utils/mapTransferOption";
import useBusiness from "resources/business/queries/useBusiness";
import useUpdatePayeeMutation from "resources/payees/mutations/useUpdatePayeeMutation";
import { useActivePayeesQuery } from "resources/payees/queries/usePayees";
import useGetUnitCoSensitiveTokenWithMfa from "resources/unit-co-customer-token/hooks/useGetUnitCoSensitiveTokenWithMfa";
import { DEFAULT_UNIT_CONFIG } from "resources/unit-co-customer-token/queries/useUnitApi";
import { internationalAddressState } from "state/payments/international/address";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import { internationalEntityState } from "state/payments/international/entity";
import { invoiceDateState } from "state/payments/international/invoiceDate";
import { invoiceNumberState } from "state/payments/international/invoiceNumber";
import { isLocalPaymentState } from "state/payments/international/isLocalPayment";
import { purposeCodeState } from "state/payments/international/purposeCode";
import { quoteCalculatedLocalAmountInCentsState } from "state/payments/international/quoteCalculatedLocalAmountInCents";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import { quoteFixedSideState } from "state/payments/international/quoteFixedSideState";
import { quoteIdempotencyKeyState } from "state/payments/international/quoteIdempotencyKey";
import quotesState from "state/payments/international/quotes";
import { quoteUsdAmountInCentsState } from "state/payments/international/quoteUsdAmountInCents";
import requiredFieldsState from "state/payments/international/requiredFields";
import { selectedBankCountryOptionState } from "state/payments/international/selectedBankCountry";
import paymentAmountState from "state/payments/paymentAmount";
import paymentIdempotencyKeyState from "state/payments/paymentIdempotencyKey";
import { notify } from "ui/feedback/Toast";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Steps, { StepType } from "ui/navigation/Steps";
import {
  Address,
  INITIAL_ADDRESS,
  INITIAL_US_ADDRESS,
  states,
  US_COUNTRY_OPTION,
} from "utils/address";
import { ApiError } from "utils/ajax";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMountEffect from "utils/customHooks/useMountEffect";
import usePrevious from "utils/customHooks/usePrevious";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import { startOfBankingDay } from "utils/date";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import useRefreshQuery from "utils/react-query/useRefreshQuery";
import {
  HighbeamBankAccountTransferOption,
  isHighbeamBankAccount,
  isHighbeamBankAccountTransferOption,
  TransferOption,
} from "utils/transfers";
import { v4 as uuidv4 } from "uuid";

import useCreatePaymentMutation from "./hooks/useCreatePaymentMutation";
import useHandlePaymentError from "./hooks/useHandlePaymentError";
import styles from "./SendMoney.module.scss";
import PayeeSchedule from "./SendMoneySteps/PayeeSchedule";
import RecurringPaymentScheduled from "./SendMoneySteps/RecurringPaymentScheduled";
import currentStepState, { Step } from "./state/currentStepState";
import isTransferErrorState from "./state/isTransferErrorState";
import selectedPaymentMethodOptionState from "./state/selectedPaymentMethodOptionState";
import {
  BankingInfo,
  PaymentMethod,
  PaymentMethodOption,
  PayeeOption,
  createAchPaymentRequest,
  createWirePaymentRequest,
  createCounterpartyRequest,
  createRecurringAchPaymentRequest,
  payeeToPayeeOption,
  createInternationalPaymentRequest,
  ACH_PAYMENT_OPTION,
  WIRE_PAYMENT_OPTION,
  DISABLED_WIRE_RECURRING_PAYMENT_OPTION,
  DISABLED_WIRE_SCHEDULED_PAYMENT_OPTION,
  INTERNATIONAL_PAYMENT_OPTION,
  DISABLED_INTERNATIONAL_RECURRING_PAYMENT_OPTION,
  DISABLED_INTERNATIONAL_SCHEDULED_PAYMENT_OPTION,
  initialEntityFromPayee,
  InternationalField,
  getAchTransferDescription,
  SAME_DAY_ACH_PAYMENT_OPTION,
  DISABLED_INTERNATIONAL_OPTION,
  defaultTransferAddenda,
} from "./utils";

const steps: StepType[] = [
  {
    id: Step.PAYEE_SCHEDULE,
    number: 1,
    title: "Payee",
  },
  {
    id: Step.PAYMENT_INFO,
    number: 2,
    title: "Payment info",
  },
  {
    id: Step.CONFIRM_DETAILS,
    number: 3,
    title: "Confirm details",
  },
];

export const accountTypeOptions = [
  {
    value: "Checking",
    label: "Checking",
  },
  {
    value: "Savings",
    label: "Savings",
  },
];

export enum PaymentType {
  ONE_TIME = "oneTimePayment",
  MULTIPLE = "recurringPayment",
}

export enum MultipleRecurringPaymentType {
  FIXED,
  UNTIL_CANCELED,
}

type LocationState = {
  payeeGuid?: string;
  accountGuid?: string;
};

const defaultPaymentMethods = [ACH_PAYMENT_OPTION, WIRE_PAYMENT_OPTION];

const defaultVipPaymentMethods = [
  SAME_DAY_ACH_PAYMENT_OPTION,
  WIRE_PAYMENT_OPTION,
  INTERNATIONAL_PAYMENT_OPTION,
];

const scheduledPaymentMethods = [
  ACH_PAYMENT_OPTION,
  DISABLED_WIRE_SCHEDULED_PAYMENT_OPTION,
  DISABLED_INTERNATIONAL_SCHEDULED_PAYMENT_OPTION,
];
const recurringPaymentMethods = [
  ACH_PAYMENT_OPTION,
  DISABLED_WIRE_RECURRING_PAYMENT_OPTION,
  DISABLED_INTERNATIONAL_RECURRING_PAYMENT_OPTION,
];

const SendMoneyPage = () => {
  const { segmentTrack } = useSegment();
  const locationState = useLocation().state as LocationState | undefined;

  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const sameDayAchVipEnabled = useFeatureFlag("SAME_DAY_ACH_VIP");
  const { data: internationalBankAccount } = useQuery(useInternationalAccountQuery());

  const {
    guid: businessGuid,
    displayName: businessDisplayName,
    unitCoCustomerId: customerId,
  } = useBusiness();
  const highbeamApi = useHighbeamApi();

  const [paymentIdempotencyKey, setPaymentIdempotencyKey] = useRecoilState(
    paymentIdempotencyKeyState
  );
  const [isTransferError, setIsTransferError] = useRecoilState(isTransferErrorState);
  const [isTransferLoading, setIsTransferLoading] = useState(false);

  const bankAccounts = useOpenBankAccounts();
  const refreshBankAccountsQuery = useRefreshQuery([BANK_ACCOUNTS_QUERY_KEY]);

  const allTransferOptions = bankAccounts.map(mapTransferOption);

  const [transferFrom, setTransferFrom] = useState<TransferOption | null>(null);
  const supportedTransferOptions = allTransferOptions.filter((it) => it.supportsSendMoney);

  const resetTransferFrom = () => {
    const accountGuid = locationState?.accountGuid;
    const found = supportedTransferOptions.find(
      (option) => isHighbeamBankAccount(option) && option.guid === accountGuid
    );
    setTransferFrom(found || null);
  };

  const setTransferFromBySelectedPayee = (payee: PayeeOption | null) => {
    if (!payee) return;
    const accountGuid = payee?.lastTransferedBankAcountGuid;
    const found = supportedTransferOptions.find(
      (option) => isHighbeamBankAccount(option) && option.guid === accountGuid
    );
    if (!found) return;
    setTransferFrom(found);
  };

  const setAddendaFromSelectedPayee = (payee: PayeeOption | null) => {
    if (payee?.lastTransferedDescription) {
      setAddenda(payee.lastTransferedDescription);
    } else {
      setAddenda(defaultTransferAddenda(payee?.label!));
    }
  };

  const {
    data: payees = [],
    isLoading: isPayeesLoading,
    refetch: refetchPayees,
  } = useActivePayeesQuery();
  const [selectedPayee, setSelectedPayee] = useState<PayeeOption | null>(null);
  const previousSelectedPayee = usePrevious(selectedPayee);
  const [numberOfPayments, setNumberOfPayments] = useState<string>();
  const [fixedNumberOfPayments, setFixedNumberOfPayments] = useState<string>();
  const [now] = useState(startOfBankingDay());
  const [scheduledDate, setScheduledDate] = useState(now);
  const [paymentMethodOptions, setPaymentMethodOptions] =
    useState<PaymentMethodOption[]>(defaultPaymentMethods);

  const [amountInCents, setAmountInCents] = useRecoilState(paymentAmountState);
  const [paymentMethodOption, setPaymentMethodOption] = useRecoilState(
    selectedPaymentMethodOptionState
  );
  const [addenda, setAddenda] = useState("");

  const [achBankingInfo, setAchBankingInfo] = useState<BankingInfo>({
    routingNumber: "",
    accountNumber: "",
    accountType: accountTypeOptions[0],
  });

  const [wireBankingInfo, setWireBankingInfo] = useState<BankingInfo>({
    routingNumber: "",
    accountNumber: "",
    accountType: accountTypeOptions[0],
  });
  const [selectedBankCountryOption, setSelectedBankCountryOption] = useRecoilState(
    selectedBankCountryOptionState
  );
  const resetSelectedBankCountryOption = useResetRecoilState(selectedBankCountryOptionState);
  const [internationalBankingInfoV2, setInternationalBankingInfoV2] = useRecoilState(
    internationalBankingInfoState
  );
  const requiredFields = useRecoilValue(requiredFieldsState);

  const [address, setAddress] = useState<Address>(INITIAL_US_ADDRESS);
  const [internationalAddress, setInternationalAddress] = useRecoilState(internationalAddressState);
  const [entity, setEntity] = useRecoilState(internationalEntityState);
  const currency = useRecoilValue(quoteCurrencyState);
  const resetCurrency = useResetRecoilState(quoteCurrencyState);
  const selectedCurrency = currency.value;
  const setInternationalQuoteCurrencyOption = useSetRecoilState(quoteCurrencyState);
  // Needed for resetting the state
  const setUsdAmountInCents = useSetRecoilState(quoteUsdAmountInCentsState);
  const isLocalPayment = useRecoilValue(isLocalPaymentState);
  const purposeCode = useRecoilValue(purposeCodeState);
  const invoiceNumber = useRecoilValue(invoiceNumberState);
  const invoiceDate = useRecoilValue(invoiceDateState);
  const resetPurposeCode = useResetRecoilState(purposeCodeState);
  const resetInvoiceNumber = useResetRecoilState(invoiceNumberState);
  const resetInvoiceDate = useResetRecoilState(invoiceDateState);
  const quoteFixedSide = useRecoilValue(quoteFixedSideState);
  const resetQuoteFixedSide = useResetRecoilState(quoteFixedSideState);

  // TODO(Siva): This is debt due to us mixing react state and recoil state.
  // Once refactored, the tranferMoney() function should live at the last step of the flow
  // So that it has access to all values it needs
  // Failure to make this a loadable will introduce a loading state
  const calculatedLocalAmountInCentsLoadable = useRecoilValueLoadable(
    quoteCalculatedLocalAmountInCentsState
  );
  const quoteLoadable = useRecoilValueLoadable(quotesState(selectedCurrency));

  const [payeeEmail, setPayeeEmail] = useState<string>("");
  const [isPayeeEmailToggled, setIsPayeeEmailToggled] = useState<boolean>(false);

  const [paymentMetadataGuid, setPaymentMetadataGuid] = useState<string>("");
  const [invoiceName, setInvoiceName] = useState<string>("");

  const [currentStep, setCurrentStep] = useRecoilState(currentStepState);
  const [activeTab, setActiveTab] = useState<string>(PaymentType.ONE_TIME);
  const [multipleRecurringPaymentType, setMultipleRecurringPaymentType] =
    useState<MultipleRecurringPaymentType>(MultipleRecurringPaymentType.FIXED);

  const isOneTimeRecurringPayment = useMemo(
    () =>
      activeTab === PaymentType.ONE_TIME && startOfBankingDay(scheduledDate).isAfter(now, "day"),
    [scheduledDate, now, activeTab]
  );

  const isMultipleRecurringPayment = activeTab === PaymentType.MULTIPLE;

  const isRecurringPayment = isOneTimeRecurringPayment || isMultipleRecurringPayment;

  const determineValidBankingField = (field: InternationalField | undefined) => {
    if (!field) return undefined;
    if (!field.isValid) return undefined;
    if (requiredFields && !requiredFields.has(field.inputName)) return undefined;

    return field.value;
  };

  const getPayee = () => {
    switch (paymentMethodOption?.value) {
      case PaymentMethod.ACH:
      case PaymentMethod.SAME_DAY_ACH:
        if (isRecurringPayment) {
          return {
            achTransferMethod: {
              routingNumber: achBankingInfo.routingNumber,
              accountNumber: achBankingInfo.accountNumber,
            },
            emailAddress: payeeEmail,
            sendEmailNotification: isPayeeEmailToggled,
          };
        } else {
          return {
            achTransferMethod: {
              routingNumber: achBankingInfo.routingNumber,
              accountNumber: achBankingInfo.accountNumber,
            },
            ...(payeeEmail ? { emailAddress: payeeEmail } : {}),
            sendEmailNotification: isPayeeEmailToggled,
          };
        }

      case PaymentMethod.INTERNATIONAL:
        if (currentStep === Step.PAYMENT_INFO) {
          // TODO Use selected country recoil state
          const country = getInternationalWireCountry(internationalAddress.country?.value ?? "");
          const paymentType = isLocalPayment ? country.local.deliveryMethod : "Priority";

          return {
            internationalWireTransferMethod: {
              type: paymentType,
              entity: {
                entityType: entity.entityType,
                firstName: entity.firstName,
                lastName: entity.lastName,
                companyName: entity.companyName,
                companyBankHolderName: entity.companyBankHolderName
                  ? entity.companyBankHolderName
                  : undefined,
              },
              address: {
                addressLine1: internationalAddress.addressLine1!.value,
                addressLine2: internationalAddress.addressLine2
                  ? internationalAddress.addressLine2
                  : undefined,
                city: internationalAddress.city,
                state: internationalAddress.state?.value ?? "",
                zipCode: internationalAddress.zipCode,
                country: internationalAddress.country?.value!,
              },
              bankCountry: selectedBankCountryOption?.value,
              accountNumber: determineValidBankingField(internationalBankingInfoV2.accountNumber),
              bankCode: determineValidBankingField(internationalBankingInfoV2.bankCode),
              branchCode: determineValidBankingField(internationalBankingInfoV2.branchCode),
              bsbCode: determineValidBankingField(internationalBankingInfoV2.bsbCode),
              clabe: determineValidBankingField(internationalBankingInfoV2.clabe),
              cnaps: determineValidBankingField(internationalBankingInfoV2.cnaps),
              iban: determineValidBankingField(internationalBankingInfoV2.iban),
              ifsc: determineValidBankingField(internationalBankingInfoV2.ifsc),
              sortCode: determineValidBankingField(internationalBankingInfoV2.sortCode),
              swift: determineValidBankingField(internationalBankingInfoV2.swift),
              currency: selectedCurrency,
            },
            ...(payeeEmail ? { emailAddress: payeeEmail } : {}),
            sendEmailNotification: isPayeeEmailToggled,
          };
        } else {
          return {};
        }
      case PaymentMethod.WIRE:
        return {
          address: {
            addressLine1: address.addressLine1!.value,
            addressLine2: address.addressLine2 ? address.addressLine2 : undefined,
            city: address.city,
            state: address.state!.value,
            zipCode: address.zipCode,
            country: address.country?.value!,
          },
          wireTransferMethod: {
            routingNumber: wireBankingInfo.routingNumber,
            accountNumber: wireBankingInfo.accountNumber,
          },
          ...(payeeEmail ? { emailAddress: payeeEmail } : {}),
          sendEmailNotification: isPayeeEmailToggled,
        };
    }
  };

  const { mutateAsync: updatePayee, isPending: isUpdatingPayee } = useUpdatePayeeMutation();

  const onPaymentInfoNextStep = async () => {
    if (paymentMethodOption?.value === PaymentMethod.INTERNATIONAL) {
      try {
        await updatePayee({
          payeeGuid: selectedPayee!.value,
          ...getPayee(),
        });
      } catch (err) {
        if (err instanceof ApiError) {
          notify("error", err.message);
        } else {
          notify("error", "There was an error saving payee details. Please try again.");
        }

        segmentTrack(SEGMENT_EVENTS.INTERNATIONAL_WIRE_PAYEE_CREATION_FAILED, {
          businessGuid,
          recipientGuid: selectedPayee!.value,
        });
        captureException(err);
        return;
      }
    }
    setCurrentStep(Step.CONFIRM_DETAILS);
  };

  const handlePaymentError = useHandlePaymentError();

  const getUnitCoSensitiveTokenWithMfa = useGetUnitCoSensitiveTokenWithMfa({
    scopes: ["COUNTERPARTIES_WRITE", "PAYMENTS_WRITE", "PAYMENTS_WRITE_ACH_DEBIT"],
  });

  // Set the initially selected payee if it was provided in location state.
  useEffect(() => {
    const payeeGuid = locationState?.payeeGuid;
    if (!payeeGuid || isPayeesLoading || !payees) return;
    const found = payees.find((payee) => payee.guid === payeeGuid);
    if (!found) return;

    setSelectedPayee(payeeToPayeeOption(found));
  }, [isPayeesLoading, locationState?.payeeGuid, payees]);

  // Set the initially selected account if it was provided in location state.
  useMountEffect(() => {
    resetTransferFrom();
  });

  useEffect(() => {
    setAddendaFromSelectedPayee(selectedPayee);
    setTransferFromBySelectedPayee(selectedPayee ?? null);

    setEntity(initialEntityFromPayee((selectedPayee?.label ?? "") as string));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayee]);

  useEffect(() => {
    if (isMultipleRecurringPayment) {
      // Disable wire payment method option
      setPaymentMethodOptions(recurringPaymentMethods);
    } else if (isOneTimeRecurringPayment) {
      setPaymentMethodOptions(scheduledPaymentMethods);
    } else if (sameDayAchVipEnabled && transferFrom?.isSameDayAchEnabled) {
      setPaymentMethodOptions(defaultVipPaymentMethods);
    } else {
      if (internationalBankAccount && internationalBankAccount.enabled) {
        setPaymentMethodOptions([...defaultPaymentMethods, INTERNATIONAL_PAYMENT_OPTION]);
      } else {
        setPaymentMethodOptions([...defaultPaymentMethods, DISABLED_INTERNATIONAL_OPTION]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledDate, numberOfPayments, transferFrom, internationalBankAccount]);

  const prefillFormWithPayeeAchInfo = (payee: PayeeOption) => {
    if (!payee.achTransferMethod) {
      resetAchInfo();
      return;
    }
    setAchBankingInfo({
      ...achBankingInfo,
      routingNumber: payee.achTransferMethod.routingNumber,
      accountNumber: payee.achTransferMethod.accountNumber,
    });
  };

  const prefillFormWithPayeeWireInfo = (payee: PayeeOption) => {
    if (!payee.wireTransferMethod || !payee.address) {
      resetWireInfo();
      return;
    }
    setWireBankingInfo({
      ...wireBankingInfo,
      routingNumber: payee.wireTransferMethod.routingNumber,
      accountNumber: payee.wireTransferMethod.accountNumber,
    });
    setAddress({
      addressLine1: {
        value: payee.address?.addressLine1,
        label: payee.address?.addressLine1,
      },
      addressLine2: payee.address.addressLine2 ?? "",
      city: payee.address.city,
      state: { label: states[payee.address.state], value: payee.address.state },
      zipCode: payee.address.zipCode,
      country: US_COUNTRY_OPTION,
    });
  };

  const resetAchInfo = () => {
    setAchBankingInfo({ accountType: accountTypeOptions[0], routingNumber: "", accountNumber: "" });
  };

  const resetWireInfo = () => {
    setWireBankingInfo({
      accountType: accountTypeOptions[0],
      routingNumber: "",
      accountNumber: "",
    });
    setAddress(INITIAL_US_ADDRESS);
  };

  const resetInternationalWireInfo = () => {
    setEntity(initialEntityFromPayee((selectedPayee?.label ?? "") as string));
    setInternationalAddress(INITIAL_ADDRESS);
    setInternationalBankingInfoV2({});
    resetSelectedBankCountryOption();
    resetPurposeCode();
    resetInvoiceNumber();
    resetInvoiceDate();
    resetCurrency();
  };

  const resetInternationalWireAmounts = () => {
    setUsdAmountInCents(amountInCents);
    resetQuoteFixedSide();
  };

  const setQuoteIdempotencyKey = useSetRecoilState(quoteIdempotencyKeyState);
  const resetQuoteIdempotencyKey = () => {
    setQuoteIdempotencyKey(uuidv4());
  };

  const prefillFormWithPayeeInternationalInfo = (payee: PayeeOption) => {
    const info = payee.internationalWireTransferMethod;
    if (!info) {
      resetInternationalWireInfo();
      return;
    }
    const { entity, address, bankingInfo, currency } = getInternationalWirePrefillInfo(info);
    setEntity(entity);
    setInternationalAddress(address);
    setInternationalBankingInfoV2(bankingInfo);
    setInternationalQuoteCurrencyOption(currency);
    if (info.bankCountry) {
      setSelectedBankCountryOption(getInternationalWireCountry(info.bankCountry));
    }
  };

  useEffect(() => {
    if (!selectedPayee) {
      return;
    }

    switch (paymentMethodOption?.value) {
      case PaymentMethod.ACH:
        prefillFormWithPayeeAchInfo(selectedPayee);
        break;
      case PaymentMethod.SAME_DAY_ACH:
        prefillFormWithPayeeAchInfo(selectedPayee);
        break;
      case PaymentMethod.INTERNATIONAL:
        prefillFormWithPayeeInternationalInfo(selectedPayee);
        resetInternationalWireAmounts();
        break;
      case PaymentMethod.WIRE:
        prefillFormWithPayeeWireInfo(selectedPayee);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayee, paymentMethodOption]);

  useEffect(() => {
    if (!selectedPayee) return;
    setIsPayeeEmailToggled(selectedPayee.sendEmailNotification!);
    setPayeeEmail(selectedPayee.emailAddress!);
  }, [selectedPayee]);

  // TODO: Move this to recoil when cleaning up fx feature flag
  useEffect(() => {
    const info = selectedPayee?.internationalWireTransferMethod;
    if (!info) return;
    const { bankingInfo } = getInternationalWirePrefillInfo(info);
    setInternationalBankingInfoV2(bankingInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayee, selectedCurrency]);

  const { mutateAsync: createPayment, isPending: isCreatePaymentApprovalLoading } =
    useCreatePaymentMutation();

  const sendInternationalWireV2 = async (
    internationalPayment: InternationalWirePaymentRep.Creation
  ) => {
    await createPayment({
      data: {
        InternationalWire: {
          bankAccountGuid: internationalPayment.bankAccountGuid,
          description: internationalPayment.description,
          fixedSide: internationalPayment.receivedCurrency === "USD" ? "Receive" : quoteFixedSide, // NB(alex): hack we need until we include swift payment calculation in send money v2
          generalPaymentMetadataGuid: internationalPayment.generalPaymentMetadataGuid ?? null,
          idempotencyKey: internationalPayment.idempotencyKey,
          invoiceDate: internationalPayment.invoiceDate,
          invoiceNumber: internationalPayment.invoiceNumber,
          notificationEmailAddress: internationalPayment.payeeEmail ?? null,
          payeeGuid: internationalPayment.payeeGuid,
          paymentType: internationalPayment.paymentType,
          purposeCode: internationalPayment.purposeCode,
          reason: internationalPayment.reason,
          receiveAmount: internationalPayment.receivedAmount,
          receiveCurrency: internationalPayment.receivedCurrency,
          sendAmount: internationalPayment.amount,
          tags: {},
        },
      },
      send: true,
    });
  };

  const handleSendMoney = async () => {
    if (
      isLocalPayment &&
      calculatedLocalAmountInCentsLoadable.state === "loading" &&
      quoteLoadable.state === "loading"
    ) {
      return;
    }

    setIsTransferError(false);
    setIsTransferLoading(true);

    // Send payment immediately
    if (!isRecurringPayment) {
      const method = paymentMethodOption!.value;
      if (method === PaymentMethod.INTERNATIONAL) {
        // TODO Use selected country recoil state
        const country = getInternationalWireCountry(internationalAddress.country?.value ?? "");
        const paymentType = isLocalPayment ? country.local.deliveryMethod : "Priority";
        const receivedAmount = isLocalPayment
          ? (calculatedLocalAmountInCentsLoadable.contents as number)
          : amountInCents;
        const quote = quoteLoadable.contents as InternationalWireQuoteRep.Complete;
        const buyRate = isLocalPayment ? quote.rate : null;
        const internationalPayment = createInternationalPaymentRequest({
          amountInCents,
          businessGuid,
          payeeGuid: selectedPayee!.value,
          transferFrom: transferFrom! as HighbeamBankAccountTransferOption,
          idempotencyKey: paymentIdempotencyKey,
          description: selectedPayee!.label,
          addenda,
          isPayeeEmailToggled,
          paymentType,
          receivedCurrency: selectedCurrency,
          receivedAmount,
          buyRate,
          genPaymentMetadataGuid: paymentMetadataGuid,
          payeeEmail,
          purposeCode: purposeCode?.value ?? null,
          invoiceNumber,
          invoiceDate: invoiceDate?.toISOString() ?? null,
        });
        if (isAllowedToApprovePayments) {
          await sendInternationalWireV2(internationalPayment);
        } else {
          await createPayment({
            data: {
              InternationalWire: {
                bankAccountGuid: internationalPayment.bankAccountGuid,
                description: internationalPayment.description,
                fixedSide:
                  internationalPayment.receivedCurrency === "USD" ? "Receive" : quoteFixedSide, // NB(alex): hack we need until we include swift payment calculation in send money v2
                generalPaymentMetadataGuid: internationalPayment.generalPaymentMetadataGuid ?? null,
                idempotencyKey: internationalPayment.idempotencyKey,
                invoiceDate: internationalPayment.invoiceDate,
                invoiceNumber: internationalPayment.invoiceNumber,
                notificationEmailAddress: internationalPayment.payeeEmail ?? null,
                payeeGuid: internationalPayment.payeeGuid,
                paymentType: internationalPayment.paymentType,
                purposeCode: internationalPayment.purposeCode,
                reason: internationalPayment.reason,
                receiveAmount: internationalPayment.receivedAmount,
                receiveCurrency: internationalPayment.receivedCurrency,
                sendAmount: internationalPayment.amount,
                tags: {},
              },
            },
            send: false,
          });
        }
      } else {
        const createPaymentRequest =
          method === PaymentMethod.WIRE
            ? createWirePaymentRequest(
                // TODO: store payee name on payee instead of using label.
                selectedPayee!.label as string,
                amountInCents,
                transferFrom!,
                addenda,
                wireBankingInfo,
                address,
                paymentIdempotencyKey,
                selectedPayee!.value,
                isPayeeEmailToggled,
                payeeEmail,
                paymentMetadataGuid
              )
            : createAchPaymentRequest(
                selectedPayee!.label as string,
                amountInCents,
                transferFrom!,
                getAchTransferDescription(
                  transferFrom!,
                  selectedPayee!.label as string,
                  businessDisplayName || ""
                ),
                addenda,
                achBankingInfo,
                paymentIdempotencyKey,
                selectedPayee!.value,
                isPayeeEmailToggled,
                payeeEmail,
                paymentMetadataGuid
              );

        try {
          if (isAllowedToApprovePayments) {
            try {
              await getUnitCoSensitiveTokenWithMfa();
            } catch (err) {
              setIsTransferError(true);
              setIsTransferLoading(false);
              notify("warning", "There was an issue completing the transfer. Please try again.");
              captureException(err);
              return;
            }

            const handlePaymentV2 = async () => {
              if (!isHighbeamBankAccountTransferOption(transferFrom!)) {
                throw Error("Transfer from is not a highbeam bank account");
              }

              const payment = await highbeamApi.paymentV2.create({
                send: true,
                detail: {
                  UnitPayment: { unitPayload: createPaymentRequest },
                },
              });

              if (payment.status === "Failed") {
                notify("error", payment.reason);
                captureException(
                  new Error(`Send money was rejected with payment data ${JSON.stringify(payment)}`)
                );
                setPaymentIdempotencyKey(uuidv4());
                setIsTransferError(true);
                return;
              } else {
                setCurrentStep(Step.TRANSFER_SCHEDULED);
              }
            };

            await handlePaymentV2();
          } else {
            await createPayment({
              data: {
                UnitPayment: {
                  unitPayload: createPaymentRequest,
                },
              },
              send: false,
            });
          }
        } catch (e) {
          return handlePaymentError({
            message: "Could not send payment",
            e,
          });
        }
      }
    } else {
      // Schedule payment (hidden if !isAllowedToApprovePayments)

      // eslint-disable-next-line functional/no-let
      let unitToken: string;
      try {
        unitToken = await getUnitCoSensitiveTokenWithMfa();
      } catch (err) {
        captureException(err);
        setIsTransferError(true);
        notify("warning", "There was an issue completing the transfer. Please try again.");
        return;
      }

      const unitApi = new Unit(unitToken, env.UNIT_API_ORIGIN, DEFAULT_UNIT_CONFIG);
      const method = paymentMethodOption!.value;
      if (method !== PaymentMethod.ACH && method !== PaymentMethod.SAME_DAY_ACH) {
        // Currently, Unit only supports scheduled ACH transfers. If we want to do wires, we'll have
        // to build something custom.
        throw new Error(
          "Invalid payment method. We currently only support ACH for scheduled payments."
        );
      }

      // TODO(justin): We should be making this request from the server
      const counterpartyRequest: CreateCounterpartyRequest = createCounterpartyRequest(
        selectedPayee!.label,
        customerId!,
        achBankingInfo,
        selectedPayee!.value
      );

      // eslint-disable-next-line functional/no-let
      let createCounterpartyResponse: UnitResponse<AchCounterparty>;
      try {
        // TODO(justin): Persist counterparty_ids
        // NB(justin): This will result in a new counterparty being created for each scheduled ACH
        //  until we persist the counterparty_id on the server
        createCounterpartyResponse = await unitApi.counterparties.create(counterpartyRequest);
      } catch (err) {
        return handlePaymentError({
          message: "Could not create payee",
          e: err,
        });
      }

      const recurringPaymentRequest: CreateRecurringPaymentRequest =
        createRecurringAchPaymentRequest({
          amountInCents,
          transferFrom: transferFrom!,
          counterpartyId: createCounterpartyResponse.data.id,
          scheduledDate,
          description: addenda,
          payeeGuid: selectedPayee!.value,
          numberOfPayments: Number(numberOfPayments),
          idempotencyKey: paymentIdempotencyKey,
        });

      try {
        await unitApi.recurringPayments.create(recurringPaymentRequest);
        setCurrentStep(Step.TRANSFER_SCHEDULED);
      } catch (err) {
        return handlePaymentError({
          message: "Could not schedule payment",
          e: err,
        });
      }
    }

    try {
      if (isAllowedToApprovePayments) {
        await updatePayee({
          payeeGuid: selectedPayee!.value,
          ...getPayee(),
        });
      }
    } catch (err) {
      captureException(err);
      if (err instanceof ApiError) {
        if (err.statusCode === 403 || err.statusCode === 404) {
          // Silent error for now since the transfer succeeded
          return;
        }
      }
    }
  };

  useEffect(() => {
    // Skip this effect if the selected payee hasn't changed.
    if (selectedPayee?.value === previousSelectedPayee?.value) {
      return;
    }

    // Only ACH is supported for scheduled payments atm
    if (isRecurringPayment) {
      setPaymentMethodOption(ACH_PAYMENT_OPTION);
    } else {
      // Prioritize wires over international wires
      if (selectedPayee?.wireTransferMethod) {
        setPaymentMethodOption(WIRE_PAYMENT_OPTION);
      } else if (selectedPayee?.internationalWireTransferMethod) {
        setPaymentMethodOption(INTERNATIONAL_PAYMENT_OPTION);
      }
    }
    // Don't include previousSelectedPayee in the deps array!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayee, setPaymentMethodOption, paymentMethodOptions, isRecurringPayment]);

  // Cleanup if user navigates away from page
  useEffect(
    () => () => {
      resetSendMoney();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const resetSendMoney = () => {
    setPaymentIdempotencyKey(uuidv4());
    setAmountInCents(0);
    setSelectedPayee(null);
    setAddenda("");
    setScheduledDate(now);
    setIsPayeeEmailToggled(false);
    setPayeeEmail("");
    setPaymentMetadataGuid("");
    setInvoiceName("");
    resetWireInfo();
    resetInternationalWireInfo();
    resetInternationalWireAmounts();
    resetQuoteIdempotencyKey();
    resetAchInfo();
    setCurrentStep(Step.PAYEE_SCHEDULE);
    refetchPayees(); // To update last transfer metadata
    setIsTransferError(false);
    setIsTransferLoading(false);
    refreshBankAccountsQuery();
    resetTransferFrom();
    setPaymentMethodOption(ACH_PAYMENT_OPTION);
  };

  const getBankingInfo = () => {
    switch (paymentMethodOption?.value) {
      case PaymentMethod.ACH:
      case PaymentMethod.SAME_DAY_ACH:
        return achBankingInfo;
      case PaymentMethod.WIRE:
        return wireBankingInfo;
    }
  };

  const getSetBankingInfo = () => {
    switch (paymentMethodOption?.value) {
      case PaymentMethod.ACH:
      case PaymentMethod.SAME_DAY_ACH:
        return setAchBankingInfo;
      case PaymentMethod.WIRE:
        return setWireBankingInfo;
    }
  };

  useEffect(() => {
    if (isTransferError) {
      setIsTransferLoading(false);
    }
  }, [isTransferError, setIsTransferLoading]);

  const isLoading = isTransferLoading || isCreatePaymentApprovalLoading;

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Send money</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.IconTile icon={<ArrowLineUpRight />} />
          <DashboardPage.Header.Title>
            {isAllowedToApprovePayments ? "Send money" : "Draft a payment"}
          </DashboardPage.Header.Title>
        </DashboardPage.Header>

        <DashboardPage.Section>
          {currentStep === Step.TRANSFER_SCHEDULED ? (
            <div className={styles.contentTransfer}>
              <div className={styles.leftContent}></div>
              <div className={styles.rightContent}>
                {isMultipleRecurringPayment ? (
                  <RecurringPaymentScheduled
                    from={transferFrom!}
                    amountInCents={amountInCents}
                    to={{
                      name: selectedPayee?.label! as string,
                      email: payeeEmail,
                      isEmailToggled: isPayeeEmailToggled,
                    }}
                    scheduledDate={scheduledDate}
                    bankingInfo={getBankingInfo()!}
                    resetSendMoney={resetSendMoney}
                    paymentMethodOption={paymentMethodOption}
                  />
                ) : (
                  <TransferScheduled
                    from={transferFrom!}
                    amountInCents={amountInCents}
                    to={{
                      name: selectedPayee?.label! as string,
                      email: payeeEmail,
                      isEmailToggled: isPayeeEmailToggled,
                    }}
                    scheduledDate={scheduledDate}
                    scheduledPayment={isOneTimeRecurringPayment}
                    bankingInfo={getBankingInfo()!}
                    internationalBankingInfo={internationalBankingInfoV2}
                    resetSendMoney={resetSendMoney}
                    paymentMethodOption={paymentMethodOption}
                    invoiceName={invoiceName}
                  />
                )}
              </div>
            </div>
          ) : (
            <main className={styles.content}>
              <Steps steps={steps} currentStep={currentStep} />

              <section className={styles.step}>
                {currentStep === Step.PAYEE_SCHEDULE && (
                  <PayeeSchedule
                    onNextPress={() => setCurrentStep(Step.PAYMENT_INFO)}
                    selectedPayee={selectedPayee}
                    setSelectedPayee={setSelectedPayee}
                    numberOfPayments={numberOfPayments}
                    setNumberOfPayments={setNumberOfPayments}
                    fixedNumberOfPayments={fixedNumberOfPayments}
                    setFixedNumberOfPayments={setFixedNumberOfPayments}
                    scheduledDate={scheduledDate}
                    setScheduledDate={setScheduledDate}
                    multipleRecurringPaymentType={multipleRecurringPaymentType}
                    setMultipleRecurringPaymentType={setMultipleRecurringPaymentType}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                )}

                {currentStep === Step.PAYMENT_INFO && (
                  <PaymentInfo
                    paymentMethodOptions={paymentMethodOptions}
                    amountInCents={amountInCents}
                    setAmountInCents={setAmountInCents}
                    transferFromOptions={supportedTransferOptions}
                    transferFrom={transferFrom}
                    setTransferFrom={setTransferFrom}
                    paymentMethodOption={paymentMethodOption}
                    setPaymentMethod={setPaymentMethodOption}
                    bankingInfo={getBankingInfo()!}
                    setBankingInfo={getSetBankingInfo()!}
                    onPrevPress={() => setCurrentStep(Step.PAYEE_SCHEDULE)}
                    onNextPress={onPaymentInfoNextStep}
                    addenda={addenda}
                    setAddenda={setAddenda}
                    address={address}
                    setAddress={setAddress}
                    accountTypeOptions={accountTypeOptions}
                    isRecurringPayment={isRecurringPayment}
                    isUpdatingPayee={isUpdatingPayee}
                    payeeEmail={payeeEmail}
                    setPayeeEmail={setPayeeEmail}
                    isPayeeEmailToggled={isPayeeEmailToggled}
                    setIsPayeeEmailToggled={setIsPayeeEmailToggled}
                    paymentMetadataGuid={paymentMetadataGuid}
                    setPaymentMetadataGuid={setPaymentMetadataGuid}
                    setInvoiceName={setInvoiceName}
                  />
                )}

                {currentStep === Step.CONFIRM_DETAILS &&
                  (isMultipleRecurringPayment ? (
                    <RecurringPaymentConfirmDetails
                      sendMoneyConfirmation={{
                        from: transferFrom!,
                        amountInCents,
                        to: {
                          name: selectedPayee?.label! as string,
                          email: payeeEmail,
                          isEmailToggled: isPayeeEmailToggled,
                        },
                        scheduledDate: scheduledDate,
                        bankingInfo: getBankingInfo()!,
                        internationalBankingInfo: internationalBankingInfoV2,
                        paymentMethodOption: paymentMethodOption,
                      }}
                      onNextPress={handleSendMoney}
                      onPrevPress={() => setCurrentStep(Step.PAYMENT_INFO)}
                      isTransferLoading={isLoading}
                      numberOfPayments={numberOfPayments}
                    />
                  ) : (
                    <SendMoneyConfirmDetails
                      sendMoneyConfirmation={{
                        from: transferFrom!,
                        amountInCents,
                        to: {
                          name: selectedPayee?.label! as string,
                          email: payeeEmail,
                          isEmailToggled: isPayeeEmailToggled,
                        },
                        scheduledDate: scheduledDate,
                        scheduledPayment: isOneTimeRecurringPayment,
                        bankingInfo: getBankingInfo()!,
                        internationalBankingInfo: internationalBankingInfoV2,
                        paymentMethodOption: paymentMethodOption,
                        invoiceName: invoiceName,
                      }}
                      onNextPress={handleSendMoney}
                      onPrevPress={() => setCurrentStep(Step.PAYMENT_INFO)}
                      isTransferLoading={isLoading}
                      step={steps[steps.length - 1]}
                    />
                  ))}
              </section>
            </main>
          )}
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

export default SendMoneyPage;
