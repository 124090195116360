import { Dialog, DialogBackdrop, Transition, TransitionChild } from "@headlessui/react";
import { Fragment, Suspense, useImperativeHandle } from "react";
import { transitions, useTransition } from "utils/transitions";

import ModalLoadingFallback from "../ModalV3/ModalLoadingFallback";

import Body from "./Body";
import Content from "./Content";
import Footer, { CancelButton, SubmitButton } from "./Footer";
import Header, { CloseModalButton } from "./Header";
import styles from "./ModalV2.module.scss";
import Section, {
  ModalSectionHeader,
  ModalSectionHeading,
  ModalSectionSubheading,
} from "./Section";

// Useful when defining a ref via `const modalRef = useRef<ModalRefValue>(null);`
export type ModalRefValue = {
  close: () => void;
};

// Useful for extending the modal.
export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  modalRef?: React.RefObject<ModalRefValue>;
};

type Props = React.PropsWithChildren<ModalProps>;

/**
 * Composable modal
 *
 * NB(alex): If you want to ensure a smooth close transition, you should be able to use the `modalRef` to close the modal.
 * ```
 * const modalRef = useRef<ModalRefValue>(null);
 * // ...
 * <ModalV2 isOpen={isOpen} onClose={onClose} modalRef={modalRef}>
 *  // ...
 *  <ModalV2.Content.Footer>
 *   <ModalV2.Content.Footer.CancelButton onClick={modalRef.current?.close} />
 *  </ModalV2.Content.Footer>
 * </ModalV2>
 * ```
 */
const ModalV2: React.FC<Props> = ({ children, isOpen, onClose, modalRef }) => {
  const { show, setShow, handleClose } = useTransition(isOpen, {
    initiateClose() {
      setShow(false);
    },
    onClose,
  });

  useImperativeHandle(
    modalRef,
    () => ({
      close: handleClose,
    }),
    [handleClose]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Transition show={show} as={Fragment}>
      <Dialog unmount onClose={handleClose}>
        <TransitionChild as={Fragment} {...transitions.opacity}>
          <DialogBackdrop className={styles.overlay} onClick={handleClose} />
        </TransitionChild>

        <TransitionChild as="div" className={styles.container} {...transitions.zoom}>
          <Suspense
            // NB(alex): Lazy fallback implementation because I'd like to rewrite ModalV2 soon.
            fallback={
              <Content>
                <ModalLoadingFallback />
              </Content>
            }
          >
            {children}
          </Suspense>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
};

export default Object.assign(ModalV2, {
  Content: Object.assign(Content, {
    Header: Object.assign(Header, {
      CloseModalButton: CloseModalButton,
    }),
    Body: Object.assign(Body, {
      Section: Object.assign(Section, {
        Header: Object.assign(ModalSectionHeader, {
          Heading: ModalSectionHeading,
          Subheading: ModalSectionSubheading,
        }),
      }),
    }),
    Footer: Object.assign(Footer, {
      CancelButton: CancelButton,
      SubmitButton: SubmitButton,
    }),
  }),
});
