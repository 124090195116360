import classNames from "classnames";
import { FC } from "react";
import { ConnectedStoreType } from "resources/connected-stores/types";
import getConnectedStoreIcon from "resources/connected-stores/utils/getConnectedStoreIcon";
import getConnectedStoreName from "resources/connected-stores/utils/getConnectedStoreName";
import colors from "styles/colors";
import PlatformAvatar from "ui/icons/PlatformAvatar";
import PlatformAvatarGroup from "ui/icons/PlatformAvatarGroup";
import Text from "ui/typography/Text";

import styles from "./ConnectedStoresAvatarGroup.module.scss";

type Props = {
  connectedStoreTypes: ConnectedStoreType[];
  isGrey?: boolean;
  size?: "sm" | "md";
};

const ConnectedStoresAvatarGroup: FC<Props> = ({ connectedStoreTypes, isGrey, size }) => {
  const uniqueConnectedStoreTypes = Array.from(new Set(connectedStoreTypes));

  return (
    <div className={classNames(styles.container)}>
      <PlatformAvatarGroup>
        {uniqueConnectedStoreTypes.map((connectedStoreType) => (
          <PlatformAvatar key={`${connectedStoreType}-avatar`}>
            <PlatformAvatar.Img
              alt={connectedStoreType}
              src={getConnectedStoreIcon({
                connectedStoreType: connectedStoreType,
                isGrey: isGrey,
              })}
            />
          </PlatformAvatar>
        ))}
      </PlatformAvatarGroup>
      <Text size={size === "sm" ? 14 : 18} weight="medium" color={colors.grey[900]}>
        {uniqueConnectedStoreTypes.length === 1
          ? `Your ${getConnectedStoreName(uniqueConnectedStoreTypes[0])} ${
              connectedStoreTypes.length === 1 ? "store" : "stores"
            }`
          : "Your stores"}
      </Text>
    </div>
  );
};

export default ConnectedStoresAvatarGroup;
