import BillRep from "reps/BillRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillActionApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async updateState(billId: string, newState: BillRep.State): Promise<BillRep.Complete> {
    const qp = new URLSearchParams({ newState });
    const url = `/accounts-payable/bills/${billId}/action/process?${qp}`;
    return (await this.api.post<BillRep.Complete>(url))!;
  }
}
