import { ErrorBoundary } from "@sentry/react";
import LoadingPage from "pages/LoadingPage";
import OnboardingBankApplicationPage from "pages/Onboarding/OnboardingBankApplicationPage";
import OnboardingConnectStoresPage from "pages/Onboarding/OnboardingConnectStoresPage";
import OnboardingDelayed from "pages/Onboarding/OnboardingDelayed";
import OnboardingGetStartedPage from "pages/Onboarding/OnboardingGetStartedPage";
import OnboardingPersonalInfoPage from "pages/Onboarding/OnboardingPersonalInfoPage";
import { Suspense } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import useBusinessMemberOnboarded from "resources/business-members/queries/useBusinessMemberOnboarded";

import { PLAID_OAUTH_REDIRECT_URL } from "../../../resources/plaid-connections/queries/usePlaidLinkTokenQueryOptions";
import PlaidOauthRedirectPage from "../../PlaidOauthRedirectPage";
import OnboardingConnectPlaidAccountsPage from "../OnboardingConnectPlaidAccountsPage";

import OnboardingNavigate from "./OnboardingNavigate";

const OnboardingRouter = () => {
  const [searchParams] = useSearchParams();

  const isOnboarded = useBusinessMemberOnboarded();

  const shouldRedirect = isOnboarded && !searchParams.get("forceOnboarding");
  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

  return (
    <ErrorBoundary fallback={() => <OnboardingDelayed />}>
      <Suspense fallback={<LoadingPage location={OnboardingRouter.name} />}>
        <Routes>
          <Route path="/connect-stores" element={<OnboardingConnectStoresPage />} />
          <Route path="/connect-accounts" element={<OnboardingConnectPlaidAccountsPage />} />
          <Route path="/bank-application" element={<OnboardingBankApplicationPage />} />
          <Route path="/personal-info" element={<OnboardingPersonalInfoPage />} />
          <Route path="/get-started" element={<OnboardingGetStartedPage />} />
          <Route path={PLAID_OAUTH_REDIRECT_URL} element={<PlaidOauthRedirectPage />} />
          <Route path="*" element={<OnboardingNavigate />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default OnboardingRouter;
