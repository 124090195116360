import ApEmailAliasRep from "reps/ApEmailAliasRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class EmailAliasApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async search(businessGuid: string): Promise<ApEmailAliasRep.Complete[]> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/accounts-payable/email-aliases?${queryParams}`;
    return (await this.api.get<ApEmailAliasRep.Complete[]>(url))!;
  }
}
