import MultiStep from "components/layouts/MultiStep";
import { Link } from "react-router-dom";

import useGetPreviousPathname from "../hooks/useGetPreviousPathname";

const CreditApplicationBackButton = () => {
  const prevStepPath = useGetPreviousPathname();

  if (!prevStepPath) {
    return null;
  }

  return (
    <Link to={prevStepPath}>
      <MultiStep.Controls.BackButton disabled={!Boolean(prevStepPath)} />
    </Link>
  );
};

export default CreditApplicationBackButton;
