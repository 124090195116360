import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./HighbeamCard.module.scss";

const HighbeamCardInactive = () => (
  <Button className={styles.inactiveContainer}>
    <Text size={12} weight={"bold"} className={styles.inactiveColor}>
      Activate
    </Text>
  </Button>
);

export default HighbeamCardInactive;
