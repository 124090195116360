import { FEATURE_FLAGS } from "flags";
import Fuse from "fuse.js";
import { useMemo, useRef } from "react";
import { useRecoilValue } from "recoil";
import SubcategoryRep from "reps/Insights/SubcategoryRep";
import TransactionRep from "reps/Insights/TransactionRep";
import VendorSummaryRep from "reps/Insights/VendorSummaryRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import featureFlagsState from "state/featureFlags";
import { Option } from "ui/inputs/Dropdown";
import { ColumnProps, FetchTableData } from "ui/table/TableV2";
import { HasKey } from "ui/table/TableV2/TableWrapper";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMediaQuery, { LARGE_DESKTOP_MAX, MOBILE_MAX } from "utils/device/useMediaQuery";
import { LocalMonth } from "utils/types/date";

import SpendByVendorTableCategoryColumn from "./SpendByVendorTableCategoryColumn";
import { Controls } from "./SpendByVendorTableControls";
import SpendByVendorTableNumericColumn from "./SpendByVendorTableNumericColumn";
import SpendByVendorTableVendorColumn from "./SpendByVendorTableVendorColumn";

type UseVendorList = {
  columns: ColumnProps<VendorSummaryRep>[];
  fetchData: FetchTableData<VendorSummaryRep & HasKey>;
};

const useVendorList = ({ since, until, search }: Controls): UseVendorList => {
  const mediaQuery = useMediaQuery();
  const isMobile = mediaQuery <= MOBILE_MAX;

  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  const flags = useRecoilValue(featureFlagsState);
  const showCategoryColumn = flags[FEATURE_FLAGS.SPEND_BY_VENDOR_ALLOW_RECATEGORIZATION] as boolean;
  const sources: TransactionRep.TransactionSource[] | undefined = flags[
    FEATURE_FLAGS.SPEND_BY_VENDOR_SHOW_TRANSACTIONS_FROM_EXTERNAL_SOURCES
  ]
    ? undefined
    : ["UnitCo"];

  const dataCache = useRef<Map<string, (VendorSummaryRep & HasKey)[]>>(new Map());

  const width = calculateWidth(mediaQuery);
  const columns = useMemo(() => {
    const onCategoryChange = (
      summary: VendorSummaryRep,
      subcategory: Option & { subcategory: SubcategoryRep.Complete }
    ) => {
      dataCache.current.forEach((value, key) => {
        dataCache.current.set(
          key,
          value.map((datum) => {
            if (
              datum.vendorName === summary.vendorName &&
              datum.vendorType === summary.vendorType
            ) {
              return { ...datum, subcategory: subcategory.subcategory };
            }
            return datum;
          })
        );
      });
    };
    return createColumns(since, until, isMobile, width, showCategoryColumn, onCategoryChange);
  }, [since, until, isMobile, width, showCategoryColumn]);

  const data = (() => {
    const key = [businessGuid, since, until].join("-");
    const data = dataCache.current.get(key);
    if (data) return Promise.resolve(data);
    return (async () => {
      const data = (await highbeamApi.vendorList.search(businessGuid, since, until, sources)).map(
        (datum, i) => ({ key: i, ...datum })
      );
      dataCache.current.set(key, data);
      return data;
    })();
  })();

  const fetchData: FetchTableData<VendorSummaryRep & HasKey> = async ({
    offset,
    pageSize,
    sort,
  }) => {
    if (offset === undefined || pageSize === undefined) {
      throw new Error("Vendor list should use pagination.");
    }

    // eslint-disable-next-line functional/no-let
    let result = [...(await data)];

    if (search) {
      result = new Fuse(result, {
        shouldSort: true,
        threshold: 0.3,
        keys: ["vendorName", "subcategory.displayName", "subcategory.category.displayName"],
      })
        .search(search)
        .map((result) => result.item);
    }

    if (typeof sort?.by === "string" && sort.by === "vendor") {
      result.sort((a, b) => a.vendorName.localeCompare(b.vendorName));
    } else if (typeof sort?.by === "string" && sort.by === "total") {
      result.sort((a, b) => a.total.amount - b.total.amount);
    } else if (typeof sort?.by === "string" && sort.by.startsWith("month-")) {
      const i = parseInt(sort.by.substring(6), 10);
      result.sort((a, b) => (a.table[i]?.amount ?? 0) - (b.table[i]?.amount ?? 0));
    }

    if (sort?.direction === "descending") {
      result.reverse();
    }

    return { data: result.slice(offset, offset + pageSize), totalCount: result.length };
  };

  return { columns, fetchData };
};

export default useVendorList;

const calculateWidth = (mediaQuery: number): number => {
  if (mediaQuery > LARGE_DESKTOP_MAX) return 224;
  if (mediaQuery > 1232) return 192;
  return 192;
};

const createColumns = (
  since: LocalMonth,
  until: LocalMonth,
  isMobile: boolean,
  width: number,
  showCategoryColumn: boolean,
  onCategoryChange: (
    summary: VendorSummaryRep,
    subcategory: Option & { subcategory: SubcategoryRep.Complete }
  ) => void
): ColumnProps<VendorSummaryRep>[] => {
  const months = since.rangeTo(until);

  // const sticky = !isMobile;
  const sticky = false; // TODO(jhudson), re-enable stickiness.

  const result: ColumnProps<VendorSummaryRep>[] = [];
  result.push({
    key: "vendor",
    renderCell: ({ vendorName, vendorType }) => (
      <SpendByVendorTableVendorColumn name={vendorName} type={vendorType} />
    ),
    sortable: true,
    sticky: sticky ? "left" : undefined,
    title: "Vendor",
    width,
  });
  if (showCategoryColumn) {
    result.push({
      key: "category",
      renderCell: (summary, { i, total }) => {
        const menuPlacement = () => {
          if (i < 4) return "bottom";
          if (total - i < 5) return "top";
          return "bottom";
        };
        return (
          <SpendByVendorTableCategoryColumn
            menuPlacement={menuPlacement()}
            onUpdate={onCategoryChange}
            vendorSummary={summary}
          />
        );
      },
      sortable: false,
      sticky: sticky ? "left" : undefined,
      title: "Category",
      width: width * 1.625,
    });
  }
  months.forEach((month, i) => {
    result.push({
      align: "right",
      defaultSortDirection: "descending",
      key: `month-${i}`,
      renderCell: ({ table }) =>
        table.length > i ? (
          <SpendByVendorTableNumericColumn>{table[i].amount}</SpendByVendorTableNumericColumn>
        ) : null,
      sortable: true,
      title: month.toString(),
    });
  });
  result.push({
    align: "right",
    defaultSortDirection: "descending",
    key: "total",
    renderCell: ({ table }) => (
      <SpendByVendorTableNumericColumn>
        {table.reduce((acc, datum) => acc + datum.amount, 0)}
      </SpendByVendorTableNumericColumn>
    ),
    sticky: sticky ? "right" : undefined,
    sortable: true,
    title: "Total",
  });
  return result;
};
