import { useSuspenseQuery } from "@tanstack/react-query";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import useCapitalDrawdownApprovalsQueryOptions from "./useCapitalDrawdownApprovalsQueryOptions";

const usePendingDrawdown = <TRequired extends boolean>(
  pendingDrawdownGuid: string,
  params?: RequiredParam<TRequired>
) => {
  const { data } = useSuspenseQuery({
    ...useCapitalDrawdownApprovalsQueryOptions(),
    select: (pendingDrawdowns) =>
      pendingDrawdowns.find((pendingDrawdown) => pendingDrawdown.guid === pendingDrawdownGuid),
  });

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default usePendingDrawdown;
