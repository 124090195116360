import classNames from "classnames";
import { FC, ReactNode } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import { FileType, fileTypeExtensions } from "utils/dropzone/file-types";

import styles from "./NoFilesUploaded.module.scss";
import NoFilesUploadedUploadFileButton from "./NoFilesUploadedUploadFileButton";

export const DFAULT_HEADING_TEXT = "No files";
export const DEFAULT_SUBHEADING_TEXT = "Click “Upload file” or drag and drop a file to upload.";

type Props = Omit<DropzoneOptions, "accept"> & {
  heading?: ReactNode;
  subheading?: ReactNode;
  uploadFileButton?: ReactNode;
  fileTypes: FileType[];
  isLoading?: boolean;
};

const NoFilesUploaded: FC<Props> = ({
  heading = DFAULT_HEADING_TEXT,
  subheading = DEFAULT_SUBHEADING_TEXT,
  uploadFileButton = <NoFilesUploadedUploadFileButton />,
  fileTypes,
  isLoading,
  ...dropzoneOptions
}) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    ...dropzoneOptions,
    accept: Object.assign({}, ...fileTypes.map((fileType) => fileTypeExtensions[fileType])),
  });

  return (
    <div
      className={classNames(styles.container, isDragActive && styles.dragActive)}
      onClick={open}
      {...getRootProps()}
    >
      {isLoading && (
        <div className={styles.loading}>
          <AnimatedSpinner />
        </div>
      )}

      <input {...getInputProps()} />

      <div className={styles.header}>
        <div className={styles.heading}>{heading}</div>
        <div className={styles.subheading}>{subheading}</div>
      </div>
      {uploadFileButton}
    </div>
  );
};

export default Object.assign(NoFilesUploaded, {
  UploadFileButton: NoFilesUploadedUploadFileButton,
});
