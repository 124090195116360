import { DEFAULT_MONTHS_SINCE } from "config/constants/filters";
import dayjs from "dayjs";
import useSearchParamDayjs from "utils/searchParams/useSearchParamDayjs";
import useSearchParamValue from "utils/searchParams/useSearchParamValue";

export const useSearchQuerySearchParam = () => useSearchParamValue("search", "");

export const useSinceSearchParam = () => {
  return useSearchParamDayjs("until", dayjs().subtract(DEFAULT_MONTHS_SINCE, "month"));
};

export const useUntilSearchParam = () => {
  return useSearchParamDayjs("since", dayjs());
};

/**
 * Aggregates all search params as a readonly object.
 */
const useLocTransactionsSearchParams = () => {
  const [search] = useSearchQuerySearchParam();
  const [since] = useSinceSearchParam();
  const [until] = useUntilSearchParam();
  return {
    search,
    until,
    since,
  };
};

export default useLocTransactionsSearchParams;
