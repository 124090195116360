import colors from "styles/colors";
import Pill from "ui/data-display/Pill";
import Text from "ui/typography/Text";

import styles from "./DrawdownTransferDetails.module.scss";

const DrawdownTransferStatus = () => {
  return (
    <div className={styles.container}>
      <Pill color="grey" bordered>
        Pending
      </Pill>
      <Text size={14} weight="medium" color={colors.grey[800]}>
        Drawdown
      </Text>
    </div>
  );
};

export default DrawdownTransferStatus;
