import { Card } from "@highbeam/unit-node-sdk";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import HighbeamCard from "components/HighbeamCard";
import { Link } from "react-router-dom";
import useCardsQueryOptions from "resources/cards/queries/useCardsQueryOptions";
import Divider from "ui/data-display/Divider";
import Shimmer from "ui/feedback/Shimmer";
import Text from "ui/typography/Text";
import { cardFilterByTab } from "utils/cards";
import { pluralize } from "utils/string";

import { EmptyCardPlaceholder } from "../CardsPage/CardPlaceholder";
import styles from "../CardsPage/CardsPage.module.scss";
import CardTabs from "../CardsPage/CardTabs";
import { useActiveTabSearchParam } from "../CardsPage/hooks/useCardsOverviewSearchParams";
import {
  isPhysicalCreditCard,
  isPhysicalDebitCard,
  isVirtualChargeCard,
  isVirtualDebitCard,
} from "../CardsPage/utils";

const ArchivedCardsLists = () => {
  const [activeTab] = useActiveTabSearchParam();

  const { data: archivedCards = [], isLoading } = useQuery({
    ...useCardsQueryOptions({ status: ["ClosedByCustomer"] }),
    select: (data) => data.filter((card) => cardFilterByTab(activeTab, card)),
  });

  return (
    <section className={styles.container}>
      <section>
        <CardTabs />
      </section>
      <VirtualCardsSection cards={archivedCards} isLoading={isLoading} />
      <Divider />
      <PhysicalCardsSection cards={archivedCards} isLoading={isLoading} />
    </section>
  );
};

type CardsSectionProps = {
  cards: Card[];
  isLoading: boolean;
};

const VirtualCardsSection = ({ cards, isLoading }: CardsSectionProps) => {
  const virtualCards = cards.filter((card) => {
    if (isVirtualChargeCard(card)) return true;
    if (isVirtualDebitCard(card)) return true;

    return false;
  });

  const showEmptyVirtualCardPlaceholder = virtualCards.length === 0;
  return (
    <section>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <Text size={16} weight="bold">
            Virtual cards
          </Text>
          {isLoading ? (
            <Shimmer />
          ) : (
            <Text size={14}>{pluralize(virtualCards.length, "card")}</Text>
          )}
        </div>
      </div>

      {isLoading && (
        <div className={classNames(styles.cards, styles.cardsShimmer)}>
          <Shimmer
            additionalShimmerWrapperClassName={styles.card}
            additionalShimmerClassName={[styles.card]}
          />
          <Shimmer
            additionalShimmerWrapperClassName={styles.card}
            additionalShimmerClassName={[styles.card]}
          />
        </div>
      )}

      {!isLoading && (
        <div className={styles.cards}>
          {virtualCards.map((card) => (
            <Link key={card.id} to={`/cards/${card.id}`}>
              <HighbeamCard card={card} areDetailsAvailable containerClickable />
            </Link>
          ))}
          {showEmptyVirtualCardPlaceholder && <EmptyCardPlaceholder heading="No archived cards" />}
        </div>
      )}
    </section>
  );
};

const PhysicalCardsSection = ({ cards, isLoading }: CardsSectionProps) => {
  const physicalCards = cards.filter((card) => {
    if (isPhysicalDebitCard(card)) return true;
    if (isPhysicalCreditCard(card)) return true;

    return false;
  });

  const showEmptyPhysicalCardPlaceholder = physicalCards.length === 0;

  return (
    <section>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <Text size={16} weight="bold">
            Physical cards
          </Text>
          {isLoading ? (
            <Shimmer />
          ) : (
            <Text size={14}>{pluralize(physicalCards.length, "card")}</Text>
          )}
        </div>
      </div>

      {isLoading && (
        <div className={classNames(styles.cards, styles.cardsShimmer)}>
          <Shimmer
            additionalShimmerWrapperClassName={styles.card}
            additionalShimmerClassName={[styles.card]}
          />
          <Shimmer
            additionalShimmerWrapperClassName={styles.card}
            additionalShimmerClassName={[styles.card]}
          />
        </div>
      )}

      {!isLoading && (
        <div className={styles.cards}>
          {physicalCards.map((card) => (
            <Link key={card.id} to={`/cards/${card.id}`}>
              <HighbeamCard card={card} areDetailsAvailable containerClickable />
            </Link>
          ))}

          {showEmptyPhysicalCardPlaceholder && <EmptyCardPlaceholder heading="No archived cards" />}
        </div>
      )}
    </section>
  );
};

export default ArchivedCardsLists;
