import classNames from "classnames";
import MultiStep from "components/layouts/MultiStep";
import { useRef, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import BarChart from "ui/data-visualization/BarChart";
import CurrencyInput from "ui/inputs/CurrencyInputV2";
import Helper from "ui/inputs/Helper";
import RadioCardSelect from "ui/inputs/RadioCardSelect";
import RadioCardSimple from "ui/inputs/RadioCardSimple";
import Text from "ui/typography/Text";
import { formatAmount } from "utils/string";

import RepaymentBankAccountDropdown from "../../components/RepaymentBankAccountDropdown";
import useRepayLineOfCreditForm, {
  useRepayLineOfCreditFormContext,
} from "../../hooks/useRepayLineOfCreditForm";

import styles from "./RepayLineOfCreditAmountView.module.scss";

const repaymentOptions = [1, 0.5, 0.25, "custom"] as const;

type RepaymentOption = (typeof repaymentOptions)[number];

const REPAY_LINE_OF_CREDIT_FORM_ID = "repay-line-of-credit-form";

const RepayLineOfCreditAmountForm = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();

  const runningBalance = capitalAccountSummary.runningBalance;
  const limit = capitalAccountSummary.details.limit;

  const amountOwed = 0 - runningBalance;

  const { control, handleSubmit, watch } = useRepayLineOfCreditFormContext();
  const amountInputValue = watch("amount");
  const repaymentBankAccountTransferOptionValue = watch("repaymentBankAccountTransferOption");

  const repaymentBankAccountAvailableBalance =
    repaymentBankAccountTransferOptionValue?.availableBalanceInCents; // NB(alex): `?.` is necessary because the default value might not be populated on mount. This hack feels wrong to me, open to suggestions for a better pattern.
  const repaymentBankAccountBalanceAfterRepayment =
    repaymentBankAccountAvailableBalance - amountInputValue;
  const insufficientFunds = repaymentBankAccountBalanceAfterRepayment < 0;

  const isOverpaying = amountInputValue > amountOwed;

  // TODO(alex): be sure to set default to updated value if they go back
  const defaultRepaymentSelectValue = repaymentOptions[0];
  const [repaymentOptionValue, setRepaymentOptionValue] = useState<RepaymentOption>(
    defaultRepaymentSelectValue
  );

  const navigate = useNavigate();

  const amountInputRef = useRef<HTMLInputElement>(null);

  const onSubmit = handleSubmit((data) => {
    navigate(`/capital/${capitalAccountSummary.guid}/credit/repay/confirm`, {
      state: {
        amount: data.amount,
        repaymentBankAccountGuid: data.repaymentBankAccountTransferOption.guid,
      },
    });
  });

  const availableAfterRepayment =
    capitalAccountSummary.available + Math.min(amountOwed, amountInputValue);
  const availableAfterRepaymentPercentage = (availableAfterRepayment / limit) * 100;

  return (
    <MultiStep.Form id={REPAY_LINE_OF_CREDIT_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            How much do you want to repay?
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <Controller
          control={control}
          name="amount"
          render={({ field }) => {
            return (
              <RadioCardSelect
                options={repaymentOptions}
                value={repaymentOptionValue}
                onChange={setRepaymentOptionValue}
                rowGap={8}
              >
                {({ option, isSelected, onSelect }) => (
                  <RadioCardSimple
                    key={option}
                    value={option}
                    checked={isSelected}
                    onChange={(value) => {
                      onSelect(value);

                      if (value !== "custom") {
                        field.onChange(amountOwed * value);
                      } else {
                        // Focus & select the amount input when the "custom" option is selected
                        // NB(alex): I wasn't able to figure out how to unset the field so I opted to select & highlight the field instead
                        amountInputRef.current?.focus();
                        setTimeout(() => amountInputRef.current?.select(), 0);
                      }
                    }}
                    label={
                      typeof option === "number"
                        ? formatAmount((amountOwed * option) / 100, { withCents: true })
                        : "Custom amount"
                    }
                    helper={typeof option === "number" ? `${option * 100}%` : undefined}
                  />
                )}
              </RadioCardSelect>
            );
          }}
        />

        <Controller
          control={control}
          name="amount"
          render={({ field }) => {
            return (
              <CurrencyInput
                inputRef={amountInputRef}
                prefixValue="$"
                label="Amount"
                className={classNames(
                  repaymentOptionValue !== "custom" && styles.hideField,
                  styles.amountInput
                )}
                {...field}
              />
            );
          }}
        />

        {insufficientFunds && (
          <Helper icon={<Helper.Icon variant="error" />}>
            The amount you entered is greater than your account balance of{" "}
            {formatAmount(repaymentBankAccountTransferOptionValue.availableBalanceInDollars, {
              withCents: true,
            })}
            .
          </Helper>
        )}

        {isOverpaying && (
          <Helper icon={<Helper.Icon variant="error" />}>
            The amount you entered is greater than the amount you owe.
          </Helper>
        )}
      </MultiStep.Section>

      <MultiStep.Section className={styles.repaymentBankAccountDropdownContainer}>
        <RepaymentBankAccountDropdown control={control} />
      </MultiStep.Section>

      <Divider margin={32} />

      <MultiStep.Section>
        <Text color={colors.grey[900]} size={14} weight="medium">
          Capital available after repayment
        </Text>

        <BarChart size={8} className={styles.barChart}>
          <BarChart.Bar color="green" widthPercentage={availableAfterRepaymentPercentage} />
        </BarChart>

        <Text size={14} weight="medium">
          <MoneyAmount size={14} cents={availableAfterRepayment} />
          <Text as="span" color={colors.grey[500]}>
            {" / "}
          </Text>
          <MoneyAmount size={14} color={colors.grey[500]} cents={limit} />
        </Text>
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.NextButton
          form={REPAY_LINE_OF_CREDIT_FORM_ID}
          disabled={!amountInputValue || insufficientFunds || isOverpaying}
        >
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

const RepayLineOfCreditAmountView = () => {
  const form = useRepayLineOfCreditForm();

  return (
    <FormProvider {...form}>
      <RepayLineOfCreditAmountForm />
    </FormProvider>
  );
};

export default RepayLineOfCreditAmountView;
