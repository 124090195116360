import { ReactNode } from "react";

import styles from "./SummaryItem.module.scss";

type SummaryItemProps = {
  children: ReactNode;
};
const SummaryItem: React.FC<SummaryItemProps> = ({ children }) => (
  <div className={styles.content}>{children}</div>
);

export default SummaryItem;
