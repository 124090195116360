import { useCallback } from "react";
import { RecoilState, useRecoilState } from "recoil";

// We are using the term "Dialog" as a generic term for modals, flexpane, etc.

// eslint-disable-next-line @typescript-eslint/ban-types
export type DialogState<TOpenState extends Record<string, unknown> = {}> =
  | { isOpen: false }
  | (TOpenState & { isOpen: true });

// eslint-disable-next-line @typescript-eslint/ban-types
const useDialog = <TOpenState extends Record<string, unknown> = {}>(
  flexpaneRecoilState: RecoilState<DialogState<TOpenState>>
) => {
  const [{ isOpen }, setState] = useRecoilState(flexpaneRecoilState);

  const open = useCallback(
    (params: TOpenState) => {
      setState({ ...params, isOpen: true });
    },
    [setState]
  );

  const close = useCallback(() => {
    setState({ isOpen: false });
  }, [setState]);

  return { isOpen, open, close };
};

export default useDialog;
