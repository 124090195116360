import { CheckCircle, ShieldWarning } from "@phosphor-icons/react";
import Text from "ui/typography/Text";
import { TextSize } from "ui/typography/Text/TextTypes";

import styles from "./ConnectedIntegrationIndicator.module.scss";

type ConnectedTextV2Props = {
  size?: TextSize;
  showIndicator?: boolean;
};

const ConnectedText: React.FC<ConnectedTextV2Props> = ({ size, showIndicator }) => (
  <div className={styles.synced}>
    {showIndicator && <CheckCircle size={16} className={styles.check} />}
    <Text className={styles.text} size={size}>
      Connected
    </Text>
  </div>
);

const ReconnectionRequired: React.FC<{ size?: TextSize }> = ({ size }) => (
  <div className={styles.reconnection}>
    <ShieldWarning size={16} className={styles.shield} />
    <Text className={styles.text} size={size}>
      Reconnection required
    </Text>
  </div>
);
type ConnectedIntegrationIndicatorProps = {
  isActive: boolean;
  size?: TextSize;
  showConnectedIndicator?: boolean;
};

export const ConnectedIntegrationIndicator: React.FC<ConnectedIntegrationIndicatorProps> = ({
  isActive,
  size = 14,
  showConnectedIndicator = true,
}) => (
  <div className={styles.indicator}>
    {isActive ? (
      <ConnectedText size={size} showIndicator={showConnectedIndicator} />
    ) : (
      <ReconnectionRequired size={size} />
    )}
  </div>
);
