import {
  CurrencyOption,
  supportedInternationalWireCurrencies,
} from "pages/SendMoneyPage/internationalWires";
import { forwardRef, ForwardRefRenderFunction } from "react";
import DropdownV2, { DropdownProps } from "ui/inputs/DropdownV2";

import styles from "./MoneyInputsCurrencyDropdown.module.scss";

// NB(alex): I want to decopule this from `supportedInternationalWireCurrencies` by passing in a `currencyOptions` prop, but we can't do this until we refactor `supportedInternationalWireCurrencies` to be less coupled with app logic.
type Props = Omit<DropdownProps<CurrencyOption, false>, "options">;

const MoneyInputsCurrencyDropdown: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  props,
  ref
) => {
  const { label = "Currency", ...dropdownProps } = props;

  return (
    <DropdownV2
      ref={ref}
      label={label}
      options={supportedInternationalWireCurrencies} // NB(alex): I want to decopule this from `supportedInternationalWireCurrencies` by passing in a `currencyOptions` prop, but we can't do this until we refactor `supportedInternationalWireCurrencies` to be less coupled with app logic.
      isClearable
      hideClearIndicator
      className={styles.currencyCodeDropdown}
      {...dropdownProps}
    />
  );
};

export default forwardRef(MoneyInputsCurrencyDropdown);
