import classNames from "classnames";
import Button from "ui/inputs/Button";

import styles from "./HighbeamCard.module.scss";

type Props = {
  isPhysicalCard: boolean;
  isRevealed: boolean;
  isLoading: boolean;
  onHide: () => void;
  onReveal: () => void;
};

const HighbeamCardDetailsButton: React.FC<Props> = ({
  isPhysicalCard,
  isRevealed,
  isLoading,
  onHide,
  onReveal,
}) => {
  const onClick = () => (isRevealed ? onHide() : onReveal());

  return (
    <Button
      variant="plain"
      className={classNames(styles.detailsButton, isPhysicalCard && styles.transparent)}
      onClick={onClick}
      disabled={isLoading}
    >
      {isRevealed ? "Hide details" : "Show details"}
    </Button>
  );
};

export default HighbeamCardDetailsButton;
