import { Card } from "@highbeam/unit-node-sdk";
import { Option } from "ui/inputs/Dropdown";
import { maskAccountNumber } from "utils/account";
import { getCardName } from "utils/cards";

// NB(alex): Do not copy this file. Massaging data to be of type `Option[]` is an anti-pattern.
// This replaces the old `cardOptionsState` recoil selector.

const DEFAULT_ALL_CARDS_OPTION = {
  label: "All cards",
  value: "",
};

const getCardDropdownOptions = (cards: Card[]): Option[] => {
  const cardOptions = cards.map((card) => ({
    label: `${getCardName(card)} ${maskAccountNumber(card.attributes.last4Digits)}`,
    value: card.id,
  }));

  return [DEFAULT_ALL_CARDS_OPTION, ...cardOptions];
};

export default getCardDropdownOptions;
