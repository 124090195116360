import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";

import styles from "./AddIntegration.module.scss";

type Props = {
  title: string;
  description: string;
  addText: string;
  onAdd: () => void;
};

const AddIntegration: React.FC<Props> = ({ title, description, addText, onAdd }) => {
  const isAllowedToConnectStores = useIsAllowedToConnectStores();

  return (
    <Card className={styles.card} dashed shadow="none">
      <Text size={14} weight="bold">
        {title}
      </Text>
      <Text size={14}>{description}</Text>

      <Button
        className={styles.button}
        variant="tertiary"
        onClick={onAdd}
        disabled={!isAllowedToConnectStores}
        tooltip={
          !isAllowedToConnectStores && (
            <Text size={14}>You don’t have permission to connect stores.</Text>
          )
        }
      >
        {addText}
      </Button>
    </Card>
  );
};

export default AddIntegration;
