import { ChangeEvent, forwardRef, ForwardRefRenderFunction, InputHTMLAttributes } from "react";

import styles from "./Switch.module.scss";

type CustomProps = {
  value: boolean;
  onChange: (value: boolean, event: ChangeEvent<HTMLInputElement>) => void;
};

type BaseInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  keyof CustomProps | "type" | "checked"
>;

type Props = CustomProps & BaseInputProps;

const Switch: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { value, onChange, ...inputProps },
  ref
) => {
  return (
    <div className={styles.switch}>
      <input
        ref={ref}
        type="checkbox"
        checked={value}
        value={`${value}`}
        onChange={(e) => onChange(!value, e)}
        {...inputProps}
      />
      <span className={styles.slider}></span>
    </div>
  );
};

export default forwardRef(Switch);
