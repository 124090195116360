import classNames from "classnames";
import { FC, ReactNode } from "react";

import styles from "./FieldsetRow.module.scss";

type Props = {
  columns: 2 | 3;
  children: ReactNode;
  className?: string;
};

const FieldsetRow: FC<Props> = ({ children, className, columns }) => {
  return (
    <div className={classNames(className, styles.container, styles[`columns--${columns}`])}>
      {children}
    </div>
  );
};

export default FieldsetRow;
