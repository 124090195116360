import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useUserRoleMembershipsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["userRoleMemberships", { businessGuid }],
    queryFn: () => highbeamApi.userRoleMembership.getByBusiness(businessGuid),
  });
};

export default useUserRoleMembershipsQueryOptions;
