import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import { Suspense } from "react";
import { Navigate, useParams } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import {
  CapitalAccountSummaryProvider,
  useCapitalAccountSummaryContext,
} from "resources/capital-accounts/context/capital-account-summary-context";
import useChargeCardAccount from "resources/charge-cards/queries/useChargeCardAccount";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import ChargeCardView from "./views/ChargeCardView";
import LineOfCreditView from "./views/LineOfCreditView";
import OfferedCapitalView from "./views/OfferedCapitalView";
import UpsellCapitalView from "./views/UpsellCapitalView";

const CapitalAccountPageContent = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const chargeCardAccount = useChargeCardAccount(capitalAccountSummary.guid);

  switch (capitalAccountSummary.state) {
    case CapitalAccountRep.State.Active:
    case CapitalAccountRep.State.OfferAccepted:
      // TODO(anna): Temoporary fix! Remove once we have the logic for creating the credit account on credit offer accepted. https://linear.app/highbeam/issue/HB-4974/remove-the-temorary-fix-logic-in-capitalaccountpage-for-chargcardonly
      const showChargeCardView =
        capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardAndCashAccess ||
        (capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly &&
          Boolean(chargeCardAccount));

      return showChargeCardView ? (
        <ChargeCardView capitalAccountSummary={capitalAccountSummary} />
      ) : (
        <LineOfCreditView capitalAccountSummary={capitalAccountSummary} />
      );
    case CapitalAccountRep.State.Offered:
      return <OfferedCapitalView capitalAccountSummary={capitalAccountSummary} />;
    case CapitalAccountRep.State.Terminated:
      return <UpsellCapitalView />;
  }
};

const CapitalAccountNameBreadcrumb = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  return <Breadcrumbs.CurrentItem>{capitalAccountSummary.name}</Breadcrumbs.CurrentItem>;
};

const CapitalAccountPage = () => {
  const { capitalAccountGuid } = useParams();

  if (!capitalAccountGuid) {
    return <Navigate to="/capital" />;
  }

  return (
    <CapitalAccountSummaryProvider capitalAccountGuid={capitalAccountGuid}>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>
          <Suspense fallback={<Breadcrumbs.CurrentItem isLoading />}>
            <CapitalAccountNameBreadcrumb />
          </Suspense>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CapitalAccountPageContent />
      </DashboardPage>
    </CapitalAccountSummaryProvider>
  );
};

export default CapitalAccountPage;
