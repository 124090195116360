import TransactionsV2 from "components/TransactionsV2";
import Empty from "components/TransactionsV2/Empty";
import dayjs, { Dayjs } from "dayjs";
import { FC, Suspense } from "react";
import CapitalTransactionRep from "reps/CapitalTransactionRep";
import useDownloadCapitalAccountTransactionsCsvMutation from "resources/capital-account-transactions/mutations/useDownloadCapitalAccountTransactionsCsvMutation";
import { useCapitalAccountTransactionsQuery } from "resources/capital-account-transactions/queries/useCapitalAccountTransactions";
import Shimmer from "ui/feedback/Shimmer";
import AmountCell from "ui/table/AmountCell";
import DateTimeCell from "ui/table/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";

import CapitalAccountTransactionTransactionTypeCell from "../CapitalAccountTransactionTransactionTypeCell";

const columns: Column<CapitalTransactionRep.Complete>[] = [
  {
    title: "Date",
    cellRender: ({ date }) => {
      return <DateTimeCell date={date} />;
    },
  },
  {
    title: "Transaction type",
    cellRender: (capitalTransaction) => {
      return (
        <CapitalAccountTransactionTransactionTypeCell capitalTransaction={capitalTransaction} />
      );
    },
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: ({ amount }) => (
      <AmountCell cents={amount} direction={amount < 0 ? "negative" : "positive"} />
    ),
  },
];

// NB(alex): Experimental pattern, please ask before copying!
// TODO(alex): Convert to `Dispatch<SetStateAction<T>>` once `useSearchParam*` hooks support this.
type Control<T> = [T, (value: T) => void];

type CapitalAccountTransactionsTableProps = {
  capitalAccountGuid: string;
  sinceControl: Control<Dayjs>;
  untilControl: Control<Dayjs>;
  searchControl: Control<string>;
};

const CapitalAccountTransactionsTable: FC<CapitalAccountTransactionsTableProps> = ({
  capitalAccountGuid,
  sinceControl: [since, setSince],
  untilControl: [until, setUntil],
  searchControl: [search, setSearch],
}) => {
  const { data, isPending: isLoadingTransactions } = useCapitalAccountTransactionsQuery({
    capitalAccountGuid,
    since,
    until,
  });

  const { mutate: downloadCapitalAccountTransactions, isPending: isDownloadingTransactions } =
    useDownloadCapitalAccountTransactionsCsvMutation();

  if (!data || data.length === 0) {
    return <Empty />;
  }

  return (
    <TransactionsV2
      filters={
        <TransactionsV2.Filters
          searchBar={<TransactionsV2.Filters.SearchBar value={search} onChange={setSearch} />}
          dateRangePickers={
            <TransactionsV2.Filters.DateRangePickers
              fromPicker={
                <TransactionsV2.Filters.DateRangePickers.FromDatePicker
                  fromDate={since.toDate()}
                  toDate={until.toDate()}
                  setFromDate={(date) => setSince(dayjs(date))}
                />
              }
              toPicker={
                <TransactionsV2.Filters.DateRangePickers.ToDatePicker
                  fromDate={since.toDate()}
                  toDate={until.toDate()}
                  setToDate={(date) => setUntil(dayjs(date))}
                />
              }
            />
          }
          paginationInfo={
            <Suspense fallback={<Shimmer />}>
              <TransactionsV2.Filters.PaginationInfo count={data.length} totalCount={data.length} />
            </Suspense>
          }
          exportButton={
            <TransactionsV2.Filters.ExportButton
              isLoading={isDownloadingTransactions}
              onClick={() =>
                downloadCapitalAccountTransactions({
                  capitalAccountGuid: capitalAccountGuid,
                  sinceInclusive: since,
                  untilInclusive: until,
                })
              }
            />
          }
        />
      }
      table={
        <Table
          data={data}
          columns={columns}
          rowKey={({ unitCoId }) => unitCoId}
          isLoading={isLoadingTransactions}
        />
      }
    />
  );
};

export default CapitalAccountTransactionsTable;
