import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import CapitalAccountRep from "reps/CapitalAccountRep";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useCapitalAccountsQueryOptions from "./useCapitalAccountsQueryOptions";

const useHasActiveCapitalAccountQueryOptions = () => {
  const capitalAccountsQueryOptions = useCapitalAccountsQueryOptions();

  return useQueryOptions({
    queryKey: [...capitalAccountsQueryOptions.queryKey, "has-active-capital-account"],
    queryFn: async () => {
      const capitalAccounts = await capitalAccountsQueryOptions.queryFn();
      return capitalAccounts.some(
        (capitalAccount) => capitalAccount.state === CapitalAccountRep.State.Active
      );
    },
  });
};

export const useHasActiveCapitalAccountQuery = () => {
  return useQuery(useHasActiveCapitalAccountQueryOptions());
};

const useHasActiveCapitalAccount = () => {
  const hasCapitalAccounts = useSuspenseQuery(useHasActiveCapitalAccountQueryOptions());
  return hasCapitalAccounts;
};

export default useHasActiveCapitalAccount;
