import { FC } from "react";
import { Control, useController } from "react-hook-form";
import usePaymentEnabledBankAccountOptions from "resources/bank-accounts/queries/usePaymentEnabledBankAccountOptions";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Dropdown from "ui/inputs/Dropdown";
import Helper from "ui/inputs/Helper";

import { RepayLineOfCreditFormInputs } from "../../hooks/useRepayLineOfCreditForm";

type Props = {
  control: Control<RepayLineOfCreditFormInputs>;
};

const RepaymentBankAccountDropdown: FC<Props> = ({ control }) => {
  const transferOptions = usePaymentEnabledBankAccountOptions();

  const { field } = useController({
    control: control,
    name: "repaymentBankAccountTransferOption",
    defaultValue: transferOptions[0],
  });

  return (
    <>
      <Dropdown
        disabled={transferOptions.length === 1}
        id="from"
        label="From"
        isSearchable={false}
        options={transferOptions}
        {...field}
      />

      {field.value && (
        <Helper>
          Account balance:{" "}
          <MoneyAmount weight="medium" cents={field.value.availableBalanceInCents} />
        </Helper>
      )}
    </>
  );
};

export default RepaymentBankAccountDropdown;
