import { ControllerRenderProps } from "react-hook-form";
import { FieldPath, FieldValues } from "react-hook-form/dist/types";
import { Option } from "ui/inputs/Dropdown";

/**
 * Our dropdown component doesn't play nicely with Zod.
 * This adapter makes it play nicely
 * by mapping the types that the dropdown expects to and from the types that Zod expects.
 */
const dropdownAdapter = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  { ...field }: ControllerRenderProps<TFieldValues, TName>,
  options: Option[]
) => {
  return {
    ...field,
    options,
    value: options.find((option) => option.value === field.value) ?? null,
    onChange: (option: Option) => field.onChange(option.value),
  };
};

export default dropdownAdapter;
