import { File, FileJpg, FilePdf, FilePng, Icon, IconProps } from "@phosphor-icons/react";
import { FC } from "react";

export type FileIconExtension = "jpeg" | "jpg" | "png" | "pdf" | string;

type Props = IconProps & {
  fileExtension: FileIconExtension;
};

const getFileIconComponent = (fileExtension: FileIconExtension): Icon => {
  switch (fileExtension) {
    case "jpeg":
    case "jpg":
      return FileJpg;
    case "png":
      return FilePng;
    case "pdf":
      return FilePdf;
    default:
      return File;
  }
};

const FileIcon: FC<Props> = ({ fileExtension, size = 16, ...iconProps }) => {
  const FileIconComponent = getFileIconComponent(fileExtension);
  return <FileIconComponent size={size} {...iconProps} />;
};

export default FileIcon;
