import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const makeQueryKey = (businessGuid: string) => ["emailAliases", { businessGuid }];

const useApEmailAliasesQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: makeQueryKey(businessGuid),
    queryFn: () => {
      return highbeamApi.apEmailAlias.search(businessGuid);
    },
  });
};

const useApEmailAliases = () => {
  const { data } = useSuspenseQuery(useApEmailAliasesQueryOptions());

  return data;
};

export default useApEmailAliases;
