import ModalV3 from "ui/overlay/ModalV3";

import { useConfirmationModal, useConfirmationModalState } from "./state/confirmationModalState";

const CONFIRMATION_MODAL_FORM_ID = "confirmation-modal-form";

const ConfirmationModal = () => {
  const state = useConfirmationModalState();
  const { close: closeConfirmationModal } = useConfirmationModal();

  const onClose = () => {
    closeConfirmationModal();
    state.isOpen && state.resolvePromise(false); // When confirmed, promise gets resolved to `true` before it gets resolved to `false` here. Not sure if there's a cleaner way to do this but leaving this note here for clarity saying this is intentional.
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    closeConfirmationModal();
    state.isOpen && state.resolvePromise(true);
  };

  return (
    <ModalV3 isOpen={state.isOpen} onClose={onClose}>
      <form id={CONFIRMATION_MODAL_FORM_ID} onSubmit={onSubmit}>
        {state.isOpen && (
          <>
            <ModalV3.Header onClose={onClose}>{state.heading}</ModalV3.Header>

            <ModalV3.Body>
              <ModalV3.Body.Text>{state.message}</ModalV3.Body.Text>
            </ModalV3.Body>

            <ModalV3.Footer onClose={onClose}>
              <ModalV3.Footer.SubmitButton
                variant={state.variant}
                form={CONFIRMATION_MODAL_FORM_ID}
              >
                {state.buttonLabel}
              </ModalV3.Footer.SubmitButton>
            </ModalV3.Footer>
          </>
        )}
      </form>
    </ModalV3>
  );
};

export default ConfirmationModal;
