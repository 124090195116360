import classNames from "classnames";
import { FC } from "react";
import colors from "styles/colors";
import Heading2 from "ui/typography/Heading2";
import Text from "ui/typography/Text";

import styles from "./MultiStepSection.module.scss";

export const MultiStepSectionHeaderHeading: React.FC<React.ComponentProps<typeof Heading2>> = ({
  ...headingProps
}) => <Heading2 className={styles.heading} {...headingProps} />;

export const MultiStepSectionHeaderSubheading: React.FC<React.ComponentProps<typeof Text>> = ({
  className,
  ...textProps
}) => (
  <Text
    size={14}
    color={colors.grey[700]}
    className={classNames(styles.subheading, className)}
    {...textProps}
  />
);

export const MultiStepSectionSpacer = () => <div className={styles.spacer} />;

type Props = React.PropsWithChildren<{
  className?: string;
}>;

export const MultiStepSectionHeader: React.FC<Props> = ({ className, children }) => (
  <div className={classNames(styles.header, className)}>{children}</div>
);

type MultiStepSectionProps = JSX.IntrinsicElements["div"];

const MultiStepSection: FC<MultiStepSectionProps> = ({ className, children, ...divProps }) => (
  <div className={classNames(className, styles.container)} {...divProps}>
    {children}
  </div>
);

export default Object.assign(MultiStepSection, {
  Header: Object.assign(MultiStepSectionHeader, {
    Heading: MultiStepSectionHeaderHeading,
    Subheading: MultiStepSectionHeaderSubheading,
  }),
  Spacer: MultiStepSectionSpacer,
});
