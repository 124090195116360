import { LockLaminated } from "@phosphor-icons/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useChatWidget } from "components/ChatWidget";
import HighbeamCard from "components/HighbeamCard";
import CardLimit from "pages/cards/CardsPage/CardBanners/CardLimits";
import useUnitCoAccountLimitQueryOptions from "resources/unit-co-account-limits/queries/useUnitCoAccountLimitQueryOptions";
import Banner from "ui/data-display/Banner";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import { getCardType, isChargeCard } from "utils/cards";

import { useCardDetailsPageContext } from "../providers/CardDetailsPageProvider";

import styles from "./CardDetailsInfo.module.scss";
import ChargeCardDetails from "./ChargeCardDetails";
import DebitCardDetails from "./DebitCardDetails";

const CardDetailsInfo = () => {
  const { card } = useCardDetailsPageContext();

  const chat = useChatWidget();
  const { data: accountLimits } = useSuspenseQuery(
    useUnitCoAccountLimitQueryOptions(card.relationships.account.data.id || "")
  );

  return (
    <div className={styles.detailsContainer}>
      <div className={styles.title}>
        <h2>Card info</h2>
      </div>
      {accountLimits && (
        <div className={styles.bannerContainer}>
          <CardLimit cards={[card]} />
        </div>
      )}
      {card.attributes.status === "SuspectedFraud" && (
        <div className={styles.bannerContainer}>
          <Banner
            icon={<LockLaminated size={16} />}
            color={"red"}
            title={
              <Text size={14} weight="bold">
                Card is locked for unusual activity
              </Text>
            }
            body={
              <Text size={14}>
                Your card has been flagged and locked due to potential unusual activity. Please
                check your email for notices from Visa to review these transactions and reactivate
                your card. If you have any questions or would like Highbeam to reactivate your card,
                please contact support.
              </Text>
            }
            footer={
              <Button variant="tertiary" onClick={() => chat.show()}>
                Contact support
              </Button>
            }
          />
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.cardContainer}>
          <HighbeamCard
            card={card}
            areDetailsAvailable={
              card.attributes.status !== "Inactive" && card.attributes.status !== "ClosedByCustomer"
            }
            singleCardDetailedView
          />
        </div>
        {isChargeCard(getCardType(card)) ? <ChargeCardDetails /> : <DebitCardDetails />}
      </div>
    </div>
  );
};

export default CardDetailsInfo;
