import { RecurringPayment } from "@highbeam/unit-node-sdk";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import { useNavigate } from "react-router-dom";
import useOpenBankAccounts from "resources/bank-accounts/queries/useOpenBankAccounts";
import isRecurring from "resources/unit-co-recurring-payments/utils/isRecurring";
import BankAccount from "ui/data-display/BankAccount";
import Pill from "ui/data-display/Pill";
import AmountCell from "ui/table/AmountCell";
import DateCell from "ui/table/DateCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import { getAccountDisplayName } from "utils/account";

import RecurringPaymentCounterparty from "../RecurringPaymentCounterparty";

import styles from "./RecurringPaymentsTable.module.scss";

type RecurringPaymentsTableProps = {
  isLoading: boolean;
  recurringPayments: RecurringPayment[];
};

const RecurringPaymentsTable: React.FC<RecurringPaymentsTableProps> = ({
  isLoading,
  recurringPayments,
}) => {
  const bankAccounts = useOpenBankAccounts();
  const navigate = useNavigate();

  const TRANSACTION_COLUMNS = {
    ACCOUNT: {
      title: "Account",
      cellTextWeight: "medium",
      key: "accountName",
      cellRender: (payment: RecurringPayment) => {
        const bankAccount = bankAccounts.find(
          (account) => account.unitCoDepositAccountId === payment.relationships.account.data.id
        );
        return (
          <BankAccount
            accountName={bankAccount ? getAccountDisplayName(bankAccount) : ""}
            shortMethodName={payment.type === "recurringCreditAchPayment" ? "ACH" : "Book"}
          />
        );
      },
    },
    AMOUNT: {
      title: "Amount",
      align: TableColumnAlignment.RIGHT,
      cellRender: (payment: RecurringPayment) => (
        <AmountCell cents={payment.attributes.amount} direction="negative" />
      ),
      cellTextNumeric: true,
    },
    TO: {
      title: "To",
      cellRender: (payment: RecurringPayment) => <RecurringPaymentCounterparty payment={payment} />,
    },
    DATE: {
      title: "Date",
      cellRender: (payment: RecurringPayment) => (
        <DateCell date={payment.attributes.schedule.nextScheduledAction} />
      ),
    },
    TYPE: {
      title: "Type",
      cellRender: (payment: RecurringPayment) => {
        if (isRecurring(payment)) {
          const interval = payment.attributes.schedule.interval?.toLowerCase();
          const totalPayments = payment.attributes.schedule.totalNumberOfPayments;
          const completedPayments = payment.attributes.numberOfPayments;

          return (
            <div className={styles.flex}>
              <Text weight="medium" size={14}>
                Recurring {interval}
              </Text>
              <div className={styles.marginLeft}>
                <Pill color="purple-light">
                  {totalPayments
                    ? `${completedPayments}/${totalPayments} completed`
                    : "Until canceled"}
                </Pill>
              </div>
            </div>
          );
        } else {
          return (
            <div className={styles.flex}>
              <Text weight="medium" size={14}>
                One time payment
              </Text>
            </div>
          );
        }
      },
    },
  };

  const columns: Column<RecurringPayment>[] = [
    TRANSACTION_COLUMNS["DATE"],
    TRANSACTION_COLUMNS["TO"],
    TRANSACTION_COLUMNS["TYPE"],
    TRANSACTION_COLUMNS["ACCOUNT"],
    TRANSACTION_COLUMNS["AMOUNT"],
  ];

  return (
    <Table
      columns={columns}
      isLoading={isLoading}
      isLoadingRows={10}
      data={recurringPayments}
      rowKey={(recurringPayment) => recurringPayment.id}
      cellClassName={styles.cell}
      onRowClick={(recurringPayment) =>
        navigate(`/payments/scheduled-payments/${recurringPayment.id}`, {
          state: DISABLE_SCROLL_TO_TOP_STATE,
        })
      }
    />
  );
};

export default RecurringPaymentsTable;
