import moneyInOut from "assets/money-in-out.svg";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import HighbeamAccountIcon, {
  HighbeamAccountTypeName,
} from "resources/bank-accounts/components/HighbeamAccountIcon";
import useCancelPaymentMutation from "resources/payment-approvals/mutations/useCancelPaymentMutation";
import colors from "styles/colors";
import BankAccountV2 from "ui/data-display/BankAccountV2";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Pill from "ui/data-display/Pill";
import ModalV3 from "ui/overlay/ModalV3";
import Text from "ui/typography/Text";

import styles from "./CancelPaymentModal.module.scss";
import cancelPaymentModalState, { useCancelPaymentModal } from "./state/cancelPaymentModalState";

const CANCEL_PAYMENT_FORM = "cancel-payment-form";

type PaymentDirection = "Incoming" | "Outgoing";

type CancelSummaryMessageProps = {
  paymentDirection: PaymentDirection;
  counterpartyName: string;
  paymentAmountInCents: number;
  accountName: string;
  accountNumber: string;
  accountTypeName: HighbeamAccountTypeName;
};

const CancelSummaryMessage: FC<CancelSummaryMessageProps> = ({
  accountName,
  accountNumber,
  accountTypeName,
  counterpartyName,
  paymentDirection,
  paymentAmountInCents,
}) => {
  if (paymentDirection === "Outgoing") {
    return (
      <>
        <div className={styles.transactionCancelCard}>
          <div className={styles.transactionCancelCardSummary}>
            <img src={moneyInOut} alt="money icon" />
            <Text size={16} color={colors.grey[600]}>
              <MoneyAmount weight={"medium"} cents={paymentAmountInCents} /> payment to{" "}
              <Text as="span" size={16} weight="medium" color={colors.grey[600]}>
                {counterpartyName}
              </Text>
            </Text>
          </div>
          <Pill color="grey-light" bordered>
            Pending
          </Pill>
        </div>
        <Text color={colors.grey[600]} size={16} weight="regular" className={styles.disclaimer}>
          After canceling, the funds will be returned to
          <BankAccountV2
            as="span"
            bankIcon={<HighbeamAccountIcon highbeamTypeName={accountTypeName} />}
            iconSize={20}
            accountName={accountName}
            accountNumber={accountNumber}
          />
        </Text>
      </>
    );
  } else {
    return (
      <>
        <div className={styles.transactionCancelCard}>
          <div className={styles.transactionCancelCardSummary}>
            <img src={moneyInOut} alt="money icon" />
            <Text size={16} color={colors.grey[600]}>
              <MoneyAmount weight={"medium"} cents={paymentAmountInCents} /> payment from{" "}
              <Text as="span" size={16} weight="medium" color={colors.grey[600]}>
                {counterpartyName}
              </Text>
            </Text>
          </div>
          <Pill color="grey-light" bordered>
            Pending
          </Pill>
        </div>
        <Text color={colors.grey[600]} size={16} weight="regular" className={styles.disclaimer}>
          After canceling, the funds will no longer be deposited into
          <BankAccountV2
            as="span"
            bankIcon={<HighbeamAccountIcon highbeamTypeName={accountTypeName} />}
            iconSize={20}
            accountName={accountName}
            accountNumber={accountNumber}
          />
        </Text>
      </>
    );
  }
};

type Props = {
  onSuccess: () => void;
  paymentDirection: PaymentDirection;
};

const CancelPaymentModal: FC<Props> = ({ onSuccess, paymentDirection }) => {
  const { close: onClose } = useCancelPaymentModal();
  const { mutateAsync: cancelPayment, isPending } = useCancelPaymentMutation();

  const state = useRecoilValue(cancelPaymentModalState);

  return (
    <ModalV3 isOpen={state.isOpen} onClose={onClose}>
      {state.isOpen && (
        <form
          id={CANCEL_PAYMENT_FORM}
          onSubmit={async (e) => {
            e.preventDefault();
            await cancelPayment({ paymentGuid: state.paymentGuid });
            onSuccess();
          }}
        >
          <ModalV3.Header onClose={onClose}>Cancel payment?</ModalV3.Header>

          <ModalV3.Body>
            <CancelSummaryMessage
              accountName={state.account.name}
              accountNumber={state.account.accountNumber}
              accountTypeName={state.account.highbeamType.name}
              counterpartyName={state.counterpartyName}
              paymentDirection={paymentDirection}
              paymentAmountInCents={state.paymentAmountInCents}
            />
          </ModalV3.Body>

          <ModalV3.Footer>
            <ModalV3.Footer.SubmitButton
              variant="danger"
              form={CANCEL_PAYMENT_FORM}
              isLoading={isPending}
            >
              Cancel payment
            </ModalV3.Footer.SubmitButton>
            <ModalV3.Footer.CloseButton onClick={onClose}>Close</ModalV3.Footer.CloseButton>
          </ModalV3.Footer>
        </form>
      )}
    </ModalV3>
  );
};

export default CancelPaymentModal;
