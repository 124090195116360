import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useMfa from "modules/mfa/useMfa";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import useRefreshCapitalAccountsQueries from "resources/capital-accounts/queries/useRefreshCapitalAccountsQueries";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import { LINE_OF_CREDIT_AGREEMENT_QUERY_KEY } from "resources/line-of-credit-agreement/queries/useLineOfCreditAgreementQueryOptions";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { bankingDay } from "utils/date";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const useAcceptCreditOfferMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const userGuid = useUserGuid();
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const refreshCapitalAccountsQueries = useRefreshCapitalAccountsQueries();
  const refreshLineAgreement = useRefreshQuery([
    LINE_OF_CREDIT_AGREEMENT_QUERY_KEY,
    businessGuid,
    capitalAccountSummary.guid,
  ]);

  const { mfa } = useMfa();

  return useMutation({
    mutationFn: async () => {
      await mfa();
      await highbeamApi.lineOfCredit.acceptLineOfCreditOffer(
        businessGuid,
        capitalAccountSummary.guid,
        userGuid
      );
      await highbeamApi.lineOfCredit.updateLineOfCreditUserActionsMetadata(
        businessGuid,
        capitalAccountSummary.guid,
        {
          agreementSignedAt: bankingDay().toISOString(),
        }
      );
      await refreshCapitalAccountsQueries();
    },
    onError: (error) => {
      captureException(error);
      notify("info", "Failed to accept the credit offer. Please try again.");
    },
    onSuccess: async () => {
      await refreshLineAgreement();
    },
  });
};

export default useAcceptCreditOfferMutation;
