import classNames from "classnames";
import { cloneElement, FC, ReactElement, ReactNode } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import { HighlightCardSideRuleColor } from "../types";

import styles from "./HighlightCardHeader.module.scss";

const ICON_COLORS = {
  default: colors.purple[500],
  warning: colors.yellow[700],
} satisfies { [key in HighlightCardSideRuleColor]: string };

type Props = {
  sideRuleColor?: HighlightCardSideRuleColor;
  icon: ReactElement<{ size: number; color: string }>;
  children?: ReactNode;
  actions?: ReactNode;
};

const HighlightCardHeader: FC<Props> = ({ icon, sideRuleColor = "default", children, actions }) => {
  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        {icon && (
          <div className={classNames(styles.iconWrapper, styles[sideRuleColor])}>
            {cloneElement(icon, { size: icon.props.size ?? 24, color: ICON_COLORS[sideRuleColor] })}
          </div>
        )}

        <Text color={colors.purple[800]} weight="bold" size={16}>
          {children}
        </Text>
      </div>

      {actions}
    </div>
  );
};

export default HighlightCardHeader;
