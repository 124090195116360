import { Card, CardLimits } from "@highbeam/unit-node-sdk";
import pencilIcon from "assets/pencil.svg";
import { BudgetType } from "pages/cards/CardsPage/CreateCardModal/utils";
import { useState } from "react";
import useEditCardLimitMutation from "resources/cards/mutations/useEditCardLimitMutation";
import { notify } from "ui/feedback/Toast";
import TextInput from "ui/inputs/TextInput";
import Modal from "ui/overlay/Modal";
import { getCardName, getCardType } from "utils/cards";
import { getDollarsFromCents, parseMoneyFloat } from "utils/money";

import CardBudgetManager from "../CardDetailsInfo/CardBudgetManager";
import CardModalInfo from "../CardModalInfo";

import styles from "./EditCardModal.module.scss";

type Props = {
  closeModal: () => void;
  card: Card;
  cardLimit: CardLimits;
};

const EditCardModal: React.FC<Props> = ({ closeModal, card, cardLimit }) => {
  const [cardName, setCardName] = useState(getCardName(card));

  const [dailyLimit, setDailyLimit] = useState<number | null>(
    cardLimit.attributes.limits?.dailyPurchase !== undefined
      ? getDollarsFromCents(cardLimit.attributes.limits.dailyPurchase)
      : null
  );
  const [monthlyLimit, setMonthlyLimit] = useState<number | null>(
    cardLimit.attributes.limits?.monthlyPurchase !== undefined
      ? getDollarsFromCents(cardLimit.attributes.limits.monthlyPurchase)
      : null
  );
  const [budgetType, setBudgetType] = useState<BudgetType>(() =>
    dailyLimit ? "Daily" : "Monthly"
  );

  const dailyPurchases = cardLimit.attributes.dailyTotals
    ? getDollarsFromCents(cardLimit.attributes.dailyTotals.purchases)
    : null;

  const monthlyPurchases = cardLimit.attributes.monthlyTotals
    ? getDollarsFromCents(cardLimit.attributes.monthlyTotals.purchases)
    : null;

  const [newLimitExceedsPurchases, setNewLimitExceedsPurchases] = useState(false);

  const updateBudgetLimit = (val: string) => {
    const parsedValue = parseMoneyFloat(val) ? parseMoneyFloat(val) : null;
    if (budgetType === "Daily") {
      setDailyLimit(parsedValue);
      setMonthlyLimit(null);
      determineWarningBannerState(dailyPurchases, parsedValue);
    } else if (budgetType === "Monthly") {
      setMonthlyLimit(parsedValue);
      setDailyLimit(null);
      determineWarningBannerState(monthlyPurchases, parsedValue);
    }
  };

  const determineWarningBannerState = (purchases: number | null, newLimit: number | null) => {
    if (purchases === null || newLimit === null) return;

    if (purchases > newLimit) {
      setNewLimitExceedsPurchases(true);
    } else {
      setNewLimitExceedsPurchases(false);
    }
  };

  const { mutateAsync: editCard, isPending } = useEditCardLimitMutation(card.id);

  const onSubmit = async () => {
    const limit = budgetType === "Daily" ? dailyLimit : monthlyLimit;

    try {
      await editCard({
        cardName: cardName,
        cardType: getCardType(card),
        budgetType: budgetType,
        limit: limit,
      });
      notify("success", "Card updated");
      closeModal();
    } catch (e) {
      notify("error", "Something went wrong! Please try again.");
    }
  };

  return (
    <Modal
      icon={<img alt="Edit icon" src={pencilIcon} />}
      title="Edit card details"
      showCancel
      buttonText="Save changes"
      isLoading={isPending}
      isPrimaryButtonDisabled={isPending || newLimitExceedsPurchases}
      isCancelButtonDisabled={isPending}
      onClick={onSubmit}
      onClose={() => closeModal()}
    >
      <div className={styles.editCardForm}>
        <CardModalInfo card={card} />
        <TextInput
          label={cardName ? "Card name" : undefined}
          value={cardName}
          onChange={(name) => setCardName(name)}
          placeholder="Give this card a name"
        />
        <CardBudgetManager
          dailyLimit={dailyLimit}
          setDailyLimit={setDailyLimit}
          dailyPurchases={dailyPurchases}
          monthlyLimit={monthlyLimit}
          setMonthlyLimit={setMonthlyLimit}
          monthlyPurchases={monthlyPurchases}
          budgetType={budgetType}
          setBudgetType={setBudgetType}
          updateBudgetLimit={updateBudgetLimit}
          isLoading={isPending}
          newLimitExceedsPurchases={newLimitExceedsPurchases}
          setNewLimitExceedsPurchases={setNewLimitExceedsPurchases}
        />
      </div>
    </Modal>
  );
};

export default EditCardModal;
