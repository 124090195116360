import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { BudgetType } from "pages/cards/CardsPage/CreateCardModal/utils";
import DebitCardRep from "reps/DebitCardRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useCardLimitQueryOptions from "../queries/useCardLimitQueryOptions";
import useCardQueryOptions from "../queries/useCardQueryOptions";

const getPurchaseLimitParams = (
  budgetType: BudgetType,
  limit: number | null
): Pick<DebitCardRep.Update, "dailyPurchaseLimit" | "monthlyPurchaseLimit"> => {
  if (!limit) {
    return {
      dailyPurchaseLimit: null,
      monthlyPurchaseLimit: null,
    };
  }

  switch (budgetType) {
    case "Daily":
      return {
        dailyPurchaseLimit: limit * 100,
        monthlyPurchaseLimit: null,
      };
    case "Monthly":
      return {
        dailyPurchaseLimit: null,
        monthlyPurchaseLimit: limit * 100,
      };
  }
};

type Variables = {
  cardType: DebitCardRep.CardType;
  cardName: string;
  budgetType: BudgetType;
  limit: number | null;
};

const useEditCardLimitMutation = (cardId: string) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const refreshCardQuery = useRefreshQuery(useCardQueryOptions(cardId).queryKey);
  const refreshCardLimitQuery = useRefreshQuery(useCardLimitQueryOptions(cardId).queryKey);

  return useMutation({
    mutationFn: async ({ cardType, cardName, budgetType, limit }: Variables) => {
      return highbeamApi.debitCard.update(businessGuid, cardId, {
        type: cardType,
        name: cardName,
        ...getPurchaseLimitParams(budgetType, limit),
      });
    },
    onError: (err) => {
      captureException(err);
    },
    onSuccess: () => {
      refreshCardQuery();
      refreshCardLimitQuery();
    },
  });
};

export default useEditCardLimitMutation;
