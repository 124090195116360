import { captureMessage } from "@sentry/react";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useIsSuperusering } from "state/auth/isSuperusering";
import { notify } from "ui/feedback/Toast";

import isMfaCompleteState from "./isMfaCompleteState";
import isMfaInProgressState from "./isMfaInProgressState";

export class MfaCanceledError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "MfaCanceledError";
    Object.setPrototypeOf(this, MfaCanceledError.prototype);
  }
}

type Callback = {
  resolve: () => void;
  reject: (error: MfaCanceledError) => void;
};

type Mfa = {
  isMfaComplete: boolean;

  /**
   * When this function is called, it returns a promise that is resolved when MFA is completed. If
   * MFA is already complete, it resolves immediately. If MFA is cancelled by the user, it rejects
   * the promise
   */
  mfa(): Promise<void>;
};

const useMfa = (): Mfa => {
  const isMfaComplete = useRecoilValue(isMfaCompleteState);

  const [isMfaInProgress, setIsMfaInProgress] = useRecoilState(isMfaInProgressState);
  const isSuperusering = useIsSuperusering();

  const [callback, setCallback] = useState<Callback>();

  // When MFA finishes, call the callback.
  useEffect(() => {
    if (!isMfaComplete || !callback) return;
    captureMessage("Debugging MFA(alex): MFA is complete, resolving callback.");
    setIsMfaInProgress(false);
    callback.resolve();
    setCallback(undefined);
  }, [callback, isMfaComplete, setIsMfaInProgress]);

  // When MFA is no longer in progress, clear the callback.
  useEffect(() => {
    if (isMfaComplete || isMfaInProgress || !callback) return;
    captureMessage("Debugging MFA(alex): MFA was canceled, rejecting callback");
    callback.reject(new MfaCanceledError());
    setCallback(undefined);
  }, [isMfaComplete, callback, isMfaInProgress]);

  const mfa = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        if (isSuperusering) {
          notify("error", "Cannot perform MFA-protected actions as a superuser.");
          reject(new MfaCanceledError());
          return;
        }
        if (isMfaComplete) {
          // Don't MFA if it's already done.
          resolve();
          return;
        }
        captureMessage("Debugging MFA(alex): MFA modal triggered"); // NB(alex): Temporary log to debug MFA modal for linking to the Sentry recording any time the MFA modal is triggered.
        setCallback({ resolve, reject });
        setIsMfaInProgress(true); // Triggers MFA by showing the modal.
      }),
    [isMfaComplete, isSuperusering, setIsMfaInProgress]
  );

  return { isMfaComplete, mfa };
};

export default useMfa;
