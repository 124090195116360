import { Calculator } from "@phosphor-icons/react";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { Link } from "react-router-dom";
import Button from "ui/inputs/Button";

const CreateYourOwnButton = () => (
  <Link to={`${CREDIT_COMPARISON_PATH}`}>
    <Button variant="tertiary">
      <Calculator size={24} />
      Create your own
    </Button>
  </Link>
);

export default CreateYourOwnButton;
