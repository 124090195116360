import { useContext } from "react";
import SectionHeader from "ui/data-display/SectionHeader";
import Fieldset from "ui/inputs/Fieldset";
import TextInput from "ui/inputs/TextInput";
import Heading3 from "ui/typography/Heading3";

import { CreatePayeeFormContext } from "./CreatePayeeForm";
import style from "./CreatePayeePage.module.scss";

const PayeeInfoSection = () => {
  const form = useContext(CreatePayeeFormContext);

  return (
    <>
      <SectionHeader>
        <Heading3>Payee info</Heading3>
      </SectionHeader>
      <Fieldset width="narrow">
        <TextInput label="Name" className={style.span2} maxLength={60} {...form.payee.name} />
        <TextInput label="Email (optional)" className={style.span2} {...form.payee.emailAddress} />
        <TextInput
          label="Phone number (optional)"
          className={style.span2}
          {...form.payee.phoneNumber}
        />
      </Fieldset>
    </>
  );
};

export default PayeeInfoSection;
