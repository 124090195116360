import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import checkIcon from "assets/check-icon-green-circle.svg";
import useDisconnectRutterConnectionMutation from "pages/capital/CreditApplicationPage/hooks/useDisconnectRutterConnectionMutation";
import { FC } from "react";
import RutterConnectionRep from "reps/RutterConnectionRep";
import useAccountingPlatformConnectionsQueryOptions from "resources/accounting-platforms/queries/useAccountingPlatformConnectionsQueryOptions";
import { useIsSuperusering } from "state/auth/isSuperusering";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";

import AccountingSoftwareCard from "./AccountingSoftwareCard";
import styles from "./AccountingSoftwareCard.module.scss";

type AccountingConnectProps = {
  type: RutterConnectionRep.RutterAccountingPlatform;
  openModal: () => void;
};

const AccountingPlatformConnectionCard: FC<AccountingConnectProps> = ({ openModal, type }) => {
  const { data: accountingPlatformConnections = [] } = useQuery(
    useAccountingPlatformConnectionsQueryOptions()
  );
  const { mutate } = useDisconnectRutterConnectionMutation();
  const isSuperusering = useIsSuperusering();

  const connectionForType = accountingPlatformConnections.find(
    (connection) => connection.platformName === type
  );
  const isAccountingPlatformConnected = Boolean(connectionForType);
  const handleDisconnect = () => {
    if (!connectionForType) {
      captureException(new Error("No Quickbooks connection found"));
      return;
    }
    mutate(connectionForType.guid);
  };
  const cardDescription = isAccountingPlatformConnected ? (
    <div className={styles.descriptionConnected}>
      <img src={checkIcon} alt="check-icon"></img>
      <Text size={14} color={colors.grey[600]}>
        Connected
      </Text>
    </div>
  ) : (
    <Text size={14} color={colors.grey[600]}>
      {`Allow Highbeam to view your ${getAccountingSoftwareName(type)} financials.`}
    </Text>
  );
  return (
    <AccountingSoftwareCard
      type={type}
      description={cardDescription}
      addText={
        isAccountingPlatformConnected
          ? isAccountingPlatformConnected && isSuperusering
            ? "[SUPERUSER] Disconnect"
            : "Disconnect"
          : "Connect"
      }
      onButtonClick={isAccountingPlatformConnected ? handleDisconnect : openModal}
      isAccountingPlatformConnected={isAccountingPlatformConnected}
    />
  );
};

export default AccountingPlatformConnectionCard;
