import { ChatTeardropDots as ChatTeardropDotsIcon } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import { FC } from "react";
import useCurrentApEmailAddress from "resources/ap-email-aliases/queries/useCurrentApEmailAddress";
import CopyLink from "ui/data-display/CopyLink";
import Button from "ui/inputs/Button";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";

import styles from "./EmailSettingsModal.module.scss";

type EmailSettingsModalContentProps = {
  onClose: () => void;
};

const EmailSettingsModalContent: FC<EmailSettingsModalContentProps> = ({ onClose }) => {
  const currentApEmailAddress = useCurrentApEmailAddress();
  const chat = useChatWidget();

  const showChat = () => {
    chat.show();
    // The Intercom chat widget is behind the modal, so we need to close.
    onClose();
  };

  return (
    <>
      <ModalV4.Header>Email inbox settings</ModalV4.Header>
      <ModalV4.Body className={styles.bodyContainer}>
        {currentApEmailAddress ? (
          <>
            <ModalV4.Body.Text as="p">
              Send invoices to this email address and they will show in the &ldquo;Email
              inbox&rdquo; tab for you to review. You can also share them with your payees so they
              can send invoices too.
            </ModalV4.Body.Text>
            <div className={styles.emailAddressPaneContainer}>
              <div className={styles.emailAddressField}>
                <Text size={14} weight="medium" as="h3">
                  Email address
                </Text>
                <CopyLink link={currentApEmailAddress} tooltipContent="Copy email address" />
              </div>
              <div className={styles.emailAddressPaneActionsContainer}>
                <ModalV4.Body.Text size={14} as="p">
                  Need to change your email?
                </ModalV4.Body.Text>
                <Button size="sm" onClick={() => showChat()}>
                  <ChatTeardropDotsIcon size={16} />
                  Talk to support
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <ModalV4.Body.Text as="p">
              You do not currently have an email address set up to receive invoices. Please contact
              support to set one up.
            </ModalV4.Body.Text>
            <Button variant="tertiary" onClick={() => showChat()}>
              <ChatTeardropDotsIcon size={20} />
              Talk to support
            </Button>
          </>
        )}
      </ModalV4.Body>
      <ModalV4.Footer>
        <ModalV4.Footer.CloseButton>Close</ModalV4.Footer.CloseButton>
      </ModalV4.Footer>
    </>
  );
};

type Props = {
  onClose: () => void;
};

const EmailSettingsModal: FC<Props> = ({ onClose }) => {
  return (
    <ModalV4 onClose={onClose}>
      <EmailSettingsModalContent onClose={onClose} />
    </ModalV4>
  );
};

export default EmailSettingsModal;
