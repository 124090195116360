import classNames from "classnames";
import Text from "ui/typography/Text";
import { TextSize, TextWeight } from "ui/typography/Text/TextTypes";

import styles from "./Address.module.scss";

export type AddressProps = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
};
type Props = {
  address: AddressProps;
  size: TextSize;
  weight?: TextWeight;
  className?: string;
};

const Address: React.FC<Props> = ({ address, className, ...props }) => (
  <div className={classNames(styles.container, className)}>
    <Text {...props}>
      {address.addressLine2
        ? `${address.addressLine1}, ${address.addressLine2}`
        : `${address.addressLine1}`}{" "}
    </Text>
    <Text {...props}>{`${address.city}, ${address.state} ${address.zipCode}`}</Text>
  </div>
);

export default Address;
