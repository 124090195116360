import PaymentDetailsRep from "reps/PaymentDetailsRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class PaymentDetailsApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async batchGet(
    businessGuid: string,
    paymentMetadataGuids: string[]
  ): Promise<PaymentDetailsRep.Complete[]> {
    const url = `/payment-details/batch-get`;
    const body = { businessGuid, paymentMetadataGuids };
    return (await this.api.post<PaymentDetailsRep.Complete[]>(url, body))!;
  }

  async getPaymentReceipt(businessGuid: string, paymentMetadataGuid: string): Promise<string> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/payment-details/${paymentMetadataGuid}/receipt?${queryParams.toString()}`;

    return (await this.api.get<string>(url))!;
  }
}
