import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import BankAccountRep from "reps/BankAccountRep";

import useBankAccountsQueryOptions from "./useBankAccountsQueryOptions";

export const useOpenBankAccountsQueryOptions = () => {
  return useBankAccountsQueryOptions({
    statuses: [BankAccountRep.Status.OPEN, BankAccountRep.Status.FROZEN],
  });
};

export const useOpenBankAccountsQuery = () => {
  return useQuery(useOpenBankAccountsQueryOptions());
};

const useOpenBankAccounts = () => {
  return useSuspenseQuery(useOpenBankAccountsQueryOptions()).data;
};

export default useOpenBankAccounts;
