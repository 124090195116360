import DashboardPage from "components/layouts/DashboardPage";
import { DEFAULT_MONTHS_SINCE } from "config/constants/filters";
import dayjs from "dayjs";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountTransactionsTable from "resources/capital-account-transactions/components/CapitalAccountTransactionsTable";
import useSearchParamDayjs from "utils/searchParams/useSearchParamDayjs";
import useSearchParamValue from "utils/searchParams/useSearchParamValue";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountCreditTransactionsSection: FC<Props> = ({ capitalAccountSummary }) => {
  const [since, setSince] = useSearchParamDayjs(
    "since",
    dayjs().subtract(DEFAULT_MONTHS_SINCE, "months")
  );
  const [until, setUntil] = useSearchParamDayjs("until", dayjs());
  const [search, setSearch] = useSearchParamValue("search", "");

  if (capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>
          {capitalAccountSummary.type === CapitalAccountRep.Type.CashAccessOnly
            ? "Line of credit transactions"
            : "Cash access line transactions"}
        </DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <CapitalAccountTransactionsTable
        capitalAccountGuid={capitalAccountSummary.guid}
        sinceControl={[since, setSince]}
        untilControl={[until, setUntil]}
        searchControl={[search, setSearch]}
      />
    </DashboardPage.Section>
  );
};

export default CapitalAccountCreditTransactionsSection;
