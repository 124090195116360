import classNames from "classnames";
import React, { ComponentProps, ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import Shimmer from "ui/feedback/Shimmer";
import Text from "ui/typography/Text";

import styles from "./Breadcrumbs.module.scss";

const BreadcrumbShimmer = () => (
  <Shimmer additionalShimmerWrapperClassName={classNames(styles.item, styles.shimmer)} />
);

const BreadcrumbItemShimmer = () => (
  <>
    <BreadcrumbShimmer />
    <span className={styles.backslash}>/</span>
  </>
);

const BreadcrumbCurrentItemShimmer = () => <BreadcrumbShimmer />;

type BaseItemProps = {
  children?: ReactNode;
  isLoading?: boolean;
};

type ItemProps = BaseItemProps & {
  to: ComponentProps<typeof Link>["to"];
};

const Item: React.FC<ItemProps> = ({ isLoading, children, to }) => {
  if (isLoading) {
    return <BreadcrumbItemShimmer />;
  }

  return (
    <>
      <Text as={"span"} className={classNames(styles.item)}>
        <Link to={to}>{children}</Link>
      </Text>
      <span className={styles.backslash}>/</span>
    </>
  );
};

type CurrentItemProps = BaseItemProps;

const CurrentItem: React.FC<CurrentItemProps> = ({ children, isLoading }) => {
  if (isLoading) {
    return <BreadcrumbCurrentItemShimmer />;
  }

  return (
    <Text as={"span"} className={classNames(styles.item, styles.active)}>
      {children}
    </Text>
  );
};

type Props = {
  children: ReactElement<BaseItemProps> | Array<ReactElement<BaseItemProps>>;
  isLoading?: boolean;
};

const Breadcrumbs: React.FC<Props> = ({ isLoading, children }) => {
  if (isLoading) {
    return <BreadcrumbItemShimmer />;
  }
  return <div className={styles.container}>{children}</div>;
};

export default Object.assign(Breadcrumbs, {
  Item: Item,
  CurrentItem: CurrentItem,
  ItemShimmer: BreadcrumbItemShimmer,
  CurrentItemShimmer: BreadcrumbCurrentItemShimmer,
});
