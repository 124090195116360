import { captureException } from "@sentry/react";
import useMfa from "modules/mfa/useMfa";
import PaymentDetailCreatorRep from "reps/payments-v2/PaymentDetailCreatorRep";
import PaymentRep from "reps/payments-v2/PaymentRep";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useRefreshBillsQueries from "../queries/useRefreshBillsQueries";

type Variables =
  | PaymentDetailCreatorRep.AchCreator
  | PaymentDetailCreatorRep.DomesticWireCreator
  | PaymentDetailCreatorRep.InternationalWireCreator;

const useSendBillPaymentMutation = (
  additionalOptions: MutationAdditionalOptions<PaymentRep.Complete, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshBillQueries = useRefreshBillsQueries();
  const { mfa } = useMfa();

  return useMutationWithDefaults(
    {
      mutationFn: async (variables: Variables) => {
        await mfa();
        return highbeamApi.paymentV2.create({
          send: true,
          detail: variables,
        });
      },
      onSuccess: async (data) => {
        if (data.status === "Failed") {
          notify("error", data.reason);
          captureException(new Error("Bill payment failed"), {
            extra: {
              data: data,
            },
          });
        }

        await refreshBillQueries();
      },
      onError: (error) => {
        captureException(error);
      },
    },
    additionalOptions
  );
};

export default useSendBillPaymentMutation;
