import { PlusCircle } from "@phosphor-icons/react";
import { FC, ReactElement, ReactNode } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";

import styles from "./DropdownCreateLabel.module.scss";

type Props = ButtonProps & {
  icon?: ReactElement;
  children: ReactNode;
};

const DropdownCreateLabel: FC<Props> = ({
  icon = <PlusCircle size={16} />,
  children,
  ...buttonProps
}) => {
  return (
    <Button fullWidth className={styles.button} {...buttonProps}>
      {icon}
      {children}
    </Button>
  );
};

export default DropdownCreateLabel;
