import { Dayjs } from "dayjs";
import CardRep from "reps/CardRep";
import { startOfBankingDay } from "utils/date";
import convertArrayToQueryString from "utils/searchParams/convertArrayToQueryString";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class CardApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getAll({ status, ...params }: CardRep.ListParams): Promise<CardRep.GetAllResponse> {
    const qp = new URLSearchParams(params);
    const statusQp = status ? "&" + convertArrayToQueryString("status", status) : "";
    const url = `/cards?${qp}${statusQp}`;
    return (await this.api.get<CardRep.GetAllResponse>(url))!;
  }

  async transactionsCsv(
    businessGuid: string,
    cardId: string,
    query?: string,
    from?: Dayjs,
    to?: Dayjs
  ): Promise<string> {
    const queryParams = new URLSearchParams({ businessGuid });

    if (query && query.length > 0) queryParams.set("query", query);
    if (from) queryParams.set("from", startOfBankingDay(from).format());
    if (to) queryParams.set("to", startOfBankingDay(to).add(1, "day").format());

    const url = `/cards/${cardId}/transactions?${queryParams}`;
    return (await this.api.get<string>(url, { accept: "text/csv" }))!;
  }

  async allTransactionsCsv(
    businessGuid: string,
    query?: string,
    from?: Dayjs,
    to?: Dayjs
  ): Promise<string> {
    const queryParams = new URLSearchParams({ businessGuid });

    if (query && query.length > 0) queryParams.set("query", query);
    if (from) queryParams.set("from", startOfBankingDay(from).format());
    if (to) queryParams.set("to", startOfBankingDay(to).add(1, "day").format());

    const url = `/cards/transactions?${queryParams}`;
    return (await this.api.get<string>(url, { accept: "text/csv" }))!;
  }

  async close(cardId: string): Promise<void> {
    const url = `/cards/${cardId}/close`;
    return (await this.api.post<void>(url))!;
  }
}
