import MultiStep from "components/layouts/MultiStep";
import AddConnectionModal from "pages/AddConnectionModal";
import ExternalCardsSection from "pages/settings/SettingsFinancialPartnersPage/ExternalCardsSection";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import usePlaidCreditConnections from "resources/plaid-connections/queries/usePlaidCreditConnections";

import CreditApplicationBackButton from "../components/CreditApplicationBackButton";
import useGetNextPathname from "../hooks/useGetNextPathname";

const CONNECT_CARDS_FORM_ID = "connect-stores-form";

const ConnectCardsView = () => {
  const [addConnectionModalVariant, setAddConnectionModalVariant] =
    useState<PlaidLinkTokenRep.AccountType>();

  const creditConnections = usePlaidCreditConnections();

  const nextPathname = useGetNextPathname();
  const navigate = useNavigate();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  return (
    <>
      {addConnectionModalVariant && (
        <AddConnectionModal
          accountType={addConnectionModalVariant}
          onClose={() => setAddConnectionModalVariant(undefined)}
        />
      )}

      <MultiStep.Form id={CONNECT_CARDS_FORM_ID} onSubmit={handleSubmit}>
        <ExternalCardsSection setAddConnectionModalVariant={setAddConnectionModalVariant} />

        <MultiStep.Controls>
          <CreditApplicationBackButton />

          <MultiStep.Controls.NextButton
            form={CONNECT_CARDS_FORM_ID}
            disabled={creditConnections.length === 0}
          >
            Save and continue
          </MultiStep.Controls.NextButton>

          {creditConnections.length === 0 && (
            <Link to={nextPathname!}>
              <MultiStep.Controls.SkipButton>
                I don’t have other cards
              </MultiStep.Controls.SkipButton>
            </Link>
          )}
        </MultiStep.Controls>
      </MultiStep.Form>
    </>
  );
};

export default ConnectCardsView;
