import classNames from "classnames";
import { FC } from "react";
import Text from "ui/typography/Text";

import styles from "./CountBadge.module.scss";

export type CountBadgeColor = "grey-200" | "grey-500" | "grey-700" | "purple-500";

const colorStyles: { [key in CountBadgeColor]: string } = {
  "grey-700": styles.grey700,
  "grey-500": styles.grey500,
  "grey-200": styles.grey200,
  "purple-500": styles.purple500,
};

type Props = {
  count: number;
  className?: string;
  color: CountBadgeColor;
};

const CountBadge: FC<Props> = ({ className, count, color }) => {
  return (
    <div className={classNames(className, styles.countBadge, colorStyles[color])}>
      <Text as="span" numeric size={12} weight="medium">
        {count}
      </Text>
    </div>
  );
};

export default CountBadge;
