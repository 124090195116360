import { Card } from "@highbeam/unit-node-sdk";
import Modal from "ui/overlay/Modal";
import { getCardType, isDebitCard } from "utils/cards";

import ActivateCard from "./ActivateCard";
import Success from "./Success";
import { Step, stepInfoMap, useActivateCardModal } from "./utils";

type Props = {
  onClose: () => void;
  card: Card;
};

const ActivateCardModal: React.FC<Props> = ({ onClose, card }) => {
  const { step, handleNext, handleReset, isLoading, hasError } = useActivateCardModal(
    onClose,
    card.id
  );
  const debitCard = isDebitCard(getCardType(card));

  return (
    <Modal
      icon={<img alt="Highbeam account" src={stepInfoMap[step].iconURL} />}
      title={stepInfoMap[step].title}
      showCancel={stepInfoMap[step].showCancel}
      buttonText={stepInfoMap[step].buttonText}
      isLoading={isLoading}
      isPrimaryButtonDisabled={isLoading}
      isCancelButtonDisabled={isLoading}
      onClick={handleNext}
      onClose={() => {
        onClose();
        handleReset();
      }}
    >
      {step === Step.ACTIVATE && <ActivateCard hasError={hasError} isDebitCard={debitCard} />}
      {step === Step.SUCCESS && <Success cardId={card.id} />}
    </Modal>
  );
};

export default ActivateCardModal;
