import { useSuspenseQuery } from "@tanstack/react-query";
import useBusiness from "resources/business/queries/useBusiness";
import useUnitCoStatementsQueryOptions from "resources/unit-co-statements/queries/useUnitCoStatementsQueryOptions";
import Text from "ui/typography/Text";

import styles from "./AccountsStatements.module.scss";

type Props = {
  accountId?: string;
};

const AccountStatementsCount: React.FC<Props> = ({ accountId }) => {
  const { unitCoCustomerId: customerId } = useBusiness();
  const { data: statements } = useSuspenseQuery(
    useUnitCoStatementsQueryOptions({ customerId: customerId ?? undefined, accountId })
  );
  if (!statements || statements.length === 0) return null;
  return (
    <Text size={14} className={styles.count}>{`${statements.length} statement${
      statements.length === 1 ? "" : "s"
    } available.`}</Text>
  );
};

export default AccountStatementsCount;
