import OnboardingRouter from "pages/Onboarding/OnboardingRouter";
import { Navigate, Route, Routes } from "react-router-dom";

const NonOnboardedRouter = () => (
  <Routes>
    <Route path="/onboarding/*" element={<OnboardingRouter />} />
    <Route path="*" element={<Navigate replace to="/onboarding" />} />
  </Routes>
);

export default NonOnboardedRouter;
