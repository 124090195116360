import OnboardingLayout from "components/layouts/Onboarding";
import React from "react";
import BankApplicationRep from "reps/BankApplicationRep";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./BankApplicationPending.module.scss";

type Props = {
  bankApplication: BankApplicationRep.Complete;
};

const BankApplicationPending: React.FC<Props> = ({ bankApplication }) => {
  const cardConfig = getStatusCardConfig(bankApplication);

  return (
    <OnboardingLayout>
      <Card className={styles.card}>
        <Text className={styles.title} size={20} weight="bold">
          {cardConfig.title}
        </Text>
        <Text size={14}>{cardConfig.body}</Text>
        {cardConfig.cta && (
          <Button variant="primary" className={styles.button} onClick={cardConfig.cta.action}>
            {cardConfig.cta?.text}
          </Button>
        )}
      </Card>
    </OnboardingLayout>
  );
};

export default BankApplicationPending;

export type StatusCardConfig = {
  title: string;
  body: string;
  cta?: { text: string; action: () => void };
};

const getStatusCardConfig = (bankApplication: BankApplicationRep.Complete): StatusCardConfig => {
  if (
    bankApplication.status === BankApplicationRep.Status.Pending ||
    bankApplication.status === BankApplicationRep.Status.PendingReview
  ) {
    return {
      title: "Almost done!",
      body: "We are processing your information and we will contact you once your Highbeam account is ready to use.",
    };
  } else if (bankApplication.status === BankApplicationRep.Status.AwaitingDocuments) {
    return {
      title: "Additional documents required",
      body: "Please upload the additional documentation to complete your application.",
      cta: {
        text: "Upload documents",
        action: () => window.location.replace(bankApplication.url!),
      },
    };
  } else if (bankApplication.status === BankApplicationRep.Status.Denied) {
    return {
      title: "We are unable to approve you at this time",
      body: "Unfortunately your account was unable to be approved at this time. If you would like more details or think this may be an error, please feel to contact us at support@highbeam.co.",
    };
  }
  throw new Error("Bank Application status unhandled.");
};
