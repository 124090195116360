import { Bank, ChartLine, Coins, Info } from "@phosphor-icons/react";
import bankIcon from "assets/bank-icon.svg";
import clock from "assets/clock.svg";
import storefrontSyncing from "assets/storefront-syncing.svg";
import OnboardingLayout from "components/layouts/Onboarding";
import OnboardingStep from "pages/Onboarding/OnboardingStep";
import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import BankApplicationRep from "reps/BankApplicationRep";
import { onboardingProgressState } from "state/onboarding/progress";
import colors from "styles/colors";
import AccentCard from "ui/data-display/AccentCard";
import Pill from "ui/data-display/Pill";
import DotsLoader from "ui/feedback/DotsLoader";
import HighbeamLogo from "ui/icons/HighbeamLogo";
import Button from "ui/inputs/Button";
import Heading3 from "ui/typography/Heading3";
import Heading4 from "ui/typography/Heading4";
import Text from "ui/typography/Text";

import styles from "./BankApplicationCreated.module.scss";

type Props = {
  bankApplication: BankApplicationRep.Complete;
};

type LocationState = {
  fromConnectStore: boolean;
};

const HeaderContent = () => (
  <div className={styles.headerContainer}>
    <img className={styles.storeSyncing} src={storefrontSyncing} alt="Store loading icon" />
    <div className={styles.headerDotsContainer}>
      <DotsLoader dots={6} small />
    </div>
    <div className={styles.headerHighbeamLogo}>
      <HighbeamLogo className={styles.highbeamLogo} />
    </div>
  </div>
);

const Highlight: React.FC<{ icon: ReactElement; text: string }> = ({ icon, text }) => (
  <div className={styles.highlight}>
    <div className={styles.highlightIcon}>{icon}</div>
    <Text className={styles.highlightText} size={12} weight={"medium"}>
      {text}
    </Text>
  </div>
);

const BankApplicationCreated: React.FC<Props> = ({ bankApplication }) => {
  const locationState = useLocation().state as LocationState | undefined;
  const setProgress = useSetRecoilState(onboardingProgressState);

  const nextButton = (
    <a href={bankApplication.url!}>
      <Button variant="primary">Start application</Button>
    </a>
  );

  useEffect(() => {
    setProgress(30);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardingLayout>
      <OnboardingStep
        headerContent={locationState?.fromConnectStore ? <HeaderContent /> : null}
        title={
          locationState?.fromConnectStore
            ? "We're crunching your store numbers"
            : "Apply for a Highbeam account"
        }
        actions={nextButton}
      >
        <AccentCard withTopBorder>
          <div className={styles.bankApplicationIconContainer}>
            {locationState?.fromConnectStore ? (
              <img src={bankIcon} className={styles.bankIcon} alt="Bank logo" />
            ) : (
              <HighbeamLogo className={styles.highbeamLogo} />
            )}
          </div>
          <div className={styles.heading}>
            <Heading3 className={styles.bankingHeading}>
              {locationState?.fromConnectStore
                ? "Let’s get started on your Highbeam banking application"
                : "Experience business banking like never before"}
            </Heading3>
            <div className={styles.highlightContainer}>
              <Highlight
                icon={<Bank size={16} color={colors.purple[500]} />}
                text={"Best-in-class banking"}
              />
              <Highlight
                icon={<ChartLine size={16} color={colors.purple[500]} />}
                text={"Cash flow insights"}
              />
              <Highlight
                icon={<Coins size={16} color={colors.purple[500]} />}
                text={"Transparent credit"}
              />
            </div>
          </div>
          <div className={styles.youllNeed}>
            <div>
              <div className={styles.youllNeedHeadingContainer}>
                <Heading4>You’ll need</Heading4>
                <Pill color="purple-light">
                  <img src={clock} alt="" />
                  3-5 minutes
                </Pill>
              </div>
              <ul>
                <li>Social security number (SSN)</li>
                <li>Employer identification number (EIN)</li>
              </ul>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <div>
              <Info size={12} color={colors.purple[500]} />
            </div>
            <div>
              <Text size={12} color={colors.grey[900]}>
                This will not affect your personal credit score. We require this information to
                ensure security and compliance with federal regulations. We never share this
                information.
              </Text>
            </div>
          </div>
        </AccentCard>
      </OnboardingStep>
    </OnboardingLayout>
  );
};

export default BankApplicationCreated;
