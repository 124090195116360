import dayjs from "dayjs";
import { selector } from "recoil";
import RevenueForecastRep, { IntervalType } from "reps/RevenueForecastRep";
import businessGuidState from "state/auth/businessGuid";
import highbeamApiState from "state/auth/highbeamApi";

const revenueForecastState = selector<RevenueForecastRep | null>({
  key: "compareCreditOffers/revenueForecastState",
  get: async ({ get }) => {
    try {
      const highbeamApi = get(highbeamApiState("authenticated"));
      const businessGuid = get(businessGuidState);

      return await highbeamApi.lineOfCredit.getRevenueForecast(
        businessGuid,
        dayjs(),
        IntervalType.Monthly,
        6
      );
    } catch (err) {
      return null;
    }
  },
});

export default revenueForecastState;
