import { Info } from "@phosphor-icons/react";
import {
  filterSupportedInternationalWireCountriesOptions,
  INTERNATIONAL_WIRE_INPUTS,
  supportedInternationalWireCountriesOptions,
} from "pages/SendMoneyPage/internationalWires";
import { useRecoilState, useRecoilValue } from "recoil";
import { isLocalPaymentState } from "state/payments/international/isLocalPayment";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import requiredFieldsState from "state/payments/international/requiredFields";
import {
  selectedBankCountryInternationalWireOptionState,
  selectedBankCountryOptionState,
} from "state/payments/international/selectedBankCountry";
import Banner from "ui/data-display/Banner";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import Text from "ui/typography/Text";

import AccountNumber from "./Inputs/AccountNumber";
import BankCode from "./Inputs/BankCode";
import BranchCode from "./Inputs/BranchCode";
import BsbCode from "./Inputs/BsbCode";
import Clabe from "./Inputs/Clabe";
import Cnaps from "./Inputs/Cnaps";
import IndianFinancialSystemCode from "./Inputs/IndianFinancialSystemCode";
import InternationalBankAccountNumber from "./Inputs/InternationalBankAccountNumber";
import SortCode from "./Inputs/SortCode";
import Swift from "./Inputs/Swift";
import styles from "./InternationalPayeeBankingInfo.module.scss";

const InternationalPayeeBankingInfo = () => {
  const isLocalPayment = useRecoilValue(isLocalPaymentState);
  const [selectedBankCountry, setSelectedBankCountry] = useRecoilState(
    selectedBankCountryOptionState
  );

  const selectedBankCountryOption = useRecoilValue(selectedBankCountryOptionState);
  const selectedCountry = selectedBankCountryOption?.value;

  const currencyOption = useRecoilValue(quoteCurrencyState);
  const selectedBankCountryInternationalWireOption = useRecoilValue(
    selectedBankCountryInternationalWireOptionState
  );

  const requiredFields = useRecoilValue(requiredFieldsState);

  const countryOptions = isLocalPayment
    ? filterSupportedInternationalWireCountriesOptions(currencyOption.value)
    : supportedInternationalWireCountriesOptions;

  const onBankCountryChange = (country: Option) => {
    setSelectedBankCountry(country);
  };

  const supportsLocalPayments = selectedBankCountryInternationalWireOption?.local.supported;
  const onlyUsdSupportedMessage = `To complete the payment successfully, please make sure that the receiving bank account accepts USD.`;
  const localSupportedMessage = `To complete the payment successfully, please make sure that the receiving bank account accepts USD. If you'd like to send funds in the local currency (${selectedBankCountryInternationalWireOption?.currencyCode}), please select it from the dropdown above.`;

  return (
    <fieldset>
      <div className={styles.container}>
        <legend className={styles.heading}>Payee’s banking info</legend>
        {!isLocalPayment && (
          <Banner
            color="purple"
            icon={<Info />}
            title="Receiving bank account must accept USD."
            className={styles.banner}
            body={
              <Text size={14}>
                {supportsLocalPayments ? localSupportedMessage : onlyUsdSupportedMessage}
              </Text>
            }
          />
        )}
        <div className={styles.formData}>
          <div className={styles.row}>
            <Dropdown
              onChange={onBankCountryChange}
              value={selectedBankCountry}
              label="Country"
              options={countryOptions}
              hideCaret
              hideCursor={selectedBankCountryOption !== null}
            />
          </div>

          {selectedCountry && requiredFields && (
            <>
              {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.BANK_CODE) && (
                <div className={styles.row}>
                  <BankCode selectedCountry={selectedCountry} />
                </div>
              )}
              {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.BRANCH_CODE) && (
                <div className={styles.row}>
                  <BranchCode selectedCountry={selectedCountry} />
                </div>
              )}
              {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER) && (
                <div className={styles.row}>
                  <AccountNumber selectedCountry={selectedCountry} />
                </div>
              )}
              {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.BSB_CODE) && (
                <div className={styles.row}>
                  <BsbCode selectedCountry={selectedCountry} />
                </div>
              )}
              {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.CLABE) && (
                <div className={styles.row}>
                  <Clabe selectedCountry={selectedCountry} />
                </div>
              )}
              {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.CNAPS) && (
                <div className={styles.row}>
                  <Cnaps selectedCountry={selectedCountry} />
                </div>
              )}
              {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT) && (
                <div className={styles.row}>
                  <Swift selectedCountry={selectedCountry} />
                </div>
              )}
              {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.IBAN) && (
                <div className={styles.row}>
                  <InternationalBankAccountNumber selectedCountry={selectedCountry} />
                </div>
              )}
              {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.IFSC) && (
                <div className={styles.row}>
                  <IndianFinancialSystemCode selectedCountry={selectedCountry} />
                </div>
              )}
              {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.SORT_CODE) && (
                <div className={styles.row}>
                  <SortCode selectedCountry={selectedCountry} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </fieldset>
  );
};

export default InternationalPayeeBankingInfo;
