import { useMutation } from "@tanstack/react-query";
import useMfa from "modules/mfa/useMfa";
import BusinessDetailsRep from "reps/BusinessDetailsRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useBusinessDetailsQueryOptions from "../queries/useBusinessDetailsQueryOptions";

const useUpdateBusinessDetailsMutation = () => {
  const refreshBusinessDetailsQuery = useRefreshQuery(useBusinessDetailsQueryOptions().queryKey);
  const { mfa } = useMfa();
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const { segmentTrack } = useSegment();

  return useMutation({
    mutationFn: async (variables: BusinessDetailsRep.Updater) => {
      await mfa();
      return highbeamApi.businessDetails.update(businessGuid, variables);
    },
    onSuccess: async () => {
      await refreshBusinessDetailsQuery();
      notify("success", "Business details updated");
      segmentTrack(SEGMENT_EVENTS.BUSINESS_DETAILS_UPDATED);
    },
    onError: async () => {
      notify("error", "Something went wrong! Please try again.");
    },
  });
};

export default useUpdateBusinessDetailsMutation;
