import { ChatDots as ChatDotsIcon } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { FC, Suspense, useRef, useEffect } from "react";
import BillCommentRep from "reps/BillCommentRep";
import BillSummaryRep from "reps/BillSummaryRep";
import BillCreateCommentForm, {
  FOCUS_REQUESTED_EVENT,
} from "resources/bills/forms/BillCreateCommentForm";
import useBillComments from "resources/bills/queries/useBillComments";
import useBusinessMember from "resources/business-members/queries/useBusinessMember";
import useBusinessMembers from "resources/business-members/queries/useBusinessMembers";
import getBusinessMemberByBusinessMemberGuid from "resources/business-members/utils/getBusinessMemberByBusinessMemberGuid";
import Avatar from "ui/data-display/Avatar";
import getAvatarInitials from "ui/data-display/Avatar/getAvatarInitials";
import EmptyState from "ui/data-display/EmptyState";
import SpeechBubbleGroup from "ui/data-display/SpeechBubbleGroup";
import DotsLoader from "ui/feedback/DotsLoader";
import Text from "ui/typography/Text";
import { formatEventTime } from "utils/date";

import styles from "./BillComments.module.scss";

const CommentsLoading: FC = () => (
  <div className={styles.loadingContainer}>
    <DotsLoader dots={5} />
  </div>
);

type CommentsEmptyStateProps = {
  eventBus: EventTarget;
};

const CommentsEmptyState: FC<CommentsEmptyStateProps> = ({ eventBus }) => (
  <EmptyState
    className={styles.emptyStateContainer}
    body={
      <>
        <EmptyState.PrimaryText>No comments</EmptyState.PrimaryText>
        <EmptyState.SecondaryText>
          Add a comment for your teammates below. All bill approvers will be notified via email.
        </EmptyState.SecondaryText>
      </>
    }
    footer={
      <EmptyState.CTA onClick={() => eventBus.dispatchEvent(new Event(FOCUS_REQUESTED_EVENT))}>
        <ChatDotsIcon />
        Add a comment
      </EmptyState.CTA>
    }
  />
);

type CommentBusinessMemberLabelContentProps = {
  businessMemberGuid: string;
};

const CommentBusinessMemberLabelContent: FC<CommentBusinessMemberLabelContentProps> = ({
  businessMemberGuid,
}) => {
  const currentBusinessMember = useBusinessMember();
  const businessMembers = useBusinessMembers();
  const businessMember = getBusinessMemberByBusinessMemberGuid(businessMembers, businessMemberGuid);
  const displayName = businessMember ? businessMember.displayName : "Deactivated member";

  return (
    <>
      <Text as="p">
        {displayName}
        {businessMemberGuid === currentBusinessMember?.guid && " (you)"}
      </Text>
      <Avatar
        initials={businessMember ? getAvatarInitials(displayName) : ""}
        color="purple-light"
        size={32}
      />
    </>
  );
};

type GroupedBillComments = {
  timestamp: string;
  businessMemberGuid: string;
  comments: BillCommentRep.Complete[];
};

const makeBillCommentsGroups = (billComments: BillCommentRep.Complete[]) => {
  const billCommentsGroups: GroupedBillComments[] = [];
  // eslint-disable-next-line functional/no-let
  let currentGroup: GroupedBillComments | null = null;

  billComments.forEach((billComment) => {
    const { businessMemberGuid, createdAt } = billComment;

    // Group comments by the same business member only if they're within 6 hours of each other.
    if (
      currentGroup &&
      currentGroup.businessMemberGuid === businessMemberGuid &&
      dayjs(createdAt).diff(dayjs(currentGroup.timestamp), "hour") < 6
    ) {
      currentGroup.comments.push(billComment);
    } else {
      currentGroup = {
        timestamp: createdAt,
        businessMemberGuid,
        comments: [billComment],
      };
      billCommentsGroups.push(currentGroup);
    }
  });

  return billCommentsGroups;
};

const makeGroupedBillCommentsKey = (groupedBillComments: GroupedBillComments): string =>
  groupedBillComments.comments.map((comment) => comment.id).join("~");

type BillCommentsSpeechBubbleGroupProps = {
  groupedBillComments: GroupedBillComments;
  isAlternate: boolean;
};

const BillCommentsSpeechBubbleGroup: FC<BillCommentsSpeechBubbleGroupProps> = ({
  groupedBillComments,
  isAlternate,
}) => {
  const { comments, timestamp, businessMemberGuid } = groupedBillComments;
  const commentsCount = comments.length;

  return (
    <SpeechBubbleGroup isAlternate={isAlternate}>
      <SpeechBubbleGroup.Timestamp>{formatEventTime(timestamp)}</SpeechBubbleGroup.Timestamp>

      {comments.map((billComment, index) => (
        <SpeechBubbleGroup.SpeechBubble
          key={billComment.id}
          labelContent={
            index < commentsCount - 1 ? null : (
              <Suspense fallback={<SpeechBubbleGroup.LabelLoadingContent />}>
                <CommentBusinessMemberLabelContent businessMemberGuid={businessMemberGuid} />
              </Suspense>
            )
          }
        >
          <Text as="p">{billComment.text}</Text>
        </SpeechBubbleGroup.SpeechBubble>
      ))}
    </SpeechBubbleGroup>
  );
};

type BillCommentsMainProps = {
  bill: BillSummaryRep.Complete;
  eventBus: EventTarget;
  onCommentsLoaded: () => void;
};

const BillCommentsMain: FC<BillCommentsMainProps> = ({ bill, eventBus, onCommentsLoaded }) => {
  const billComments = useBillComments(bill.id);

  useEffect(() => {
    onCommentsLoaded();
  }, [billComments, onCommentsLoaded]);

  if (!billComments.length) {
    return <CommentsEmptyState eventBus={eventBus} />;
  }

  const billCommentsGroups = makeBillCommentsGroups(billComments);

  return (
    <div className={styles.billCommentsGroupsContainer}>
      {billCommentsGroups.map((groupedBillComments, index) => (
        <BillCommentsSpeechBubbleGroup
          key={makeGroupedBillCommentsKey(groupedBillComments)}
          groupedBillComments={groupedBillComments}
          isAlternate={index % 2 !== 0}
        />
      ))}
    </div>
  );
};

type Props = {
  bill: BillSummaryRep.Complete;
};

const BillComments: FC<Props> = ({ bill }) => {
  const eventBusRef = useRef(new EventTarget());
  const eventBus = eventBusRef.current;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.container} ref={containerRef}>
      <Suspense fallback={<CommentsLoading />}>
        <div className={styles.contentContainer}>
          <BillCommentsMain
            bill={bill}
            eventBus={eventBus}
            onCommentsLoaded={() => {
              // Set scroll position to the bottom of the container.
              const container = containerRef.current!;
              container.scrollTop = container.scrollHeight;
            }}
          />
        </div>
        <footer className={styles.footerContainer}>
          <BillCreateCommentForm bill={bill} eventBus={eventBus} />
        </footer>
      </Suspense>
    </div>
  );
};

export default BillComments;
