import { forwardRef, ReactElement } from "react";
import Button from "ui/inputs/Button";

import styles from "./ActionButton.module.scss";

type Props = {
  text: string;
  icon: ReactElement;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ActionButton = forwardRef<HTMLButtonElement, Props>(({ text, icon, ...props }, ref) => (
  <Button ref={ref} className={styles.button} variant="secondary" {...props}>
    {icon}
    {text}
  </Button>
));

export default ActionButton;
