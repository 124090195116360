import classNames from "classnames";
import { PropsWithChildren, ReactNode } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./MetadataList.module.scss";

type Props = {
  className?: string;
} & PropsWithChildren;

const MetadataList: React.FC<Props> = ({ className, children }) => (
  <table className={classNames(styles.table, className)}>
    <tbody>{children}</tbody>
  </table>
);

type ItemProps = {
  label: ReactNode;
  boldLabel?: boolean;
  hideBorder?: boolean;
  tooltipContent?: string;
} & PropsWithChildren;

const Item: React.FC<ItemProps> = ({ label, boldLabel = false, children, hideBorder = false }) => (
  <tr
    className={classNames({
      [styles.hideBorder]: hideBorder,
    })}
  >
    <td className={styles.label}>
      <Text weight={boldLabel ? "bold" : "regular"} size={14} color={colors.grey[600]}>
        {label}
      </Text>
    </td>
    <td className={styles.content}>{children}</td>
  </tr>
);

export default Object.assign(MetadataList, { Item: Item });
