import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import ConnectionItem from "components/rutter/ConnectionItem";
import ConnectRutter from "pages/settings/SettingsPage/Integrations/ConnectRutter";
import RutterConnectionRep from "reps/RutterConnectionRep";
import useAccountingPlatformConnectionsQueryOptions from "resources/accounting-platforms/queries/useAccountingPlatformConnectionsQueryOptions";
import colors from "styles/colors";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import { getRutterIcon } from "utils/rutter";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";

import useDisconnectRutterConnectionMutation from "../../hooks/useDisconnectRutterConnectionMutation";

import styles from "./UploadFinancialDocumentsView.module.scss";

const ConnectTabAccountingSoftwareStep = () => {
  const { data: accountingPlatformConnections, refetch: refetchAccountingPlatformConnections } =
    useQuery(useAccountingPlatformConnectionsQueryOptions());

  const { mutateAsync } = useDisconnectRutterConnectionMutation();

  return (
    <>
      <Heading3>1. Connect accounting software</Heading3>
      <Text size={14} color={colors.grey[600]} className={styles.accountingSoftwareSubheading}>
        Connecting one of the accounting tools listed below, would help us to better understand your
        business.
      </Text>
      <div className={styles.container}>
        {RutterConnectionRep.rutterAccountingPlatforms.map((type) => {
          const connection = accountingPlatformConnections?.find(
            (connection) => connection.platformName === type
          );
          const hasConnection = Boolean(connection);
          const providerName = getAccountingSoftwareName(type);

          return (
            <ConnectRutter
              key={`${type}-rutter`}
              platform={type}
              onConnect={(_, { closeModal }) => {
                refetchAccountingPlatformConnections();
                closeModal();
              }}
            >
              {({ openModal }) => (
                <ConnectionItem
                  iconSrc={getRutterIcon(type)}
                  providerName={providerName}
                  onClickConnect={() => openModal()}
                  onClickDisconnect={async () => {
                    try {
                      if (!connection) {
                        captureException(new Error(`No ${providerName} connection found`));
                        return;
                      }
                      await mutateAsync(connection.guid);
                    } finally {
                      refetchAccountingPlatformConnections();
                    }
                  }}
                  isConnected={hasConnection}
                />
              )}
            </ConnectRutter>
          );
        })}
      </div>
    </>
  );
};

export default ConnectTabAccountingSoftwareStep;
