import warningIcon from "assets/warning.svg";
import Text from "ui/typography/Text";

import styles from "./TransferPlaidReAuth.module.scss";

const TransferPlaidReAuth = () => (
  <Text size={12} className={styles.reauth}>
    <img src={warningIcon} alt="warning" className={styles["reauth--icon"]} /> Reauthentication
    required
  </Text>
);
export default TransferPlaidReAuth;
