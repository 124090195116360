import classNames from "classnames";

import styles from "../Heading.module.scss";

type Props = React.HTMLAttributes<HTMLHeadingElement>;

const Heading2: React.FC<Props> = ({ children, className, ...props }) => (
  <h2 className={classNames(styles.heading, styles.heading2, className)} {...props}>
    {children}
  </h2>
);

export default Heading2;
