import ChargeCardRepaymentRep from "reps/ChargeCardRepaymentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ChargeCardTransactionsApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  // Used for repaying charge cards **only** without paying back the line of credit.
  async createRepayment(
    businessGuid: string,
    chargeCardCreditAccountGuid: string,
    creator: ChargeCardRepaymentRep.Creator
  ) {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      chargeCardAccountGuid: chargeCardCreditAccountGuid,
    });
    const url = `/charge-card-repayments?${qp}`;
    return await this.api.post(url, creator);
  }
}
