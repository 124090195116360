import { useSuspenseQuery } from "@tanstack/react-query";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import CardAuthorizations from "modules/card-authorizations-table/CardAuthorizations";
import useCardAuthorizations from "modules/card-authorizations-table/CardAuthorizations/hooks/useCardAuthorizations";
import IncomingPaymentsTable from "modules/incoming-payments/components/IncomingPaymentsTable";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import useUnitCoCheckDepostsQueryOptions, {
  INCOMING_CHECK_DEPOSITS_PARAMS,
} from "resources/unit-co-check-deposits/queries/useUnitCoCheckDepostsQueryOptions";
import useUnitCoPaymentsQueryOptions, {
  INCOMING_PAYMENT_PARAMS,
} from "resources/unit-co-payments/queries/useUnitCoPaymentsQueryOptions";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Tabs, { Tab } from "ui/navigation/Tabs";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { useIsTablet } from "utils/device/useMediaQuery";

import styles from "./AccountsTransactionsPage.module.scss";
import Transactions from "./Transactions";

const AccountsTransactionsPageContent = () => {
  const isTablet = useIsTablet();
  const [activeTab, setActiveTab] = useState<string>("incoming");
  const [scrollTransactionsIntoView, setScrollTransactionsIntoView] = useState(false);
  const showIncomingPayments = useFeatureFlag("INCOMING_PAYMENTS");

  const { data: unitCheckDeposits } = useSuspenseQuery(
    useUnitCoCheckDepostsQueryOptions(INCOMING_CHECK_DEPOSITS_PARAMS)
  );

  const { data: unitPayments } = useSuspenseQuery(
    useUnitCoPaymentsQueryOptions(INCOMING_PAYMENT_PARAMS)
  );

  const transactionScrollRef = useRef<HTMLDivElement>(null);
  const { hash } = useLocation();

  const tabs: Tab[] = [
    { id: "incoming", label: "Incoming" },
    { id: "transactions", label: "Transactions" },
  ];

  useEffect(() => {
    if (hash === "#transactions") {
      setActiveTab("transactions");
      setScrollTransactionsIntoView(true);
    }
  }, [hash]);

  const hasIncoming = unitCheckDeposits.length + unitPayments.length > 0;
  const hasAuthorizations = useCardAuthorizations({ accountType: "deposit" }).pagination.total > 0;

  return (
    <>
      {isTablet && showIncomingPayments && hasIncoming && (
        <Tabs
          tabsWrapperAdditionalClassName={styles.tabs}
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}

      {(!isTablet || activeTab === "incoming") && showIncomingPayments && hasIncoming && (
        <DashboardPage.Section>
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>Incoming</DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>

          <DashboardPage.Section.Body>
            <IncomingPaymentsTable />
          </DashboardPage.Section.Body>
        </DashboardPage.Section>
      )}

      {(!isTablet || activeTab === "transactions" || !showIncomingPayments || !hasIncoming) && (
        <>
          {hasAuthorizations && (
            <DashboardPage.Section>
              <DashboardPage.Section.Body>
                <CardAuthorizations accountType="deposit" />
              </DashboardPage.Section.Body>
            </DashboardPage.Section>
          )}
          <DashboardPage.Section>
            <DashboardPage.Section.Header>
              <DashboardPage.Section.HeaderHeading>
                Transactions
              </DashboardPage.Section.HeaderHeading>
            </DashboardPage.Section.Header>

            <Transactions
              scrollRef={scrollTransactionsIntoView ? transactionScrollRef : undefined}
            />
          </DashboardPage.Section>
        </>
      )}
    </>
  );
};

const AccountsTransactionsPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Transactions</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <AccountsTransactionsPageContent />
      </DashboardPage>
    </>
  );
};

export default AccountsTransactionsPage;
