import { Icon } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, ReactNode } from "react";
import colors from "styles/colors";
import AccentCard from "ui/data-display/AccentCard";
import Pill from "ui/data-display/Pill";
import PillList from "ui/data-display/PillList";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useIsAllowedToCreateCards from "utils/permissions/useIsAllowedToCreateCards";
import stringToReactKey from "utils/react-helpers/stringToReactKey";

import styles from "./CardPlaceholder.module.scss";

const PROMPT_COLORS = ["purple", "yellow", "pink", "blue"] as const;

const getPromptColor = (index: number) => PROMPT_COLORS[index % PROMPT_COLORS.length];

type Props = {
  primaryText: string;
  secondaryText?: string;
  prompts?: string[];
  ctaText: string;
  ctaOnClick: () => void;
  ctaIcon: Icon;
  className?: string;
  onPromptClick?: (prompt: string) => void;
  disablePrimaryAction: boolean;
  disabledPrimaryText: ReactNode;
};

const CardPlaceholder: FC<Props> = ({
  primaryText,
  secondaryText,
  prompts = [],
  ctaIcon: CtaIcon,
  ctaText,
  ctaOnClick,
  className,
  onPromptClick,
  disablePrimaryAction,
  disabledPrimaryText,
}) => {
  const isAllowedToCreateCards = useIsAllowedToCreateCards();

  return (
    <AccentCard className={classNames(className, styles.container)}>
      <div className={styles.header}>
        <Text size={14} weight="bold" className={styles.heading} align="center">
          {primaryText}
        </Text>

        {secondaryText && (
          <Text size={14} className={styles.subheading} align="center">
            {secondaryText}
          </Text>
        )}
      </div>

      <Button
        variant="tertiary"
        onClick={ctaOnClick}
        size="sm"
        disabled={disablePrimaryAction}
        tooltip={disablePrimaryAction && disabledPrimaryText}
      >
        <CtaIcon size={12} />
        {ctaText}
      </Button>

      <PillList
        className={classNames(styles.prompts, { [styles.disabled]: !isAllowedToCreateCards })}
      >
        {prompts.map((prompt, index) => (
          <Pill
            key={stringToReactKey(prompt, index)}
            color={getPromptColor(index)}
            onClick={() => isAllowedToCreateCards && onPromptClick && onPromptClick(prompt)}
          >
            {prompt}
          </Pill>
        ))}
      </PillList>
    </AccentCard>
  );
};

type EmptyPlaceholderProps = {
  className?: string;
  heading: string;
  subheading?: string;
};

export const EmptyCardPlaceholder: FC<EmptyPlaceholderProps> = ({
  className,
  heading,
  subheading,
}) => {
  return (
    <AccentCard className={classNames(className, styles.container)}>
      <div className={styles.header}>
        <Text size={14} weight="bold" color={colors.black} align="center">
          {heading}
        </Text>
        {subheading && (
          <Text size={14} weight="regular" color={colors.grey[600]} align="center">
            {subheading}
          </Text>
        )}
      </div>
    </AccentCard>
  );
};

export default CardPlaceholder;
