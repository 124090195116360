import IconWithTooltip from "components/common/IconWithTooltip";
import CapitalPromotionalAprLabel from "resources/capital-accounts/components/CapitalPromotionalAprLabel";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import colors from "styles/colors";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SummaryArray from "ui/data-display/SummaryArray";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { roundEpsilon } from "utils/math";

import styles from "./LineOfCreditSummaryItems.module.scss";

const LineOfCreditSummaryItems = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const isCapitalPromotionalRateEnabled = useFeatureFlag("CAPITAL_PROMOTIONAL_RATES");

  return (
    <SummaryArray className={styles.summaryArray}>
      <SummaryArray.Item className={styles.summaryArrayItem}>
        <div className={styles.tooltipContainer}>
          <Text size={14}>Starting limit</Text>
          <IconWithTooltip
            color="dark"
            content="This limit will grow over time with healthy credit usage"
          />
        </div>
        <CashDisplay cents={capitalAccountSummary.details.limit} />
      </SummaryArray.Item>
      <SummaryArray.Item className={styles.summaryArrayItem}>
        <div className={styles.tooltipContainer}>
          {isCapitalPromotionalRateEnabled ? (
            <CapitalPromotionalAprLabel withGradient />
          ) : (
            <>
              <Text size={14}>APR</Text>
              <IconWithTooltip
                color="dark"
                content="Interest is charged daily based on the current balance"
              />
            </>
          )}
        </div>

        {capitalAccountSummary.details.apr > 0 && (
          <>
            {isCapitalPromotionalRateEnabled ? (
              <div className={styles.promotionalAprItem}>
                <Text size={28} weight="medium">
                  10.00%
                </Text>
                <Text
                  size={16}
                  color={colors.grey[600]}
                  weight="medium"
                  lineThrough
                  className={styles.capitalRegularApr}
                >
                  {roundEpsilon(capitalAccountSummary.details.apr * 100, 2)}%
                </Text>
              </div>
            ) : (
              <Text size={28} weight="medium">
                {roundEpsilon(capitalAccountSummary.details.apr * 100, 2)}%
              </Text>
            )}
          </>
        )}
      </SummaryArray.Item>
      <SummaryArray.Item className={styles.summaryArrayItem}>
        <Text size={14}>Repayment period</Text>
        <Text size={28} weight="medium">
          {capitalAccountSummary.details.targetRepaymentDays} days
        </Text>
      </SummaryArray.Item>
    </SummaryArray>
  );
};

export default LineOfCreditSummaryItems;
