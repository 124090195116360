import AccountLabel from "components/Accounts/AccountLabel";
import TransferBalance from "pages/TransferMoneyPage/TransferBalance";
import { Transfer } from "pages/TransferMoneyPage/TransferInfo/utils";
import TransferPlaidReAuth from "pages/TransferMoneyPage/TransferPlaidReAuth";
import { useCallback, useState } from "react";
import BankAccountRep from "reps/BankAccountRep";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import mapTransferOption from "resources/bank-accounts/utils/mapTransferOption";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { Option } from "ui/inputs/Dropdown";
import { getDollarsFromCents } from "utils/money";

import useHighbeamApi from "./customHooks/useHighbeamApi";

type TransferOptionParams = {
  accountName: string;
  type: string;
  availableBalanceInCents: number; // NB(alex): this should just be `availableBalance`, but we previously used dollars so I wanted to reduce ambiguity in the interim while `availableBalanceInDollars` is still in use.
  availableBalanceInDollars: number; // DEPRECATED: use availableBalanceInCents instead
  processor: string;
  supportsSendMoney: boolean;
  supportsTransferMoney: boolean;
  supportsTransferMoneyExternally: boolean;
  isThreadAccount: boolean;
  isSameDayAchEnabled: boolean;
} & Option;

export enum Processor {
  Highbeam = "highbeam",
  Plaid = "plaid",
}

export type HighbeamBankAccountTransferOption = {
  processor: Processor.Highbeam;
  guid: string;
  isPrimary: boolean;
} & TransferOptionParams;

export type PlaidBankAccountTransferOption = {
  processor: Processor.Plaid;
  accountOwnerFullName?: string;
  processorToken: string;
} & TransferOptionParams;

export type TransferOption = HighbeamBankAccountTransferOption | PlaidBankAccountTransferOption;

export const isHighbeamBankAccount = (
  transferOption: TransferOption
): transferOption is HighbeamBankAccountTransferOption =>
  transferOption.processor === Processor.Highbeam;

export const isHighbeamBankAccountTransferOption = (
  transferOption: TransferOption
): transferOption is HighbeamBankAccountTransferOption =>
  transferOption.processor === Processor.Highbeam;

export const isPlaidBankAccount = (
  transferOption: TransferOption
): transferOption is PlaidBankAccountTransferOption => transferOption.processor === Processor.Plaid;

const mapPlaidBankAccountTransferOption = (
  account: PlaidBankAccountRep.Complete
): PlaidBankAccountTransferOption => ({
  value: account.plaidAccountId,
  label: AccountLabel({ plaidAccount: account }),
  description: account.isActive
    ? TransferBalance({ balance: account.availableBalance })
    : TransferPlaidReAuth(),
  type: account.subtype,
  availableBalanceInCents: account.availableBalance,
  availableBalanceInDollars: getDollarsFromCents(account.availableBalance),
  accountName: account.accountName,
  accountOwnerFullName: account.accountOwnerFullName,
  processorToken: account.plaidProcessorToken,
  processor: Processor.Plaid,
  isDisabled: !account.isActive,
  supportsSendMoney: true,
  supportsTransferMoney: true,
  supportsTransferMoneyExternally: false,
  isThreadAccount: false,
  isSameDayAchEnabled: false,
});

const getPlaidTransferOptions = (plaidAccounts: PlaidBankAccountRep.Complete[]) => {
  return plaidAccounts
    .filter((account) => Boolean(account.plaidProcessorToken))
    .map((account) => mapPlaidBankAccountTransferOption(account));
};

export const useTransferAccountOptions = () => {
  const [transferOptions, setTransferOptions] = useState<TransferOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const fetchTransferOptions = useCallback(
    () => {
      setIsLoading(true);
      (async () => {
        const highbeamAccountOptions = await highbeamApi.bankAccount
          .getOpenByBusiness(businessGuid)
          .then((data) => data.map(mapTransferOption));

        const plaidBankAccountOptions = await highbeamApi.plaid
          .getPlaidBankAccountsByBusiness(businessGuid)
          .then((plaidBankAccounts) => getPlaidTransferOptions(plaidBankAccounts));

        setTransferOptions([...highbeamAccountOptions, ...plaidBankAccountOptions]);
        setIsLoading(false);
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    fetchTransferOptions,
    transferOptions,
    isLoading,
  };
};

export const getTransferDeliveryMessage = (
  transfer: Transfer,
  bankAccountsByUnitId: Map<string, BankAccountRep.Complete>
) => {
  if (hasExternalAccounts(transfer))
    return "Transfers involving connected accounts via ACH can take up to 3 business days";

  if (isLargeDifferentHighbeamBankTransfer(transfer, bankAccountsByUnitId)) {
    return "Large transfers between your Blue Ridge Bank and Thread Bank accounts are made using wire and process same or next business day";
  } else if (isDifferentHighbeamBankTransfer(transfer, bankAccountsByUnitId)) {
    return "Transfers between your Blue Ridge Bank and Thread Bank accounts are made using ACH and can take up to 2 business days";
  } else if (hasMultiplePartnerBankAccounts(bankAccountsByUnitId)) {
    return "Transfers between Highbeam accounts on the same financial institution are instant ⚡️";
  }
  return "Transfers between Highbeam accounts are instant ⚡️";
};

export const hasMultiplePartnerBankAccounts = (
  bankAccountsByUnitId: Map<string, BankAccountRep.Complete>
): boolean => {
  const bankAccounts = Array.from(bankAccountsByUnitId.values());
  const threadAccounts = bankAccounts.filter((bankAccount) => bankAccount.threadAccount);
  const blueRidgeAccounts = bankAccounts.filter((bankAccount) => bankAccount.blueRidgeAccount);

  return threadAccounts.length > 0 && blueRidgeAccounts.length > 0;
};

export const isDifferentHighbeamBankTransfer = (
  transfer: Transfer,
  bankAccountsByUnitId: Map<string, BankAccountRep.Complete>
): boolean => {
  const fromAccount = bankAccountsByUnitId.get(transfer.from!.value);
  const toAccount = bankAccountsByUnitId.get(transfer.to!.value);

  return (
    (Boolean(fromAccount?.threadAccount) && Boolean(toAccount?.blueRidgeAccount)) ||
    (Boolean(fromAccount?.blueRidgeAccount) && Boolean(toAccount?.threadAccount))
  );
};

export const isLargeDifferentHighbeamBankTransfer = (
  transfer: Transfer,
  bankAccountsByUnitId: Map<string, BankAccountRep.Complete>
): boolean => {
  return (
    isDifferentHighbeamBankTransfer(transfer, bankAccountsByUnitId) &&
    transfer.amountInCents >= 75_000_00
  );
};

export const hasExternalAccounts = (transfer: Transfer) =>
  (transfer.to && isPlaidBankAccount(transfer.to)) ||
  (transfer.from && isPlaidBankAccount(transfer.from));
