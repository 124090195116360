import PayeeLegalAddress from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/PayeeLegalAddress";
import { FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useFinancialInstitutionQuery } from "resources/financial-institutions/queries/useFinancialInstitution";
import useDomesticWirePaymentMethodForm from "resources/payee-payment-methods/forms/useDomesticWirePaymentMethodForm";
import FieldsetV2 from "ui/inputs/FieldsetV2";
import Helper from "ui/inputs/Helper";
import TextInputV2 from "ui/inputs/TextInputV2";
import { Address } from "utils/address";
import { ROUTING_NUMBER_LENGTH } from "utils/zod/routingNumberSchema";

import RoutingNumberInput from "../RoutingNumberInput";

type Props = {
  form: ReturnType<typeof useDomesticWirePaymentMethodForm>;
};

const DomesticWirePaymentMethodFieldset: FC<Props> = ({ form }) => {
  const { control } = form;

  // NB(alex): This is basically duplicate code from `AchPaymentMethodFieldset`. I'd like to figure out a better pattern, but gotta ship this for now.
  const routingNumber = form.watch("routingNumber");
  const financialInstitutionQueryEnabled = routingNumber.length >= ROUTING_NUMBER_LENGTH;

  const { data: financialInstitution, isFetching: isFetchingFinancialInstitution } =
    useFinancialInstitutionQuery({
      routingNumber: routingNumber,
      enabled: financialInstitutionQueryEnabled,
    });

  // Trigger routing number validation once the query is enabled to see if the institution is valid.
  useEffect(() => {
    if (financialInstitutionQueryEnabled && financialInstitution !== undefined) {
      form.trigger("routingNumber");
    } else {
      form.clearErrors("routingNumber");
    }
  }, [
    form,
    financialInstitutionQueryEnabled,
    financialInstitution, // Ensures form validation is re-triggered if this value changes.
  ]);

  return (
    <FieldsetV2>
      <Controller
        control={control}
        name="routingNumber"
        render={({ field, fieldState: { error } }) => {
          return (
            <div>
              <RoutingNumberInput
                isFetchingFinancialInstitution={isFetchingFinancialInstitution}
                financialInstitution={financialInstitution ?? null}
                {...field}
              />
              {error && <Helper icon={<Helper.Icon variant="error" />}>{error.message}</Helper>}
            </div>
          );
        }}
      />

      <Controller
        control={control}
        name="accountNumber"
        render={({ field, fieldState: { error } }) => {
          return (
            <div>
              <TextInputV2 label="Account number" inputMode="numeric" {...field} />
              {error && <Helper icon={<Helper.Icon variant="error" />}>{error.message}</Helper>}
            </div>
          );
        }}
      />

      <Controller
        control={control}
        name="address"
        render={({ field, fieldState: { error } }) => {
          // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
          return (
            <div>
              <PayeeLegalAddress address={field.value as Address} setAddress={field.onChange} />
              {error && <Helper icon={<Helper.Icon variant="error" />}>{error.message}</Helper>}
            </div>
          );
        }}
      />
    </FieldsetV2>
  );
};

export default DomesticWirePaymentMethodFieldset;
