import { ErrorBoundary } from "@sentry/react";
import IntercomChat from "components/ChatWidget/IntercomChat";

const ChatWidget = () => {
  return (
    <ErrorBoundary fallback={() => <></>}>
      <IntercomChat />
    </ErrorBoundary>
  );
};

export default ChatWidget;

type ChatWidgetType = {
  show: () => void;
  hide: () => void;
  refresh: () => void;
  message: (message: string) => void;
  shutDown: () => void;
};

export const useChatWidget = (): ChatWidgetType => {
  return {
    show: () => {
      Intercom("show");
    },
    hide: () => {
      Intercom("hide");
    },
    refresh: () => {
      Intercom("update", {
        last_request_at: Math.floor(new Date().getTime() / 1000), // eslint-disable-line camelcase
      });
    },
    message: (message: string) => {
      Intercom("showNewMessage", message);
    },
    shutDown: () => {
      Intercom("shutdown");
    },
  };
};
