import CapitalAccountRep from "reps/CapitalAccountRep";

import useCapitalAccounts from "./useCapitalAccounts";

/**
 * Returns the first capital account that has an associated charge card.
 * Is temporary, we should delete this once we support multiple charge cards.
 */
const useTemporaryFirstCapitalAccountWithChargeCard = ():
  | CapitalAccountRep.Complete
  | undefined => {
  const capitalAccounts = useCapitalAccounts();

  return capitalAccounts.filter(
    ({ type }) =>
      type === CapitalAccountRep.Type.ChargeCardOnly ||
      type === CapitalAccountRep.Type.ChargeCardAndCashAccess
  )[0];
};

export default useTemporaryFirstCapitalAccountWithChargeCard;
