import Flexpane from "ui/overlay/Flexpane";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

import OriginatedAchTransactionFlexpaneHeader from "./OriginatedAchTransactionFlexpaneHeader";

const PaymentInfoFlexpaneHeaderSection = () => {
  const { payment, onClose } = usePaymentInfoFlexpaneContext();

  if (payment.type === "achPayment") {
    return <OriginatedAchTransactionFlexpaneHeader />;
  } else {
    return <Flexpane.Header onClose={onClose}>Payment info</Flexpane.Header>;
  }
};

export default PaymentInfoFlexpaneHeaderSection;
