import MetadataList from "components/MetadataList";
import Section from "ui/data-display/Section";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import { HighbeamReceivedAchTransaction } from "utils/types/transactionsTypes";

import TraceNumberLabel from "./TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";

type Props = {
  transaction: HighbeamReceivedAchTransaction;
};

const ReceivedAchTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>ACH transaction details</Heading3>
    <MetadataList>
      <MetadataList.Item label="Description">
        <Text size={14} weight="medium">
          {transaction.description}
        </Text>
      </MetadataList.Item>
      {transaction.addenda !== "" && (
        <MetadataList.Item label="Additional details">
          <Text size={14} weight="medium">
            {transaction.addenda}
          </Text>
        </MetadataList.Item>
      )}
      <MetadataList.Item label="Routing number">
        <Text size={14} weight="medium">
          {transaction.counterpartyRoutingNumber}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item label={<TraceNumberLabel />}>
        <Text size={14} weight="medium">
          {transaction.traceNumber}
        </Text>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default ReceivedAchTransactionDetailsSection;
