import { CheckCircle } from "@phosphor-icons/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import receiptImg from "assets/receipt.svg";
import ActionItemDrawer from "components/ActionItemDrawer";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import { FEATURE_FLAGS } from "flags";
import { useRecoilValue } from "recoil";
import { ActionItem, InsightsOnboardingStep, Milestone } from "resources/action-items/types";
import usePlaidCreditAccountsQueryOptions from "resources/plaid-connections/queries/usePlaidCreditAccountsQueryOptions";
import usePlaidDepositoryAccountsQueryOptions from "resources/plaid-connections/queries/usePlaidDepositoryAccountsQueryOptions";
import actionItemsState from "state/actionItems/actionItems";
import featureFlagsState from "state/featureFlags";
import colors from "styles/colors";
import AccentCard from "ui/data-display/AccentCard";
import Section from "ui/data-display/Section";
import SectionHeader from "ui/data-display/SectionHeader";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Heading2 from "ui/typography/Heading2";
import Text from "ui/typography/Text";

import styles from "./InsightsOnboarding.module.scss";

export const getInsightsOnboardingText = (accounts: number, cards: number) => {
  const accountsText = () => {
    switch (accounts) {
      case 0:
        return "";
      case 1:
        return `1 account`;
      default:
        return `${accounts} accounts`;
    }
  };
  const cardsText = () => {
    switch (cards) {
      case 0:
        return "";
      case 1:
        return `1 card`;
      default:
        return `${cards} cards`;
    }
  };
  return `You have ${[accountsText(), cardsText()].join(
    accounts > 0 && cards > 0 ? " and " : ""
  )} connected`;
};

export const useIsInsightsUIEnabled = () => {
  const flags = useRecoilValue(featureFlagsState);
  const isInsightsUIEnabled = flags[FEATURE_FLAGS.SPEND_BY_VENDOR_UI];

  return isInsightsUIEnabled as boolean;
};

export const InsightsOnboardingCategorizeVendorsText = () => {
  const isInsightsUIEnabled = useIsInsightsUIEnabled();

  return (
    <Text size={14}>
      {isInsightsUIEnabled
        ? "Once done, we will automatically categorize transactions under those vendors to the selected categories. You can also manually change the category for specific transactions if needed. If you just connected new accounts, it might take us up to 10 minutes to fetch your transactions."
        : "We are categorizing your transactions. Please check back in 24 hours."}
    </Text>
  );
};

export const InsightsOnboardingExternalConnections = () => {
  const { data: plaidDepositoryAccounts } = useSuspenseQuery(
    usePlaidDepositoryAccountsQueryOptions()
  );
  const { data: plaidCardAccounts } = useSuspenseQuery(usePlaidCreditAccountsQueryOptions());

  const numOfExternalAccounts = plaidDepositoryAccounts.length;
  const numOfExternalCards = plaidCardAccounts.length;
  const completedIcon = <CheckCircle weight="fill" color={colors.green[400]} size={24} />;
  const noConections = numOfExternalAccounts === 0 && numOfExternalCards === 0;

  if (noConections) return null;

  return (
    <div className={styles.externalConnectionsRow}>
      {completedIcon}
      <Text size={14}>{getInsightsOnboardingText(numOfExternalAccounts, numOfExternalCards)}</Text>
    </div>
  );
};

export const InsightsOnboarding = () => {
  const actionItems = useInsightsActionItems();

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Spend</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>
      <Section className={styles.section}>
        <SectionHeader>
          <img src={receiptImg} alt="Receipt" />
          <Heading2>Spend</Heading2>
        </SectionHeader>
        <AccentCard withTopBorder className={styles.container}>
          <div className={styles.drawerContainer}>
            <ActionItemDrawer
              title="Welcome! Let’s get you set up for Highbeam spend insights"
              subtitle="Takes approximately 2-5 minutes"
              fullWidth
              actionItems={actionItems}
            />
          </div>
        </AccentCard>
      </Section>
    </>
  );
};

export const useInsightsActionItems = (): ActionItem[] => {
  const flags = useRecoilValue(featureFlagsState);
  const actionItems = useRecoilValue(actionItemsState(Milestone.InsightsOnboarding));

  return actionItems.filter((actionItem) => {
    if (actionItem.name === InsightsOnboardingStep.ConnectAccounts) {
      return flags[FEATURE_FLAGS.SPEND_BY_VENDOR_SHOW_TRANSACTIONS_FROM_EXTERNAL_SOURCES];
    }
    if (actionItem.name === InsightsOnboardingStep.CategorizeVendors) {
      return flags[FEATURE_FLAGS.SPEND_BY_VENDOR_ALLOW_RECATEGORIZATION];
    }
    return true;
  });
};
