import {
  CARD_TRANSACTIONS_TABLE_DATA_PAGE_SIZE,
  DEFAULT_MONTHS_SINCE,
} from "config/constants/filters";
import dayjs from "dayjs";
import { CARD_TRANSACTIONS_TABLE_TABS } from "modules/card-transactions-table/constants";
import useSearchParamDayjs from "utils/searchParams/useSearchParamDayjs";
import useSearchParamNumber from "utils/searchParams/useSearchParamNumber";
import useSearchParamOption from "utils/searchParams/useSearchParamOption";
import useSearchParamValue from "utils/searchParams/useSearchParamValue";

const useCardDetailsPageSearchParams = () => {
  const [activeTab, setActiveTab] = useSearchParamOption(
    "tab",
    CARD_TRANSACTIONS_TABLE_TABS.map((tab) => tab.id),
    "Completed"
  );

  const [search, setSearch] = useSearchParamValue("search", "");

  const [page, setPage] = useSearchParamNumber("page", 1);

  const [since, setSince] = useSearchParamDayjs(
    "since",
    dayjs().subtract(DEFAULT_MONTHS_SINCE, "month")
  );

  const [until, setUntil] = useSearchParamDayjs("until", dayjs());

  const offset = (page - 1) * CARD_TRANSACTIONS_TABLE_DATA_PAGE_SIZE;
  const limit = CARD_TRANSACTIONS_TABLE_DATA_PAGE_SIZE;

  return {
    activeTab,
    setActiveTab,
    search,
    setSearch,
    page,
    setPage,
    since,
    setSince,
    until,
    setUntil,
    offset,
    limit,
  };
};

export default useCardDetailsPageSearchParams;
