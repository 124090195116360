import {
  DefaultError,
  QueryFunctionContext,
  QueryKey,
  UseQueryOptions as ReactQueryUseQueryOptions,
} from "@tanstack/react-query";

/**
 * Custom `UseQueryOptions` generic. Omits a few properties to make `useQueryOptions` extra reusable.
 *
 * Why do we remove certain keys?
 * `enabled` is removed because it is not available in `useSuspenseQuery`.
 * `queryFn` is omitted because we make it required. This allows us to more-easily reuse the `queryFn` if we want to alter its behavior.
 * `select` is omitted because this should be defined at the `useQuery` or `useSuspenseQuery` level.
 */
type UseQueryOptions<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  ReactQueryUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  "enabled" | "queryFn" | "select"
> & {
  // This makes `queryFn` required. It is slightly different from `QueryFunction` because `context` is optional.
  queryFn: (context?: QueryFunctionContext<TQueryKey>) => TQueryFnData | Promise<TQueryFnData>;
};

const useQueryOptions = <
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  return options as UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>;
};

export default useQueryOptions;
