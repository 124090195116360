import { useSuspenseQuery } from "@tanstack/react-query";

import useCreditApplicationsQueryOptions from "./useCreditApplicationsQueryOptions";

const useMostRecentCreditApplication = () => {
  const { data: creditApplications } = useSuspenseQuery(useCreditApplicationsQueryOptions());

  return creditApplications[0];
};

export default useMostRecentCreditApplication;
