import { useState } from "react";

import EditCardModal from "../../EditCardModal";
import { useCardDetailsPageContext } from "../../providers/CardDetailsPageProvider";

import CardBudgetInfoWithLimit from "./CardBudgetInfoWithLimit";
import CardBudgetInfoWithoutLimit from "./CardBudgetInfoWithoutLimit";

// NB(zafin): Made a separate component to make it easier to gate behind a FF without breaking existing functionality. Will delete CardBudget once we're ready to remove the FF.
const CardBudgetV2 = () => {
  const { card, cardLimits } = useCardDetailsPageContext();

  const [showEditCardModal, setShowEditCardModal] = useState(false);

  const cardHasNoLimit =
    !cardLimits?.attributes.limits?.dailyPurchase &&
    !cardLimits?.attributes.limits?.monthlyPurchase;

  return (
    <>
      {cardHasNoLimit ? (
        <CardBudgetInfoWithoutLimit setShowEditCardModal={setShowEditCardModal} />
      ) : (
        <CardBudgetInfoWithLimit cardLimit={cardLimits!} />
      )}
      {showEditCardModal && (
        <EditCardModal
          card={card}
          cardLimit={cardLimits!}
          closeModal={() => setShowEditCardModal(false)}
        />
      )}
    </>
  );
};

export default CardBudgetV2;
