import adobeLogo from "assets/merchants/adobe.svg";
import affirmLogo from "assets/merchants/affirm.svg";
import amazonLogo from "assets/merchants/amazon.svg";
import americanExpressLogo from "assets/merchants/american-express.svg";
import easypostLogo from "assets/merchants/easypost.svg";
import facebookLogo from "assets/merchants/facebook.svg";
import googleLogo from "assets/merchants/google.svg";
import klaviyoLogo from "assets/merchants/klaviyo.svg";
import microsoftLogo from "assets/merchants/microsoft.svg";
import paypalLogo from "assets/merchants/paypal.svg";
import shopifyLogo from "assets/merchants/shopify.svg";
import stripe from "assets/merchants/stripe.svg";
import tiktokLogo from "assets/merchants/tiktok.svg";
import typeformLogo from "assets/merchants/typeform.svg";
import uberLogo from "assets/merchants/uber.svg";
import walmartLogo from "assets/merchants/walmart.svg";
import wayflyerLogo from "assets/merchants/wayflyer.svg";
import wholeFoodsLogo from "assets/merchants/whole-foods.svg";

import { CounterpartyVendorKey } from "./counterpartyVendorNames";

/* eslint sort-keys: ["error", "asc", { "natural": true }] */

const counterpartyVendorLogos = {
  adobe: adobeLogo,
  affirm: affirmLogo,
  amazon: amazonLogo,
  amazonPayout: amazonLogo,
  americanExpress: americanExpressLogo,
  americanExpressPayment: americanExpressLogo,
  easypost: easypostLogo,
  facebook: facebookLogo,
  google: googleLogo,
  klaviyo: klaviyoLogo,
  microsoft: microsoftLogo,
  paypal: paypalLogo,
  paypalPayout: paypalLogo,
  shopify: shopifyLogo,
  shopifyCapital: shopifyLogo,
  shopifyPayout: shopifyLogo,
  stripe,
  tiktok: tiktokLogo,
  typeform: typeformLogo,
  uber: uberLogo,
  walmart: walmartLogo,
  wayflyer: wayflyerLogo,
  wholeFoods: wholeFoodsLogo,
} satisfies { [key in CounterpartyVendorKey]: string };

export default counterpartyVendorLogos;
