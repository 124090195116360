import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { FC } from "react";
import useConnectedStoresQueryOptions from "resources/connected-stores/queries/useConnectedStoresQueryOptions";
import { ConnectedStoreType } from "resources/connected-stores/types";
import getConnectedStoreAccountOrStoreLabel from "resources/connected-stores/utils/getConnectedStoreAccountOrStoreLabel";
import getConnectedStoreLogoWithName from "resources/connected-stores/utils/getConnectedStoreLogoWithName";
import Button from "ui/inputs/Button";
import Helper from "ui/inputs/Helper";
import { pluralize } from "utils/string";

import ConnectStoreButtonContainer from "./ConnectStoreButtonContainer";
import styles from "./ConnectStores.module.scss";

type Props = {
  type: ConnectedStoreType;
  className: string;
  redirectPathKey: string;
};

const ConnectStoresCard: FC<Props> = ({ type, className, redirectPathKey }) => {
  const { data: connectedStores = [] } = useQuery(useConnectedStoresQueryOptions());
  const numberOfConnections = connectedStores.filter(
    (integration) => integration.type === type
  ).length;
  const hasConnections = numberOfConnections > 0;

  return (
    <div className={classNames(styles.card, hasConnections ? className : styles.generic)}>
      <div className={styles.header}>
        <img
          src={getConnectedStoreLogoWithName({ connectedStoreType: type })}
          alt={type}
          className={styles.logo}
        />
      </div>

      {!hasConnections && (
        <Helper className={styles.subtitle}>
          No {getConnectedStoreAccountOrStoreLabel({ connectedStoreType: type, pluralize: true })}{" "}
          connected.
        </Helper>
      )}

      {hasConnections && (
        <Helper className={styles.subtitle} icon={<Helper.Icon variant="success" />}>
          Connected to{" "}
          {pluralize(
            numberOfConnections,
            getConnectedStoreAccountOrStoreLabel({ connectedStoreType: type })
          )}
          .
        </Helper>
      )}

      <ConnectStoreButtonContainer platform={type} redirectPathKey={redirectPathKey}>
        {({ openModal }) => (
          <Button className={styles.button} variant="secondary" onClick={openModal} size="sm">
            Connect {type} {getConnectedStoreAccountOrStoreLabel({ connectedStoreType: type })}
          </Button>
        )}
      </ConnectStoreButtonContainer>
    </div>
  );
};

export default ConnectStoresCard;
