import DebitCardRep from "reps/DebitCardRep";

export const getCardTypeName = (cardType: DebitCardRep.CardType): string => {
  switch (cardType) {
    case "VIRTUAL":
    case "PHYSICAL":
      return "Debit card";
    case "VIRTUAL_CREDIT":
    case "PHYSICAL_CREDIT":
      return "Highbeam Card";
  }
};

export const getCardTypeDescription = (cardType: DebitCardRep.CardType): string => {
  switch (cardType) {
    case "VIRTUAL":
      return "Spend via your Highbeam account.";
    case "VIRTUAL_CREDIT":
      return "Spend via your Highbeam Card limit.";
    case "PHYSICAL":
      return "Spend via your Highbeam account.";
    case "PHYSICAL_CREDIT":
      return "Spend via your Highbeam Card limit.";
  }
};
