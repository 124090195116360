import { useSuspenseQuery } from "@tanstack/react-query";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import useCapitalAccountSummaryQueryOptions from "./useCapitalAccountSummaryQueryOptions";

const useCapitalAccountSummary = <TRequired extends boolean>(
  capitalAccountGuid: string,
  params?: RequiredParam<TRequired>
) => {
  const { data: capitalAccountSummary } = useSuspenseQuery(
    useCapitalAccountSummaryQueryOptions(capitalAccountGuid)
  );

  if (params?.required && !capitalAccountSummary) {
    throw new RequiredButNotFoundError();
  }

  return capitalAccountSummary as DataRequired<typeof capitalAccountSummary, TRequired>;
};

export default useCapitalAccountSummary;
