import classNames from "classnames";
import { PropsWithChildren } from "react";

import styles from "./Teaser.module.scss";

type Props = {
  className?: string;
} & PropsWithChildren;

const Teaser: React.FC<Props> = ({ className, children }) => (
  <div className={classNames(styles.container, className)}>{children}</div>
);

export default Teaser;
