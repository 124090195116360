import { useSuspenseQuery } from "@tanstack/react-query";
import PayeeRep from "reps/PayeeRep";
import useCategorizedResource from "resources/categorized-resources/queries/useCategorizedResource";
import useTransactionSubcategoriesQueryOptions from "resources/transaction-subcategories/queries/useTransactionSubcategoriesQueryOptions";
import CopyIconTooltip from "ui/overlay/Tooltip/CopyIconTooltip/CopyIconTooltip";
import Text from "ui/typography/Text";
import { getSubcategoryBreadcrumb } from "utils/subcategories";

import styles from "./PayeeDetailsInfo.module.scss";

type RowData = {
  name: string;
  copyValue?: string;
  data?: React.ReactNode;
};

type Props = {
  payee: PayeeRep.Complete;
};

type DisplayTableProps = {
  data: RowData[];
};

export const PayeeDetailsTable: React.FC<Props> = ({ payee }) => {
  const { data: subcategories } = useSuspenseQuery(useTransactionSubcategoriesQueryOptions());

  const categorizedResource = useCategorizedResource(payee.guid);

  const subcategory = categorizedResource
    ? subcategories.find(({ guid }) => guid === categorizedResource.subcategoryGuid)
    : undefined;

  const NoValueText = () => (
    <Text size={14} weight="medium" className={styles["table-row__value"]}>
      -
    </Text>
  );

  const getTableText = (text?: string, isNumeric = false) => {
    if (!text) {
      return <NoValueText />;
    }

    return (
      <Text size={14} weight="medium" numeric={isNumeric} className={styles["table-row__value"]}>
        {text}
      </Text>
    );
  };

  const payeeDetails: RowData[] = [
    { name: "Name", data: getTableText(payee.name) },
    { name: "Email", data: getTableText(payee.emailAddress ?? undefined) },
    {
      name: "Category for transactions",
      data: getTableText(subcategory ? getSubcategoryBreadcrumb(subcategory) : undefined),
    },
  ];

  const DisplayTable = ({ data }: DisplayTableProps) => (
    <table className={styles.table}>
      <tbody>
        {data.map((row: RowData) => (
          <tr className={styles["table-row"]} key={row.name}>
            <td>
              <Text size={14} className={styles["table-row__name"]}>
                {row.name}
              </Text>
            </td>
            <td>{row.data}</td>
            <td>
              {row.copyValue && <CopyIconTooltip textToCopy={row.copyValue}></CopyIconTooltip>}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  return <DisplayTable data={payeeDetails} />;
};
