const colors = {
  black: "#000000",
  white: "#ffffff",

  blue: "#2f80ed", // TODO(alex): Do we need shades of blue?

  "yellow-focus": "#ffc700", // TODO(alex): This replaces `--hb-yellow-focus-yellow` but none of our shades from the design system match. We should resolve this.

  grey: {
    50: "#fafafa",
    100: "#f4f4f5",
    200: "#e4e3e7",
    300: "#d4d3d8",
    400: "#a1a1aa",
    500: "#71717a",
    600: "#52525b",
    700: "#3f3f46",
    800: "#27272a",
    900: "#18181b",
  },
  purple: {
    50: "#f8f7ff",
    100: "#eeebff",
    200: "#d9d3f5",
    300: "#b3a7ec",
    400: "#6650d8",
    500: "#3b20c6",
    600: "#2d1a8f",
    700: "#180a60",
    800: "#100640",
    900: "#090325",
  },
  pink: {
    50: "#fff8fb",
    100: "#ffdfee",
    200: "#fecee5",
    300: "#faa7cf",
    400: "#e966a5",
    500: "#d01b71",
    600: "#a51358",
    700: "#7c0f43",
    800: "#490626",
    900: "#290315",
  },
  orange: {
    50: "#fff9f7",
    100: "#fef1ec",
    200: "#ffdccf",
    300: "#ffac89",
    400: "#fd7f49",
    500: "#f25f20",
    600: "#883512",
    700: "#6c280a",
    800: "#541d06",
    900: "#39180a",
  },
  green: {
    50: "#f6fff8",
    100: "#ebffee",
    200: "#dbfae0",
    300: "#7edd8d",
    400: "#49ca5d",
    500: "#15a22c",
    600: "#178428",
    700: "#0c661b",
    800: "#0b4114",
    900: "#05240a",
  },
  red: {
    50: "#fff6f6",
    100: "#feecec",
    200: "#ffdede",
    300: "#f69e9e",
    400: "#ff6060",
    500: "#f13535",
    600: "#c62727",
    700: "#ae1a1a",
    800: "#6b0f0f",
    900: "#360606",
  },
  yellow: {
    50: "#fff7ed",
    100: "#ffeedc",
    200: "#ffddb9",
    300: "#ffcd95",
    400: "#ffbc72",
    500: "#ffac50",
    600: "#cc893f",
    700: "#99672f",
    800: "#664420",
    900: "#332210",
  },
} as const;

export default colors;
