import { Bank, Car, CreditCard, Globe, Hamburger, IconProps, Users } from "@phosphor-icons/react";
import facebookLogo from "assets/merchants/facebook.svg";
import googleLogo from "assets/merchants/google.svg";
import React, { ExoticComponent, ReactNode } from "react";
import { VendorType } from "reps/Insights/VendorSummaryRep";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./SpendByVendorTableVendorColumn.module.scss";

type Props = {
  name: string;
  type: VendorType;
};

const SpendByVendorTableVendorColumn: React.FC<Props> = ({ name, type }) => (
  <div className={styles.container}>
    {getIcon(name, type)}
    <Text color={colors.grey[900]} size={14} weight="medium">
      {name}
    </Text>
  </div>
);

export default SpendByVendorTableVendorColumn;

const getIcon = (name: string, type: VendorType): ReactNode => {
  if (type === "Payee") {
    return phosphorIcon(Users, { color: colors.purple[500] });
  }

  const standardizedName = name.toLowerCase().replace(/[^a-z0-9]/g, "");

  if (/^bankfees$/.test(standardizedName)) {
    return phosphorIcon(Bank);
  } else if (/^externalbankpayments$/.test(standardizedName)) {
    return phosphorIcon(Bank);
  } else if (/^facebookads$/.test(standardizedName)) {
    return imageIcon(facebookLogo);
  } else if (/^foodanddrinkrestaurants$/.test(standardizedName)) {
    return phosphorIcon(Hamburger);
  } else if (/^googleads$/.test(standardizedName)) {
    return imageIcon(googleLogo);
  } else if (/^googlesuite$/.test(standardizedName)) {
    return imageIcon(googleLogo);
  } else if (/^internationalwiretransfer/.test(standardizedName)) {
    return phosphorIcon(Globe);
  } else if (/^travelgasstations/.test(standardizedName)) {
    return phosphorIcon(Car);
  }

  return phosphorIcon(CreditCard);
};

const imageIcon = (icon: string): ReactNode => <img alt="" className={styles.icon} src={icon} />;

const phosphorIcon = (
  Icon: ExoticComponent<IconProps>,
  { color }: { color?: string } = {}
): ReactNode => <Icon className={styles.icon} color={color} />;
