import { ArrowsClockwise } from "@phosphor-icons/react";
import errorPageIcon from "assets/error-page-icon.svg";
import { useChatWidget } from "components/ChatWidget";
import { useDebugSettings } from "providers/DebugSettingsProvider";
import { FC } from "react";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./ErrorCard.module.scss";

type Props = {
  readonly error?: Error;
};

const ErrorCard: FC<Props> = ({ error }) => {
  const refreshPage = () => window.location.reload();
  const chat = useChatWidget();

  const { showDebugMessages } = useDebugSettings();
  const errorStack = showDebugMessages ? error?.stack : undefined;

  return (
    <Card className={styles.card}>
      <div className={styles.errorIconContainer}>
        <img src={errorPageIcon} className={styles.errorIcon} alt="Oh no, something went wrong!" />
      </div>

      <div className={styles.header}>
        <Text weight="bold" size={20}>
          Oh no, something went wrong!
        </Text>
        <Text className={styles.subheading} size={14}>
          We are sorry, there is a temporary issue. Please come back in a few minutes or refresh the
          page. If the issue persists, reach out to support.
        </Text>
      </div>

      <div className={styles.buttonContainer}>
        <Button variant="primary" onClick={() => refreshPage()}>
          <ArrowsClockwise size={16} />
          Refresh
        </Button>
        <Button variant="tertiary" onClick={() => chat.show()}>
          Contact support
        </Button>
      </div>

      {errorStack && (
        <div className={styles.errorStackContainer}>
          <code>{errorStack}</code>
        </div>
      )}
    </Card>
  );
};

export default ErrorCard;
