import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import UserRoleRep from "reps/UserRoleRep";
import { z } from "zod";

const schema = z.object({
  firstName: z.string().min(1, { message: "This field is required." }),
  lastName: z.string().min(1, { message: "This field is required." }),
  emailAddress: z.string().email({ message: "Please enter a valid email address." }),
  userRole: z.custom<UserRoleRep.Complete>((value) => Boolean(value), "Please select a role."),
});

export type InviteUserFormInputs = z.infer<typeof schema>;

const useInviteUserForm = () => {
  // NB(alex): For some reason the labels start of shrunken without this...
  const defaultValues = {
    firstName: "",
    lastName: "",
    emailAddress: "",
  };

  return useForm<InviteUserFormInputs>({
    defaultValues: defaultValues,
    mode: "onBlur", // Validate input on blur because button is disabled until form fields are valid.
    resolver: zodResolver(schema),
  });
};

export default useInviteUserForm;
