import logo from "assets/highbeam-logo.svg";
import React from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import isAuthenticatedState from "state/auth/isAuthenticated";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./Header.module.scss";

const HeaderLogo = () => (
  <Link to="/">
    <img src={logo} alt="logo" />
  </Link>
);

const JoinHighbeamButton = () => (
  <Link to="/sign-up">
    <Button variant="secondary" size="sm">
      {"🎊 Join Highbeam!"}
    </Button>
  </Link>
);

const LogoutButton = () => (
  <Link to="/sign-out">
    <Text size={14}>Logout</Text>
  </Link>
);

type HeaderActionsProps = {
  authenticatedActions?: React.ReactNode;
  unauthenticatedActions?: React.ReactNode;
};

const HeaderActions: React.FC<HeaderActionsProps> = ({
  authenticatedActions = <LogoutButton />,
  unauthenticatedActions = <JoinHighbeamButton />,
}) => {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  return (
    <div className={styles.actions}>
      {isAuthenticated ? authenticatedActions : unauthenticatedActions}
    </div>
  );
};

type Props = {
  logo?: React.ReactNode;
  actions?: React.ReactNode;
};

const Header: React.FC<Props> = ({ logo = <HeaderLogo />, actions = <HeaderActions /> }) => (
  <div className={styles.header}>
    <div className={styles.content}>
      {logo}
      {actions}
    </div>
  </div>
);

export default Object.assign(Header, {
  HeaderLogo: HeaderLogo,
  HeaderActions: HeaderActions,
});
