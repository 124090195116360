import env from "env";
import { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Option } from "ui/inputs/Dropdown";

import countries from "./geography/countries";

export const states: { [key: string]: string } = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
  DC: "District of Columbia",
  FM: "Micronesia",
  GU: "Guam",
  MH: "Marshall Islands",
  MP: "Northern Mariana Islands",
  PW: "Palau",
  PR: "Puerto Rico",
  VI: "Virgin Islands",
};

export const supportedStatesForCapital: { [key: string]: string } = {
  AL: "Alabama",
  AZ: "Arizona",
  AR: "Arkansas",
  CO: "Colorado",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MO: "Missouri",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  OH: "Ohio",
  PA: "Pennsylvania",
  SC: "South Carolina",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const US_COUNTRY_OPTION = {
  label: "United States",
  value: "US",
};

export const canadianProvinces: { [key: string]: string } = {
  AL: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NT: "Northwest Territories",
  NS: "Nova Scotia",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon",
};

export const mexicoStates: { [key: string]: string } = {
  AGS: "Aguascalientes",
  BC: "Baja California",
  BCS: "Baja California Sur",
  CAMP: "Campeche",
  CHIS: "Chiapas",
  CHIH: "Chihuahua",
  CDMX: "Ciudad de México",
  COAH: "Coahuila",
  COL: "Colima",
  DGO: "Durango",
  GTO: "Guanajuato",
  GRO: "Guerrero",
  HGO: "Hidalgo",
  JAL: "Jalisco",
  MEX: "México",
  MICH: "Michoacán",
  MOR: "Morelos",
  NAY: "Nayarit",
  NL: "Nuevo León",
  OAX: "Oaxaca",
  PUE: "Puebla",
  QRO: "Querétaro",
  QROO: "Quintana Roo",
  SLP: "San Luis Potosí",
  SIN: "Sinaloa",
  SON: "Sonora",
  TAB: "Tabasco",
  TAMPS: "Tamaulipas",
  TLAX: "Tlaxcala",
  VER: "Veracruz",
  YUC: "Yucatán",
  ZAC: "Zacatecas",
};

export const mexicoStateOptions: Option[] = Object.keys(mexicoStates).map((abbreviation) => ({
  label: mexicoStates[abbreviation],
  value: abbreviation,
}));

export const stateOptions: Option[] = Object.keys(states).map((abbreviation) => ({
  label: states[abbreviation],
  value: abbreviation,
}));

export const supportedStatesForCapitalOptions: Option[] = Object.entries(
  supportedStatesForCapital
).map(([abbreviation, value]) => ({
  label: value,
  value: abbreviation,
}));

export const provinceOptions: Option[] = Object.keys(canadianProvinces).map((abbreviation) => ({
  label: canadianProvinces[abbreviation],
  value: abbreviation,
}));

export const countryOptions = Object.values(countries).map(({ name, alpha2 }) => ({
  label: name,
  value: alpha2,
})) satisfies Option[];

export const getStateOptionForCountry = (country: Option | null, state: string) => {
  if (country?.value === "US") {
    return {
      label: states[state],
      value: state,
    };
  }
  if (country?.value === "CA") {
    return {
      label: canadianProvinces[state],
      value: state,
    };
  }
  if (country?.value === "MX") {
    const key = Object.keys(mexicoStates).find((key) => mexicoStates[key] === state) ?? "";
    return {
      label: state,
      value: key,
    };
  }
  // Fallback
  return {
    label: state,
    value: state,
  };
};

export type AddressSuggestionOption = {
  placeId?: string;
} & Option;

export type Address = {
  addressLine1: AddressSuggestionOption | null;
  addressLine2: string;
  city: string;
  state: Option | null;
  region?: string | null;
  zipCode: string;
  country: Option | null;
};

export const INITIAL_ADDRESS: Address = {
  addressLine1: null,
  addressLine2: "",
  city: "",
  state: null,
  region: null,
  zipCode: "",
  country: null,
};

export const INITIAL_US_ADDRESS: Address = {
  addressLine1: null,
  addressLine2: "",
  city: "",
  state: null,
  zipCode: "",
  country: US_COUNTRY_OPTION,
};

export const useGooglePlacesAutocomplete = () => {
  const [addressSuggestions, setAddressSuggestions] = useState<AddressSuggestionOption[]>([]);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    refreshSessionToken,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: env.GOOGLE_MAPS_AND_PLACES_API_KEY,
    debounce: 200,
    sessionToken: true,
  });

  useEffect(() => {
    if (!placePredictions.length) {
      return;
    }

    const placePredictionsOptions = placePredictions.map((prediction) => ({
      label: prediction.description,
      value: prediction.structured_formatting.main_text,
      placeId: prediction.place_id,
    }));
    setAddressSuggestions(placePredictionsOptions);
  }, [placePredictions]);

  const getPlaceDetails = (
    placeId?: string,
    callback?: (details: google.maps.places.PlaceResult) => void
  ) => {
    if (!placeId) {
      return;
    }

    placesService!.getDetails({ placeId, fields: ["address_components"] }, (details) => {
      if (details) callback?.(details);
    });
    refreshSessionToken();
  };

  const resetAddressSuggestions = () => {
    setAddressSuggestions([]);
  };

  return {
    addressSuggestions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    getPlaceDetails,
    resetAddressSuggestions,
  };
};
