import MultiStep from "components/layouts/MultiStep";
import { Link } from "react-router-dom";

import { useReviewLineOfferContext } from "../context/ReviewLineOfferProvider";

const ReviewLineOfferBackButton = () => {
  const { prevPathname } = useReviewLineOfferContext();

  if (!prevPathname) {
    return null;
  }

  return (
    <Link to={prevPathname}>
      <MultiStep.Controls.BackButton disabled={!Boolean(prevPathname)} />
    </Link>
  );
};

export default ReviewLineOfferBackButton;
