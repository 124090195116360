import { useSuspenseQuery } from "@tanstack/react-query";
import useOpenBankAccounts from "resources/bank-accounts/queries/useOpenBankAccounts";
import useBusinessUnitCoCustomerId from "resources/business/queries/useBusinessUnitCoCustomerId";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import { UNIT_CO_TRANSACTIONS_QUERY_KEY } from "resources/unit-co-transactions/queries/useUnitCoTransactionsQueryOptions";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { startOfYear } from "utils/date";

type Params = {
  highYieldAccountGuid: string;
};

const useHighYieldInterestYtd = ({ highYieldAccountGuid }: Params) => {
  const openBankAccounts = useOpenBankAccounts();
  const unitApi = useUnitApi();
  const customerId = useBusinessUnitCoCustomerId({ required: true });
  const shouldIncludeAdjustments = useFeatureFlag("INCLUDE_ADJUSTMENT_TRANSACTIONS_AS_INTEREST");

  const { data } = useSuspenseQuery({
    queryKey: [UNIT_CO_TRANSACTIONS_QUERY_KEY, customerId],
    queryFn: async () => {
      const highYieldAccount = openBankAccounts.find(
        (account) =>
          account.highbeamType.name === "HighYield" && account.guid === highYieldAccountGuid
      );

      if (!highYieldAccount) {
        return [];
      }

      const response = await unitApi.transactions.list({
        sort: "-createdAt",
        customerId: customerId,
        accountId: highYieldAccount?.unitCoDepositAccountId,
        since: startOfYear().format(),
        type: shouldIncludeAdjustments ? ["Interest", "Adjustment"] : ["Interest"],
      });

      return response.data;
    },
    select: (transactions) => {
      return transactions.map((t) => t.attributes.amount).reduce((a, b) => a + b, 0);
    },
  });

  return data;
};

export default useHighYieldInterestYtd;
