import { FileText as FileTextIcon } from "@phosphor-icons/react";
import { FC } from "react";
import BillSummaryRep from "reps/BillSummaryRep";
import BillPaymentStatusPill from "resources/bills/components/BillPaymentStatusPill";
import useDeleteBillMutation from "resources/bills/mutations/useDeleteBillMutation";
import usePayee from "resources/payees/queries/usePayee";
import MoneyAmountV2 from "ui/data-display/money/MoneyAmountV2";
import Button from "ui/inputs/Button";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";

import styles from "./DeleteBillModal.module.scss";

type BillOverviewProps = {
  bill: BillSummaryRep.Complete;
};

const BillOverview: FC<BillOverviewProps> = ({ bill }) => {
  const { amount, payeeGuid } = bill;
  const payee = usePayee(payeeGuid || undefined, { required: false });

  return (
    <div className={styles.billOverviewContainer}>
      <div className={styles.billOverviewBodyContainer}>
        <FileTextIcon size={24} weight="light" />
        <Text as="p">
          {amount ? (
            <>
              <Text as="strong" weight="medium">
                <MoneyAmountV2
                  amount={amount.amount}
                  currencyCode={amount.currency}
                  showCurrencySymbol
                />
              </Text>{" "}
              bill
            </>
          ) : (
            <>Bill</>
          )}
          {payee && (
            <>
              {" from "}
              <Text as="strong" weight="medium">
                {payee.name}
              </Text>
            </>
          )}
        </Text>
      </div>
      <div>
        <BillPaymentStatusPill bill={bill} />
      </div>
    </div>
  );
};

type CommonExternalProps = {
  bill: BillSummaryRep.Complete;
  onClose: () => void;
  onDeleteSuccessful: () => void;
};

type DeleteBillModalContentProps = CommonExternalProps;

const DeleteBillModalContent: FC<DeleteBillModalContentProps> = ({
  bill,
  onClose,
  onDeleteSuccessful,
}) => {
  const { canDeleteBill } = bill;

  const { mutate, isPending } = useDeleteBillMutation(bill.id, {
    onMutate: () => {
      // This should never actually happen, but just as an extra precaution, we throw an
      // error to prevent this mutation from being executed if the bill cannot be deleted.
      // This error message *would* show up in a toast notification (see the onError callback
      // in useDeleteBillMutation).
      if (!canDeleteBill) {
        throw new Error("Bill cannot be deleted");
      }
    },
    onSuccess: () => {
      onDeleteSuccessful();
      onClose();
    },
  });

  return (
    <>
      <ModalV4.Header>
        {canDeleteBill ? <>Delete bill?</> : <>Bill cannot be deleted</>}
      </ModalV4.Header>
      <ModalV4.Body className={styles.bodyContainer}>
        <BillOverview bill={bill} />
        <ModalV4.Body.Text as="p">
          {canDeleteBill ? (
            <>Bills cannot be restored after deletion.</>
          ) : (
            <>
              Paid bills cannot be deleted. If you need to delete this bill, please reach out to
              Highbeam support.
            </>
          )}
        </ModalV4.Body.Text>
      </ModalV4.Body>
      <ModalV4.Footer>
        {canDeleteBill && (
          <Button variant="danger" isLoading={isPending} onClick={() => mutate()}>
            Delete bill
          </Button>
        )}
        <ModalV4.Footer.CloseButton>Close</ModalV4.Footer.CloseButton>
      </ModalV4.Footer>
    </>
  );
};

type Props = CommonExternalProps;

const DeleteBillModal: FC<Props> = (props) => {
  return (
    <ModalV4 onClose={props.onClose}>
      <DeleteBillModalContent {...props} />
    </ModalV4>
  );
};

export default DeleteBillModal;
