import { captureException, ErrorBoundary } from "@sentry/react";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import DevTools from "components/DevTools";
import MfaModal from "components/MfaModal";
import ScrollToTopOnNavigate from "components/navigation/ScrollToTopOnNavigate";
import ErrorPage from "pages/ErrorPage";
import LoadingPage from "pages/LoadingPage";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RootRouter from "routes/RootRouter";
import { ToastContainer } from "ui/feedback/Toast";
import { ApiError } from "utils/ajax";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      // ApiErrors are captured in HighbeamBaseApi.ts
      if (!(error instanceof ApiError)) {
        captureException(error);
      }
    },
  }),
});

const App = () => (
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
        <Suspense fallback={<LoadingPage location={App.name} />}>
          <BrowserRouter>
            <ScrollToTopOnNavigate />
            <RootRouter />
            <MfaModal />
            <ToastContainer />
          </BrowserRouter>
        </Suspense>
      </ErrorBoundary>

      <DevTools />
    </QueryClientProvider>
  </RecoilRoot>
);

export default App;
