import MultiStep from "components/layouts/MultiStep";
import AddConnectionModal from "pages/AddConnectionModal";
import ExternalBankAccountsSection from "pages/settings/SettingsFinancialPartnersPage/ExternalBankAccountsSection";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import usePlaidDepositoryConnections from "resources/plaid-connections/queries/usePlaidDepositoryConnections";

import CreditApplicationBackButton from "../components/CreditApplicationBackButton";
import useGetNextPathname from "../hooks/useGetNextPathname";

import styles from "./CreditApplicationViews.module.scss";

const CONNECT_BANK_ACCOUNTS_FORM_ID = "connect-stores-form";

const ConnectBankAccountsView = () => {
  const navigate = useNavigate();
  const nextPathname = useGetNextPathname();

  const [addConnectionModalVariant, setAddConnectionModalVariant] =
    useState<PlaidLinkTokenRep.AccountType>();

  const depositoryConnections = usePlaidDepositoryConnections();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  return (
    <>
      {addConnectionModalVariant && (
        <AddConnectionModal
          accountType={addConnectionModalVariant}
          onClose={() => setAddConnectionModalVariant(undefined)}
        />
      )}

      <MultiStep.Form id={CONNECT_BANK_ACCOUNTS_FORM_ID} onSubmit={handleSubmit}>
        <ExternalBankAccountsSection setAddConnectionModalVariant={setAddConnectionModalVariant} />

        <MultiStep.Controls>
          <CreditApplicationBackButton />

          <MultiStep.Controls.NextButton
            form={CONNECT_BANK_ACCOUNTS_FORM_ID}
            disabled={depositoryConnections.length === 0}
          >
            Save and continue
          </MultiStep.Controls.NextButton>

          {nextPathname && (
            <Link to={nextPathname}>
              <MultiStep.Controls.SkipButton className={styles.skipButton}>
                I don’t have other bank accounts
              </MultiStep.Controls.SkipButton>
            </Link>
          )}
        </MultiStep.Controls>
      </MultiStep.Form>
    </>
  );
};

export default ConnectBankAccountsView;
