import {
  ArrowLineUpRight,
  DotsThreeVertical,
  CheckSquareOffset,
  Trash,
} from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import dayjs, { Dayjs, isDayjs } from "dayjs";
import { debounce } from "debounce";
import equal from "fast-deep-equal";
import { lastTransferDescription } from "pages/SendMoneyPage/utils";
import { FC, FocusEvent, FormEventHandler, useCallback, useState, useRef, useEffect } from "react";
import { Control, useController } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import BillRep from "reps/BillRep";
import BillSummaryRep from "reps/BillSummaryRep";
import { Money } from "reps/Money";
import BillRelatedPaymentsTable from "resources/bills/components/BillRelatedPaymentsTable";
import DeleteBillModal from "resources/bills/components/DeleteBillModal";
import useUpdateBillMutation from "resources/bills/mutations/useUpdateBillMutation";
import useUpdateBillStateMutation from "resources/bills/mutations/useUpdateBillStateMutation";
import { useRefreshBillQuery } from "resources/bills/queries/useBill";
import useBillRelatedPayments from "resources/bills/queries/useBillRelatedPayments";
import billDetailsAutosaveState from "resources/bills/state/billDetailsAutosaveState";
import getBillPercentPaid from "resources/bills/utils/getBillPercentPaid";
import getIsBillFullyPaid from "resources/bills/utils/getIsBillFullyPaid";
import { useActivePayeesQueryOptions } from "resources/payees/queries/usePayees";
import filterAndSortPayeesByLastTransferAt from "resources/payees/utils/filterAndSortPayeesByLastTransferAt";
import colors from "styles/colors";
import DetailsSidebarBody from "ui/data-display/DetailsSidebarBody";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import DatePicker from "ui/inputs/DatePicker";
import DropdownV2, { convertStringValueToDropdownOption } from "ui/inputs/DropdownV2";
import FieldsetV2 from "ui/inputs/FieldsetV2";
import Helper from "ui/inputs/Helper";
import MoneyInputs from "ui/inputs/MoneyInputs";
import TextInput from "ui/inputs/TextInputV2";
import Menu from "ui/overlay/Menu";
import Text from "ui/typography/Text";
import toDayjsOrNull from "utils/date/toDayjsOrNull";

import styles from "./BillDetailsForm.module.scss";
import DuplicateBillBanner from "./DuplicateBillBanner";
import useBillDetailsForm, { BillDetailsFormInputs } from "./useBillDetailsForm";

const AUTOSAVE_DEBOUNCE_DELAY = 750;
const SAVE_INDICATOR_IDLE_DELAY = 3000;

type BillDetailsFormInputProps = {
  control: Control<BillDetailsFormInputs>;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
};

type BillDetailsFormPayeeDropdownProps = BillDetailsFormInputProps & {
  onShowPaymentDetails: () => void;
  onCreateNewPayee: (defaultPayeeName: string) => void;
};

const BillDetailsFormPayeeDropdown: FC<BillDetailsFormPayeeDropdownProps> = ({
  control,
  onBlur,
  onShowPaymentDetails,
  onCreateNewPayee,
}) => {
  const { field, fieldState } = useController({ name: "payee", control });
  const [inputValue, setInputValue] = useState("");

  const { data: payees } = useQuery({
    ...useActivePayeesQueryOptions(),
    select: (payees) => {
      if (inputValue) {
        return payees;
      } else {
        return filterAndSortPayeesByLastTransferAt(payees).slice(0, 5);
      }
    },
  });

  return (
    <div className={styles.payeeDropdownContainer}>
      {field.value && (
        <div className={styles.paymentDetailsButtonContainer}>
          <button
            className={styles.paymentDetailsButton}
            type="button"
            onClick={onShowPaymentDetails}
          >
            Payment details
          </button>
        </div>
      )}

      <DropdownV2
        {...field}
        inputValue={inputValue}
        onInputChange={setInputValue}
        options={payees}
        label="Payee’s legal name"
        isClearable
        hideClearIndicator
        onBlur={(e) => {
          field.onBlur();
          onBlur?.(e);
        }}
        // NB(alex): `onCreateOption` work-around explainer:
        //
        // When adding the prop `onCreateOption`, the dropdown component switches to using `react-select`'s `CreatableSelect` under the hood.
        // Unfortunately, `react-select` does not set the correct typescript types when passing in custom data, so the following work-around is needed.
        // Specifying `onCreateOption` injects a special "__isNew__" option into the options array, but it doesn't update the typescript types of `(option) => ...` to reflect this.
        // This causes the dropdown to crash when trying to access fields on `option.data` that don't exist on this creatable ("__isNew__") option.
        // eg: `filterOption` below calls `option.data.name.toLowerCase`, but the injected option doesn't have the property `option.data.name`,
        // so the component crashes when trying to access this property on the injected option.
        //
        // That said, the injected option does have a field "__isNew__" (which isn't exposed in the typescript type),
        // which we can use to check to see if that field exists to know if the option we're dealing with is this custom injected option.
        //
        // Also worth noting:
        // - This work-around is only needed if we want to use custom data, (in this case we pass in `options: PayeeRep.Complete[]`) the way we do in this dropdown.
        // - `__isNew__` is an internal value in `react-select` so upgrading the package may break this.
        // - There may be a better solution using the `getNewOptionData` prop, but I was unable to figure out a better solution that what I landed with here.
        onCreateOption={(inputValue) => {
          onCreateNewPayee(inputValue);
        }}
        getOptionLabel={(option) => {
          // NB(alex): `onCreateOption` work-around.
          // @ts-expect-error
          if ("__isNew__" in option) return option.label;

          return option.name;
        }}
        getOptionValue={(option) => {
          return option.guid;
        }}
        blurInputOnSelect
        isOptionSelected={(option) => {
          return option?.guid === field.value?.guid;
        }}
        filterOption={(option, inputValue) => {
          if (!option.data) {
            return false;
          }

          // NB(alex): `onCreateOption` work-around.
          if ("__isNew__" in option.data) return true;

          return option.data.name.toLowerCase().includes(inputValue.toLowerCase());
        }}
        renderOption={(optionProps) => {
          if (!optionProps.data) {
            return null;
          }

          // NB(alex): `onCreateOption` work-around.
          if ("__isNew__" in optionProps.data) {
            return (
              <DropdownV2.CreateLabel onClick={() => onCreateNewPayee(inputValue)}>
                Create payee "{inputValue}"
              </DropdownV2.CreateLabel>
            );
          }

          return (
            <DropdownV2.Option
              {...optionProps}
              description={
                optionProps.data.lastTransferAt
                  ? `Last transferred: ${lastTransferDescription(optionProps.data)}`
                  : "No payments"
              }
            >
              {optionProps.label}
            </DropdownV2.Option>
          );
        }}
        renderMenu={({ children, ...menuProps }, selectRef) => {
          const inputValue = selectRef.current?.inputRef?.value;

          return (
            <DropdownV2.Menu
              {...menuProps}
              selectRef={selectRef}
              appendElement={
                !inputValue && (
                  <DropdownV2.CreateLabel onClick={() => onCreateNewPayee("")}>
                    Create payee
                  </DropdownV2.CreateLabel>
                )
              }
            >
              {children}
            </DropdownV2.Menu>
          );
        }}
      />
      {fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>{fieldState.error.message}</Helper>
      )}
    </div>
  );
};

const BillDetailsFormMoneyAmountInput: FC<BillDetailsFormInputProps> = ({ control, onBlur }) => {
  const amountController = useController({
    name: "amount.amount",
    control: control,
  });

  const currencyController = useController({
    name: "amount.currency",
    control: control,
  });

  return (
    <div>
      <MoneyInputs
        amountInput={
          <MoneyInputs.AmountInput
            {...amountController.field}
            label="Amount"
            currency={currencyController.field.value?.value ?? null}
            onBlur={(e) => {
              amountController.field.onBlur();
              onBlur?.(e);
            }}
          />
        }
        currencyDropdown={
          <MoneyInputs.CurrencyDropdown
            {...currencyController.field}
            onBlur={(e) => {
              currencyController.field.onBlur();
              onBlur?.(e);
            }}
          />
        }
      />
      {amountController.fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>
          {amountController.fieldState.error.message}
        </Helper>
      )}
      {currencyController.fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>
          {currencyController.fieldState.error.message}
        </Helper>
      )}
    </div>
  );
};

type BillDetailsFormDateInputProps = Omit<BillDetailsFormInputProps, "onBlur"> & {
  // NB(alex): the underlying date picker is buggy so `onBlur` doesn't work as-expected.
  onBlur?: () => void;
};

const BillDetailsFormInvoiceDateInput: FC<BillDetailsFormDateInputProps> = ({
  control,
  onBlur: onBlurProp,
}) => {
  const {
    field: { ref: _ref, value, onBlur: onBlurControl, onChange, ...field },
    fieldState,
  } = useController({
    name: "invoiceDate",
    control: control,
  });

  return (
    <div>
      <DatePicker
        label="Invoice date"
        {...field}
        value={value ? value.toDate() : null}
        onChange={(selected) => {
          onChange(selected ? dayjs(selected) : selected);
        }}
        isClearable
        variant="no-date"
        onCalendarClose={() => {
          onBlurControl();
          onBlurProp?.();
        }}
      />
      {fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>{fieldState.error.message}</Helper>
      )}
    </div>
  );
};

const BillDetailsFormDueDateInput: FC<BillDetailsFormDateInputProps> = ({
  control,
  onBlur: onBlurProp,
}) => {
  const {
    field: { ref: _ref, value, onBlur: onBlurControl, onChange, ...field },
    fieldState,
  } = useController({
    name: "invoiceDueDate",
    control: control,
  });

  return (
    <div>
      <DatePicker
        label="Due date"
        {...field}
        value={value ? value.toDate() : null}
        onChange={(selected) => {
          onChange(selected ? dayjs(selected) : selected);
        }}
        isClearable
        variant="no-date"
        onCalendarClose={() => {
          onBlurControl();
          onBlurProp?.();
        }}
      />
      {fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>{fieldState.error.message}</Helper>
      )}
    </div>
  );
};

const BillDetailsFormInvoiceNumberInput: FC<BillDetailsFormInputProps> = ({ control, onBlur }) => {
  const { field, fieldState } = useController({
    name: "invoiceNumber",
    control: control,
    defaultValue: "",
  });

  return (
    <div>
      <TextInput
        label="Invoice number"
        {...field}
        onBlur={(e) => {
          field.onBlur();
          onBlur?.(e);
        }}
      />
      {fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>{fieldState.error.message}</Helper>
      )}
    </div>
  );
};

const BillDetailsFormPaymentTermsInput: FC<BillDetailsFormInputProps> = ({ control, onBlur }) => {
  const {
    field: { onChange, value, ...field },
    fieldState,
  } = useController({ name: "paymentTerms", control });

  return (
    <div>
      <DropdownV2
        label="Payment terms (optional)"
        options={[
          "Net-15",
          "Net-30",
          "Net-45",
          "Net-60",
          "Net-90",
          "Due on Receipt",
          "1/10 Net-30",
          "2/10 Net-30",
        ].map(convertStringValueToDropdownOption)}
        value={value ? convertStringValueToDropdownOption(value) : null}
        onChange={(val) => onChange(val ? val.value : null)}
        onCreateOption={(val) => onChange(val)}
        isClearable
        blurInputOnSelect
        {...field}
        onBlur={(e) => {
          field.onBlur();
          onBlur?.(e);
        }}
      />
      {fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>{fieldState.error.message}</Helper>
      )}
    </div>
  );
};

const BillDetailsFormPurchaseOrderNumberInput: FC<BillDetailsFormInputProps> = ({
  control,
  onBlur,
}) => {
  const { field } = useController({
    name: "purchaseOrderNumber",
    control: control,
    defaultValue: "",
  });

  return (
    <TextInput
      label="PO number (optional)"
      {...field}
      onBlur={(e) => {
        field.onBlur();
        onBlur?.(e);
      }}
    />
  );
};

const BillDetailsFormMemoInput: FC<BillDetailsFormInputProps> = ({ control, onBlur }) => {
  const { field } = useController({
    name: "memo",
    control: control,
    defaultValue: "",
  });

  return (
    <TextInput
      label="Internal memo (optional)"
      {...field}
      onBlur={(e) => {
        field.onBlur();
        onBlur?.(e);
      }}
    />
  );
};

const shouldUpdateDateValue = (prevDate: Dayjs | null, nextDate: Dayjs | null): boolean => {
  if (isDayjs(prevDate) && isDayjs(nextDate)) {
    return !prevDate.isSame(nextDate);
  }
  return prevDate !== nextDate;
};

const shouldUpdateMoneyValue = (prevValue: Money | null, nextValue: Money | null): boolean => {
  if (!prevValue || !nextValue) {
    return prevValue !== nextValue;
  }
  return !equal(prevValue, nextValue);
};

// NB(alex): Feel free to move this to a shared util if it's needed in more places.
const getBillUpdater = (bill: BillSummaryRep.Complete, data: BillDetailsFormInputs) => {
  const amount: Money | null =
    data.amount.amount && data.amount.currency
      ? {
          amount: data.amount.amount,
          currency: data.amount.currency.value,
        }
      : null;

  return {
    ...(bill.payeeGuid !== (data.payee?.guid ?? null) && {
      payeeGuid: data.payee?.guid ?? null,
    }),
    ...(shouldUpdateMoneyValue(bill.amount ?? null, amount ?? null) && {
      amount: amount,
    }),
    ...(shouldUpdateDateValue(toDayjsOrNull(bill.invoiceDate), data.invoiceDate) && {
      invoiceDate: data.invoiceDate?.format("YYYY-MM-DD") ?? null,
    }),
    ...(shouldUpdateDateValue(toDayjsOrNull(bill.invoiceDueDate), data.invoiceDueDate) && {
      invoiceDueDate: data.invoiceDueDate?.format("YYYY-MM-DD") ?? null,
    }),

    // NB(alex): We need to default `""` to `null` or else changes will be registered & submitted. Would be nice to clean this logic up somehow, this feels like a hack.
    ...(bill.invoiceNumber !== (data.invoiceNumber || null) && {
      invoiceNumber: data.invoiceNumber || null,
    }),
    ...(bill.paymentTerms !== (data.paymentTerms || null) && {
      paymentTerms: data.paymentTerms || null,
    }),
    ...(bill.purchaseOrderNumber !== (data.purchaseOrderNumber || null) && {
      purchaseOrderNumber: data.purchaseOrderNumber || null,
    }),
    ...(bill.memo !== (data.memo || null) && {
      memo: data.memo || null,
    }),
  } satisfies BillRep.Updater;
};

type BillDetailsFormProps = {
  bill: BillSummaryRep.Complete;
  form: ReturnType<typeof useBillDetailsForm>;
  onCreatePayment: (formValues: BillDetailsFormInputs) => void;
  onDeleteSuccessful: () => void;
  onMarkAsPaid: () => void;
  onDraftBillSavedAsOpenSuccess: () => void;
  onShowPaymentDetails: () => void;
  onCreateNewPayee: (defaultPayeeName: string) => void;
};

const BillDetailsForm: FC<BillDetailsFormProps> = ({
  bill,
  form,
  onCreatePayment,
  onDeleteSuccessful,
  onMarkAsPaid,
  onDraftBillSavedAsOpenSuccess,
  onShowPaymentDetails,
  onCreateNewPayee,
}) => {
  const { control } = form;

  const navigate = useNavigate();
  const { search } = useLocation();

  const refreshBillQuery = useRefreshBillQuery(bill.id, {
    type: "all", // Ensures bill query gets refreshed even if the page has been unmounted because some of our updates are debounced.
  });

  const { mutate: updateBillStateMutation, isPending: isUpdatingBillState } =
    useUpdateBillStateMutation({
      onSuccess: (bill) => {
        notify("success", "Bill saved", {
          action: {
            text: "View bill",
            onClick: () => {
              navigate(`/payments/bills/${bill.id}${search}`);
            },
          },
        });
        onDraftBillSavedAsOpenSuccess();
        refreshBillQuery();
      },
    });

  const { mutate: updateBillStateToPaidMutation, isPending: isUpdatingBillStateToPaid } =
    useUpdateBillStateMutation({
      onSuccess: (bill) => {
        notify("success", "Bill marked as paid", {
          action: {
            text: "View bill",
            onClick: () => {
              navigate(`/payments/bills/${bill.id}${search}`);
            },
          },
        });
        onMarkAsPaid();
        refreshBillQuery();
      },
    });

  const setAutosaveState = useSetRecoilState(billDetailsAutosaveState);
  const resetAutosaveStatus = useCallback(
    () =>
      setAutosaveState((current) =>
        current.saveStatus === "saved" ? { ...current, saveStatus: "idle" } : current
      ),
    [setAutosaveState]
  );
  const saveIndicatorIdleTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Clear the save indicator timeout (and reset the autosave status) if the component unmounts before it fires.
  useEffect(
    () => () => {
      const saveIndicatorIdleTimeout = saveIndicatorIdleTimeoutRef.current;
      if (saveIndicatorIdleTimeout) {
        resetAutosaveStatus();
        clearTimeout(saveIndicatorIdleTimeout);
      }
    },
    [resetAutosaveStatus]
  );

  const { mutateAsync: updateBill } = useUpdateBillMutation(bill.id, {
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
      setAutosaveState((current) => ({ ...current, saveStatus: "idle" }));
    },
    onSuccess: () => {
      setAutosaveState((current) => ({ ...current, saveStatus: "saved" }));
      saveIndicatorIdleTimeoutRef.current = setTimeout(() => {
        resetAutosaveStatus();
        saveIndicatorIdleTimeoutRef.current = null;
      }, SAVE_INDICATOR_IDLE_DELAY);
      refreshBillQuery();
    },
  });

  const onInputBlur = debounce(
    useCallback(async () => {
      const data = form.getValues();
      const updater = getBillUpdater(bill, data);

      if (Object.keys(updater).length > 0) {
        setAutosaveState((current) => ({ ...current, saveStatus: "saving" }));
        await updateBill(updater);
      }
    }, [bill, form, setAutosaveState, updateBill]),
    AUTOSAVE_DEBOUNCE_DELAY
  );

  // We assume the bill's state is either: Draft | Open
  const onFormSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      if (bill.state === BillRep.State.Draft) {
        e.preventDefault();
        updateBillStateMutation({ billId: bill.id, state: BillRep.State.Open });
      } else {
        return form.handleSubmit(async (data) => {
          const updater = getBillUpdater(bill, data);
          if (Object.keys(updater).length > 0) {
            await updateBill(updater);
          }
          onCreatePayment(data);
        })(e);
      }
    },
    [bill, form, onCreatePayment, updateBillStateMutation, updateBill]
  );

  const [isDeleteBillOpen, setIsDeleteBillOpen] = useState(false);

  const isBillFullyPaid = getIsBillFullyPaid(bill);

  const billPercentPaid = getBillPercentPaid(bill);

  const relatedPayments = useBillRelatedPayments({ billIds: [bill.id] });

  return (
    <>
      <form className={styles.form} onSubmit={onFormSubmit}>
        <DetailsSidebarBody
          main={
            <DetailsSidebarBody.Main className={styles.main}>
              <DetailsSidebarBody.Section>
                <FieldsetV2>
                  {bill.duplicateOfBillId && (
                    <DuplicateBillBanner duplicateBillId={bill.duplicateOfBillId} />
                  )}
                  <FieldsetV2.Legend size={16} weight="bold">
                    Bill info
                  </FieldsetV2.Legend>

                  <BillDetailsFormPayeeDropdown
                    control={control}
                    onBlur={onInputBlur}
                    onShowPaymentDetails={onShowPaymentDetails}
                    onCreateNewPayee={onCreateNewPayee}
                  />

                  <BillDetailsFormMoneyAmountInput control={control} onBlur={onInputBlur} />

                  <FieldsetV2.Row columns={2}>
                    <BillDetailsFormInvoiceDateInput control={control} onBlur={onInputBlur} />

                    <BillDetailsFormDueDateInput control={control} onBlur={onInputBlur} />
                  </FieldsetV2.Row>

                  <FieldsetV2.Row columns={2}>
                    <BillDetailsFormInvoiceNumberInput control={control} onBlur={onInputBlur} />

                    <BillDetailsFormPaymentTermsInput control={control} onBlur={onInputBlur} />
                  </FieldsetV2.Row>

                  <BillDetailsFormPurchaseOrderNumberInput control={control} onBlur={onInputBlur} />

                  <BillDetailsFormMemoInput control={control} onBlur={onInputBlur} />
                </FieldsetV2>
              </DetailsSidebarBody.Section>

              {relatedPayments.length > 0 && (
                <DetailsSidebarBody.Section>
                  <div className={styles.paymentsTableHeader}>
                    <Text size={16} weight="bold" style={{ width: "100%", display: "flex" }}>
                      Payments
                    </Text>
                    {billPercentPaid !== undefined && (
                      <Text size={14} color={colors.grey[600]} style={{ marginLeft: "auto" }}>
                        Bill {billPercentPaid}% paid
                      </Text>
                    )}
                  </div>

                  <BillRelatedPaymentsTable bill={bill} />
                </DetailsSidebarBody.Section>
              )}
            </DetailsSidebarBody.Main>
          }
          footer={
            <DetailsSidebarBody.Footer className={styles.footer}>
              <Button
                variant="primary"
                fullWidth
                type="submit"
                // NB(lev): We don't use the update bill mutation pending state to control the loading state
                // here, because we don't want to show the loading state when the bill is being auto-saved.
                // We use the form state instead, to show the loading state when the form is explicitly submitted.
                isLoading={
                  isUpdatingBillState || isUpdatingBillStateToPaid || form.formState.isSubmitting
                }
                disabled={isBillFullyPaid}
                tooltip={isBillFullyPaid && "This bill has already been paid."}
              >
                {bill.state === BillRep.State.Draft ? (
                  <>Save as bill</>
                ) : (
                  <>
                    Create payment <ArrowLineUpRight size={20} />
                  </>
                )}
              </Button>

              <Menu
                button={
                  <Button variant="tertiary" isSquare>
                    <DotsThreeVertical size={20} />
                  </Button>
                }
                placement={{ bottom: 48 }}
              >
                {bill.state !== BillRep.State.Paid && (
                  <Menu.Item
                    icon={<CheckSquareOffset />}
                    onClick={async () => {
                      await updateBillStateToPaidMutation({
                        billId: bill.id,
                        state: BillRep.State.Paid,
                      });
                    }}
                  >
                    Mark bill as paid
                  </Menu.Item>
                )}
                <Menu.Item
                  icon={<Trash />}
                  variant="danger"
                  onClick={() => setIsDeleteBillOpen(true)}
                >
                  Delete bill
                </Menu.Item>
              </Menu>
            </DetailsSidebarBody.Footer>
          }
        />
      </form>

      {isDeleteBillOpen && (
        <DeleteBillModal
          bill={bill}
          onClose={() => setIsDeleteBillOpen(false)}
          onDeleteSuccessful={onDeleteSuccessful}
        />
      )}
    </>
  );
};

export default BillDetailsForm;
