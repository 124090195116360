import classNames from "classnames";
import React, { FC } from "react";
import { InfoIconVariant } from "ui/icons/InfoIcon";
import Text from "ui/typography/Text";

import Helper from "../Helper";

import styles from "./InputWrapper.module.scss";

export type CommonInputProps = {
  id?: string;
  disabled?: boolean;
  className?: string;
  prefixValue?: string;
  suffixValue?: string;
  hasError?: boolean;

  /**
   * DEPRECATED: Please use `Helper` directly below your input instead of passing in this prop.
   */
  errorMessage?: string | null;
  append?: React.ReactElement | string;
  label?: string;
  hideBorders?: boolean;
  textArea?: boolean;
};

export type Props = {
  children: React.ReactElement;
  focusOutline: boolean;
  showLabelAsFocused: boolean;
  hideCursor?: boolean;
  icon?: React.ReactElement;
  onIconClick?: () => void;
  onClick?: () => void;
  hasValue?: boolean;
  errorVariant?: InfoIconVariant;
} & CommonInputProps;

export const shouldShowLabelAsFocused = (
  label: string | undefined,
  value: string | undefined,
  placeholder: string | undefined,
  focused: boolean
): boolean => Boolean(label) && (value?.trim() !== "" || Boolean(placeholder) || focused);

const InputWrapper: FC<Props> = ({
  children,
  focusOutline,
  showLabelAsFocused,
  hideCursor,
  id,
  className,
  label,
  icon,
  onIconClick,
  onClick,
  prefixValue,
  suffixValue,
  append,
  hasValue,
  hasError,
  errorMessage,
  disabled = false,
  hideBorders = false,
  textArea = false,
  errorVariant = "error",
}) => {
  const showPrefix = prefixValue && (!label || showLabelAsFocused);

  return (
    <div className={classNames(styles["form-group__wrapper"], className)}>
      <div
        className={classNames({
          [styles["form-group"]]: true,
          [styles["form-group--hide-borders"]]: hideBorders,
          [styles["form-group--focused"]]: showLabelAsFocused,
          [styles["form-group--disabled"]]: disabled,
          [styles["form-group--has-prefix"]]: prefixValue,
          [styles["form-group--error"]]: hasError,
          [styles["form-group--focused-outline"]]: focusOutline,
          [styles["form-group--hide-cursor"]]: hideCursor,
        })}
      >
        <div
          className={classNames({
            [styles["form-control__wrapper"]]: true,
            [styles["form-control__wrapper--has-icon"]]: hasValue && icon,
            [styles["form-control__wrapper--text-area"]]: textArea,
          })}
        >
          {showPrefix && (
            <Text weight="medium" className={styles["form-control__input-prefix"]} size={14}>
              {prefixValue}
            </Text>
          )}

          {React.cloneElement(children, { className: styles["form-control"] })}

          {label && (
            <label className={styles["form-control__label"]} htmlFor={id}>
              {label}
            </label>
          )}
          {suffixValue && <div className={styles["form-control__input-suffix"]}>{suffixValue}</div>}
          {icon && (
            <div className={styles["form-control__icon"]} onClick={onIconClick || onClick}>
              {icon}
            </div>
          )}
        </div>
        {append && (
          <div className={classNames(styles["form-group__append"], disabled && styles.disabled)}>
            {typeof append === "string" ? (
              <Text size={14} className={styles.appendText}>
                {append}
              </Text>
            ) : (
              append
            )}
          </div>
        )}
      </div>
      {hasError && errorMessage && (
        <Helper icon={<Helper.Icon variant={errorVariant} />}>{errorMessage}</Helper>
      )}
    </div>
  );
};

export default InputWrapper;
