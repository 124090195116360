import checkIcon from "assets/check-deposit-icon.svg";
import PaymentDetailsCard from "components/PaymentDetailsCard";
import { PaymentMethod } from "pages/SendMoneyPage/utils";
import { useRecoilValue } from "recoil";
import checkDepositState from "state/checkDeposit";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import StepHeader from "ui/navigation/Steps/StepHeader";
import Text from "ui/typography/Text";

import styles from "./ConfirmDetails.module.scss";

type Props = {
  isLoading: boolean;
  onChangeFrontImage: () => void;
  onChangeBackImage: () => void;
  onPrevPress: () => void;
  onSubmit: () => void;
};

const ConfirmDetails: React.FC<Props> = ({
  isLoading,
  onChangeFrontImage,
  onChangeBackImage,
  onPrevPress,
  onSubmit,
}) => {
  const deposit = useRecoilValue(checkDepositState);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <>
      <StepHeader
        stepNumber={4}
        title="Create deposit"
        handleBackButtonClick={onPrevPress}
        disableForwardButton
      />

      <form id="create-deposit" onSubmit={handleSubmit}>
        <div>
          <PaymentDetailsCard
            paymentMethod={PaymentMethod.CHECK}
            iconBesideAmount={<img src={checkIcon} alt="check icon" className={styles.checkIcon} />}
            amountInCents={deposit.amountInCents!}
            toLabel="Deposit to"
            to={deposit.account?.label}
            descriptionMessage={deposit.description}
            footer={
              <div>
                <div className={styles.frontOfCheck}>
                  <div className={styles.title}>
                    <Text size={16} weight="bold" color={colors.grey[900]}>
                      Front of check
                    </Text>
                    <Button variant="tertiary" size="sm" onClick={onChangeFrontImage}>
                      Change
                    </Button>
                  </div>
                  <img src={deposit.front.image!} alt="front" className={styles.depositImage}></img>
                </div>
                <div className={styles.backOfCheck}>
                  <div className={styles.title}>
                    <Text size={16} weight="bold" color={colors.grey[900]}>
                      Back of check
                    </Text>
                    <Button variant="tertiary" size="sm" onClick={onChangeBackImage}>
                      Change
                    </Button>
                  </div>
                  <img src={deposit.back.image!} alt="back" className={styles.depositImage}></img>
                </div>
                <Text size={12} weight="regular" className={styles.infoText}>
                  Please make sure the images are clear and change if necessary.
                </Text>
              </div>
            }
          />
          <div className={styles.buttons}>
            <Button type="submit" form="create-deposit" variant="primary" isLoading={isLoading}>
              Deposit check
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ConfirmDetails;
