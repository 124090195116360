import classNames from "classnames";
import { CSSProperties, ReactElement, ReactNode, cloneElement } from "react";
import CountBadge from "ui/data-display/CountBadge";

import styles from "./SegmentControlsButton.module.scss";

export type SegmentControlsButtonSize = "sm" | "md";

const getDefaultIconSize = (size: SegmentControlsButtonSize) => {
  return { sm: 16, md: 20 }[size];
};

type Props = {
  children: ReactNode;
  className?: string;
  count?: number;
  icon?: ReactElement<{ size?: number }>;
  isActive?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
  size: SegmentControlsButtonSize;
};

const SegmentControlsButton = ({
  children,
  className,
  count,
  icon,
  isActive,
  style,
  size,
  onClick,
}: Props) => {
  return (
    <button
      onClick={onClick}
      className={classNames(styles.button, isActive && styles.active, styles[`size-${size}`])}
      aria-selected={isActive}
      role="tab"
      type="button"
    >
      <div className={classNames(className, styles.content)} style={style}>
        {icon && (
          <span className={styles.iconContainer}>
            {cloneElement(icon, { size: icon.props.size ?? getDefaultIconSize(size) })}
          </span>
        )}

        <div className={classNames(styles.label)}>{children}</div>

        {count !== undefined && (
          <CountBadge count={count} color={isActive ? "grey-700" : "grey-500"} />
        )}
      </div>
    </button>
  );
};

export default SegmentControlsButton;
