import { ArrowRight } from "@phosphor-icons/react";
import logos from "assets/add-connection-modal-logos.svg";
import { FC } from "react";
import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import usePlaidLinkToken from "resources/plaid-connections/queries/usePlaidLinkToken";
import useRefreshPlaidConnectionsQuery from "resources/plaid-connections/queries/useRefreshPlaidConnectionsQuery";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useHighbeamPlaidLink from "utils/customHooks/useHighbeamPlaidLink";

import styles from "./AddNewInstitution.module.scss";

type Props = {
  accountType: PlaidLinkTokenRep.AccountType;
  onComplete?: () => void;
};

const AddNewInstitution: FC<Props> = ({ accountType, onComplete }) => {
  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQuery();
  const isCredit = accountType === "CREDIT";

  const { openPlaid } = useHighbeamPlaidLink({
    linkToken: usePlaidLinkToken({
      accountType: accountType,
      allowAccountSelectionOnUpdate: false,
    }),
    onComplete: () => {
      refreshPlaidConnectionsQuery();
      onComplete?.();
    },
  });

  return (
    <div className={styles.container}>
      <div>
        <img src={logos} alt="" />
      </div>
      <div>
        <Text size={16} weight="bold">
          Add {isCredit ? "a" : "an"} {isCredit ? "card" : "account"} from a new login
        </Text>
        <Text size={14} weight="regular">
          Connect {isCredit ? "a" : "an"} {isCredit ? "card" : "account"} from many of the
          institutions we support via Plaid.
        </Text>
      </div>
      <div>
        <Button size="sm" variant="tertiary" onClick={openPlaid}>
          Log in <ArrowRight />
        </Button>
      </div>
    </div>
  );
};

export default AddNewInstitution;
