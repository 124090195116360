import { ArrowUpRight, CalendarCheck } from "@phosphor-icons/react";
import successGif from "assets/success.gif";
import PaymentDetailsCard from "components/PaymentDetailsCard";
import { Dayjs } from "dayjs";
import {
  BankingInfo,
  PaymentMethod,
  getSendMoneyDeliveryMessage,
  scheduledAfter3pmOrWeekend,
  InternationalBankingInfo,
  PaymentMethodOption,
} from "pages/SendMoneyPage/utils";
import { useNavigate } from "react-router-dom";
import colors from "styles/colors";
import InfoBox from "ui/data-display/InfoBox";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import { TransferOption } from "utils/transfers";

import PayeeInfo, { Recipient } from "../PayeeInfo";

import styles from "./TransferScheduled.module.scss";

type Props = {
  from: TransferOption;
  amountInCents: number;
  to: Recipient;
  scheduledDate: Dayjs;
  scheduledPayment: boolean;
  bankingInfo: BankingInfo;
  internationalBankingInfo?: InternationalBankingInfo;
  resetSendMoney: () => void;
  paymentMethodOption: PaymentMethodOption;
  invoiceName?: string;
};

const TransferScheduled: React.FC<Props> = ({
  from,
  amountInCents,
  to,
  scheduledDate,
  scheduledPayment,
  bankingInfo,
  internationalBankingInfo,
  resetSendMoney,
  paymentMethodOption,
  invoiceName,
}) => {
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const navigate = useNavigate();

  return (
    <>
      {scheduledPayment ? (
        <CalendarCheck size={32} weight="light" color={colors.purple[500]} />
      ) : (
        <img className={styles.successGif} src={`${successGif}?a=${Math.random()}`} alt="success" />
      )}
      <div className={styles.heading}>
        <h1 className={styles.text}>
          {scheduledPayment
            ? "Payment scheduled"
            : isAllowedToApprovePayments
              ? "Payment sent"
              : "Payment submitted for approval"}
        </h1>
        {!isAllowedToApprovePayments && (
          <Text color={colors.grey[500]} size={16}>
            An email notification has been sent to all admins
          </Text>
        )}
      </div>
      <PaymentDetailsCard
        paymentMethod={paymentMethodOption.value}
        iconBesideAmount={<ArrowUpRight size={16} />}
        amountInCents={amountInCents}
        from={from.label}
        to={<PayeeInfo to={to} infoText="Recipient was sent a confirmation email." />}
        scheduledDate={scheduledDate}
        routingNumber={bankingInfo?.routingNumber}
        accountNumber={
          paymentMethodOption.value === PaymentMethod.INTERNATIONAL
            ? internationalBankingInfo?.accountNumber?.value
            : bankingInfo?.accountNumber
        }
        iban={
          paymentMethodOption.value === PaymentMethod.INTERNATIONAL
            ? internationalBankingInfo?.iban?.value
            : undefined
        }
        swift={
          paymentMethodOption.value === PaymentMethod.INTERNATIONAL
            ? internationalBankingInfo?.swift?.value
            : undefined
        }
        deliveryMessage={getSendMoneyDeliveryMessage(paymentMethodOption.value, scheduledPayment)}
        invoiceName={invoiceName}
      />
      <div className={styles.buttons}>
        <Button className={styles.button} variant="tertiary" onClick={resetSendMoney}>
          {isAllowedToApprovePayments ? "Make another payment" : "Draft another payment"}
        </Button>
        <Button className={styles.button} variant="primary" onClick={() => navigate("/")} autoFocus>
          Back to dashboard
        </Button>
      </div>
      {scheduledAfter3pmOrWeekend(scheduledDate) && (
        <div className={styles.infoboxContainer}>
          <InfoBox
            iconSize={40}
            variant="info"
            text="Transactions made after 3:00 PM ET or on a weekend or holiday will be processed the next business day."
          />
        </div>
      )}
    </>
  );
};

export default TransferScheduled;
