import usePendingDrawdown from "resources/line-of-credit/queries/usePendingDrawdown";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import InfoBox from "ui/data-display/InfoBox";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";

import { usePendingDrawdownFlexpaneContext } from "../../context/PendingDrawdownFlexpaneProvider";

import styles from "./DrawdownDetails.module.scss";

const PendingDrawdownDetails = () => {
  const { pendingDrawdownGuid } = usePendingDrawdownFlexpaneContext();
  const pendingDrawdown = usePendingDrawdown(pendingDrawdownGuid, { required: true });

  return (
    <div>
      <InfoBox
        variant="info"
        iconSize={24}
        text={
          <>
            This drawdown is under review by Highbeam to verify that the drawdown is for inventory
            spend. <strong>Review can take up to 3 business days.</strong>
          </>
        }
      />
      <Divider />
      <MoneyAmount
        cents={pendingDrawdown.amount}
        withCents={pendingDrawdown.amount % 100 !== 0}
        direction="negative"
        weight="medium"
        size={20}
      />
      <Divider />
      <div className={styles.drawdownDetails}>
        <Text size={16} color={colors.grey[900]} weight="bold">
          Drawdown details
        </Text>
        <div className={styles.drawdownDetailsDescription}>
          <Text size={14} color={colors.grey[600]}>
            Description
          </Text>
          <Text size={14} color={colors.grey[900]} weight="medium">
            {pendingDrawdown.note}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default PendingDrawdownDetails;
