import { useSuspenseQuery } from "@tanstack/react-query";
import LoadingPage from "pages/LoadingPage";
import { Navigate } from "react-router-dom";
import BankApplicationRep from "reps/BankApplicationRep";
import useCreateBankApplicationMutation from "resources/bank-application/mutations/useCreateBankApplicationMutation";
import useBankApplicationQueryOptions from "resources/bank-application/queries/useBankApplicationQueryOptions";
import useMountEffect from "utils/customHooks/useMountEffect";

import BankApplicationCreated from "./BankApplicationCreated";
import BankApplicationPending from "./BankApplicationPending";

const OnboardingBankApplicationPage = () => {
  const { data: bankApplication } = useSuspenseQuery(useBankApplicationQueryOptions());

  const { mutate: createBankApplication } = useCreateBankApplicationMutation();

  useMountEffect(() => {
    if (!bankApplication) {
      createBankApplication();
    }
  });

  if (!bankApplication) {
    return <LoadingPage location="NoBankApplication" />;
  } else if (bankApplication.status === BankApplicationRep.Status.FormCreated) {
    return <BankApplicationCreated bankApplication={bankApplication} />;
  } else if (bankApplication.status === BankApplicationRep.Status.Approved) {
    return <Navigate replace to="/onboarding/get-started" />;
  } else {
    return <BankApplicationPending bankApplication={bankApplication} />;
  }
};

export default OnboardingBankApplicationPage;
