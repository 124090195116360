import { Transaction } from "@highbeam/unit-node-sdk";
import PaymentDetailsRep from "reps/PaymentDetailsRep";

import { HighbeamPaymentStatus } from "./paymentsTypes";

type HighbeamTransactionType =
  | "cashback"
  | "failedPaymentTransaction"
  | "internationalWireTransaction"
  | "recurringCreditAchPayment"
  | "LineOfCreditPayment"
  | "ChargeCardCreditAccountRepayment";

export type TransactionType = Transaction["type"] | HighbeamTransactionType;

export type BaseHighbeamTransaction = {
  id: string;
  accountId: string;
  type: TransactionType;
  createdAt: string;
  formattedCreatedAt: string;
  accountName: string;
  counterpartyName: string;
  counterpartyIcon?: string;
  counterpartyFormattedName?: string;
  direction: "Credit" | "Debit";
  shortMethodName: string;
  longMethodName: string;
  summary: string;
  category: string;
  amountInCents: number;
  balanceInCents?: number;
  paymentStatus?: HighbeamPaymentStatus;
  paymentEstimatedDate?: string;
  formattedPaymentEstimatedDate?: string;
  generalPaymentMetadataGuid?: string;
  payeeGuid?: string;
};

// TODO: Refactor our transactions code to avoid grouping Payments and Transactions

export type HighbeamAdjustmentTransaction = BaseHighbeamTransaction & {
  type: "adjustmentTransaction";
  description: string;
};

export type HighbeamAtmTransaction = BaseHighbeamTransaction & {
  type: "atmTransaction";
  cardId: string;
  atmName: string;
  atmLocation?: string;
  surchargeInCents: number;
  formattedSurcharge: string;
};

export type HighbeamBookTransaction = BaseHighbeamTransaction & {
  type: "bookTransaction";
};

export type HighbeamCardTransaction = BaseHighbeamTransaction & {
  type: "cardTransaction";
  cardId: string;
  merchantCategory?: string;
  merchantLocation?: string;
};

export type HighbeamCardReversalTransaction = BaseHighbeamTransaction & {
  type: "cardReversalTransaction";
};

export type HighbeamDishonoredAchTransaction = BaseHighbeamTransaction & {
  type: "dishonoredAchTransaction";
  description: string;
  counterpartyRoutingNumber: string;
  traceNumber: string;
  reason: string;
};

export type HighbeamInternationalWireTransaction = BaseHighbeamTransaction & {
  type: "internationalWireTransaction";
  description?: string;
  addenda?: string;
  receivedAmountInCents: number;
  receivedCurrency: string;
  internationalPaymentType: PaymentDetailsRep.InternationalPaymentType;
  internationalWireStatus: string;
  internationalWireFeeInCents: number;
  payeeGuid: string;
  payeeName: string | null;
  paymentMetadataGuid: string;
};

export type HighbeamFailedPaymentTransaction = BaseHighbeamTransaction & {
  type: "failedPaymentTransaction";
  paymentStatus: HighbeamPaymentStatus;
  reason: string;
};

export type HighbeamCheckDepositTransaction = BaseHighbeamTransaction & {
  type: "checkDepositTransaction";
};

export type HighbeamPurchaseTransaction = BaseHighbeamTransaction & {
  type: "purchaseTransaction";
  cardId: string;
  merchantCategory?: string;
  merchantLocation?: string;
};

export type HighbeamOriginatedAchTransaction = BaseHighbeamTransaction & {
  type: "originatedAchTransaction";
  description: string;
  addenda?: string;
  counterpartyRoutingNumber: string;
  counterpartyAccountNumber: string;
  counterpartyAccountType: string;
  paymentGuid?: string;
  traceNumber?: number;
};

export type HighbeamReceivedAchTransaction = BaseHighbeamTransaction & {
  type: "receivedAchTransaction";
  description: string;
  addenda?: string;
  counterpartyRoutingNumber: string;
  traceNumber: string;
};

export type HighbeamReturnedAchTransaction = BaseHighbeamTransaction & {
  type: "returnedAchTransaction";
  counterpartyRoutingNumber: string;
  reason: string;
};

export type HighbeamReturnedReceivedAchTransaction = BaseHighbeamTransaction & {
  type: "returnedReceivedAchTransaction";
  reason: string;
};

export type HighbeamWireImadOmad = {
  imad: string;
  omad: string;
};

export type HighbeamWireTransaction = BaseHighbeamTransaction & {
  type: "wireTransaction";
  description?: string;
  counterpartyRoutingNumber: string;
  counterpartyAccountNumber: string;
  counterpartyAccountType: string;
  imadOmad?: HighbeamWireImadOmad;
};

export type HighbeamTransaction =
  | BaseHighbeamTransaction
  | HighbeamAdjustmentTransaction
  | HighbeamAtmTransaction
  | HighbeamBookTransaction
  | HighbeamCardTransaction
  | HighbeamCardReversalTransaction
  | HighbeamDishonoredAchTransaction
  | HighbeamInternationalWireTransaction
  | HighbeamOriginatedAchTransaction
  | HighbeamPurchaseTransaction
  | HighbeamReceivedAchTransaction
  | HighbeamReturnedAchTransaction
  | HighbeamReturnedReceivedAchTransaction
  | HighbeamWireTransaction;

export const isAdjustmentTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamAdjustmentTransaction => transaction.type === "adjustmentTransaction";

export const isAtmTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamAtmTransaction => transaction.type === "atmTransaction";

export const isBookTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamBookTransaction => transaction.type === "bookTransaction";

export const isCardTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamCardTransaction => transaction.type === "cardTransaction";

export const isCardReversalTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamCardReversalTransaction => transaction.type === "cardReversalTransaction";

export const isDishonoredAchTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamDishonoredAchTransaction =>
  transaction.type === "dishonoredAchTransaction";

export const isInternationalWireTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamInternationalWireTransaction =>
  transaction.type === "internationalWireTransaction";

export const isOriginatedAchTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamOriginatedAchTransaction =>
  transaction.type === "originatedAchTransaction";

export const isFailedPaymentTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamFailedPaymentTransaction =>
  transaction.type === "failedPaymentTransaction";

export const isPurchaseTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamPurchaseTransaction => transaction.type === "purchaseTransaction";

export const isReceivedAchTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamReceivedAchTransaction => transaction.type === "receivedAchTransaction";

export const isReturnedAchTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamReturnedAchTransaction => transaction.type === "returnedAchTransaction";

export const isReturnedReceivedAchTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamReturnedReceivedAchTransaction =>
  transaction.type === "returnedReceivedAchTransaction";

export const isWireTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamWireTransaction => transaction.type === "wireTransaction";

export const isCheckDepositTransaction = (
  transaction: HighbeamTransaction
): transaction is HighbeamCheckDepositTransaction => transaction.type === "checkDepositTransaction";

// Transaction Tags should only be used when general payment metadata is not sufficient
// or for quick dressing up of a transaction for display purposes.
export type TransactionTags = {
  transactionType?: TransactionType;
  counterpartyName?: string;
  transactionCompanyName?: string;
  transactionDescription?: string;
  transactionMerchantName?: string;
  transactionSummary?: string;
  transactionUnitType?: string;
};

export type AccountTags = {
  businessGuid?: string;
  bankAccountGuid?: string;
};

export type CardTags = {
  name?: string;
  businessGuid?: string;
  bankAccountGuid?: string;
  debitCardGuid?: string;
};

export enum InternationalWireStatus {
  NEW = "new",
  READY_TO_SEND = "ready_to_send",
  COMPLETED = "completed",
  FAILED = "failed",
  RELEASED = "released",
  SUSPENDED = "suspended",
  AWAITING_AUTHORISATION = "awaiting_authorisation",
  SUBMITTED = "submitted",
  AUTHORISED = "authorised",
  DELETED = "deleted",
}
