import classNames from "classnames";
import { CSSProperties } from "react";
import { TabConfig } from "utils/tabs/useTabState";

import styles from "./SegmentControls.module.scss";
import SegmentControlsButton, { SegmentControlsButtonSize } from "./SegmentControlsButton";

type SegmentControlsSize = SegmentControlsButtonSize;

type Props<TTabs extends TabConfig> = {
  className?: string;
  activeTab: keyof TTabs;
  setActiveTab: (tab: keyof TTabs) => void;
  tabs: TTabs;
  style?: CSSProperties;
  size: SegmentControlsSize;
};

const SegmentControls = <TTabs extends TabConfig>({
  className,
  activeTab,
  setActiveTab,
  tabs,
  style,
  size,
}: Props<TTabs>) => {
  const tabsArray = Object.entries(tabs);
  return (
    <div className={classNames(className, styles.container, styles[`size-${size}`])} style={style}>
      {tabsArray.map(([tabName, { icon, label, count }]) => (
        <SegmentControlsButton
          size={size}
          key={tabName}
          icon={icon}
          isActive={tabName === activeTab}
          count={count}
          onClick={() => setActiveTab(tabName)}
        >
          {label}
        </SegmentControlsButton>
      ))}
    </div>
  );
};

export default Object.assign(SegmentControls, {
  Button: SegmentControlsButton,
});
