import { CalendarCheck, Coin, CurrencyCircleDollar, Prohibit } from "@phosphor-icons/react";
import chargeCardPhysicalLogo from "assets/charge-card-physical-icon.svg";
import chargeCardVirtualLogo from "assets/charge-card-virtual-icon.svg";
import debitCardPhysicalLogo from "assets/debit-card-physical-icon.svg";
import debitCardVirtualLogo from "assets/debit-card-virtual-icon.svg";
import React from "react";
import DebitCardRep from "reps/DebitCardRep";

import { CreateCardModalType } from "../utils";

import CardTag from "./CardTag";
import styles from "./SelectCard.module.scss";
import SelectCardOption from "./SelectVirtualCardOption";

type SelectCardProps = {
  createCardType: CreateCardModalType;
  handleNext: () => void;
  setCardType: (cardType: DebitCardRep.CardType) => void;
};

const SelectCard: React.FC<SelectCardProps> = ({ createCardType, handleNext, setCardType }) => (
  <div className={styles.chooseCardContainer}>
    {createCardType === "Virtual" && (
      <VirtualCardOptions handleNext={handleNext} setCardType={setCardType} />
    )}
    {createCardType === "Physical" && (
      <PhysicalCardOptions handleNext={handleNext} setCardType={setCardType} />
    )}
  </div>
);

const VirtualCardOptions: React.FC<{
  handleNext: (cardType?: DebitCardRep.CardType) => void;
  setCardType: (cardType: DebitCardRep.CardType) => void;
}> = ({ handleNext, setCardType }) => (
  <>
    <SelectCardOption
      cardType={"VIRTUAL_CREDIT"}
      handleNext={handleNext}
      setCardType={setCardType}
      img={
        <img
          className={styles.chargeCardVirtualLogo}
          src={chargeCardVirtualLogo}
          alt="Virtual Highbeam Card icon"
        />
      }
      tags={
        <div className={styles.highbeamCardTagContainer}>
          <div className={styles.cardTagContainer}>
            <CardTag icon={<CurrencyCircleDollar size={16} />} text="1.5% cash back on ad spend" />
            <CardTag icon={<Coin size={16} />} text="1% cashback on everything else" />
          </div>
          <div className={styles.cardTagContainer}>
            <CardTag icon={<Prohibit mirrored size={16} />} text="Interest free" />
            <CardTag icon={<CalendarCheck size={16} />} text="Up to 45 days payback" />
          </div>
        </div>
      }
    />
    <SelectCardOption
      handleNext={handleNext}
      setCardType={setCardType}
      cardType={"VIRTUAL"}
      img={<img src={debitCardVirtualLogo} alt="Virtual debit card icon" />}
      tags={
        <div className={styles.cardTagContainer}>
          <CardTag icon={<CurrencyCircleDollar size={16} />} text="2% cash back on ad spend" />
          <CardTag icon={<Coin size={16} />} text="1% cash back on everything else" />
        </div>
      }
    />
  </>
);

const PhysicalCardOptions: React.FC<{
  handleNext: () => void;
  setCardType: (cardType: DebitCardRep.CardType) => void;
}> = ({ handleNext, setCardType }) => (
  <>
    <SelectCardOption
      cardType={"PHYSICAL_CREDIT"}
      handleNext={handleNext}
      setCardType={setCardType}
      img={
        <img
          className={styles.chargeCardVirtualLogo}
          src={chargeCardPhysicalLogo}
          alt="Physical Highbeam Card icon"
        />
      }
      tags={
        <div className={styles.highbeamCardTagContainer}>
          <div className={styles.cardTagContainer}>
            <CardTag icon={<CurrencyCircleDollar size={16} />} text="1.5% cash back on ad spend" />
            <CardTag icon={<Coin size={16} />} text="1% cashback on everything else" />
          </div>
          <div className={styles.cardTagContainer}>
            <CardTag icon={<Prohibit mirrored size={16} />} text="Interest free" />
            <CardTag icon={<CalendarCheck size={16} />} text="Up to 45 days payback" />
          </div>
        </div>
      }
    />
    <SelectCardOption
      handleNext={handleNext}
      setCardType={setCardType}
      cardType={"PHYSICAL"}
      img={<img src={debitCardPhysicalLogo} alt="Physical debit card icon" />}
      tags={
        <div className={styles.cardTagContainer}>
          <CardTag icon={<CurrencyCircleDollar size={16} />} text="2% cash back on ad spend" />
          <CardTag icon={<Coin size={16} />} text="1% cash back on everything else" />
        </div>
      }
    />
  </>
);
export default SelectCard;
