import BankAccountRep from "reps/BankAccountRep";

import useBankAccountsQueryOptions from "./useBankAccountsQueryOptions";

const useClosedBankAccountsQueryOptions = () => {
  return useBankAccountsQueryOptions({
    statuses: [BankAccountRep.Status.CLOSED],
  });
};

export default useClosedBankAccountsQueryOptions;
