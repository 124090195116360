import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const PLAID_ACCOUNTS_QUERY_KEY = "plaid-accounts";

type Params = {
  // NB(alex): The endpoint calls all plaid accounts a "bank account", but on the frontend, we call them "accounts".
  // There are two types of plaid accounts: depository and credit.
  // Due to this ambiguity, I think we should make sure to specify "All" if we really want all of the accounts.
  accountType: PlaidBankAccountRep.AccountType | "All";
};

const usePlaidAccountsQueryOptions = ({ accountType }: Params) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [PLAID_ACCOUNTS_QUERY_KEY, { businessGuid, accountType }],
    queryFn: async () => {
      const allPlaidAccounts = await highbeamApi.plaid.getPlaidBankAccountsByBusiness(businessGuid);

      if (accountType === "All") {
        return allPlaidAccounts;
      }

      return allPlaidAccounts.filter((account) => account.accountType === accountType);
    },
  });
};

export default usePlaidAccountsQueryOptions;
