import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useCardQueryOptions = (cardId: string) => {
  const unitApi = useUnitApi();

  return useQueryOptions({
    queryKey: ["cardDetails", { cardId }],
    queryFn: async () => (await unitApi.cards.get(cardId)).data,
  });
};

export default useCardQueryOptions;
