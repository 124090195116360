import classNames from "classnames";
import { forwardRef } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";

import styles from "./ButtonArrayButton.module.scss";

const ButtonArrayButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => {
    return <Button ref={ref} {...props} className={classNames(className, styles.arrayButton)} />;
  }
);

export default ButtonArrayButton;
