import { Clock as ClockIcon } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, PropsWithChildren, ReactNode, createContext, useContext } from "react";
import Shimmer from "ui/feedback/Shimmer";
import Text from "ui/typography/Text";

import styles from "./SpeechBubbleGroup.module.scss";

type SpeechBubbleGroupContextValue = {
  isAlternate: boolean;
};

const SpeechBubbleGroupContext = createContext<SpeechBubbleGroupContextValue>({
  isAlternate: false,
});

const useSpeechBubbleGroupContext = () => useContext(SpeechBubbleGroupContext);

type SpeechBubbleGroupProps = PropsWithChildren & {
  isAlternate?: boolean;
};

const SpeechBubbleGroup: FC<SpeechBubbleGroupProps> = ({ children, isAlternate = false }) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles["container--alternate"]]: isAlternate,
      })}
    >
      <SpeechBubbleGroupContext.Provider value={{ isAlternate }}>
        {children}
      </SpeechBubbleGroupContext.Provider>
    </div>
  );
};

const Timestamp: FC<PropsWithChildren> = ({ children }) => {
  return (
    <span className={styles.timestampContainer}>
      <ClockIcon size={16} />
      <Text as="p">{children}</Text>
    </span>
  );
};

type SpeechBubbleProps = PropsWithChildren & {
  labelContent?: ReactNode;
};

const SpeechBubble: FC<SpeechBubbleProps> = ({ children, labelContent }) => {
  const { isAlternate } = useSpeechBubbleGroupContext();

  return (
    <div
      className={classNames(styles.bubbleContainer, {
        [styles["bubbleContainer--alternate"]]: isAlternate,
      })}
    >
      <div className={styles.bubbleMain}>{children}</div>
      {labelContent && <div className={styles.bubbleLabel}>{labelContent}</div>}
    </div>
  );
};

const LabelLoadingContent: FC = () => {
  return (
    <>
      <div className={styles.labelLoadingShimmerContainer} aria-hidden="true">
        <Shimmer />
      </div>
      <div className={styles.labelLoadingAvatarPlaceholder} aria-hidden="true" />
    </>
  );
};

export default Object.assign(SpeechBubbleGroup, {
  Timestamp,
  SpeechBubble,
  LabelLoadingContent,
});
