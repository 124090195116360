import { CardLimits } from "@highbeam/unit-node-sdk";
import IconWithTooltip from "components/common/IconWithTooltip";
import ProgressBar from "ui/data-visualization/ProgressBar";
import Text from "ui/typography/Text";
import { applyMinProgress } from "utils/cards";
import { getDollarsFromCents } from "utils/money";
import { formatAmount, percentage } from "utils/string";

import styles from ".././CardDetailsInfo.module.scss";
import { getCopyForTimeLeft } from "../utils";

type CardBudgetInfoWithLimitProps = {
  cardLimit: CardLimits;
};

const CardBudgetInfoWithLimit: React.FC<CardBudgetInfoWithLimitProps> = ({ cardLimit }) => {
  const period = cardLimit.attributes.limits?.dailyPurchase ? "Daily" : "Monthly";
  const totalsKey = period === "Daily" ? "dailyTotals" : "monthlyTotals";
  const limitKey = period === "Daily" ? "dailyPurchase" : "monthlyPurchase";
  const tooltipCopyForTimeLeft = getCopyForTimeLeft(period);
  const availableBudget = formatAmount(
    getDollarsFromCents(
      (cardLimit.attributes.limits?.[limitKey] || 0) -
        (cardLimit.attributes[totalsKey]?.purchases || 0)
    )
  );

  const totalLimit = cardLimit.attributes.limits?.[limitKey];

  return (
    <div className={styles.cardInfoTitle}>
      <div className={styles.titleAndInfoContainer}>
        <div className={styles.leftContent}>
          <Text size={14}>{period} budget available</Text>
          <IconWithTooltip content={tooltipCopyForTimeLeft} />
        </div>
        <div>
          <Text size={14}>{availableBudget}</Text>
        </div>
      </div>
      <div className={styles.progressBarContainer}>
        {totalLimit !== undefined && (
          <ProgressBar
            percent={applyMinProgress(
              percentage(cardLimit.attributes[totalsKey]?.purchases || 0, totalLimit)
            )}
          />
        )}
      </div>
      <div className={styles.amountV2}>
        <Text size={14}>
          Spent
          <span className={styles.spent}>
            {formatAmount(getDollarsFromCents(cardLimit.attributes[totalsKey]?.purchases || 0))}
          </span>
          <span>
            {" / "}
            {totalLimit !== undefined ? formatAmount(getDollarsFromCents(totalLimit)) : "-"}
          </span>
        </Text>
      </div>
    </div>
  );
};

export default CardBudgetInfoWithLimit;
