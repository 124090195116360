import ChargeCardOfferRep from "reps/ChargeCardOfferRep";

export const isChargeCardOfferWithCashback = (
  chargeCardOffer: ChargeCardOfferRep.Complete | null
) => {
  const cardCreditTerm = chargeCardOffer?.creditTerms;
  switch (cardCreditTerm) {
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxExtend:
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionExtend:
      return false;
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxFlex:
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionFlex:
    case ChargeCardOfferRep.ChargeCardCreditTerm.Sandbox:
    case ChargeCardOfferRep.ChargeCardCreditTerm.Production:
      return true;
  }
};
