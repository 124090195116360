import { useFormContext } from "react-hook-form";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import Text from "ui/typography/Text";

import styles from "./ConfirmAddressModalContent.module.scss";
import { UpdateBusinessAddressFormInputs } from "./useUpdateBusinessAddressForm";

const ConfirmAddressModalContent = () => {
  const { getValues } = useFormContext<UpdateBusinessAddressFormInputs>();
  const addressLine1 = getValues("addressLine1");
  const addressLine2 = getValues("addressLine2");
  const city = getValues("city");
  const state = getValues("state");
  const zipCode = getValues("zipCode");
  return (
    <div>
      <div className={styles.address}>
        <Text size={14} color={colors.grey[600]}>
          {addressLine1}
        </Text>
        {addressLine2 && (
          <Text size={14} color={colors.grey[600]}>
            Apartment {addressLine2}
          </Text>
        )}
        <Text size={14} color={colors.grey[600]}>
          {city}, {state} {zipCode}
        </Text>
      </div>
      <Divider />
      <div className={styles.changeAddressNoteContent}>
        <Text size={14} color={colors.grey[900]} weight="medium">
          Please note:
        </Text>
        <div className={styles.changeAddressNote}>
          <Text size={12} className={styles.noteNumber} weight="medium">
            1
          </Text>
          <Text size={14} color={colors.grey[900]}>
            This address will be used as the billing address and default shipping address for all
            cards
          </Text>
        </div>
        <div className={styles.changeAddressNote}>
          <Text size={12} className={styles.noteNumber} weight="medium">
            2
          </Text>
          <Text size={14} color={colors.grey[900]}>
            Vendors will use this address to send you wire payments
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAddressModalContent;
