import { useQuery } from "@tanstack/react-query";
import MetadataList from "components/MetadataList";
import React from "react";
import useInternationalAccountQuery from "resources/bank-accounts/queries/useInternationalAccountQuery";
import { useBusinessQuery } from "resources/business/queries/useBusiness";
import InfoBox from "ui/data-display/InfoBox";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import CopyItemTooltip from "ui/overlay/Tooltip/CopyItemTooltip/CopyItemTooltip";
import Text from "ui/typography/Text";

import SecondaryInternationalAccountInformation from "./SecondaryInternationalAccountInformation";

type InternationalAccountInformationProps = {
  isAllowedToViewAccountNumbers: boolean;
  isPrimary: boolean;
};

const InternationalAccountInformation: React.FC<InternationalAccountInformationProps> = ({
  isAllowedToViewAccountNumbers,
  isPrimary,
}) => {
  const { data: account, isPending: isAccountPending } = useQuery(useInternationalAccountQuery());
  const { data: business, isPending: isBusinessPending } = useBusinessQuery();

  if (!isAllowedToViewAccountNumbers) return <ContactAdmin />;
  if (!isPrimary) return <SecondaryInternationalAccountInformation />;
  if (isAccountPending || isBusinessPending || !business) {
    return <InternationalAccountInformationLoading />;
  }
  if (!account) return <ContactSupport />;

  const accountHolderName = business.name;
  const accountNumber = account.iban;

  const swiftCode = account.swiftCode;

  return (
    <MetadataList>
      <MetadataList.Item label="Account holder name">
        <CopyItemTooltip textToCopy={accountHolderName || ""}>
          <Text size={14} weight="medium">
            {accountHolderName}
          </Text>
        </CopyItemTooltip>
      </MetadataList.Item>

      <MetadataList.Item label="Account number">
        <CopyItemTooltip textToCopy={accountNumber || ""}>
          <Text size={14} weight="medium">
            {accountNumber}
          </Text>
        </CopyItemTooltip>
      </MetadataList.Item>

      <MetadataList.Item label="SWIFT code">
        <CopyItemTooltip textToCopy={swiftCode || ""}>
          <Text size={14} weight="medium">
            {swiftCode}
          </Text>
        </CopyItemTooltip>
      </MetadataList.Item>
      <PartnerBankDetails swiftCode={swiftCode} />
    </MetadataList>
  );
};

const ContactSupport = () => {
  return (
    <InfoBox
      variant="info"
      text="International account information is not available for this account. Please contact support for more information."
    />
  );
};

const ContactAdmin = () => {
  return (
    <InfoBox variant="info" text="Please contact your account admin to view this information." />
  );
};

const InternationalAccountInformationLoading = () => {
  return (
    <MetadataList>
      <MetadataList.Item label="Account holder name">
        <Text size={14} weight="medium">
          <AnimatedSpinner />
        </Text>
      </MetadataList.Item>

      <MetadataList.Item label="Account number">
        <Text size={14} weight="medium">
          <AnimatedSpinner />
        </Text>
      </MetadataList.Item>

      <MetadataList.Item label="SWIFT code">
        <Text size={14} weight="medium">
          <AnimatedSpinner />
        </Text>
      </MetadataList.Item>

      <MetadataList.Item label="Partner bank">
        <Text size={14} weight="medium">
          <AnimatedSpinner />
        </Text>
        <Text size={14} weight="medium">
          <AnimatedSpinner />
        </Text>
        <Text size={14} weight="medium">
          <AnimatedSpinner />
        </Text>
        <Text size={14} weight="medium">
          <AnimatedSpinner />
        </Text>
      </MetadataList.Item>
    </MetadataList>
  );
};

type PartnerBankDetailsProps = {
  swiftCode: string | null;
};

const PartnerBankDetails: React.FC<PartnerBankDetailsProps> = ({ swiftCode }) => {
  if (swiftCode === "CMFGUS33") {
    return (
      <MetadataList.Item label="Partner bank">
        <Text size={14} weight="medium">
          Community Federal Savings Bank{" "}
        </Text>
        <Text size={14} weight="medium">
          810 Seventh Avenue
        </Text>
        <Text size={14} weight="medium">
          New York, NY 10019
        </Text>
      </MetadataList.Item>
    );
  }
  return (
    <MetadataList.Item label="Partner bank">
      <Text size={14} weight="medium">
        The Currency Cloud Limited
      </Text>
      <Text size={14} weight="medium">
        12 Steward Street
      </Text>
      <Text size={14} weight="medium">
        London, E1 6FQ
      </Text>
      <Text size={14} weight="medium">
        United Kingdom
      </Text>
    </MetadataList.Item>
  );
};

export default InternationalAccountInformation;
