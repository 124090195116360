import { captureMessage } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import BillDocumentRep from "reps/BillDocumentRep";
import { useRefreshBillQuery } from "resources/bills/queries/useBill";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { downloadWith404Retry } from "utils/download";

import useUploadBillDocumentsMutation from "./useUploadBillDocumentsMutation";

type Variables = {
  file: File;
};

const useUploadBillInvoiceDocumentMutation = (billId: string) => {
  const highbeamApi = useHighbeamApi();
  const { mutateAsync: uploadBillDocuments } = useUploadBillDocumentsMutation();
  const refreshBill = useRefreshBillQuery(billId);

  return useMutation({
    mutationFn: async ({ file }: Variables) => {
      const { associatedBillDocuments } = await uploadBillDocuments({
        billId: billId,
        files: [file],
        type: BillDocumentRep.DocumentType.Invoice,
      });

      // Should never happen
      if (associatedBillDocuments.length !== 1) {
        captureMessage(
          `Alert(alex): Expected "associatedBillDocuments.length" to be 1, but it was ${associatedBillDocuments.length}.`
        );
      }

      const invoiceBillDocument = associatedBillDocuments[0];
      await downloadWith404Retry(invoiceBillDocument.signedDocumentUrl);

      await highbeamApi.billDocumentAction.process(invoiceBillDocument.id);
    },
    onSuccess: async () => {
      await refreshBill();
    },
  });
};

export default useUploadBillInvoiceDocumentMutation;
