import { PlusCircle } from "@phosphor-icons/react";
import { captureException } from "@sentry/react";
import ProviderDropdown from "pages/capital/CreditComparisonPage/components/ProviderDropdown";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import offerInputsState, {
  OfferInput,
  defaultOfferInput,
} from "state/compareCreditOffers/inputs/offerInputs/offerInputs";
import isSelectCreditOfferProvidersFormValidState from "state/compareCreditOffers/isSelectCreditOfferProvidersFormValid";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import useMountEffect from "utils/customHooks/useMountEffect";

import FromControls from "../components/FromControls";
import { CREDIT_OFFER_PARAM_PREFIX } from "../constants";
import filterOfferInputsWithoutProviderName from "../utils/filterOfferInputsWithoutProviderName";

import styles from "./Form.module.scss";

const SelectCreditOfferProvidersForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [offerInputs, setOfferInputs] = useRecoilState(offerInputsState);
  const offerInputsWithProviderName = filterOfferInputsWithoutProviderName(offerInputs);

  // Inserts a blank offer input on mount if the form has other values (so the user doesn't have to click "Add another")
  useMountEffect(() => {
    if (offerInputsWithProviderName.length > 0) {
      setOfferInputs((prev) =>
        prev.length <= 6 ? [...filterOfferInputsWithoutProviderName(prev), defaultOfferInput] : prev
      );
    }
  });

  const isSelectCreditOfferProvidersFormValid = useRecoilValue(
    isSelectCreditOfferProvidersFormValidState
  );
  const isSubmitDisabled = !isSelectCreditOfferProvidersFormValid;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      setOfferInputs(offerInputsWithProviderName);
      navigate(`${CREDIT_COMPARISON_PATH}/${CREDIT_OFFER_PARAM_PREFIX}1`);
    } catch (error) {
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.sectionHeader}>
        <Heading3>Select up to 6 credit providers</Heading3>
        <Text size={16} weight="medium" color={colors.grey[500]}>
          {offerInputs.filter((offer) => Boolean(offer)).length}
          /6
        </Text>
      </div>

      {offerInputs.map(({ providerName }, index) => (
        <ProviderDropdown
          key={`${index}-${providerName}`}
          label={`Provider ${index + 1}`}
          value={providerName || ""}
          onClickRemove={() => {
            setOfferInputs((prev) => prev.filter((_, i) => i !== index));
          }}
          setSelectedOption={(opt) => {
            setOfferInputs((prev) => {
              const value: OfferInput = {
                ...defaultOfferInput,
                providerName: (opt?.value ?? "") as OfferInput["providerName"],
              };
              return prev.map((prevVal, valIndex) => (valIndex === index ? value : prevVal));
            });
          }}
          isRemoveable={offerInputs.length > 1}
        />
      ))}

      <Button
        className={styles.addButton}
        variant="tertiary"
        disabled={offerInputs.length >= 6}
        onClick={() => {
          if (offerInputs.length < 6) {
            setOfferInputs((prev) => [...prev, defaultOfferInput]);
          }
        }}
      >
        <PlusCircle />
        Add another
      </Button>

      <FromControls isLoading={isLoading} isSubmitDisabled={isSubmitDisabled} />
    </form>
  );
};

export default SelectCreditOfferProvidersForm;
