import logo from "assets/highbeam-logo.svg";
import { Link, Navigate } from "react-router-dom";
import useBusiness from "resources/business/queries/useBusiness";
import Text from "ui/typography/Text";

import ApplicationEvaluation from "./ApplicationEvaluation";
import styles from "./ApplicationEvaluationPage.module.scss";

const ApplicationEvaluationPage = () => {
  const business = useBusiness();
  const isActive = business.status === "Active";

  if (isActive) {
    return <Navigate to="/onboarding/get-started" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <img src={logo} className={styles.logo} alt="logo" />
        <Link to="/sign-out">
          <Text size={14} weight="medium">
            Logout
          </Text>
        </Link>
      </div>
      <ApplicationEvaluation />
    </div>
  );
};

export default ApplicationEvaluationPage;
