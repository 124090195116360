import { Warning } from "@phosphor-icons/react";
import IconWithTooltip from "components/common/IconWithTooltip";
import { FC } from "react";
import HighbeamAccountIcon, {
  HighbeamAccountTypeName,
} from "resources/bank-accounts/components/HighbeamAccountIcon";
import colors from "styles/colors";
import BankAccountV2 from "ui/data-display/BankAccountV2";
import HighlightItem from "ui/data-display/HighlightItem";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";

import styles from "./ChargeCardAutoPayHighlightItem.module.scss";

type Props = {
  balanceDueAmountInCents: number;
  autoPayHighbeamAccountTypeName: HighbeamAccountTypeName;
  autoPayAccountAccountName: string;
  autoPayAccountAccountNumber: string;
  autoPayAccountBalanceInCents: number;
};

const ChargeCardAutoPayHighlightItem: FC<Props> = ({
  balanceDueAmountInCents,
  autoPayAccountBalanceInCents,
  autoPayHighbeamAccountTypeName,
  autoPayAccountAccountName,
  autoPayAccountAccountNumber,
}) => {
  const isBalanceDueGreaterThanAutoPayAccountBalance =
    balanceDueAmountInCents > autoPayAccountBalanceInCents;

  return (
    <HighlightItem
      sideRuleColor={isBalanceDueGreaterThanAutoPayAccountBalance ? "warning" : "default"}
      label={
        <>
          Auto-pay account
          <IconWithTooltip
            color="dark"
            content={
              <Text size={14}>
                Highbeam will debit from this account on each due date. If the account balance is
                insufficient, the full amount will be paid with your line of credit.
              </Text>
            }
          />
        </>
      }
      value={
        <BankAccountV2
          iconSize={20}
          bankIcon={<HighbeamAccountIcon highbeamTypeName={autoPayHighbeamAccountTypeName} />}
          accountName={autoPayAccountAccountName}
          accountNumber={autoPayAccountAccountNumber}
        />
      }
      subtext={
        isBalanceDueGreaterThanAutoPayAccountBalance ? (
          <div className={styles.warningSubtext}>
            <div>
              <Warning size={24} weight="light" color={colors.yellow[600]} />
            </div>
            <Text color={colors.yellow[700]} as="span">
              Your balance of <MoneyAmount cents={autoPayAccountBalanceInCents} /> is too low. The
              full amount will be paid down using your line of credit.
            </Text>
          </div>
        ) : (
          <>
            Balance: <MoneyAmount cents={autoPayAccountBalanceInCents} />
          </>
        )
      }
    />
  );
};

export default ChargeCardAutoPayHighlightItem;
