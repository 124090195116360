import DebitCardRep from "reps/DebitCardRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

// NB(alex): The `Debit` prefix is legacy. This is a generic card rep. We should clean this up eventually!

export default class DebitCardApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(creation: DebitCardRep.Creation): Promise<DebitCardRep.Complete> {
    const url = `/debit-cards`;
    return (await this.api.post<DebitCardRep.Complete>(url, creation))!;
  }

  async update(
    businessGuid: string,
    cardId: string,
    debitCard: DebitCardRep.Update
  ): Promise<DebitCardRep.Complete | null> {
    const url = `/businesses/${businessGuid}/debit-cards/${cardId}`;
    return await this.api.patch<DebitCardRep.Complete>(url, debitCard);
  }
}
