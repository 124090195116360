import useUserGuid from "resources/jwt/queries/useUserGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useIntercomMetadataQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const userGuid = useUserGuid();

  return useQueryOptions({
    queryKey: ["intercomMetadata", { userGuid }],
    queryFn: async () => {
      return (await highbeamApi.intercom.getMetadata(userGuid))!;
    },
  });
};

export default useIntercomMetadataQueryOptions;
