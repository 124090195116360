import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import PlaidAccessTokenRep from "reps/PlaidAccessTokenRep";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import { sortPlaidAccounts, sortPlaidConnections } from "utils/account";
import { ApiError } from "utils/ajax";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class PlaidApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async createLinkToken(body: PlaidLinkTokenRep.Creation): Promise<PlaidLinkTokenRep.Complete> {
    const url = `/plaid/link-token`;
    try {
      return (await this.api.post<PlaidLinkTokenRep.Complete>(url, body))!;
    } catch (error) {
      if (error instanceof ApiError && error.statusCode === 403) {
        return {
          linkToken: null,
        };
      }
      throw error;
    }
  }

  async createAccessToken(
    businessGuid: string,
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
    isNewConnection: boolean
  ): Promise<void> {
    const url = `/plaid/access-token`;
    const body: PlaidAccessTokenRep.Creation = {
      businessGuid,
      publicToken,
      metadata,
      isNewConnection,
    };
    return (await this.api.post(url, body))!;
  }

  async getPlaidBankAccountsByBusiness(
    businessGuid: string
  ): Promise<PlaidBankAccountRep.Complete[]> {
    const url = `/plaid/bank-accounts/${businessGuid}`;
    return (await this.api.get<PlaidBankAccountRep.Complete[]>(url))!.sort(sortPlaidAccounts);
  }

  async getPlaidConnectionsByBusiness(
    businessGuid: string
  ): Promise<PlaidConnectionRep.Complete[]> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/plaid/connections?${queryParams}`;
    return (await this.api.get<PlaidConnectionRep.Complete[]>(url))!.sort(sortPlaidConnections);
  }

  async deleteConnection({
    businessGuid,
    connectionGuid,
  }: {
    businessGuid: string;
    connectionGuid: string;
  }): Promise<PlaidConnectionRep.Complete> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/plaid/connections/${connectionGuid}?${queryParams}`;
    return (await this.api.delete<PlaidConnectionRep.Complete>(url))!;
  }
}
