import { useEffect, useState } from "react";

export const MOBILE_MAX = 768;
export const TABLET_MAX = 991;
export const LARGE_DESKTOP_MAX = 1280;

const useMediaQuery = (): number => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width;
};

export const useIsMobile = () => useMediaQuery() <= MOBILE_MAX;
export const useIsTablet = () => useMediaQuery() <= TABLET_MAX;
export const useIsDesktop = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  return !isMobile && !isTablet;
};

export default useMediaQuery;
