import { captureException } from "@sentry/react";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import BankApplicationRep from "reps/BankApplicationRep";
import { Milestone } from "resources/action-items/types";
import useBankApplicationQueryOptions from "resources/bank-application/queries/useBankApplicationQueryOptions";
import useBusiness from "resources/business/queries/useBusiness";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import actionItemsState from "state/actionItems/actionItems";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

const useMarkAuthorizedWithUnit = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const userGuid = useUserGuid();

  // Redirects pending approval.
  const { unitCoCustomerId } = useBusiness();

  const { data: bankApplication } = useSuspenseQuery(useBankApplicationQueryOptions());
  const isApplicationApproved = bankApplication?.status === BankApplicationRep.Status.Approved;

  const navigate = useNavigate();
  useEffect(() => {
    if (!unitCoCustomerId) {
      if (isApplicationApproved) {
        // Prevents an infinite loop caused by a rare edge case where the application is approved but `unitCoCustomerId` is undefined. Context: https://highbeamco.slack.com/archives/C05A92512J0/p1697139667912349
        throw new Error("Bank application is approved but `unitCoCustomerId` is undefined.");
      }
      navigate("/onboarding");
    }
  }, [navigate, unitCoCustomerId, isApplicationApproved]);

  const resetActionItemsState = useResetRecoilState(actionItemsState(Milestone.Signup));

  return useMutation({
    mutationFn: () => highbeamApi.authorizedUser.create(businessGuid, userGuid),
    onError: (err) => {
      notify("error", "Something went wrong! Please try again.");
      captureException(err);
    },
    onSuccess: () => {
      // The backend creates an action item "Signup/AuthorizeWithUnit" upon completion, which we need to be aware of.
      resetActionItemsState();
      window.location.href = "/"; // Intended to force a refresh.
    },
  });
};

export default useMarkAuthorizedWithUnit;
