import { WarningCircle } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { Controller } from "react-hook-form";
import dropdownAdapter from "resources/business-details/hooks/dropdownAdapter";
import { useBusinessAddressFormContext } from "resources/business-details/hooks/useBusinessAddressForm";
import { useEnabledCapitalAccountsQueryOptions } from "resources/capital-accounts/queries/useEnabledCapitalAccounts";
import colors from "styles/colors";
import InfoBox from "ui/data-display/InfoBox";
import Dropdown from "ui/inputs/Dropdown";
import Fieldset from "ui/inputs/Fieldset";
import TextInput from "ui/inputs/TextInput";
import ItemWithTooltip from "ui/overlay/Tooltip/ItemWithTooltip/ItemWithTooltip";
import Text from "ui/typography/Text";
import { states, supportedStatesForCapital, US_COUNTRY_OPTION } from "utils/address";
import useIsAllowedToUpdateBusinessAddress from "utils/permissions/useIsAllowedToUpdateBusinessAddress";

import styles from "./BusinessAddressFormFields.module.scss";

const UnsupportedStateWarningTooltip: React.FC<{ label: string }> = ({ label }) => (
  <ItemWithTooltip
    content={
      <Text size={14}>
        We can’t currently accept business addresses located in {label} for customers who are using
        our capital products.
      </Text>
    }
    wrapperClassName={styles.unsupportedStateWarning}
    wrapperTooltipClassName={styles.unsupportedStateWarningTooltip}
  >
    <WarningCircle size={16} color={colors.yellow[600]} />
    <Text size={14} color={colors.yellow[700]}>
      {label}
    </Text>
  </ItemWithTooltip>
);

const BusinessAddressFormFields: FC = () => {
  const { control } = useBusinessAddressFormContext();
  const hasWritePermission = useIsAllowedToUpdateBusinessAddress();

  // Show warning next to states that don't support our capital products, if they have an active capital account or offer.
  const { data: enabledCapitalAccounts } = useQuery({
    ...useEnabledCapitalAccountsQueryOptions(),
    enabled: hasWritePermission,
  });
  const hasEnabledCapitalAccount = enabledCapitalAccounts && enabledCapitalAccounts.length > 0;
  const stateOptions = useMemo(
    () =>
      Object.entries(states).map(([value, label]) => {
        if (hasEnabledCapitalAccount && !(value in supportedStatesForCapital)) {
          return {
            label: <UnsupportedStateWarningTooltip label={label} />,
            value,
          };
        }
        return { label, value };
      }),
    [hasEnabledCapitalAccount]
  );

  const countryOptions = [US_COUNTRY_OPTION];

  return (
    <Fieldset width="wide">
      <Controller
        name="line1"
        control={control}
        render={({ field, formState }) => (
          <TextInput
            label="Street address"
            hasError={Boolean(formState.errors.line1)}
            errorMessage={formState.errors.line1?.message}
            disabled={!hasWritePermission}
            {...field}
          />
        )}
      />
      <Controller
        name="line2"
        control={control}
        render={({ field, formState }) => (
          <TextInput
            label="Address line 2"
            hasError={Boolean(formState.errors.line2)}
            errorMessage={formState.errors.line2?.message}
            disabled={!hasWritePermission}
            {...field}
          />
        )}
      />
      <Controller
        name="city"
        control={control}
        render={({ field, formState }) => (
          <TextInput
            label="City"
            hasError={Boolean(formState.errors.city)}
            errorMessage={formState.errors.city?.message}
            disabled={!hasWritePermission}
            {...field}
          />
        )}
      />
      <Controller
        name="state"
        control={control}
        render={({ field, formState }) => {
          return (
            <Dropdown
              label="State"
              hasError={Boolean(formState.errors.state)}
              errorMessage={formState.errors.state?.message}
              disabled={!hasWritePermission}
              {...dropdownAdapter(field, stateOptions)}
            />
          );
        }}
      />
      <Controller
        name="postalCode"
        control={control}
        render={({ field, formState }) => (
          <TextInput
            label="ZIP"
            hasError={Boolean(formState.errors.postalCode)}
            errorMessage={formState.errors.postalCode?.message}
            disabled={!hasWritePermission}
            {...field}
          />
        )}
      />
      <Controller
        name="country"
        control={control}
        render={({ field, formState }) => {
          return (
            <Dropdown
              label="Country"
              disabled
              hasError={Boolean(formState.errors.country)}
              errorMessage={formState.errors.country?.message}
              {...dropdownAdapter(field, countryOptions)}
            />
          );
        }}
      />

      <InfoBox
        className={styles.infoBox}
        variant="info"
        iconSize={24}
        text="We may email you for additional documentation to verify your new address."
      />
    </Fieldset>
  );
};

export default BusinessAddressFormFields;
