import { FC, ReactNode, createContext, useContext, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

export type ModalContextValue = {
  onClose: () => void;
  size?: "standard" | "small";

  // We auto-generate a formId, but you may override it if you'd like.
  formId?: string;
};

const ModalContext = createContext<ModalContextValue>({} as ModalContextValue);

type ModalContextProviderProps = ModalContextValue & {
  children: ReactNode;
};

export const ModalContextProvider: FC<ModalContextProviderProps> = ({
  children,
  onClose,
  formId: formIdProp,
  size,
}) => {
  // `useMemo` is used here to prevent a new formId from being generated on every render.
  const formId = useMemo(() => formIdProp || `form-${uuidv4()}`, [formIdProp]);

  return (
    <ModalContext.Provider
      value={{
        onClose,
        formId,
        size,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const useModalContext = () => {
  const modalContext = useContext(ModalContext);
  if (modalContext === undefined) {
    throw new Error("useModalContext must be used within a ModalContextProvider");
  }
  return modalContext;
};

export default useModalContext;
