import { ArrowRight } from "@phosphor-icons/react";
import AccountLabel from "components/Accounts/AccountLabel";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import BankAccountRep from "reps/BankAccountRep";
import useOpenBankAccounts from "resources/bank-accounts/queries/useOpenBankAccounts";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import { useIsMobile } from "utils/device/useMediaQuery";

import styles from "./BankAccountsTable.module.scss";

type BankAccountRow = BankAccountRep.Complete & { lineOfCreditLimit?: number };

const AvailableBalanceCell: FC<{ account: BankAccountRow }> = ({ account }) => {
  return (
    <Text color={colors.grey[800]} size={14} weight="medium" numeric align="right">
      {account.highbeamType.name === "LineOfCredit" ? (
        <>
          <MoneyAmount
            cents={account.availableBalance}
            withCents={account.availableBalance !== 0}
          />

          {account.lineOfCreditLimit !== undefined && (
            <Text as="span" color={colors.grey[400]}>
              {" / "}
              <MoneyAmount
                cents={account.lineOfCreditLimit}
                withCents={account.lineOfCreditLimit % 100 !== 0}
              />
            </Text>
          )}
        </>
      ) : (
        <MoneyAmount cents={account.availableBalance} />
      )}
    </Text>
  );
};

const createColumns = (isMobile: boolean): Column<BankAccountRow>[] => [
  {
    title: "Account",
    cellRender: (account) => <AccountLabel bankAccount={account} showConnectedStores />,
  },
  {
    title: "Available",
    align: TableColumnAlignment.RIGHT,
    cellRender: (account) => <AvailableBalanceCell account={account} />,
  },
  {
    title: !isMobile ? "Account details" : "",
    align: TableColumnAlignment.RIGHT,
    cellRender: () => (
      <div className={styles.details}>
        <Text size={14} align="right">
          View account details
        </Text>
        <ArrowRight size={16} />
      </div>
    ),
  },
];

const BankAccountsTable = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const bankAccounts = useOpenBankAccounts();
  const accounts = bankAccounts.filter((account) => account.highbeamType.name !== "LineOfCredit");

  return (
    <Table
      columns={createColumns(isMobile)}
      rowKey={(account) => account.guid}
      data={accounts}
      onRowClick={(account) => navigate(`/accounts/${account.guid}`)}
    />
  );
};

export default BankAccountsTable;
