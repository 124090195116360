import { useController } from "react-hook-form";
import CapitalAccountIcon from "resources/capital-accounts/components/CapitalAccountIcon";
import Dropdown from "ui/inputs/Dropdown";
import Text from "ui/typography/Text";

import { useDrawdownAmountFormContext } from "../context/DrawdownProvider";

import styles from "./DrawdownAmountView.module.scss";

const DrawdownFromCapitalAccountDropdown = () => {
  const { control } = useDrawdownAmountFormContext();

  const { field } = useController({
    control: control,
    name: "drawdownFromCapitalAccount",
  });

  return (
    <>
      <Dropdown
        disabled
        id="from"
        label="From"
        isSearchable={false}
        options={[]} // Render all capital account options once we fully implement multiple capital accounts
        value={{
          label: (
            <div className={styles.drawdownFromDropdown}>
              <CapitalAccountIcon capitalAccountType={field.value.type} size={20} />
              <Text size={14} weight="medium">
                {field.value.name}
              </Text>
            </div>
          ),
          value: field.value.name,
        }}
      />
    </>
  );
};

export default DrawdownFromCapitalAccountDropdown;
