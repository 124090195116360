import PaymentInfoFlexpane from "dialogs/PaymentInfoFlexpane";
import PaymentStatusPill from "dialogs/PaymentInfoFlexpane/components/PaymentStatusPill";
import { get } from "lodash-es";
import { FC } from "react";
import BillSummaryRep from "reps/BillSummaryRep";
import useBillRelatedPayments from "resources/bills/queries/useBillRelatedPayments";
import AmountCell from "ui/table/AmountCell";
import CounterpartyCell from "ui/table/CounterpartyCell";
import DateTimeCell from "ui/table/DateTimeCell";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { TableColumnAlignment } from "ui/table/Table";
import useSearchParamValue from "utils/searchParams/useSearchParamValue";

type Props = {
  bill: BillSummaryRep.Complete;
};

const BillRelatedPaymentsTable: FC<Props> = ({ bill }) => {
  const payments = useBillRelatedPayments({ billIds: [bill.id] });

  const [paymentId, setPaymentId] = useSearchParamValue("payment");

  return (
    <>
      <PaymentInfoFlexpane paymentId={paymentId} onClose={() => setPaymentId("")} />

      <Table
        data={payments}
        rowKey={(data) => data.id}
        onRowClick={(data) => setPaymentId(data.id)}
        columns={[
          {
            title: "Date",
            key: "formattedCreatedAt",
            cellRender: (payment) => <DateTimeCell date={payment.attributes.createdAt} />,
            width: 120,
          },
          {
            title: "To",
            cellRender: (payment) => {
              const counterpartyName = get(payment, "attributes.counterparty.name") as
                | string
                | undefined;

              if (!counterpartyName) {
                return <NotFoundCell />;
              }

              return (
                <CounterpartyCell>
                  {counterpartyName} <PaymentStatusPill payment={payment} />
                </CounterpartyCell>
              );
            },
          },

          {
            title: "Amount",
            align: TableColumnAlignment.RIGHT,
            cellRender: (payment) => <AmountCell as="div" cents={payment.attributes.amount} />,
          },
        ]}
      />
    </>
  );
};

export default BillRelatedPaymentsTable;
