export const parseMoneyFloat = (string: string) => {
  return parseFloat(string.replace(",", "."));
};

export const getCentsFromDollars = (dollars: string) => {
  return Math.round(parseMoneyFloat(dollars) * 100);
};

// NB(alex): refactored this file but we should also be consistent with "dollars" being a string or a number. I think it should be a string, but we can deal with that later.
export const getDollarsFromCents = (cents: number) => {
  return cents / 100;
};
