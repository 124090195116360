import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitCoTaxFormsQueryOptions from "resources/account-tax-forms/useUnitCoTaxFormsQueryOptions";
import useBusiness from "resources/business/queries/useBusiness";
import Text from "ui/typography/Text";

import styles from "../AccountsStatements.module.scss";

type Props = {
  accountId?: string;
};

const TaxFormsCount: React.FC<Props> = ({ accountId }) => {
  const { unitCoCustomerId: customerId } = useBusiness();
  const {
    data: { data: taxForms },
  } = useSuspenseQuery(
    useUnitCoTaxFormsQueryOptions({ customerId: customerId ?? undefined, accountId })
  );
  if (!taxForms || taxForms.length === 0) return null;
  return (
    <Text size={14} className={styles.count}>{`${taxForms.length} document${
      taxForms.length === 1 ? "" : "s"
    } available.`}</Text>
  );
};

export default TaxFormsCount;
