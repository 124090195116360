import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { CAPITAL_ACCOUNTS_QUERY_KEY } from "./useCapitalAccountsQueryOptions";

const useCapitalAccountSummaryQueryOptions = (capitalAccountGuid: string) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [CAPITAL_ACCOUNTS_QUERY_KEY, businessGuid, capitalAccountGuid],
    queryFn: () => {
      return highbeamApi.capitalAccount.getSummary(businessGuid, capitalAccountGuid);
    },
  });
};

export default useCapitalAccountSummaryQueryOptions;
