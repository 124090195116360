import { UserPlus } from "@phosphor-icons/react";
import { FC } from "react";
import UserReferralRep from "reps/ReferralLinkRep";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import { APY_REFERRALS_SLUG_TO_AVATAR_MAP } from "..";
import styles from "../Referral.module.scss";

type Props = {
  referral: UserReferralRep;
};

const ReferralHeader: FC<Props> = ({ referral }) => {
  const referrerAvatar = APY_REFERRALS_SLUG_TO_AVATAR_MAP[referral.slug];
  return (
    <div className={styles.text}>
      <div className={styles.headerSection}>
        {referrerAvatar ? (
          <img src={referrerAvatar} alt={referral.slug} className={styles.referrerAvatar} />
        ) : (
          <div className={styles.iconAvatar}>
            <UserPlus size={24} color={colors.purple[500]} />
          </div>
        )}
        <Text size={20} weight="bold" className={styles.primary}>
          {referral.senderName ? <>{referral.senderName} invited you</> : <>You’ve been invited</>}{" "}
          to join Highbeam!
        </Text>
        <Text size={14} className={styles.secondary}>
          Earn <strong>1.50% APY</strong> in your checking accounts and <strong>4.50% APY</strong>{" "}
          in your high yield accounts through the end of 2024 with no minimum balances when you sign
          up and use Highbeam for operations.*
        </Text>
      </div>
    </div>
  );
};

export default ReferralHeader;
