import { zodResolver } from "@hookform/resolvers/zod";
import { PaperPlaneRight as PaperPlaneRightIcon } from "@phosphor-icons/react";
import { FC, useCallback, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import BillSummaryRep from "reps/BillSummaryRep";
import useCreateBillCommentMutation from "resources/bills/mutations/useCreateBillCommentMutation";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Button from "ui/inputs/Button";
import TextArea from "ui/inputs/TextArea";
import Text from "ui/typography/Text";
import { z } from "zod";

import styles from "./BillCreateCommentForm.module.scss";

export const FOCUS_REQUESTED_EVENT = "billCreateCommentFormFocusRequested";

const createCommentFormSchema = z.object({
  text: z.string().nonempty(),
});

type CreateCommentFormInputs = z.infer<typeof createCommentFormSchema>;

const useCreateCommentForm = () =>
  useForm<CreateCommentFormInputs>({
    resolver: zodResolver(createCommentFormSchema),
    defaultValues: {
      text: "",
    },
  });

type BillCreateCommentFormProps = {
  bill: BillSummaryRep.Complete;
  eventBus: EventTarget;
};

const BillCreateCommentForm: FC<BillCreateCommentFormProps> = ({ bill, eventBus }) => {
  const isSuperusering = useIsSuperusering();

  const formRef = useRef<HTMLFormElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useCreateCommentForm();

  const { mutate, isPending } = useCreateBillCommentMutation(bill.id, {
    onSuccess: () => {
      reset();
    },
  });

  const onSubmit = useCallback(
    (data: CreateCommentFormInputs) => {
      if (isSuperusering || isPending) return;

      mutate({
        text: data.text,
      });
    },
    [isSuperusering, mutate, isPending]
  );

  useEffect(() => {
    const handleFocusRequested = () => {
      textAreaRef.current!.focus();
    };

    eventBus.addEventListener(FOCUS_REQUESTED_EVENT, handleFocusRequested);
    return () => eventBus.removeEventListener(FOCUS_REQUESTED_EVENT, handleFocusRequested);
  }, [eventBus]);

  return (
    <form className={styles.container} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="text"
        control={control}
        render={({ field }) => (
          <TextArea
            ref={textAreaRef}
            placeholder="Leave a comment for your teammates&hellip;"
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            onKeyDown={(e) => {
              // Cmd+enter or ctrl+enter to submit the form.
              if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                formRef.current!.requestSubmit();
              }
            }}
          />
        )}
      />

      <Button
        type="submit"
        fullWidth
        variant="primary"
        disabled={isSuperusering || !isValid}
        isLoading={isPending}
        tooltip={isSuperusering ? "Cannot add bill comments as a superuser." : null}
      >
        Send
        <PaperPlaneRightIcon size={24} />
      </Button>
      <Text as="p" size={14}>
        All approvers on this bill will be notified via email.
      </Text>
    </form>
  );
};

export default BillCreateCommentForm;
