import MultiStep from "components/layouts/MultiStep";
import PaymentSummary from "components/PaymentSummary";
import dayjs from "dayjs";
import { FC, FormEvent } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import HighbeamAccountIcon from "resources/bank-accounts/components/HighbeamAccountIcon";
import useOpenBankAccount from "resources/bank-accounts/queries/useOpenBankAccount";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import useCreateChargeCardsRepaymentMutation from "resources/charge-cards/mutations/useCreateChargeCardsRepaymentMutation";
import useChargeCardAccount from "resources/charge-cards/queries/useChargeCardAccount";
import useChargeCardRepaymentInfo from "resources/charge-cards/queries/useChargeCardRepaymentInfo";
import BankAccountV2 from "ui/data-display/BankAccountV2";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

const REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID = "repay-highbeam-cards-confirmation-form";

type RepayHighbeamCardsConfirmationViewState = {
  amount: number;
  repaymentBankAccountGuid: string;
};

type RepayHighbeamCardsConfirmationFormProps = RepayHighbeamCardsConfirmationViewState;

const RepayHighbeamCardsConfirmationForm: FC<RepayHighbeamCardsConfirmationFormProps> = ({
  amount,
  repaymentBankAccountGuid,
}) => {
  const repaymentBankAccount = useOpenBankAccount(repaymentBankAccountGuid, { required: true });
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const navigate = useNavigate();

  const availableAfterRepayment = capitalAccountSummary.available + amount;
  const chargeCardAccount = useChargeCardAccount(capitalAccountSummary.guid, { required: true });
  const { remainingAmountDue, nextRepaymentDueDate } = useChargeCardRepaymentInfo(
    chargeCardAccount.guid,
    {
      required: true,
    }
  );

  const { mutateAsync: repayHighbeamCards, isPending } = useCreateChargeCardsRepaymentMutation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await repayHighbeamCards({
      amountInCents: amount,
      chargeCardCreditAccountGuid: chargeCardAccount.guid,
      transferFromBankAccountGuid: repaymentBankAccountGuid,
    });

    navigate(`/capital/${capitalAccountSummary.guid}`);
  };

  return (
    <MultiStep.Form id={REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Confirm details</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <PaymentSummary>
          <PaymentSummary.Header>
            <PaymentSummary.Header.Title>Repayment amount</PaymentSummary.Header.Title>
            <PaymentSummary.Header.Amount cents={amount} />
          </PaymentSummary.Header>

          <PaymentSummary.SectionDivider />

          <PaymentSummary.Section>
            <PaymentSummary.Section.SummaryItem
              label="Pay from"
              value={
                <BankAccountV2
                  bankIcon={<HighbeamAccountIcon highbeamTypeName="DepositAccount" />}
                  accountName={repaymentBankAccount.name}
                  accountNumber={repaymentBankAccount.accountNumber}
                />
              }
            />

            <PaymentSummary.Section.SummaryItem
              label="Capital available (current)"
              value={
                <MoneyAmount size={14} cents={capitalAccountSummary.available} weight="medium" />
              }
            />

            <PaymentSummary.Section.SummaryItem
              label="Capital available after repaying"
              value={<MoneyAmount size={14} cents={availableAfterRepayment} weight="medium" />}
            />

            <PaymentSummary.SectionDivider />

            <PaymentSummary.Section.SummaryItem
              label="Settled balance (current)"
              value={
                <MoneyAmount
                  size={14}
                  cents={0 - capitalAccountSummary.cardBalance}
                  weight="medium"
                />
              }
            />

            <PaymentSummary.Section.SummaryItem
              label="Settled balance after repaying"
              value={
                <MoneyAmount
                  size={14}
                  cents={0 - capitalAccountSummary.cardBalance - amount}
                  weight="medium"
                />
              }
            />

            {Boolean(remainingAmountDue) && (
              <>
                <PaymentSummary.Section.SummaryItem
                  label={`Due ${dayjs(nextRepaymentDueDate).format("MMM D")} (current)`}
                  value={<MoneyAmount size={14} cents={remainingAmountDue} weight="medium" />}
                />

                <PaymentSummary.Section.SummaryItem
                  label={`Due ${dayjs(nextRepaymentDueDate).format("MMM D")} after repaying`}
                  value={
                    <MoneyAmount
                      size={14}
                      cents={Math.max(remainingAmountDue - amount, 0)}
                      weight="medium"
                    />
                  }
                />
              </>
            )}
          </PaymentSummary.Section>
        </PaymentSummary>
      </MultiStep.Section>

      <MultiStep.Controls>
        <Link to={`/capital/${capitalAccountSummary.guid}/cards/repay`}>
          <MultiStep.Controls.BackButton />
        </Link>

        <MultiStep.Controls.NextButton
          form={REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID}
          autoFocus
          isLoading={isPending}
        >
          Complete repayment
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

const isValidRepayHighbeamCardsConfirmationViewState = (
  state: unknown
): state is RepayHighbeamCardsConfirmationViewState => {
  return (
    typeof state === "object" &&
    state !== null &&
    "amount" in state &&
    typeof state.amount === "number" &&
    "repaymentBankAccountGuid" in state &&
    typeof state.repaymentBankAccountGuid === "string"
  );
};

const RepayHighbeamCardsConfirmationView = () => {
  const { state } = useLocation();

  if (!isValidRepayHighbeamCardsConfirmationViewState(state)) {
    return <Navigate to="/capital" />;
  }

  return (
    <RepayHighbeamCardsConfirmationForm
      amount={state.amount}
      repaymentBankAccountGuid={state.repaymentBankAccountGuid}
    />
  );
};

export default RepayHighbeamCardsConfirmationView;
