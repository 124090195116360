import { MagnifyingGlass } from "@phosphor-icons/react";
import classNames from "classnames";
import { ComponentProps, FC } from "react";
import TextInput from "ui/inputs/TextInput";

import styles from "./TransactionsV2.module.scss";

type Props = ComponentProps<typeof TextInput> & {
  wide?: boolean;
};

const SearchBar: FC<Props> = ({ wide = true, ...textInputProps }) => (
  <TextInput
    id="search-filter"
    placeholder="Search for transaction"
    icon={<MagnifyingGlass size={12} />}
    className={classNames(styles.search, wide && styles.wide)}
    {...textInputProps}
  />
);

export default SearchBar;
