import { useSuspenseQuery } from "@tanstack/react-query";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import useCardQueryOptions from "./useCardQueryOptions";

const useCard = <TRequired extends boolean = false>(
  cardId: string,
  params?: RequiredParam<TRequired>
) => {
  const { data } = useSuspenseQuery(useCardQueryOptions(cardId));

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useCard;
