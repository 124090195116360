import { Check, Info } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { useChatWidget } from "components/ChatWidget";
import useConnectedStoresQueryOptions from "resources/connected-stores/queries/useConnectedStoresQueryOptions";
import { ConnectedStoreType } from "resources/connected-stores/types";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import ActivationPendingBannerItem from "../ActivationPendingBannerItem";

import styles from "./ActivationPendingBanner.module.scss";

type ReroutingPaymentsBannerHeaderProps = {
  onClickHavingTrouble: () => void;
};

const ReroutingPaymentsBannerHeader: React.FC<ReroutingPaymentsBannerHeaderProps> = ({
  onClickHavingTrouble,
}) => {
  const { data: connectedStores = [] } = useQuery(useConnectedStoresQueryOptions());
  return (
    <div className={styles.bannerHeader}>
      <div className={styles.contentContainer}>
        <div className={styles.iconContainer}>
          <Info size={24} color={colors.grey[800]} />
        </div>
        <div className={styles.textContainer}>
          <Text size={14} color={colors.grey[800]} weight="bold">
            Rerouting payouts
          </Text>
          <Text size={14} color={colors.grey[800]} weight="regular">
            In order to draw down from Highbeam, you will need to reroute your payouts.
            {connectedStores.length ? ' Click "Reroute payouts" to learn how.' : ""}
          </Text>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.button} onClick={onClickHavingTrouble}>
          <Check size={24} weight="light" />
          <Text size={14} color={colors.grey[800]} weight="medium">
            I already did this
          </Text>
        </button>
      </div>
    </div>
  );
};

const ActivationPendingBanner = () => {
  const chat = useChatWidget();

  const { data: connectedStores = [] } = useQuery(useConnectedStoresQueryOptions());

  const connectedStoresByType = connectedStores.reduce<ConnectedStoreType[]>(
    (acc, connectedStore) => {
      if (!acc.includes(connectedStore.type)) {
        acc.push(connectedStore.type);
      }
      return acc;
    },
    []
  );

  const handleClickHavingTrouble = () => {
    chat.message("I've already rerouted my payouts to Highbeam.");
  };

  return (
    <div className={styles.container}>
      <ReroutingPaymentsBannerHeader onClickHavingTrouble={handleClickHavingTrouble} />

      {connectedStoresByType.map((connectedStoreType, index) => (
        <div key={`${connectedStoreType}-${index}`}>
          <div className={styles.separator} />
          <ActivationPendingBannerItem connectedStoreType={connectedStoreType} />
        </div>
      ))}
    </div>
  );
};

export default ActivationPendingBanner;
