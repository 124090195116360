import MultiStep from "components/layouts/MultiStep";
import { FormEventHandler } from "react";
import { Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import TextArea from "ui/inputs/TextArea";

import { useDrawdownContext, useDrawdownInvoiceFormContext } from "../context/DrawdownProvider";

import DrawdownInvoiceUploadSection from "./DrawdownInvoiceUploadSection";

const DRAW_DOWN_INVOICE_FORM_ID = "draw-down-invoice-form";

const DrawdownInvoiceView = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const { nextPathname, prevPathname } = useDrawdownContext();

  const { control, formState } = useDrawdownInvoiceFormContext();

  const navigate = useNavigate();

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  return (
    <MultiStep.Form id={DRAW_DOWN_INVOICE_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Upload invoice</MultiStep.Section.Header.Heading>
          <MultiStep.Section.Header.Subheading>
            Upload the invoice you plan to pay with this drawdown. Highbeam will review the invoice
            to verify that the drawdown is for inventory spend. Review can take{" "}
            <strong>up to 3 business days.</strong>
          </MultiStep.Section.Header.Subheading>
        </MultiStep.Section.Header>

        <MultiStep.Section>
          <DrawdownInvoiceUploadSection />
        </MultiStep.Section>

        <MultiStep.Section>
          <Controller
            control={control}
            name="reasonForDrawdown"
            render={({ field }) => (
              <TextArea placeholder="Reason for drawdown" onChange={(val) => field.onChange(val)} />
            )}
          />
        </MultiStep.Section>
      </MultiStep.Section>

      <MultiStep.Controls>
        <Link to={prevPathname || `/capital/${capitalAccountSummary.guid}`}>
          <MultiStep.Controls.BackButton />
        </Link>
        <MultiStep.Controls.NextButton
          form={DRAW_DOWN_INVOICE_FORM_ID}
          disabled={!formState.isValid}
        >
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default DrawdownInvoiceView;
