import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import useUserRolesQueryOptions from "resources/user-roles/queries/useUserRolesQueryOptions";
import RadioCard from "ui/inputs/RadioCard";
import RadioCardSelect from "ui/inputs/RadioCardSelect";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import sortUserRoles from "../utils/sortUserRoles";

import { UserRoleOptionDescription, UserRoleOptionIcon } from "./constants";
import { InviteUserFormInputs } from "./hooks/useInviteUserForm";

const UserRoleSelect = () => {
  const showEmployeeOption = useFeatureFlag("EMPLOYEE_USER_ROLE_INVITATION");

  const { control } = useFormContext<InviteUserFormInputs>();
  const { data: userRoles } = useSuspenseQuery({
    ...useUserRolesQueryOptions(),
    select: (userRoles) => {
      if (showEmployeeOption) {
        return userRoles;
      } else {
        return userRoles.filter((userRole) => userRole.type !== "Employee");
      }
    },
  });
  const sortedUserRoles = useMemo(() => sortUserRoles(userRoles), [userRoles]);

  return (
    <Controller
      name="userRole"
      control={control}
      render={({
        field: {
          ref: _ref, // `RadioCardSelect` does not support `ref` yet so we need to omit it.
          ...field
        },
      }) => (
        <RadioCardSelect options={sortedUserRoles} value={field.value} onChange={field.onChange}>
          {({ option: role, isSelected, onSelect }) => (
            <RadioCard
              key={role.guid}
              icon={<UserRoleOptionIcon type={role.type} />}
              checked={isSelected}
              value={role.guid}
              label={role.name}
              description={<UserRoleOptionDescription type={role.type} />}
              onChange={() => onSelect(role)}
            />
          )}
        </RadioCardSelect>
      )}
    />
  );
};

export default UserRoleSelect;
