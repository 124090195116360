import { useEffect, useState } from "react";
import UserReferralRep from "reps/ReferralLinkRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

export function useReferral(referralSlug: string | undefined) {
  const highbeamApi = useHighbeamApi({ authMode: "maybeAuthenticated" });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const [referral, setReferral] = useState<UserReferralRep>();

  useEffect(() => {
    (async () => {
      if (!referralSlug) return;
      try {
        const referral = await highbeamApi.referralLink.get(referralSlug);
        setReferral(referral || undefined);
      } catch (e) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [highbeamApi, referralSlug]);
  return {
    isLoading,
    error,
    referral,
  };
}
