import { Statement } from "@highbeam/unit-node-sdk";
import { DownloadSimple } from "@phosphor-icons/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import emptyImg from "assets/empty-state.svg";
import env from "env";
import { useState } from "react";
import useAllBankAccounts from "resources/bank-accounts/queries/useAllBankAccounts";
import getBankAccountDropdownOptions from "resources/bank-accounts/utils/getBankAccountDropdownOptions";
import getBankAccountOptionsByUnitId from "resources/bank-accounts/utils/getBankAccountOptionsByUnitId";
import useBusiness from "resources/business/queries/useBusiness";
import useUnitCoCustomerToken from "resources/unit-co-customer-token/queries/useUnitCoCustomerToken";
import useUnitCoStatementsQueryOptions from "resources/unit-co-statements/queries/useUnitCoStatementsQueryOptions";
import BankAccount from "ui/data-display/BankAccount";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Button from "ui/inputs/Button";
import EmptyState from "ui/table/EmptyState";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import { downloadBlob } from "utils/download";

import styles from "./AccountsStatements.module.scss";
import formatStatementPeriod from "./utils/formatStatementPeriod";

type Props = {
  accountId?: string;
};

const getStatementFileName = (statement: Statement, accountNumberLast4Digits: string) =>
  `highbeam-statement-${accountNumberLast4Digits}-${statement.attributes.period}.pdf`;

const AccountStatements: React.FC<Props> = ({ accountId }) => {
  const { unitCoCustomerId: customerId } = useBusiness();
  const { data: statements } = useSuspenseQuery(
    useUnitCoStatementsQueryOptions({ customerId: customerId ?? undefined, accountId })
  );
  const unitCoCustomerToken = useUnitCoCustomerToken();
  const [downloadingStatementId, setDownloadingStatementId] = useState<string | null>();

  const bankAccounts = useAllBankAccounts();

  const bankAccountOptions = getBankAccountDropdownOptions(bankAccounts);
  const bankAccountOptionsByUnitId = getBankAccountOptionsByUnitId(bankAccountOptions);

  const downloadStatement = async (statement: Statement) => {
    setDownloadingStatementId(statement.id);

    const bankAccount = bankAccounts.find(
      (account) => account.unitCoDepositAccountId === statement.relationships.account.data.id
    );

    const accountNumberLast4Digits = bankAccount?.accountNumber.slice(-4) ?? "";

    // TODO: Use Unit SDK
    const res = await fetch(`${env.UNIT_API_ORIGIN}/statements/${statement.id}/pdf`, {
      headers: {
        Authorization: `Bearer ${unitCoCustomerToken}`,
      },
    });
    const blob = await res.blob();
    downloadBlob(
      getStatementFileName(statement, accountNumberLast4Digits),
      window.URL.createObjectURL(blob)
    );

    setDownloadingStatementId(null);
  };

  if (!statements || statements.length === 0)
    return (
      <EmptyState
        image={emptyImg}
        primaryText="No statements available."
        secondaryText="Once you have statements, they will be shown here."
      />
    );
  return (
    <Table
      rowKey="id"
      className={styles.table}
      columns={[
        {
          title: "Period",
          cellRender: (statement: Statement) => (
            <Text size={14}>{formatStatementPeriod(statement.attributes.period)}</Text>
          ),
        },
        {
          title: "Account",
          cellRender: (statement) => {
            const accountName =
              bankAccountOptionsByUnitId[
                statement.relationships.account.data.id
              ]?.description?.toString();
            const isDisabled =
              bankAccountOptionsByUnitId[statement.relationships.account.data.id]?.isDisabled;
            if (!accountName) {
              return <NotFoundCell />;
            }
            return <BankAccount accountName={accountName} isDisabled={isDisabled} />;
          },
        },
        {
          title: "Action",
          align: TableColumnAlignment.RIGHT,
          cellClassName: styles.action,
          cellRender: (statement: Statement) => {
            const isDownloading = downloadingStatementId === statement.id;
            return (
              <Button
                size="sm"
                variant="tertiary"
                onClick={() => downloadStatement(statement)}
                disabled={isDownloading}
              >
                {isDownloading ? <AnimatedSpinner /> : <DownloadSimple size={14} />}
                Download
              </Button>
            );
          },
        },
      ]}
      data={statements}
    />
  );
};

export default AccountStatements;
