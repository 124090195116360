import { ChatDots, ListDashes, Path } from "@phosphor-icons/react";
import { getCurrencyOption } from "pages/SendMoneyPage/internationalWires";
import { FC, useEffect } from "react";
import BillRep from "reps/BillRep";
import BillAuditTrail from "resources/bills/components/BillAuditTrail";
import BillComments from "resources/bills/components/BillComments";
import BillCreatePayeeForm from "resources/bills/forms/BillCreatePayeeForm";
import BillUpdatePayeeForm from "resources/bills/forms/BillUpdatePayeeForm";
import useBill from "resources/bills/queries/useBill";
import { useBillCommentsCountQuery } from "resources/bills/queries/useBillComments";
import useBillCreatePayeeFormDefaultPayeeName from "resources/bills/search-params/useBillCreatePayeeFormDefaultPayeeName";
import useBillInfoFormTabs from "resources/bills/search-params/useBillInfoFormTabs";
import { usePayeeQuery } from "resources/payees/queries/usePayee";
import DetailsSidebarPanel from "ui/data-display/DetailsSidebarPanel";
import TabsV2 from "ui/navigation/TabsV2";
import toDayjsOrNull from "utils/date/toDayjsOrNull";
import useSearchParamOption from "utils/searchParams/useSearchParamOption";
import { TabConfig } from "utils/tabs/useTabState";
import getObjectKeys from "utils/ts/getObjectKeys";

import BillDetailsForm from "../../forms/BillDetailsForm";
import useBillDetailsForm, {
  BillDetailsFormInputs,
} from "../../forms/BillDetailsForm/useBillDetailsForm";

import styles from "./BillDetailsInfoView.module.scss";

type Props = {
  billId: string;
  onCreatePayment: (formValues: BillDetailsFormInputs) => void;
  onDeleteSuccessful: () => void;
  onDraftBillSavedAsOpenSuccess: () => void;
  onMarkAsPaid: () => void;
};

const BillDetailsInfoView: FC<Props> = ({
  billId,
  onCreatePayment,
  onDeleteSuccessful,
  onDraftBillSavedAsOpenSuccess,
  onMarkAsPaid,
}) => {
  const { data: commentsCount } = useBillCommentsCountQuery(billId);

  const billDetailsInfoViewTabs = {
    details: {
      icon: <ListDashes size={16} />,
      label: "Details",
    },
    comments: {
      icon: <ChatDots size={16} />,
      label: "Comments",
      count: commentsCount || undefined,
    },
    "audit-trail": {
      icon: <Path size={16} />,
      label: "Audit trail",
    },
  } satisfies TabConfig;

  const [activeTab, setActiveTab] = useSearchParamOption(
    "detail-tab",
    getObjectKeys(billDetailsInfoViewTabs),
    "details"
  );

  // NB(alex): We should use a multi-step form component here, ideally with animations, perhaps via Framer Motion.
  const { activeTab: billInfoFormActiveTab, setActiveTab: setBillInfoFormActiveTab } =
    useBillInfoFormTabs();
  const [_, setBillCreatePayeeFormDefaultPayeeName] = useBillCreatePayeeFormDefaultPayeeName();

  // NB(alex): It would be nice to figure out a pattern for batching these queries to run asynchronously.
  const bill = useBill(billId, { required: true });

  const { data: payee } = usePayeeQuery(bill.payeeGuid ?? undefined);

  const billDetailsForm = useBillDetailsForm({
    disabled: !bill.isEditable,
    defaultValues: {
      payee: null,
      amount: {
        amount: bill.amount?.amount ?? "",
        currency: bill.amount?.currency ? (getCurrencyOption(bill.amount.currency) ?? null) : null,
      },
      invoiceDate: toDayjsOrNull(bill.invoiceDate),
      invoiceDueDate: toDayjsOrNull(bill.invoiceDueDate),
      invoiceNumber: bill.invoiceNumber ?? "",
      paymentTerms: bill.paymentTerms ?? "",
      purchaseOrderNumber: bill.purchaseOrderNumber ?? "",
      memo: bill.memo ?? "",
    },
  });

  // Resets the payee field's default value without triggering suspense.
  useEffect(() => {
    // We do not want to reset the default value when the payee is `undefined`, but we do want to reset the field if it becomes `null`.
    const hasSetDefaultValue = billDetailsForm.getFieldState("payee").isDirty;
    if (payee !== undefined && !hasSetDefaultValue) {
      billDetailsForm.resetField("payee", { defaultValue: payee });
      billDetailsForm.setValue("payee", payee, { shouldTouch: false });
    }
  }, [payee, billDetailsForm]);

  return (
    <DetailsSidebarPanel>
      <DetailsSidebarPanel.Header>
        {bill.state !== BillRep.State.Draft && (
          <TabsV2
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={billDetailsInfoViewTabs}
            justify="space-around"
            className={styles.tabs}
          />
        )}
      </DetailsSidebarPanel.Header>

      <DetailsSidebarPanel.Body>
        {activeTab === "details" && (
          <>
            {billInfoFormActiveTab === "bill-info" && (
              <BillDetailsForm
                bill={bill}
                form={billDetailsForm}
                onCreatePayment={onCreatePayment}
                onDeleteSuccessful={onDeleteSuccessful}
                onDraftBillSavedAsOpenSuccess={onDraftBillSavedAsOpenSuccess}
                onMarkAsPaid={onMarkAsPaid}
                onShowPaymentDetails={() => setBillInfoFormActiveTab("update-payee-details")}
                onCreateNewPayee={async (defaultPayeeName) => {
                  setBillInfoFormActiveTab("create-payee-details");
                  setBillCreatePayeeFormDefaultPayeeName(defaultPayeeName);
                }}
              />
            )}

            {billInfoFormActiveTab === "update-payee-details" && payee && (
              <BillUpdatePayeeForm
                onBack={() => setBillInfoFormActiveTab("bill-info")}
                payee={payee}
              />
            )}

            {(billInfoFormActiveTab === "create-payee-details" ||
              // Fall back to create payee form if trying to update payee details but `payee` is not defined.
              (billInfoFormActiveTab === "update-payee-details" && !payee)) && (
              <BillCreatePayeeForm
                billId={bill.id}
                onBack={() => setBillInfoFormActiveTab("bill-info")}
                onSuccess={(createdPayee) => {
                  billDetailsForm.setValue("payee", createdPayee);
                  setBillInfoFormActiveTab("bill-info");
                }}
              />
            )}
          </>
        )}
        {activeTab === "comments" && <BillComments bill={bill} />}
        {activeTab === "audit-trail" && <BillAuditTrail bill={bill} />}
      </DetailsSidebarPanel.Body>
    </DetailsSidebarPanel>
  );
};

export default BillDetailsInfoView;
