import { CaretDown } from "@phosphor-icons/react";
import classNames from "classnames";
import React, { PropsWithChildren, ReactNode, useEffect, useRef, useState } from "react";
import Button from "ui/inputs/Button";
import { transitionDuration } from "utils/transitions";

import styles from "./CollapsibleAccordion.module.scss";

type Props = {
  heading: ReactNode;
  children: ReactNode;
} & PropsWithChildren;

const CollapsibleAccordion: React.FC<Props> = ({ heading, children }) => {
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((currVal) => !currVal);
  };

  /**
   * It's not possible to do vertical animations in CSS,
   * so we do some magic in the [useEffect] to dynamically set the height to a specific value.
   */
  useEffect(() => {
    const ref = contentWrapperRef.current;
    if (!ref) return;
    if (isOpen) {
      ref.style.height = `${ref.scrollHeight}px`;
      setTimeout(() => (ref.style.height = "auto"), transitionDuration);
    } else {
      ref.style.height = `${ref.scrollHeight}px`;
      setTimeout(() => (ref.style.height = "0"), 0);
    }
  }, [isOpen]);

  return (
    <div className={classNames(styles.container, { [styles.isOpen]: isOpen })}>
      <Button variant="ghost" onClick={toggleIsOpen} className={styles.heading}>
        {heading}
        <CaretDown
          size={16}
          className={classNames(styles.headingIcon, { [styles.isOpen]: isOpen })}
        />
      </Button>
      <div className={classNames(styles.contentWrapper)} ref={contentWrapperRef}>
        <div className={classNames(styles.content, { [styles.isOpen]: isOpen })}>{children}</div>
      </div>
    </div>
  );
};

export default CollapsibleAccordion;
