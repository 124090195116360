import UserReferralRep from "reps/ReferralLinkRep";

import ExpiredReferralHeader from "./components/ExpiredReferralHeader";
import ReferralContent from "./components/ReferralContent";
import styles from "./ExpiredReferral.module.scss";

type Props = {
  referral: UserReferralRep;
};

const ExpiredReferral: React.FC<Props> = ({ referral }) => {
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <ExpiredReferralHeader referral={referral} />
        <ReferralContent referral={referral} />
      </div>
    </div>
  );
};

export default ExpiredReferral;
