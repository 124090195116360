// TODO: Generate from backend code

const BASE = "https://highbeam.co/";

const JwtClaims = {
  expiresAt: "exp",

  acl: `${BASE}acl`,
  bankAccounts: `${BASE}bank-accounts`,
  businesses: `${BASE}businesses`,
  mfa: `${BASE}mfa`,
  roles: `${BASE}roles`,
  user: `${BASE}user`,
};

export default JwtClaims;
