import UserInvitationRep from "reps/UserInvitationRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class UserInvitationApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(
    businessGuid: string,
    invitation: UserInvitationRep.Creation
  ): Promise<UserInvitationRep.Complete> {
    const url = `/businesses/${businessGuid}/user-invitations`;
    return (await this.api.post<UserInvitationRep.Complete>(url, invitation))!;
  }

  async getBySlug(invitationSlug: string): Promise<UserInvitationRep.Complete | null> {
    const url = `/user-invitations/${invitationSlug}`;
    return await this.api.get<UserInvitationRep.Complete>(url);
  }

  async getByBusiness(businessGuid: string): Promise<UserInvitationRep.Complete[]> {
    const url = `/businesses/${businessGuid}/user-invitations`;
    return (await this.api.get<UserInvitationRep.Complete[]>(url))!;
  }

  async delete(
    businessGuid: string,
    invitationGuid: string
  ): Promise<UserInvitationRep.Complete | null> {
    const url = `/businesses/${businessGuid}/user-invitations/${invitationGuid}`;
    return await this.api.delete<UserInvitationRep.Complete>(url);
  }
}
