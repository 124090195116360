import { ComponentProps, FC } from "react";
import isHighCreditUtilization from "resources/line-of-credit/utils/isHighCreditUtilization";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./CapitalAvailableTooltipContent.module.scss";

const Label = (props: TextProps) => <Text size={14} weight="regular" {...props} />;

const Value = ({ cents, ...moneyAmountProps }: ComponentProps<typeof MoneyAmount>) => (
  <MoneyAmount
    direction={cents < 0 ? "negative" : "none"}
    cents={cents}
    weight="medium"
    {...moneyAmountProps}
  />
);

type Props = {
  capitalLimit: number;
  lineOfCreditUsed: number;
  highbeamCardBalance: number;
  hasHighbeamCard: boolean;
};

const CapitalAvailableTooltipContent: FC<Props> = ({
  capitalLimit,
  lineOfCreditUsed,
  highbeamCardBalance,
  hasHighbeamCard,
}) => {
  const lineOfCreditUsedAsNegative = 0 - lineOfCreditUsed;
  const highbeamCardBalanceAsNegative = 0 - highbeamCardBalance;
  const capitalAvailable =
    capitalLimit + lineOfCreditUsedAsNegative + highbeamCardBalanceAsNegative;

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Label color={colors.grey[900]}>
          {hasHighbeamCard ? "Highbeam card limit" : "Capital limit"}
        </Label>
        <Value cents={capitalLimit} />
      </div>
      <div className={styles.row}>
        <Label color={colors.grey[600]}>
          {hasHighbeamCard ? "Last statement balance" : "Line of credit used"}
        </Label>
        <Value cents={lineOfCreditUsedAsNegative} />
      </div>
      {hasHighbeamCard && (
        <div className={styles.row}>
          <Label color={colors.grey[600]}>Highbeam Card balance</Label>
          <Value cents={highbeamCardBalanceAsNegative} />
        </div>
      )}

      <Divider margin={0} />

      <div className={styles.row}>
        <Label color={colors.grey[900]}>Capital available</Label>
        <Value
          cents={capitalAvailable}
          color={
            isHighCreditUtilization(lineOfCreditUsed, capitalLimit)
              ? colors.yellow[700]
              : colors.green[500]
          }
        />
      </div>
    </div>
  );
};

export default CapitalAvailableTooltipContent;
