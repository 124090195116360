import fedHolidays from "@18f/us-federal-holidays";
import { Clock, Repeat } from "@phosphor-icons/react";
import { Dayjs } from "dayjs";
import { MultipleRecurringPaymentType, PaymentType } from "pages/SendMoneyPage";
import { PayeeOption, payeeToPayeeOption, unitDayOfMonth } from "pages/SendMoneyPage/utils";
import { useEffect, useMemo, useState } from "react";
import useCreatePayeeMutation from "resources/payees/mutations/useCreatePayeeMutation";
import { useActivePayeesQuery } from "resources/payees/queries/usePayees";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import DatePicker, { isWeekdayOrCurrentDate } from "ui/inputs/DatePicker";
import Dropdown from "ui/inputs/Dropdown";
import RadioLabel from "ui/inputs/RadioLabel";
import TextInput from "ui/inputs/TextInput";
import StepHeader from "ui/navigation/Steps/StepHeader";
import Tabs, { Tab } from "ui/navigation/Tabs";
import Text from "ui/typography/Text";
import { dateOnly, startOfBankingDay } from "utils/date";
import { nth } from "utils/numbers";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";

import styles from "./PayeeSchedule.module.scss";

const MAX_FIXED_NUMBER_OF_PAYMENTS = 100;

type Props = {
  selectedPayee: PayeeOption | null;
  setSelectedPayee: (newPayee: PayeeOption | null) => void;
  onNextPress: () => void;
  scheduledDate: Dayjs;
  setScheduledDate: (newScheduledDate: Dayjs) => void;
  numberOfPayments?: string;
  setNumberOfPayments: (newNumberOfPayments?: string) => void;
  fixedNumberOfPayments?: string;
  setFixedNumberOfPayments: (newFixedNumberOfPayments: string) => void;
  multipleRecurringPaymentType: MultipleRecurringPaymentType;
  setMultipleRecurringPaymentType: (newRecurringPaymentType: MultipleRecurringPaymentType) => void;
  activeTab: string;
  setActiveTab: (newActiveTab: string) => void;
};

export const tabs: Tab[] = [
  {
    id: "oneTimePayment",
    label: "One time payment",
  },
  {
    id: "recurringPayment",
    label: "Recurring payment",
  },
];

const PayeeSchedule: React.FC<Props> = ({
  selectedPayee,
  setSelectedPayee,
  onNextPress,
  scheduledDate,
  setScheduledDate,
  numberOfPayments,
  setNumberOfPayments,
  fixedNumberOfPayments,
  setFixedNumberOfPayments,
  multipleRecurringPaymentType,
  setMultipleRecurringPaymentType,
  activeTab,
  setActiveTab,
}) => {
  const { data: payees = [], isLoading: isPayeesLoading } = useActivePayeesQuery();
  const { mutateAsync: createPayee, isPending: isCreatingPayee } = useCreatePayeeMutation();
  const isLoading = isPayeesLoading || isCreatingPayee;

  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();

  const [payeeInput, setPayeeInput] = useState("");
  const payeeOptions: PayeeOption[] = useMemo(() => payees.map(payeeToPayeeOption), [payees]);
  // useState required here otherwise moment's time is updated each time the component reloads
  const [now] = useState(startOfBankingDay());

  const lastTransferredPayeeOptions: PayeeOption[] = useMemo(
    () =>
      payeeOptions
        .filter((payee) => payee.lastTransferred)
        .sort((a, b) => b.lastTransferred!.valueOf() - a.lastTransferred!.valueOf())
        .slice(0, 5),
    [payeeOptions]
  );

  const handlePayeeChange = (payee: PayeeOption) => {
    if (!payee) return;
    setSelectedPayee(payee);
  };

  const handleNewPayee = async (name: string) => {
    setSelectedPayee(null);
    const payee = await createPayee({ name });
    if (payee.guid) {
      setSelectedPayee({ value: payee.guid, label: name });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNextPress();
  };

  const hasError = () => {
    if (numberOfPayments) {
      return Number(numberOfPayments) <= 1 || !Number.isInteger(Number(numberOfPayments));
    }
  };

  useEffect(() => {
    if (activeTab === PaymentType.ONE_TIME) {
      setNumberOfPayments("1");
    } else if (activeTab === PaymentType.MULTIPLE) {
      if (multipleRecurringPaymentType === MultipleRecurringPaymentType.FIXED) {
        setNumberOfPayments(fixedNumberOfPayments);
      } else {
        setNumberOfPayments(undefined);
      }
    }
  }, [activeTab, fixedNumberOfPayments, multipleRecurringPaymentType, setNumberOfPayments]);

  const isNextStepAllowed = () => {
    if (activeTab === PaymentType.ONE_TIME) {
      return Boolean(selectedPayee);
    } else if (activeTab === PaymentType.MULTIPLE) {
      if (multipleRecurringPaymentType === MultipleRecurringPaymentType.FIXED) {
        return (
          Boolean(selectedPayee) &&
          numberOfPayments !== undefined &&
          numberOfPayments !== "" &&
          Number(numberOfPayments) > 1 &&
          Number(numberOfPayments) < MAX_FIXED_NUMBER_OF_PAYMENTS &&
          Number.isInteger(Number(numberOfPayments))
        );
      } else if (multipleRecurringPaymentType === MultipleRecurringPaymentType.UNTIL_CANCELED) {
        return Boolean(selectedPayee) && numberOfPayments === undefined;
      }
    }
  };

  const getFormattedDate = () => {
    const day = scheduledDate.date();
    const unitDay = unitDayOfMonth(scheduledDate);
    switch (unitDay) {
      case -1:
        return "last day";
      case -2:
        return "second to last day";
      case -3:
        return "third to last day";
      default:
        return `${day}${nth(day)}`;
    }
  };

  const isMultiplePayment = activeTab === PaymentType.MULTIPLE;

  const minDate = dateOnly(now).toDate();
  // You aren't allowed to schedule a transaction further than 1 year into the future
  const maxDate = dateOnly(now.add(1, "y")).toDate();
  const usFederalHolidays = fedHolidays.inRange(minDate, maxDate).map((holiday) => holiday.date);

  const recurringPaymentRepeatInfo = (
    <div className={styles.infoText}>
      <Repeat color={colors.purple[500]} size={14} />
      <Text size={14} weight="medium">
        {`Repeats on the ${getFormattedDate()} of every month`}
      </Text>
    </div>
  );

  const multipleRecurringPaymentTypeSelect = (
    <div className={styles.container}>
      <Text size={14} weight="bold">
        Number of payments
      </Text>
      <div className={styles.grid}>
        <div className={styles.firstHalf}>
          <RadioLabel
            label="Fixed"
            checked={multipleRecurringPaymentType === MultipleRecurringPaymentType.FIXED}
            onClick={() => {
              setNumberOfPayments(fixedNumberOfPayments);
              setMultipleRecurringPaymentType(MultipleRecurringPaymentType.FIXED);
            }}
          />
        </div>
        <div className={styles.secondHalf}>
          <RadioLabel
            label="Until canceled"
            checked={multipleRecurringPaymentType === MultipleRecurringPaymentType.UNTIL_CANCELED}
            onClick={() => {
              setMultipleRecurringPaymentType(MultipleRecurringPaymentType.UNTIL_CANCELED);
              setNumberOfPayments(undefined);
            }}
          />
        </div>
      </div>
      {multipleRecurringPaymentType === MultipleRecurringPaymentType.FIXED && (
        <TextInput
          placeholder="Total number of payments"
          type="number"
          value={fixedNumberOfPayments}
          onChange={(val) => {
            setNumberOfPayments(val);
            setFixedNumberOfPayments(val);
          }}
          hasError={hasError()}
          errorMessage={`Must have at least 2 payments to create a recurring payment. Please select “One time payment” if you want only one payment.`}
        ></TextInput>
      )}
    </div>
  );

  return (
    <>
      <StepHeader
        disableBackButton
        disableForwardButton={!isNextStepAllowed()}
        handleForwardButtonClick={onNextPress}
        stepNumber={1}
        title="Payee"
      />
      <form onSubmit={handleSubmit} className={styles.formData}>
        <Dropdown
          inputValue={payeeInput}
          isLoading={isLoading}
          disabled={isLoading}
          menuPrepend={
            !payeeInput && lastTransferredPayeeOptions.length > 0
              ? { icon: Clock, text: "Recently transferred" }
              : undefined
          }
          formatCreateLabel={(inputValue) => (
            <Dropdown.CreateLabel>Create new payee "{inputValue}"</Dropdown.CreateLabel>
          )}
          onCreate={handleNewPayee}
          value={selectedPayee}
          onInputChange={setPayeeInput}
          onChange={handlePayeeChange}
          onClear={() => setSelectedPayee(null)}
          id="payee-legal-name"
          label="Payee’s legal name"
          options={
            !payeeInput && lastTransferredPayeeOptions.length > 0
              ? lastTransferredPayeeOptions
              : payeeOptions
          }
          isClearable
          hideCaret
          hideCursor={selectedPayee !== null}
        />
        {isAllowedToApprovePayments && (
          <>
            <Tabs
              variant="rounded--large"
              tabs={tabs}
              noBorder
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            {isMultiplePayment ? (
              <DatePicker
                value={dateOnly(scheduledDate).toDate()}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => setScheduledDate?.(startOfBankingDay(date))}
                label="Initiate on"
                variant="no-date"
                startDate={dateOnly(scheduledDate).toDate()}
                endDate={dateOnly(scheduledDate).toDate()}
              />
            ) : (
              <DatePicker
                value={dateOnly(scheduledDate).toDate()}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => setScheduledDate?.(startOfBankingDay(date))}
                label="Initiate on"
                variant="no-date"
                startDate={dateOnly(scheduledDate).toDate()}
                endDate={dateOnly(scheduledDate).toDate()}
                filterDate={isWeekdayOrCurrentDate}
                excludeDates={usFederalHolidays}
              />
            )}
            {isMultiplePayment && recurringPaymentRepeatInfo}
            {isMultiplePayment && multipleRecurringPaymentTypeSelect}
          </>
        )}
        <div className={styles.footer}>
          <Button type="submit" variant="primary" disabled={!isNextStepAllowed()}>
            Next
          </Button>
        </div>
      </form>
    </>
  );
};

export default PayeeSchedule;
