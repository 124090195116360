import colors from "styles/colors";
import BankAccount from "ui/data-display/BankAccount";
import Text from "ui/typography/Text";
import { HighbeamTransaction } from "utils/types/transactionsTypes";

import styles from "./TransactionInfoFlexpaneToFromSection.module.scss";

type Props = {
  accountName: string;
  transaction: HighbeamTransaction;
};

const BankAccountDetail: React.FC<Props> = ({ accountName, transaction }) => (
  <>
    <BankAccount accountName={accountName} className={styles.bankAccount} />
    <Text size={14} color={colors.grey[500]}>
      {transaction.direction === "Debit" && transaction.formattedCreatedAt}
    </Text>
  </>
);

export default BankAccountDetail;
