import { FC } from "react";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";

import styles from "./CardLimits.module.scss";

type CardLimitBarProps = {
  current: number;
  total: number;
};

const CardLimitBar: FC<CardLimitBarProps> = ({ current, total }) => {
  const percentage = (current / total) * 100;

  return (
    <Text className={styles.barContainer}>
      <div className={styles.bar}>
        <div style={{ width: `${percentage}%` }} />
      </div>
      <Text size={14} weight="medium" className={styles.nowrap}>
        <MoneyAmount cents={current} withCents={false} />
        {" / "}
        <MoneyAmount cents={total} withCents={false} />
      </Text>
    </Text>
  );
};

export default CardLimitBar;
