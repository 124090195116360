import classNames from "classnames";
import { FC, ReactElement } from "react";

import styles from "./Avatar.module.scss";

export type AvatarColor = "purple-dark" | "purple-light" | "grey";

export type AvatarSize = 32 | 44;

type Props = {
  color: AvatarColor;
  size: AvatarSize;
  className?: string;
} & (
  | {
      initials?: undefined;
      icon: ReactElement;
    }
  | {
      initials: string;
      icon?: undefined;
    }
);

const Avatar: FC<Props> = ({ initials, icon, className, color, size }) => {
  return (
    <div
      className={classNames(
        className,
        styles.avatar,
        styles[`color--${color}`],
        styles[`size--${size}`]
      )}
    >
      {icon}
      {initials}
    </div>
  );
};

export default Avatar;
