import { ChartLine } from "@phosphor-icons/react";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import React from "react";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import Spacer from "../../ui/data-display/Spacer";

import BalanceTable from "./components/BalancesTable/BalancesTable";
import DailyPlTable from "./components/DailyPlTable/DailyPlTable";
import PayoutsTable from "./components/payoutsTable/PayoutsTable";

const HighbeamInsightsPage: React.FC = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Highbeam Insights</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <HighbeamInsightsHeader />

        <DashboardPage.Section>
          <BalanceTable />
          <Spacer height={44} />
          <PayoutsTable />
          <Spacer height={44} />
          <DailyPlTable />
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

export default HighbeamInsightsPage;

const HighbeamInsightsHeader: React.FC = () => {
  return (
    <DashboardPage.Header>
      <DashboardPage.Header.IconTile icon={<ChartLine />} />
      <DashboardPage.Header.Title>Highbeam Insights</DashboardPage.Header.Title>
    </DashboardPage.Header>
  );
};
