import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountAutoPayBankAccountHighlightItem from "resources/capital-accounts/components/CapitalAccountAutoPayBankAccountHighlightItem";
import CapitalAccountAutoPayConnectedStoresHighlightItem from "resources/capital-accounts/components/CapitalAccountAutoPayConnectedStoresHighlightItem";
import CapitalAccountInterestDueHighlightItem from "resources/capital-accounts/components/CapitalAccountInterestDueHighlightItem";
import getCapitalAccountRepaymentOptionType from "resources/capital-accounts/utils/getCapitalAccountRepaymentOptionType";
import Divider from "ui/data-display/Divider";
import { useIsMobile } from "utils/device/useMediaQuery";

import styles from "./CapitalAvailableHighlightItems.module.scss";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAvailableHighlightItems: React.FC<Props> = ({ capitalAccountSummary }) => {
  const isMobile = useIsMobile();

  return (
    <div className={styles.highlightCardsContainer}>
      <div className={styles.highlightItemContainer}>
        {getCapitalAccountRepaymentOptionType(capitalAccountSummary) === "DailyInstallments" ? (
          <CapitalAccountAutoPayBankAccountHighlightItem
            capitalAccountSummary={capitalAccountSummary}
            size="sm"
            sideRuleColor="none"
          />
        ) : (
          <CapitalAccountAutoPayConnectedStoresHighlightItem
            capitalAccountSummary={capitalAccountSummary}
            infoIconColor="primary"
            size="sm"
            sideRuleColor="none"
          />
        )}
      </div>

      <Divider className={styles.divider} orientation={isMobile ? "horizontal" : "vertical"} />

      {capitalAccountSummary.details.apr > 0 && (
        <div className={styles.highlightItemContainer}>
          <CapitalAccountInterestDueHighlightItem
            capitalAccountSummary={capitalAccountSummary}
            infoIconColor="primary"
            size="sm"
            sideRuleColor="none"
          />
        </div>
      )}
    </div>
  );
};

export default CapitalAvailableHighlightItems;
