import { ArrowRight } from "@phosphor-icons/react";
import classNames from "classnames";
import MetadataList from "components/MetadataList";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import useCapitalAccountSummary from "resources/capital-accounts/queries/useCapitalAccountSummary";
import getCapitalAccountRepaymentOptionType from "resources/capital-accounts/utils/getCapitalAccountRepaymentOptionType";
import isCapitalAccountSummaryWithChargeCard from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import OfferDetails from "ui/data-display/OfferDetails";
import Pill from "ui/data-display/Pill";
import BarChart from "ui/data-visualization/BarChart";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import CapitalAccountIcon from "../CapitalAccountIcon";
import CapitalPromotionalAprLabel from "../CapitalPromotionalAprLabel";

import styles from "./CapitalAccountCard.module.scss";
import CapitalAccountCardAutoPayBankAccountItem from "./CapitalAccountCardAutoPayBankAccountItem";
import CapitalAccountCardAutoPayConnectedStoresItem from "./CapitalAccountCardAutoPayConnectedStoresItem";
import CapitalAccountCardChargeCardCashBackOfferDetailsItem from "./CapitalAccountCardChargeCardCashBackOfferDetailsItem";
import CapitalAccountCardHeader from "./CapitalAccountCardHeader";
import CapitalAccountCardRepaymentPeriodOfferDetailsItem from "./CapitalAccountCardRepaymentPeriodOfferDetailsItem";
import CapitalAccountCardTodaysInterestItem from "./CapitalAccountCardTodaysInterestItem";

type CapitalAccountCardProps = {
  capitalAccountGuid: string;
};

const CapitalAccountCard: FC<CapitalAccountCardProps> = ({ capitalAccountGuid }) => {
  const capitalAccountSummary = useCapitalAccountSummary(capitalAccountGuid, { required: true });

  const isCapitalPromotionalRateEnabled = useFeatureFlag("CAPITAL_PROMOTIONAL_RATES");

  const availablePercentage =
    (capitalAccountSummary.available / capitalAccountSummary.details.limit) * 100;

  return (
    <div className={classNames(styles.container, styles.card)}>
      <div className={styles.headerContainer}>
        <CapitalAccountCardHeader
          icon={<CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} />}
          badge={
            capitalAccountSummary.state === CapitalAccountRep.State.Offered && (
              <Pill color="purple-light">
                {/* TODO(alex): Make this dynamic once the backend passes a dynamic expiration date */}
                {/* <CalendarBlank size={16} /> {14} days to accept */}
                New
              </Pill>
            )
          }
        >
          {capitalAccountSummary.name}
        </CapitalAccountCardHeader>
      </div>

      <div className={styles.bodyContainer}>
        <div className={styles.usageContainer}>
          <div className={styles.usageFractionContainer}>
            <MoneyAmount
              cents={capitalAccountSummary.available}
              color={colors.grey[800]}
              size={18}
              weight="medium"
              withCents={capitalAccountSummary.available !== capitalAccountSummary.details.limit}
            />

            <Text size={14} color={colors.grey[500]} weight="medium">
              {" / "}
            </Text>

            <MoneyAmount
              weight="medium"
              cents={capitalAccountSummary.details.limit}
              color={colors.grey[500]}
              size={14}
              withCents={capitalAccountSummary.details.limit % 100 !== 0}
            />
          </div>

          <BarChart size={10} backgroundVariant="striped">
            <BarChart.Bar
              color={
                capitalAccountSummary.state === CapitalAccountRep.State.Offered
                  ? "green-disabled"
                  : "green"
              }
              widthPercentage={availablePercentage}
            />
          </BarChart>
        </div>

        {capitalAccountSummary.state === CapitalAccountRep.State.Offered ? (
          <OfferDetails>
            <>
              {capitalAccountSummary.type === CapitalAccountRep.Type.CashAccessOnly &&
                capitalAccountSummary.details.apr > 0 && (
                  <>
                    <OfferDetails.Item
                      label={
                        isCapitalPromotionalRateEnabled ? (
                          <CapitalPromotionalAprLabel withGradient />
                        ) : (
                          "APR"
                        )
                      }
                      value={`${capitalAccountSummary.details.apr * 100}%`}
                    />

                    <Divider orientation="vertical" />
                  </>
                )}

              {isCapitalAccountSummaryWithChargeCard(capitalAccountSummary) && (
                <>
                  <CapitalAccountCardChargeCardCashBackOfferDetailsItem
                    capitalAccountSummaryWithChargeCard={capitalAccountSummary}
                  />

                  <Divider orientation="vertical" />
                </>
              )}

              <CapitalAccountCardRepaymentPeriodOfferDetailsItem
                capitalAccountSummary={capitalAccountSummary}
              />
            </>
          </OfferDetails>
        ) : (
          <div className={styles.metadataListContainer}>
            <MetadataList className={styles.metadataList}>
              <CapitalAccountCardTodaysInterestItem capitalAccountGuid={capitalAccountGuid} />
              {getCapitalAccountRepaymentOptionType(capitalAccountSummary) ===
                "PayoutPercentage" && (
                <CapitalAccountCardAutoPayConnectedStoresItem
                  capitalAccountSummary={capitalAccountSummary}
                />
              )}
              {getCapitalAccountRepaymentOptionType(capitalAccountSummary) !== "None" && (
                <CapitalAccountCardAutoPayBankAccountItem
                  capitalAccountSummary={capitalAccountSummary}
                />
              )}
            </MetadataList>
          </div>
        )}
      </div>

      {capitalAccountSummary.state === CapitalAccountRep.State.Offered && (
        <div className={styles.footerContainer}>
          <Button variant="primary" fullWidth>
            See offer details <ArrowRight size={24} weight="thin" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default CapitalAccountCard;
