import { captureException } from "@sentry/react";
import classNames from "classnames";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import CreditComparisonSnapshotRep from "reps/CreditComparisonSnapshotRep";
import allStepsState from "state/compareCreditOffers/allSteps";
import feeState from "state/compareCreditOffers/inputs/offerInputs/fee";
import feeInputTypeState from "state/compareCreditOffers/inputs/offerInputs/feeInputType";
import loanAmountState from "state/compareCreditOffers/inputs/offerInputs/loanAmount";
import remittanceRateState from "state/compareCreditOffers/inputs/offerInputs/remittanceRate";
import weeklyCapState from "state/compareCreditOffers/inputs/offerInputs/weeklyCap";
import isOfferFormValidState from "state/compareCreditOffers/isOfferFormValid";
import CurrencyInput from "ui/inputs/CurrencyInput";
import Helper from "ui/inputs/Helper";
import TextInput from "ui/inputs/TextInput";
import Heading2 from "ui/typography/Heading2";
import { isNDecimalPlace } from "utils/numbers";
import { formatAmount } from "utils/string";
import Validator from "utils/validator";

import FromControls from "../../components/FromControls";
import PercentOrDollarTabs from "../../components/PercentOrDollarTabs";
import getCreditProviderLogo from "../../utils/getCreditProviderLogo";
import getFeeAmountByInputType from "../../utils/getFeeAmountByInputType";
import getPathFromStepIndex from "../../utils/getPathFromStepIndex";
import getStepIndexFromPathname from "../../utils/getStepIndexFromPathname";
import styles from "../Form.module.scss";

type Props = {
  offerIndex: number;
  providerName: CreditComparisonSnapshotRep.ProviderName;
};

const DefaultCreditOfferDetailsForm: React.FC<Props> = ({ offerIndex, providerName }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [loanAmount, setLoanAmount] = useRecoilState(loanAmountState(offerIndex));
  const feeInputType = useRecoilValue(feeInputTypeState(offerIndex));
  const [fee, setFee] = useRecoilState(feeState(offerIndex));
  const [weeklyCap, setWeeklyCap] = useRecoilState(weeklyCapState(offerIndex));
  const [remittanceRate, setRemittanceRate] = useRecoilState(remittanceRateState(offerIndex));
  const isOfferFormValid = useRecoilValue(isOfferFormValidState(offerIndex));

  // NB(alex): this would be a great time to use react-hook-form
  const [weeklyCapErrorMessage, setWeeklyCapErrorMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const allSteps = useRecoilValue(allStepsState);
  const { pathname } = useLocation();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (weeklyCap !== "" && Number(weeklyCap) <= 0) {
      setWeeklyCapErrorMessage("Must be greater than 0");
      return;
    }

    setIsLoading(true);

    try {
      const currentStepIndex = getStepIndexFromPathname(allSteps, pathname);
      const to = getPathFromStepIndex(allSteps, currentStepIndex + 1);
      navigate(to);
    } catch (error) {
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled = !isOfferFormValid;

  return (
    <form className={classNames(styles.form, styles.offers)} onSubmit={handleSubmit}>
      <img
        src={getCreditProviderLogo(providerName)}
        alt="counterparty icon"
        className={styles.icon}
      />

      <Heading2 className={styles.heading}>Enter {providerName} offer details</Heading2>

      <CurrencyInput
        allowDecimals={false}
        prefixValue="$"
        label="Loan amount"
        className={styles.textInput}
        value={loanAmount}
        onChange={(val) => {
          setLoanAmount(val);
        }}
        hasError={loanAmount !== "" && Number(loanAmount) === 0}
        errorMessage={"Must be greater than 0"}
        autoFocus
      />

      <div className={styles.inputWrapper}>
        {feeInputType === "Percentage" ? (
          <TextInput
            label="Fee (%)"
            type="number"
            className={styles.textInput}
            append={<PercentOrDollarTabs offerIndex={offerIndex} />}
            value={fee}
            onChange={(val) => {
              if (!Validator.positive(val) || !isNDecimalPlace(val, 2)) return;
              setFee(val);
            }}
          />
        ) : (
          <CurrencyInput
            label="Fee"
            className={styles.textInput}
            allowDecimals={false}
            prefixValue="$"
            append={<PercentOrDollarTabs offerIndex={offerIndex} />}
            value={fee}
            onChange={(val) => {
              if (!Validator.positive(val)) return;
              setFee(val);
            }}
          />
        )}
        <Helper>
          You will pay back a total of{" "}
          <strong>
            {formatAmount(
              Number(loanAmount) +
                getFeeAmountByInputType(Number(loanAmount), Number(fee), feeInputType) / 100,
              { withCents: false }
            )}
          </strong>
        </Helper>
      </div>

      <TextInput
        label="% of daily sales"
        type="number"
        className={styles.textInput}
        value={remittanceRate}
        onChange={(val) => {
          if (!Validator.positive(val) || !isNDecimalPlace(val, 2)) return;
          setRemittanceRate(val);
        }}
      />

      <CurrencyInput
        allowDecimals={false}
        prefixValue="$"
        label="Weekly cap (if applicable)"
        className={styles.textInput}
        value={weeklyCap}
        errorMessage={weeklyCapErrorMessage}
        hasError={Boolean(weeklyCapErrorMessage)}
        onChange={(value) => {
          setWeeklyCapErrorMessage(null);
          setWeeklyCap(value);
        }}
      />

      <FromControls isLoading={isLoading} isSubmitDisabled={isSubmitDisabled} />
    </form>
  );
};

export default DefaultCreditOfferDetailsForm;
