import { useRecoilValue } from "recoil";
import colors from "styles/colors";
import Avatar from "ui/data-display/Avatar";
import ModalV2 from "ui/overlay/ModalV2";
import Text from "ui/typography/Text";

import ClickToCopyInviteLink from "../ClickToCopyInviteLink";

import styles from "./InvitationDetailsModal.module.scss";
import invitationDetailsModalState, {
  useCloseInvitationDetailsModal,
} from "./state/invitationDetailsModalState";

const InvitationDetailsModal = () => {
  const state = useRecoilValue(invitationDetailsModalState);
  const closeModal = useCloseInvitationDetailsModal();

  if (!state.isOpen) {
    return null;
  }

  return (
    <ModalV2 isOpen onClose={() => closeModal()}>
      <ModalV2.Content
        element="form"
        id="show-invitation-details-modal"
        onSubmit={(e) => {
          e.preventDefault();
          closeModal();
        }}
      >
        <ModalV2.Content.Header heading={"Invitation details"} />

        <ModalV2.Content.Body>
          <div className={styles.container}>
            <Text size={16} weight="bold">
              Here’s the invite link.
            </Text>
            <div className={styles.avatar}>
              <Avatar color="purple-light" size={44} initials={state.initials} />

              <Text size={12} weight="bold">
                {state.displayName}
              </Text>
            </div>
            <Text size={14} color={colors.grey[600]}>
              Share this link with {state.displayName} and they will be able to join Highbeam and
              access your account.
            </Text>
            <ClickToCopyInviteLink slug={state.slug} />
          </div>
        </ModalV2.Content.Body>

        <ModalV2.Content.Footer>
          <ModalV2.Content.Footer.SubmitButton form="show-invitation-details-modal">
            Close
          </ModalV2.Content.Footer.SubmitButton>
        </ModalV2.Content.Footer>
      </ModalV2.Content>
    </ModalV2>
  );
};

export default InvitationDetailsModal;
