import { atom } from "recoil";
import useDialog, { DialogState } from "utils/dialog/useDialog";

const editCapitalAccountNameModalState = atom<DialogState>({
  key: "editCapitalAccountNameModalState",
  default: {
    isOpen: false,
  },
});

export const useEditCapitalAccountNameModal = () => useDialog(editCapitalAccountNameModalState);

export default editCapitalAccountNameModalState;
