import BankAccountRep from "reps/BankAccountRep";
import { sortAccounts } from "utils/account";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BankAccountApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(creation: BankAccountRep.Creation): Promise<BankAccountRep.Complete> {
    const url = `/bank-accounts`;
    return (await this.api.post<BankAccountRep.Complete>(url, creation))!;
  }

  async get(accountGuid: string): Promise<BankAccountRep.Complete | null> {
    const url = `/bank-accounts/${accountGuid}`;
    return await this.api.get<BankAccountRep.Complete>(url);
  }

  async getByBusiness(businessGuid: string): Promise<BankAccountRep.Complete[]> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/bank-accounts?${queryParams.toString()}`;
    return (await this.api.get<BankAccountRep.Complete[]>(url))!;
  }

  async getOpenByBusiness(businessGuid: string): Promise<BankAccountRep.Complete[]> {
    return (await this.getByBusiness(businessGuid))
      .filter((bankAccount) => bankAccount.status === "OPEN" || bankAccount.status === "FROZEN")
      .sort(sortAccounts);
  }

  async close(accountGuid: string): Promise<BankAccountRep.Complete> {
    const url = `/bank-accounts/${accountGuid}/close`;
    return (await this.api.post<BankAccountRep.Complete>(url))!;
  }

  async update(
    accountGuid: string,
    update: BankAccountRep.Update
  ): Promise<BankAccountRep.Complete | null> {
    const url = `/bank-accounts/${accountGuid}`;
    return (await this.api.patch<BankAccountRep.Complete>(url, update))!;
  }

  async migrateToThread(update: BankAccountRep.MigrateToThreadUpdate): Promise<void> {
    const qp = new URLSearchParams({
      ...(update.accountNamePostfix ? { accountNamePostfix: update.accountNamePostfix } : {}),
      businessGuid: update.businessGuid,
      ...(update.copyAllAccounts ? { copyAllAccounts: update.copyAllAccounts.toString() } : {}),
    });
    const url = `/bank-accounts/migrate?${qp.toString()}`;
    await this.api.post<void>(url);
    return;
  }
}
