import {
  CreateBookPaymentRequest,
  CreateInlinePaymentRequest,
  CreateVerifiedPaymentRequest,
  CreateWirePaymentRequest,
  Address as UnitAddress,
} from "@highbeam/unit-node-sdk";

import { ConversionSide } from "./ConversionSide";

// NB(alex): I think we should type this properly...
type Tags = Record<string, string>;

namespace PaymentDetailCreatorRep {
  export interface InternationalWirePayload {
    readonly bankAccountGuid: string;
    readonly description: string;
    readonly fixedSide: ConversionSide;
    readonly generalPaymentMetadataGuid: string | null;
    readonly idempotencyKey: string;
    readonly invoiceNumber: string | null;
    readonly invoiceDate: string | null;
    readonly notificationEmailAddress: string | null;
    readonly payeeGuid: string;
    readonly paymentType: "Priority" | "Regular";
    readonly purposeCode: string | null;
    readonly reason: string;
    readonly receiveAmount: number;
    readonly receiveCurrency: string;
    readonly sendAmount: number;
    readonly tags: Tags;
  }

  export interface InternationalWireCreator {
    InternationalWire: InternationalWirePayload;
  }

  export interface UnitPaymentPayload {
    readonly unitPayload: CreateInlinePaymentRequest | CreateWirePaymentRequest;
  }

  export interface UnitTransferPayload {
    readonly unitPayload:
      | CreateVerifiedPaymentRequest
      | CreateBookPaymentRequest
      | CreateInlinePaymentRequest
      | CreateWirePaymentRequest;
  }

  export interface UnitPaymentCreator {
    UnitPayment: UnitPaymentPayload;
  }

  export interface UnitTransferCreator {
    UnitTransfer: UnitTransferPayload;
  }

  export enum MoneyDirection {
    Credit = "Credit",
    Debit = "Debit",
  }

  // TODO(alex): Clean this up...
  type AddressRep = UnitAddress;

  export interface DomesticWireCreator {
    DomesticWire: {
      readonly amount: number;
      readonly accountNumber: string;
      readonly routingNumber: string;
      readonly addressRep: AddressRep;
      readonly description: string;
      readonly direction: MoneyDirection;
      readonly fromBankAccountGuid: string;
      readonly payeeName: string;
      readonly idempotencyKey: string;
      readonly tags: Tags;
    };
  }

  export interface AchCreator {
    Ach: {
      readonly amount: number;
      readonly accountNumber: string;
      readonly routingNumber: string;
      readonly payeeName: string;
      readonly description: string;
      readonly direction: MoneyDirection;
      readonly addenda: string;
      readonly fromBankAccountGuid: string;
      readonly idempotencyKey: string;
      readonly sameDay: boolean;
      readonly tags: Tags;
    };
  }

  export type Creator =
    | InternationalWireCreator
    | UnitPaymentCreator
    | UnitTransferCreator
    | AchCreator
    | DomesticWireCreator;
}

export default PaymentDetailCreatorRep;
