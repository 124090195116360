import { FC, ReactNode } from "react";

import styles from "./TransactionsV2.module.scss";

type Props = {
  dropdown?: ReactNode;
  searchBar?: ReactNode;
  dateRangePickers?: ReactNode;
  paginationInfo: ReactNode;
  filterTabs?: ReactNode;
  exportButton?: ReactNode;
};

const Filters: FC<Props> = ({
  dropdown,
  searchBar,
  dateRangePickers,
  paginationInfo,
  filterTabs,
  exportButton,
}) => (
  <div className={styles.filters}>
    {(dropdown || searchBar || dateRangePickers) && (
      <div className={styles.inputs}>
        {dropdown}
        {searchBar}
        {dateRangePickers}
      </div>
    )}
    <div className={styles.actions}>
      <div>{filterTabs}</div>
      <span className={styles.actionsRightContainer}>
        <div className={styles.paginationInfo}>{paginationInfo}</div>
        <div>{exportButton}</div>
      </span>
    </div>
  </div>
);

export default Filters;
