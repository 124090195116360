import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import BusinessRep from "reps/BusinessRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { DataRequired, LoginRequiredParam } from "utils/react-query/require-data";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const BUSINESS_QUERY_KEY = "business";

export const useBusinessQueryOptions = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  const businessGuid = useBusinessGuid(params);
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [BUSINESS_QUERY_KEY, { businessGuid }],
    queryFn: () => {
      return (businessGuid ? highbeamApi.business.get(businessGuid) : null) as DataRequired<
        BusinessRep.Complete,
        TRequired
      >;
    },
  });
};

export const useRefreshBusinessQuery = () => {
  return useRefreshQuery([BUSINESS_QUERY_KEY]);
};

export const useBusinessQuery = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  return useQuery(useBusinessQueryOptions(params));
};

const useBusiness = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  const { data } = useSuspenseQuery(useBusinessQueryOptions(params));

  return data as DataRequired<typeof data, TRequired>;
};

export default useBusiness;
