import { DialogTitle } from "@headlessui/react";
import closeIcon from "assets/close-icon.svg";
import { FC, ReactNode } from "react";
import Heading2 from "ui/typography/Heading2";

import styles from "./FlexpaneHeader.module.scss";

type Props = {
  title?: string;
  children: ReactNode;
  right?: ReactNode;
  onClose: () => void;
};

const FlexpaneHeader: FC<Props> = ({ children, right, onClose }) => (
  <DialogTitle as="div" className={styles.header}>
    <div className={styles.left}>
      <Heading2>{children}</Heading2>
    </div>
    <div className={styles.right}>
      {right}

      <button className={styles.closeButton} onClick={onClose}>
        <img src={closeIcon} alt="Close" title="Close" />
      </button>
    </div>
  </DialogTitle>
);

export default FlexpaneHeader;
