import SparklingStarLogo from "assets/sparkling-star.svg?react";
import classNames from "classnames";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "../HighbeamCard.module.scss";

const VirtualDebitCardPill = () => (
  <div className={styles.rowWithGap}>
    <div className={classNames(styles.row, styles.cardTypePill, styles.virtualDebitCardTypePill)}>
      <div className={styles.cardTypePillTextContainer}>
        <SparklingStarLogo fill={colors.purple[500]} className={styles.sparklingStarLogo} />
        <Text size={12} weight="medium" color={colors.purple[500]} align="center">
          Virtual
        </Text>
      </div>
    </div>
    <div className={classNames(styles.row, styles.cardTypePill, styles.virtualDebitCardTypePill)}>
      {
        <div className={styles.cardTypePillTextContainer}>
          <Text size={12} weight="medium" color={colors.purple[500]} align="center">
            Debit
          </Text>
        </div>
      }
    </div>
  </div>
);

export default VirtualDebitCardPill;
