import ChargeCardOfferRep from "reps/ChargeCardOfferRep";
import ChargeCardAccountRep from "reps/ChargeCardRep";
import ChargeCardRepaymentInfoRep from "reps/ChargeCardRepaymentInfoRep";
import ChargeCardRepaymentRep from "reps/ChargeCardRepaymentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ChargeCardApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getOffer(
    businessGuid: string,
    capitalAccountGuid: string
  ): Promise<ChargeCardOfferRep.Complete | null> {
    const qp = new URLSearchParams({ businessGuid, capitalAccountGuid });
    const url = `/charge-card-offers?${qp}`;
    return await this.api.get<ChargeCardOfferRep.Complete>(url);
  }

  async getRepaymentsInfo(
    businessGuid: string,
    chargeCardCreditAccountGuid: string
  ): Promise<ChargeCardRepaymentInfoRep.Complete | null> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      chargeCardAccountGuid: chargeCardCreditAccountGuid,
    });
    const url = `/charge-card-repayments/info?${qp}`;
    return await this.api.get(url);
  }

  async getTransactions(
    businessGuid: string,
    chargeCardAccountGuid: string
  ): Promise<ChargeCardRepaymentRep.Complete[]> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      chargeCardAccountGuid: chargeCardAccountGuid,
    });
    const url = `/charge-card-repayments?${qp}`;
    const transactions = await this.api.get<ChargeCardRepaymentRep.Complete[]>(url);
    return transactions || [];
  }

  async getTransactionsCsv(
    businessGuid: string,
    chargeCardAccountGuid: string
  ): Promise<string | null> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      chargeCardAccountGuid: chargeCardAccountGuid,
    });
    const url = `/charge-card-repayments?${qp}`;
    return await this.api.get<string>(url, {
      accept: "text/csv",
    });
  }

  async get(
    businessGuid: string,
    capitalAccountGuid: string
  ): Promise<ChargeCardAccountRep.Complete | null> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      capitalAccountGuid: capitalAccountGuid,
    });
    const url = `/charge-card-accounts?${qp}`;
    return await this.api.get(url);
  }

  async updateRepaymentAccount(
    businessGuid: string,
    chargeCardAccountGuid: string,
    repaymentBankAccountGuid: string
  ): Promise<ChargeCardAccountRep.Complete | null> {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/charge-card-accounts/${chargeCardAccountGuid}/repayment?${qp}`;
    return await this.api.patch(url, { repaymentBankAccountGuid });
  }
}
