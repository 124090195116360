import { useSuspenseQuery } from "@tanstack/react-query";

import usePlaidDepositoryAccountsQueryOptions from "./usePlaidDepositoryAccountsQueryOptions";

const useInactivePlaidDepositoryAccounts = () => {
  const { data: inactivePlaidDepositoryAccounts } = useSuspenseQuery({
    ...usePlaidDepositoryAccountsQueryOptions(),
    select: (plaidDepositoryAccounts) => {
      return plaidDepositoryAccounts.filter((account) => !account.isActive);
    },
  });

  return inactivePlaidDepositoryAccounts;
};

export default useInactivePlaidDepositoryAccounts;
