import classNames from "classnames";
import CopyItemTooltip from "ui/overlay/Tooltip/CopyItemTooltip/CopyItemTooltip";
import Text from "ui/typography/Text";

import styles from "./ClickToCopyInviteLink.module.scss";

type Props = {
  slug: string;
  className?: string;
};

const ClickToCopyInviteLink: React.FC<Props> = ({ slug, className }) => {
  const text = `${window.location.origin}/invitation/${slug}`;
  return (
    <CopyItemTooltip
      textToCopy={text}
      accented
      wrapperClassName={classNames(styles.clickToCopy, className)}
      tooltipContent="Copy invite link"
    >
      <Text size={14}>{text}</Text>
    </CopyItemTooltip>
  );
};

export default ClickToCopyInviteLink;
