import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useBusinessMembersQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["businessMembers", { businessGuid }],
    queryFn: () => highbeamApi.businessMember.getByBusiness(businessGuid),
  });
};

export default useBusinessMembersQueryOptions;
