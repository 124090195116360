import { ReactElement } from "react";
import RutterConnectionRep from "reps/RutterConnectionRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import colors from "styles/colors";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import { getRutterIcon } from "utils/rutter";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";

import styles from "./AccountingSoftwareCard.module.scss";

type Props = {
  description: ReactElement;
  addText: string;
  type: RutterConnectionRep.RutterAccountingPlatform;
  isAccountingPlatformConnected?: boolean;
  onButtonClick: () => void;
};

const AccountingSoftwareCard: React.FC<Props> = ({
  type,
  description,
  addText,
  onButtonClick,
  isAccountingPlatformConnected,
}) => {
  const title = getAccountingSoftwareName(type);
  const isSuperusering = useIsSuperusering();

  return (
    <Card className={styles.card} shadow="none">
      <div className={styles.cardTopSection}>
        <div className={styles.cardHeading}>
          <img src={getRutterIcon(type)} alt={`${title} logo`} />
          <Text size={14} weight="bold" color={colors.grey[800]}>
            {title}
          </Text>
        </div>
        {description}
      </div>

      <Button
        className={
          isAccountingPlatformConnected ? styles.accountingSoftwareConnected : styles.button
        }
        variant={isAccountingPlatformConnected ? "danger" : "tertiary"}
        onClick={onButtonClick}
        disabled={isAccountingPlatformConnected && !isSuperusering}
        tooltip={
          isAccountingPlatformConnected && <DisconnectAccountingSoftwareTooltip title={title} />
        }
      >
        {addText}
      </Button>
    </Card>
  );
};

const DisconnectAccountingSoftwareTooltip: React.FC<{ title: string }> = ({ title }) => (
  <div className={styles.tooltip}>
    <Text size={14}>
      If you disconnect {title}, then your line of credit will be frozen. If you want to disconnect{" "}
      {title}, please reach out to Highbeam support.
    </Text>
  </div>
);

export default AccountingSoftwareCard;
