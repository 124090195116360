import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitCoCheckDepostsQueryOptions, {
  INCOMING_CHECK_DEPOSITS_PARAMS,
} from "resources/unit-co-check-deposits/queries/useUnitCoCheckDepostsQueryOptions";
import useUnitCoPaymentsQueryOptions, {
  INCOMING_PAYMENT_PARAMS,
} from "resources/unit-co-payments/queries/useUnitCoPaymentsQueryOptions";

type Params = {
  accountId?: string;
};

const useIncomingPayments = ({ accountId }: Params = {}) => {
  const { data: unitCheckDeposits } = useSuspenseQuery(
    useUnitCoCheckDepostsQueryOptions({
      accountId,
      ...INCOMING_CHECK_DEPOSITS_PARAMS,
    })
  );

  const { data: unitPayments } = useSuspenseQuery(
    useUnitCoPaymentsQueryOptions({
      accountId,
      ...INCOMING_PAYMENT_PARAMS,
    })
  );

  const incomingPayments = [...unitCheckDeposits, ...unitPayments].sort((a, b) => {
    // -1  = a > b
    //  0  = b === b
    //  1  = a < b
    // Payments with estimatedDate should be first. Then sorted by createdAt.

    if (
      "settlementDate" in a.attributes &&
      a.attributes.settlementDate &&
      "settlementDate" in b.attributes &&
      b.attributes.settlementDate
    ) {
      if (a.attributes.settlementDate > b.attributes.settlementDate) {
        return -1;
      } else if (a.attributes.settlementDate < b.attributes.settlementDate) {
        return 1;
      }
    } else if ("settlementDate" in a.attributes && a.attributes.settlementDate) {
      return -1;
    } else if ("settlementDate" in b.attributes && b.attributes.settlementDate) {
      return 1;
    } else if (a.attributes.createdAt > b.attributes.createdAt) {
      return -1;
    } else if (a.attributes.createdAt < b.attributes.createdAt) {
      return 1;
    }
    return 0;
  });

  return incomingPayments;
};

export default useIncomingPayments;
