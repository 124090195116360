import CapitalAccountControlsRep from "./CapitalAccountControlsRep";
import CapitalAccountDetailsRep from "./CapitalAccountDetailsRep";

namespace CapitalAccountRep {
  export enum State {
    Active = "Active",
    Terminated = "Terminated",
    Offered = "Offered",
    OfferAccepted = "OfferAccepted",
  }

  export enum Type {
    CashAccessOnly = "CashAccessOnly",
    ChargeCardAndCashAccess = "ChargeCardAndCashAccess",
    ChargeCardOnly = "ChargeCardOnly",
  }

  export interface Update {
    readonly name?: string;
  }

  export interface Complete {
    readonly guid: string;
    readonly type: Type;
    readonly name: string;
    readonly businessGuid: string;
    readonly details: CapitalAccountDetailsRep.Complete;
    readonly state: State;
    readonly controls: CapitalAccountControlsRep.Complete;
    readonly activatedAt: string | null; // ZonedDateTime
  }
}

export default CapitalAccountRep;
