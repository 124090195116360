import { FC, ReactElement, ReactNode, cloneElement } from "react";
import Card from "ui/data-display/Card";
import Text from "ui/typography/Text";

import styles from "./SectionLinkCard.module.scss";

type Props = {
  icon: ReactElement<{ size?: number }>;
  title: ReactNode;
  description: ReactNode;
};

const SectionLinkCard: FC<Props> = ({ icon, title, description }) => {
  return (
    <Card className={styles.card} shadow="xs">
      <div className={styles.icon}>{cloneElement(icon, { size: 24 })}</div>
      <Text size={14} weight="bold" className={styles.title}>
        {title}
      </Text>
      <Text size={14} className={styles.description}>
        {description}
      </Text>
    </Card>
  );
};

export default SectionLinkCard;
