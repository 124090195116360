import { FileText } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import { useNavigate } from "react-router-dom";
import CapitalDrawdownApprovalRep from "reps/CapitalDrawdownApprovalRep";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import useCapitalDrawdownApprovalsQueryOptions from "resources/line-of-credit/queries/useCapitalDrawdownApprovalsQueryOptions";
import Pill from "ui/data-display/Pill";
import AmountCell from "ui/table/AmountCell";
import DateCell from "ui/table/DateCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";

const columns: Column<CapitalDrawdownApprovalRep.Complete>[] = [
  {
    title: "Submitted",
    cellRender: ({ createdAt }) => <DateCell date={createdAt} />,
  },
  {
    title: "Status",
    cellRender: () => <Pill color="orange">Pending</Pill>,
  },
  {
    title: "Invoice",
    align: TableColumnAlignment.RIGHT,
    cellRender: () => (
      <div>
        <FileText size={20} />
      </div>
    ),
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: ({ amount }) => (
      <AmountCell cents={amount} withCents={amount % 100 !== 0} direction="negative" />
    ),
  },
];

const PendingDrawdownsTable = () => {
  const { data: pendingDrawdowns = [], isLoading } = useQuery(
    useCapitalDrawdownApprovalsQueryOptions()
  );
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const navigate = useNavigate();

  const openPendingDrawdownFlexpane = (pendingDrawdownGuid: string) => {
    navigate(`/capital/${capitalAccountSummary.guid}/pending-drawdowns/${pendingDrawdownGuid}`, {
      state: DISABLE_SCROLL_TO_TOP_STATE,
    });
  };

  return (
    <Table
      columns={columns}
      isLoading={isLoading}
      isLoadingRows={3}
      data={pendingDrawdowns}
      rowKey={(drawdown) => drawdown.guid}
      onRowClick={(drawdown) => openPendingDrawdownFlexpane(drawdown.guid)}
    />
  );
};

export default PendingDrawdownsTable;
