import LoadingPage from "pages/LoadingPage";
import { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useMilestoneActionItems } from "resources/action-items/hooks/useMilestoneActionItems";
import { Milestone, GetStartedSetupGuideStep } from "resources/action-items/types";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

// NOTE: Modify allowed redirect URLs in Shopify settings if you change `supportedOrigins`.
export const supportedRedirectUrls: { [key: string]: string } = {
  capital: "/capital/application/connect-stores",
  settings: "/settings/stores",
  onboarding: "/onboarding/connect-stores",
};

const LoadingShopify = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const path = window.location.pathname;
  const { finishItemByStep } = useMilestoneActionItems(Milestone.GetStarted);
  const { segmentTrack } = useSegment();
  const [params] = useSearchParams();
  const [isCreatingConnection, setIsCreatingConnection] = useState(true);
  const createShopifyConnection = async ({
    code,
    state,
    hmac,
    shopSubdomain,
    params,
  }: {
    code: string;
    state: string;
    hmac: string;

    shopSubdomain: string;
    params: URLSearchParams;
  }) => {
    params.delete("hmac");
    params.sort();
    const hmacMessage = encodeURI(params.toString());
    const connection = await highbeamApi.shopifyConnection.create(
      code,
      businessGuid,
      hmac,
      hmacMessage,
      shopSubdomain,
      state
    );

    if (!connection) {
      notify("error", "There was an error connecting your Shopify store. Please try again.");
      setIsCreatingConnection(false);
      return;
    }

    await finishItemByStep(GetStartedSetupGuideStep.ConnectStore, "Complete");

    notify("success", "Shopify store connected");

    segmentTrack(SEGMENT_EVENTS.SHOPIFY_CONNECT_COMPLETED, {
      shopifyShopSubdomain: shopSubdomain,
    });
    setIsCreatingConnection(false);
  };

  useEffect(() => {
    if (!isCreatingConnection) return;
    const create = async () => {
      try {
        await createShopifyConnection({
          code: params.get("code")!,
          state: params.get("state")!,
          hmac: params.get("hmac")!,
          params: params,
          shopSubdomain: params.get("shop")!,
        });
      } catch (e) {
        setIsCreatingConnection(false);
      }
    };
    create();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const redirectPathKey = params.get("redirect");
  const redirectUrl = redirectPathKey ? supportedRedirectUrls[redirectPathKey] : "/settings/stores";

  return isCreatingConnection ? (
    <LoadingPage location={LoadingShopify.name} />
  ) : (
    <Navigate to={redirectUrl} />
  );
};

export default LoadingShopify;
