import { ClockCounterClockwise } from "@phosphor-icons/react";
import Helper from "ui/inputs/Helper";

import { useUpdateCounterpartyAliasModalContext } from "../../context";

const UpdateCounterpartyAliasOriginalNameHelperText = () => {
  const { counterparty, originalCounterparties } = useUpdateCounterpartyAliasModalContext();

  const originalName = originalCounterparties[0];

  if (originalCounterparties.length !== 1 || counterparty === originalName) {
    return null;
  }

  return (
    <Helper icon={<ClockCounterClockwise />} size={12}>
      Original name: <strong>{originalName}</strong>
    </Helper>
  );
};

export default UpdateCounterpartyAliasOriginalNameHelperText;
