import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFormContext } from "react-hook-form";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import { HighbeamBankAccountTransferOption } from "utils/transfers";
import { z } from "zod";

const schema = z.object({
  amount: z.number().positive(),
  // NB(alex): Once we have a composable `Dropdown`, we should use `repaymentBankAccount: z.custom<BankAccountRep.Complete>` here.
  repaymentBankAccountTransferOption: z.custom<HighbeamBankAccountTransferOption>(
    (value) => Boolean(value),
    "Please select a bank account."
  ),
});

export type RepayHighbeamCardsFormInputs = z.infer<typeof schema>;

const useRepayHighbeamCardsForm = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();

  return useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: 0 - capitalAccountSummary.cardBalance,
    },
  });
};

export default useRepayHighbeamCardsForm;

export const useRepayHighbeamCardsFormContext = () => {
  return useFormContext<RepayHighbeamCardsFormInputs>();
};
