import { AuthorizationParams } from "@auth0/auth0-spa-js";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import auth0ClientState from "state/auth/auth0Client";

type SignUpAuthorizationOptions = Omit<AuthorizationParams, "screen_hint" | "max_age">;

const useSignUpWithRedirect = () => {
  const auth0 = useRecoilValue(auth0ClientState);

  const signUpWithRedirect = useCallback(
    (signUpAuthorizationOptions: SignUpAuthorizationOptions) => {
      auth0.loginWithRedirect({
        authorizationParams: {
          /* eslint-disable camelcase */
          // Prompts the user to sign up, rather than log in (the default).
          screen_hint: "signup",
          // Prevents existing sessions from being used (essentially logs the user out).
          max_age: 0,
          /* eslint-enable camelcase */
          ...signUpAuthorizationOptions,
        },
      });
    },
    [auth0]
  );

  return signUpWithRedirect;
};

export default useSignUpWithRedirect;
