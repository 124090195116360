import { ArrowLineUpRight } from "@phosphor-icons/react";
import PaymentDetailsCard from "components/PaymentDetailsCard";
import { Dayjs } from "dayjs";
import PayeeInfo, { Recipient } from "pages/SendMoneyPage/SendMoneySteps/PayeeInfo";
import {
  BankingInfo,
  PaymentMethod,
  getSendMoneyDeliveryMessage,
  InternationalBankingInfo,
  PaymentMethodOption,
} from "pages/SendMoneyPage/utils";
import InfoBox from "ui/data-display/InfoBox";
import Button from "ui/inputs/Button";
import { StepType } from "ui/navigation/Steps";
import StepHeader from "ui/navigation/Steps/StepHeader";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import { TransferOption } from "utils/transfers";

import styles from "./SendMoneyConfirmDetails.module.scss";

type SendMoneyConfirmation = {
  from: TransferOption;
  amountInCents: number;
  to: Recipient;
  scheduledDate: Dayjs;
  scheduledPayment: boolean;
  bankingInfo?: BankingInfo;
  internationalBankingInfo?: InternationalBankingInfo;
  paymentMethodOption: PaymentMethodOption;
  invoiceName?: string;
};

type Props = {
  sendMoneyConfirmation: SendMoneyConfirmation;
  onNextPress: () => void;
  onPrevPress: () => void;
  isTransferLoading: boolean;
  step: StepType;
};

const SendMoneyConfirmDetails: React.FC<Props> = ({
  sendMoneyConfirmation,
  onNextPress,
  onPrevPress,
  isTransferLoading,
  step: stepType,
}) => {
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();

  const onTransferButtonClick = () => {
    onNextPress();
  };

  return (
    <>
      <StepHeader
        handleBackButtonClick={onPrevPress}
        disableBackButton={isTransferLoading}
        stepNumber={stepType.number}
        disableForwardButton
        title={stepType.title}
      />

      <PaymentDetailsCard
        paymentMethod={sendMoneyConfirmation.paymentMethodOption.value}
        iconBesideAmount={<ArrowLineUpRight size={16} />}
        amountInCents={sendMoneyConfirmation.amountInCents}
        from={sendMoneyConfirmation.from.label}
        to={
          <PayeeInfo
            to={sendMoneyConfirmation.to}
            infoText="The recipient will receive a confirmation email for this payment."
          />
        }
        scheduledDate={sendMoneyConfirmation.scheduledDate}
        routingNumber={sendMoneyConfirmation.bankingInfo?.routingNumber}
        accountNumber={
          sendMoneyConfirmation.paymentMethodOption?.value === PaymentMethod.INTERNATIONAL
            ? sendMoneyConfirmation.internationalBankingInfo?.accountNumber?.value
            : sendMoneyConfirmation.bankingInfo?.accountNumber
        }
        iban={
          sendMoneyConfirmation.paymentMethodOption?.value === PaymentMethod.INTERNATIONAL
            ? sendMoneyConfirmation.internationalBankingInfo?.iban?.value
            : undefined
        }
        swift={
          sendMoneyConfirmation.paymentMethodOption?.value === PaymentMethod.INTERNATIONAL
            ? sendMoneyConfirmation.internationalBankingInfo?.swift?.value
            : undefined
        }
        deliveryMessage={getSendMoneyDeliveryMessage(
          sendMoneyConfirmation.paymentMethodOption?.value as PaymentMethod,
          sendMoneyConfirmation.scheduledPayment
        )}
        invoiceName={sendMoneyConfirmation.invoiceName}
      />

      <div className={styles.buttons}>
        <Button onClick={onPrevPress} variant="tertiary" disabled={isTransferLoading}>
          Back
        </Button>
        <Button
          variant="primary"
          onClick={onTransferButtonClick}
          isLoading={isTransferLoading}
          autoFocus
        >
          {sendMoneyConfirmation
            ? sendMoneyConfirmation.scheduledPayment
              ? "Schedule"
              : isAllowedToApprovePayments
                ? "Send"
                : "Submit for approval"
            : "Transfer"}
        </Button>
      </div>

      {isAllowedToApprovePayments && (
        <div className={styles.info}>
          <InfoBox
            variant="info"
            iconSize={24}
            text="Transactions made after 3:00 PM ET or on a weekend or holiday will be processed the next business day."
          />
        </div>
      )}
    </>
  );
};
export default SendMoneyConfirmDetails;
