import MetadataList from "components/MetadataList";
import Section from "ui/data-display/Section";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import { HighbeamDishonoredAchTransaction } from "utils/types/transactionsTypes";

import TraceNumberLabel from "./TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";

type Props = {
  transaction: HighbeamDishonoredAchTransaction;
};

const DishonoredAchTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>Returned ACH details</Heading3>
    <MetadataList>
      <MetadataList.Item label="Description">
        <Text size={14} weight="medium">
          {transaction.description}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item label="Routing number">
        <Text size={14} weight="medium">
          {transaction.counterpartyRoutingNumber}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item label={<TraceNumberLabel />}>
        <Text size={14} weight="medium">
          {transaction.traceNumber}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item label="Reason">
        <Text size={14} weight="medium">
          {transaction.reason}
        </Text>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default DishonoredAchTransactionDetailsSection;
