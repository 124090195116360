import { DialogTitle } from "@headlessui/react";
import { X } from "@phosphor-icons/react";
import { isValidElement } from "react";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./Header.module.scss";

export const CloseModalButton: React.FC<React.ComponentProps<typeof Button>> = ({
  ...buttonProps
}) => (
  <Button variant="tertiary" size="sm" isSquare {...buttonProps}>
    <X />
  </Button>
);

export const Heading: React.FC<React.ComponentProps<typeof Text>> = ({ ...textProps }) => (
  <Text size={18} weight={"bold"} {...textProps} />
);

type Props = {
  icon?: React.ReactNode;
  heading: React.ReactNode;
  actions?: React.ReactNode;
  onClickClose?: () => void;
  children?: React.ReactNode;
};

/**
 * Optionally composable modal header.
 * @param icon - Icon to display in the header.
 * @param heading - Modal title. Uses `Heading` component by default, but replaces the component if `heading` is a React element.
 * @param actions - Actions, typically buttons, to display in the header.
 * @param onClickClose - Close modal button event. Displays the default close modal button if defined.
 */
const Header: React.FC<Props> = ({ icon, heading, children, actions, onClickClose }) => (
  <DialogTitle as="div" className={styles.header}>
    {children ? (
      children
    ) : (
      <div className={styles.row}>
        {icon}
        {isValidElement(heading) ? heading : <Heading>{heading}</Heading>}
      </div>
    )}
    {actions}
    {onClickClose && <CloseModalButton onClick={onClickClose} />}
  </DialogTitle>
);

export default Header;
