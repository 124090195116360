import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useUserRolesQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["userRoles", { businessGuid }],
    queryFn: () => highbeamApi.userRole.getByBusiness(businessGuid),
  });
};

export default useUserRolesQueryOptions;
