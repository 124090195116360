import highbeamIcon from "assets/highbeam-icon.svg";
import classNames from "classnames";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./BankAccount.module.scss";

type Props = {
  accountName: string;
  bankIcon?: React.ReactNode;
  shortMethodName?: string;
  className?: string;
  children?: React.ReactNode;
  size?: 14 | 18;
  isDisabled?: boolean;
};

const BankAccount: React.FC<Props> = ({
  accountName,
  bankIcon = <img src={highbeamIcon} alt="" className={styles.icon} />, // TODO(alex): make a `<BankIcon />` component
  shortMethodName,
  className,
  size = 14,
  isDisabled,
}) => (
  <div className={styles.container}>
    {bankIcon}
    <Text
      size={size}
      weight="medium"
      color={isDisabled ? colors.grey[600] : colors.grey[900]}
      className={classNames(styles.text, className)}
    >
      {accountName}
    </Text>
    {shortMethodName && (
      <Text size={size} color={colors.grey[500]}>
        via {shortMethodName}
      </Text>
    )}
  </div>
);

export default BankAccount;
