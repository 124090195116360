import { MapPin } from "@phosphor-icons/react";
import { useState } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import useUpdateBusinessAddressMutation from "resources/business-details/hooks/useUpdateBusinessAddressMutation";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import ModalV3 from "ui/overlay/ModalV3";

import { useUpdateBusinessAddressModal } from "../state/UpdateBusinessAddressModalState";
import styles from "../UpsellCapitalView.module.scss";

import ConfirmAddressModalContent from "./ConfirmAddressModalContent";
import UpdateAddressModalContent from "./UpdateAddressModalContent";
import useUpdateBusinessAddressForm, {
  UpdateBusinessAddressFormInputs,
} from "./useUpdateBusinessAddressForm";

const UPDATE_BUSINESS_ADDRESS_FORM_ID = "business-address-form";

const UpdateBusinessAddressForm = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { close: onClose } = useUpdateBusinessAddressModal();
  const { formState, handleSubmit, trigger } = useFormContext<UpdateBusinessAddressFormInputs>();

  const { mutate: updateBusinessAddress, isPending } = useUpdateBusinessAddressMutation();

  const onSubmit = handleSubmit((data) => {
    updateBusinessAddress({
      line1: data.addressLine1,
      line2: data.addressLine2 || null,
      city: data.city,
      state: data.state,
      postalCode: data.zipCode,
      country: data.country,
    });
  });

  const handleNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      setShowConfirmation(true);
    }
  };

  return (
    <form
      id={UPDATE_BUSINESS_ADDRESS_FORM_ID}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <ModalV3.Header onClose={onClose}>
        <div className={styles.modalHeader}>
          <div className={styles.modalIcon}>
            <MapPin size={24} color={colors.purple[500]} />
          </div>
          Add a physical address
        </div>
      </ModalV3.Header>

      <ModalV3.Body className={styles.modalBody}>
        {showConfirmation ? <ConfirmAddressModalContent /> : <UpdateAddressModalContent />}
      </ModalV3.Body>

      <ModalV3.Footer>
        {showConfirmation ? (
          <ModalV3.Footer.SubmitButton
            isLoading={isPending}
            disabled={!formState.isValid}
            form={UPDATE_BUSINESS_ADDRESS_FORM_ID}
          >
            Change address
          </ModalV3.Footer.SubmitButton>
        ) : (
          <Button onClick={handleNext} variant="primary">
            Next
          </Button>
        )}
        <ModalV3.Footer.CloseButton onClick={onClose}>Cancel</ModalV3.Footer.CloseButton>
      </ModalV3.Footer>
    </form>
  );
};

const WrappedUpdateBusinessAddressForm = () => {
  const form = useUpdateBusinessAddressForm();

  return (
    <FormProvider {...form}>
      <UpdateBusinessAddressForm />
    </FormProvider>
  );
};

export default WrappedUpdateBusinessAddressForm;
