import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { bankingDay } from "utils/date";

const useUpdateLineOfCreditUserActionsMetadataMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const capitalAccountSummary = useCapitalAccountSummaryContext();

  return useMutation({
    mutationFn: () => {
      return highbeamApi.lineOfCredit.updateLineOfCreditUserActionsMetadata(
        businessGuid,
        capitalAccountSummary.guid,
        {
          unsignedAgreementOpenedAt: bankingDay().toISOString(),
        }
      );
    },
    onError: (err) => {
      captureException(err);
      notify("error", "Failed to update user actions. Please try again.");
    },
  });
};

export default useUpdateLineOfCreditUserActionsMetadataMutation;
