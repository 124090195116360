import { Trash, Pencil, ArrowLineUpRight } from "@phosphor-icons/react";
import DashboardPage from "components/layouts/DashboardPage";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import usePayee from "resources/payees/queries/usePayee";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import useIsAllowedToDeletePayees from "utils/permissions/useIsAllowedToDeletePayees";
import useIsAllowedToEditPayees from "utils/permissions/useIsAllowedToEditPayees";
import useIsAllowedToViewSendMoney from "utils/permissions/useIsAllowedToViewSendMoney";

import RemovePayeeModal from "./RemovePayeeModal";

const PayeeDetailsHeader = () => {
  const { payeeGuid } = useParams();
  const payee = usePayee(payeeGuid!, { required: true });

  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isAllowedToViewSendMoney = useIsAllowedToViewSendMoney();
  const isAllowedToEditPayees = useIsAllowedToEditPayees();
  const isAllowedToDeletePayees = useIsAllowedToDeletePayees();
  const [removePayeeModalIsOpen, setRemovePayeeModalIsOpen] = useState(false);

  return (
    <DashboardPage.Header
      actions={
        <>
          {removePayeeModalIsOpen && (
            <RemovePayeeModal payee={payee} onClose={() => setRemovePayeeModalIsOpen(false)} />
          )}
          {isAllowedToViewSendMoney && (
            <Link to="/send-money" state={{ payeeGuid: payee.guid }}>
              <Button variant="secondary">
                <ArrowLineUpRight />
                {isAllowedToApprovePayments ? "Send money" : "Draft a payment"}
              </Button>
            </Link>
          )}

          <Link to={`/payments/payees/${payee.guid}/edit`} state={{ payeeGuid: payee.guid }}>
            <Button
              variant="tertiary"
              disabled={!isAllowedToEditPayees}
              tooltip={
                !isAllowedToEditPayees && (
                  <Text size={14}>You don’t have permission to edit payees.</Text>
                )
              }
            >
              <Pencil />
              Edit
            </Button>
          </Link>

          <Button
            disabled={!isAllowedToDeletePayees}
            variant="danger"
            onClick={() => setRemovePayeeModalIsOpen(true)}
            tooltip={
              !isAllowedToDeletePayees && (
                <Text size={14}>You don’t have permission to delete payees.</Text>
              )
            }
          >
            <Trash />
            Remove
          </Button>
        </>
      }
    >
      <DashboardPage.Header.BackCaret to="/payments/payees" />
      <DashboardPage.Header.Title>{payee.name}</DashboardPage.Header.Title>
    </DashboardPage.Header>
  );
};

export default PayeeDetailsHeader;
