import classNames from "classnames";
import { FC, ReactElement } from "react";
import { Falsy } from "utils/array";

import styles from "./BarChart.module.scss";

type BarChartBarProps = {
  color: "green" | "green-disabled" | "purple" | "yellow";
  widthPercentage: number;
  className?: string;

  /**
   * Minimum bar width, if the percentage is barely greater than 0. Useful for ensuring that the bar is still visible.
   */
  minWidthPercentageIfNotZero?: number;
};

const BarChartBar: FC<BarChartBarProps> = ({
  className,
  color,
  widthPercentage,
  minWidthPercentageIfNotZero = 1,
}) => {
  const widthStylePercentage =
    widthPercentage === 0 ? 0 : Math.max(minWidthPercentageIfNotZero, widthPercentage);

  return (
    <div
      className={classNames(className, styles.bar, styles[color])}
      style={{ width: `${widthStylePercentage}%`, height: "100%" }}
    />
  );
};

type BarChartProps = {
  size: 8 | 10;
  backgroundVariant?: "solid" | "striped";
  children: ReactElement<typeof BarChartBar> | Falsy | ReactElement<typeof BarChartBar | Falsy>[];
  className?: string;
};

const BarChart: FC<BarChartProps> = ({
  className,
  size,
  backgroundVariant = "solid",
  children,
}) => {
  return (
    <div
      className={classNames(
        className,
        backgroundVariant === "striped" && styles.striped,
        styles[`size-${size}`],
        styles.barChartContainer
      )}
    >
      {children}
    </div>
  );
};

export default Object.assign(BarChart, {
  Bar: BarChartBar,
});
