import { Minus } from "@phosphor-icons/react";
import TransactionFlexpaneDetails from "components/common/transaction-flexpane/TransactionFlexpaneDetails";
import useCardAuthorization from "dialogs/CardAuthorizationFlexpane/hooks/useCardAuthorization";
import useCard from "resources/cards/queries/useCard";
import { isBusinessCard } from "utils/cards";

const CardAuthorizationDetails = () => {
  const authorization = useCardAuthorization();
  const card = useCard(authorization.relationships.card.data.id, { required: true });

  // TODO(alex): Double-check to make sure this is static for all card authorizations
  const longMethodName = "Card transaction";

  return (
    <TransactionFlexpaneDetails>
      <TransactionFlexpaneDetails.Heading>
        {longMethodName} details
      </TransactionFlexpaneDetails.Heading>

      <TransactionFlexpaneDetails.MetadataList>
        <TransactionFlexpaneDetails.MetadataList.Item label="Card">
          {card.attributes.tags.name}
        </TransactionFlexpaneDetails.MetadataList.Item>

        {isBusinessCard(card) && (
          <TransactionFlexpaneDetails.MetadataList.Item label="Cardholder’s name">
            {card.attributes.fullName.first} {card.attributes.fullName.last}
          </TransactionFlexpaneDetails.MetadataList.Item>
        )}

        <TransactionFlexpaneDetails.MetadataList.Item label="Description">
          {authorization.attributes.merchant.name}
        </TransactionFlexpaneDetails.MetadataList.Item>

        {authorization.attributes.status === "Declined" && (
          <TransactionFlexpaneDetails.MetadataList.Item label="Decline reason">
            {authorization.attributes.declineReason || <Minus size={16} />}
          </TransactionFlexpaneDetails.MetadataList.Item>
        )}
      </TransactionFlexpaneDetails.MetadataList>
    </TransactionFlexpaneDetails>
  );
};

export default CardAuthorizationDetails;
