import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { downloadString } from "utils/download";

const useDownloadChargeCardTransactionsCsvMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useMutation({
    mutationFn: async (chargeCardGuid: string) => {
      const csvText = await highbeamApi.chargeCard.getTransactionsCsv(businessGuid, chargeCardGuid);

      if (!csvText) {
        throw new Error("Highbeam card repayments CSV text is null");
      }

      const fileName = `highbeam-card-repayments.csv`;
      downloadString(fileName, csvText);
    },
    onError: (err) => {
      captureException(err);
      notify("info", "Something went wrong while exporting transactions. Please try again.");
    },
  });
};

export default useDownloadChargeCardTransactionsCsvMutation;
