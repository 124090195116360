import { Dayjs } from "dayjs";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const LINE_OF_CREDIT_INTEREST_FEE_QUERY_KEY = "lineOfCreditInterestFee";

type Params = {
  lineOfCreditGuid: string;
  since: Dayjs;
  untilInclusive: Dayjs;
};

const useLineOfCreditInterestFeeQueryOptions = ({
  lineOfCreditGuid,
  since,
  untilInclusive,
}: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const sinceString = since.format("YYYY-MM-DD");
  const untilInclusiveString = untilInclusive.format("YYYY-MM-DD");

  return useQueryOptions({
    queryKey: [
      LINE_OF_CREDIT_INTEREST_FEE_QUERY_KEY,
      {
        businessGuid,
        lineOfCreditGuid,
        since: sinceString,
        untilInclusive: untilInclusiveString,
      },
    ],
    queryFn: () =>
      highbeamApi.lineOfCreditInterestFee.getAccruedInterestFees(
        businessGuid,
        lineOfCreditGuid,
        sinceString,
        untilInclusiveString
      ),
  });
};

export default useLineOfCreditInterestFeeQueryOptions;
