import classNames from "classnames";
import { forwardRef } from "react";

import styles from "./Radio.module.scss";

const Radio = forwardRef<HTMLInputElement, JSX.IntrinsicElements["input"]>(
  ({ className, ...inputProps }, ref) => (
    <input type="radio" className={classNames(className, styles.radio)} ref={ref} {...inputProps} />
  )
);

export default Radio;
