import { CalendarBlank } from "@phosphor-icons/react";
import classNames from "classnames";
import MultiStep from "components/layouts/MultiStep";
import dayjs from "dayjs";
import RepaymentBankAccountDropdown from "pages/capital/CapitalRepayLineOfCreditPage/components/RepaymentBankAccountDropdown";
import { useRef, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import useChargeCardAccount from "resources/charge-cards/queries/useChargeCardAccount";
import useChargeCardRepaymentInfo from "resources/charge-cards/queries/useChargeCardRepaymentInfo";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Pill from "ui/data-display/Pill";
import BarChart from "ui/data-visualization/BarChart";
import CurrencyInput from "ui/inputs/CurrencyInputV2";
import Helper from "ui/inputs/Helper";
import RadioCardSelect from "ui/inputs/RadioCardSelect";
import RadioCardSimple from "ui/inputs/RadioCardSimple";
import Text from "ui/typography/Text";
import { formatAmount } from "utils/string";

import useRepayHighbeamCardsForm, {
  useRepayHighbeamCardsFormContext,
} from "../../hooks/useRepayHighbeamCardsForm";

import styles from "./RepayHighbeamCardsAmountView.module.scss";

const REPAYMENT_OPTIONS = ["current-balance", "cycle-balance", "custom"] as const;

type RepaymentOption = (typeof REPAYMENT_OPTIONS)[number];

const REPAY_HIGHBEAM_CARDS_FORM_ID = "repay-highbeam-cards-form";

const RepayHighbeamCardsAmountForm = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const chargeCardAccount = useChargeCardAccount(capitalAccountSummary.guid, { required: true });
  const { remainingAmountDue, nextRepaymentDueDate } = useChargeCardRepaymentInfo(
    chargeCardAccount.guid,
    {
      required: true,
    }
  );

  const navigate = useNavigate();
  const { control, handleSubmit, watch } = useRepayHighbeamCardsFormContext();
  const amountInputRef = useRef<HTMLInputElement>(null);

  const amountInputValue = watch("amount");
  const repaymentBankAccountTransferOptionValue = watch("repaymentBankAccountTransferOption");

  // TODO(alex): be sure to set default to updated value if they go back
  const defaultRepaymentSelectValue = REPAYMENT_OPTIONS[0];
  const [repaymentOptionValue, setRepaymentOptionValue] = useState<RepaymentOption>(
    defaultRepaymentSelectValue
  );

  const onSubmit = handleSubmit((data) => {
    navigate(`/capital/${capitalAccountSummary.guid}/cards/repay/confirm`, {
      state: {
        amount: data.amount,
        repaymentBankAccountGuid: data.repaymentBankAccountTransferOption.guid,
      },
    });
  });

  const amountOwed = 0 - capitalAccountSummary.cardBalance;
  const maximumAmountAvailableAfterRepayment = capitalAccountSummary.available + amountOwed;
  const availableAfterRepayment = Math.min(
    maximumAmountAvailableAfterRepayment, // Ensures bar chart doesn't show a value higher than the maximum value during custom input
    capitalAccountSummary.available + amountInputValue
  );
  const availableAfterRepaymentPercentage =
    (availableAfterRepayment / capitalAccountSummary.details.limit) * 100;

  const repaymentBankAccountAvailableBalance =
    repaymentBankAccountTransferOptionValue?.availableBalanceInCents; // NB(alex): `?.` is necessary because the default value might not be populated on mount. This hack feels wrong to me, open to suggestions for a better pattern.
  const repaymentBankAccountBalanceAfterRepayment =
    repaymentBankAccountAvailableBalance - amountInputValue;
  const insufficientFunds = repaymentBankAccountBalanceAfterRepayment < 0;

  return (
    <MultiStep.Form id={REPAY_HIGHBEAM_CARDS_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            How much do you want to repay?
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <Controller
          control={control}
          name="amount"
          render={({ field }) => {
            return (
              <RadioCardSelect
                options={
                  remainingAmountDue === 0
                    ? (["current-balance", "custom"] as const)
                    : REPAYMENT_OPTIONS
                }
                value={repaymentOptionValue}
                onChange={setRepaymentOptionValue}
                rowGap={8}
              >
                {({ option, isSelected, onSelect }) => {
                  switch (option) {
                    case "current-balance":
                      return (
                        <RadioCardSimple
                          key={option}
                          value={option}
                          checked={isSelected}
                          onChange={(value) => {
                            onSelect(value);
                            field.onChange(0 - capitalAccountSummary.cardBalance);
                          }}
                          label={
                            <MoneyAmount
                              cents={0 - capitalAccountSummary.cardBalance}
                              weight="medium"
                            />
                          }
                          helper="Settled balance"
                        />
                      );
                    case "cycle-balance":
                      return (
                        <RadioCardSimple
                          key={option}
                          value={option}
                          checked={isSelected}
                          onChange={(value) => {
                            onSelect(value);
                            field.onChange(remainingAmountDue);
                          }}
                          label={<MoneyAmount cents={remainingAmountDue} weight="medium" />}
                          helper={
                            <Pill color="yellow">
                              <CalendarBlank /> Due {dayjs(nextRepaymentDueDate).format("MMM D")}
                            </Pill>
                          }
                        />
                      );
                    case "custom":
                      return (
                        <RadioCardSimple
                          key={option}
                          value={option}
                          checked={isSelected}
                          onChange={(value) => {
                            onSelect(value);

                            // Focus & select the amount input when the "custom" option is selected
                            // NB(alex): I wasn't able to figure out how to unset the field so I opted to select & highlight the field instead
                            amountInputRef.current?.focus();
                            setTimeout(() => amountInputRef.current?.select(), 0);
                          }}
                          label={"Custom amount"}
                        />
                      );
                  }
                }}
              </RadioCardSelect>
            );
          }}
        />

        <Controller
          control={control}
          name="amount"
          render={({ field }) => {
            return (
              <CurrencyInput
                inputRef={amountInputRef}
                prefixValue="$"
                label="Amount"
                className={classNames(
                  repaymentOptionValue !== "custom" && styles.hideField,
                  styles.amountInput
                )}
                {...field}
              />
            );
          }}
        />

        {insufficientFunds && (
          <Helper icon={<Helper.Icon variant="error" />}>
            The amount you entered is greater than your account balance of{" "}
            {formatAmount(repaymentBankAccountTransferOptionValue.availableBalanceInDollars, {
              withCents: true,
            })}
            .
          </Helper>
        )}

        {amountInputValue > amountOwed && (
          <Helper icon={<Helper.Icon variant="error" />}>
            The amount you entered is greater than the amount you owe.
          </Helper>
        )}
      </MultiStep.Section>

      <MultiStep.Section className={styles.repaymentBankAccountDropdownContainer}>
        <RepaymentBankAccountDropdown control={control} />
      </MultiStep.Section>

      <Divider margin={32} />

      <MultiStep.Section>
        <Text color={colors.grey[900]} size={14} weight="medium">
          Capital available after repayment
        </Text>

        <BarChart size={8} className={styles.barChart}>
          <BarChart.Bar color="green" widthPercentage={availableAfterRepaymentPercentage} />
        </BarChart>

        <Text size={14} weight="medium">
          <MoneyAmount size={14} cents={availableAfterRepayment} />
          <Text as="span" color={colors.grey[500]}>
            {" / "}
          </Text>
          <MoneyAmount
            size={14}
            color={colors.grey[500]}
            cents={capitalAccountSummary.details.limit}
          />
        </Text>
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.NextButton
          form={REPAY_HIGHBEAM_CARDS_FORM_ID}
          disabled={insufficientFunds || amountInputValue > amountOwed}
        >
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

const RepayHighbeamCardsAmountView = () => {
  const form = useRepayHighbeamCardsForm();

  return (
    <FormProvider {...form}>
      <RepayHighbeamCardsAmountForm />
    </FormProvider>
  );
};

export default RepayHighbeamCardsAmountView;
