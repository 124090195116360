import { useMutation } from "@tanstack/react-query";
import BusinessMemberRep from "reps/BusinessMemberRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useBusinessMemberQueryOptions from "../queries/useBusinessMemberQueryOptions";

type Variables = BusinessMemberRep.Updater & {
  readonly memberGuid: string;
};

const useUpdateBusinessMemberMutation = () => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const userGuid = useUserGuid();
  const refreshBusinessMemberQuery = useRefreshQuery(
    useBusinessMemberQueryOptions(userGuid).queryKey
  );

  return useMutation({
    mutationFn: ({ memberGuid, ...variables }: Variables) => {
      return highbeamApi.businessMember.update(businessGuid, memberGuid, variables);
    },
    onSuccess: async () => {
      await refreshBusinessMemberQuery();
    },
  });
};

export default useUpdateBusinessMemberMutation;
