import logoWithoutLabel from "assets/highbeam-logo-without-label.svg";
import TotalYieldInfoTooltipContent from "components/Accounts/InterestTier/TotalYieldEarnedTooltipContent";
import IconWithTooltip from "components/common/IconWithTooltip";
import useBankAccountBalanceHistory from "modules/balance-history/queries/useBankAccountsBalanceHistory";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useBankAccountInterestYieldYtd from "resources/bank-accounts/queries/useBankAccountInterestYieldYtd";
import colors from "styles/colors";
import CashDisplay from "ui/data-display/money/CashDisplay";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import DetailedAreaChart, { DetailedAreaChartDatum } from "ui/data-visualization/DetailedAreaChart";
import Button from "ui/inputs/Button";
import Heading2 from "ui/typography/Heading2";
import Text from "ui/typography/Text";
import { useIsMobile } from "utils/device/useMediaQuery";

import styles from "./HomeBalance.module.scss";

const DAYS_BETWEEN_TICKS = 10;

const FundedAccount = () => {
  const [activeTick, setActiveTick] = useState<DetailedAreaChartDatum | null>(null);
  const accountBalanceHistory = useBankAccountBalanceHistory();
  const isMobile = useIsMobile();
  const [isAnimationActive, setIsAnimationActive] = useState(true);

  const interestYtdCents = useBankAccountInterestYieldYtd();
  const showInterestYtd = interestYtdCents > 0;

  // Homegraph redrew itself when the timerange was adjusted from the dropdown in the homepage.
  // Using Profiler and WDYR (https://github.com/welldone-software/why-did-you-render), screenshot below,
  // we identified that it was happening cause of issues with the Animate component used by recharts
  // https://arc.net/e/79186D2C-087A-407E-9ECF-61E3BE4AAA95
  // This should be fixed later but for now this hack seems to work.
  useEffect(() => {
    const animationTimer = setTimeout(() => {
      setIsAnimationActive(false);
    }, 1500);
    return () => clearTimeout(animationTimer);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles["balance-container"]}>
          <Heading2>
            <img className={styles.logo} src={logoWithoutLabel} alt="Highbeam logo" />
            Highbeam balance
          </Heading2>
          <div className={styles["balance-container__balance"]}>
            <CashDisplay
              color={activeTick ? colors.purple[500] : colors.black}
              cents={
                activeTick
                  ? activeTick.amount
                  : accountBalanceHistory[accountBalanceHistory.length - 1]?.amount || 0
              }
            />
            <Text size={12} className={styles.text}>
              as of {activeTick ? activeTick.date : "today"}
            </Text>
          </div>

          {showInterestYtd && (
            <div className={styles.totalYieldContainer}>
              <div className={styles.totalYieldContainerHeader}>
                <Text size={14} weight="medium">
                  Total yield (YTD)
                </Text>
                <IconWithTooltip content={<TotalYieldInfoTooltipContent />} />
              </div>

              <MoneyAmount
                color={"green"}
                cents={interestYtdCents}
                size={18}
                centsTextSize={14}
                weight="medium"
              />
            </div>
          )}

          <Link to="/accounts/transactions">
            <Button className={styles["balance-container__button"]} variant="tertiary" size="sm">
              See transactions
            </Button>
          </Link>
        </div>

        <div className={styles["chart-container"]}>
          {
            <DetailedAreaChart
              data={accountBalanceHistory}
              ticks={accountBalanceHistory
                .map((datum) => datum.date)
                .filter(
                  (_, i) => (accountBalanceHistory.length - i - 1) % DAYS_BETWEEN_TICKS === 0
                )}
              setActiveTick={setActiveTick}
              chartHeight={isMobile ? 180 : 350}
              isAnimationActive={isAnimationActive}
            />
          }
        </div>
      </div>
    </>
  );
};

export default FundedAccount;
