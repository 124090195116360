import colors from "styles/colors";
import Avatar from "ui/data-display/Avatar";
import Text from "ui/typography/Text";

import styles from "./AvatarCard.module.scss";

type Props = {
  initials: string;
  fullName: string;
  userRoleName?: string;
};

const AvatarCard: React.FC<Props> = ({ initials, fullName, userRoleName }) => {
  return (
    <div className={styles.container}>
      <Avatar color="purple-light" size={44} initials={initials} />

      <Text size={14} weight="medium">
        {fullName}
      </Text>

      {userRoleName && (
        <div className={styles.badge}>
          <Text color={colors.grey[500]} size={10}>
            {userRoleName}
          </Text>
        </div>
      )}
    </div>
  );
};

export default AvatarCard;
