import classNames from "classnames";
import { FC, ReactNode } from "react";
import colors from "styles/colors";
import Text, { TextProps } from "ui/typography/Text";

import useModalContext, { ModalContextValue } from "../context/useModalContext";

import styles from "./ModalBody.module.scss";

type ModalBodyTextProps = TextProps;

export const ModalBodyText: FC<ModalBodyTextProps> = (props) => {
  return <Text size={16} color={colors.grey[600]} {...props} />;
};

type ModalBodyProps = JSX.IntrinsicElements["div"] & {
  children: ReactNode | ((renderProps: ModalContextValue) => ReactNode);
};

const ModalBody: FC<ModalBodyProps> = ({ children, className, ...divProps }) => {
  const modalContext = useModalContext();

  return (
    <div className={classNames(className, styles.body)} {...divProps}>
      {typeof children === "function" ? children(modalContext) : children}
    </div>
  );
};

export default Object.assign(ModalBody, {
  Text: ModalBodyText,
});
