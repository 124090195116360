import { useRef } from "react";
import { useRecoilValue } from "recoil";
import isCreateCardModalOpenState from "state/card/isCreateCardModalOpen";
import { createCardModalTypeState } from "state/cardCreation/createCardModalType";
import ModalV2, { ModalProps, ModalRefValue } from "ui/overlay/ModalV2";

import CreateCardModalContent from "./CreateCardModalContent";
import { stepInfoMap, useCreateCardModal } from "./utils";

type Props = {
  defaultCardName?: string;
  handleClose: (reload: boolean) => void;
} & Omit<ModalProps, "onClose" | "isOpen">;

const CreateCardModal: React.FC<Props> = ({ defaultCardName, handleClose }) => {
  const createCardModalType = useRecoilValue(createCardModalTypeState);
  const isCreateCardModalOpen = useRecoilValue(isCreateCardModalOpenState);

  const createCardModalProps = useCreateCardModal(
    createCardModalType,
    defaultCardName,
    handleClose
  );

  const modalRef = useRef<ModalRefValue>(null);
  const closeModal = () => modalRef.current?.close();

  return (
    <ModalV2
      isOpen={isCreateCardModalOpen}
      onClose={() => handleClose(stepInfoMap[createCardModalProps.step].reloadPage)}
      modalRef={modalRef}
    >
      <CreateCardModalContent onClickClose={closeModal} {...createCardModalProps} />
    </ModalV2>
  );
};

export default CreateCardModal;
