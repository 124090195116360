import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import ActivateThreadBanner from "modules/migrate-to-thread/ActivateThreadBanner";
import ActivateThreadView from "modules/migrate-to-thread/ActivateThreadView";
import useForceMigrateToThreadView from "modules/migrate-to-thread/ActivateThreadView/hooks/useForceMigrateToThreadView";
import useShouldShowActivateThreadView from "modules/migrate-to-thread/ActivateThreadView/hooks/useShouldShowActivateThreadView";
import {
  DEFAULT_ACTIVE_TAB,
  usePayoutInstructionsModal,
} from "pages/PayoutInstructionModal/state/payoutInstructionsModalState";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ActionItemSteps, GetStartedSetupGuideStep } from "resources/action-items/types";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import AccountsOverviewBalanceSection from "./sections/AccountsOverviewBalanceSection";
import AccountsOverviewPageHeader from "./sections/AccountsOverviewPageHeader";
import BankAccountsSection from "./sections/BankAccountsSection";
import CapitalAccountsSection from "./sections/CapitalAccountsSection";
import ExternalAccountsSection from "./sections/ExternalAccountsSection";
import StoreAccountsSection from "./sections/StoreAccountsSection";

const AccountsOverviewPageContent = () => {
  const shouldShowActivateThreadView = useShouldShowActivateThreadView();
  const forceMigrateToThread = useForceMigrateToThreadView();

  if (shouldShowActivateThreadView || forceMigrateToThread) {
    return <ActivateThreadView />;
  }

  return (
    <>
      <AccountsOverviewPageHeader />

      <DashboardPage.Section>
        <ActivateThreadBanner />
      </DashboardPage.Section>

      <AccountsOverviewBalanceSection />

      <BankAccountsSection />

      <CapitalAccountsSection />

      <ExternalAccountsSection />

      <StoreAccountsSection />
    </>
  );
};

type LocationState = {
  fromStep: ActionItemSteps;
};

const AccountsOverviewPage = () => {
  const locationState = useLocation().state as LocationState | undefined;
  const { open: openPayoutInstructionsModal } = usePayoutInstructionsModal();

  useEffect(() => {
    if (locationState?.fromStep === GetStartedSetupGuideStep.ReceiveStorePayouts) {
      openPayoutInstructionsModal({ activeTab: DEFAULT_ACTIVE_TAB });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Accounts</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <AccountsOverviewPageContent />
      </DashboardPage>
    </>
  );
};

export default AccountsOverviewPage;
