import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const PLAID_LINK_TOKEN_QUERY_KEY = "plaid-link-token";

/**
 * How long a Plaid link token is valid after creation.
 *
 * The real time is 4 hours for new connections, and 30 minutes for updates,
 * but to keep things simple we just use an amount under the lower bound.
 *
 * https://plaid.com/docs/api/link/#link-token-create-response-expiration
 */
const PLAID_LINK_TOKEN_EXPIRATION_MINS = 25;

/**
 * The redirect URL used in OAuth flows.
 *
 * This value must be allowlisted for all hosts at https://dashboard.plaid.com/developers/api
 * and must be bound in a route to handle the Plaid oauth link reinitialization.
 *
 * See https://plaid.com/docs/link/oauth/
 */
export const PLAID_OAUTH_REDIRECT_URL = "/plaid-oauth";

export type PlaidLinkTokenParams = Pick<
  PlaidLinkTokenRep.Creation,
  "accountType" | "allowAccountSelectionOnUpdate" | "connectionGuid"
>;

const usePlaidLinkTokenQueryOptions = (params: PlaidLinkTokenParams) => {
  const userGuid = useUserGuid();
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [PLAID_LINK_TOKEN_QUERY_KEY, { userGuid, businessGuid, ...params }],
    queryFn: async () => {
      return (
        await highbeamApi.plaid.createLinkToken({
          businessGuid,
          userGuid,
          ...params,
          redirectUrl: window.location.origin + PLAID_OAUTH_REDIRECT_URL,
        })
      ).linkToken;
    },
    staleTime: PLAID_LINK_TOKEN_EXPIRATION_MINS * 60 * 1000,
  });
};

export default usePlaidLinkTokenQueryOptions;
