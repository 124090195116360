import { Card } from "@highbeam/unit-node-sdk";
import IconWithTooltip from "components/common/IconWithTooltip";
import useMfa from "modules/mfa/useMfa";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import { getCardType, isDebitCard } from "utils/cards";
import useIsAllowedToPerformCardActions from "utils/permissions/useIsAllowedToPerformCardActions";

import { useCardDetailsPageContext } from "../../providers/CardDetailsPageProvider";
import styles from ".././CardDetailsInfo.module.scss";

type Props = {
  setShowEditCardModal: (value: boolean) => void;
};

const cardLimitTooltipContent = (card: Card) => {
  if (isDebitCard(getCardType(card))) {
    return "This card has no budget, and can spend up to 100% of the associated account balance.";
  } else {
    return "This card has no budget, and can spend up to 100% of available usage";
  }
};

const CardBudgetInfoWithoutLimit: React.FC<Props> = ({ setShowEditCardModal }) => {
  const { card } = useCardDetailsPageContext();

  const { mfa } = useMfa();
  const isAllowedToPerformCardActions = useIsAllowedToPerformCardActions();

  return (
    <div className={styles.cardInfoTitle}>
      <div className={styles.titleAndInfoContainer}>
        <div className={styles.leftContent}>
          <Text size={14}>No spend budget</Text>
          <IconWithTooltip content={cardLimitTooltipContent(card)} />
        </div>
        <div>
          <Button
            variant="tertiary"
            size="xs"
            onClick={() => mfa().then(() => setShowEditCardModal(true))}
            disabled={!isAllowedToPerformCardActions}
            tooltip={
              !isAllowedToPerformCardActions && (
                <Text size={14}>You don’t have permission to edit the card budget.</Text>
              )
            }
          >
            Set spend budget
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CardBudgetInfoWithoutLimit;
