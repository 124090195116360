import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import DashboardPage from "components/layouts/DashboardPage";
import { FC } from "react";
import RutterConnectionRep from "reps/RutterConnectionRep";
import useAccountingPlatformConnectionsQueryOptions from "resources/accounting-platforms/queries/useAccountingPlatformConnectionsQueryOptions";
import useHasActiveCapitalAccount from "resources/capital-accounts/queries/useHasActiveCapitalAccount";
import useCreditApplicationsQueryOptions from "resources/credit-application/queries/useCreditApplicationsQueryOptions";
import { getIsNewCreditApplication } from "resources/credit-application/utils/credit-application-type-guards";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import Text from "ui/typography/Text";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";

import ConnectRutter from "../SettingsPage/Integrations/ConnectRutter";

import AccountingInstructions from "./AccountingInstructions";
import AccountingPlatformConnectionCard from "./AccountingPlatformConnectionCard";
import AccountingSoftwareCard from "./AccountingSoftwareCard";
import styles from "./AccountingSoftwareSection.module.scss";

const AccountingSoftwareSection: FC = () => {
  const hasActiveCapitalAccount = useHasActiveCapitalAccount();

  const { data: nonNewCreditApplications } = useSuspenseQuery({
    ...useCreditApplicationsQueryOptions(),
    select: (creditApplications) => {
      return creditApplications.filter((creditApplication) => {
        return !getIsNewCreditApplication(creditApplication);
      });
    },
  });

  return (
    <DashboardPage.Section>
      {(nonNewCreditApplications.length > 0 || hasActiveCapitalAccount) && (
        <>
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>
              Share financials with Highbeam
            </DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>

          <DashboardPage.Section.Body className={styles.linksContainer}>
            <AccountingSoftwareRutterConnect />
          </DashboardPage.Section.Body>

          <Divider />
        </>
      )}

      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>
          Share Highbeam transactions with accounting
        </DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body className={styles.linksContainer}>
        <AccountingSoftwareInstructions />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default AccountingSoftwareSection;

const AccountingSoftwareInstructions: FC = () => {
  const getCardDescription = (type: RutterConnectionRep.RutterAccountingPlatform) => (
    <Text size={14} color={colors.grey[600]}>
      {`Sync your Highbeam transactions to ${getAccountingSoftwareName(type)}.`}
    </Text>
  );

  return (
    <>
      {RutterConnectionRep.rutterAccountingPlatforms.map((type) => (
        <AccountingInstructions accountingSoftwareType={type} key={type}>
          {({ openModal }) => (
            <AccountingSoftwareCard
              type={type}
              description={getCardDescription(type)}
              addText="View instructions"
              onButtonClick={openModal}
            />
          )}
        </AccountingInstructions>
      ))}
    </>
  );
};

const AccountingSoftwareRutterConnect: FC = () => {
  const { refetch } = useQuery(useAccountingPlatformConnectionsQueryOptions());

  return (
    <>
      {RutterConnectionRep.rutterAccountingPlatforms.map((type) => (
        <ConnectRutter
          platform={type}
          onConnect={(_, callbacks) => {
            refetch();
            callbacks.closeModal();
          }}
          key={type}
        >
          {({ openModal }) => (
            <AccountingPlatformConnectionCard openModal={openModal} type={type} />
          )}
        </ConnectRutter>
      ))}
    </>
  );
};
