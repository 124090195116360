import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useMfa from "modules/mfa/useMfa";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import uploadFile from "utils/uploadFile";

export type CreateDrawdownApprovalVariables = {
  amount: number;
  idempotencyKey: string;
  bankAccountGuid: string;
  note: string;
  file: File;
};

const useCreateDrawdownApprovalRequestMutation = () => {
  const { mfa } = useMfa();
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const capitalAccountSummary = useCapitalAccountSummaryContext();

  return useMutation({
    mutationFn: async ({
      amount,
      idempotencyKey,
      bankAccountGuid,
      note,
      file,
    }: CreateDrawdownApprovalVariables) => {
      await mfa();
      const createDrawdownApproval = await highbeamApi.capitalDrawdownApproval.create(
        businessGuid,
        capitalAccountSummary.guid,
        {
          amount,
          idempotencyKey,
          bankAccountGuid,
          note,
        }
      );
      const { guid } = createDrawdownApproval;
      const { attachmentUploadUrl } = await highbeamApi.capitalDrawdownApproval.uploadUrl(
        guid,
        businessGuid
      );
      const uploadResponse = await uploadFile(file, attachmentUploadUrl);
      if (uploadResponse) {
        return createDrawdownApproval;
      }
    },
    onError: (error) => {
      notify("warning", "There was an issue creating drawdown request. Please try again.");
      captureException(error);
    },
  });
};

export default useCreateDrawdownApprovalRequestMutation;
