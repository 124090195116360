namespace CapitalAccountDetailsRep {
  export enum LineType {
    Advance = "Advance",
    Revolving = "Revolving",
    NonRevolving = "NonRevolving",
    InRepayment = "InRepayment",
  }

  export enum SecuredStatus {
    Secured = "Secured",
    Unsecured = "Unsecured",
  }

  export interface RepaymentDetail {
    readonly repaymentOptionGuid: string;
    readonly bankAccountGuid: string;
  }

  export enum RepaymentType {
    PayoutPercentage = "PayoutPercentage",
    DailyInstallments = "DailyInstallments",
    None = "None",
  }

  export interface DailyInstallmentsRepaymentOption {
    readonly type: RepaymentType.DailyInstallments;
    readonly term: number;
  }

  export interface PayoutPercentageRepaymentOption {
    readonly type: RepaymentType.PayoutPercentage;
    readonly percentage: number; // BigDecimal
  }

  export interface NoneRepaymentOption {
    readonly type: RepaymentType.None;
  }

  export type CapitalRepaymentOption =
    | DailyInstallmentsRepaymentOption
    | PayoutPercentageRepaymentOption
    | NoneRepaymentOption;

  export interface Repayment {
    readonly option: CapitalRepaymentOption;
    readonly bankAccountGuid: string | null;
  }

  export interface Complete {
    readonly limit: number;
    readonly apr: number;
    readonly repayment: Repayment;
    readonly lineType: LineType | null;
    readonly targetRepaymentDays: number;
    readonly securedStatus: SecuredStatus | null;
  }
}

export default CapitalAccountDetailsRep;
