import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useBankAccountsQueryOptions from "resources/bank-accounts/queries/useBankAccountsQueryOptions";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

type Variables = {
  accountGuid: string;
  accountName: string;
};

const useEditAccountNameMutation = () => {
  const highbeamApi = useHighbeamApi();

  const refreshBankAccounts = useRefreshQuery(useBankAccountsQueryOptions().queryKey);

  return useMutation({
    mutationFn: async ({ accountGuid, accountName }: Variables) =>
      await highbeamApi.bankAccount.update(accountGuid, { name: accountName }),
    onError: (err) => {
      notify("error", "Something went wrong! Please try again.");
      captureException(err);
    },
    onSuccess: async () => {
      await refreshBankAccounts();
      notify("success", "Account name updated successfully!");
    },
  });
};

export default useEditAccountNameMutation;
