import env from "env";
import { Link } from "react-router-dom";
import useBusiness from "resources/business/queries/useBusiness";
import useBusinessesQueryOptions from "resources/businesses/queries/useBusinessesQueryOptions";
import Button from "ui/inputs/Button";
import CopyItemTooltip from "ui/overlay/Tooltip/CopyItemTooltip/CopyItemTooltip";
import Text from "ui/typography/Text";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import styles from "./BusinessSwitcherTopRow.module.scss";

const BUSINESS_SWITCHER_TOP_ROW_ID = "business-switcher-top-row";

const BusinessSwitcherTopRow = () => {
  const business = useBusiness();
  const refreshBusinessesQuery = useRefreshQuery(useBusinessesQueryOptions().queryKey);
  const superblocksUrl = `${env.SUPERBLOCKS_BUSINESS_LOOKUP_APP_LINK}?businessGuid=${business.guid}`;
  const unitAdminUrl = `${env.UNIT_APP_LINK}/customers/${business.unitCoCustomerId}`;

  return (
    <div className={styles.container} id={BUSINESS_SWITCHER_TOP_ROW_ID}>
      <div className={styles.currentBusinessInfoContainer}>
        <Text size={16} weight="medium">
          {business.internalName}
        </Text>
        <CopyItemTooltip textToCopy={business.guid} rootElementId={BUSINESS_SWITCHER_TOP_ROW_ID}>
          <Text size={16}>{"guid: " + business.guid}</Text>
        </CopyItemTooltip>
      </div>
      <Link to={superblocksUrl} target="_blank">
        <Button variant="tertiary">Superblocks</Button>
      </Link>
      <Link to={unitAdminUrl} target="_blank">
        <Button variant="tertiary">Unit</Button>
      </Link>
      <Button variant="tertiary" onClick={() => refreshBusinessesQuery()}>
        Refresh
      </Button>
    </div>
  );
};
export default BusinessSwitcherTopRow;
