import { MapPin } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import DashboardPage from "components/layouts/DashboardPage";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import useBusinessAddressForm from "resources/business-details/hooks/useBusinessAddressForm";
import useUpdateBusinessAddressMutation from "resources/business-details/hooks/useUpdateBusinessAddressMutation";
import { useEnabledCapitalAccountsQueryOptions } from "resources/capital-accounts/queries/useEnabledCapitalAccounts";
import Button from "ui/inputs/Button";
import { supportedStatesForCapital } from "utils/address";
import useIsAllowedToUpdateBusinessAddress from "utils/permissions/useIsAllowedToUpdateBusinessAddress";

import BusinessAddressFormFields from "./BusinessAddressFormFields";
import styles from "./BusinessAddressSection.module.scss";

const BusinessAddressSection: FC = () => {
  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header
        subheading="This address is the primary address associated with your Highbeam account. It will be
        used as the billing address for all of your cards."
      >
        <DashboardPage.Section.HeaderHeading icon={<MapPin />}>
          Business address
        </DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <BusinessAddressForm />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};
export default BusinessAddressSection;

const BusinessAddressForm: FC = () => {
  const form = useBusinessAddressForm();
  const { mutateAsync, isPending } = useUpdateBusinessAddressMutation();

  const hasWritePermission = useIsAllowedToUpdateBusinessAddress();

  const { data: enabledCapitalAccounts } = useQuery({
    ...useEnabledCapitalAccountsQueryOptions(),
    enabled: hasWritePermission,
  });
  const hasEnabledCapitalAccount = enabledCapitalAccounts && enabledCapitalAccounts.length > 0;

  const stateValue = form.watch("state");

  const handleSubmit = form.handleSubmit(async (data) => {
    const result = await mutateAsync({
      line1: data.line1,
      line2: data.line2 || null,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      country: data.country,
    });

    form.reset({
      line1: result.line1 ?? undefined,
      line2: result.line2 ?? undefined,
      city: result.city ?? undefined,
      state: result.state ?? undefined,
      postalCode: result.postalCode ?? undefined,
      country: result.country ?? undefined,
    });
  });

  return (
    <FormProvider {...form}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <BusinessAddressFormFields />
        <div>
          <Button
            isLoading={isPending}
            type="submit"
            variant="primary"
            disabled={
              !hasWritePermission ||
              !form.formState.isDirty ||
              (!(stateValue in supportedStatesForCapital) && hasEnabledCapitalAccount)
            }
            tooltip={!hasWritePermission && "You don't have permission to edit this information."}
          >
            Save changes
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
