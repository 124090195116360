import classNames from "classnames";
import { FC, ReactNode } from "react";

import styles from "./FeatureOverviewTableFeature.module.scss";

type Props = {
  className?: string;
  children: ReactNode;
};

const FeatureOverviewTableFeature: FC<Props> = ({ className, children }) => {
  return <div className={classNames(styles.feature, className)}>{children}</div>;
};

export default FeatureOverviewTableFeature;
