import { CreditCard } from "@phosphor-icons/react";
import DashboardPage from "components/layouts/DashboardPage";
import { migrateToThreadActionItemConstants } from "modules/migrate-to-thread/migrateToThreadActionItems/constants";
import useSetThreadActionItemMutation from "modules/migrate-to-thread/migrateToThreadActionItems/useSetThreadActionItemMutation";
import { FC } from "react";
import colors from "styles/colors";
import Banner from "ui/data-display/Banner";
import Text from "ui/typography/Text";

import styles from "./MigrateToThreadDebitCardBanner.module.scss";

const MigrateToThreadDebitCardBanner: FC = () => {
  const { mutate: setThreadActionItem } = useSetThreadActionItemMutation();
  return (
    <DashboardPage.Section>
      <Banner
        color="purple"
        icon={
          <div className={styles.migrationIconContainer}>
            <CreditCard color={colors.purple[500]} size={24} weight="thin" />
          </div>
        }
        title={
          <Text size={16} weight="bold">
            Create debit cards for your Thread accounts
          </Text>
        }
        onDismiss={() => {
          setThreadActionItem({
            actionItemName:
              migrateToThreadActionItemConstants.migrateDebitCardsBanner.actionItemName,
            state: "Dismissed",
          });
        }}
        body={
          <Text size={14}>
            To complete the move to your Thread accounts, create new debit cards associated with a
            Thread account.
          </Text>
        }
      />
    </DashboardPage.Section>
  );
};

export default MigrateToThreadDebitCardBanner;
