import { useQuery } from "@tanstack/react-query";
import DashboardPage from "components/layouts/DashboardPage";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import useCapitalDrawdownApprovalsQueryOptions from "resources/line-of-credit/queries/useCapitalDrawdownApprovalsQueryOptions";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import PendingDrawdownsTable from "./PendingDrawdownsTable";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountPendingDrawdownsSection: FC<Props> = ({ capitalAccountSummary }) => {
  const isDrawdownApprovalFeatureFlagEnabled = useFeatureFlag("DRAW_DOWN_WITH_APPROVAL_REQUIRED");
  const { data: pendingDrawdowns = [] } = useQuery(useCapitalDrawdownApprovalsQueryOptions());

  if (
    !isDrawdownApprovalFeatureFlagEnabled ||
    !capitalAccountSummary.controls.drawdownRequiresApproval ||
    pendingDrawdowns.length === 0
  ) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Pending drawdowns</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <PendingDrawdownsTable />
    </DashboardPage.Section>
  );
};

export default CapitalAccountPendingDrawdownsSection;
