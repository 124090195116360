import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useCardLimitQueryOptions = (cardId: string) => {
  const unitApi = useUnitApi();

  return useQueryOptions({
    queryKey: ["cardLimit", { cardId }],
    queryFn: async () => {
      return (await unitApi.cards.limits(cardId)).data;
    },
  });
};

export default useCardLimitQueryOptions;
