import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import HighYieldPage from "pages/accounts/HighYieldPage";
import { Navigate, useParams } from "react-router-dom";
import useAllBankAccounts from "resources/bank-accounts/queries/useAllBankAccounts";
import { NotFoundError } from "routes/AuthenticatedRouter/AuthenticatedRouterErrorBoundary";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import { getAccountHeaderName } from "utils/account";

import AccountAuthorizationsTable from "./AccountAuthorizationsTable";
import AccountDetailsAccountTransactionsSection from "./AccountDetailsAccountTransactionsSection";
import AccountDetailsIncomingPaymentsSection from "./AccountDetailsIncomingPaymentsSection";
import AccountDetailsPageHeader from "./AccountDetailsPageHeader";
import AccountInformation from "./AccountInformation";

const AccountDetailsPage = () => {
  const params = useParams();
  const accountGuid = params.accountGuid!;

  const accounts = useAllBankAccounts();
  const account = accounts.find((account) => account.guid === accountGuid);
  if (!account) throw new NotFoundError("Account not found.");
  if (account.highbeamType.name === "LineOfCredit") return <Navigate to="/capital" />;

  const accountPage =
    account.highbeamType.name === "HighYield" ? (
      <HighYieldPage account={account} />
    ) : (
      <>
        <DashboardHeader>
          <Breadcrumbs>
            <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
            <Breadcrumbs.CurrentItem>{getAccountHeaderName(account)}</Breadcrumbs.CurrentItem>
          </Breadcrumbs>
        </DashboardHeader>

        <DashboardPage>
          <AccountDetailsPageHeader account={account} />
          <AccountInformation account={account} />

          <AccountDetailsIncomingPaymentsSection
            unitCoDepositAccountId={account.unitCoDepositAccountId}
          />

          <AccountAuthorizationsTable accountId={account.unitCoDepositAccountId} />

          <AccountDetailsAccountTransactionsSection account={account} />
        </DashboardPage>
      </>
    );

  return accountPage;
};

export default AccountDetailsPage;
