import { selector, useRecoilValue } from "recoil";

import { selectedBusinessGuidState } from "./businessGuid";
import isAuthenticatedState from "./isAuthenticated";

const isSuperuseringState = selector<boolean>({
  key: "auth/isSuperusering",
  get: ({ get }) => {
    const isAuthenticated = get(isAuthenticatedState);
    const selectedBusinessGuid = get(selectedBusinessGuidState);

    return isAuthenticated && Boolean(selectedBusinessGuid);
  },
});

export default isSuperuseringState;

export const useIsSuperusering = () => {
  return useRecoilValue(isSuperuseringState);
};
