import BankAccountRep from "reps/BankAccountRep";

import { BankingPartner } from "../types";

const filterBankAccountsByPartnerBank = (
  bankAccounts: BankAccountRep.Complete[],
  partnerBank: BankingPartner
): BankAccountRep.Complete[] => {
  switch (partnerBank) {
    case "Thread":
      return bankAccounts.filter((bankAccount) => bankAccount.threadAccount);
    case "Hybrid":
      return bankAccounts;
    case "BlueRidgeBank":
      return bankAccounts.filter((bankAccount) => bankAccount.blueRidgeAccount);
  }
};

export default filterBankAccountsByPartnerBank;
