import classNames from "classnames";
import RecurringPaymentCounterparty from "pages/payments/PaymentsOverviewPage/RecurringPayments/RecurringPaymentCounterparty";
import useUnitCoRecurringPayment from "resources/unit-co-recurring-payments/queries/useUnitCoRecurringPayment";
import isRecurring from "resources/unit-co-recurring-payments/utils/isRecurring";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { formatDate } from "utils/date";

import { useRecurringPaymentFlexpaneContext } from "../context/RecurringPaymentFlexpaneProvider";

import BankAccountDetail from "./BankAccountDetail";
import RecurringPaymentDetailBubble from "./RecurringPaymentDetailBubble";
import styles from "./RecurringPaymentInfoFlexpaneToFromSection.module.scss";

const RecurringPaymentInfoFlexpaneToFromSection = () => {
  const { recurringPaymentId } = useRecurringPaymentFlexpaneContext();
  const recurringPayment = useUnitCoRecurringPayment(recurringPaymentId, { required: true });

  const isScheduled = !isRecurring(recurringPayment);
  const scheduledPaymentDate = formatDate(
    recurringPayment.attributes.schedule.nextScheduledAction
  ).replace(",", "");

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <Text size={14} color={colors.grey[900]}>
          From
        </Text>
        <BankAccountDetail recurringPayment={recurringPayment} />
        {isScheduled && (
          <Text
            size={14}
            weight="regular"
            color={colors.grey[500]}
          >{`Scheduled ${scheduledPaymentDate}`}</Text>
        )}
      </div>
      <div className={classNames(styles.column, styles.center)}>
        <RecurringPaymentDetailBubble recurringPayment={recurringPayment} />
      </div>
      <div className={styles.column}>
        <Text size={14} color={colors.grey[900]}>
          To
        </Text>
        <RecurringPaymentCounterparty payment={recurringPayment} />
      </div>
    </div>
  );
};
export default RecurringPaymentInfoFlexpaneToFromSection;
