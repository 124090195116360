import RequestAdditionalCapitalButton from "../RequestAdditionalCapitalButton";

import styles from "./EmptyCapitalAccountCard.module.scss";

const EmptyCapitalAccountCard = () => {
  return (
    <div className={styles.emptyCard}>
      <RequestAdditionalCapitalButton />
    </div>
  );
};

export default EmptyCapitalAccountCard;
