import applePayLogo from "assets/apple-pay.svg";
import classNames from "classnames";
import Text from "ui/typography/Text";

import styles from "./ApplePayInfoText.module.scss";

type Props = {
  className?: string;
};

const ApplePayInfoText: React.FC<Props> = ({ className }) => (
  <div className={classNames(styles.container, className)}>
    <Text size={14}>
      Open the wallet app on your phone and add the card to start using it with
      <img src={applePayLogo} className={styles.applePayLogo} alt="apple pay logo" />
    </Text>
  </div>
);

export default ApplePayInfoText;
