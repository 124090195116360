import { useSuspenseQuery } from "@tanstack/react-query";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";

import usePlaidConnectionsQueryOptions from "./usePlaidConnectionsQueryOptions";

type ConnectionInfo = {
  creditCards: PlaidBankAccountRep.Complete[];
  bankAccounts: PlaidBankAccountRep.Complete[];
  isActive: boolean;
  institutionId: string;
  institutionName: string;
};

const usePlaidConnectionsByConnectionGuid = () => {
  const { data: plaidConnectionsByConnectionGuid } = useSuspenseQuery({
    ...usePlaidConnectionsQueryOptions(),
    select: (plaidConnections) => {
      return plaidConnections.reduce((acc, connection) => {
        const accountInfo = acc.get(connection.guid) ?? {
          creditCards: [],
          bankAccounts: [],
          isActive: connection.isActive,
          institutionId: connection.institutionId,
          institutionName: connection.institutionName,
        };
        if (connection.accountType === "CREDIT") {
          accountInfo.creditCards.push(...connection.accounts);
        } else if (connection.accountType === "DEPOSITORY") {
          accountInfo.bankAccounts.push(...connection.accounts);
        }
        acc.set(connection.guid, accountInfo);
        return acc;
      }, new Map<string, ConnectionInfo>());
    },
  });

  return plaidConnectionsByConnectionGuid;
};

export default usePlaidConnectionsByConnectionGuid;
