import { AuthorizationStatus } from "@highbeam/unit-node-sdk";
import CardInfo from "components/CardInfo";
import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import TransactionFlexpaneStatus from "components/common/transaction-flexpane/TransactionFlexpaneStatus";
import useCardAuthorization from "dialogs/CardAuthorizationFlexpane/hooks/useCardAuthorization";
import colors from "styles/colors";
import { PillColor } from "ui/data-display/Pill";
import Text from "ui/typography/Text";

const cardAuthorizationStatusPillMappings: Record<AuthorizationStatus, PillColor> = {
  Authorized: "grey-light",
  Completed: "green",
  Canceled: "grey",
  Declined: "red",
};

const cardAuthorizationStatusTextMappings: Record<AuthorizationStatus, string> = {
  Authorized: "Pending",
  Completed: "Completed",
  Canceled: "Canceled",
  Declined: "Declined",
};

const CardAuthorizationStatus = () => {
  const authorization = useCardAuthorization();
  const status = authorization.attributes.status;

  return (
    <TransactionFlexpaneStatus
      pillColor={cardAuthorizationStatusPillMappings[status]}
      status={cardAuthorizationStatusTextMappings[status]}
      methodName="Card transaction"
    />
  );
};

const CardAuthorizationParties = () => {
  const authorization = useCardAuthorization();

  return (
    <TransactionFlexpaneParties
      from={
        <CardInfo
          cardId={authorization.relationships.card.data.id}
          showLabel={false}
          showIcon={false}
        />
      }
      status={<CardAuthorizationStatus />}
      to={
        <Text color={colors.grey[900]} size={14} weight="medium">
          {authorization.attributes.merchant.name}
        </Text>
      }
    />
  );
};

export default CardAuthorizationParties;
