import { ArrowFatLinesUp } from "@phosphor-icons/react";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import MultiStep from "components/layouts/MultiStep";
import { Suspense } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import {
  CapitalAccountSummaryProvider,
  useCapitalAccountSummaryContext,
} from "resources/capital-accounts/context/capital-account-summary-context";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToRepayCapital from "utils/permissions/useIsAllowedtoRepayCapital";

import RepayLineOfCreditSteps from "./components/RepayLineOfCreditSteps";
import RepayLineOfCreditAmountView from "./views/RepayLineOfCreditAmountView";
import RepayLineOfCreditConfirmationView from "./views/RepayLineOfCreditConfirmationView";

const CapitalRepayLineOfCreditPageContent = () => {
  const isAllowedToRepayCapital = useIsAllowedToRepayCapital();
  const capitalAccountSummary = useCapitalAccountSummaryContext();

  if (!isAllowedToRepayCapital) {
    return <Navigate to={`/capital/${capitalAccountSummary.guid}`} />;
  }

  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.BackCaret to={`/capital/${capitalAccountSummary.guid}`} />
        <DashboardPage.Header.IconTile icon={<ArrowFatLinesUp size={20} weight="light" />} />
        <DashboardPage.Header.Title>Repay line of credit</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        <MultiStep steps={<RepayLineOfCreditSteps />}>
          <Routes>
            <Route index element={<RepayLineOfCreditAmountView />} />
            <Route path="/confirm" element={<RepayLineOfCreditConfirmationView />} />
          </Routes>
        </MultiStep>
      </DashboardPage.Section>
    </>
  );
};

const CapitalAccountNameBreadcrumb = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  return (
    <Breadcrumbs.Item to={`/capital/${capitalAccountSummary.guid}`}>
      {capitalAccountSummary.name}
    </Breadcrumbs.Item>
  );
};

const CapitalRepayLineOfCreditPage = () => {
  const { capitalAccountGuid } = useParams();

  if (!capitalAccountGuid) {
    return <Navigate to="/capital" />;
  }

  return (
    <CapitalAccountSummaryProvider capitalAccountGuid={capitalAccountGuid}>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>
          <Suspense fallback={<Breadcrumbs.ItemShimmer />}>
            <CapitalAccountNameBreadcrumb />
          </Suspense>
          <Breadcrumbs.CurrentItem>Repay credit</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CapitalRepayLineOfCreditPageContent />
      </DashboardPage>
    </CapitalAccountSummaryProvider>
  );
};

export default CapitalRepayLineOfCreditPage;
