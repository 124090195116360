import { ReactNode } from "react";
import { atom, useRecoilValue } from "recoil";
import { ButtonProps } from "ui/inputs/Button";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

type ConfirmationModalVariant = ButtonProps["variant"];

export type ConfirmationModalParams = {
  heading: ReactNode;
  message: ReactNode;
  variant: ConfirmationModalVariant;
  buttonLabel: ReactNode;
};

export type ConfirmationModalOpenState = ConfirmationModalParams & {
  resolvePromise: (confirmed: boolean) => void;
};

type ConfirmationModalState = DialogState<ConfirmationModalOpenState>;

const confirmationModalState = atom<ConfirmationModalState>({
  key: "root/confirmationModalState",
  default: { isOpen: false },
});

export default confirmationModalState;

export const useConfirmationModal = () => {
  return useModal(confirmationModalState);
};

export const useConfirmationModalState = () => {
  return useRecoilValue(confirmationModalState);
};
