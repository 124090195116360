import { atom } from "recoil";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

const updateBusinessAddressModalState = atom<DialogState>({
  key: "updateBusinessAddressModalState",
  default: { isOpen: false },
});

export default updateBusinessAddressModalState;

export const useUpdateBusinessAddressModal = () => {
  return useModal(updateBusinessAddressModalState);
};
