import ModalV4 from "ui/overlay/ModalV4";

import EditCapitalAccountNameForm from "./EditCapitalAccountNameForm";
import { useEditCapitalAccountNameModal } from "./state/editCapitalAccountNameModalState";

const EditCapitalAccountNameModal = () => {
  const { isOpen, close } = useEditCapitalAccountNameModal();

  if (!isOpen) {
    return null;
  }

  return (
    <ModalV4 onClose={close}>
      <EditCapitalAccountNameForm />
    </ModalV4>
  );
};

export default EditCapitalAccountNameModal;
