import { ErrorBoundary } from "@sentry/react";
import ErrorText from "components/error/ErrorText";
import FeatureStatusIndicator from "components/FeatureStatusIndicator";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUser from "resources/user/queries/useUser";
import Section from "ui/data-display/Section";
import SectionHeader from "ui/data-display/SectionHeader";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Heading2 from "ui/typography/Heading2";
import useMountEffect from "utils/customHooks/useMountEffect";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import styles from "./InsightsVendorPage.module.scss";
import SendFeedbackButton from "./SendFeedbackButton";
import SpendByVendorTable from "./SpendByVendorTable";
import SpendByVendorTableControls, {
  useSpendByVendorTableControls,
} from "./SpendByVendorTableControls";
import useVendorList from "./useVendorList";

const InsightsVendorPageV1 = () => {
  const { segmentTrack } = useSegment();
  const businessGuid = useBusinessGuid();
  const user = useUser();

  useMountEffect(() => {
    segmentTrack(SEGMENT_EVENTS.INSIGHTS_SPEND_BY_VENDOR_VISITED, { businessGuid, user });
  });

  const controls = useSpendByVendorTableControls();

  const { columns, fetchData } = useVendorList(controls);

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/spend">Spend</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>By vendor</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <div className={styles.container}>
        <Section className={styles.section}>
          <SectionHeader actions={<SendFeedbackButton />}>
            <Heading2>Spend by vendor</Heading2>
            <FeatureStatusIndicator featureStatus="Beta" />
          </SectionHeader>
          <SpendByVendorTableControls controls={controls} />
          <ErrorBoundary fallback={({ error }) => <ErrorText error={error} />}>
            <SpendByVendorTable columns={columns} fetchData={fetchData} />
          </ErrorBoundary>
        </Section>
      </div>
    </>
  );
};

export default InsightsVendorPageV1;
