import OnboardingLayout from "components/layouts/Onboarding";
import OnboardingStep from "pages/Onboarding/OnboardingStep";

const OnboardingDelayed = () => (
  <OnboardingLayout>
    <OnboardingStep
      title="Something went wrong"
      subtitle="We are sorry, there is a temporary issue with your signup. Please come back in a few minutes, or reach out to support."
    />
  </OnboardingLayout>
);

export default OnboardingDelayed;
