import IconWithTooltip from "components/common/IconWithTooltip";
import Address from "ui/data-display/Address";
import Text from "ui/typography/Text";
import { getCardBillingAddress } from "utils/cards";

import { useCardDetailsPageContext } from "../providers/CardDetailsPageProvider";

import CardBudgetV2 from "./CardBudget/CardBudgetV2";
import styles from "./CardDetailsInfo.module.scss";
import { convertUnitAddressToAddressComponentProp } from "./utils";

const ChargeCardDetails = () => {
  const { card } = useCardDetailsPageContext();

  const billingAddress = getCardBillingAddress(card);
  const isCardArchived = card.attributes.status === "ClosedByCustomer";

  return (
    <div className={styles.details}>
      <div>
        <div className={styles.cardInfo}>
          <div className={styles.cardInfoTitle}>
            <Text size={14}>Billing address</Text>
            <IconWithTooltip
              content={
                "Your business address is used as billing address for all cards. To update your business address, go to Settings."
              }
            />
          </div>
          <div className={styles.cardInfoContent}>
            {billingAddress && (
              <Address
                address={convertUnitAddressToAddressComponentProp(billingAddress)}
                size={14}
                weight="medium"
              />
            )}
          </div>
        </div>

        {!isCardArchived && (
          <div className={styles.spendAndLimit}>
            <CardBudgetV2 />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChargeCardDetails;
