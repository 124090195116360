import { FC } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";

const BackButton: FC<ButtonProps> = ({ children = "Back", ...buttonV2Props }) => (
  <Button variant="tertiary" type="button" {...buttonV2Props}>
    {children}
  </Button>
);

export default BackButton;
