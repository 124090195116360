import DashboardPage from "components/layouts/DashboardPage";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

import CapitalAvailableSection from "../../components/CapitalAvailableSection";
import ChargeCardRepayments from "../../components/ChargeCardRepayments";
import CapitalAccountBanners from "../../sections/CapitalAccountBanners";
import CapitalAccountCreditTransactionsSection from "../../sections/CapitalAccountCreditTransactionsSection";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";

import ChargeCardHighlightCard from "./ChargeCardHighlightCard";
import LineOfCreditHighlightCard from "./LineOfCreditHighlightCard";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const ChargeCardView: FC<Props> = ({ capitalAccountSummary }) => {
  return (
    <>
      <CapitalAccountBanners capitalAccountSummary={capitalAccountSummary} />

      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummary} />

      <DashboardPage.Section>
        <CapitalAvailableSection
          capitalAccountSummary={capitalAccountSummary}
          showViewCardsButton
        />

        <DashboardPage.Section.Body>
          <ChargeCardHighlightCard />
        </DashboardPage.Section.Body>

        <DashboardPage.Section.Body>
          <LineOfCreditHighlightCard />
        </DashboardPage.Section.Body>
      </DashboardPage.Section>

      <DashboardPage.Section>
        <ChargeCardRepayments capitalAccountSummary={capitalAccountSummary} />
      </DashboardPage.Section>

      <CapitalAccountCreditTransactionsSection capitalAccountSummary={capitalAccountSummary} />
    </>
  );
};

export default ChargeCardView;
