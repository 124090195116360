import getActionItem from "resources/action-items/utils/getActionItem";

import useMigrateToThreadActionItems from "../queries/useMigrateToThreadActionItems";

import { migrateToThreadActionItemConstants } from "./constants";

const useHasDismissedCreateNewDebitCardBanner = () => {
  const { data } = useMigrateToThreadActionItems();
  const activateThreadViewActionItem = getActionItem(
    data,
    migrateToThreadActionItemConstants.migrateDebitCardsBanner.actionItemName
  );
  return activateThreadViewActionItem?.state === "Dismissed";
};

export default useHasDismissedCreateNewDebitCardBanner;
