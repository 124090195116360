import { CalendarBlank, Timer } from "@phosphor-icons/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import MultiStep from "components/layouts/MultiStep";
import { useNavigate } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import useOpenBankAccount from "resources/bank-accounts/queries/useOpenBankAccount";
import { useOpenBankAccountsQueryOptions } from "resources/bank-accounts/queries/useOpenBankAccounts";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";
import mapTransferOption from "resources/bank-accounts/utils/mapTransferOption";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import colors from "styles/colors";
import Banner from "ui/data-display/Banner";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Dropdown from "ui/inputs/Dropdown";
import Text from "ui/typography/Text";

import AutoPayScheduleSteps from "../components/AutoPayScheduleSteps";
import ChargeCardAutoPaySubheading from "../components/ChargeCardAutoPaySubheading";
import ChargeCardExampleStatementPeriod from "../components/ChargeCardExampleStatementPeriod";
import ReviewLineOfferBackButton from "../components/ReviewLineOfferBackButton";
import { useReviewLineOfferContext } from "../context/ReviewLineOfferProvider";

import styles from "./ReviewLineOfferViews.module.scss";

const AUTO_PAY_FORM_ID = "auto-pay-form";

const CashAccessLineAutoPayContent = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const primaryAccount = usePrimaryBankAccount({ required: true });
  const repaymentAccount = useOpenBankAccount(primaryAccount.guid, {
    required: true,
  });
  const { data: accountOptions } = useSuspenseQuery({
    ...useOpenBankAccountsQueryOptions(),
    select: (bankAccounts) => bankAccounts.map(mapTransferOption),
  });

  const targetRepaymentDays = capitalAccountSummary.details.targetRepaymentDays;

  return (
    <div className={styles.multistepContainer}>
      <Text size={16} color={colors.grey[900]}>
        Each drawdown will be repaid in equal daily installments over {targetRepaymentDays} days.
      </Text>
      <div className={styles.autopayScheduleCard}>
        <div className={styles.autopayScheduleSubtext}>
          <div className={styles.subtextItem}>
            <CalendarBlank size={20} color={colors.grey[800]} />
            <Text size={14} color={colors.grey[800]}>
              Example auto-pay schedule
            </Text>
          </div>
          <Text size={14} color={colors.grey[800]}>
            Daily payment
          </Text>
        </div>
        <Divider margin={0} />
        <div className={styles.autopayScheduleSteps}>
          <AutoPayScheduleSteps />
          <Text size={14}>
            <MoneyAmount
              cents={capitalAccountSummary.details.limit / targetRepaymentDays}
              currency={"USD"}
              weight="medium"
              withCents={false}
            />
            {" / "}
            day
          </Text>
        </div>
      </div>
      <Dropdown
        label="Auto-pay account"
        value={
          accountOptions.find((option) => {
            return option.guid === repaymentAccount.guid;
          }) ?? accountOptions[0]
        }
        options={accountOptions}
        disabled
      />
    </div>
  );
};

const ChargeCardAutoPayContent = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const primaryAccount = usePrimaryBankAccount({ required: true });
  const repaymentAccount = useOpenBankAccount(primaryAccount.guid, {
    required: true,
  });
  const { data: accountOptions } = useSuspenseQuery({
    ...useOpenBankAccountsQueryOptions(),
    select: (bankAccounts) => bankAccounts.map(mapTransferOption),
  });

  return (
    <div className={styles.multistepContainer}>
      <ChargeCardAutoPaySubheading capitalAccountGuid={capitalAccountSummary.guid} />
      <Dropdown
        label="Auto-pay account"
        value={
          accountOptions.find((option) => {
            return option.guid === repaymentAccount.guid;
          }) ?? accountOptions[0]
        }
        options={accountOptions}
        disabled
      />
      <div className={styles.exampleStatementContainer}>
        <Text as="p" size={14} weight="medium">
          Example statement period:
        </Text>
        <ChargeCardExampleStatementPeriod capitalAccountGuid={capitalAccountSummary.guid} />
      </div>
      <Banner
        padding="normal"
        color="purple"
        icon={<Timer />}
        title="Overdue balances"
        body={
          <Text size={14}>
            After the due date, unpaid balances
            {capitalAccountSummary.details.apr > 0 && (
              <>
                {" "}
                will accrue interest daily ({capitalAccountSummary.details.apr * 100}% APR) and
                Highbeam
              </>
            )}{" "}
            will auto-debit from your accounts over 15 days until the balance is repaid.
          </Text>
        }
      />
    </div>
  );
};

const AutoPayView = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const navigate = useNavigate();
  const { nextPathname } = useReviewLineOfferContext();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  return (
    <MultiStep.Form id={AUTO_PAY_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Auto-pay</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>
      </MultiStep.Section>

      <MultiStep.Section>
        {capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly ? (
          <ChargeCardAutoPayContent />
        ) : (
          <CashAccessLineAutoPayContent />
        )}
      </MultiStep.Section>

      <MultiStep.Controls>
        <ReviewLineOfferBackButton />
        <MultiStep.Controls.NextButton form={AUTO_PAY_FORM_ID}>Next</MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default AutoPayView;
