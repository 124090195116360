import { ArrowFatLinesDown } from "@phosphor-icons/react";
import { ComponentProps, FC } from "react";
import { Link } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import isAbleToDrawDown from "resources/capital-accounts/utils/isAbleToDrawDown";
import ButtonArray from "ui/navigation/ButtonArray";
import useIsAllowedToDrawDownCapital from "utils/permissions/useIsAllowedToDrawDownCapital";

import { NotAllowedToDrawDownCapital } from "../../constants";

type ButtonArrayButtonProps = Partial<ComponentProps<typeof ButtonArray.Button>>;

type Props = ButtonArrayButtonProps & {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const LineOfCreditDrawdownButtonArrayButton: FC<Props> = ({
  className,
  style,
  capitalAccountSummary,
  ...buttonArrayButtonProps
}) => {
  const isAllowedToDrawDownCapital = useIsAllowedToDrawDownCapital();

  return (
    <Link
      to={`/capital/${capitalAccountSummary.guid}/credit/draw-down`}
      className={className}
      style={style}
    >
      <ButtonArray.Button
        variant="secondary"
        fullWidth
        disabled={!isAllowedToDrawDownCapital || !isAbleToDrawDown(capitalAccountSummary)}
        tooltip={!isAllowedToDrawDownCapital && <NotAllowedToDrawDownCapital />}
        {...buttonArrayButtonProps}
      >
        <ArrowFatLinesDown size={20} weight="light" />
        Draw down
      </ButtonArray.Button>
    </Link>
  );
};

export default LineOfCreditDrawdownButtonArrayButton;
