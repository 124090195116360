import { Authorization } from "@highbeam/unit-node-sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import TransactionsV2 from "components/TransactionsV2";
import { Suspense } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUnitCoAuthorizationsQueryOptions from "resources/unit-co-transactions/queries/useUnitCoAuthorizationsQueryOptions";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import DateTimeCell from "ui/table/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";

import { useCardDetailsPageContext } from "../../providers/CardDetailsPageProvider";

const authorizationColumns: Column<Authorization>[] = [
  {
    title: "Date",
    cellRender: ({ attributes }) => <DateTimeCell date={attributes.createdAt} />,
    width: 120,
  },
  {
    title: "Vendor",
    cellRender: ({ attributes }) => (
      <Text size={14} weight="medium" color={colors.grey[800]}>
        {attributes.merchant.name}
      </Text>
    ),
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: ({ attributes: { amount } }) => (
      <MoneyAmount
        as="div"
        cents={amount}
        direction="negative"
        color={colors.grey[800]}
        weight="medium"
      />
    ),
  },
];

const PendingAuthorizationsTable = () => {
  const { card } = useCardDetailsPageContext();

  const {
    data: { authorizations },
  } = useSuspenseQuery(
    useUnitCoAuthorizationsQueryOptions({
      cardId: card.id,
      status: "Authorized",
    })
  );

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (authorizations.length === 0) {
    return null;
  }

  return (
    <TransactionsV2
      title={<TransactionsV2.Title title="Pending" />}
      table={
        <Suspense
          fallback={
            <Table.Loading columns={authorizationColumns.map(({ title }) => ({ title }))} />
          }
        >
          <TransactionsV2.TransactionsTable
            data={authorizations}
            rowKey={({ id }) => id}
            columns={authorizationColumns}
            onRowClick={(authorization) => {
              // NB(alex): We want to preserve search params when navigating to preserve the parent page's filters.
              navigate(`/cards/${card.id}/pending/${authorization.id}?${searchParams}`, {
                state: DISABLE_SCROLL_TO_TOP_STATE,
              });
            }}
          />
        </Suspense>
      }
    />
  );
};

export default PendingAuthorizationsTable;
