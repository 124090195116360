import { ArrowFatLinesDown, ArrowFatLineUp, Calendar, Pause } from "@phosphor-icons/react";
import colors from "styles/colors";
import FeatureOverviewTable from "ui/data-display/FeatureOverviewTable";
import IconTile, { IconType, IconColor } from "ui/icons/IconTile";
import Text from "ui/typography/Text";

import styles from "./CapitalFeatureOverviewTable.module.scss";

type Props = {
  className?: string;
};

const CapitalFeatureOverviewTable: React.FC<Props> = ({ className }) => (
  <FeatureOverviewTable className={className}>
    <FeatureOverviewTable.Feature className={styles.feature}>
      <IconTile
        icon={<ArrowFatLinesDown />}
        size={64}
        type={IconType.Circle}
        color={IconColor.Purple}
      />
      <div>
        <Text size={16} weight="bold" className={styles.featureTitle}>
          Multiple draws
        </Text>
        <Text size={14} color={colors.grey[600]}>
          Get funding on demand, as many times as you need, when you need it.
        </Text>
      </div>
    </FeatureOverviewTable.Feature>

    <FeatureOverviewTable.Feature className={styles.feature}>
      <IconTile
        icon={<ArrowFatLineUp />}
        size={64}
        type={IconType.Circle}
        color={IconColor.Green}
      />
      <div>
        <Text size={16} weight="bold" className={styles.featureTitle}>
          Early pay
        </Text>
        <Text size={14} color={colors.grey[600]}>
          Unexpectedly found yourself with additional income? Save money and reduce interest by
          repaying early.
        </Text>
      </div>
    </FeatureOverviewTable.Feature>

    <FeatureOverviewTable.Feature className={styles.feature}>
      <IconTile icon={<Calendar />} size={64} type={IconType.Circle} color={IconColor.Orange} />
      <div>
        <Text size={16} weight="bold" className={styles.featureTitle}>
          Configurable repayment schedule
        </Text>
        <Text size={14} color={colors.grey[600]}>
          Repayment flexibility like nowhere else. Schedule when and for how long you want to take
          to repay (up to 6 months).
        </Text>
      </div>
    </FeatureOverviewTable.Feature>

    <FeatureOverviewTable.Feature className={styles.feature}>
      <IconTile icon={<Pause />} size={64} type={IconType.Circle} color={IconColor.Red} />
      <div>
        <Text size={16} weight="bold" className={styles.featureTitle}>
          Pause payments
        </Text>
        <Text size={14} color={colors.grey[600]}>
          Uncomfortable repaying today? Pause payments until your financials are back on track.
        </Text>
      </div>
    </FeatureOverviewTable.Feature>
  </FeatureOverviewTable>
);

export default CapitalFeatureOverviewTable;
