import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";

type InvitationDetailsModalClosedState = { isOpen: false };
type InvitationDetailsModalOpenState = {
  isOpen: true;
  initials: string;
  displayName: string;
  slug: string;
};

type InvitationDetailsModalState =
  | InvitationDetailsModalClosedState
  | InvitationDetailsModalOpenState;

const invitationDetailsModalState = atom<InvitationDetailsModalState>({
  key: "settings/users/invitationDetailsModalState",
  default: { isOpen: false },
});

export default invitationDetailsModalState;

export const useOpenInvitationDetailsModal = () => {
  const setInvitationDetailsModalState = useSetRecoilState(invitationDetailsModalState);
  return useCallback(
    (state: Omit<InvitationDetailsModalOpenState, "isOpen">) =>
      setInvitationDetailsModalState({
        isOpen: true,
        ...state,
      }),
    [setInvitationDetailsModalState]
  );
};

export const useCloseInvitationDetailsModal = () => {
  const setInvitationDetailsModalState = useSetRecoilState(invitationDetailsModalState);
  return useCallback(
    () => setInvitationDetailsModalState({ isOpen: false }),
    [setInvitationDetailsModalState]
  );
};
