import Sheet from "react-modal-sheet";
import { useRecoilValue } from "recoil";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import { CloseModalButton } from "ui/overlay/ModalV2/Header";
import Text from "ui/typography/Text";

import AddToHomeScreenView from "../AddToHomeScreenView";

import styles from "./AddToHomeScreenInstructionsSheet.module.scss";
import addToHomeScreenInstructionsSheetState, {
  useCloseAddToHomeScreenInstructionsSheet,
} from "./state/addToHomeScreenInstructionsSheetState";

const AddToHomeScreenInstructionsSheet = () => {
  const { isOpen } = useRecoilValue(addToHomeScreenInstructionsSheetState);
  const closeSheet = useCloseAddToHomeScreenInstructionsSheet();

  const handleClose = () => {
    closeSheet();
  };

  return (
    <Sheet isOpen={isOpen} onClose={handleClose} detent="content-height">
      <Sheet.Container>
        <Sheet.Header className={styles.header}>
          <Text color={colors.grey[900]} weight="bold" size={16}>
            Add Highbeam to home screen
          </Text>
          <CloseModalButton onClick={handleClose} />
        </Sheet.Header>
        <Sheet.Content className={styles.content}>
          <AddToHomeScreenView />
        </Sheet.Content>

        <div className={styles.footer}>
          <Button variant="primary" fullWidth onClick={handleClose}>
            Done
          </Button>
        </div>
      </Sheet.Container>

      <Sheet.Backdrop onTap={handleClose} />
    </Sheet>
  );
};

export default AddToHomeScreenInstructionsSheet;
