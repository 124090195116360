import React from "react";
import Text from "ui/typography/Text";

import styles from "./SelectCard.module.scss";

type CardTagProps = {
  icon: React.ReactNode;
  text: string;
};

const CardTag: React.FC<CardTagProps> = ({ icon, text }) => (
  <div className={styles.cardTag}>
    {icon}
    <Text className={styles.cardTagText} weight="medium" size={12}>
      {text}
    </Text>
  </div>
);

export default CardTag;
