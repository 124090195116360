import { captureException } from "@sentry/react";
import env from "env";
import { useMemo } from "react";
import {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptionsWithLinkToken,
  usePlaidLink,
} from "react-plaid-link";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { GetStartedSetupGuideStep, Milestone } from "resources/action-items/types";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { localStorageLinkTokenState } from "state/localStorage/linkToken";
import duplicatePlaidConnectionGuidState from "state/plaid/duplicateConnectionGuid";
import isPlaidExistingAccountModalOpenState from "state/plaid/isExistingAccountModalOpen";
import { notify } from "ui/feedback/Toast";
import { ApiError, HighbeamApiError } from "utils/ajax";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import { useMilestoneActionItems } from "../../resources/action-items/hooks/useMilestoneActionItems";
import { localStoragePostPlaidOauthRedirectPath } from "../../state/localStorage/postPlaidOauthRedirectPath";

import useHighbeamApi from "./useHighbeamApi";

const useHighbeamPlaidLink = ({
  linkToken,
  onComplete,
  isNewConnection = true,
}: {
  linkToken: string | null;
  onComplete?: () => void;
  isNewConnection?: boolean;
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const oauthFlowNeedsCompletion = searchParams.get("oauth_state_id") !== null;
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const setIsPlaidExistingAccountModalOpen = useSetRecoilState(
    isPlaidExistingAccountModalOpenState
  );
  const setDuplicatePlaidConnectionGuid = useSetRecoilState(duplicatePlaidConnectionGuidState);

  const setExistingLinkToken = useSetRecoilState(localStorageLinkTokenState);
  const setPostPlaidOauthRedirectPath = useSetRecoilState(localStoragePostPlaidOauthRedirectPath);
  const { finishItemByStep } = useMilestoneActionItems(Milestone.GetStarted);
  const { segmentTrack } = useSegment();

  const config: PlaidLinkOptionsWithLinkToken = useMemo(() => {
    return {
      onSuccess: async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
        try {
          await highbeamApi.plaid.createAccessToken(
            businessGuid,
            publicToken,
            metadata,
            isNewConnection
          );
          await finishItemByStep(GetStartedSetupGuideStep.ConnectBank, "Complete");
          const name = metadata.institution?.name ?? "external";
          if (isNewConnection) {
            notify("success", `Your ${name} account is connected 🎉.`);
          } else {
            notify("success", `Your ${name} account has been updated 🎉.`);
          }

          onComplete?.();
        } catch (e) {
          if (e instanceof HighbeamApiError && e.error === "PlaidConnectionAlreadyExists") {
            setDuplicatePlaidConnectionGuid(e.properties.connectionGuid);
            setIsPlaidExistingAccountModalOpen(true);
            segmentTrack(SEGMENT_EVENTS.PLAID_LINK_DUPLICATE, metadata);
            return;
          }

          notify("error", "We were unable to connect the account.");
          segmentTrack(SEGMENT_EVENTS.PLAID_LINK_FAILED, metadata);
          if (e instanceof ApiError) {
            captureException(
              new Error(
                `Plaid link token request returned with status ${e.statusCode} and data ${e.message}`
              )
            );
          } else {
            captureException(e);
          }
        }
      },
      onExit: (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
        if (error) {
          captureException(new Error(`Plaid link failed for businessGuid=${businessGuid}`), {
            extra: {
              businessGuid,
              error,
              metadata,
            },
          });
        }

        // Workaround to solve https://linear.app/highbeam/issue/HB-1378/double-plaid-notification-toasts
        // Once we move to a new dropdown component that allows items to handle their own loading/error states
        // we can remove this
        if (!linkToken) return;

        notify("info", "Trouble connecting your account?", {
          action: {
            text: "Explore common issues",
            onClick: () => {
              window.open("https://plaid.com/trouble-connecting/", "_blank")?.focus();
            },
          },
        });
      },
      token: linkToken,
      env: env.PLAID_ENVIRONMENT,
      ...(oauthFlowNeedsCompletion && { receivedRedirectUri: window.location.href }),
    };
  }, [
    linkToken,
    oauthFlowNeedsCompletion,
    highbeamApi.plaid,
    businessGuid,
    isNewConnection,
    finishItemByStep,
    onComplete,
    segmentTrack,
    setDuplicatePlaidConnectionGuid,
    setIsPlaidExistingAccountModalOpen,
  ]);

  const { open: openPlaid, ready: isPlaidReady } = usePlaidLink(config);

  // We want to set the link token in case it is an oauth flow
  const openHighbeamPlaid = () => {
    setExistingLinkToken(linkToken!); // Should always be set if `isPlaidReady` is true
    if (!oauthFlowNeedsCompletion) {
      setPostPlaidOauthRedirectPath(location.pathname);
    }
    openPlaid();
  };

  return {
    openPlaid: openHighbeamPlaid,
    isPlaidReady: isPlaidReady,
  };
};

export default useHighbeamPlaidLink;
