import logo from "assets/highbeam-logo.svg";
import loadingAnimation from "assets/loading-highbeam.gif";
import { useDebugSettings } from "providers/DebugSettingsProvider";
import Text from "ui/typography/Text";

import styles from "./LoadingPage.module.scss";

/**
 * This full-page loading page should only be used when the entire app is loading. If an individual
 * component is loading, a local loading state is more appropriate.
 */

type Props = {
  location: string;
  text?: string;
  showLoadingAnimation?: boolean;
};

const LoadingPage: React.FC<Props> = ({
  location,
  text = "Loading...",
  showLoadingAnimation = true,
}) => {
  const { showDebugMessages } = useDebugSettings();

  return (
    <div className={styles.container}>
      <img src={logo} className={styles.logo} alt="logo" />

      <div className={styles.animation}>
        {showLoadingAnimation && (
          <img className={styles.icon} src={loadingAnimation} alt="Loading" />
        )}
        <Text size={14} align="center" weight="medium">
          {text}
        </Text>
        {showDebugMessages && (
          <Text size={14} align="center" weight="medium">
            {`[Dev only] Loading from ${location}`}
          </Text>
        )}
      </div>
    </div>
  );
};

export default LoadingPage;
