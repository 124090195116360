import dayjs, { Dayjs } from "dayjs";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  getIsValidDayInputOptions,
  isValidDayInput,
  isValidMonthInput,
  isValidYearInput,
} from "utils/date/validation";

import Fieldset from "../Fieldset";
import TextInput from "../TextInput";

type InputValues = {
  month: string;
  day: string;
  year: string;
};

type Props = {
  value: Dayjs | null;
  onChange: (date: Dayjs | null, values: InputValues) => void;
  disabled?: boolean;
  fieldsetErrorMessage?: string;
};

const DateInputFieldset: FC<Props> = ({
  disabled,
  value,
  onChange: onChangeProp,
  fieldsetErrorMessage,
}) => {
  const [month, setMonth] = useState<string>(value ? value.format("MM") : "");
  const [day, setDay] = useState<string>(value ? value.format("DD") : "");
  const [year, setYear] = useState<string>(value ? value.format("YYYY") : "");

  const [previousValue, setPreviousValue] = useState<Dayjs | null>(value);

  // Update input values when the value prop changes outside of this component's `onChange` handler.
  useEffect(() => {
    const previousValueFormatted = previousValue ? previousValue.format("YYYY-MM-DD") : "";
    const valueFormatted = value ? value.format("YYYY-MM-DD") : "";

    if (previousValueFormatted !== valueFormatted) {
      const formattedInputValues = `${year}-${month}-${day}`;
      if (formattedInputValues !== valueFormatted) {
        setMonth(value ? value.format("MM") : "");
        setDay(value ? value.format("DD") : "");
        setYear(value ? value.format("YYYY") : "");
      }
    }

    setPreviousValue(value);
  }, [value, previousValue, month, day, year]);

  const onChange = useCallback(
    (monthInput: string, dayInput: string, yearInput: string) => {
      const inputValues = { month: monthInput, day: dayInput, year: yearInput };
      if (
        isValidMonthInput(monthInput) &&
        isValidDayInput(dayInput, getIsValidDayInputOptions(monthInput, yearInput)) &&
        isValidYearInput(yearInput)
      ) {
        const date = dayjs(`${yearInput}-${monthInput}-${dayInput}`, "YYYY-MM-DD", true);
        onChangeProp(date, inputValues);
        setPreviousValue(date); // Prevents `useEffect` from updating to the new value.
      } else {
        onChangeProp(null, inputValues);
        setPreviousValue(null); // Prevents `useEffect` from updating to the new value.
      }
    },
    [onChangeProp]
  );

  const [monthError, setMonthError] = useState<string>();
  const [dayError, setDayError] = useState<string>();
  const [yearError, setYearError] = useState<string>();

  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const shouldShowFieldsetError =
    Boolean(fieldsetErrorMessage) && !Boolean(monthError || dayError || yearError);

  return (
    <Fieldset
      width="wide"
      columns={3}
      errorMessage={shouldShowFieldsetError ? fieldsetErrorMessage : undefined}
    >
      <TextInput
        label="Month"
        value={month}
        onChange={(val: string) => {
          setMonth(val.replace(/\D/, ""));
          onChange(val, day, year);

          const isValidMonth = isValidMonthInput(val);

          // Clear error if applicable
          if (monthError && isValidMonth) {
            setMonthError(undefined);
          }

          // Auto-focus next input
          if (val.length === 2 && isValidMonth) {
            dayRef.current?.focus();
          }
        }}
        onBlur={(e) => {
          if (!isValidMonthInput(e.target.value)) {
            setMonthError("Invalid month");
          } else {
            if (e.target.value.length === 1) {
              setMonth(`0${e.target.value}`);
            }
          }
        }}
        hasError={shouldShowFieldsetError || Boolean(monthError)}
        errorMessage={monthError ? monthError : undefined}
        disabled={disabled}
        maxLength={2}
        ref={monthRef}
      />

      <TextInput
        label="Day"
        value={day}
        onChange={(val: string) => {
          setDay(val.replace(/\D/, ""));
          onChange(month, val, year);

          const isValidDay = isValidDayInput(val, getIsValidDayInputOptions(month, year));

          // Clear error if applicable
          if (dayError && isValidDay) {
            setDayError(undefined);
          }

          // Auto-focus next input
          if (val.length === 2 && isValidDay) {
            yearRef.current?.focus();
          }
        }}
        onBlur={(e) => {
          if (!isValidDayInput(e.target.value, getIsValidDayInputOptions(month, year))) {
            setDayError("Invalid day");
          } else {
            if (e.target.value.length === 1) {
              setDay(`0${e.target.value}`);
            }
          }
        }}
        onKeyDown={(e) => {
          if (day.length === 0) {
            if (e.key === "Backspace") {
              monthRef.current?.focus();
            }
          }
        }}
        hasError={shouldShowFieldsetError || Boolean(dayError)}
        errorMessage={dayError ? dayError : undefined}
        disabled={disabled}
        maxLength={2}
        ref={dayRef}
      />

      <TextInput
        label="Year"
        value={year}
        onChange={(val: string) => {
          setYear(val.replace(/\D/, ""));
          onChange(month, day, val);

          const isValidYear = isValidYearInput(val);

          if (yearError && isValidYear) {
            setYearError(undefined);
          }

          if (val.length === 2 && isValidYear) {
            yearRef.current?.focus();
          }

          // Validate `day` in case the year causes the day to be invalid during a leap year.
          if (!isValidDayInput(day, getIsValidDayInputOptions(month, val))) {
            setDayError("Invalid day");
          } else {
            setDayError(undefined);
          }
        }}
        onBlur={(e) => {
          if (!isValidYearInput(e.target.value)) {
            setYearError("Invalid year");
          }
        }}
        onKeyDown={(e) => {
          if (year.length === 0) {
            if (e.key === "Backspace") {
              dayRef.current?.focus();
            }
          }
        }}
        hasError={shouldShowFieldsetError || Boolean(yearError)}
        errorMessage={yearError ? yearError : undefined}
        disabled={disabled}
        maxLength={4}
        ref={yearRef}
      />
    </Fieldset>
  );
};

export default DateInputFieldset;
