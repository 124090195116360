import DashboardPage from "components/layouts/DashboardPage";
import IncomingPaymentsTable from "modules/incoming-payments/components/IncomingPaymentsTable";
import useIncomingPayments from "modules/incoming-payments/queries/useIncomingPayments";
import { FC } from "react";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

type Props = {
  unitCoDepositAccountId: string;
};

const AccountDetailsIncomingPaymentsSection: FC<Props> = ({ unitCoDepositAccountId }) => {
  const showIncomingPayments = useFeatureFlag("INCOMING_PAYMENTS");

  const incomingPayments = useIncomingPayments({ accountId: unitCoDepositAccountId });

  if (!showIncomingPayments || incomingPayments.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Incoming</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <IncomingPaymentsTable accountId={unitCoDepositAccountId} />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default AccountDetailsIncomingPaymentsSection;
