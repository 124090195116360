import classNames from "classnames";

import styles from "./Content.module.scss";

// `onSubmit` & `id` are required if element is a form
type ContentAsFormProps = React.PropsWithChildren<{
  element: "form";
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  id: string;
}>;

type ContentAsDivProps = React.PropsWithChildren<{
  element?: "div";
}>;

type Props = (ContentAsFormProps | ContentAsDivProps) & {
  size?: "standard" | "small";
  className?: string;
};

const Content: React.FC<Props> = ({ children, size = "standard", className, ...props }) => {
  const sharedClassName = classNames(styles.content, size === "small" && styles.small, className);

  return props.element === "form" ? (
    <form className={sharedClassName} onSubmit={props.onSubmit} id={props.id}>
      {children}
    </form>
  ) : (
    <div className={sharedClassName}>{children}</div>
  );
};

export default Content;
