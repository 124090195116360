import { useController } from "react-hook-form";
import usePaymentEnabledBankAccountOptions from "resources/bank-accounts/queries/usePaymentEnabledBankAccountOptions";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Dropdown from "ui/inputs/Dropdown";
import Helper from "ui/inputs/Helper";

import { useDrawdownAmountFormContext } from "../context/DrawdownProvider";

const DrawdownToBankAccountDropdown = () => {
  const transferOptions = usePaymentEnabledBankAccountOptions();
  const { control } = useDrawdownAmountFormContext();

  const { field } = useController({
    control: control,
    name: "drawdownToBankAccountOption",
  });

  return (
    <>
      <Dropdown
        disabled={transferOptions.length === 1}
        id="to"
        label="To"
        isSearchable={false}
        options={transferOptions}
        {...field}
      />

      {field.value && (
        <Helper>
          Account balance:{" "}
          <MoneyAmount weight="medium" cents={field.value.availableBalanceInCents} />
        </Helper>
      )}
    </>
  );
};

export default DrawdownToBankAccountDropdown;
