import { ErrorBoundary } from "@sentry/react";
import DashboardLayout from "components/layouts/Dashboard";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import React, { PropsWithChildren } from "react";

import NotFoundPage from "../../pages/NotFoundPage";

export class NotFoundError extends Error {}

const AuthenticatedRouterErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => (
  <ErrorBoundary
    fallback={(error) => {
      if (!(error instanceof NotFoundError)) throw error;
      return (
        <DashboardLayout>
          <DashboardHeader />
          <NotFoundPage error={error} />
        </DashboardLayout>
      );
    }}
  >
    {children}
  </ErrorBoundary>
);

export default AuthenticatedRouterErrorBoundary;
