import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import usePaymentEnabledBankAccountOptions from "resources/bank-accounts/queries/usePaymentEnabledBankAccountOptions";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import { HighbeamBankAccountTransferOption } from "utils/transfers";
import { z } from "zod";

const schema = z.object({
  amount: z.number().positive(),
  drawdownFromCapitalAccount: z.custom<CapitalAccountSummaryRep.Complete>(
    (value) => Boolean(value),
    "Please select a capital account."
  ),
  drawdownToBankAccountOption: z.custom<HighbeamBankAccountTransferOption>(
    (value) => Boolean(value),
    "Please select a bank account."
  ),
});

export type DrawdownAmountFormInputs = z.infer<typeof schema>;

const useDrawdownAmountForm = () => {
  const transferOptions = usePaymentEnabledBankAccountOptions();
  const capitalAccountSummary = useCapitalAccountSummaryContext();

  return useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: 0,
      drawdownFromCapitalAccount: capitalAccountSummary,
      drawdownToBankAccountOption: transferOptions[0],
    },
  });
};

export default useDrawdownAmountForm;
