import { useSuspenseQuery } from "@tanstack/react-query";

import useBusinessMembersQueryOptions from "./useBusinessMembersQueryOptions";

const useBusinessMembers = () => {
  const { data } = useSuspenseQuery(useBusinessMembersQueryOptions());
  return data;
};

export default useBusinessMembers;
