import DashboardPage from "components/layouts/DashboardPage";
import { Link } from "react-router-dom";
import useOpenBankAccounts from "resources/bank-accounts/queries/useOpenBankAccounts";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useIsAllowedToCreateBankAccounts from "utils/permissions/useIsAllowedToCreateBankAccounts";

import BankAccountsTable from "../../components/BankAccountsTable";

const ManageAccountsButton = () => {
  const isAllowedToCreateBankAccounts = useIsAllowedToCreateBankAccounts();

  return (
    <Link to="/accounts/manage-accounts">
      <Button
        variant="tertiary"
        disabled={!isAllowedToCreateBankAccounts}
        tooltip={
          !isAllowedToCreateBankAccounts && (
            <Text size={14}>You don’t have permission to create bank accounts.</Text>
          )
        }
      >
        Manage accounts
      </Button>
    </Link>
  );
};

const BankAccountsSection = () => {
  // NB(alex): Hack until we have empty states. Matches the query in `BankAccountsTable`.
  const bankAccounts = useOpenBankAccounts();

  if (bankAccounts.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Bank accounts</DashboardPage.Section.HeaderHeading>

        <DashboardPage.Section.HeaderEnd>
          <ManageAccountsButton />
        </DashboardPage.Section.HeaderEnd>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <BankAccountsTable />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default BankAccountsSection;
