import MetadataList from "components/MetadataList";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import useCapitalAccountAutoPayConnectedStoreTypes from "resources/capital-accounts/queries/useCapitalAccountAutoPayConnectedStoreTypes";
import getRemittanceRate from "resources/capital-accounts/utils/getRemittanceRate";
import ConnectedStoresAvatarGroup from "resources/connected-stores/components/ConnectedStoresAvatarGroup";

import styles from "./CapitalAccountCardAutoPayConnectedStoresItem.module.scss";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountCardAutoPayConnectedStoresItem: FC<Props> = ({ capitalAccountSummary }) => {
  // NB(alex): The rutter platform should always be a connected store, but we have to handle other cases because the ts types don't line up. Would be nice to fix this on the backend, but this works for now.
  const autoPayConnectedStoreTypes = useCapitalAccountAutoPayConnectedStoreTypes(
    capitalAccountSummary.guid
  );

  const remittanceRate = getRemittanceRate(capitalAccountSummary) ?? 0;
  const remittancePercent = remittanceRate * 100;

  if (autoPayConnectedStoreTypes.length === 0) {
    return null;
  }

  return (
    <MetadataList.Item label="Auto-paying from">
      <div className={styles.avatarGroupContainer}>
        <ConnectedStoresAvatarGroup
          connectedStoreTypes={autoPayConnectedStoreTypes}
          isGrey={remittancePercent === 0}
          size="sm"
        />
      </div>
    </MetadataList.Item>
  );
};

export default CapitalAccountCardAutoPayConnectedStoresItem;
