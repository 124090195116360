import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import Flexpane from "ui/overlay/Flexpane";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

import BankAccountDetail from "./BankAccountDetail";
import CounterpartyDetail from "./CounterpartyDetail";
import PaymentDetailBubble from "./PaymentDetailBubble";

const PaymentInfoFlexpaneToFromSection = () => {
  const { payment } = usePaymentInfoFlexpaneContext();
  const positive = payment.attributes.direction === "Debit";

  return (
    <Flexpane.Section>
      <TransactionFlexpaneParties
        from={positive ? <CounterpartyDetail /> : <BankAccountDetail />}
        status={<PaymentDetailBubble />}
        to={positive ? <BankAccountDetail /> : <CounterpartyDetail />}
      />
    </Flexpane.Section>
  );
};

export default PaymentInfoFlexpaneToFromSection;
