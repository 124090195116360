import { Card } from "@highbeam/unit-node-sdk";
import useBusinessMember from "resources/business-members/queries/useBusinessMember";

import useHasPermission from "./useHasPermission";

const useIsAllowedToActivateCard = (card: Card) => {
  const businessMember = useBusinessMember();
  const isAllowedToActivateAnyCard = useHasPermission(["card:activate", "card:activateAny"]);
  const isOnlyAllowedToActivateOwnCard = useHasPermission("card:activateOwn");

  const cardBelongsToAuthenticatedBusinessMember = Boolean(
    businessMember && card.attributes.tags.businessMemberGuid === businessMember.guid
  );

  return (
    isAllowedToActivateAnyCard ||
    (isOnlyAllowedToActivateOwnCard && cardBelongsToAuthenticatedBusinessMember)
  );
};

export default useIsAllowedToActivateCard;
