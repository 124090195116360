import { captureException } from "@sentry/react";
import env from "env";
import { useEffect, useState } from "react";
import { CARDS_QUERY_KEY } from "resources/cards/queries/useCardsQueryOptions";
import useGetUnitCoSensitiveTokenWithMfa from "resources/unit-co-customer-token/hooks/useGetUnitCoSensitiveTokenWithMfa";
import colors from "styles/colors";
import { notify } from "ui/feedback/Toast";
import { getBaseVgsCss } from "utils/cards";
import useImportScript from "utils/customHooks/useImportScript";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

// JS library imported in hook below
declare const VGSCollect: any;

const VGS_INPUT_CSS = {
  ...getBaseVgsCss(),
  "letter-spacing": "0.02em",
  color: colors.grey[600],
};

export const useSetPinModal = (
  handleClose: (reload: boolean) => void,
  cardName: string | null,
  cardId: string
) => {
  // NB(alex): Still experimenting with this pattern. The idea here is to be able to refresh any queries related to a resource group (cards) and then a specific resource (card).
  const refreshCardQueries = useRefreshQuery([CARDS_QUERY_KEY, cardId]);

  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [vgsForm, setVgsForm] = useState<any>(null);

  const getUnitCoSensitiveTokenWithMfa = useGetUnitCoSensitiveTokenWithMfa({
    scopes: ["CARDS_SENSITIVE_WRITE"],
  });

  const vgsLoaded = useImportScript(
    `https://js.verygoodvault.com/vgs-collect/2.9.0/vgs-collect.js`
  );

  const createPin = async () => {
    if (!vgsForm) {
      return;
    }
    setIsLoading(true);
    setFormError(undefined);
    try {
      const sensitiveToken = await getUnitCoSensitiveTokenWithMfa();

      vgsForm.submit(
        `/cards/${cardId}/secure-data/pin`,
        {
          // This converts the dot-separated field name strings into a JSON object
          mapDotToObject: "true",
          headers: {
            "Content-Type": "application/vnd.api+json",
            Authorization: `Bearer ${sensitiveToken}`,
          },
        },
        (status: any, _data: any) => {
          setIsLoading(false);
          if (status === 200) {
            // hacky fix for VGS postMessage error, see PR#996
            setTimeout(() => {
              handleClose(true);
              refreshCardQueries();
              notify("success", `PIN set for "${cardName}"`);
            }, 100);
          } else {
            notify("error", "Failed to set PIN");
            captureException(
              new Error(`VGS returned with status ${status} and data ${JSON.stringify(_data)}`)
            );
          }
        },
        (errors: any) => {
          setIsLoading(false);
          if (errors) {
            if (errors["data.attributes.pin"] && errors["data.attributes.pin"]["isEmpty"]) {
              setFormError("Please enter a PIN code to continue.");
            } else {
              setFormError("Please make sure the PIN is 4 digits.");
            }
          }
        }
      );
    } catch (e) {
      notify("error", "Failed to set PIN");
      captureException(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (vgsLoaded && !vgsForm) {
      const form = VGSCollect.create(env.UNIT_VGS_VAULT_ID, env.UNIT_VGS_ENVIRONMENT, () => {});
      form.on("enterPress", () => createPin());
      const ccPin = form.field("#cc-pin", {
        type: "password",
        name: "data.attributes.pin",
        placeholder: "Enter PIN",
        maxLength: 4,
        validations: ["required", "/^([0-9]{4})$/"],
        css: VGS_INPUT_CSS,
      });
      ccPin.promise.then(() => {
        setVgsForm(form);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vgsForm, vgsLoaded]);

  const handleReset = () => {
    setIsLoading(false);
    setFormError(undefined);
    setVgsForm(null);
  };

  return {
    createPin,
    handleReset,
    isLoading,
    formError,
  };
};
