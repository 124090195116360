import classNames from "classnames";
import MetadataList from "components/MetadataList";
import { ComponentProps, FC } from "react";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";

import styles from "./TransactionFlexpaneDetails.module.scss";

const TransactionFlexpaneDetails: FC<JSX.IntrinsicElements["div"]> = ({
  children,
  className,
  ...divProps
}) => {
  return (
    <div className={classNames(className, styles.container)} {...divProps}>
      {children}
    </div>
  );
};

const TransactionFlexpaneDetailsHeading: FC<ComponentProps<typeof Heading3>> = (props) => {
  return <Heading3 {...props} />;
};

const TransactionFlexpaneDetailsMetadataList: FC<ComponentProps<typeof MetadataList>> = (props) => (
  <MetadataList {...props} />
);

const TransactionFlexpaneDetailsMetadataListItem: FC<ComponentProps<typeof MetadataList.Item>> = ({
  children,
  ...metadataListItemProps
}) => {
  return (
    <MetadataList.Item {...metadataListItemProps}>
      <Text size={14} weight="medium">
        {children}
      </Text>
    </MetadataList.Item>
  );
};

export default Object.assign(TransactionFlexpaneDetails, {
  Heading: TransactionFlexpaneDetailsHeading,
  MetadataList: Object.assign(TransactionFlexpaneDetailsMetadataList, {
    Item: TransactionFlexpaneDetailsMetadataListItem,
  }),
});
