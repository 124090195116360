import React, { ReactElement } from "react";
import Text from "ui/typography/Text";

import styles from "./withLabel.module.scss";

type Props = {
  onClick: (evt: React.MouseEvent<HTMLInputElement>) => void;
  component: ReactElement;
  label?: string;
};

const WithLabel: React.FC<Props> = ({ component, label, onClick }) => (
  <div className={styles.container} onClick={onClick}>
    {component}
    {label && (
      <Text size={14} weight="medium">
        {label}
      </Text>
    )}
  </div>
);

export default WithLabel;
