import { ErrorBoundary } from "@sentry/react";
import ErrorText from "components/error/ErrorText";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import AddConnectionModal from "pages/AddConnectionModal";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToNavigateToFinancialPartnersPage from "utils/permissions/navigation/useIsAllowedToNavigateToFinancialPartnersPage";

import ExternalBankAccountsSection from "./ExternalBankAccountsSection";
import ExternalCardsSection from "./ExternalCardsSection";

const SettingsFinancialPartnersPageContent = () => {
  const [addConnectionModalVariant, setAddConnectionModalVariant] =
    useState<PlaidLinkTokenRep.AccountType>();
  return (
    <>
      {addConnectionModalVariant && (
        <AddConnectionModal
          accountType={addConnectionModalVariant}
          onClose={() => setAddConnectionModalVariant(undefined)}
        />
      )}

      <DashboardPage.Header>
        <DashboardPage.Header.BackCaret to="/settings" />
        <DashboardPage.Header.Title>Financial partners</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <ErrorBoundary
        fallback={({ error }) => (
          <DashboardPage.Section>
            <ErrorText error={error} />
          </DashboardPage.Section>
        )}
      >
        <ExternalBankAccountsSection setAddConnectionModalVariant={setAddConnectionModalVariant} />

        <ExternalCardsSection setAddConnectionModalVariant={setAddConnectionModalVariant} />
      </ErrorBoundary>
    </>
  );
};

const SettingsFinancialPartnersPage = () => {
  const isAllowedToNavigateToFinancialPartnersPage =
    useIsAllowedToNavigateToFinancialPartnersPage();

  if (!isAllowedToNavigateToFinancialPartnersPage) {
    return <Navigate to="/settings" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/settings">Settings</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Financial partners</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <SettingsFinancialPartnersPageContent />
      </DashboardPage>
    </>
  );
};

export default SettingsFinancialPartnersPage;
