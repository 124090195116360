import LoadingPage from "pages/LoadingPage";
import { useRecoilValue } from "recoil";
import auth0ClientState from "state/auth/auth0Client";

/**
 * Redirects the browser to Auth0 to handle sign in. Auth0 requires that there's a dedicated path
 * that will always redirect to Auth0. For more information, see
 * https://auth0.com/docs/universal-login/configure-default-login-routes.
 */
const SignInPage = () => {
  const auth0 = useRecoilValue(auth0ClientState);

  void auth0.loginWithRedirect();

  return <LoadingPage location={SignInPage.name} />;
};

export default SignInPage;
