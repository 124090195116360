import MetadataList from "components/MetadataList";
import Section from "ui/data-display/Section";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import { BaseHighbeamTransaction } from "utils/types/transactionsTypes";

type Props = {
  transaction: BaseHighbeamTransaction;
};

const BaseTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>
      {transaction.longMethodName ? `${transaction.longMethodName} details` : "Transaction details"}
    </Heading3>
    <MetadataList>
      <MetadataList.Item label="Description">
        <Text size={14} weight="medium">
          {transaction.summary}
        </Text>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default BaseTransactionDetailsSection;
