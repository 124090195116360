import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";

import styles from "../views/ReviewLineOfferViews.module.scss";

type Props = {
  capitalAccountSummary: CapitalAccountRep.Complete;
};

const ChargeCardOfferSummary: FC<Props> = ({ capitalAccountSummary }) => (
  <Text size={14} color={colors.grey[500]}>
    <ul className={styles.agreementSummaryList}>
      <li>
        Your line limit will start at{" "}
        <MoneyAmount cents={capitalAccountSummary.details.limit} withCents={false} />{" "}
        {capitalAccountSummary.details.apr > 0 && (
          <>with a {capitalAccountSummary.details.apr * 100}% APR</>
        )}
        . Your line will have a 6-month draw period, after which you will be evaluated for a new
        line at a higher or lower limit.
      </li>

      <li>
        Within 45 days of activation, Highbeam must act as your primary operating account. i.e.
        sales payouts, payroll, inventory, marketing and card payments must flow through Highbeam.
      </li>
      <li>
        No new debt can be taken on without Highbeam approval. Taking on new debt without approval
        will cause the line to be paused.
      </li>
      <li>
        Highbeam will ask for financials from time to time, at least once a quarter, and should be
        submitted within two weeks upon request.
      </li>
    </ul>
  </Text>
);

const LineOfCreditOfferSummary: FC<Props> = ({ capitalAccountSummary }) => (
  <Text size={14} color={colors.grey[500]}>
    <ul className={styles.agreementSummaryList}>
      <li>
        Your line limit will start at{" "}
        <MoneyAmount cents={capitalAccountSummary.details.limit} withCents={false} /> with a{" "}
        {capitalAccountSummary.details.apr * 100}% APR.
      </li>
      <li>
        Please use Highbeam as primary operating account. That is, sales, payroll, inventory,
        marketing and cards must flow through your Highbeam bank accounts.
      </li>
      <li>You have to tell Highbeam if you are considering taking on additional financing.</li>
      <li>
        Highbeam will ask for financials from time to time, at least once a quarter. You will have
        two weeks upon request to provide those.
      </li>
    </ul>
  </Text>
);

const LineOfferSummarySection: FC<Props> = ({ capitalAccountSummary }) => (
  <div className={styles.agreementSummaryContent}>
    {capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly ? (
      <ChargeCardOfferSummary capitalAccountSummary={capitalAccountSummary} />
    ) : (
      <LineOfCreditOfferSummary capitalAccountSummary={capitalAccountSummary} />
    )}
  </div>
);

export default LineOfferSummarySection;
