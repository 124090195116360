import MultiStep from "components/layouts/MultiStep";
import Button from "ui/inputs/Button";

import ConnectTabAccountingSoftwareStep from "./ConnectTabAccountingSoftwareStep";
import ConnectTabUploadDocumentsStep from "./ConnectTabUploadDocumentsStep";

type ConnectAccountingPlatformsTabProps = {
  onClickNoMatchingPlatforms: () => void;
};

const ConnectTab: React.FC<ConnectAccountingPlatformsTabProps> = ({
  onClickNoMatchingPlatforms,
}) => (
  <MultiStep.Section>
    <ConnectTabAccountingSoftwareStep />

    <MultiStep.Section.Spacer />

    <Button variant="default" onClick={onClickNoMatchingPlatforms}>
      I don’t use any of these platforms
    </Button>

    <MultiStep.Section.Spacer />

    <ConnectTabUploadDocumentsStep />
  </MultiStep.Section>
);

export default ConnectTab;
