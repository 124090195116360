import DashboardPage from "components/layouts/DashboardPage";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalPromotionalAprLabel from "resources/capital-accounts/components/CapitalPromotionalAprLabel";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import styles from "./LineOfCreditDetailsSection.module.scss";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const LineOfCreditDetailsSection: React.FC<Props> = ({ capitalAccountSummary }) => {
  const isCapitalPromotionalRateEnabled = useFeatureFlag("CAPITAL_PROMOTIONAL_RATES");

  return (
    <div className={styles.detailsContainer}>
      <DashboardPage.Section.HeaderHeading weight="medium">
        Details
      </DashboardPage.Section.HeaderHeading>

      {capitalAccountSummary.details.apr > 0 && (
        <div className={styles.detailsRow}>
          {isCapitalPromotionalRateEnabled ? (
            <>
              <CapitalPromotionalAprLabel weight="regular" />
              <div className={styles.promotionalAprDetails}>
                <Text size={14} weight="medium">
                  10.00%
                </Text>
                <Text size={14} color={colors.grey[600]} weight="medium" lineThrough>
                  {`${capitalAccountSummary.details.apr * 100}%`}
                </Text>
              </div>
            </>
          ) : (
            <>
              <Text size={14}>APR (Fixed)</Text>
              <Text size={14} weight="medium">
                {capitalAccountSummary.details.apr * 100}%
              </Text>
            </>
          )}
        </div>
      )}
      <div className={styles.detailsRow}>
        <Text size={14}>Repayment term</Text>
        <Text size={14} weight="medium">
          {capitalAccountSummary.details.targetRepaymentDays} days
        </Text>
      </div>
      {capitalAccountSummary.details.lineType && (
        <div className={styles.detailsRow}>
          <Text size={14}>Line type</Text>
          <Text size={14} weight="medium">
            {capitalAccountSummary.details.lineType}
          </Text>
        </div>
      )}
      {capitalAccountSummary.details.securedStatus && (
        <div className={styles.detailsRow}>
          <Text size={14}>Collateral</Text>
          <Text size={14} weight="medium">
            {capitalAccountSummary.details.securedStatus}
          </Text>
        </div>
      )}
    </div>
  );
};

export default LineOfCreditDetailsSection;
