import { useRef, useState } from "react";
import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import usePlaidConnectionsByConnectionGuid from "resources/plaid-connections/queries/usePlaidConnectionsByConnectionGuid";
import ModalV2, { ModalRefValue } from "ui/overlay/ModalV2";
import Text from "ui/typography/Text";

import styles from "./AddConnectionModal.module.scss";
import AddNewInstitution from "./AddNewInstitution";
import { ConnectedIntegrationRow } from "./ConnectedIntegrationRow";
import { HeadUpsModalStep } from "./HeadsUpModalStep";
import { AddConnectionModalStep, getConnectionModalTitle } from "./utils";

type Props = {
  accountType: PlaidLinkTokenRep.AccountType;
  onClose: () => void;
  onComplete?: () => void;
};

const AddConnectionModal: React.FC<Props> = ({ accountType, onClose, onComplete }) => {
  const connections = usePlaidConnectionsByConnectionGuid();
  const [addConnectionModalConnectionGuid, setAddConnectionModalConnectionGuid] = useState<
    string | undefined
  >(undefined);

  const [step, setStep] = useState<AddConnectionModalStep>(
    AddConnectionModalStep.ADD_NEW_CONNECTION
  );
  const isCardPrimary = accountType === "CREDIT";
  const cardOrAccount = isCardPrimary ? "card" : "account";

  const renderContent = () => {
    switch (step) {
      case AddConnectionModalStep.ADD_NEW_CONNECTION:
        return (
          <div>
            <Text size={16} weight="bold">
              Add {accountType === "CREDIT" ? "a" : "an"} {cardOrAccount} from one of your existing
              logins
            </Text>
            {Array.from(connections.entries()).map((entry) => {
              const [key, value] = entry;
              return (
                <ConnectedIntegrationRow
                  institutionName={value.institutionName}
                  accountType={accountType}
                  key={key}
                  connectionGuid={key}
                  value={value}
                  bankAccounts={value.bankAccounts}
                  creditCards={value.creditCards}
                  isActive={value.isActive}
                  institutionId={value.institutionId}
                  goToNextStep={() => {
                    setStep(AddConnectionModalStep.HEADS_UP);
                  }}
                  setAddConnectionModalConnectionGuid={setAddConnectionModalConnectionGuid}
                />
              );
            })}

            <Text size={12} weight="regular" className={styles.orContainer}>
              <span>or</span>
            </Text>

            <AddNewInstitution accountType={accountType} onComplete={onComplete} />
          </div>
        );
      case AddConnectionModalStep.HEADS_UP:
        return (
          <HeadUpsModalStep
            accountType={accountType}
            addConnectionModalConnectionGuid={addConnectionModalConnectionGuid}
            onComplete={onComplete}
          />
        );
    }
  };

  const modalRef = useRef<ModalRefValue>(null);

  return (
    <ModalV2 isOpen onClose={onClose} modalRef={modalRef}>
      <ModalV2.Content size={step === AddConnectionModalStep.HEADS_UP ? "small" : "standard"}>
        <ModalV2.Content.Header
          heading={getConnectionModalTitle(step, isCardPrimary)}
          onClickClose={modalRef.current?.close}
        />

        <ModalV2.Content.Body>{renderContent()}</ModalV2.Content.Body>
      </ModalV2.Content>
    </ModalV2>
  );
};

export default AddConnectionModal;
