import { Card } from "@highbeam/unit-node-sdk";
import { Warning } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import { useCardLimitsModal } from "pages/CardLimitModal/state/cardLimitModalState";
import HighbeamAccountIcon from "resources/bank-accounts/components/HighbeamAccountIcon";
import useOpenBankAccountsByUnitCoDepositAccountId from "resources/bank-accounts/queries/useOpenBankAccountsByUnitCoDepositAccountId";
import BankAccountV2 from "ui/data-display/BankAccountV2";
import Banner from "ui/data-display/Banner";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import CardLimitBar from "./CardLimitBar";
import styles from "./CardLimits.module.scss";

type Props = {
  accountId: string;
  currentPurchase: number;
  totalLimit: number;
  cards: Card[];
};

const DepositAccountCardLimit: React.FC<Props> = ({
  accountId,
  currentPurchase,
  totalLimit,
  cards,
}) => {
  const chat = useChatWidget();
  const { open: openCardLimitsModal } = useCardLimitsModal();
  const openBankAccountsByUnitCoDepositAccountId = useOpenBankAccountsByUnitCoDepositAccountId();
  const account = openBankAccountsByUnitCoDepositAccountId.get(accountId);

  if (!account) {
    return null;
  }

  return (
    <Banner
      icon={<Warning />}
      color={"yellow"}
      title={
        <div className={styles.bannerTitle}>
          <Text size={16} weight="bold">
            You’re approaching the <MoneyAmount cents={totalLimit} size={16} withCents={false} />{" "}
            daily debit limit for
          </Text>
          <BankAccountV2
            bankIcon={<HighbeamAccountIcon highbeamTypeName={account.highbeamType.name} />}
            iconSize={20}
            accountName={account.name}
            accountNumber={account.accountNumber}
          />
        </div>
      }
      body={
        <>
          <Text size={16}>Associated debit cards:</Text>
          <ul className={styles.cardList}>
            {cards.map((card) => (
              <li key={card.id}>
                <Text size={16}>{card.attributes.tags.name}</Text>
              </li>
            ))}
          </ul>
          <CardLimitBar current={currentPurchase} total={totalLimit} />
        </>
      }
      footer={
        <>
          <Button
            variant="tertiary"
            onClick={() => {
              openCardLimitsModal({
                account: account,
                currentPurchase: currentPurchase,
              });
            }}
          >
            Learn more
          </Button>
          <Button
            variant="plain"
            onClick={() =>
              chat.message(
                "I'm approaching my debit card limit. Could I get more information on what I can do?"
              )
            }
          >
            Talk to us
          </Button>
        </>
      }
    />
  );
};

export default DepositAccountCardLimit;
