import equal from "fast-deep-equal";
import { ReactElement } from "react";

import styles from "./RadioCardSelect.module.scss";

type Props<TOptions extends ReadonlyArray<unknown>> = {
  options: TOptions;
  value: TOptions[number];
  onChange: (value: TOptions[number]) => void;
  children: (
    props: {
      option: TOptions[number];
      isSelected: boolean;
      onSelect: (value: TOptions[number]) => void;
    },
    index: number
  ) => ReactElement;
  rowGap?: 8 | 16 | 24 | 32;
};

const RadioCardSelect = <TOptions extends ReadonlyArray<unknown>>({
  options,
  children,
  value,
  onChange,
  rowGap = 16,
}: Props<TOptions>) => {
  return (
    <fieldset className={styles.container} style={{ rowGap }}>
      {options.map((option, index) =>
        children(
          {
            option: option,
            isSelected: equal(value, option),
            onSelect: onChange,
          },
          index
        )
      )}
    </fieldset>
  );
};

export default RadioCardSelect;
