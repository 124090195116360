import { useMutation } from "@tanstack/react-query";
import BillDocumentRep from "reps/BillDocumentRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import uploadFile from "utils/uploadFile";

import { useRefreshBillDocumentsQueries } from "../queries/useBillDocuments";

type Variables = {
  files: File[];
  billId: string;
  type: BillDocumentRep.DocumentType;
};

const useUploadBillDocumentsMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const refreshBillDocumentsQueries = useRefreshBillDocumentsQueries();

  return useMutation({
    mutationFn: async ({ files, billId, type }: Variables) => {
      const associatedBillDocuments = await Promise.all(
        files.map(async (file) => {
          const billDocument = await highbeamApi.billDocument.create({
            businessGuid: businessGuid,
            billId: billId,
            type: type,
            fileName: file.name,
          });

          await uploadFile(file, billDocument.signedDocumentUrl);

          return billDocument;
        })
      );

      return {
        associatedBillDocuments,
      };
    },
    onSuccess: async () => {
      await refreshBillDocumentsQueries();
    },
  });
};

export default useUploadBillDocumentsMutation;
