import { useSuspenseQuery } from "@tanstack/react-query";
import BillSummaryRep from "reps/BillSummaryRep";
import { SetNonNullable, Simplify } from "type-fest";
import IncorrectDataReceivedError from "utils/react-query/IncorrectDataReceivedError";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { useBillQueryOptions } from "./useBill";

// Experimental(alex): Pattern for overriding server reps with client-side validated reps. They should always be safe to use, but may not match the rep on the backend 100%.

export type ReadyForPaymentBill = Simplify<
  Omit<
    SetNonNullable<
      BillSummaryRep.Complete,
      | "amount"
      | "remainingAmount"
      | "invoiceDate"
      | "invoiceDueDate"
      | "invoiceNumber"
      | "payeeGuid"
    >,
    "isReadyForPayment"
  > & {
    isReadyForPayment: true;
  }
>;

export const useReadyForPaymentBillQueryOptions = (billId: string) => {
  const billQueryOptions = useBillQueryOptions(billId);

  return useQueryOptions({
    queryKey: [...billQueryOptions.queryKey, "ready-for-payment"],
    queryFn: async () => {
      const bill = await billQueryOptions.queryFn();

      if (!bill) {
        throw new RequiredButNotFoundError();
      }

      if (!bill.isReadyForPayment) {
        throw new IncorrectDataReceivedError(bill);
      }

      // We trust the backend sets the fields to be non-nullable. An alternative would be to validate with zod on the frontend if we didn't trust the backend.
      return bill as ReadyForPaymentBill;
    },
    retry: (failureCount, error) => {
      if (
        error instanceof RequiredButNotFoundError ||
        error instanceof IncorrectDataReceivedError
      ) {
        return false;
      }
      return failureCount < 2;
    },
    throwOnError: true,
  });
};

const useReadyForPaymentBill = (billId: string) => {
  const { data, error } = useSuspenseQuery(useReadyForPaymentBillQueryOptions(billId));
  if (error) {
    // NB(alex): Was running into an issue where the cached value would be returned even if there was an error.
    // This ensures an error is thrown if a user fills out all required fields, goes to the payment page, goes back, deletes a field, and clicks the browser's "forward" button.
    // I thought specifying `throwOnError: true` would do this, but apparently not.
    throw error;
  }
  return data;
};

export default useReadyForPaymentBill;
