import logoWithoutLabel from "assets/highbeam-logo-without-label.svg";
import IconWithTooltip from "components/common/IconWithTooltip";
import useAllBankAccounts from "resources/bank-accounts/queries/useAllBankAccounts";
import Address from "ui/data-display/Address";
import Text from "ui/typography/Text";
import { getCardAccountName, getCardBillingAddress } from "utils/cards";
import useIsAllowedToSeeAssociatedBankAccount from "utils/permissions/useIsAllowedToSeeAssociatedBankAccount";

import { useCardDetailsPageContext } from "../providers/CardDetailsPageProvider";

import CardBudgetV2 from "./CardBudget/CardBudgetV2";
import styles from "./CardDetailsInfo.module.scss";
import { convertUnitAddressToAddressComponentProp } from "./utils";

const AssociatedBankAccountField = () => {
  const { card } = useCardDetailsPageContext();
  const relatedAccounts = useAllBankAccounts();
  const accountName = getCardAccountName(card, relatedAccounts);

  return (
    <div className={styles.cardInfo}>
      <div className={styles.cardInfoTitle}>
        <Text size={14}>Associated account</Text>
      </div>
      <div className={styles.cardInfoContent}>
        <>
          <img src={logoWithoutLabel} className={styles.logo} alt="Highbeam logo" />
          {accountName}
        </>
      </div>
    </div>
  );
};

const DebitCardDetails = () => {
  const { card } = useCardDetailsPageContext();

  const address = getCardBillingAddress(card);
  const isCardArchived = card?.attributes.status === "ClosedByCustomer";

  const isAllowedToSeeAssociatedBankAccount = useIsAllowedToSeeAssociatedBankAccount();

  return (
    <div className={styles.details}>
      <div>
        <div className={styles.cardInfo}>
          <div className={styles.cardInfoTitle}>
            <Text size={14}>Billing address</Text>
            <IconWithTooltip
              content={
                "Your business address is used as billing address for all cards. To your business address go to Settings."
              }
            />
          </div>
          <div className={styles.cardInfoContent}>
            {address && (
              <Address
                address={convertUnitAddressToAddressComponentProp(address)}
                size={14}
                weight="medium"
              />
            )}
          </div>
        </div>

        {isAllowedToSeeAssociatedBankAccount && <AssociatedBankAccountField />}
      </div>

      {!isCardArchived && (
        <div className={styles.spendAndLimit}>
          <CardBudgetV2 />
        </div>
      )}
    </div>
  );
};

export default DebitCardDetails;
