import { ArrowRight } from "@phosphor-icons/react";
import dayjs from "dayjs";
import env from "env";
import { useState } from "react";
import UserReferralRep from "reps/ReferralLinkRep";
import useSignUpWithRedirect from "resources/auth/hooks/useSignUpWithRedirect";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import { SEGMENT_EVENTS, useTrackPageMount } from "utils/customHooks/useSegment";

import ExpiredReferral from "../ExpiredReferral";

import billDAAvatar from "./assets/billDA.jpeg";
import nikSharmaAvatar from "./assets/nikSharma.png";
import styles from "./Referral.module.scss";
import ReferralApy from "./ReferralApy/ReferralApy";
import ReferralBfcm from "./ReferralBfcm/ReferralBfcm";

type Props = {
  referral: UserReferralRep;
};

// To add new apy referral, use referral's slug as a key and avatar as value
// Use null as value for default icon image
export const APY_REFERRALS_SLUG_TO_AVATAR_MAP: { [key: string]: string | null } = {
  bill: billDAAvatar,
  "bill-april-2024": billDAAvatar,
  TrueOps: null,
};

// To add new bfcm referral, use referral's slug as a key and avatar as value
// Use null as value for default icon image
export const BFCM_REFERRALS_SLUG_TO_AVATAR_MAP: { [key: string]: string | null } = {
  "sharma-april-2024": nikSharmaAvatar,
  "sharma-bfcm-2024": nikSharmaAvatar,
};

const Referral: React.FC<Props> = ({ referral }) => {
  const signUpWithRedirect = useSignUpWithRedirect();
  const [ctaPending, setCtaPending] = useState(false);
  const acceptInvite = () => {
    setCtaPending(true);
    signUpWithRedirect({
      referralLinkSlug: referral.slug,
    });
  };

  useTrackPageMount(SEGMENT_EVENTS.REFERRAL_LINK_VISITED, { referralSlug: referral.slug });

  const referralExpiresAt = referral.expiresAt
    ? dayjs(referral.expiresAt).format("MM/DD/YY")
    : null;
  const hasExpired = referral.expiresAt ? dayjs(referral.expiresAt).isBefore(dayjs()) : false;

  if (referralExpiresAt && hasExpired) {
    return <ExpiredReferral referral={referral} />;
  }

  if (referral.slug in APY_REFERRALS_SLUG_TO_AVATAR_MAP) {
    return <ReferralApy referral={referral} />;
  }

  if (referral.slug in BFCM_REFERRALS_SLUG_TO_AVATAR_MAP) {
    return <ReferralBfcm referral={referral} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.brand}>
          <div className={styles.avatar} />
          <div className={styles.logo} />
        </div>

        <div className={styles.text}>{topMessage(referral)}</div>
        <Button
          variant="primary"
          className={styles.button}
          onClick={acceptInvite}
          disabled={ctaPending}
        >
          Join Highbeam
          <ArrowRight size={20} />
        </Button>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomColumn}>
          <div className={styles.text}>
            <Text size={14} weight="bold" className={styles.primary}>
              Want to learn more?
            </Text>
            <Text size={14} className={styles.secondary}>
              Visit our website to learn more about how Highbeam helps ecommerce businesses with
              banking, credit, and cash management.
            </Text>
          </div>
          <a
            className={styles.link}
            href={env.HIGHBEAM_MARKETING_SITE_URL}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="tertiary" className={styles.button} disabled={ctaPending}>
              Learn more
            </Button>
          </a>
        </div>
        <div className={styles.bottomColumn}>
          <div className={styles.text}>
            <Text size={14} weight="bold" className={styles.primary}>
              Want to chat?
            </Text>
            <Text size={14} className={styles.secondary}>
              Schedule a demo to learn more about our solutions and see if Highbeam is a fit for
              your business.
            </Text>
          </div>
          <a
            className={styles.button}
            href={env.CALENDLY_DEMO_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="tertiary" className={styles.button} disabled={ctaPending}>
              Schedule a call
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Referral;

function topMessage(referral: UserReferralRep) {
  return (
    <>
      <Text size={20} weight="bold" className={styles.primary}>
        {referral.senderName ? <>{referral.senderName} invited you</> : <>You’ve been invited</>} to
        join Highbeam.
      </Text>
      <Text size={14} className={styles.secondary}>
        Highbeam is the business banking solution designed for ecommerce brands.
        <br />
        Accept your invite and skip the waitlist.
      </Text>
    </>
  );
}
