import classNames from "classnames";
import colors from "styles/colors";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./Section.module.scss";

export const ModalSectionHeading: React.FC<TextProps> = ({ ...textProps }) => (
  <Text weight="bold" size={16} as="h2" {...textProps} />
);

export const ModalSectionSubheading: React.FC<TextProps> = ({ ...textProps }) => (
  <Text weight="medium" size={14} color={colors.grey[700]} as="h3" {...textProps} />
);

export const ModalSectionHeader: React.FC<JSX.IntrinsicElements["div"]> = ({
  className,
  children,
  ...divProps
}) => (
  <div className={classNames(styles.header, className)} {...divProps}>
    {children}
  </div>
);

const Section: React.FC<JSX.IntrinsicElements["div"]> = ({ className, children, ...divProps }) => (
  <div className={classNames(styles.section, className)} {...divProps}>
    {children}
  </div>
);

export default Section;
