import classNames from "classnames";
import React, { ReactNode } from "react";

import styles from "./SummaryArray.module.scss";
import SummaryArrayItem from "./SummaryArrayItem";

type Props = {
  children: ReactNode;
  className?: string;
};

const SummaryArray: React.FC<Props> = ({ children, className }) => (
  <div className={classNames(styles.container, className)}>{children}</div>
);

export default Object.assign(SummaryArray, {
  Item: SummaryArrayItem,
});
