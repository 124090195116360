import Dropdown from "ui/inputs/Dropdown";
import TextInput from "ui/inputs/TextInput";
import ModalV2 from "ui/overlay/ModalV2";
import { Address, stateOptions } from "utils/address";
import { useAddressAutocomplete } from "utils/customHooks/useAddressAutocomplete";

import { ValidationErrors } from "../utils";

import styles from "./ShippingAddress.module.scss";

type Props = {
  address: Address;
  setAddress: (address: Address) => void;
  validationErrors: ValidationErrors;
};

const ShippingAddress: React.FC<Props> = ({ address, setAddress, validationErrors }) => {
  const {
    streetAddressInput,
    autoCompleteHandleChange,
    autoCompleteHandleInputChange,
    placeId,
    addressSuggestions,
    isPlacePredictionsLoading,
  } = useAddressAutocomplete(address, setAddress);
  return (
    <ModalV2.Content.Body.Section className={styles.section}>
      <ModalV2.Content.Body.Section.Header>
        <ModalV2.Content.Body.Section.Header.Heading>
          Shipping address
        </ModalV2.Content.Body.Section.Header.Heading>
        <ModalV2.Content.Body.Section.Header.Subheading>
          We will send this card to the following address.
        </ModalV2.Content.Body.Section.Header.Subheading>
      </ModalV2.Content.Body.Section.Header>

      <div className={styles.inputGroup}>
        <Dropdown
          id="street-address"
          options={addressSuggestions}
          label="Street address"
          isLoading={isPlacePredictionsLoading}
          value={address.addressLine1}
          inputValue={streetAddressInput}
          onChange={autoCompleteHandleChange}
          onInputChange={autoCompleteHandleInputChange}
          onBlur={() => {
            if (placeId) {
              return;
            }
            setAddress({
              ...address,
              addressLine1: { label: streetAddressInput, value: streetAddressInput },
            });
          }}
          onClear={() => {
            setAddress({
              ...address,
              addressLine1: null,
            });
          }}
          isClearable
          hideCaret
          hasError={Boolean(validationErrors["addressLine1"])}
          errorMessage={validationErrors["addressLine1"]}
        />
      </div>

      <div className={styles.inputGroup}>
        <TextInput
          label="Address line 2"
          value={address.addressLine2}
          onChange={(addressLine2) => setAddress({ ...address, addressLine2 })}
        />
      </div>

      <div className={styles.inputRow}>
        <TextInput
          className={styles.inputWrapper}
          label="City"
          value={address.city}
          onChange={(city) => setAddress({ ...address, city })}
          hasError={Boolean(validationErrors.city)}
          errorMessage={validationErrors.city}
        />
        <div className={styles.inputWrapper}>
          <Dropdown
            id="state"
            onChange={(state) => setAddress({ ...address, state })}
            value={address.state}
            label="State"
            options={stateOptions}
            menuClassName={styles.menu}
            hasError={Boolean(validationErrors.state)}
            errorMessage={validationErrors.state}
          />
        </div>
        <TextInput
          className={styles.inputWrapper}
          label="ZIP"
          value={address.zipCode}
          onChange={(zipCode) => setAddress({ ...address, zipCode })}
          hasError={Boolean(validationErrors["zipCode"])}
          errorMessage={validationErrors["zipCode"]}
        />
      </div>
    </ModalV2.Content.Body.Section>
  );
};

export default ShippingAddress;
