import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import { FC } from "react";
import { Navigate } from "react-router-dom";
import Divider from "ui/data-display/Divider";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToNavigateToBusinessDetailsSettingsPage from "utils/permissions/navigation/useIsAllowedToNavigateToBusinessDetailsSettingsPage";

import BusinessAddressSection from "./BusinessAddressSection";
import BusinessDetailsSection from "./BusinessDetailsSection";

const SettingsBusinessPage: FC = () => {
  const isAllowedToNavigateToBusinessDetailsSettingsPage =
    useIsAllowedToNavigateToBusinessDetailsSettingsPage();

  if (!isAllowedToNavigateToBusinessDetailsSettingsPage) {
    return <Navigate to="/settings" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/settings">Settings</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Business information</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.BackCaret to="/settings" />
          <DashboardPage.Header.Title>Business information</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <BusinessDetailsSection />
        <Divider />
        <BusinessAddressSection />
      </DashboardPage>
    </>
  );
};

export default SettingsBusinessPage;
