import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import { z } from "zod";

const schema = z.object({
  name: z.string().min(1, { message: "This field is required." }),
});

export type EditCapitalAccountNameFormInputs = z.infer<typeof schema>;

const useEditCapitalAccountNameForm = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();

  return useForm<EditCapitalAccountNameFormInputs>({
    defaultValues: {
      name: capitalAccountSummary.name,
    },
    resolver: zodResolver(schema),
  });
};

export default useEditCapitalAccountNameForm;
