import { ArrowRight, CalendarPlus } from "@phosphor-icons/react";
import dayjs from "dayjs";
import env from "env";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import UserReferralRep from "reps/ReferralLinkRep";
import useSignUpWithRedirect from "resources/auth/hooks/useSignUpWithRedirect";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "../../Referral/Referral.module.scss";

type Props = {
  referral: UserReferralRep;
};

const ReferralContent: FC<Props> = ({ referral }) => {
  const [ctaPending, setCtaPending] = useState(false);
  const signUpWithRedirect = useSignUpWithRedirect();
  const acceptInvite = () => {
    setCtaPending(true);
    signUpWithRedirect({
      referralLinkSlug: referral.slug,
    });
  };
  const referralExpiresAt = referral.expiresAt
    ? dayjs(referral.expiresAt).format("MM/DD/YY")
    : null;
  const hasExpired = referral.expiresAt ? dayjs(referral.expiresAt).isBefore(dayjs()) : false;

  return (
    <>
      <Button
        variant="primary"
        className={styles.button}
        onClick={acceptInvite}
        isLoading={ctaPending}
      >
        Join Highbeam
        <ArrowRight size={20} />
      </Button>
      {referralExpiresAt && !hasExpired && (
        <div className={styles.expiresAt}>
          <CalendarPlus size={16} color={colors.grey[500]} />
          <Text size={12} color={colors.grey[500]}>
            Invitation expires {referralExpiresAt}
          </Text>
        </div>
      )}
      <div className={styles.bookDemo}>
        <Text size={14} color={colors.grey[600]} className={styles.noWrap}>
          Have questions?
        </Text>
        <Link
          className={styles.button}
          to={env.CALENDLY_DEMO_LINK}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="ghost">
            <Text size={14} color={colors.purple[500]} className={styles.noWrap}>
              Book a demo
            </Text>
          </Button>
        </Link>
      </div>
    </>
  );
};

export default ReferralContent;
