import CapitalAccountRep from "reps/CapitalAccountRep";

import useCapitalAccounts from "./useCapitalAccounts";

const useActiveCapitalAccountsWithChargeCard = (): CapitalAccountRep.Complete[] => {
  const capitalAccounts = useCapitalAccounts();

  return capitalAccounts.filter(
    ({ type, state }) =>
      (type === CapitalAccountRep.Type.ChargeCardOnly ||
        type === CapitalAccountRep.Type.ChargeCardAndCashAccess) &&
      state === CapitalAccountRep.State.Active
  );
};

export default useActiveCapitalAccountsWithChargeCard;
