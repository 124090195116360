import classNames from "classnames";
import { SetRequired } from "type-fest";
import Button from "ui/inputs/Button";

import styles from "./Footer.module.scss";

type SubmitButtonProps = SetRequired<React.ComponentProps<typeof Button>, "form"> & {
  isLoading?: boolean;
};

export const SubmitButton: React.FC<SubmitButtonProps> = ({ children, ...buttonProps }) => (
  <Button type="submit" variant="primary" {...buttonProps}>
    {children}
  </Button>
);

export const CancelButton: React.FC<React.ComponentProps<typeof Button>> = ({
  children = "Close",
  ...buttonProps
}) => (
  <Button type="button" variant="default" {...buttonProps}>
    {children}
  </Button>
);

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const Footer: React.FC<Props> = ({ children, className }) => (
  <div className={classNames(styles.footer, className)}>{children}</div>
);

export default Footer;
