import AccountLabel from "components/Accounts/AccountLabel";
import TransferBalance from "pages/TransferMoneyPage/TransferBalance";
import BankAccountRep from "reps/BankAccountRep";
import { getDollarsFromCents } from "utils/money";
import { HighbeamBankAccountTransferOption, Processor } from "utils/transfers";

// NB(alex): This is an anti-pattern and should be deleted once we have a composable `<Dropdown>` component.

const mapTransferOption = (
  account: BankAccountRep.Complete
): HighbeamBankAccountTransferOption => ({
  value: account.unitCoDepositAccountId,
  label: AccountLabel({ bankAccount: account }),
  description: TransferBalance({ balance: account.availableBalance }),
  type: account.type,
  availableBalanceInCents: account.availableBalance,
  availableBalanceInDollars: getDollarsFromCents(account.availableBalance),
  processor: Processor.Highbeam,
  accountName: account.name,
  guid: account.guid,
  supportsSendMoney: account.highbeamType.includeInSendMoney,
  supportsTransferMoney: true,
  supportsTransferMoneyExternally: account.highbeamType.supportsExternalTransfers,
  isPrimary: account.isPrimary,
  isThreadAccount: account.threadAccount,
  isDisabled: account.status !== BankAccountRep.Status.OPEN,
  isSameDayAchEnabled: account.depositProduct.sameDayAch,
});

export default mapTransferOption;
