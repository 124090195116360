import { useQuery } from "@tanstack/react-query";
import MetadataList from "components/MetadataList";
import { getCounterpartyIdFromRecurringPayment } from "resources/recurring-payments/utils/getCounterpartyIdFromRecurringPayment";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useUnitCoRecurringPayment from "resources/unit-co-recurring-payments/queries/useUnitCoRecurringPayment";
import FlexpaneSection from "ui/overlay/Flexpane/FlexpaneSection";
import CopyItemTooltip from "ui/overlay/Tooltip/CopyItemTooltip/CopyItemTooltip";
import Text from "ui/typography/Text";

import { useRecurringPaymentFlexpaneContext } from "../context/RecurringPaymentFlexpaneProvider";

const RecurringPaymentDetailsSection = () => {
  const { recurringPaymentId } = useRecurringPaymentFlexpaneContext();
  const recurringPayment = useUnitCoRecurringPayment(recurringPaymentId, { required: true });

  const unitApi = useUnitApi();

  const counterpartyId = getCounterpartyIdFromRecurringPayment(recurringPayment);

  const { data: counterparty } = useQuery({
    queryKey: ["counteryparty", { counterpartyId }],
    queryFn: async () => {
      const { data: counterparty } = await unitApi.counterparties.get(counterpartyId);
      return counterparty;
    },
  });

  if (!counterparty) {
    return null;
  }

  return (
    <>
      <FlexpaneSection.Heading>ACH details</FlexpaneSection.Heading>

      <MetadataList>
        <MetadataList.Item label="Routing number">
          <CopyItemTooltip textToCopy={counterparty.attributes.routingNumber}>
            <Text size={14} weight="medium">
              {counterparty.attributes.routingNumber}
            </Text>
          </CopyItemTooltip>
        </MetadataList.Item>
        <MetadataList.Item label="Account number">
          <CopyItemTooltip textToCopy={counterparty.attributes.accountNumber}>
            <Text size={14} weight="medium">
              {counterparty.attributes.accountNumber}
            </Text>
          </CopyItemTooltip>
        </MetadataList.Item>
        <MetadataList.Item label="Account type">
          <Text size={14} weight="medium">
            {counterparty.attributes.accountType}
          </Text>
        </MetadataList.Item>
        {"addenda" in recurringPayment.attributes && recurringPayment.attributes.addenda && (
          <MetadataList.Item label="Description">
            <Text size={14} weight="medium">
              {recurringPayment.attributes.addenda}
            </Text>
          </MetadataList.Item>
        )}
      </MetadataList>
    </>
  );
};

export default RecurringPaymentDetailsSection;
