import { ArrowsLeftRight } from "@phosphor-icons/react";
import amazonIcon from "assets/merchants/amazon.svg";
import paypalIcon from "assets/paypal-icon.svg";
import shopifyIcon from "assets/shopify-icon.svg";
import stripeIcon from "assets/stripe-icon.svg";
import { usePayoutInstructionsModal } from "pages/PayoutInstructionModal/state/payoutInstructionsModalState";
import { ConnectedStoreType } from "resources/connected-stores/types";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./ActivationPendingBannerItem.module.scss";

const getIcon = (connectedStoreType: ConnectedStoreType): React.ReactElement => {
  const iconSize = 18;
  const commonSizeProps = { width: iconSize, height: iconSize };
  switch (connectedStoreType) {
    case "Shopify":
      return <img {...commonSizeProps} src={shopifyIcon} alt="Shopify Icon" />;
    case "PayPal":
      return <img {...commonSizeProps} src={paypalIcon} alt="PayPal Icon" />;
    case "Stripe":
      return <img {...commonSizeProps} src={stripeIcon} alt="Stripe Icon" />;
    case "Amazon":
      return <img {...commonSizeProps} src={amazonIcon} alt="Amazon Icon" />;
  }
};

type Props = {
  connectedStoreType: ConnectedStoreType;
};

const ActivationPendingBannerItem: React.FC<Props> = ({ connectedStoreType }) => {
  const { open: openPayoutInstructionsModal } = usePayoutInstructionsModal();

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div>{getIcon(connectedStoreType)}</div>

        <div className={styles.textContainer}>
          <Text size={14} color={colors.grey[900]} weight="medium">
            Update your {connectedStoreType} payouts to flow through Highbeam
          </Text>
          <Text size={14} color={colors.grey[400]} weight="medium">
            Make sure {connectedStoreType} deposits flow through Highbeam
          </Text>
        </div>
      </div>
      <button
        className={styles.button}
        onClick={() => openPayoutInstructionsModal({ activeTab: connectedStoreType })}
      >
        <ArrowsLeftRight size={16} />
        Reroute payouts
      </button>
    </div>
  );
};

export default ActivationPendingBannerItem;
