export enum AddConnectionModalStep {
  ADD_NEW_CONNECTION,
  HEADS_UP,
}

export const getConnectionModalTitle = (step: AddConnectionModalStep, isCardPrimary?: boolean) => {
  switch (step) {
    case AddConnectionModalStep.ADD_NEW_CONNECTION:
      const vowel = isCardPrimary ? "a" : "an";
      const buttonType = isCardPrimary ? "card" : "account";
      return `Add ${vowel} ${buttonType}`;
    case AddConnectionModalStep.HEADS_UP:
      return "Heads up!";
  }
};

export const getActiveButtonLabel = (isCardPrimary: boolean, primaryMasks: string[]) => {
  const vowel = isCardPrimary ? "a" : "an";
  const buttonType = isCardPrimary ? "card" : "account";
  if (primaryMasks.length === 0) {
    return `Add ${vowel} ${buttonType} from this login`;
  } else {
    return `Add another ${buttonType} from this login`;
  }
};

export const getConnectedIntegrationSubheadingText = (
  primaryMasks: string[],
  secondaryMasks: string[],
  isCardPrimary: boolean
) => {
  const getSubheadingTextSecondHalf = () => {
    const join = secondaryMasks.length === 0 ? "" : "and ";
    if (primaryMasks.length === 0) {
      return "";
    }
    if (primaryMasks.length === 1) {
      return `${join}1 ${isCardPrimary ? "card" : "bank account"}`;
    }
    return `${join}${primaryMasks.length} ${isCardPrimary ? "cards" : "bank accounts"}`;
  };

  const secondaryDescription =
    secondaryMasks.length > 0 ? (isCardPrimary ? " bank accounts " : " cards ") : "";

  const secondaryMasksText =
    secondaryMasks.length <= 0 ? "" : secondaryMasks.map((mask) => `•• ${mask}`).join(", ");

  return `Connected with${secondaryDescription}${secondaryMasksText} ${getSubheadingTextSecondHalf()}`;
};
