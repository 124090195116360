import useAllBankAccounts from "resources/bank-accounts/queries/useAllBankAccounts";
import colors from "styles/colors";
import BankAccount from "ui/data-display/BankAccount";
import Text from "ui/typography/Text";
import { formatBankingDate } from "utils/date";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

import styles from "./PaymentInfoFlexpaneToFromSection.module.scss";

const BankAccountDetail = () => {
  const { payment } = usePaymentInfoFlexpaneContext();

  const bankAccounts = useAllBankAccounts();
  const bankAccountsByUnitId = new Map(
    bankAccounts.map((account) => [account.unitCoDepositAccountId, account])
  );
  const account = bankAccountsByUnitId.get(payment.relationships.account.data.id);

  return (
    <>
      <BankAccount accountName={account?.name || ""} className={styles.bankAccount} />
      {payment.type === "achPayment" && payment.attributes.settlementDate && (
        <Text size={14} color={colors.grey[500]}>
          {"Estimated " + formatBankingDate(payment.attributes.settlementDate)}
        </Text>
      )}
    </>
  );
};

export default BankAccountDetail;
