import Invoice from "components/Invoice";
import usePendingDrawdown from "resources/line-of-credit/queries/usePendingDrawdown";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import { usePendingDrawdownFlexpaneContext } from "../../context/PendingDrawdownFlexpaneProvider";

import styles from "./PendingDrawdownInvoice.module.scss";

const PendingDrawdownInvoice = () => {
  const { pendingDrawdownGuid } = usePendingDrawdownFlexpaneContext();
  const pendingDrawdown = usePendingDrawdown(pendingDrawdownGuid, { required: true });

  return (
    <div className={styles.container}>
      <Text size={16} color={colors.grey[900]} weight="bold">
        Invoice
      </Text>
      <Invoice invoiceUrl={pendingDrawdown.attachmentUrl} />
    </div>
  );
};

export default PendingDrawdownInvoice;
