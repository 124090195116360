import useSearchParamOption from "utils/searchParams/useSearchParamOption";
import { TabConfig } from "utils/tabs/useTabState";
import getObjectKeys from "utils/ts/getObjectKeys";

export const BILL_INFO_FORM_TAB_KEY = "bill-info-form-tab";

export const billInfoFormTabs = {
  "bill-info": { label: "Bill info" },
  "update-payee-details": { label: "Update payee" },
  "create-payee-details": { label: "Create payee" },
} satisfies TabConfig;

const useBillInfoFormTabs = () => {
  const [activeTab, setActiveTab] = useSearchParamOption(
    BILL_INFO_FORM_TAB_KEY,
    getObjectKeys(billInfoFormTabs),
    "bill-info"
  );

  return {
    tabs: billInfoFormTabs,
    activeTab: activeTab,
    setActiveTab: setActiveTab,
  };
};

export default useBillInfoFormTabs;
