import { captureException } from "@sentry/react";
import ErrorCard from "components/error/ErrorCard";
import React, { ReactNode } from "react";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useMountEffect from "utils/customHooks/useMountEffect";

import styles from "./ErrorPage.module.scss";
import ErrorPageHeader from "./ErrorPageHeader";

type Props = {
  readonly error?: Error;
  header?: ReactNode;
};

/**
 * This full-page error page should only be used when something goes terribly wrong and a partial
 * state can't even be shown.
 *
 * Keep this as vanilla as possible to avoid potential errors get thrown from within the error page.
 */
const ErrorPage: React.FC<Props> = ({ error, header = <ErrorPageHeader /> }) => {
  const isSuperusering = useIsSuperusering();

  useMountEffect(() => {
    if (isSuperusering) return;
    captureException(error);
  });

  return (
    <div className={styles.container}>
      {header}

      <ErrorCard error={error} />
    </div>
  );
};

export default ErrorPage;
