import { FC, ReactNode, createContext, useContext } from "react";

import useCapitalAccountSummary from "../queries/useCapitalAccountSummary";

// NB(alex): Experimental pattern for shared context.

type CapitalAccountSummaryContextValue = {
  capitalAccountGuid: string;
};

const CapitalAccountSummaryContext = createContext<CapitalAccountSummaryContextValue | null>(null);

type CapitalAccountSummaryProviderProps = {
  children: ReactNode;
  capitalAccountGuid: string;
};

export const CapitalAccountSummaryProvider: FC<CapitalAccountSummaryProviderProps> = ({
  children,
  capitalAccountGuid,
}) => {
  return (
    <CapitalAccountSummaryContext.Provider value={{ capitalAccountGuid }}>
      {children}
    </CapitalAccountSummaryContext.Provider>
  );
};

export const useCapitalAccountSummaryContext = () => {
  const context = useContext(CapitalAccountSummaryContext);
  if (context === null) {
    throw new Error(
      "useCapitalAccountSummaryContext must be used within a CapitalAccountSummaryProvider"
    );
  }
  return useCapitalAccountSummary(context.capitalAccountGuid, { required: true });
};
