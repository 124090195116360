import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const CATEGORIZED_RESOURCE_QUERY_KEY = "categorized-resource";

const useCategorizedResourceQueryOptions = (resourceGuid: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [CATEGORIZED_RESOURCE_QUERY_KEY],
    queryFn: () => {
      return highbeamApi.categorizedResource.get(businessGuid, resourceGuid);
    },
  });
};

export const useRefreshCategorizedResourceQuery = (resourceGuid: string) => {
  return useRefreshQuery(useCategorizedResourceQueryOptions(resourceGuid).queryKey);
};

const useCategorizedResource = (resourceGuid: string) => {
  const { data: categorizedResource } = useSuspenseQuery(
    useCategorizedResourceQueryOptions(resourceGuid)
  );

  return categorizedResource;
};

export default useCategorizedResource;
