import { captureMessage } from "@sentry/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import MetadataList from "components/MetadataList";
import dayjs from "dayjs";
import { FC } from "react";
import useLineOfCreditInterestFeeQueryOptions from "resources/line-of-credit/queries/useLineOfCreditInterestFeeQueryOptions";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

type Props = {
  capitalAccountGuid: string;
};

const CapitalAccountCardTodaysInterestItem: FC<Props> = ({ capitalAccountGuid }) => {
  const { data: interestFees } = useSuspenseQuery(
    useLineOfCreditInterestFeeQueryOptions({
      lineOfCreditGuid: capitalAccountGuid,
      since: dayjs(),
      untilInclusive: dayjs(),
    })
  );

  const todaysInterest = interestFees[0];

  if (!todaysInterest) {
    captureMessage(
      `Alert(alex): today's interest not found for capital account ${capitalAccountGuid}. (this should not happen)`
    );
    return null;
  }

  return (
    <MetadataList.Item label="Today’s interest">
      <MoneyAmount
        cents={todaysInterest.amount}
        size={14}
        color={colors.grey[800]}
        weight="medium"
      />
    </MetadataList.Item>
  );
};

export default CapitalAccountCardTodaysInterestItem;
