import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import BusinessMemberRep from "reps/BusinessMemberRep";
import BusinessRep from "reps/BusinessRep";
import useBusinessMember from "resources/business-members/queries/useBusinessMember";
import useBusiness from "resources/business/queries/useBusiness";
import isBusinessSwitcherOpen from "state/superuser/isBusinessSwitcherOpen";
import Avatar from "ui/data-display/Avatar";
import Text from "ui/typography/Text";
import useOnClickOutside from "use-onclickoutside";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";

import styles from "./DashboardUserMenu.module.scss";

type Props = {
  businessMember: BusinessMemberRep.Complete | null;
  business: BusinessRep.Complete | null;
  handleCloseUserMenu: () => void;
};

const UserMenuHeader: React.FC<Props> = ({ businessMember, handleCloseUserMenu, business }) => (
  <div className={styles.header}>
    <div className={styles.userInfo}>
      <Text size={14} weight="medium" as="p">
        {businessMember?.displayName || ""}
      </Text>
      <Text size={12} as="p">
        {business?.displayName || ""}
      </Text>
    </div>
    <button onClick={handleCloseUserMenu}>
      <Avatar color="purple-dark" size={32} initials={businessMember?.initials ?? ""} />
    </button>
  </div>
);

const DashboardUserMenu = () => {
  const navigate = useNavigate();

  const businessMember = useBusinessMember();
  const business = useBusiness();

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const canSuperuser = useCanSuperuser();
  const setIsBusinessSwitcherOpen = useSetRecoilState(isBusinessSwitcherOpen);

  useOnClickOutside(menuRef, () => setIsUserMenuOpen(false));

  const handleOpenUserMenu = () => {
    setIsUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setIsUserMenuOpen(false);
  };

  useKeyboardEvent(
    (event) => event.key === "Escape" && isUserMenuOpen,
    () => setIsUserMenuOpen(false),
    [isUserMenuOpen]
  );

  const menuItemClickHandler = (action: () => void) => () => {
    action();
    setIsUserMenuOpen(false);
  };

  return (
    <div className={styles.menu}>
      <button onClick={handleOpenUserMenu}>
        <Avatar color="purple-dark" size={32} initials={businessMember?.initials ?? ""} />
      </button>

      {isUserMenuOpen && (
        <div className={styles.dropdown} ref={menuRef}>
          <UserMenuHeader
            business={business}
            handleCloseUserMenu={handleCloseUserMenu}
            businessMember={businessMember}
          />
          {canSuperuser && (
            <button
              className={styles.item}
              onClick={menuItemClickHandler(() => setIsBusinessSwitcherOpen(true))}
            >
              [SUPERUSER] Business switcher
            </button>
          )}
          <div className={styles.item} onClick={menuItemClickHandler(() => navigate("/sign-out"))}>
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardUserMenu;
