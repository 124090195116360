import { DownloadSimple } from "@phosphor-icons/react";
import MetadataList from "components/MetadataList";
import React from "react";
import BankAccountRep from "reps/BankAccountRep";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Button from "ui/inputs/Button";
import CopyItemTooltip from "ui/overlay/Tooltip/CopyItemTooltip/CopyItemTooltip";
import Text from "ui/typography/Text";
import {
  getAccountType,
  getAccountPartnerBank,
  getPartnerBankAddress,
  getPartnerBankAddressCityState,
  maskAccountNumber,
} from "utils/account";

import styles from "./AccountInformation.module.scss";
import { NotAllowedToDownloadBankVerificationLetterTooltipContent } from "./constants";
import useDownloadBankVerificationLetterMutation from "./hooks/useDownloadBankVerificationLetterMutation";

type DomesticAccountInformationProps = {
  account: BankAccountRep.Complete;
  isAllowedToViewAccountNumbers: boolean;
};

const DomesticAccountInformation: React.FC<DomesticAccountInformationProps> = ({
  account,
  isAllowedToViewAccountNumbers,
}) => {
  const { mutate: downloadStatement, isPending: isDownloading } =
    useDownloadBankVerificationLetterMutation();

  return (
    <MetadataList>
      {account.highbeamType.showDetailsToUser && (
        <MetadataList.Item label="Routing number">
          <CopyItemTooltip textToCopy={account.routingNumber}>
            <Text size={14} weight="medium">
              {account.routingNumber}
            </Text>
          </CopyItemTooltip>
        </MetadataList.Item>
      )}
      {account.highbeamType.showDetailsToUser && (
        <MetadataList.Item label="Account number">
          {isAllowedToViewAccountNumbers ? (
            <CopyItemTooltip textToCopy={account.accountNumber}>
              <Text size={14} weight="medium">
                {account.accountNumber}
              </Text>
            </CopyItemTooltip>
          ) : (
            <Text size={14} weight="medium">
              {maskAccountNumber(account.accountNumber)}
            </Text>
          )}
        </MetadataList.Item>
      )}
      <MetadataList.Item label="Account type">
        <Text size={14} weight="medium">
          {getAccountType(account)}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item label="Partner bank">
        <Text size={14} weight="medium">
          {getAccountPartnerBank(account)}
        </Text>
        <Text size={14} weight="medium">
          {getPartnerBankAddress(account)}
        </Text>
        <Text size={14} weight="medium">
          {getPartnerBankAddressCityState(account)}
        </Text>
      </MetadataList.Item>
      {account.highbeamType.showDetailsToUser && (
        <MetadataList.Item label="Account verification letter">
          <Button
            variant="tertiary"
            size="xs"
            className={styles.letterButton}
            onClick={() => downloadStatement(account)}
            disabled={!isAllowedToViewAccountNumbers || isDownloading}
            tooltip={
              !isAllowedToViewAccountNumbers && (
                <NotAllowedToDownloadBankVerificationLetterTooltipContent />
              )
            }
          >
            {isDownloading ? <AnimatedSpinner /> : <DownloadSimple size={14} />}
            <Text>Download PDF</Text>
          </Button>
        </MetadataList.Item>
      )}
    </MetadataList>
  );
};

export default DomesticAccountInformation;
