import { FC, ReactNode, createContext, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import { isNotNull } from "utils/array";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import useDrawdownAmountForm from "../forms/useDrawdownAmountForm";
import useDrawdownInvoiceForm from "../forms/useDrawdownInvoiceForm";

type Step = {
  pathname: string;
  title: string;
};

type DrawdownContextState = {
  amountForm: ReturnType<typeof useDrawdownAmountForm>;
  invoiceForm: ReturnType<typeof useDrawdownInvoiceForm>;
  currentStepIndex: number;
  nextPathname: string | null;
  prevPathname: string | null;
  steps: Step[];
};

const DrawdownContext = createContext<DrawdownContextState>({} as DrawdownContextState);

type Props = {
  children: ReactNode;
};

const DrawdownProvider: FC<Props> = ({ children }) => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const isDrawdownApprovalRequired = capitalAccountSummary.controls.drawdownRequiresApproval;
  const isDrawdownApprovalFeatureFlagEnabled = useFeatureFlag("DRAW_DOWN_WITH_APPROVAL_REQUIRED");

  const amountForm = useDrawdownAmountForm();
  const invoiceForm = useDrawdownInvoiceForm();

  const { pathname } = useLocation();

  const steps = [
    {
      pathname: `/capital/${capitalAccountSummary.guid}/credit/draw-down`,
      title: "Amount",
    },
    isDrawdownApprovalFeatureFlagEnabled && isDrawdownApprovalRequired
      ? {
          pathname: `/capital/${capitalAccountSummary.guid}/credit/draw-down/invoice`,
          title: "Invoice",
        }
      : null,
    {
      pathname: `/capital/${capitalAccountSummary.guid}/credit/draw-down/confirm`,
      title: "Confirm details",
    },
  ].filter(isNotNull) satisfies Step[];

  const currentStepIndex = steps.findIndex((step) => step.pathname === pathname);

  const nextPathname =
    currentStepIndex >= 0 && currentStepIndex < steps.length - 1
      ? steps[currentStepIndex + 1].pathname
      : null;

  const prevPathname =
    currentStepIndex > 0 && currentStepIndex < steps.length
      ? steps[currentStepIndex - 1].pathname
      : null;

  return (
    <DrawdownContext.Provider
      value={{
        amountForm,
        invoiceForm,
        currentStepIndex,
        nextPathname,
        prevPathname,
        steps,
      }}
    >
      {children}
    </DrawdownContext.Provider>
  );
};

export default DrawdownProvider;

export const useDrawdownContext = () => {
  return useContext(DrawdownContext);
};

export const useDrawdownAmountFormContext = () => {
  return useContext(DrawdownContext).amountForm;
};

export const useDrawdownInvoiceFormContext = () => {
  return useContext(DrawdownContext).invoiceForm;
};
