import { ChartBar } from "@phosphor-icons/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import DashboardPage from "components/layouts/DashboardPage";
import useUnitCoAccountLimitQueryOptions from "resources/unit-co-account-limits/queries/useUnitCoAccountLimitQueryOptions";
import colors from "styles/colors";
import ProgressBar from "ui/data-visualization/ProgressBar";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import { getDollarsFromCents } from "utils/money";
import { formatAmount, percentage } from "utils/string";

import { useOpenManualTransferFallbackModal } from "./ManualTransferFallbackModal/state/manualTransferFallbackModalState";
import { Transfer } from "./TransferInfo/utils";
import styles from "./TransferLimitSectionV2.module.scss";
import { isAchCredit, isAchDebit } from "./utils";

export enum ACHType {
  ACH_DEBIT,
  ACH_CREDIT,
}
type TransferLimitSectionProps = {
  transfer: Transfer;
};
export const TransferLimitSectionV2: React.FC<TransferLimitSectionProps> = ({ transfer }) => {
  if (isAchDebit(transfer) && transfer.to) {
    return (
      <TransferLimitSectionInfoV2
        accountId={transfer.to.value}
        achType={ACHType.ACH_DEBIT}
        amount={transfer.amountInCents}
      />
    );
  }
  if (isAchCredit(transfer) && transfer.from) {
    return (
      <TransferLimitSectionInfoV2
        accountId={transfer.from.value}
        achType={ACHType.ACH_CREDIT}
        amount={transfer.amountInCents}
      />
    );
  }
  return null;
};

type TransferLimitSectionInfoV2Props = {
  accountId: string;
  achType: ACHType;
  amount: number;
};
const formatAndSlice = (amount: number) => formatAmount(amount).slice(0, -3);

const getPercentageBarColor = (isLimitReached: boolean) =>
  isLimitReached ? colors.red[500] : colors.grey[900];

type TransferringLargeAmountsButtonProps = {
  onClick: () => void;
};
const TransferringLargeAmountsButton: React.FC<TransferringLargeAmountsButtonProps> = ({
  onClick,
}) => (
  <Button className={styles.transferMoreButton} onClick={onClick} variant="tertiary">
    <Text weight="medium">Looking to transfer more?</Text>
  </Button>
);

export const TransferLimitSectionInfoV2: React.FC<TransferLimitSectionInfoV2Props> = ({
  accountId,
  achType,
  amount,
}) => {
  const { data: accountLimits } = useSuspenseQuery(useUnitCoAccountLimitQueryOptions(accountId));
  const openManualTransferFallbackModal = useOpenManualTransferFallbackModal();

  switch (achType) {
    case ACHType.ACH_DEBIT:
      const formattedDailyDebitLimit = formatAndSlice(
        getDollarsFromCents(accountLimits.attributes.ach.limits.dailyDebit)
      );
      const formattedMonthlyDebitLimit = formatAndSlice(
        getDollarsFromCents(accountLimits.attributes.ach.limits.monthlyDebit)
      );
      const formattedDailyDebitUsage = formatAndSlice(
        getDollarsFromCents(accountLimits.attributes.ach.totalsDaily.debits + amount)
      );
      const formattedMonthlyDebitUsage = formatAndSlice(
        getDollarsFromCents(accountLimits.attributes.ach.totalsMonthly.debits + amount)
      );
      const dailyDebitPercentageUsed = percentage(
        accountLimits.attributes.ach.totalsDaily.debits + amount,
        accountLimits.attributes.ach.limits.dailyDebit
      );
      const isDailyDebitLimitReached = dailyDebitPercentageUsed >= 100;
      const monthlyDebitPercentageUsed = percentage(
        accountLimits.attributes.ach.totalsMonthly.credits + amount,
        accountLimits.attributes.ach.limits.monthlyCredit
      );
      const isMonthlyDebitLimitReached = monthlyDebitPercentageUsed >= 100;
      return (
        <DashboardPage.Section>
          <ChartBar size={16} />
          <div className={styles.infoContainer}>
            <Text size={14}>How much can I transfer from my connected account?</Text>
            <div className={styles.row}>
              <Text size={12}>Daily incoming</Text>
              <Text size={12}>
                {formattedDailyDebitUsage} / {formattedDailyDebitLimit}
              </Text>
            </div>
            <ProgressBar
              className={styles.progressBar}
              percent={dailyDebitPercentageUsed}
              color={getPercentageBarColor(isDailyDebitLimitReached)}
            />
            <div className={styles.row}>
              <Text size={12}>Monthly incoming</Text>
              <Text size={12}>
                {formattedMonthlyDebitUsage} / {formattedMonthlyDebitLimit}
              </Text>
            </div>
            <ProgressBar
              className={styles.progressBar}
              percent={monthlyDebitPercentageUsed}
              color={getPercentageBarColor(isMonthlyDebitLimitReached)}
            />
            <TransferringLargeAmountsButton
              onClick={() => {
                openManualTransferFallbackModal();
              }}
            />
          </div>
        </DashboardPage.Section>
      );

    case ACHType.ACH_CREDIT:
      const formattedDailyCreditLimit = formatAndSlice(
        getDollarsFromCents(accountLimits.attributes.ach.limits.dailyCredit)
      );
      const formattedMonthlyCreditLimit = formatAndSlice(
        getDollarsFromCents(accountLimits.attributes.ach.limits.monthlyCredit)
      );
      const formattedDailyCreditUsage = formatAndSlice(
        getDollarsFromCents(accountLimits.attributes.ach.totalsDaily.credits + amount)
      );
      const formattedMonthlyCreditUsage = formatAndSlice(
        getDollarsFromCents(accountLimits.attributes.ach.totalsMonthly.credits + amount)
      );
      const dailyCreditPercentageUsed = percentage(
        accountLimits.attributes.ach.totalsDaily.credits + amount,
        accountLimits.attributes.ach.limits.dailyCredit
      );
      const isDailyCreditLimitReached = dailyCreditPercentageUsed > 100;
      const monthlyCreditPercentageUsed = percentage(
        accountLimits.attributes.ach.totalsMonthly.credits + amount,
        accountLimits.attributes.ach.limits.monthlyCredit
      );
      const isMonthlyCreditLimitReached = monthlyCreditPercentageUsed > 100;

      return (
        <DashboardPage.Section>
          <div>
            <ChartBar size={16} />
          </div>
          <div className={styles.infoContainer}>
            <Text size={14}>How much can I send?</Text>
            <div className={styles.row}>
              <Text size={12}>Daily outgoing</Text>
              <Text size={12}>
                {formattedDailyCreditUsage} / {formattedDailyCreditLimit}
              </Text>
            </div>
            <ProgressBar
              className={styles.progressBar}
              percent={dailyCreditPercentageUsed}
              color={getPercentageBarColor(isDailyCreditLimitReached)}
            />
            <div className={styles.row}>
              <Text size={12}>Monthly outgoing</Text>
              <Text size={12}>
                {formattedMonthlyCreditUsage} / {formattedMonthlyCreditLimit}
              </Text>
            </div>
            <ProgressBar
              className={styles.progressBar}
              percent={monthlyCreditPercentageUsed}
              color={getPercentageBarColor(isMonthlyCreditLimitReached)}
            />
          </div>
        </DashboardPage.Section>
      );
  }
};
