import { Suspense } from "react";
import ModalV3 from "ui/overlay/ModalV3";

import { useUpdateBusinessAddressModal } from "../state/UpdateBusinessAddressModalState";

import UpdateBusinessAddressForm from "./UpdateBusinessAddressForm";

const UpdateBusinessAddressModal = () => {
  const { isOpen, close } = useUpdateBusinessAddressModal();

  return (
    <ModalV3 isOpen={isOpen} onClose={close}>
      <Suspense fallback={null}>
        <UpdateBusinessAddressForm />
      </Suspense>
    </ModalV3>
  );
};

export default UpdateBusinessAddressModal;
