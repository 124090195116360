import BankAccountRep from "reps/BankAccountRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { maskBankAccountAccountNumber, sortAccounts } from "utils/account";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useHasPermission from "utils/permissions/useHasPermission";
import useIsAllowedToViewAccountNumbers from "utils/permissions/useIsAllowedToViewAccountNumbers";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const BANK_ACCOUNTS_QUERY_KEY = "bankAccounts";

const ALL_STATUSES = [
  BankAccountRep.Status.OPEN,
  BankAccountRep.Status.CLOSED,
  BankAccountRep.Status.FROZEN,
];

const useBankAccountsQueryOptions = (
  { statuses }: { statuses: BankAccountRep.Status[] } = { statuses: ALL_STATUSES }
) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const isAllowedToViewAccountNumbers = useIsAllowedToViewAccountNumbers();

  // NB(alex): Unsure about this pattern, please don't copy it yet.
  const hasPermission = useHasPermission("bankAccount:read");

  return useQueryOptions({
    queryKey: [BANK_ACCOUNTS_QUERY_KEY, { businessGuid, statuses }],
    queryFn: async () => {
      if (!hasPermission) {
        return [];
      }

      const bankAccounts = await highbeamApi.bankAccount.getByBusiness(businessGuid);

      // NB(alex): We should mask account numbers on the backend, but this simulates the behavior for now.
      const bankAccountsWithMaskedAccountNumbers = isAllowedToViewAccountNumbers
        ? bankAccounts
        : bankAccounts.map(maskBankAccountAccountNumber);

      const bankAccountsFilteredByStatus = bankAccountsWithMaskedAccountNumbers.filter(
        (bankAccount) => statuses.includes(bankAccount.status)
      );

      return bankAccountsFilteredByStatus.sort(sortAccounts);
    },
  });
};

export default useBankAccountsQueryOptions;
