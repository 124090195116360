import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import BillRep from "reps/BillRep";

import { useBillsQueryOptions } from "./useBills";

export const useBillsEmailInboxDataQueryOptions = () => {
  return useBillsQueryOptions({ state: [BillRep.State.Draft] });
};

export const useBillsEmailInboxDataQuery = () => {
  return useQuery(useBillsEmailInboxDataQueryOptions());
};

export const useBillsEmailInboxDataCountQuery = () => {
  return useQuery({
    ...useBillsEmailInboxDataQueryOptions(),
    select: (data) => data.length,
  });
};

const useBillsEmailInboxData = () => {
  return useSuspenseQuery(useBillsEmailInboxDataQueryOptions()).data;
};

export default useBillsEmailInboxData;
