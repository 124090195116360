import { FC, ReactNode } from "react";
import colors from "styles/colors";
import Pill from "ui/data-display/Pill";
import Text from "ui/typography/Text";

import styles from "./BusinessMemberDropdownLabel.module.scss";

type Props = {
  fullName: ReactNode;
  userRoleName?: string;
  isOnboarded: boolean;
};

const BusinessMemberDropdownLabel: FC<Props> = ({ fullName, userRoleName, isOnboarded }) => {
  return (
    <Text size={14} color={colors.grey[900]} className={styles.container}>
      {fullName}
      {userRoleName && <Pill color="grey-light">{userRoleName}</Pill>}
      {!isOnboarded && <Pill color="grey-light">Onboarding</Pill>}
    </Text>
  );
};

export default BusinessMemberDropdownLabel;
