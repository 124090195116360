import { Controller, FormProvider } from "react-hook-form";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import TextInput from "ui/inputs/TextInput";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/context/useModalContext";

import { useOpenInvitationSuccessfulModal } from "../InvitationSuccessfulModal/state/invitationSuccessfulModalState";

import useCreateUserInvitationMutation from "./hooks/useCreateUserInvitationMutation";
import useInviteUserForm from "./hooks/useInviteUserForm";
import styles from "./InviteUserModal.module.scss";
import UserRoleSelect from "./UserRoleSelect";

const InviteUserForm = () => {
  const form = useInviteUserForm();
  const { mutateAsync: createUserInvitation, isPending } = useCreateUserInvitationMutation();
  const openInvitationSuccessfulModal = useOpenInvitationSuccessfulModal();
  const modalContext = useModalContext();

  const userGuid = useUserGuid();
  const onSubmit = form.handleSubmit(async (data) => {
    const { displayName, slug } = await createUserInvitation({
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.emailAddress,
      userRoleGuids: [data.userRole.guid],
      senderUserGuid: userGuid,
    });

    form.reset();
    openInvitationSuccessfulModal({ displayName: displayName, slug: slug });
    modalContext.onClose();
  });

  return (
    <FormProvider {...form}>
      <ModalV4.Form onSubmit={onSubmit}>
        <ModalV4.Header>Invite a user</ModalV4.Header>

        <ModalV4.Body className={styles.body}>
          <div className={styles.nameContainer}>
            <Controller
              name="firstName"
              control={form.control}
              render={({ field, formState }) => (
                <TextInput
                  label="First name"
                  hasError={Boolean(formState.errors.firstName)}
                  errorMessage={formState.errors.firstName?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="lastName"
              control={form.control}
              render={({ field, formState }) => (
                <TextInput
                  label="Last name"
                  hasError={Boolean(formState.errors.lastName)}
                  errorMessage={formState.errors.lastName?.message}
                  {...field}
                />
              )}
            />
          </div>

          <Controller
            name="emailAddress"
            control={form.control}
            render={({ field, formState }) => (
              <TextInput
                type="email"
                label="Email address"
                hasError={Boolean(formState.errors.emailAddress)}
                errorMessage={formState.errors.emailAddress?.message}
                {...field}
              />
            )}
          />

          <UserRoleSelect />
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.Footer.SubmitButton isLoading={isPending} disabled={!form.formState.isValid}>
            Invite user
          </ModalV4.Footer.SubmitButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </FormProvider>
  );
};

export default InviteUserForm;
