import dayjs from "dayjs";
import { FC, useRef, useState } from "react";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import { getUsersLocalTimezoneAbbreviation } from "utils/date";

import DateCell from "../DateCell";

import styles from "./DateTimeCell.module.scss";

type Props = {
  date: string;
};

const DateTimeCell: FC<Props> = ({ date }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <>
      <div
        ref={containerRef}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        className={styles.container}
      >
        {containerRef.current && isHovered && (
          <Tooltip
            closeTooltip={() => setIsHovered(false)}
            openTooltip={() => setIsHovered(true)}
            referenceElement={containerRef.current}
          >
            <Tooltip.Content>
              {dayjs(date).format("MMM D, YYYY [at] h:mm:ss A")}{" "}
              {getUsersLocalTimezoneAbbreviation(new Date(date))}
            </Tooltip.Content>
          </Tooltip>
        )}
        <DateCell className={styles.text} date={date} />
      </div>
    </>
  );
};

export default DateTimeCell;
