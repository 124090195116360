import { Controller, useFormContext } from "react-hook-form";
import colors from "styles/colors";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import TextInput from "ui/inputs/TextInput";
import Text from "ui/typography/Text";
import {
  supportedStatesForCapital,
  Address,
  supportedStatesForCapitalOptions,
  stateOptions,
} from "utils/address";
import { useAddressAutocomplete } from "utils/customHooks/useAddressAutocomplete";

import styles from "../UpsellCapitalView.module.scss";

import { UpdateBusinessAddressFormInputs } from "./useUpdateBusinessAddressForm";

const UpdateAddressModalContent = () => {
  const { formState, control, getFieldState, getValues, setValue } =
    useFormContext<UpdateBusinessAddressFormInputs>();
  const { isDirty: isStateDirty } = getFieldState("state", formState);

  const currentAddress: Address = {
    addressLine1: { value: getValues("addressLine1"), label: getValues("addressLine1") },
    addressLine2: getValues("addressLine2"),
    city: getValues("city"),
    state: { value: getValues("state"), label: getValues("state") },
    zipCode: getValues("zipCode"),
    country: { value: getValues("country"), label: getValues("country") },
  };

  const setAddress = (autofill: Address) => {
    setValue("addressLine1", autofill.addressLine1?.value || "");
    setValue("addressLine2", autofill.addressLine2);
    setValue("city", autofill.city);
    setValue("state", autofill.state?.value || "", { shouldValidate: true });
    setValue("zipCode", autofill.zipCode, { shouldValidate: true });
  };

  const {
    streetAddressInput,
    autoCompleteHandleChange,
    autoCompleteHandleInputChange,
    addressSuggestions,
    isPlacePredictionsLoading,
  } = useAddressAutocomplete(currentAddress, setAddress);

  return (
    <>
      <Text size={16} color={colors.grey[900]}>
        This must be a physical address where you do business. PO boxes and addresses of registered
        agents will not be accepted.
      </Text>
      <div>
        <Controller
          name="state"
          control={control}
          render={({ field, formState }) => (
            <Dropdown
              label="State"
              menuClassName={styles.modalDropdownMenu}
              hasError={Boolean(formState.errors.state)}
              errorMessage={formState.errors.state?.message}
              options={supportedStatesForCapitalOptions}
              onChange={(option: Option) => field.onChange(option.value)}
              value={stateOptions.find((option) => option.value === field.value) ?? null}
            />
          )}
        />
      </div>
      <Text size={12} color={colors.grey[600]}>
        <strong>Supported states: </strong>
        {Object.values(supportedStatesForCapital).join(", ")}
      </Text>
      {isStateDirty && (
        <div className={styles.modalAddressSection}>
          <Controller
            name="addressLine1"
            control={control}
            render={({ field, formState }) => (
              <Dropdown
                label="Street address"
                hasError={Boolean(formState.errors.addressLine1)}
                errorMessage={formState.errors.addressLine1?.message}
                value={{ value: field.value, label: field.value }}
                inputValue={streetAddressInput}
                onChange={autoCompleteHandleChange}
                onInputChange={autoCompleteHandleInputChange}
                options={addressSuggestions}
                filterOption={() => true} // always show all options that autocomplete provides
                onClear={() => {
                  setValue("addressLine1", "");
                }}
                isClearable
                isLoading={isPlacePredictionsLoading}
                hideCaret
              />
            )}
          />
          <Controller
            name="addressLine2"
            control={control}
            render={({ field, formState }) => (
              <TextInput
                label="Apartment, unit, suite, or floor # (optional)"
                hasError={Boolean(formState.errors.addressLine2)}
                errorMessage={formState.errors.addressLine2?.message}
                {...field}
              />
            )}
          />
          <div className={styles.modalCityZipSection}>
            <Controller
              name="city"
              control={control}
              render={({ field, formState }) => (
                <TextInput
                  label="City"
                  className={styles.modalCityZipInput}
                  hasError={Boolean(formState.errors.city)}
                  errorMessage={formState.errors.city?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="zipCode"
              control={control}
              render={({ field, formState }) => (
                <TextInput
                  label="Zip"
                  className={styles.modalCityZipInput}
                  hasError={Boolean(formState.errors.zipCode)}
                  errorMessage={formState.errors.zipCode?.message}
                  {...field}
                />
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateAddressModalContent;
