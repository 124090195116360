import { useSuspenseQuery } from "@tanstack/react-query";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import getPrimaryBankAccount from "../utils/getPrimaryBankAccount";

import { useOpenBankAccountsQueryOptions } from "./useOpenBankAccounts";

const usePrimaryBankAccount = <TRequired extends boolean>(params?: RequiredParam<TRequired>) => {
  const { data } = useSuspenseQuery({
    ...useOpenBankAccountsQueryOptions(),
    select: (bankAccounts) => getPrimaryBankAccount(bankAccounts),
  });

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default usePrimaryBankAccount;
