import { CaretDown, CaretUp } from "@phosphor-icons/react";
import classNames from "classnames";
import { DropdownIndicatorProps, components, GroupBase } from "react-select";

import styles from "./DropdownIndicator.module.scss";

const DropdownIndicator = <
  TOption,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>({
  selectProps,
  isDisabled,
  ...props
}: DropdownIndicatorProps<TOption, TIsMulti, TGroup>) => {
  const Caret = selectProps.menuIsOpen ? CaretUp : CaretDown;

  return (
    <components.DropdownIndicator selectProps={selectProps} isDisabled={isDisabled} {...props}>
      <Caret size={16} className={classNames(styles.caret, isDisabled && styles.disabled)} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
