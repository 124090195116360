import { PhoneOutgoing } from "@phosphor-icons/react";
import pencilIcon from "assets/pencil.svg";
import warningIcon from "assets/warning.svg";
import classNames from "classnames";
import useCard from "resources/cards/queries/useCard";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";
import { getCardName } from "utils/cards";

import CardModalInfo from "../CardModalInfo";

import styles from "./ChangePinModal.module.scss";
import { useChangePinModal } from "./utils";

type Props = {
  closeModal: () => void;
  cardId: string;
};

const ChangePinModal: React.FC<Props> = ({ closeModal, cardId }) => {
  const card = useCard(cardId, { required: true });
  const cardName = getCardName(card);

  const { changePin, handleReset, isLoading, formError } = useChangePinModal(
    closeModal,
    cardName,
    cardId
  );

  return (
    <Modal
      icon={<img alt="Edit icon" src={pencilIcon} />}
      title="Change PIN"
      showCancel
      buttonText="Change PIN"
      isLoading={isLoading}
      isPrimaryButtonDisabled={isLoading}
      isCancelButtonDisabled={isLoading}
      onClick={changePin}
      onClose={() => {
        handleReset();
        closeModal();
      }}
    >
      <div className={styles["change-pin"]}>
        <CardModalInfo card={card} />
        <form className={styles["change-pin__form"]}>
          <div className={styles["change-pin__row"]}>
            <Text size={14} weight="bold">
              Old PIN code
            </Text>
            <div
              id="cc-old-pin"
              className={classNames({
                [styles["change-pin__vgs-input"]]: true,
                [styles["change-pin__vgs-input-error"]]: formError !== undefined,
              })}
            />
          </div>
          <div className={styles["change-pin__row"]}>
            <Text size={14} weight="bold">
              New PIN code
            </Text>
            <div
              id="cc-new-pin"
              className={classNames({
                [styles["change-pin__vgs-input"]]: true,
                [styles["change-pin__vgs-input-error"]]: formError !== undefined,
              })}
            />
          </div>
        </form>
        <Text className={styles.contactUsWrapper} size={14}>
          Forgot PIN? Contact Visa
          <span className={styles.contactVisaLinkText}>
            <span className={styles.phone}>
              <PhoneOutgoing size={16} />
            </span>
            <a href="tel:1-833-333-0417"> 1-833-333-0417</a>
          </span>
        </Text>
        {formError && (
          <div className={styles["change-pin__warning"]}>
            <img src={warningIcon} alt="warning" />
            <Text size={14}>{formError}</Text>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ChangePinModal;
