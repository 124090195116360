import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useCapitalAccountSummaryQueryOptions from "resources/capital-accounts/queries/useCapitalAccountSummaryQueryOptions";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

type Variables = {
  name: string;
};

type Params = {
  capitalAccountGuid: string;
};

const useEditCapitalAccountNameMutation = ({ capitalAccountGuid }: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const refreshCapitalAccountSummary = useRefreshQuery(
    useCapitalAccountSummaryQueryOptions(capitalAccountGuid).queryKey
  );

  return useMutation({
    mutationFn: async ({ name }: Variables) =>
      await highbeamApi.capitalAccount.update(businessGuid, capitalAccountGuid, { name }),
    onError: (err) => {
      notify("error", "Something went wrong! Please try again.");
      captureException(err);
    },
    onSuccess: async () => {
      await refreshCapitalAccountSummary();
      notify("success", "Capital account name updated successfully!");
    },
  });
};

export default useEditCapitalAccountNameMutation;
