import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import BillRep from "reps/BillRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { BILLS_QUERY_KEY } from "./useRefreshBillsQueries";

type Params = {
  state?: BillRep.State[];
};

export const useBillsQueryOptions = (params: Params = {}) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [BILLS_QUERY_KEY, businessGuid, params],
    queryFn: async () => {
      const bills = await highbeamApi.billSummary.search(businessGuid);
      if (params.state) {
        const filterStates = params.state; // NB(alex): Need to reassign to prevent ts error. I'm not sure why this is needed 🤔
        return bills.filter(({ state }) => filterStates.includes(state));
      }
      return bills;
    },
  });
};

export const useBillsQuery = (params: Params = {}) => {
  return useQuery(useBillsQueryOptions(params));
};

const useBills = (params: Params = {}) => {
  const { data } = useSuspenseQuery(useBillsQueryOptions(params));
  return data;
};

export default useBills;
