import { ErrorBoundary } from "@sentry/react";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import Transactions from "components/Transactions";
import { Suspense } from "react";
import { Navigate, useParams } from "react-router-dom";
import usePayee from "resources/payees/queries/usePayee";
import Shimmer from "ui/feedback/Shimmer";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import PayeeDetailsHeader from "./PayeeDetailsHeader";
import PayeeDetailsInfo from "./PayeeDetailsInfo";
import { usePayeeTransactions } from "./transaction-utils";

const PayeeDetailsBreadcrumbItem = () => {
  const { payeeGuid } = useParams();
  const payee = usePayee(payeeGuid!, { required: true });
  return <Breadcrumbs.CurrentItem>{payee.name}</Breadcrumbs.CurrentItem>;
};

const PayeeDetailsContent = () => {
  const { payeeGuid } = useParams();
  const payeeTransactions = usePayeeTransactions(payeeGuid!);

  return (
    <>
      <Suspense fallback={<Shimmer total={2} />}>
        <PayeeDetailsHeader />
      </Suspense>

      <DashboardPage.Section>
        <Suspense
          fallback={<Shimmer total={2} />} // TODO(alex): `DashboardPage.Section` should have a default fallback
        >
          <PayeeDetailsInfo />
        </Suspense>
      </DashboardPage.Section>

      <DashboardPage.Section>
        <DashboardPage.Section.Header>
          <DashboardPage.Section.HeaderHeading>Transactions</DashboardPage.Section.HeaderHeading>
        </DashboardPage.Section.Header>

        <DashboardPage.Section.Body>
          <Transactions
            {...payeeTransactions}
            // NB(alex): These unnecessary props are currently required but will be deleted soon.
            activeTab="completed"
            setActiveTab={() => {}}
            tabs={[{ id: "completed", label: "Completed" }]}
          />
        </DashboardPage.Section.Body>
      </DashboardPage.Section>
    </>
  );
};

const PayeeDetailsPage = () => {
  return (
    <ErrorBoundary fallback={<Navigate to="/payments/payees" />}>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/payments">Payments</Breadcrumbs.Item>
          <Breadcrumbs.Item to="/payments/payees">Payees</Breadcrumbs.Item>
          <Suspense fallback={<Breadcrumbs.CurrentItemShimmer />}>
            <PayeeDetailsBreadcrumbItem />
          </Suspense>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <PayeeDetailsContent />
      </DashboardPage>
    </ErrorBoundary>
  );
};

export default PayeeDetailsPage;
