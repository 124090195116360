import colors from "styles/colors";
import ProgressBar from "ui/data-visualization/ProgressBar";

import { Step } from "../utils";

import styles from "./ChargeCardOnboarding.module.scss";

type Props = {
  step: Step;
};

const CHARGE_CARD_PROGRESS: Map<Step, number> = new Map([
  [Step.SELECT_VIRTUAL_CARD, 25],
  [Step.SELECT_PHYSICAL_CARD, 20],
  [Step.CHARGE_CARD_ONBOARDING, 40],
  [Step.CREATE_PHYSICAL_CREDIT_CARD, 60],
  [Step.CREATE_VIRTUAL_CREDIT_CARD, 80],
  [Step.SHIPPING_ADDRESS, 80],
  [Step.CONFIRMATION, 100],
]);

const ChargeCardCreationProgress: React.FC<Props> = ({ step }) => {
  const progress = CHARGE_CARD_PROGRESS.get(step) ?? 0;

  return (
    <ProgressBar
      className={styles.progressBarContainer}
      percent={progress}
      color={colors.purple[500]}
    />
  );
};

export default ChargeCardCreationProgress;
