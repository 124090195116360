import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitCoTransactionsQueryOptions from "resources/unit-co-transactions/queries/useUnitCoTransactionsQueryOptions";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { startOfYear } from "utils/date";

import useBankAccountsQueryOptions from "./useBankAccountsQueryOptions";

// If a bankAccountGuid is passed in, it fetches the interest for that bank account.
// Otherwise, it returns interest across all accounts.
const useBankAccountInterestYieldYtd = (bankAccountGuid?: string) => {
  const shouldIncludeAdjustments = useFeatureFlag("INCLUDE_ADJUSTMENT_TRANSACTIONS_AS_INTEREST");
  const { data: bankAccount } = useSuspenseQuery({
    ...useBankAccountsQueryOptions(),
    select: (bankAccounts) =>
      bankAccounts.find((bankAccount) => bankAccount.guid === bankAccountGuid),
  });

  const { data } = useSuspenseQuery({
    ...useUnitCoTransactionsQueryOptions({
      accountId: bankAccount?.unitCoDepositAccountId,
      since: startOfYear().format(),
      type: shouldIncludeAdjustments ? ["Interest", "Adjustment"] : ["Interest"],
    }),
  });

  return data.transactions.map((t) => t.attributes.amount).reduce((a, b) => a + b, 0);
};

export default useBankAccountInterestYieldYtd;
