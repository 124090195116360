import { CreditCard, Bank, PlusCircle, ShieldWarning } from "@phosphor-icons/react";
import { getInstitutionNameFromId, getIcon } from "components/Accounts/AccountLabel";
import { ConnectedIntegrationIndicator } from "pages/settings/SettingsPage/ConnectedIntegrationIndicator";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import Pill from "ui/data-display/Pill";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import { maskAccountNumber } from "utils/account";

import styles from "./ConnectedIntegrationRow.module.scss";
import { getActiveButtonLabel, getConnectedIntegrationSubheadingText } from "./utils";

type ConnectedIntegrationRowProps = {
  accountType: PlaidLinkTokenRep.AccountType;
  connectionGuid: string;
  value?: any;
  creditCards: PlaidBankAccountRep.Complete[];
  bankAccounts: PlaidBankAccountRep.Complete[];
  isActive: boolean;
  institutionName: string;
  institutionId: string;
  goToNextStep: () => void;
  setAddConnectionModalConnectionGuid: (connectionGuid: string) => void;
};

const getMasksFromPlaidConnection = (accounts: PlaidBankAccountRep.Complete[]): string[] =>
  accounts.map((account) => account.accountMask || "");

export const ConnectedIntegrationRow: React.FC<ConnectedIntegrationRowProps> = ({
  connectionGuid,
  bankAccounts,
  creditCards,
  isActive,
  institutionId,
  institutionName: institutionNameFromProps,
  accountType,
  goToNextStep,
  setAddConnectionModalConnectionGuid,
}) => {
  const isCardPrimary = accountType === "CREDIT";
  const institutionName =
    institutionNameFromProps ??
    getInstitutionNameFromId(institutionId, `${isCardPrimary ? "card" : "bank"}`);
  const logo = getIcon(institutionId);
  const bankAccountMasks = getMasksFromPlaidConnection(bankAccounts);
  const creditCardMasks = getMasksFromPlaidConnection(creditCards);
  const primaryMasks = isCardPrimary ? creditCardMasks : bankAccountMasks;
  const secondaryMasks = isCardPrimary ? bankAccountMasks : creditCardMasks;

  return (
    <div className={styles.connectedIntegrationRowContainer}>
      <div className={styles.topRowContainer}>
        <div>{logo}</div>
        <div>
          <div className={styles.rowHeader}>
            <Text size={14} weight="regular">
              {institutionName}
            </Text>
            <ConnectedIntegrationIndicator
              isActive={isActive}
              size={12}
              showConnectedIndicator={false}
            />
          </div>
          <Text className={styles.secondaryAccountMasks} size={12}>
            <>
              {getConnectedIntegrationSubheadingText(primaryMasks, secondaryMasks, isCardPrimary)}
            </>
          </Text>
        </div>
      </div>
      <div className={styles.tagContainer}>
        {primaryMasks.length === 0 && (
          <Text size={14}>
            You don’t have any {isCardPrimary ? "cards" : "accounts"} from this login yet
          </Text>
        )}
        {primaryMasks.map((mask) => (
          <Pill color="grey-light" key={mask}>
            {isCardPrimary ? <CreditCard size={12} /> : <Bank size={12} />}{" "}
            {isCardPrimary ? "Card" : "Account"} {maskAccountNumber(mask)}
          </Pill>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          size="sm"
          className={styles.button}
          onClick={() => {
            setAddConnectionModalConnectionGuid(connectionGuid);
            goToNextStep();
          }}
        >
          {isActive ? (
            <>
              <PlusCircle /> {getActiveButtonLabel(isCardPrimary, primaryMasks)}
            </>
          ) : (
            <>
              <ShieldWarning /> Reauthenticate
            </>
          )}
        </Button>
      </div>
    </div>
  );
};
