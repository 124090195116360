import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useBillAuditLogsQueryOptions = (billId: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["billAuditLogs", { billId, businessGuid }],
    queryFn: async () => {
      return highbeamApi.auditTrail.getBillAuditLogs(billId, businessGuid);
    },
  });
};

const useBillAuditLogs = (billId: string) => {
  const { data } = useSuspenseQuery(useBillAuditLogsQueryOptions(billId));

  return data;
};

export default useBillAuditLogs;
