import ChargeCardOfferRep from "reps/ChargeCardOfferRep";

export const chargeCardOfferMaximumRepaymentPeriodDays = {
  [ChargeCardOfferRep.ChargeCardCreditTerm.Production]: 45,
  [ChargeCardOfferRep.ChargeCardCreditTerm.Sandbox]: 45,
  [ChargeCardOfferRep.ChargeCardCreditTerm.ProductionExtend]: 60,
  [ChargeCardOfferRep.ChargeCardCreditTerm.ProductionFlex]: 30,
  [ChargeCardOfferRep.ChargeCardCreditTerm.ProductionCash]: 1,
  [ChargeCardOfferRep.ChargeCardCreditTerm.SandboxExtend]: 60,
  [ChargeCardOfferRep.ChargeCardCreditTerm.SandboxFlex]: 30,
  [ChargeCardOfferRep.ChargeCardCreditTerm.SandboxCash]: 1,
} satisfies { [key in ChargeCardOfferRep.ChargeCardCreditTerm]: number };
