import HighbeamCard from "components/HighbeamCard";
import useCard from "resources/cards/queries/useCard";
import Text from "ui/typography/Text";
import { getCardType, isPhysical } from "utils/cards";

import styles from "./Success.module.scss";

type SuccessProps = {
  cardId: string;
};

const Success: React.FC<SuccessProps> = ({ cardId }) => {
  const card = useCard(cardId, { required: true });

  const cardType = getCardType(card);

  return (
    <>
      <Text className={styles.header} size={16} weight="bold">
        You’re all set!
      </Text>
      <Text className={styles.subText} size={14}>
        You can now use this card for physical and online purchases.
      </Text>
      <HighbeamCard card={card} areDetailsAvailable={!isPhysical(cardType)} />
    </>
  );
};

export default Success;
