import { forwardRef, ForwardRefRenderFunction, useState } from "react";
import CurrencyInputField, { CurrencyInputProps } from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";

import InputWrapper, { SharedInputProps } from "../InputWrapperV2";

import styles from "./CurrencyInput.module.scss";

type CurrencyInputPropsWithoutChangeHandlers = Omit<
  CurrencyInputProps,
  "onChange" | "onValueChange"
>;

type Props = CurrencyInputPropsWithoutChangeHandlers &
  SharedInputProps & {
    onChange: (value: string, name?: string, values?: CurrencyInputOnChangeValues) => void;
  };

const CurrencyInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    value,
    placeholder,
    className,
    disabled,
    id,
    label,
    startAdornment,
    endAdornment,
    showErrorOutline,
    onFocus,
    onChange,
    onBlur,
    ...currencyInputProps
  },
  ref
) => {
  const [focused, setFocused] = useState(false);
  const shouldShrinkLabel = Boolean(value || placeholder || focused);

  return (
    <InputWrapper
      shouldShrinkLabel={shouldShrinkLabel}
      showFocusOutline={focused}
      disabled={disabled}
      className={className}
      inputId={id}
      label={label}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      showErrorOutline={showErrorOutline}
    >
      <CurrencyInputField
        ref={ref}
        onValueChange={(value, name, values) => onChange(value ?? "", name, values)}
        className={styles.input}
        onFocus={(e) => {
          setFocused(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          onBlur?.(e);
        }}
        id={id}
        value={value}
        disabled={disabled}
        {...currencyInputProps}
      />
    </InputWrapper>
  );
};

export default forwardRef(CurrencyInput);
