import { Address as AddressType, Card, CardLimits } from "@highbeam/unit-node-sdk";
import truck from "assets/create-card-delivery-truck.svg";
import highbeamIcon from "assets/highbeam-icon.svg";
import ApplePayInfoText from "components/ApplePayInfoText";
import HighbeamCard from "components/HighbeamCard";
import { convertUnitAddressToAddressComponentProp } from "pages/cards/CardDetailsPage/CardDetailsInfo/utils";
import { FC } from "react";
import BankAccountRep from "reps/BankAccountRep";
import CapitalAccountRep from "reps/CapitalAccountRep";
import DebitCardRep from "reps/DebitCardRep";
import Address from "ui/data-display/Address";
import Divider from "ui/data-display/Divider";
import Text from "ui/typography/Text";
import {
  getCardAccountName,
  getCardBillingAddress,
  getCardShippingAddress,
  getCardholderName,
  isChargeCard,
  isPhysical,
} from "utils/cards";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";

import styles from "./Confirmation.module.scss";

type Props = {
  createdCard?: Card;
  createdCardLimit?: CardLimits;
  createdCardRelatedAccounts?: BankAccountRep.Complete[];
  cardType: DebitCardRep.CardType;
  selectedCapitalAccount: CapitalAccountRep.Complete | null;
};

const Confirmation: React.FC<Props> = ({
  createdCard,
  createdCardLimit,
  createdCardRelatedAccounts,
  cardType,
  selectedCapitalAccount,
}) => {
  if (!createdCard || !createdCardLimit) {
    return null;
  }

  const address = getCardShippingAddress(createdCard);
  const billingAddress = getCardBillingAddress(createdCard);
  const bankAccountName =
    createdCardRelatedAccounts && createdCardRelatedAccounts.length > 0
      ? getCardAccountName(createdCard, createdCardRelatedAccounts)
      : null;
  const cardholderName = getCardholderName(createdCard);
  const chargeCardAccountName = selectedCapitalAccount?.name;
  const isPhysicalCardType = isPhysical(cardType);

  return (
    <>
      {isPhysicalCardType && <PhysicalConfirmationInfo address={address} />}
      {!isPhysicalCardType && <VirtualConfirmationInfo cardholderName={cardholderName} />}

      <Divider />

      <div className={styles.highbeamCardContainer}>
        <HighbeamCard card={createdCard} areDetailsAvailable={cardType !== "PHYSICAL"} />
      </div>

      <table className={styles.table}>
        {billingAddress && !isPhysicalCardType && (
          <tr>
            <td>
              <Text className={styles.label} size={14}>
                Billing address
              </Text>
            </td>
            <td>
              <Address
                address={convertUnitAddressToAddressComponentProp(billingAddress)}
                size={14}
                weight="medium"
              />
            </td>
          </tr>
        )}
        {cardholderName && isPhysicalCardType && (
          <tr>
            <td>
              <Text className={styles.label} size={14}>
                Cardholder
              </Text>
            </td>
            <td>
              <Text className={styles.value} size={14} weight="medium" align="right">
                {cardholderName}
              </Text>
            </td>
          </tr>
        )}
        <tr>
          <td>
            <Text className={styles.label} size={14}>
              Associated account
            </Text>
          </td>
          <td className={styles.account}>
            <img src={highbeamIcon} alt="Highbeam" />
            <Text className={styles.value} size={14} weight="medium" align="right">
              {isChargeCard(cardType) ? chargeCardAccountName : bankAccountName}
            </Text>
          </td>
        </tr>

        <tr>
          <td>
            <Text className={styles.label} size={14}>
              Daily spend budget
            </Text>
          </td>
          <td>
            <Text className={styles.value} size={14} weight="medium" align="right">
              {createdCardLimit.attributes.limits &&
              createdCardLimit.attributes.limits.dailyPurchase
                ? formatAmount(
                    getDollarsFromCents(createdCardLimit.attributes.limits.dailyPurchase)
                  )
                : "-"}
            </Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text className={styles.label} size={14}>
              Monthly spend budget
            </Text>
          </td>
          <td>
            <Text className={styles.value} size={14} weight="medium" align="right">
              {createdCardLimit.attributes.limits &&
              createdCardLimit.attributes.limits.monthlyPurchase
                ? formatAmount(
                    getDollarsFromCents(createdCardLimit.attributes.limits.monthlyPurchase)
                  )
                : "-"}
            </Text>
          </td>
        </tr>
      </table>

      <ApplePayInfoText className={styles.applePayInfo} />
    </>
  );
};

type VirtualConfirmationInfoProps = {
  cardholderName: string | null;
};

export const VirtualConfirmationInfo: FC<VirtualConfirmationInfoProps> = ({ cardholderName }) => (
  <>
    <Text className={styles.header} size={18} weight="bold">
      Here are the card details.
    </Text>
    <Text as="p" size={14} className={styles.description}>
      {cardholderName ?? "You"} can now access and use this virtual card from the{" "}
      <strong>Cards</strong> page in their Highbeam account.
    </Text>
  </>
);

export const PhysicalConfirmationInfo = ({ address }: { address: AddressType | null }) => (
  <>
    <div className={styles.deliveryInfo}>
      <img src={truck} alt="Your card is on its way!" />
      <div className={styles.textBlock}>
        <Text weight="bold" size={16} className={styles.title}>
          Your card is on its way!
        </Text>
        <Text size={14}>The card will arrive within 5 to 10 business days.</Text>

        {address && (
          <p className={styles.shippingTo}>
            <Text size={14}>Shipping to </Text>
            <Text weight="medium" size={14} className={styles.address}>
              {[
                address?.street,
                address?.street2,
                address?.city,
                `${address?.state} ${address?.postalCode}`,
              ]
                .filter(Boolean)
                .join(", ") + "."}
            </Text>
          </p>
        )}
        <Text size={14} className={styles.activateInfo}>
          Once received, please activate the card before attempting a transaction.
        </Text>
      </div>
    </div>

    <Divider />
  </>
);

export default Confirmation;
