import amazonIconGrey from "assets/merchants/amazon-icon-grey.svg";
import amazonIcon from "assets/merchants/amazon.svg";
import paypalIconGrey from "assets/merchants/paypal-icon-grey.svg";
import paypalIcon from "assets/merchants/paypal.svg";
import shopifyIconGrey from "assets/shopify-icon-grey.svg";
import shopifyIcon from "assets/shopify-icon.svg";
import stripeIconGrey from "assets/stripe-icon-grey.svg";
import stripeIcon from "assets/stripe-icon.svg";

import { ConnectedStoreType } from "../types";

type Params = {
  connectedStoreType: ConnectedStoreType;
  isGrey?: boolean;
};

const getConnectedStoreIcon = ({ connectedStoreType, isGrey }: Params): string => {
  switch (connectedStoreType) {
    case "Shopify":
      return isGrey ? shopifyIconGrey : shopifyIcon;
    case "Amazon":
      return isGrey ? amazonIconGrey : amazonIcon;
    case "Stripe":
      return isGrey ? stripeIconGrey : stripeIcon;
    case "PayPal":
      return isGrey ? paypalIconGrey : paypalIcon;
  }
};

export default getConnectedStoreIcon;
