import {
  ArrowsClockwise,
  CalendarCheck,
  CreditCard,
  CurrencyCircleDollar,
  MegaphoneSimple,
  Percent,
} from "@phosphor-icons/react";
import DashboardPage from "components/layouts/DashboardPage";
import colors from "styles/colors";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import LineOfCreditIcon from "./assets/line-of-credit-icon.svg";
import styles from "./UpsellCapitalView.module.scss";

const CapitalProductsSection = () => {
  const isHighbeamCardProductAvailable = useFeatureFlag("CAPITAL_PRODUCTS_HIGHBEAM_CARD");

  return (
    <DashboardPage.Section.Body>
      <div className={styles.capitalProductsCards}>
        <div className={styles.capitalProductsCard}>
          <div className={styles.productCardHeading}>
            <div className={styles.creditProductsIcon}>
              <img src={LineOfCreditIcon} alt="line-of-credit" />
            </div>
            <Heading3>Line of credit</Heading3>
          </div>
          <Text size={14} color={colors.grey[600]} className={styles.productDescription}>
            A line of credit that you can draw down from and repay instantly for your working
            capital needs.
          </Text>
          <div className={styles.productCardBody}>
            <div className={styles.productOfferingRow}>
              <Percent size={24} weight="thin" color={colors.grey[600]} />
              <Text size={14} color={colors.grey[600]}>
                16-20% APR
              </Text>
            </div>
            <div className={styles.productOfferingRow}>
              <ArrowsClockwise size={24} weight="thin" color={colors.grey[600]} />
              <Text size={14} color={colors.grey[600]}>
                Multiple draws from capital limit
              </Text>
            </div>
            <div className={styles.productOfferingRow}>
              <CalendarCheck size={24} weight="thin" color={colors.grey[600]} />
              <Text size={14} color={colors.grey[600]}>
                Repayment period of up to 6 months
              </Text>
            </div>
          </div>
        </div>

        {isHighbeamCardProductAvailable && (
          <div className={styles.capitalProductsCard}>
            <div className={styles.productCardHeading}>
              <div className={styles.creditProductsIcon}>
                <CreditCard color={colors.purple[500]} size={32} weight="thin" />
              </div>
              <Heading3>Highbeam Card</Heading3>
            </div>
            <Text size={14} color={colors.grey[600]} className={styles.productDescription}>
              A charge card built for e-commerce. Spend freely with no interest charges and repay
              monthly.
            </Text>
            <div className={styles.productCardBody}>
              <div className={styles.productOfferingRow}>
                <MegaphoneSimple size={24} weight="thin" color={colors.grey[600]} />
                <Text size={14} color={colors.grey[600]}>
                  1.5% cash back on ads
                </Text>
              </div>
              <div className={styles.productOfferingRow}>
                <CurrencyCircleDollar size={24} weight="thin" color={colors.grey[600]} />
                <Text size={14} color={colors.grey[600]}>
                  1% cash back on everything else
                </Text>
              </div>
              <div className={styles.productOfferingRow}>
                <CalendarCheck size={24} weight="thin" color={colors.grey[600]} />
                <Text size={14} color={colors.grey[600]}>
                  Up to 45 days to repay
                </Text>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardPage.Section.Body>
  );
};

export default CapitalProductsSection;
