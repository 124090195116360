import { atom, useRecoilState } from "recoil";
import useDialog, { DialogState } from "utils/dialog/useDialog";

export enum ApplicationRequirementsModalStep {
  QUESTIONS = "QUESTIONS",
  SUMMARY = "SUMMARY",
}

const applicationRequirementsModalState = atom<DialogState>({
  key: "applicationRequirementsModalState",
  default: {
    isOpen: false,
  },
});

const stepState = atom<ApplicationRequirementsModalStep>({
  key: "applicationRequirementsModalStep",
  default: ApplicationRequirementsModalStep.QUESTIONS,
});

export const useApplicationRequirementsModal = () => {
  const { isOpen, open, close } = useDialog(applicationRequirementsModalState);
  const [step, setStep] = useRecoilState(stepState);

  const nextStep = () => setStep(ApplicationRequirementsModalStep.SUMMARY);
  const resetStep = () => setStep(ApplicationRequirementsModalStep.QUESTIONS);

  const handleClose = () => {
    resetStep();
    close();
  };

  return { isOpen, open, close: handleClose, step, nextStep };
};

export default applicationRequirementsModalState;
