import { useSuspenseQuery } from "@tanstack/react-query";
import { migrateToThreadActionItemConstants } from "modules/migrate-to-thread/migrateToThreadActionItems/constants";
import useHasDismissedCreateNewDebitCardBanner from "modules/migrate-to-thread/migrateToThreadActionItems/useHasDismissedCreateNewDebitCardBanner";
import useMigrateToThreadActionItems from "modules/migrate-to-thread/queries/useMigrateToThreadActionItems";
import getActionItem from "resources/action-items/utils/getActionItem";
import useCardsByPartnerBankQueryOptions from "resources/cards/queries/useCardsByPartnerBankQueryOptions";

const useShouldShowMigrateToThreadDebitCardBanner = () => {
  const { data: threadCards } = useSuspenseQuery(useCardsByPartnerBankQueryOptions("Thread"));
  const dismissedCreateThreadDebitCardBanner = useHasDismissedCreateNewDebitCardBanner();

  const { data: migrateToThreadActionItems } = useMigrateToThreadActionItems();
  const activeThreadViewActionItem = getActionItem(
    migrateToThreadActionItems,
    migrateToThreadActionItemConstants.activateThreadView.actionItemName
  );
  const hasCompletedActivateThreadView = activeThreadViewActionItem?.state === "Complete";

  return (
    !dismissedCreateThreadDebitCardBanner &&
    hasCompletedActivateThreadView &&
    threadCards.length === 0
  );
};

export default useShouldShowMigrateToThreadDebitCardBanner;
