import { ArrowRight, CalendarPlus } from "@phosphor-icons/react";
import dayjs from "dayjs";
import env from "env";
import { FC } from "react";
import { Link } from "react-router-dom";
import UserReferralRep from "reps/ReferralLinkRep";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "../Referral.module.scss";

type Props = {
  referral: UserReferralRep;
};

const ReferralContent: FC<Props> = ({ referral }) => {
  const referralExpiresAt = referral.expiresAt
    ? dayjs(referral.expiresAt).format("MM/DD/YY")
    : null;
  const hasExpired = referral.expiresAt ? dayjs(referral.expiresAt).isBefore(dayjs()) : false;

  return (
    <>
      <Link
        className={styles.button}
        to={`${env.HIGHBEAM_MARKETING_SITE_URL}/qualify?source=${referral.slug}`}
        rel="noreferrer"
      >
        <Button variant="primary" className={styles.button}>
          Apply now <ArrowRight size={20} />
        </Button>
      </Link>
      {referralExpiresAt && !hasExpired && (
        <div className={styles.expiresAt}>
          <CalendarPlus size={16} color={colors.grey[500]} />
          <Text size={12} color={colors.grey[500]}>
            Invitation expires {referralExpiresAt}
          </Text>
        </div>
      )}
      <div className={styles.bookDemo}>
        <Text size={14} color={colors.grey[600]} className={styles.noWrap}>
          Have questions?
        </Text>
        <Link
          className={styles.button}
          to={env.CALENDLY_DEMO_LINK}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="ghost">
            <Text size={14} color={colors.purple[500]} className={styles.noWrap}>
              Book a demo
            </Text>
          </Button>
        </Link>
      </div>
    </>
  );
};

export default ReferralContent;
