import { FC } from "react";
import ChargeCardOfferRep from "reps/ChargeCardOfferRep";
import useChargeCardOffer from "resources/charge-cards/queries/useChargeCardOffer";
import colors from "styles/colors";
import Text from "ui/typography/Text";

type ChargeCardAutoPaySubheadingProps = {
  capitalAccountGuid: string;
};

const ChargeCardAutoPaySubheading: FC<ChargeCardAutoPaySubheadingProps> = ({
  capitalAccountGuid,
}) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountGuid);

  switch (chargeCardOffer?.creditTerms) {
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionFlex:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxFlex:
      return (
        <Text size={16} color={colors.grey[600]}>
          Statements are monthly, and the outstanding balance is due{" "}
          <strong>the first day of the following month</strong>.
        </Text>
      );
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionExtend:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxExtend:
      return (
        <Text size={16} color={colors.grey[600]}>
          Statements are monthly, and the outstanding balance is due{" "}
          <strong>on the last day of the following month</strong>.
        </Text>
      );
    case ChargeCardOfferRep.ChargeCardCreditTerm.Production:
    case ChargeCardOfferRep.ChargeCardCreditTerm.Sandbox:
      return (
        <Text size={16} color={colors.grey[600]}>
          Statements are monthly, and the outstanding balance is due{" "}
          <strong>on the 15th of the following month</strong>.
        </Text>
      );
    default:
      return null;
  }
};

export default ChargeCardAutoPaySubheading;
