import { zodResolver } from "@hookform/resolvers/zod";
import dayjs, { Dayjs } from "dayjs";
import { useForm } from "react-hook-form";
import { z } from "zod";

const REQUIRED_ACTIVE_BUSINESS_DAYS = 365;
const REQUIRED_CLOSED_BOOKS_DAYS = 75;

export const isFirstSaleMonthValid = (inputDate: Dayjs) => {
  const now = dayjs();
  const earliestValidDate = now.subtract(REQUIRED_ACTIVE_BUSINESS_DAYS, "day");

  // Check if the input date's month is the same or before the expected date's month
  return inputDate.isBefore(earliestValidDate.add(1, "month"), "month");
};

export const isClosedBooksMonthValid = (inputDate: Dayjs): boolean => {
  if (!inputDate) return false;
  const now = dayjs();
  const latestValidDate = now.subtract(REQUIRED_CLOSED_BOOKS_DAYS, "day");

  // Check if the input date's month is the same or after the expected date's month
  return inputDate.isAfter(latestValidDate.subtract(1, "month"), "month");
};

const schema = z.object({
  firstSale: z
    .custom<Dayjs>((value) => Boolean(value), "Please select the date of your first sale.")
    .refine((value) => dayjs.isDayjs(value) && isFirstSaleMonthValid(value), {
      message:
        "Your business needs at least one year of sales history in order to be eligible for Highbeam Capital.",
    }),
  closedBooks: z
    .custom<Dayjs>(
      (value) => Boolean(value),
      "Please select a date when you last closed your books."
    )
    .refine((value) => dayjs(value).isValid() && isClosedBooksMonthValid(value), {
      message: `We don't accept financials older than ${dayjs()
        .subtract(REQUIRED_CLOSED_BOOKS_DAYS, "day")
        .format("MMMM YYYY")}.`,
    }),
});

export type ApplicationRequirementsFormInputs = z.infer<typeof schema>;

const useApplicationRequirementsForm = () => {
  return useForm<ApplicationRequirementsFormInputs>({
    resolver: zodResolver(schema),
  });
};

export default useApplicationRequirementsForm;
