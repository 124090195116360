import { ComponentProps, FC } from "react";
import { formatBankingDate } from "utils/date";

import TextCell from "../TextCell";

type Props = ComponentProps<typeof TextCell> & {
  date: string;
  // TODO(alex): hideCurrentYear?: boolean;
};

const DateCell: FC<Props> = ({ date, ...textCellProps }) => {
  return <TextCell {...textCellProps}>{formatBankingDate(date)}</TextCell>;
};

export default DateCell;
