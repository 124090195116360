import { ArrowRight } from "@phosphor-icons/react";
import React from "react";
import DebitCardRep from "reps/DebitCardRep";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./SelectCard.module.scss";
import { getCardTypeDescription, getCardTypeName } from "./utils";

type SelectCardOptionProps = {
  handleNext: (cardType?: DebitCardRep.CardType) => void;
  cardType: DebitCardRep.CardType;
  setCardType: (cardType: DebitCardRep.CardType) => void;
  tags?: React.ReactNode;
  img: React.ReactNode;
};

const SelectCardOption: React.FC<SelectCardOptionProps> = ({
  handleNext,
  cardType,
  setCardType,
  img,
  tags,
}) => (
  <Button
    variant="plain"
    className={styles.chooseCardButtonContainer}
    onClick={() => {
      setCardType(cardType);
      handleNext(cardType);
    }}
  >
    <div className={styles.row}>
      {img}
      <div className={styles.column}>
        <Text size={16} weight="bold">
          {getCardTypeName(cardType)}
        </Text>
        <Text size={14} weight="regular">
          {getCardTypeDescription(cardType)}
        </Text>
        <div className={styles.tagsRow}>{tags}</div>
      </div>
    </div>
    <ArrowRight size={20} className={styles.arrowRight} />
  </Button>
);

export default SelectCardOption;
