import { getCardIcon, getCardLabel, getCardMiniLabel } from "pages/cards/CardsPage/utils";
import useCard from "resources/cards/queries/useCard";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { maskAccountNumber } from "utils/account";
import { getCardName } from "utils/cards";

import styles from "./CardInfo.module.scss";

type CardInfoProps = {
  cardId: string;
  showIcon?: boolean;
  showLabel?: boolean;
  showMiniLabel?: boolean; // mini label is used to show just "Physical" or "Virtual" label
};

const CardInfo = ({
  cardId,
  showIcon = true,
  showLabel = true,
  showMiniLabel = false,
}: CardInfoProps) => {
  const card = useCard(cardId, { required: true });
  const last4digits = card?.attributes.last4Digits;
  const icon = getCardIcon(card);
  const cardLabel = getCardLabel(card);
  const cardMiniLabel = getCardMiniLabel(card);

  return (
    <div className={styles.cardCellContainer}>
      {showIcon && (
        <div className={styles.iconContainer}>
          <img className={styles.icon} src={icon} alt="Card icon" />
        </div>
      )}
      <div>
        <Text size={14} weight="medium" color={colors.grey[800]} className={styles.cardLabel}>
          {`${getCardName(card)} ${last4digits && maskAccountNumber(last4digits)}`}
        </Text>
        {showLabel && (
          <Text size={12} weight="medium" color={colors.grey[400]} className={styles.cardLabel}>
            {cardLabel}
          </Text>
        )}
        {showMiniLabel && (
          <Text size={12} weight="medium" color={colors.grey[400]} className={styles.cardLabel}>
            {cardMiniLabel}
          </Text>
        )}
      </div>
    </div>
  );
};

export default CardInfo;
