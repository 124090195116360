import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormProps } from "react-hook-form";
import { useGetFinancialInstitutionFromQueryCache } from "resources/financial-institutions/queries/useFinancialInstitution";
import accountNumberSchema from "utils/zod/accountNumberSchema";
import routingNumberSchema, { RoutingNumberSchemaParams } from "utils/zod/routingNumberSchema";
import { z } from "zod";

type AchPaymentMethodFormSchemaParams = RoutingNumberSchemaParams;

const achPaymentMethodFormSchema = ({
  fetchFinancialInstitutionByRoutingNumber,
  paymentMethod,
}: AchPaymentMethodFormSchemaParams) => {
  return z.object({
    routingNumber: routingNumberSchema({
      fetchFinancialInstitutionByRoutingNumber,
      paymentMethod,
    }),
    accountNumber: accountNumberSchema,
  });
};

export type AchPaymentMethodFormInputs = z.infer<ReturnType<typeof achPaymentMethodFormSchema>>;

type UseAchPaymentMethodFormParams = Pick<
  UseFormProps<AchPaymentMethodFormInputs>,
  "defaultValues" | "disabled"
>;

const useAchPaymentMethodForm = (params?: UseAchPaymentMethodFormParams) => {
  const getFinancialInstitutionFromQueryCache = useGetFinancialInstitutionFromQueryCache();

  return useForm({
    ...params,
    resolver: zodResolver(
      achPaymentMethodFormSchema({
        fetchFinancialInstitutionByRoutingNumber: async (routingNumber) => {
          const financialInstitution = getFinancialInstitutionFromQueryCache(routingNumber);

          if (!financialInstitution) {
            return null;
          }
          return {
            isAchSupported: financialInstitution.isAchSupported,
            isDomesticWireSupported: financialInstitution.isWireSupported,
            name: financialInstitution.name,
          };
        },
        paymentMethod: "ach",
      })
    ),
    defaultValues: {
      routingNumber: "",
      accountNumber: "",
      ...params?.defaultValues,
    },
  });
};

export default useAchPaymentMethodForm;
