import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { CAPITAL_ACCOUNTS_QUERY_KEY } from "./useCapitalAccountsQueryOptions";

const useRefreshCapitalAccountsQueries = () => {
  const refreshCapitalAccountsQueries = useRefreshQuery([CAPITAL_ACCOUNTS_QUERY_KEY]);
  return refreshCapitalAccountsQueries;
};

export default useRefreshCapitalAccountsQueries;
