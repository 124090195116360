import { ArrowFatLinesDown, ArrowFatLinesUp } from "@phosphor-icons/react";
import React from "react";
import CapitalTransactionRep from "reps/CapitalTransactionRep";
import colors from "styles/colors";
import TextCell from "ui/table/TextCell";

type Props = {
  capitalTransaction: CapitalTransactionRep.Complete;
};

const CapitalAccountTransactionTransactionTypeCell: React.FC<Props> = ({ capitalTransaction }) => {
  const isPositive = capitalTransaction.amount >= 0;

  return (
    <TextCell>
      {isPositive ? (
        <ArrowFatLinesUp size={14} color={colors.purple[500]} />
      ) : (
        <ArrowFatLinesDown size={14} color={colors.purple[500]} />
      )}
      {isPositive ? "Repayment" : "Drawdown"}
    </TextCell>
  );
};

export default CapitalAccountTransactionTransactionTypeCell;
