import clearcoLogo from "assets/clearco-logo.svg";
import genericLogo from "assets/merchants/genericbank.svg";
import shopifyLogo from "assets/shopify-logo.svg";
import wayflayerLogo from "assets/wayflyer-logo.svg";
import CreditComparisonSnapshotRep from "reps/CreditComparisonSnapshotRep";

const getCreditProviderLogo = (
  creditProvider: CreditComparisonSnapshotRep.ProviderName | string
): string => {
  switch (creditProvider) {
    case "Shopify":
      return shopifyLogo;
    case "Wayflyer":
      return wayflayerLogo;
    case "Clearco":
      return clearcoLogo;
    case "Custom":
    default:
      return genericLogo;
  }
};

export default getCreditProviderLogo;
