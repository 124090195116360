import classNames from "classnames";
import React, { ReactNode } from "react";

import styles from "./FeatureOverviewTable.module.scss";
import FeatureOverviewTableFeature from "./FeatureOverviewTableFeature";

type FeatureProps = {
  children: ReactNode;
  className?: string;
};

const FeatureOverviewTable: React.FC<FeatureProps> = ({ children, className }) => (
  <div className={classNames(styles.container, className)}>{children}</div>
);

export default Object.assign(FeatureOverviewTable, {
  Feature: FeatureOverviewTableFeature,
});
