import { CheckSquare, FlagCheckered } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import plaidLogo from "assets/plaid.svg";
import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import usePlaidLinkTokenQueryOptions from "resources/plaid-connections/queries/usePlaidLinkTokenQueryOptions";
import useRefreshPlaidConnectionsQuery from "resources/plaid-connections/queries/useRefreshPlaidConnectionsQuery";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useHighbeamPlaidLink from "utils/customHooks/useHighbeamPlaidLink";

import styles from "./HeadsUpModalStep.module.scss";

type HeadsUpModalContinueButtonProps = {
  accountType: PlaidLinkTokenRep.AccountType;
  addConnectionModalConnectionGuid?: string;
  onComplete?: () => void;
};

export const HeadsUpModalContinueButton: React.FC<HeadsUpModalContinueButtonProps> = ({
  accountType,
  addConnectionModalConnectionGuid,
  onComplete,
}) => {
  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQuery();

  const { data: plaidLinkToken } = useQuery(
    usePlaidLinkTokenQueryOptions({
      accountType: accountType,
      connectionGuid: addConnectionModalConnectionGuid,
      allowAccountSelectionOnUpdate: Boolean(addConnectionModalConnectionGuid),
    })
  );

  const { openPlaid } = useHighbeamPlaidLink({
    linkToken: plaidLinkToken ?? null,
    onComplete: () => {
      refreshPlaidConnectionsQuery();
      onComplete?.();
    },
    isNewConnection: Boolean(!addConnectionModalConnectionGuid),
  });

  return (
    <Button
      type="submit"
      variant="tertiary"
      disabled={!plaidLinkToken}
      className={styles.button}
      onClick={openPlaid}
      fullWidth
    >
      Continue
    </Button>
  );
};

type Props = HeadsUpModalContinueButtonProps;

export const HeadUpsModalStep: React.FC<Props> = ({ ...continueButtonProps }) => (
  <div className={styles.container}>
    <img src={plaidLogo} className={styles.plaidLogo} alt="plaid logo" />
    <Text size={16} align="center">
      You are about to add a new account using Plaid, here are a few things to know beforehand.
    </Text>
    <div className={styles.row}>
      <div>
        <CheckSquare size={20} />
      </div>
      <div>
        <Text size={16} weight="medium">
          Account selection
        </Text>
        <Text size={14}>
          Make sure to also select any previously selected accounts and cards to avoid disconnecting
          them.
        </Text>
      </div>
    </div>
    <div className={styles.row}>
      <div>
        <FlagCheckered size={20} />
      </div>
      <div>
        <Text size={16} weight="medium">
          Finish the flow
        </Text>
        <Text size={14}>
          Once you continue, please complete logging in through to the end, otherwise it may
          disconnect your existing login.
        </Text>
      </div>
    </div>

    <HeadsUpModalContinueButton {...continueButtonProps} />
  </div>
);
