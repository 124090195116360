import { BookOpen, Calculator, FadersHorizontal, User, UserGear } from "@phosphor-icons/react";
import { FC } from "react";
import UserRoleRep from "reps/UserRoleRep";
import { RadioCardIcon } from "ui/inputs/RadioCard";

export const UserRoleOptionIcon: FC<{ type: UserRoleRep.UserRoleType }> = ({ type }) => {
  switch (type) {
    case "Admin":
      return <RadioCardIcon Icon={UserGear} />;
    case "AccountsPayable":
      return <RadioCardIcon Icon={Calculator} />;
    case "Bookkeeper":
      return <RadioCardIcon Icon={BookOpen} />;
    case "Employee":
      return <RadioCardIcon Icon={User} />;
    case "Custom":
      return <RadioCardIcon Icon={FadersHorizontal} />;
  }
};

export const UserRoleOptionDescription: FC<{ type: UserRoleRep.UserRoleType }> = ({ type }) => {
  switch (type) {
    case "Admin":
      return (
        <>
          Can send money to anyone, create bills, create cards, apply for credit, and manage other
          users.
        </>
      );
    case "AccountsPayable":
    case "Bookkeeper":
      return (
        <>
          Can transfer money internally, create bills, view transactions, and submit payment
          requests for approval.
        </>
      );
    case "Employee":
      return (
        <>
          Can make purchases, upload receipts, and view transactions for all cards assigned to them.
        </>
      );
    case "Custom":
      return <></>;
  }
};
