import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { CARDS_QUERY_KEY } from "./useCardsQueryOptions";

const useCardPinStatusQueryOptions = (cardId: string) => {
  const unitApi = useUnitApi();

  return useQueryOptions({
    queryKey: [CARDS_QUERY_KEY, cardId, "pin-status"],
    queryFn: () => {
      return unitApi.cards.getPinStatus(cardId);
    },
  });
};

export default useCardPinStatusQueryOptions;
