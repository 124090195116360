import { captureException } from "@sentry/react";
import { useCallback, useEffect, useState } from "react";
import Pill from "ui/data-display/Pill";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import { notify } from "ui/feedback/Toast";
import Modal from "ui/overlay/Modal";
import { downloadWith404Retry } from "utils/download";

import styles from "./PreviewInvoiceModal.module.scss";

type Props = {
  onClose: () => void;
  getDownloadUrl: () => Promise<string>;
  attachmentType: "receipt" | "invoice";
};

const PreviewInvoiceModal: React.FC<Props> = ({ onClose, getDownloadUrl, attachmentType }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [url, setUrl] = useState<string>();

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    try {
      const url = await getDownloadUrl();
      const blob = await downloadWith404Retry(url);
      setUrl(window.URL.createObjectURL(blob));
    } catch (e) {
      // This modal doesn't display errors nicely. Instead, send an error toast
      // and trigger a modal close.
      notify("error", "Something went wrong displaying your invoice. Please try again.");
      captureException(e);
      onClose();
    } finally {
      setIsLoading(false);
    }
  }, [getDownloadUrl, onClose]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal title={`Preview ${attachmentType}`} onClose={onClose} bodyClassName={styles.container}>
      {isLoading ? (
        <div className={styles.placeholder}>
          <Pill color="purple-dark">
            <AnimatedSpinner />
            Loading {attachmentType}
          </Pill>
        </div>
      ) : (
        <img src={url} className={styles.invoiceImg} alt="Uploaded invoice" />
      )}
    </Modal>
  );
};

export default PreviewInvoiceModal;
