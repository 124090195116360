import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import useCapitalAccountSummary from "resources/capital-accounts/queries/useCapitalAccountSummary";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import BarChart from "ui/data-visualization/BarChart";
import Text from "ui/typography/Text";

import styles from "./ChargeCardOnboarding.module.scss";

type Props = {
  capitalAccountGuid: string;
};

const CapitalAvailableBarChart: FC<Props> = ({ capitalAccountGuid }) => {
  const capitalAccountSummary = useCapitalAccountSummary(capitalAccountGuid, { required: true });
  const availablePercentage =
    (capitalAccountSummary.available / capitalAccountSummary.details.limit) * 100;

  return (
    <div className={styles.capitalAvailableContainer}>
      <div className={styles.usageContainer}>
        <BarChart size={10} backgroundVariant="striped">
          <BarChart.Bar
            color={
              capitalAccountSummary.state === CapitalAccountRep.State.Offered
                ? "green-disabled"
                : "green"
            }
            widthPercentage={availablePercentage}
          />
        </BarChart>
        <div className={styles.usageAvailableContainer}>
          <Text size={14} color={colors.grey[500]} weight="medium">
            Available
          </Text>
          <div className={styles.usageFractionContainer}>
            <MoneyAmount
              cents={capitalAccountSummary.available}
              color={colors.grey[800]}
              size={14}
              weight="medium"
              withCents={capitalAccountSummary.available !== capitalAccountSummary.details.limit}
            />

            <Text size={14} color={colors.grey[500]} weight="medium">
              {" / "}
            </Text>

            <MoneyAmount
              cents={capitalAccountSummary.details.limit}
              color={colors.grey[500]}
              size={14}
              withCents={capitalAccountSummary.details.limit % 100 !== 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapitalAvailableBarChart;
