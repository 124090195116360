import { forwardRef, ForwardRefRenderFunction } from "react";
import {
  BillPaymentMethod,
  BillPaymentMethodType,
} from "resources/bills/queries/useBillPaymentMethods";
import PaymentMethodBankingInstitution from "resources/payee-payment-methods/components/PaymentMethodBankingInstitution";
import DropdownV2, { DropdownProps } from "ui/inputs/DropdownV2";
import Text from "ui/typography/Text";

import styles from "./BillPaymentMethodsDropdown.module.scss";

const getPaymentMethodName = (billPaymentMethodType: BillPaymentMethodType): string => {
  switch (billPaymentMethodType) {
    case "ach":
      return "ACH";
    case "domestic-wire":
      return "Wire";
    case "international-wire-local-currency":
      return "International wire ·";
    case "international-wire-usd-swift":
      return "International wire · USD";
  }
};

const getPaymentMethodDescription = (billPaymentMethodType: BillPaymentMethodType): string => {
  switch (billPaymentMethodType) {
    case "ach":
      return "Free • 1 business day or less";
    case "domestic-wire":
      return "Free • 1 business day";
    case "international-wire-local-currency":
      return "Free · 3 - 5 business days";
    case "international-wire-usd-swift":
      return "SWIFT fee • 3 - 5 business days";
  }
};

type Props = DropdownProps<BillPaymentMethod>;

const BillPaymentMethodsDropdown: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  props,
  ref
) => {
  return (
    <DropdownV2
      ref={ref}
      label="Payment method"
      getOptionLabel={(option) =>
        option?.billPaymentMethodType ? getPaymentMethodName(option.billPaymentMethodType) : ""
      }
      isClearable
      renderOption={(optionProps) => {
        const billPaymentMethod = optionProps.data;
        const payeePaymentMethod = billPaymentMethod.payeePaymentMethod;

        return (
          <DropdownV2.Option
            {...optionProps}
            description={getPaymentMethodDescription(optionProps.data.billPaymentMethodType)}
          >
            <Text weight="medium" className={styles.optionContent}>
              {optionProps.children}

              {billPaymentMethod.billPaymentMethodType === "international-wire-local-currency" &&
                !payeePaymentMethod &&
                " Local currency"}

              {payeePaymentMethod && (
                <PaymentMethodBankingInstitution payeePaymentMethod={payeePaymentMethod} />
              )}
            </Text>
          </DropdownV2.Option>
        );
      }}
      components={{
        SingleValue: (singleValueProps) => {
          const billPaymentMethod = singleValueProps.data;
          const payeePaymentMethod = billPaymentMethod.payeePaymentMethod;

          return (
            <DropdownV2.SingleValue {...singleValueProps}>
              <Text weight="medium" className={styles.optionContent}>
                {singleValueProps.children}

                {billPaymentMethod.billPaymentMethodType === "international-wire-local-currency" &&
                  !payeePaymentMethod &&
                  " Local currency"}

                {payeePaymentMethod && (
                  <PaymentMethodBankingInstitution payeePaymentMethod={payeePaymentMethod} />
                )}
              </Text>
            </DropdownV2.SingleValue>
          );
        },
      }}
      {...props}
    />
  );
};

export default forwardRef(BillPaymentMethodsDropdown);
