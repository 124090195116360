import classNames from "classnames";
import Helper from "ui/inputs/Helper";
import Text from "ui/typography/Text";

import styles from "./ActivateCard.module.scss";
import backOfChargeCard from "./back-of-charge-card.svg";
import backOfDebitCard from "./back-of-debit-card.svg";

type Props = {
  hasError: boolean;
  isDebitCard: boolean;
};

const ActivateCard: React.FC<Props> = ({ hasError, isDebitCard }) => (
  <div className={styles.container}>
    <Text size={14}>Enter the expiry date and CVV number for this card.</Text>
    <form className={styles.form}>
      <div className={styles.row}>
        <div
          id="expiryDate"
          className={classNames(styles.input, {
            [styles.error]: hasError,
          })}
        />
        <div
          id="cvv"
          className={classNames(styles.input, {
            [styles.error]: hasError,
          })}
        />
      </div>
    </form>
    {hasError && (
      <Helper icon={<Helper.Icon variant="error" />}>
        <Text size={14}>Please verify the details are correct and try again.</Text>
      </Helper>
    )}
    {isDebitCard ? (
      <img className={styles.cardBack} src={backOfDebitCard} alt="Back of Highbeam debit card" />
    ) : (
      <img className={styles.cardBack} src={backOfChargeCard} alt="Back of Highbeam Card" />
    )}
  </div>
);

export default ActivateCard;
