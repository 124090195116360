import TransactionFlexpaneCounterparty from "components/common/transaction-flexpane/TransactionFlexpaneCounterparty";
import useAllBankAccountsByUnitCoDepositAccountId from "resources/bank-accounts/queries/useAllBankAccountsByUnitCoDepositAccountId";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { formatBankingDate } from "utils/date";
import { getPaymentCounterparty } from "utils/payments";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

const CounterpartyDetail = () => {
  const { payment } = usePaymentInfoFlexpaneContext();
  const bankAccountsByUnitCoDepositAccountId = useAllBankAccountsByUnitCoDepositAccountId();
  const counterparty = getPaymentCounterparty(payment, bankAccountsByUnitCoDepositAccountId);

  return (
    <>
      <TransactionFlexpaneCounterparty>
        {counterparty.formattedName ?? counterparty.name}
      </TransactionFlexpaneCounterparty>

      <Text size={14} color={colors.grey[500]}>
        {formatBankingDate(payment.attributes.createdAt)}
      </Text>
    </>
  );
};
export default CounterpartyDetail;
