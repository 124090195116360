import { useQuery } from "@tanstack/react-query";
import TransactionsV2 from "components/TransactionsV2";
import { FC, Suspense } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import ChargeCardRepaymentRep from "reps/ChargeCardRepaymentRep";
import useChargeCardAccount from "resources/charge-cards/queries/useChargeCardAccount";
import useChargeCardRepaymentsQueryOptions from "resources/charge-cards/queries/useChargeCardRepaymentsQueryOptions";
import Shimmer from "ui/feedback/Shimmer";
import AmountCell from "ui/table/AmountCell";
import DateTimeCell from "ui/table/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";

import ChargeCardRepaymentType from "./ChargeCardRepaymentType";
import useDownloadChargeCardTransactionsCsvMutation from "./hooks/useDownloadChargeCardTransactionsCsvMutation";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const PaginationInfo: FC<Props> = ({ capitalAccountSummary }) => {
  const chargeCardAccount = useChargeCardAccount(capitalAccountSummary.guid, { required: true });
  const { data: repayments = [] } = useQuery(
    useChargeCardRepaymentsQueryOptions(chargeCardAccount.guid)
  );
  const count = repayments.length ?? 0;
  return <TransactionsV2.Filters.PaginationInfo count={count} totalCount={count} />;
};

const ExportButton: FC<Props> = ({ capitalAccountSummary }) => {
  const chargeCardAccount = useChargeCardAccount(capitalAccountSummary.guid);
  const { mutate: downloadCsv, isPending: isExportLoading } =
    useDownloadChargeCardTransactionsCsvMutation();

  if (!chargeCardAccount) return null;

  const handleClick = async () => {
    downloadCsv(chargeCardAccount.guid);
  };

  return <TransactionsV2.Filters.ExportButton isLoading={isExportLoading} onClick={handleClick} />;
};

const columns: Column<ChargeCardRepaymentRep.Complete>[] = [
  {
    title: "Date",
    cellRender: ({ createdAt }) => <DateTimeCell date={createdAt} />,
  },
  {
    title: "Transaction type",
    cellRender: () => <ChargeCardRepaymentType />,
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: ({ amount }) => (
      <AmountCell cents={amount} direction={amount >= 0 ? "positive" : "negative"} />
    ),
  },
];

const TransactionsTable: FC<Props> = ({ capitalAccountSummary }) => {
  const chargeCardAccount = useChargeCardAccount(capitalAccountSummary.guid, { required: true });
  const { data: repayments = [] } = useQuery(
    useChargeCardRepaymentsQueryOptions(chargeCardAccount.guid)
  );

  return (
    <TransactionsV2.TransactionsTable
      data={repayments}
      rowKey={({ unitCoTransactionId }) => unitCoTransactionId}
      columns={columns}
    />
  );
};

const ChargeCardRepayments: FC<Props> = ({ capitalAccountSummary }) => (
  <TransactionsV2
    title={<TransactionsV2.Title title="Highbeam Card repayments" />}
    filters={
      <TransactionsV2.Filters
        paginationInfo={
          <Suspense fallback={<Shimmer />}>
            <PaginationInfo capitalAccountSummary={capitalAccountSummary} />
          </Suspense>
        }
        exportButton={<ExportButton capitalAccountSummary={capitalAccountSummary} />}
      />
    }
    table={
      <Suspense fallback={<Table.Loading columns={columns.map(({ title }) => ({ title }))} />}>
        <TransactionsTable capitalAccountSummary={capitalAccountSummary} />
      </Suspense>
    }
  />
);

export default ChargeCardRepayments;
