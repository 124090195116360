import { Info, FileText } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import BillDetailsDocumentsView from "resources/bills/views/BillDetailsDocumentsView";
import BillDetailsInfoView from "resources/bills/views/BillDetailsInfoView";
import SegmentControls from "ui/navigation/SegmentControls";
import FullPageModal from "ui/overlay/FullPageModal";
import useElementSize from "utils/device/useElementSize";
import useTabState from "utils/tabs/useTabState";

import styles from "./BillDetailsFullPageModal.module.scss";
import BillDetailsFullPageModalHeader from "./BillDetailsFullPageModalHeader";

type BillDetailsPageProps = {
  billId: string;
  onClose: () => void;
};

const BillDetailsPage: FC<BillDetailsPageProps> = ({ billId, onClose }) => {
  const { elementRef: headerContainerRef, height: headerContainerHeight } = useElementSize();

  const tabs = {
    "bill-info": {
      icon: <Info />,
      label: "Bill info",
    },
    documents: {
      icon: <FileText />,
      label: "Documents",
    },
  };

  const [activeTab, setActiveTab] = useTabState(tabs);

  const navigate = useNavigate();

  const onCreatePayment = () => {
    navigate(`/payments/bills/payment?bills=${billId}`);
  };

  return (
    <div className={styles.container}>
      <div ref={headerContainerRef}>
        <BillDetailsFullPageModalHeader billId={billId} />

        <div className={styles.billInfoAndDocumentsSegmentControlContainer}>
          <SegmentControls
            size="sm"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
          />
        </div>
      </div>

      <div
        className={styles.bodyContainer}
        style={{ height: `calc(100% - ${headerContainerHeight}px)` }}
      >
        <div
          className={classNames(
            styles.sidebarContainer,
            activeTab === "bill-info" && styles.active
          )}
        >
          <BillDetailsInfoView
            billId={billId}
            onCreatePayment={onCreatePayment}
            onDeleteSuccessful={onClose}
            onDraftBillSavedAsOpenSuccess={onClose}
            onMarkAsPaid={onClose}
          />
        </div>

        <div
          className={classNames(
            styles.contentContainer,
            activeTab === "documents" && styles.active
          )}
        >
          <BillDetailsDocumentsView billId={billId} />
        </div>
      </div>
    </div>
  );
};

type Props = {
  billId: string;
  onClose: () => void;
};

const BillDetailsFullPageModal: FC<Props> = ({ billId, onClose }) => {
  return (
    <FullPageModal onClose={onClose}>
      <BillDetailsPage billId={billId} onClose={onClose} />
    </FullPageModal>
  );
};

export default BillDetailsFullPageModal;
