import { IconWeight, X } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, ReactElement, ReactNode, cloneElement } from "react";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./Banner.module.scss";

type BannerColor = "purple" | "red" | "orange" | "green" | "grey" | "yellow" | "white";

type BannerPadding = "compact" | "normal";

type Props = {
  icon: ReactElement<{ size: number; weight: IconWeight }>;
  color: BannerColor;
  title: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  padding?: BannerPadding;
  className?: string;

  /**
   * Called when the banner's `X` button is pressed. This also controls whether or not the `X` button is shown.
   */
  onDismiss?: () => void;
};

const Banner: FC<Props> = ({
  icon,
  title,
  body,
  padding = "normal",
  onDismiss,
  footer,
  color = "purple",
  className,
}) => (
  <div
    className={classNames(
      className,
      styles.container,
      styles[`color-${color}`],
      styles[`padding-${padding}`]
    )}
  >
    <div className={classNames(styles.main)}>
      <div className={styles.leftContainer}>
        <div className={styles.iconContainer}>
          {cloneElement(icon, {
            size: icon.props.size ?? 24,
            weight: icon.props.weight ?? "light",
          })}
        </div>

        <div className={styles.textContainer}>
          <Text size={14} weight="bold">
            {title}
          </Text>

          {body && (
            <Text size={12} weight="regular">
              {body}
            </Text>
          )}
        </div>
      </div>

      {onDismiss && (
        <Button variant="ghost" size="xs" onClick={onDismiss}>
          <X size={16} />
        </Button>
      )}
    </div>

    {footer && <div className={styles.footer}>{footer}</div>}
  </div>
);

export default Banner;
