import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useBusinessesQueryOptions = () => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: ["businesses"],
    queryFn: () => {
      return highbeamApi.business.getAll();
    },
  });
};

export default useBusinessesQueryOptions;
