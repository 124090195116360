import { FC, ReactNode } from "react";

import styles from "./TransactionsV2.module.scss";

type Props = {
  pageIndicator?: ReactNode;
};

const Footer: FC<Props> = ({ pageIndicator }) => (
  <div className={styles.footer}>{pageIndicator}</div>
);

export default Footer;
