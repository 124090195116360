import { useSuspenseQuery } from "@tanstack/react-query";
import { FC, ReactNode, createContext, useContext } from "react";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import { UNIT_PAYMENTS_QUERY_KEY } from "resources/unit-co-payments/queries/useUnitCoPaymentsQueryOptions";

type PaymentInfoFlexpaneContextState = {
  paymentId: string;
  onClose: () => void;
};

const PaymentInfoFlexpaneContext = createContext<PaymentInfoFlexpaneContextState>(
  {} as PaymentInfoFlexpaneContextState
);

type Props = {
  children: ReactNode;
  paymentId: string;
  onClose: () => void;
};

const PaymentInfoFlexpaneProvider: FC<Props> = ({ children, paymentId, onClose }) => {
  return (
    <PaymentInfoFlexpaneContext.Provider
      value={{
        paymentId: paymentId,
        onClose: onClose,
      }}
    >
      {children}
    </PaymentInfoFlexpaneContext.Provider>
  );
};

export default PaymentInfoFlexpaneProvider;

export const usePaymentInfoFlexpaneContext = () => {
  const { paymentId, onClose } = useContext(PaymentInfoFlexpaneContext);
  const unitApi = useUnitApi();

  const { data: payment } = useSuspenseQuery({
    queryKey: [UNIT_PAYMENTS_QUERY_KEY, { paymentId }],
    queryFn: async () => {
      const { data } = await unitApi.payments.get(paymentId);
      return data;
    },
  });

  return {
    payment,
    onClose,
  };
};
