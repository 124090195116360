import { useSuspenseQuery } from "@tanstack/react-query";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import useChargeCardRepaymentInfoQueryOptions from "./useChargeCardRepaymentInfoQueryOptions";

const useChargeCardRepaymentInfo = <TRequired extends boolean>(
  chargeCardAccountGuid: string,
  params?: RequiredParam<TRequired>
) => {
  const { data } = useSuspenseQuery(useChargeCardRepaymentInfoQueryOptions(chargeCardAccountGuid));

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useChargeCardRepaymentInfo;
