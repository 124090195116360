import { atom, selector } from "recoil";
import jwtState from "state/auth/jwt";
import { getBusinessGuidFromJwt } from "utils/auth";
import { localStorageEffect } from "utils/recoil";

/**
 * In the rare case where a Highbeam superuser wants to see other accounts for a
 * selected businessGuid, the businessGuid would be stored in local storage.
 * For most purposes, `businessGuidState` should be used instead.
 */
export const selectedBusinessGuidState = atom<string>({
  key: "auth/selectedBusinessGuid",
  default: "",
  effects: [localStorageEffect("businessGuid")],
});

/**
 * businessGuidState acts as an intermediate layer between businessState and
 * jwtState to suppress re-rendering if the `businesses` value in the jwt state
 * does not change.
 */

const businessGuidState = selector<string>({
  key: "auth/businessGuid",
  get: async ({ get }) => {
    const jwt = get(jwtState);
    const selectedBusinessGuid = get(selectedBusinessGuidState);
    if (selectedBusinessGuid && jwt.roles.includes("SUPERUSER")) {
      return selectedBusinessGuid;
    }

    return getBusinessGuidFromJwt(jwt);
  },
});

export default businessGuidState;
