import { Coins } from "@phosphor-icons/react";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import { Navigate } from "react-router-dom";
import RequestAdditionalCapitalButton from "resources/capital-accounts/components/RequestAdditionalCapitalButton";
import useEnabledCapitalAccounts from "resources/capital-accounts/queries/useEnabledCapitalAccounts";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

// TODO(alex): We will move this view within the `CapitalOverviewPage` when or before rolling out `CAPITAL_OVERVIEW_PAGE`.
import UpsellCapitalView from "../CapitalAccountPage/views/UpsellCapitalView";

import CapitalAccountsList from "./components/CapitalAccountsList";

const CapitalOverviewContent = () => {
  const enableCapitalOverviewPage = useFeatureFlag("CAPITAL_OVERVIEW_PAGE");
  const enabledCapitalAccounts = useEnabledCapitalAccounts();

  if (enabledCapitalAccounts.length === 0) {
    return <UpsellCapitalView />;
  }

  // NB(alex): We can delete this redirect logic once we fully roll out the `CAPITAL_OVERVIEW_PAGE` flag.
  if (!enableCapitalOverviewPage && enabledCapitalAccounts.length === 1) {
    return <Navigate to={`/capital/${enabledCapitalAccounts[0].guid}`} />;
  }

  return (
    <>
      <DashboardPage.Header actions={<RequestAdditionalCapitalButton />}>
        <DashboardPage.Header.IconTile icon={<Coins />} />
        <DashboardPage.Header.Title>Capital</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        <CapitalAccountsList />
      </DashboardPage.Section>
    </>
  );
};

const CapitalOverviewPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Capital</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CapitalOverviewContent />
      </DashboardPage>
    </>
  );
};

export default CapitalOverviewPage;
