import logo from "assets/highbeam-logo.svg";
import env from "env";
import { Link } from "react-router-dom";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./ArchivedCustomerPage.module.scss";

const ArchivedCustomerPage = () => (
  <div className={styles.container}>
    <div className={styles.headerContainer}>
      <img src={logo} className={styles.logo} alt="logo" />
      <Link to="/sign-out">
        <Text size={14} weight="medium">
          Logout
        </Text>
      </Link>
    </div>
    <div className={styles.content}>
      <Text size={20} weight="bold" color={colors.grey[900]}>
        Account archived
      </Text>
      <Text size={14} color={colors.grey[600]}>
        This account has been archived and cannot be accessed. If you think this was a mistake,
        reach out to{" "}
        <a
          href={`mailto:${env.HIGHBEAM_SUPPORT_EMAIL_ADDRESS}?subject=Archived+account`}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          {env.HIGHBEAM_SUPPORT_EMAIL_ADDRESS}
        </a>
      </Text>
    </div>
  </div>
);

export default ArchivedCustomerPage;
