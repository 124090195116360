import { ErrorBoundary } from "@sentry/react";
import classNames from "classnames";
import ErrorCard from "components/error/ErrorCard";
import { FC, ReactNode, Suspense } from "react";
import DotsLoader from "ui/feedback/DotsLoader";

import styles from "./DashboardPage.module.scss";
import Header from "./Header";
import Section from "./Section";

type DashboardPageContainerProps = {
  children: ReactNode;
  className?: string;
};

const DashboardPageContainer: FC<DashboardPageContainerProps> = ({ children, className }) => (
  <div className={classNames(styles.page, className)}>
    <ErrorBoundary
      fallback={({ error }) => (
        <div className={styles.errorCardContainer}>
          <ErrorCard error={error} />
        </div>
      )}
    >
      <Suspense
        fallback={
          <div className={styles.dotsContainer}>
            <DotsLoader dots={5} />
          </div>
        }
      >
        <div className={styles.content}>{children}</div>
      </Suspense>
    </ErrorBoundary>
  </div>
);

const DashboardPage = Object.assign(DashboardPageContainer, {
  Header: Header,
  Section: Section,
});

export default DashboardPage;
