import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useCapitalDrawdownApprovalsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const capitalAccountGuid = capitalAccountSummary.guid;

  return useQueryOptions({
    queryKey: ["capitalDrawdownApprovals", { businessGuid, capitalAccountGuid }],
    queryFn: () => {
      return highbeamApi.capitalDrawdownApproval.get(capitalAccountGuid, businessGuid);
    },
  });
};

export default useCapitalDrawdownApprovalsQueryOptions;
