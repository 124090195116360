import { Warning } from "@phosphor-icons/react";
import classNames from "classnames";
import env from "env";
import Text from "ui/typography/Text";

import styles from "./InvalidInvitation.module.scss";

const InvalidInvitation = () => (
  <div className={styles.container}>
    <Warning weight="fill" size={40} className={styles.icon} />
    <div className={styles.text}>
      <Text size={16} weight="bold" className={classNames(styles.text, styles.secondary)}>
        We couldn’t find that invitation.
      </Text>
      <Text size={16} className={classNames(styles.text, styles.primary)}>
        Please contact the person who sent it to you.
      </Text>
      <Text size={16} className={classNames(styles.text, styles.secondary)}>
        If you think this is a mistake or if the problem persists, contact{" "}
        <a
          href={`mailto:${env.HIGHBEAM_SUPPORT_EMAIL_ADDRESS}?subject=Invalid+user+invitation`}
          target="_blank"
          rel="noreferrer"
        >
          {env.HIGHBEAM_SUPPORT_EMAIL_ADDRESS}
        </a>
        .
      </Text>
    </div>
  </div>
);

export default InvalidInvitation;
