import { ArrowRight } from "@phosphor-icons/react";
import Emoji from "components/Emoji";
import DashboardPage from "components/layouts/DashboardPage";
import { Link } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import colors from "styles/colors";
import Teaser from "ui/data-display/Teaser";
import TeaserMainSection from "ui/data-display/Teaser/TeaserMainSection";
import Button from "ui/inputs/Button";
import Heading2 from "ui/typography/Heading2";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useIsAllowedToAcceptLineOfCredit from "utils/permissions/useIsAllowedToAcceptLineOfCredit";

import CapitalFeatureOverviewTable from "../../components/CapitalFeatureOverviewTable";
import CapitalHeaderTitle from "../../components/CapitalHeaderTitle";
import { NotAllowedToActivateLineOfCreditContent } from "../../constants";

import ChargeCardSummaryItems from "./ChargeCardSummaryItems";
import LineOfCreditSummaryItems from "./LineOfCreditSummaryItems";
import styles from "./OfferedCapitalView.module.scss";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const OfferedCapitalView: React.FC<Props> = ({ capitalAccountSummary }) => {
  const enableCapitalOverviewPage = useFeatureFlag("CAPITAL_OVERVIEW_PAGE");
  const isAllowedToAcceptLineOfCredit = useIsAllowedToAcceptLineOfCredit();
  const offeredCapitalHeading = `Your ${
    capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly
      ? "Highbeam Card"
      : "revolving line of credit"
  } is ready!`;

  return (
    <>
      <DashboardPage.Header>
        {enableCapitalOverviewPage && <DashboardPage.Header.BackCaret to="/capital" />}
        <CapitalHeaderTitle />
      </DashboardPage.Header>

      <DashboardPage.Section>
        <Teaser className={styles.teaser}>
          <TeaserMainSection className={styles.teaser}>
            <Emoji className={styles.emoji}>🎉</Emoji>
            <div className={styles.teaserText}>
              <Heading2>{offeredCapitalHeading}</Heading2>
              <Text size={16} color={colors.grey[500]}>
                Accept your offer to get started!
              </Text>
            </div>

            {capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly ? (
              <ChargeCardSummaryItems />
            ) : (
              <LineOfCreditSummaryItems />
            )}

            <Link to={`/capital/${capitalAccountSummary.guid}/review-line-offer/how-it-works`}>
              <Button
                className={styles.teaserButton}
                variant="primary"
                disabled={!isAllowedToAcceptLineOfCredit}
                tooltip={
                  !isAllowedToAcceptLineOfCredit && <NotAllowedToActivateLineOfCreditContent />
                }
              >
                <div className={styles.acceptButton}>
                  <Text size={14}>Review and accept</Text>
                  <ArrowRight size={14} />
                </div>
              </Button>
            </Link>
          </TeaserMainSection>
        </Teaser>
      </DashboardPage.Section>

      {capitalAccountSummary.type !== CapitalAccountRep.Type.ChargeCardOnly && (
        <DashboardPage.Section>
          <CapitalFeatureOverviewTable />
        </DashboardPage.Section>
      )}
    </>
  );
};

export default OfferedCapitalView;
