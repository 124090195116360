import AccentCard from "ui/data-display/AccentCard";
import Text from "ui/typography/Text";

import styles from "./EmptyState.module.scss";

type Props = {
  image: string;
  primaryText: string;
  secondaryText?: string;
};

const EmptyState: React.FC<Props> = ({ image, primaryText, secondaryText }) => (
  <AccentCard className={styles.container}>
    <img src={image} alt={primaryText} height={96} />
    <Text size={16} weight="medium" align="center">
      {primaryText}
    </Text>
    {secondaryText && (
      <Text size={12} align="center" className={styles.secondaryText}>
        {secondaryText}
      </Text>
    )}
  </AccentCard>
);

export default EmptyState;
