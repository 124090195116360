namespace BankApplicationRep {
  export enum Status {
    FormCreated = "FormCreated",
    AwaitingDocuments = "AwaitingDocuments",
    Pending = "Pending",
    PendingReview = "PendingReview",
    Approved = "Approved",
    Denied = "Denied",
  }

  export interface Creation {
    readonly businessGuid: string;
  }

  export interface Complete {
    readonly status: Status;
    readonly url: string | null;
  }
}

export default BankApplicationRep;
