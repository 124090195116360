import { Card } from "@highbeam/unit-node-sdk";
import { getCardIcon, getCardLabel } from "pages/cards/CardsPage/utils";
import Text from "ui/typography/Text";
import { getCardName } from "utils/cards";

import styles from "./CardModalInfo.module.scss";

type Props = {
  card: Card | undefined;
};

const CardModalInfo: React.FC<Props> = ({ card }) => {
  if (!card) return null;

  const cardName = getCardName(card);
  const icon = getCardIcon(card);
  const label = getCardLabel(card);

  return (
    <div className={styles.info}>
      <img src={icon} alt="card" />
      <div>
        <Text weight="medium" size={14}>
          {cardName ?? "Highbeam Card"}
        </Text>
        <Text className={styles.type} size={12}>
          {label}
        </Text>
      </div>
    </div>
  );
};

export default CardModalInfo;
