import SparklingStarLogo from "assets/sparkling-star.svg?react";
import classNames from "classnames";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "../HighbeamCard.module.scss";

const VirtualCreditCardPill = () => (
  <div className={styles.rowWithGap}>
    <div className={classNames(styles.row, styles.cardTypePill, styles.virtualCreditCardTypePill)}>
      <div className={styles.cardTypePillTextContainer}>
        <SparklingStarLogo fill={colors.black} className={styles.sparklingStarLogo} />
        <Text size={12} weight="medium" color={colors.black} align="center">
          Virtual
        </Text>
      </div>
    </div>
  </div>
);

export default VirtualCreditCardPill;
