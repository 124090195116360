import { captureException } from "@sentry/react";
import env from "env";
import { useEffect, useState } from "react";
import useGetUnitCoSensitiveTokenWithMfa from "resources/unit-co-customer-token/hooks/useGetUnitCoSensitiveTokenWithMfa";
import colors from "styles/colors";
import { notify } from "ui/feedback/Toast";
import { getBaseVgsCss } from "utils/cards";
import useImportScript from "utils/customHooks/useImportScript";

// JS library imported in hook below
declare const VGSCollect: any;

const VGS_INPUT_CSS = {
  ...getBaseVgsCss(),
  "letter-spacing": "0.02em",
  color: colors.grey[600],
};

export const useChangePinModal = (
  handleClose: (reload: boolean) => void,
  cardName: string | null,
  cardId: string
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [vgsForm, setVgsForm] = useState<any>(null);

  const getUnitCoSensitiveTokenWithMfa = useGetUnitCoSensitiveTokenWithMfa({
    scopes: ["CARDS_SENSITIVE_WRITE"],
  });

  const vgsLoaded = useImportScript(
    `https://js.verygoodvault.com/vgs-collect/2.9.0/vgs-collect.js`
  );

  const changePin = async () => {
    if (!vgsForm) {
      return;
    }
    setIsLoading(true);
    setFormError(undefined);
    try {
      const sensitiveToken = await getUnitCoSensitiveTokenWithMfa();

      vgsForm.submit(
        `/cards/${cardId}/secure-data/pin`,
        {
          method: "PUT",
          // This converts the dot-separated field name strings into a JSON object
          mapDotToObject: "true",
          headers: {
            "Content-Type": "application/vnd.api+json",
            Authorization: `Bearer ${sensitiveToken}`,
          },
        },
        (status: any, data: any) => {
          setIsLoading(false);
          if (status === 200) {
            // hacky fix for VGS postMessage error, see PR#996
            setTimeout(() => {
              handleClose(true);
              notify("success", `PIN changed for "${cardName}"`);
            }, 100);
          } else if (
            status === 400 &&
            data.errors &&
            data.errors[0].title === "Incorrect or invalid PIN."
          ) {
            setFormError("The old PIN is incorrect.");
          } else {
            notify("error", "Failed to change PIN");
            captureException(
              new Error(`VGS returned with status ${status} and data ${JSON.stringify(data)}`)
            );
          }
        },
        (errors: any) => {
          setIsLoading(false);
          if (errors) {
            if (errors["data.attributes.oldPin"]) {
              setFormError("The old PIN is incorrect.");
            } else if (
              errors["data.attributes.newPin"] &&
              errors["data.attributes.newPin"]["isEmpty"]
            ) {
              setFormError("Please enter a new PIN to continue.");
            } else if (
              errors["data.attributes.newPin"] &&
              !errors["data.attributes.newPin"]["isValid"]
            ) {
              setFormError("Please make sure the PIN is 4 digits.");
            } else {
              setFormError("Please verify the details are correct.");
            }
          }
        }
      );
    } catch (e) {
      notify("error", "Failed to change PIN");
      captureException(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (vgsLoaded && !vgsForm) {
      const form = VGSCollect.create(env.UNIT_VGS_VAULT_ID, env.UNIT_VGS_ENVIRONMENT, () => {});
      form.on("enterPress", () => changePin());
      const oldCCPin = form.field("#cc-old-pin", {
        type: "password",
        name: "data.attributes.oldPin",
        placeholder: "Enter PIN",
        maxLength: 4,
        validations: ["required", "/^([0-9]{4})$/"],
        css: VGS_INPUT_CSS,
      });
      const newCCPin = form.field("#cc-new-pin", {
        type: "password",
        name: "data.attributes.newPin",
        placeholder: "Enter PIN",
        maxLength: 4,
        validations: ["required", "/^([0-9]{4})$/"],
        css: VGS_INPUT_CSS,
      });
      Promise.all([oldCCPin.promise, newCCPin.promise]).then(() => {
        setVgsForm(form);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vgsForm, vgsLoaded]);

  const handleReset = () => {
    setIsLoading(false);
    setFormError(undefined);
    setVgsForm(null);
  };

  return {
    changePin,
    handleReset,
    isLoading,
    formError,
  };
};
