import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import HighbeamAccountIcon from "resources/bank-accounts/components/HighbeamAccountIcon";
import useCapitalAccountAutoPayBankAccount from "resources/capital-accounts/queries/useCapitalAccountAutoPayBankAccount";
import BankAccountV2 from "ui/data-display/BankAccountV2";
import HighlightItem, {
  HighlightItemSideRuleColor,
  HighlightItemSize,
} from "ui/data-display/HighlightItem";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  size?: HighlightItemSize;
  sideRuleColor?: HighlightItemSideRuleColor;
};

const CapitalAccountAutoPayBankAccountHighlightItem: FC<Props> = ({
  capitalAccountSummary,
  size,
  sideRuleColor,
}) => {
  const capitalAccountAutoPayBankAccount =
    useCapitalAccountAutoPayBankAccount(capitalAccountSummary);

  return (
    <HighlightItem
      size={size}
      sideRuleColor={sideRuleColor}
      label="Auto-pay account"
      value={
        <BankAccountV2
          accountName={capitalAccountAutoPayBankAccount.name}
          accountNumber={capitalAccountAutoPayBankAccount.accountNumber}
          bankIcon={
            <HighbeamAccountIcon
              highbeamTypeName={capitalAccountAutoPayBankAccount.highbeamType.name}
            />
          }
        />
      }
      subtext={
        <>
          Balance: <MoneyAmount cents={capitalAccountAutoPayBankAccount.availableBalance} />
        </>
      }
    />
  );
};

export default CapitalAccountAutoPayBankAccountHighlightItem;
