import { useRecoilValue } from "recoil";
import { selectedBusinessGuidState } from "state/auth/businessGuid";
import { useIsSuperusering } from "state/auth/isSuperusering";
import { getBusinessGuidFromJwt } from "utils/auth";
import { DataRequired, LoginRequiredParam } from "utils/react-query/require-data";

import useJwt from "./useJwt";

const useBusinessGuid = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  const jwt = useJwt(params);

  const selectedBusinessGuid = useRecoilValue(selectedBusinessGuidState);
  const isSuperusering = useIsSuperusering();
  if (isSuperusering) {
    return selectedBusinessGuid;
  }

  return (jwt ? getBusinessGuidFromJwt(jwt) : null) as DataRequired<string, TRequired>;
};

export default useBusinessGuid;
