import { NotePencil } from "@phosphor-icons/react";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import MultiStep from "components/layouts/MultiStep";
import { Suspense } from "react";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import {
  CapitalAccountSummaryProvider,
  useCapitalAccountSummaryContext,
} from "resources/capital-accounts/context/capital-account-summary-context";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToAcceptLineOfCredit from "utils/permissions/useIsAllowedToAcceptLineOfCredit";

import ReviewLineOfferSteps from "./components/ReviewLineOfferSteps";
import ReviewLineOfferProvider from "./context/ReviewLineOfferProvider";
import AgreementView from "./views/AgreementView";
import AutoPayView from "./views/AutoPayView";
import HowItWorksView from "./views/HowItWorksView";
import OfferAcceptedView from "./views/OfferAcceptedView";
import styles from "./views/ReviewLineOfferViews.module.scss";
import ReviewView from "./views/ReviewView";

const ReviewLineOfferPageContent = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const isAllowedToAcceptLineOfCredit = useIsAllowedToAcceptLineOfCredit();
  const { pathname } = useLocation();
  const isOfferAcceptedPathname =
    pathname === `/capital/${capitalAccountSummary.guid}/review-line-offer/offer-accepted`;

  if (!isAllowedToAcceptLineOfCredit) {
    return <Navigate to={`/capital/${capitalAccountSummary.guid}`} />;
  }

  return (
    <>
      {!isOfferAcceptedPathname ? (
        <DashboardPage>
          <DashboardPage.Header>
            <DashboardPage.Header.BackCaret to={`/capital/${capitalAccountSummary.guid}`} />
            <DashboardPage.Header.IconTile icon={<NotePencil />} />
            <DashboardPage.Header.Title>Review line offer</DashboardPage.Header.Title>
          </DashboardPage.Header>

          <DashboardPage.Section>
            <ReviewLineOfferProvider>
              <MultiStep steps={<ReviewLineOfferSteps />}>
                <Routes>
                  <Route
                    index
                    element={
                      <Navigate
                        to={`/capital/${capitalAccountSummary.guid}/review-line-offer/how-it-works`}
                      />
                    }
                  />
                  <Route path="/how-it-works" element={<HowItWorksView />} />
                  <Route path="/auto-pay" element={<AutoPayView />} />
                  <Route path="/agreement" element={<AgreementView />} />
                  <Route path="/review" element={<ReviewView />} />
                </Routes>
              </MultiStep>
            </ReviewLineOfferProvider>
          </DashboardPage.Section>
        </DashboardPage>
      ) : (
        // Render this page after the offer is accepted, with different styling
        <DashboardPage className={styles.offerAcceptedPage}>
          <Routes>
            <Route path="/offer-accepted" element={<OfferAcceptedView />} />
          </Routes>
        </DashboardPage>
      )}
    </>
  );
};

const CapitalAccountNameBreadcrumb = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  return (
    <Breadcrumbs.Item to={`/capital/${capitalAccountSummary.guid}`}>
      {capitalAccountSummary.name}
    </Breadcrumbs.Item>
  );
};

const ReviewLineOfferPage = () => {
  const { capitalAccountGuid } = useParams();

  // Redirect to capital page if no capitalAccountGuid
  if (!capitalAccountGuid) {
    return <Navigate to="/capital" />;
  }

  return (
    <CapitalAccountSummaryProvider capitalAccountGuid={capitalAccountGuid}>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>
          <Suspense fallback={<Breadcrumbs.ItemShimmer />}>
            <CapitalAccountNameBreadcrumb />
          </Suspense>
          <Breadcrumbs.CurrentItem>Review line offer</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <ReviewLineOfferPageContent />
    </CapitalAccountSummaryProvider>
  );
};

export default ReviewLineOfferPage;
