import Shimmer from "ui/feedback/Shimmer";

const SingleStatementLoading = () => (
  <div>
    <Shimmer />
    <button>
      <Shimmer />
    </button>
  </div>
);

const AccountsStatementsLoading = () => (
  <>
    <div>
      <Shimmer />
      <Shimmer />
    </div>
    <SingleStatementLoading />
    <SingleStatementLoading />
    <SingleStatementLoading />
    <SingleStatementLoading />
  </>
);

export default AccountsStatementsLoading;
