type InstitutionIdtoNameMap = {
  [key: string]: string;
};

/* eslint-disable camelcase */
export const institutionIdtoNameMap: InstitutionIdtoNameMap = {
  ins_3: "Chase",
  ins_4: "Wells Fargo",
  ins_5: "Citibank Online",
  ins_7: "USAA",
  ins_10: "American Express",
  ins_12: "Fidelity",
  ins_13: "PNC",
  ins_14: "TD Bank",
  ins_15: "Navy Federal Credit Union",
  ins_19: "Regions Bank",
  ins_20: "Citizens Bank",
  ins_21: "Huntington Bank",
  ins_22: "PayPal",
  ins_25: "Ally Bank",
  ins_26: "Fifth Third Bank",
  ins_27: "M&T Bank",
  ins_28: "Santander - Personal",
  ins_29: "KeyBank",
  ins_31: "Union Bank",
  ins_32: "Woodforest National Bank",
  ins_33: "Discover",
  ins_35: "Chime",
  ins_36: "State Employees' Credit Union (NC)",
  ins_52: "Marcus by Goldman Sachs",
  ins_53: "SVB Online",
  ins_54: "Robinhood",
  ins_56: "Chase",
  ins_100103: "Comerica Bank",
  ins_100866: "Bank of America - Charitable Gift Fund",
  ins_101550: "ESL Federal Credit Union",
  ins_101554: "Redstone Federal Credit Union",
  ins_101559: "Hudson Valley Credit Union",
  ins_101561: "Wright-Patt Credit Union",
  ins_103548: "Old National Bank",
  ins_103549: "OneUnited Bank",
  ins_104809: "Keesler Federal Credit Union",
  ins_106769: "Quakertown National Bank (QNB)",
  ins_109454: "Yampa Valley Bank",
  ins_109534: "Eastman Credit Union",
  ins_109535: "Educational Employees Credit Union (EECU)",
  ins_109551: "IH Mississippi Valley Credit Union",
  ins_109630: "Dort Federal Credit Union",
  ins_109701: "Jovia Financial",
  ins_109719: "GECU",
  ins_109726: "Quincy Credit Union",
  ins_109903: "Xplore Federal Credit Union",
  ins_109904: "Zeal Credit Union",
  ins_110019: "First Citizens Bank - Digital Banking",
  ins_110417: "IBC Bank",
  ins_110429: "Robins Financial Credit Union",
  ins_110430: "Umpqua Bank - Personal",
  ins_110464: "Lincoln Savings Bank (IA) - Online Banking",
  ins_110465: "Trustmark",
  ins_110603: "ESL Federal Credit Union - Business",
  ins_110938: "First National Bank Texas & First Convenience Bank",
  ins_111000: "Heartland Bank and Trust Company",
  ins_111013: "Kemba Credit Union",
  ins_111025: "Indiana Members Credit Union",
  ins_111436: "Qualstar Credit Union",
  ins_111573: "Y-12 Federal Credit Union",
  ins_111611: "The Commercial & Savings Bank (Millersburg, OH)",
  ins_111636: "JAX Federal Credit Union",
  ins_111972: "Arvest Bank - Online Banking",
  ins_111997: "Walmart - Money Network Pa Card",
  ins_111998: "Walmart MoneyCard by Green Dot",
  ins_112521: "Queenstown Bank - Personal",
  ins_113128: "Kemba Financial Credit Union",
  ins_131932: "SVB Go",
  ins_113806: "Idaho Central Credit Union",
  ins_113824: "ZIA Credit Union",
  ins_113973: "Zions Bank",
  ins_113976: "OnPoint Community Credit Union",
  ins_114054: "OceanFirst Bank - Personal",
  ins_114115: "Farmers & Merchants Bank (ME Banking) - Personal",
  ins_114157: "American Express - Personal Savings",
  ins_114213: "Desert Financial Credit Union",
  ins_114437: "Yakima Federal Savings & Loan - Online Banking",
  ins_114512: "Bank 7 - Personal",
  ins_114860: "Wisely (formerly Global Cash Card)",
  ins_114869: "Veridian Credit Union",
  ins_115035: "Mountain Valley Bank (Dunlap, TN)",
  ins_115054: "Bank of Clarkson",
  ins_115581: "VyStar Credit Union",
  ins_115585: "Boeing Employees Credit Union (BECU) - Personal Online Banking",
  ins_115586: "Delta Community Credit Union",
  ins_115587: "First Horizon Bank - Digital Banking",
  ins_115616: "Vanguard",
  ins_115686: "Randolph Brooks Federal Credit Union",
  ins_116196: "Lake Michigan Credit Union - Personal and Business",
  ins_116525: "First Hawaiian Bank",
  ins_116594: "Novo Bank",
  ins_116618: "Founders Credit Union",
  ins_116739: "Fidelity - Charitable Gift Fund",
  ins_116794: "Mercury",
  ins_116798: "PenFed Credit Union",
  ins_116799: "HAPO Community Credit Union",
  ins_116862: "Axos Bank",
  ins_116939: "Trustmark - Mortgage",
  ins_116953: "Netspend All-Access Account by MetaBank",
  ins_116960: "M1 Finance",
  ins_117003: "Police and Fire Federal Credit Union",
  ins_117145: "Eastern Bank (MA)",
  ins_117179: "Dave",
  ins_117248: "ACE Flare Account by Metabank",
  ins_117321: "Aspiration",
  ins_117414: "Empower",
  ins_117706: "Local Government Federal Credit Union",
  ins_118128: "CitiBusiness",
  ins_118197: "Green Dot Prepaid Debit Card",
  ins_118390: "Oxygen",
  ins_118395: "Fidelity - NetBenefits",
  ins_118580: "Citizens Bank - Investment Services",
  ins_119423: "TD Ameritrade (Investments)",
  ins_119490: "Umpqua Bank - Business",
  ins_120000: "Golden 1 Credit Union",
  ins_120012: "Bank of the West - Personal & Small Business",
  ins_120013: "America First Credit Union",
  ins_120018: "Langley Federal Credit Union - Personal",
  ins_120162: "Wellby Financial",
  ins_120184: "TD Ameritrade (Advisor Client)",
  ins_120473: "XCEL Credit Union",
  ins_123314: "Prosperity Bank",
  ins_123320: "Pinnacle Financial Partners - TN",
  ins_125503: "OceanFirst Bank - Business",
  ins_125686: "Mountain Valley Bank (Walden, CO)",
  ins_126249: "Rho",
  ins_126340: "Lili",
  ins_127247: "Umpqua Bank - Mortgage",
  ins_127312: "Qapital",
  ins_127888: "Brex",
  ins_127892: "GO2bank",
  ins_127948: "U.S. Bank ReliaCard",
  ins_127953: "KeyBank Prepaid - Key2Benefits",
  ins_127989: "Bank of America",
  ins_127990: "U.S. Bank",
  ins_127991: "Wells Fargo",
  ins_128026: "Capital One",
  ins_129272: "Cred",
  ins_129417: "ONE",
  ins_129418: "Found",
  ins_129570: "card.com - Premium Bank Account",
  ins_129571: "Bank21",
  ins_129579: "Nearside Business Checking",
  ins_129591: "The Bank (Oberlin, KS)",
  ins_129642: "Zions Bank - Mortgage",
  ins_129666: "First Horizon Bank - Business",
  ins_129736: "First National Bank of Pana",
  ins_129756: "Shell Western States Credit Union",
  ins_129892: "Community Bank (Wichita, KS)",
  ins_129896: "Zeta",
  ins_129907: "UBS - Online Services",
  ins_129920: "National Bank of St. Anne",
  ins_130022: "Clair",
  ins_130046: "Sable",
  ins_130054: "Hightop",
  ins_130084: "TFNB",
  ins_130209: "5th Bank of Eastern Fargo",
  ins_130217: "Ally Bank - Home Loan",
  ins_130242: "Ocala Community Credit Union",
  ins_130251: "Greensboro Municipal Credit Union",
  ins_130259: "Jelli",
  ins_130332: "First Community Bank & Trust (IL) - Personal",
  ins_130347: "Chiphone Credit Union",
  ins_130358: "Equitable Bank",
  ins_130393: "PrimeSource Credit Union",
  ins_130479: "L.C.E. Credit Union",
  ins_130955: "MoneyLion - RoarMoney",
  ins_130957: "Wethos",
  ins_130958: "Bridge",
  ins_130962: "Enzo",
  ins_130966: "Tribevest",
  ins_130973: "Hancock Whitney",
  ins_130978: "First Bank",
  ins_131089: "Moves",
  ins_131512: "Freedom Bank (Elkader, IA)",
  ins_131515: "Garden FI Bank",
  ins_131516: "Glitch County CU",
  ins_131533: "Bank 7 - Business",
  ins_131815: "Invoice2go Money",
  ins_131827: "First New Mexico Bank - Las Cruces, NM",
  ins_131858: "First National Bank (Manning, IA)",
  ins_131862: "A+ Federal Credit Union",
  ins_131893: "First New Mexico Bank - Deming, NM",
  ins_131894: "Citizens Bank Of Chatsworth (IL)",
  ins_131897: "Mos",
  ins_131902: "Fundbox",
  ins_131919: "Sagewell",
  ins_131926: "X1 Card",
  ins_131939: "Creative Juice",
  ins_131957: "Benepass",
  ins_131968: "AngelList Stack",
  ins_131973: "Novel",
  ins_131976: "Levro",
  ins_131986: "Ellis",
  ins_131989: "MIDFLORIDA Credit Union",
  ins_132102: "Boeing Employees Credit Union (BECU) - Business Online Banking",
  ins_132109: "Grant County Bank - Ulysses, KS",
  ins_132117: "Tri-Valley Bank - Randolph, IA",
  ins_132134: "Lincoln Federal Savings Bank of Nebraska",
  ins_132135: "Prime Alliance Bank",
  ins_132150: "First State Bank",
  ins_132151: "Yampa Valley Bank - Business",
  ins_132201: "Fundomate",
  ins_132204: "The Farmers Savings Bank (Spencer, OH)",
  ins_132254: "Citizens Bank (Amersterdam, MO)",
  ins_132255: "Ivella",
  ins_132257: "Security State Bank (Chancellor, SD)",
  ins_132285: "WaFd Bank",
  ins_132442: "Shopify Balance",
  ins_132462: "Ampla",
  ins_132706: "Highbeam",
};
