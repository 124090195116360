import classNames from "classnames";
import { ComponentProps, FC } from "react";
import Dropdown from "ui/inputs/Dropdown";

import styles from "./TransactionsV2.module.scss";

type Props = ComponentProps<typeof Dropdown> & { wide?: boolean };

const DropdownFilter: FC<Props> = ({ wide = false, ...dropdownProps }) => (
  <Dropdown
    label="Show transactions from"
    className={classNames(styles.dropdown, wide && styles.wide)}
    isSearchable={false}
    {...dropdownProps}
  />
);

export default DropdownFilter;
