import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import useInternationalWireQuoteQueryOptions from "resources/international-wires/queries/useInternationalWireQuoteQueryOptions";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import ShimmerV2 from "ui/feedback/ShimmerV2";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./ExchangeRateQuote.module.scss";

type Props = TextProps & {
  currency: string; // TODO(alex): string union
};

const ExchangeRateQuote: FC<Props> = ({ currency, ...textProps }) => {
  // TODO(alex): HB-5295 Figure out how to share queries across components.
  const { data: quote, isPending } = useQuery(useInternationalWireQuoteQueryOptions(currency));

  return (
    <Text numeric {...textProps}>
      {isPending ? (
        <ShimmerV2 className={styles.shimmer} />
      ) : (
        <>
          {quote ? (
            <>
              1 USD ={" "}
              <MoneyAmount
                cents={quote.rate * 100}
                currency={currency}
                trailingCurrencyCode
                {...textProps} // NB(alex): It was a mistake to set default values for `MoneyAmount`. It should inherit the parent's styles.
              />
            </>
          ) : (
            "Rate not found"
          )}
        </>
      )}
    </Text>
  );
};

export default ExchangeRateQuote;
