import classNames from "classnames";
import { ReactNode } from "react";

import styles from "./SummaryArrayItem.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
};

const SummaryArrayItem: React.FC<Props> = ({ children, className }) => (
  <div className={classNames(styles.content, className)}>{children}</div>
);

export default SummaryArrayItem;
