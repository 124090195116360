import { FC, ReactNode } from "react";
import PageIndicator from "ui/navigation/PageIndicator";
import DateTimeCell from "ui/table/DateTimeCell";
import TextCell from "ui/table/TextCell";

import DateRangePickers, { FromDatePicker, ToDatePicker } from "./DateRangePickers";
import Dropdown from "./Dropdown";
import Empty from "./Empty";
import ExportButton from "./ExportButton";
import Filters from "./Filters";
import FilterTabs from "./FilterTabs";
import Footer from "./Footer";
import PaginationInfo from "./PaginationInfo";
import SearchBar from "./SearchBar";
import Title from "./Title";
import TransactionsTable from "./TransactionsTable";

type Props = {
  title?: ReactNode;
  filters?: ReactNode;
  table: ReactNode;
  footer?: ReactNode;
  transactionFlexpane?: ReactNode;
};

const TransactionsV2: FC<Props> = ({ title, filters, table, footer, transactionFlexpane }) => (
  <>
    <div>
      {title}
      {filters}
      {table}
      {footer}
    </div>
    {transactionFlexpane}
  </>
);

export default Object.assign(TransactionsV2, {
  Title: Title,
  Filters: Object.assign(Filters, {
    Dropdown: Dropdown,
    SearchBar: SearchBar,
    DateRangePickers: Object.assign(DateRangePickers, {
      FromDatePicker: FromDatePicker,
      ToDatePicker: ToDatePicker,
    }),
    PaginationInfo: PaginationInfo,
    FilterTabs: FilterTabs,
    ExportButton: ExportButton,
  }),
  TransactionsTable: Object.assign(TransactionsTable, {
    Empty: Empty,
    DateCell: DateTimeCell,
    TextCell: TextCell,
  }),
  Footer: Object.assign(Footer, {
    PageIndicator: PageIndicator,
  }),
});
