import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

const CHARGE_CARD_OFFER_QUERY_KEY = "charge-card-offer";

const useChargeCardOfferQueryOptions = (capitalAccountGuid?: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [CHARGE_CARD_OFFER_QUERY_KEY, { businessGuid, capitalAccountGuid }],
    queryFn: () => {
      if (!capitalAccountGuid) {
        return null;
      }
      return highbeamApi.chargeCard.getOffer(businessGuid, capitalAccountGuid);
    },
  });
};

const useChargeCardOffer = <TRequired extends boolean = false>(
  capitalAccountGuid?: string,
  params?: RequiredParam<TRequired>
) => {
  const { data } = useSuspenseQuery(useChargeCardOfferQueryOptions(capitalAccountGuid));

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useChargeCardOffer;
