import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { CARDS_QUERY_KEY } from "resources/cards/queries/useCardsQueryOptions";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const useArchiveCardMutation = () => {
  const highbeamApi = useHighbeamApi();
  const refreshListCardsQuery = useRefreshQuery([CARDS_QUERY_KEY]);
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (cardId: string) => {
      await highbeamApi.card.close(cardId);
    },
    onSuccess: async () => {
      await refreshListCardsQuery();
      navigate("/cards");
      notify("success", "Card archived");
    },
    onError: (err) => {
      captureException(err);
      notify("warning", "There was an issue closing the card. Please try again.");
    },
  });
};
