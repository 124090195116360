import {
  Paperclip as PaperclipIcon,
  Gear as GearIcon,
  ChatTeardropDots as ChatTeardropDotsIcon,
} from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import IconWithTooltip from "components/common/IconWithTooltip";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import { FC, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BillSummaryRep from "reps/BillSummaryRep";
import useCurrentApEmailAddress from "resources/ap-email-aliases/queries/useCurrentApEmailAddress";
import { useBillsEmailInboxDataQuery } from "resources/bills/queries/useBillsEmailInboxData";
import colors from "styles/colors";
import EmptyState from "ui/data-display/EmptyState";
import InlineCopyText from "ui/data-display/InlineCopyText";
import Shimmer from "ui/feedback/Shimmer";
import Button from "ui/inputs/Button";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { Column } from "ui/table/Table";
import TextCell from "ui/table/TextCell";
import Text from "ui/typography/Text";

import { BillInvoiceDueDateCell, BillPayeeNameCell, BillTotalAmountCell } from "../AllBillsView";

import styles from "./BillsEmailInboxView.module.scss";

const CurrentApEmailAddressInfo: FC = () => {
  const currentApEmailAddress = useCurrentApEmailAddress();

  if (!currentApEmailAddress) {
    return null;
  }

  return (
    <Text as="p" size={14} color={colors.grey[600]}>
      Email invoices to{" "}
      <InlineCopyText textToCopy={currentApEmailAddress} tooltipContent="Copy email address">
        {currentApEmailAddress}
      </InlineCopyText>
    </Text>
  );
};

type BillsEmailInboxEmptyStateProps = {
  openEmailSettings: () => void;
};

const BillsEmailInboxEmptyState: FC<BillsEmailInboxEmptyStateProps> = ({ openEmailSettings }) => {
  const currentApEmailAddress = useCurrentApEmailAddress();
  const chat = useChatWidget();

  return (
    <EmptyState
      variant="card"
      className={styles.emptyState}
      body={
        <>
          <EmptyState.PrimaryText>No bills</EmptyState.PrimaryText>
          {currentApEmailAddress ? (
            <EmptyState.SecondaryText>
              To create a bill, email an invoice to{" "}
              <InlineCopyText
                textToCopy={currentApEmailAddress}
                tooltipContent="Copy email address"
              >
                {currentApEmailAddress}
              </InlineCopyText>
            </EmptyState.SecondaryText>
          ) : (
            <EmptyState.SecondaryText>
              You do not currently have an email address set up to receive invoices. Please contact
              support to set one up.
            </EmptyState.SecondaryText>
          )}
        </>
      }
      footer={
        currentApEmailAddress ? (
          <EmptyState.CTA onClick={() => openEmailSettings()}>
            <GearIcon />
            Email settings
          </EmptyState.CTA>
        ) : (
          <EmptyState.CTA onClick={() => chat.show()}>
            <ChatTeardropDotsIcon />
            Talk to support
          </EmptyState.CTA>
        )
      }
    />
  );
};

const columns: Column<BillSummaryRep.Complete>[] = [
  {
    title: "Sent by",
    cellRender: (bill) => {
      if (!bill.sentByEmail) {
        return <NotFoundCell />;
      }
      return (
        <TextCell darken overflowEllipsis>
          {bill.sentByEmail}
        </TextCell>
      );
    },
  },
  {
    title: "Invoice #",
    cellRender: (bill) => {
      if (!bill.invoiceNumber) {
        return <NotFoundCell />;
      }
      return (
        <TextCell darken>
          {bill.invoiceNumber}{" "}
          {bill.duplicateOfBillId && (
            <IconWithTooltip icon="warning" color="warning" content="Possible duplicate bill" />
          )}
        </TextCell>
      );
    },
  },
  {
    title: "Payee",
    cellRender: (bill) => {
      return <BillPayeeNameCell bill={bill} />;
    },
  },
  {
    title: "Due",
    cellRender: (bill) => {
      return <BillInvoiceDueDateCell bill={bill} />;
    },
  },
  {
    title: "Bill amount",
    cellRender: (bill) => {
      return <BillTotalAmountCell bill={bill} />;
    },
  },
  {
    title: "Attachments",
    cellRender: (bill) => {
      return (
        <TextCell darken>
          <PaperclipIcon size={16} />
          {bill.billDocumentsCount}
        </TextCell>
      );
    },
  },
  {
    title: "Actions",
    cellRender: (_bill) => {
      return (
        <Button variant="secondary" size="sm">
          Review
        </Button>
      );
    },
  },
];

type Props = {
  openEmailSettings: () => void;
};

const BillsEmailInboxView: FC<Props> = ({ openEmailSettings }) => {
  const { data: bills, isPending } = useBillsEmailInboxDataQuery();
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <div className={styles.container}>
      <div className={styles.controlsContainer}>
        <Suspense fallback={<Shimmer additionalShimmerWrapperClassName={styles.controlsShimmer} />}>
          <CurrentApEmailAddressInfo />
        </Suspense>
      </div>

      <Suspense fallback={<Table.Loading columns={columns} isLoadingRows={2} />}>
        {!isPending && bills && !bills.length ? (
          <BillsEmailInboxEmptyState openEmailSettings={openEmailSettings} />
        ) : (
          <Table
            isLoading={isPending}
            rowKey={(bill) => bill.id}
            onRowClick={({ id }) =>
              navigate(`/payments/bills/${id}${search}`, { state: DISABLE_SCROLL_TO_TOP_STATE })
            }
            data={bills}
            columns={columns}
          />
        )}
      </Suspense>
    </div>
  );
};

export default BillsEmailInboxView;
