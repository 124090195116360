import { Payment } from "@highbeam/unit-node-sdk";
import { FC } from "react";
import Pill, { PillColor } from "ui/data-display/Pill";

type StatusLabel = {
  text: string;
  pillColor: PillColor;
};

const pending: StatusLabel = {
  text: "Pending",
  pillColor: "grey-light",
};

const clearing: StatusLabel = {
  text: "Clearing",
  pillColor: "grey",
};

const failed: StatusLabel = {
  text: "Failed",
  pillColor: "pink-dark",
};

const canceled: StatusLabel = {
  text: "Canceled",
  pillColor: "orange",
};

const getLabel = (payment: Payment): StatusLabel | undefined => {
  switch (payment.attributes.status) {
    case "Pending":
      return pending;
    case "Clearing":
      return clearing;
    case "Rejected":
    case "Returned":
      return failed;
    case "Canceled":
      return canceled;
    default:
      return undefined;
  }
};

type Props = {
  payment: Payment;
};

const PaymentStatusPill: FC<Props> = ({ payment }) => {
  const label = getLabel(payment);

  if (!label) return null;

  return (
    <Pill bordered color={label.pillColor}>
      {label.text}
    </Pill>
  );
};

export default PaymentStatusPill;
