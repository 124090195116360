import useMfa from "modules/mfa/useMfa";
import { FC } from "react";
import { useSetRecoilState } from "recoil";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useIsAllowedToChangeAutoPayAccount from "utils/permissions/useIsAllowedToChangeAutoPayAccount";

import UpdateRepaymentAccountModal from "../UpdateRepaymentModal";
import isUpdateRepaymentAccountModalOpenState from "../UpdateRepaymentModal/states/isUpdateRepaymentAccountModalOpen";

const ChangeAutoPayAccountButton: FC<{ fullWidth: boolean }> = ({ fullWidth }) => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const isAllowedToChangeAutoPayAccount = useIsAllowedToChangeAutoPayAccount();
  const { mfa } = useMfa();

  const setIsUpdateRepaymentAccountModalOpenState = useSetRecoilState(
    isUpdateRepaymentAccountModalOpenState
  );

  return (
    <>
      <UpdateRepaymentAccountModal capitalAccountSummary={capitalAccountSummary} />

      <Button
        fullWidth={fullWidth}
        variant="tertiary"
        onClick={async () => {
          await mfa();
          setIsUpdateRepaymentAccountModalOpenState(true);
        }}
        disabled={!isAllowedToChangeAutoPayAccount}
        tooltip={
          !isAllowedToChangeAutoPayAccount && (
            <Text size={14}>You don’t have permission to change the auto-pay account.</Text>
          )
        }
      >
        Change auto-pay account
      </Button>
    </>
  );
};

export default ChangeAutoPayAccountButton;
