import { useQuery } from "@tanstack/react-query";

import usePlaidLinkTokenQueryOptions, {
  PlaidLinkTokenParams,
} from "./usePlaidLinkTokenQueryOptions";

// This function is similar to usePlaidLinkToken, but it doesn't automatically create the link token.
// Instead, it returns a handle object with a `createLinkToken` function that can be used to create the link token.
// This is similar to the semantics of a *mutation*, except that the link token is kept in the query cache
// after it's been created.
// TODO(HB-5173): Consider reworking this to use a mutation instead.
const useDeferredPlaidLinkToken = (params: PlaidLinkTokenParams) => {
  const { data, refetch, isFetching } = useQuery({
    ...usePlaidLinkTokenQueryOptions(params),
    // Disable automatic fetching. Fetches are triggered manually by calling the `refetch` function,
    // (which is exposed to the caller as `createLinkToken`).
    enabled: false,
  });

  return { linkToken: data ?? null, createLinkToken: refetch, isCreatingLinkToken: isFetching };
};

export default useDeferredPlaidLinkToken;
