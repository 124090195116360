import { Link } from "react-router-dom";
import useInactivePlaidDepositoryAccounts from "resources/plaid-connections/queries/useInactivePlaidDepositoryAccounts";
import Banner from "ui/data-display/Banner";
import InfoIcon from "ui/icons/InfoIcon";
import Button from "ui/inputs/Button";

type Props = {
  bannerWrapperAdditionalClassName?: string;
};

const UpdatePlaidConnectionBanners: React.FC<Props> = ({
  bannerWrapperAdditionalClassName = "",
}) => {
  const inactivePlaidDepositoryAccounts = useInactivePlaidDepositoryAccounts();

  if (inactivePlaidDepositoryAccounts.length === 0) {
    return null;
  }

  return (
    <div className={bannerWrapperAdditionalClassName}>
      <Banner
        padding="compact"
        color="white"
        icon={<InfoIcon variant="info" />}
        title="Some accounts need to be reconnected"
        body={
          "You need to reconnect one or more of your connected accounts in order to use them with Highbeam again."
        }
        footer={
          <Link to="/settings/financial-partners">
            <Button variant="tertiary" size="sm">
              Go to settings
            </Button>
          </Link>
        }
      />
    </div>
  );
};
export default UpdatePlaidConnectionBanners;
