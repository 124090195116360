import { useLocation } from "react-router-dom";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import StepsV3 from "ui/navigation/StepsV3";

type Step = {
  pathname: string;
  title: string;
};

const RepayLineOfCreditSteps = () => {
  const { pathname } = useLocation();
  const capitalAccountSummary = useCapitalAccountSummaryContext();

  const steps = [
    {
      pathname: `/capital/${capitalAccountSummary.guid}/credit/repay`,
      title: "Amount",
    },
    {
      pathname: `/capital/${capitalAccountSummary.guid}/credit/repay/confirm`,
      title: "Confirm details",
    },
  ] satisfies Step[];

  const currentStepIndex = steps.findIndex((step) => step.pathname === pathname);

  return (
    <StepsV3>
      {steps.map(({ pathname, title }, index) => (
        <StepsV3.Step
          key={pathname}
          number={index + 1}
          state={
            currentStepIndex === index ? "active" : currentStepIndex > index ? "done" : "incomplete"
          }
          isLast={index + 1 === steps.length}
          to={currentStepIndex <= index ? undefined : pathname}
        >
          {title}
        </StepsV3.Step>
      ))}
    </StepsV3>
  );
};

export default RepayLineOfCreditSteps;
