import classNames from "classnames";
import SubcategoryDropdown from "components/SubcategoryDropdown";
import SectionHeader from "ui/data-display/SectionHeader";
import { DropdownState } from "ui/inputs/Dropdown";
import Fieldset from "ui/inputs/Fieldset";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";

import styles from "./CreatePayeePage.module.scss";

type Props = {
  sectionDescription: string;
  dropdownState: DropdownState;
};

const PayeeCategorySection: React.FC<Props> = ({ sectionDescription, dropdownState }) => (
  <div className={styles.categoryContainer}>
    <div>
      <SectionHeader>
        <Heading3>Category</Heading3>
      </SectionHeader>
      <Text size={14} className={styles.categoryDescription}>
        {sectionDescription}
      </Text>
    </div>
    <Fieldset width="narrow">
      <SubcategoryDropdown dropdownState={dropdownState} className={classNames(styles.span2)} />
    </Fieldset>
  </div>
);

export default PayeeCategorySection;
