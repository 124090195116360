import { CreditCard as CardIcon } from "@phosphor-icons/react";
import cardChip from "assets/card-chip.svg";
import classNames from "classnames";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "../HighbeamCard.module.scss";

const PhysicalCreditCardPill = () => (
  <div className={styles.rowWithSpace}>
    <div className={styles.rowWithGap}>
      <div
        className={classNames(styles.row, styles.cardTypePill, styles.physicalCreditCardTypePill)}
      >
        <div className={styles.cardTypePillTextContainer}>
          <CardIcon size={16} color={colors.white} />
          <Text size={12} weight="medium" color={colors.white} align="center">
            Physical
          </Text>
        </div>
      </div>
    </div>
    <img src={cardChip} alt="card chip" />
  </div>
);

export default PhysicalCreditCardPill;
