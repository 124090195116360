import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const CHARGE_CARD_REPAYMENT_QUERY_KEY = "charge-card-repayments";

// NB(alex): we would like to pass `search` to the transactions query but the backend does not support it yet, so we filter transactions here on the frontend
const useChargeCardRepaymentsQueryOptions = (chargeCardAccountGuid: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [CHARGE_CARD_REPAYMENT_QUERY_KEY, { chargeCardAccountGuid }],
    queryFn: () => {
      return highbeamApi.chargeCard.getTransactions(businessGuid, chargeCardAccountGuid);
    },
  });
};

export default useChargeCardRepaymentsQueryOptions;
