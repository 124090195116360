import logo from "assets/highbeam-logo.svg";
import backgroundLogo from "assets/onboarding-background.svg";
import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { onboardingProgressState } from "state/onboarding/progress";
import colors from "styles/colors";
import ProgressBar from "ui/data-visualization/ProgressBar";

import styles from "./Onboarding.module.scss";

type Props = {
  style?: CSSProperties;
  showProgressBar?: boolean;
} & React.PropsWithChildren;

const OnboardingLayout: React.FC<Props> = ({
  children,
  style = { backgroundImage: `url(${backgroundLogo})` }, // Keeping this until we deprecate the old onboarding flow
  showProgressBar = true,
}) => {
  const progress = useRecoilValue(onboardingProgressState);
  return (
    <>
      <div className={styles.container} style={style}>
        <header className={styles.header}>
          <img src={logo} className={styles.logo} alt="logo" />
          <Link className={styles.signOut} to="/sign-out">
            Logout
          </Link>
        </header>
        <div className={styles.body}>{children}</div>
      </div>
      {showProgressBar && (
        <ProgressBar
          className={styles.progressBarContainer}
          percent={progress}
          color={colors.purple[500]}
        />
      )}
    </>
  );
};
export default OnboardingLayout;
