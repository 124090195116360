import ModalV4 from "ui/overlay/ModalV4";

import ApplicationRequirementsForm from "./ApplicationRequirementsForm";
import { useApplicationRequirementsModal } from "./state/applicationRequirementsModalState";

const ApplicationRequirementsModal = () => {
  const { isOpen, close } = useApplicationRequirementsModal();

  if (!isOpen) {
    return null;
  }

  return (
    <ModalV4 onClose={close} dropdownOverflowHack>
      <ApplicationRequirementsForm />
    </ModalV4>
  );
};

export default ApplicationRequirementsModal;
