import { useSuspenseQuery } from "@tanstack/react-query";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import { useOpenBankAccountsQueryOptions } from "./useOpenBankAccounts";

const useOpenBankAccount = <TRequired extends boolean>(
  bankAccountGuid: string,
  params?: RequiredParam<TRequired>
) => {
  const { data } = useSuspenseQuery({
    ...useOpenBankAccountsQueryOptions(),
    select: (bankAccounts) =>
      bankAccounts.find((bankAccount) => bankAccount.guid === bankAccountGuid),
  });

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useOpenBankAccount;
