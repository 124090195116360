import {
  DotsThreeVertical as DotsThreeVerticalIcon,
  EnvelopeSimple as EnvelopeSimpleIcon,
} from "@phosphor-icons/react";
import FeatureStatusIndicator from "components/FeatureStatusIndicator";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import { useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AllBillsView from "resources/bills/components/AllBillsView";
import BillsEmailInboxView from "resources/bills/components/BillsEmailInboxView";
import CreateBillButton from "resources/bills/components/CreateBillButton";
import useCreateBillMutation from "resources/bills/mutations/useCreateBillMutation";
import { useBillsEmailInboxDataCountQuery } from "resources/bills/queries/useBillsEmailInboxData";
import Button from "ui/inputs/Button";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import TabsV2 from "ui/navigation/TabsV2";
import Menu from "ui/overlay/Menu";
import useSearchParamOption from "utils/searchParams/useSearchParamOption";
import { TabConfig } from "utils/tabs/useTabState";
import getObjectKeys from "utils/ts/getObjectKeys";

import BillDetailsFullPageModal from "../BillDetailsFullPageModal";
import BillPaymentFullPageModal from "../BillPaymentFullPageModal";
import EmailSettingsModal from "../EmailSettingsModal";

import styles from "./BillsPage.module.scss";

const BillsPageBillDetailsFullPageModal = () => {
  const { billId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const backToPath = "/payments/bills";

  // Should never happen, just here for type-safety.
  if (!billId) {
    return <Navigate to={`${backToPath}?${searchParams}`} />;
  }

  return (
    <BillDetailsFullPageModal
      billId={billId}
      onClose={() => {
        searchParams.delete("bill-info-form-tab"); // Do not preserve update/create payee.
        navigate(`${backToPath}?${searchParams}`);
      }}
    />
  );
};

const BillsPageBillPaymentFullPageModal = () => {
  const [searchParams] = useSearchParams();
  const billIds = searchParams.getAll("bills");
  const navigate = useNavigate();

  return (
    <BillPaymentFullPageModal
      billIds={billIds}
      onClose={() => {
        searchParams.delete("bills");
        navigate(`/payments/bills?${searchParams}`);
      }}
      onCancel={() => navigate(-1)}
    />
  );
};

const BillsPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const { data: emailInboxCount } = useBillsEmailInboxDataCountQuery();

  const billsPageTabs = {
    "all-bills": { label: "All bills" },
    "email-inbox": { label: "Email inbox", count: emailInboxCount || undefined },
  } satisfies TabConfig;

  const [activeTab, setActiveTab] = useSearchParamOption(
    "tab",
    getObjectKeys(billsPageTabs),
    "all-bills"
  );

  const { mutate: createBill, isPending } = useCreateBillMutation({
    onSuccess: (bill) => {
      navigate(`/payments/bills/${bill.id}${search}`);
    },
  });

  const makeCreateBillVariables = () => ({
    amount: null,
    invoiceDate: null,
    invoiceDueDate: null,
    invoiceNumber: null,
    payeeGuid: null,
    memo: null,
    paymentTerms: null,
    purchaseOrderNumber: null,
  });

  const [isEmailSettingsOpen, setIsEmailSettingsOpen] = useState(false);

  return (
    <>
      <Routes>
        <Route path="/:billId" element={<BillsPageBillDetailsFullPageModal />} />
        <Route path="/payment" element={<BillsPageBillPaymentFullPageModal />} />
      </Routes>

      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/payments">Payments</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Bills</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header
          actions={
            <div className={styles.headerActionsCtaContainer}>
              <CreateBillButton
                isLoading={isPending}
                onClick={() => createBill(makeCreateBillVariables())}
              />
              <Menu
                button={
                  <Button variant="tertiary" isSquare>
                    <DotsThreeVerticalIcon size={20} />
                  </Button>
                }
                placement={{ top: "calc(100% - 4px)" }}
              >
                <Menu.Item
                  icon={<EnvelopeSimpleIcon />}
                  onClick={() => setIsEmailSettingsOpen(true)}
                >
                  Email settings
                </Menu.Item>
              </Menu>
            </div>
          }
        >
          <DashboardPage.Header.Title>Bills</DashboardPage.Header.Title>
          <FeatureStatusIndicator featureStatus="Beta" />
        </DashboardPage.Header>

        <TabsV2 activeTab={activeTab} setActiveTab={setActiveTab} tabs={billsPageTabs} />

        <DashboardPage.Section>
          {activeTab === "all-bills" && (
            <AllBillsView
              createBill={() => createBill(makeCreateBillVariables())}
              isCreatingBill={isPending}
            />
          )}
          {activeTab === "email-inbox" && (
            <BillsEmailInboxView openEmailSettings={() => setIsEmailSettingsOpen(true)} />
          )}
        </DashboardPage.Section>
      </DashboardPage>

      {isEmailSettingsOpen && <EmailSettingsModal onClose={() => setIsEmailSettingsOpen(false)} />}
    </>
  );
};

export default BillsPage;
