import BankAccountRep from "reps/BankAccountRep";

const getBankAccountsByUnitCoDepositAccountId = (bankAccounts: BankAccountRep.Complete[]) => {
  return bankAccounts.reduce((map, account) => {
    map.set(account.unitCoDepositAccountId, account);
    return map;
  }, new Map<string, BankAccountRep.Complete>());
};

export default getBankAccountsByUnitCoDepositAccountId;
