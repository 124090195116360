import { UserPlus } from "@phosphor-icons/react";
import { FC } from "react";
import UserReferralRep from "reps/ReferralLinkRep";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import { BFCM_REFERRALS_SLUG_TO_AVATAR_MAP } from "..";
import styles from "../Referral.module.scss";

type Props = {
  referral: UserReferralRep;
};

const ReferralHeader: FC<Props> = ({ referral }) => {
  const referrerAvatar = BFCM_REFERRALS_SLUG_TO_AVATAR_MAP[referral.slug];
  return (
    <div className={styles.text}>
      <div className={styles.headerSection}>
        {referrerAvatar ? (
          <img src={referrerAvatar} alt={referral.slug} className={styles.referrerAvatar} />
        ) : (
          <div className={styles.iconAvatar}>
            <UserPlus size={24} color={colors.purple[500]} />
          </div>
        )}
        <Text size={20} weight="bold" className={styles.primary}>
          {referral.senderName ? <>{referral.senderName} invited you</> : <>You’ve been invited</>}{" "}
          to join Highbeam!
        </Text>
        <Text size={14} className={styles.secondary}>
          Sign up for a promotional Highbeam Line of Credit by October 15th 2024 to get an effective
          APR of <strong>10%</strong> on an unsecured line of credit through Cyber Monday.*
          <br />
          <br />
          No personal guarantees. Pay it back after your biggest sales of the year.
        </Text>
      </div>
    </div>
  );
};

export default ReferralHeader;
