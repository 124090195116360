import MetadataList from "components/MetadataList";
import Section from "ui/data-display/Section";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import { HighbeamAtmTransaction } from "utils/types/transactionsTypes";

type Props = {
  transaction: HighbeamAtmTransaction;
};

const AtmTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>ATM transaction details</Heading3>
    <MetadataList>
      <MetadataList.Item label="ATM name">
        <Text size={14} weight="medium">
          {transaction.atmName}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item label="ATM surcharge">
        <Text size={14} weight="medium" numeric>
          {transaction.formattedSurcharge}
        </Text>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default AtmTransactionDetailsSection;
