import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";

const useShowTooltip = (delay: number = 0): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isHovered, setIsHovered] = useState(false);
  const pointerLeaveTimeout = useRef<number>();

  const setShowTooltip = useCallback(
    (value: boolean | ((prevState: boolean) => boolean)) => {
      if (typeof value === "function") {
        setIsHovered(value(isHovered));
        return;
      } else if (value === true) {
        setIsHovered(true);

        if (pointerLeaveTimeout.current) {
          window.clearTimeout(pointerLeaveTimeout.current);
        }
      } else {
        pointerLeaveTimeout.current = window.setTimeout(() => {
          setIsHovered(false);
        }, delay);
      }
    },
    [delay, isHovered]
  );

  return [isHovered, setShowTooltip];
};

export default useShowTooltip;
