import classNames from "classnames";
import { ComponentProps, FC } from "react";
import { Merge } from "type-fest";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./FieldsetLegend.module.scss";

type Props = Merge<ComponentProps<"legend">, TextProps>;

const FieldsetLegend: FC<Props> = ({ className, children, ...legendAndTextProps }) => {
  return (
    <Text
      as="legend"
      size={14}
      weight="bold"
      className={classNames(className, styles.fieldset)}
      {...legendAndTextProps}
    >
      {children}
    </Text>
  );
};

export default FieldsetLegend;
