import classNames from "classnames";
import { FC, ReactNode } from "react";

import styles from "./HighlightCard.module.scss";
import HighlightCardHeader from "./HighlightCardHeader";
import { HighlightCardSideRuleColor } from "./types";

type Props = {
  sideRuleColor?: HighlightCardSideRuleColor;
  header?: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
};

const HighlightCard: FC<Props> = ({ sideRuleColor = "default", header, body, footer }) => {
  return (
    <div className={classNames(styles.container, styles[sideRuleColor])}>
      <div className={styles.header}>{header}</div>
      <div className={styles.body}>{body}</div>
      <div className={styles.footer}>{footer}</div>
    </div>
  );
};

export default Object.assign(HighlightCard, {
  Header: HighlightCardHeader,
});
