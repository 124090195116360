import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const CAPITAL_ACCOUNTS_QUERY_KEY = "capital-accounts";

const useCapitalAccountsQueryOptions = () => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [CAPITAL_ACCOUNTS_QUERY_KEY, businessGuid],
    queryFn: () => {
      return highbeamApi.capitalAccount.getAll(businessGuid);
    },
  });
};

export default useCapitalAccountsQueryOptions;
