import { useState } from "react";
import { useResetRecoilState } from "recoil";
import useBusiness from "resources/business/queries/useBusiness";
import { selectedBusinessGuidState } from "state/auth/businessGuid";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Text from "ui/typography/Text";

import styles from "./SuperuserBanner.module.scss";

const SuperuserBanner = () => {
  const isSuperusering = useIsSuperusering();
  const resetSelectedBusinessGuid = useResetRecoilState(selectedBusinessGuidState);
  const business = useBusiness();
  const [isDismissed, setIsDismissed] = useState<boolean>(false);

  if (!isSuperusering || isDismissed) {
    return null;
  }

  const onExitSuperuser = () => {
    resetSelectedBusinessGuid();
    window.location.reload();
  };

  return (
    <div className={styles["superuser-banner"]}>
      <Text size={20}>
        Please be careful, you are impersonating business "
        <span title={`Business guid: ${business.guid}`}>{business.internalName}</span>".
      </Text>
      <div className={styles.actions}>
        <button onClick={onExitSuperuser}>Exit superuser</button>
        <button onClick={() => setIsDismissed(true)}>Dismiss</button>
      </div>
    </div>
  );
};

export default SuperuserBanner;
