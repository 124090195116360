import HighYieldTeaserImage from "assets/teasers/high-yield-teaser.png";
import Emoji from "components/Emoji";
import DashboardPage from "components/layouts/DashboardPage";
import { useNavigate } from "react-router-dom";
import BankAccountRep from "reps/BankAccountRep";
import colors from "styles/colors";
import Teaser from "ui/data-display/Teaser";
import TeaserHero from "ui/data-display/Teaser/TeaserHero";
import TeaserMainSection from "ui/data-display/Teaser/TeaserMainSection";
import Button from "ui/inputs/Button";
import Heading2 from "ui/typography/Heading2";
import Text from "ui/typography/Text";

import styles from "./HighYieldTeaser.module.scss";

type Props = {
  account: BankAccountRep.Complete;
};

const HighYieldTeaser: React.FC<Props> = ({ account }) => {
  const navigate = useNavigate();
  if (!account) return null;

  return (
    <DashboardPage.Section>
      <Teaser>
        <TeaserMainSection>
          <>
            <Emoji className={styles.emoji}>💰</Emoji>
            <div className={styles.teaserText}>
              <Heading2>Earn 4% APY</Heading2>
              <Text size={16} color={colors.grey[500]}>
                Deposit money to your High yield account and grow funds on Highbeam!{" "}
              </Text>
            </div>
            <Button
              className={styles.teaserButton}
              variant="primary"
              onClick={() =>
                navigate("/transfer-money", { state: { toAccountGuid: account.guid } })
              }
            >
              Deposit now
            </Button>
          </>
        </TeaserMainSection>
        <TeaserHero>
          <img
            src={HighYieldTeaserImage}
            alt="High yield teaser"
            title="High yield teaser"
            width="100%"
          />
        </TeaserHero>
      </Teaser>
    </DashboardPage.Section>
  );
};

export default HighYieldTeaser;
