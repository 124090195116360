import { DotsThree, Pencil } from "@phosphor-icons/react";
import DashboardPage from "components/layouts/DashboardPage";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountIcon from "resources/capital-accounts/components/CapitalAccountIcon";
import Button from "ui/inputs/Button";
import ButtonArray from "ui/navigation/ButtonArray";
import Menu from "ui/overlay/Menu";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import EditCapitalAccountNameModal from "../../components/EditCapitalAccountNameModal";
import { useEditCapitalAccountNameModal } from "../../components/EditCapitalAccountNameModal/state/editCapitalAccountNameModalState";
import LineOfCreditDrawdownButtonArrayButton from "../../components/LineOfCreditDrawdownButtonArrayButton";
import LineOfCreditRepayButtonArrayButton from "../../components/LineOfCreditRepayButtonArrayButton";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountHeader: FC<Props> = ({ capitalAccountSummary }) => {
  const enableCapitalOverviewPage = useFeatureFlag("CAPITAL_OVERVIEW_PAGE");
  const { open: openEditCapitalAccountModal } = useEditCapitalAccountNameModal();

  return (
    <>
      <EditCapitalAccountNameModal />

      <DashboardPage.Header
        actions={
          <>
            {capitalAccountSummary.type === CapitalAccountRep.Type.CashAccessOnly && (
              <ButtonArray alignment="horizontal">
                <LineOfCreditRepayButtonArrayButton capitalAccountSummary={capitalAccountSummary} />
                <LineOfCreditDrawdownButtonArrayButton
                  capitalAccountSummary={capitalAccountSummary}
                />
              </ButtonArray>
            )}
            <Menu
              button={
                <Button variant="tertiary" isSquare>
                  <DotsThree size={16} />
                </Button>
              }
            >
              <Menu.Item
                icon={<Pencil size={16} />}
                onClick={() => {
                  openEditCapitalAccountModal({});
                }}
              >
                Edit capital account name
              </Menu.Item>
            </Menu>
          </>
        }
      >
        {enableCapitalOverviewPage && <DashboardPage.Header.BackCaret to="/capital" />}
        <DashboardPage.Header.IconTile
          icon={<CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} />}
        />
        <DashboardPage.Header.Title>{capitalAccountSummary.name}</DashboardPage.Header.Title>
      </DashboardPage.Header>
    </>
  );
};

export default CapitalAccountHeader;
