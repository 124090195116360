import colors from "styles/colors";
import Text from "ui/typography/Text";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";

import styles from "./TransferBalance.module.scss";

type Props = {
  balance: number;
};

const TransferBalance: React.FC<Props> = ({ balance }) => (
  <div className={styles.balanceContainer}>
    <Text size={12} color={colors.grey[600]} className={styles.balance}>
      {`Balance: ${formatAmount(getDollarsFromCents(balance))}`}
    </Text>
  </div>
);

export default TransferBalance;
