import CreditApplicationDocumentRep from "reps/CreditApplicationDocumentRep";
import colors from "styles/colors";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";

import CreditApplicationDocumentExampleButton from "../../components/CreditApplicationDocumentExampleButton";
import UploadDocumentsSection from "../../components/UploadDocumentsSection";

import styles from "./UploadFinancialDocumentsView.module.scss";

const ConnectTabUploadDocumentsStep = () => (
  <div className={styles.container}>
    <Heading3>2. Upload documents</Heading3>
    <UploadDocumentsSection
      subheading={
        <div className={styles.uploadDocumentsWithExampleButton}>
          <Text size={14} color={colors.grey[900]} weight="medium">
            AP & AR aging reports
          </Text>
          <CreditApplicationDocumentExampleButton
            documentType={CreditApplicationDocumentRep.DocumentType.ApArAgingReport}
          />
        </div>
      }
      type={CreditApplicationDocumentRep.DocumentType.ApArAgingReport}
    />
    <UploadDocumentsSection
      subheading={
        <Text size={14} color={colors.grey[900]} weight="medium">
          (Optional) Any additional documents that might be useful (Ex. Term sheets, org. charts)
        </Text>
      }
      type={CreditApplicationDocumentRep.DocumentType.Unknown}
    />
  </div>
);

export default ConnectTabUploadDocumentsStep;
