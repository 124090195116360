import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { useState } from "react";

import PageButton from "./PageButton";
import PageDots from "./PageDots";
import styles from "./PageIndicator.module.scss";

export type Pagination = {
  pageSize: number;
  currentPage: number;
  offset: number;
  setCurrentPage: (current: number) => void;
  totalCount: number;
  totalPages: number;
  setTotalCount: (total: number) => void;
};

type PaginationOptions = {
  pageSize?: number;
};

export const usePagination = ({ pageSize = 25 }: PaginationOptions = {}): Pagination => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  return {
    pageSize,
    currentPage,
    offset: currentPage * pageSize,
    setCurrentPage,
    totalCount,
    totalPages: Math.ceil(totalCount / pageSize),
    setTotalCount,
  };
};

type Props = {
  currentPage: number;
  setCurrentPage: (current: number) => void;
  totalPages: number;
};

const PageIndicator: React.FC<Props> = ({ currentPage, setCurrentPage, totalPages }) => {
  const PreviousPageButton = () =>
    currentPage >= 1 ? (
      <PageButton onClick={() => setCurrentPage(currentPage - 1)}>
        <CaretLeft />
      </PageButton>
    ) : null;

  const FirstPageButton = () =>
    totalPages > 3 && currentPage >= 2 ? (
      <PageButton onClick={() => setCurrentPage(0)}>1</PageButton>
    ) : null;

  const FirstPageDotSeparator = () => (totalPages > 5 && currentPage >= 3 ? <PageDots /> : null);

  const PreviousThreePagesButton = () =>
    totalPages === 5 && totalPages - currentPage <= 1 ? (
      <PageButton onClick={() => setCurrentPage(currentPage - 3)}>{currentPage - 2}</PageButton>
    ) : null;

  const PreviousTwoPagesButton = () =>
    (totalPages > 2 && totalPages - currentPage <= 1) ||
    (totalPages === 5 && totalPages - currentPage <= 2) ? (
      <PageButton onClick={() => setCurrentPage(currentPage - 2)}>{currentPage - 1}</PageButton>
    ) : null;

  const PreviousOnePageButton = () =>
    currentPage >= 1 ? (
      <PageButton onClick={() => setCurrentPage(currentPage - 1)}>{currentPage}</PageButton>
    ) : null;

  const CurrentPageButton = () => (
    <PageButton active onClick={() => setCurrentPage(currentPage)}>
      {currentPage + 1}
    </PageButton>
  );

  const NextOnePageButton = () =>
    totalPages - currentPage > 1 ? (
      <PageButton onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 2}</PageButton>
    ) : null;

  const NextTwoPagesButton = () =>
    (totalPages > 2 && currentPage < 1) || (totalPages === 5 && currentPage < 2) ? (
      <PageButton onClick={() => setCurrentPage(currentPage + 2)}>{currentPage + 3}</PageButton>
    ) : null;

  const NextThreePagesButton = () =>
    totalPages === 5 && currentPage < 1 ? (
      <PageButton onClick={() => setCurrentPage(currentPage + 3)}>{currentPage + 4}</PageButton>
    ) : null;

  const LastPageDotSeparator = () =>
    totalPages > 5 && totalPages - currentPage > 3 ? <PageDots /> : null;

  const LastPageButton = () =>
    totalPages > 3 && totalPages - currentPage > 2 ? (
      <PageButton onClick={() => setCurrentPage(totalPages - 1)}>{totalPages}</PageButton>
    ) : null;

  const NextPageButton = () =>
    totalPages - currentPage > 1 ? (
      <PageButton onClick={() => setCurrentPage(currentPage + 1)}>
        <CaretRight />
      </PageButton>
    ) : null;

  return (
    <div className={styles.container}>
      <PreviousPageButton />
      <FirstPageButton />
      <FirstPageDotSeparator />
      <PreviousThreePagesButton />
      <PreviousTwoPagesButton />
      <PreviousOnePageButton />
      <CurrentPageButton />
      <NextOnePageButton />
      <NextTwoPagesButton />
      <NextThreePagesButton />
      <LastPageDotSeparator />
      <LastPageButton />
      <NextPageButton />
    </div>
  );
};

export default PageIndicator;
