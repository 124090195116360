import { DialogTitle } from "@headlessui/react";
import closeIcon from "assets/close-icon.svg";
import { FC, ReactNode } from "react";
import Heading2 from "ui/typography/Heading2";

import styles from "./ModalHeader.module.scss";

type Props = {
  icon?: ReactNode;
  children: ReactNode;
  onClose: () => void;
};

const ModalHeader: FC<Props> = ({ icon, children, onClose }) => (
  <DialogTitle as="div" className={styles.header}>
    <div className={styles.left}>
      {icon}
      <Heading2>{children}</Heading2>
    </div>
    <div className={styles.right}>
      <button className={styles.closeButton} onClick={onClose} type="button">
        <img src={closeIcon} alt="Close" title="Close" />
      </button>
    </div>
  </DialogTitle>
);

export default ModalHeader;
