import { ListDashes } from "@phosphor-icons/react";
import DashboardPage from "components/layouts/DashboardPage";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import useBusinessDetailsForm from "resources/business-details/hooks/useBusinessDetailsForm";
import useUpdateBusinessDetailsMutation from "resources/business-details/hooks/useUpdateBusinessDetailsMutation";
import Button from "ui/inputs/Button";
import useIsAllowedToUpdateBusinessDetails from "utils/permissions/useIsAllowedToUpdateBusinessDetails";

import BusinessDetailsFormFields from "./BusinessDetailsFormFields";
import styles from "./BusinessDetailsSection.module.scss";

const BusinessDetailsSection: FC = () => {
  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading icon={<ListDashes />}>
          Business details
        </DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <BusinessDetailsForm />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};
export default BusinessDetailsSection;

const BusinessDetailsForm: FC = () => {
  const form = useBusinessDetailsForm();
  const { mutateAsync, isPending } = useUpdateBusinessDetailsMutation();
  const hasWritePermission = useIsAllowedToUpdateBusinessDetails();

  const handleSubmit = form.handleSubmit(async (data) => {
    const result = await mutateAsync({
      dba: data.dba || undefined,
      phoneNumber: data.phoneNumber || undefined,
    });

    form.reset({
      dba: result.dba ?? undefined,
      phoneNumber: result.phoneNumber ?? undefined,
    });
  });

  return (
    <FormProvider {...form}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <BusinessDetailsFormFields />

        <div>
          <Button
            isLoading={isPending}
            type="submit"
            variant="primary"
            disabled={!hasWritePermission || !form.formState.isDirty}
            tooltip={!hasWritePermission && "You don't have permission to edit this information."}
          >
            Save changes
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
