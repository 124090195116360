import { ArrowsClockwise, Check } from "@phosphor-icons/react";
import { ReactNode } from "react";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import ItemWithTooltip from "ui/overlay/Tooltip/ItemWithTooltip/ItemWithTooltip";
import Text from "ui/typography/Text";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";

import styles from "./RutterConnectionCard.module.scss";

type Props = {
  name: string;
  hasSynced?: boolean;

  logo?: ReactNode;
  onDisconnect?: () => void;
};

const ConnectedText = (
  <>
    <Check size={16} className={styles.synced} color="white" />
    <Text size={14} as="p">
      Connected
    </Text>
  </>
);

const SyncingToolTip = (
  <ItemWithTooltip
    wrapperClassName={styles.content}
    content={
      <Text size={12} className={styles.contentTooltip} as="p">
        {"Sync can take from an average of 24h up to a week."}{" "}
      </Text>
    }
  >
    <ArrowsClockwise size={16} className={styles.syncing} />
    <Text size={14}>Syncing store data</Text>
  </ItemWithTooltip>
);

const RutterConnectionCard: React.FC<Props> = ({
  name,
  hasSynced,
  logo,

  onDisconnect,
}) => {
  const isAllowedToConnectStores = useIsAllowedToConnectStores();

  return (
    <Card className={styles.rutterConnectionCard} shadow="xs">
      <div>
        <div className={styles.header}>
          {logo}
          <Text size={14} weight="bold">
            {name}
          </Text>
        </div>

        <div className={styles.indicator}>{hasSynced ? ConnectedText : SyncingToolTip}</div>
      </div>

      {onDisconnect && (
        <Button
          className={styles.button}
          variant="tertiary"
          onClick={onDisconnect}
          disabled={!isAllowedToConnectStores}
          tooltip={
            !isAllowedToConnectStores && (
              <Text size={14}>You don’t have permission to disconnect stores.</Text>
            )
          }
        >
          Disconnect
        </Button>
      )}
    </Card>
  );
};

export default RutterConnectionCard;
