import {
  createBankHolderNameFromEntity,
  extractFirstLastNamesFromPayee,
} from "pages/SendMoneyPage/utils";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { internationalEntityState } from "state/payments/international/entity";
import Helper from "ui/inputs/Helper";
import RadioLabel from "ui/inputs/RadioLabel";
import TextInput from "ui/inputs/TextInput";
import { EntityCompany, EntityIndividual } from "utils/entity";

import styles from "./InternationalPayeeEntity.module.scss";

const InternationalPayeeEntity = () => {
  const [entity, setEntity] = useRecoilState(internationalEntityState);
  const [bankAccountHolderName, setBankAccountHolderName] = useState("");

  const [hasTouchedBankAccountHolderName, setHasTouchedBankAccountHolderName] = useState(false);
  const [hasBankAccountHolderNameError, setHasBankAccountHolderNameError] = useState(false);
  const [bankAccountHolderNameError, setBankAccountHolderNameError] = useState("Invalid name");

  // We handle validation manually for the bank account holder name
  // since we derive it based on the payee name

  // We count having prefilled the bank account holder name as having touched it
  // This allows us to catch errors with the payee name
  useEffect(() => {
    const name = createBankHolderNameFromEntity(entity);
    setHasTouchedBankAccountHolderName(Boolean(name.trim()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBankAccountHolderName(createBankHolderNameFromEntity(entity));
  }, [entity]);

  useEffect(() => {
    if (!hasTouchedBankAccountHolderName) return;
    if (entity.entityType === EntityIndividual) {
      const invalidName = !entity.firstName || !entity.lastName || entity.lastName === " ";
      if (invalidName) {
        setHasBankAccountHolderNameError(true);
        setBankAccountHolderNameError(
          "Invalid bank account holder name, please provide a valid first and last name"
        );
        return;
      }
    } else {
      const invalidName = !entity.companyName;
      if (invalidName) {
        setHasBankAccountHolderNameError(true);
        setBankAccountHolderNameError(
          "Invalid bank account holder name, please provide a valid company name"
        );
        return;
      }
    }
    setHasBankAccountHolderNameError(false);
  }, [bankAccountHolderName, entity, hasTouchedBankAccountHolderName]);

  return (
    <fieldset>
      <div className={styles.container}>
        <legend className={styles.heading}>Payee’s details</legend>
        <div>
          <div>
            <div className={styles.radio}>
              <RadioLabel
                value="1"
                label="🏢 Company"
                checked={entity.entityType === EntityCompany}
                onClick={() => {
                  setEntity({ ...entity, entityType: EntityCompany });
                }}
              />
              <RadioLabel
                value="0"
                label="🧑 Individual"
                checked={entity.entityType === EntityIndividual}
                onClick={() => {
                  setEntity({ ...entity, entityType: EntityIndividual });
                }}
              />
            </div>
            <div>
              <TextInput
                type="text"
                value={bankAccountHolderName}
                label="Bank account holder name"
                hasError={hasBankAccountHolderNameError}
                errorMessage={bankAccountHolderNameError}
                onBlur={() => {
                  setHasTouchedBankAccountHolderName(true);
                }}
                onChange={(name) => {
                  if (entity.entityType === EntityIndividual) {
                    const [firstName, lastName] = extractFirstLastNamesFromPayee(name);
                    setEntity({ ...entity, firstName, lastName });
                  } else if (entity.entityType === EntityCompany) {
                    setEntity({ ...entity, companyName: name, companyBankHolderName: name });
                  }
                }}
              />
              <Helper>
                Bank account holder name must match the name listed as the account owner at the
                receiving bank.
              </Helper>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default InternationalPayeeEntity;
