import DashboardLayout from "components/layouts/Dashboard";
import SuperuserRedirect from "modules/superuser/SuperuserRedirect";
import AccountDetailsPage from "pages/accounts/AccountDetailsPage";
import AccountsOverviewPage from "pages/accounts/AccountsOverviewPage";
import AccountsStatementsPage from "pages/accounts/AccountsStatementsPage";
import AccountsTransactionsPage from "pages/accounts/AccountsTransactionsPage";
import ManageAccountsPage from "pages/accounts/ManageAccountsPage";
import ApplicationEvaluationPage from "pages/ApplicationEvaluationPage";
import BillsPage from "pages/bills/BillsPage";
import CapitalAccountPage from "pages/capital/CapitalAccountPage";
import CapitalDocumentsPage from "pages/capital/CapitalDocumentsPage";
import CapitalOverviewPage from "pages/capital/CapitalOverviewPage";
import CapitalRepayHighbeamCardsPage from "pages/capital/CapitalRepayHighbeamCardsPage";
import CapitalRepayLineOfCreditPage from "pages/capital/CapitalRepayLineOfCreditPage";
import CreditApplicationPage from "pages/capital/CreditApplicationPage";
import DrawdownPage from "pages/capital/DrawdownPage";
import ReviewLineOfferPage from "pages/capital/ReviewLineOfferPage";
import ReviewUpdatedLineAgreementPage from "pages/capital/ReviewUpdatedLineAgreementPage";
import ArchivedCardsPage from "pages/cards/ArchivedCardsPage";
import CardDetailsPage from "pages/cards/CardDetailsPage";
import CardsPage from "pages/cards/CardsPage";
import CardTransactionsPage from "pages/cards/CardTransactionsPage";
import CashFlowPage from "pages/CashFlowPage";
import DepositCheckPage from "pages/DepositCheckPage";
import InsightsRouter from "pages/Insights/InsightsRouter";
import OnboardingRouter from "pages/Onboarding/OnboardingRouter";
import PayeesPage from "pages/payments/PayeesPage";
import CreatePayeePage from "pages/payments/PayeesPage/CreatePayeePage";
import EditPayeePage from "pages/payments/PayeesPage/EditPayeePage";
import PayeeDetailsPage from "pages/payments/PayeesPage/PayeeDetailsPage";
import PaymentsOverviewPage from "pages/payments/PaymentsOverviewPage";
import PlaidOauthRedirectPage from "pages/PlaidOauthRedirectPage";
import SendMoneyPage from "pages/SendMoneyPage";
import SettingsAccountingSoftwarePage from "pages/settings/SettingsAccountingSoftwarePage";
import SettingsBusinessPage from "pages/settings/SettingsBusinessPage";
import SettingsFinancialPartnersPage from "pages/settings/SettingsFinancialPartnersPage";
import SettingsPage from "pages/settings/SettingsPage";
import SettingsSecurityPage from "pages/settings/SettingsSecurityPage";
import SettingsStoresPage from "pages/settings/SettingsStoresPage";
import SettingsUsersPage from "pages/settings/SettingsUsersPage";
import TransferMoneyPage from "pages/TransferMoneyPage";
import { Navigate, Route, Routes } from "react-router-dom";
import useBusiness from "resources/business/queries/useBusiness";
import useIndexRoute from "routes/hooks/useIndexRoute";
import RewriteUrl from "ui/navigation/RewriteUrl";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useIsAllowedToNavigateToAccountsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToAccountsRoutes";
import useIsAllowedToNavigateToCapitalRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToCapitalRoutes";
import useIsAllowedToNavigateToCardTransactionsPage from "utils/permissions/navigation/useIsAllowedToNavigateToCardTransactionsPage";
import useIsAllowedToNavigateToDepositCheckPage from "utils/permissions/navigation/useIsAllowedToNavigateToDepositCheckPage";
import useIsAllowedToNavigateToPaymentsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToPaymentsRoutes";
import useIsAllowedToNavigateToSendMoney from "utils/permissions/navigation/useIsAllowedToNavigateToSendMoney";
import useIsAllowedToNavigateToSpendRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToSpendRoutes";
import useIsAllowedToNavigateToTransferMoney from "utils/permissions/navigation/useIsAllowedToNavigateToTransferMoney";
import useIsAllowedToEditPayees from "utils/permissions/useIsAllowedToEditPayees";

import { PLAID_OAUTH_REDIRECT_URL } from "../../resources/plaid-connections/queries/usePlaidLinkTokenQueryOptions";

const OnboardedRouter = () => {
  const isAllowedToNavigateToSendMoney = useIsAllowedToNavigateToSendMoney();
  const isAllowedToNavigateToTransferMoney = useIsAllowedToNavigateToTransferMoney();
  const isAllowedToNavigateToDepositCheckPage = useIsAllowedToNavigateToDepositCheckPage();
  const isAllowedToNavigateToAccountsRoutes = useIsAllowedToNavigateToAccountsRoutes();
  const isAllowedToNavigateToPaymentsRoutes = useIsAllowedToNavigateToPaymentsRoutes();
  const isAllowedToNavigateToCapitalRoutes = useIsAllowedToNavigateToCapitalRoutes();
  const isAllowedToNavigateToSpendRoutes = useIsAllowedToNavigateToSpendRoutes();
  const isAllowedToNavigateToCardTransactionsPage = useIsAllowedToNavigateToCardTransactionsPage();
  const isAllowedToEditPayee = useIsAllowedToEditPayees();

  const billPayEnabled = useFeatureFlag("BILL_PAY_UI");

  const indexRoute = useIndexRoute();
  const business = useBusiness();
  const isBusinessActive = business.status === "Active";

  if (!isBusinessActive) {
    return <ApplicationEvaluationPage />;
  }

  return (
    <Routes>
      <Route path="/onboarding/*" element={<OnboardingRouter />} />
      <Route path="/home" element={<Navigate to={indexRoute} />} />
      <Route
        path="/accounts/*"
        element={
          isAllowedToNavigateToAccountsRoutes ? (
            <DashboardLayout>
              <Routes>
                <Route path="/" element={<AccountsOverviewPage />} />
                <Route path="/transactions" element={<AccountsTransactionsPage />} />
                <Route path="/statements" element={<AccountsStatementsPage />} />
                <Route path="/documents" element={<AccountsStatementsPage />} />
                <Route path="/manage-accounts" element={<ManageAccountsPage />} />
                <Route path="/high-yield" element={<Navigate replace to={indexRoute} />} />
                <Route path="/:accountGuid" element={<AccountDetailsPage />} />
              </Routes>
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />
      <Route
        path="/cards/*"
        element={
          <DashboardLayout>
            <Routes>
              <Route path="/" element={<CardsPage />} />
              <Route path="/archived" element={<ArchivedCardsPage />} />

              {isAllowedToNavigateToCardTransactionsPage && (
                <Route path="/transactions" element={<CardTransactionsPage />}>
                  <Route
                    path=":unitCoDepositAccountId/:transactionId"
                    element={<CardTransactionsPage />}
                  />

                  {/* NB(alex): There may be a better way to do this. Basically, for these statuses, we use the card authorization flexpane, otherwise we use the card transaction flexpane... */}
                  <Route path="pending/:authorizationId" element={<CardTransactionsPage />} />
                  <Route path="canceled/:authorizationId" element={<CardTransactionsPage />} />
                  <Route path="declined/:authorizationId" element={<CardTransactionsPage />} />
                </Route>
              )}

              <Route path="/:cardId" element={<CardDetailsPage />}>
                <Route
                  path="completed/:unitCoDepositAccountId/:transactionId"
                  element={<CardDetailsPage />}
                />

                {/* NB(alex): There may be a better way to do this. Basically, for these statuses, we use the card authorization flexpane, otherwise we use the card transaction flexpane... */}
                <Route path="pending/:authorizationId" element={<CardDetailsPage />} />
                <Route path="canceled/:authorizationId" element={<CardDetailsPage />} />
                <Route path="declined/:authorizationId" element={<CardDetailsPage />} />
              </Route>
            </Routes>
          </DashboardLayout>
        }
      />
      <Route
        path="/payments/*"
        element={
          isAllowedToNavigateToPaymentsRoutes ? (
            <DashboardLayout>
              <Routes>
                <Route path="/" element={<PaymentsOverviewPage />}>
                  <Route
                    path="/payment-approvals/:paymentApprovalGuid"
                    element={<PaymentsOverviewPage />}
                  />
                  <Route
                    path="/scheduled-payments/:recurringPaymentId"
                    element={<PaymentsOverviewPage />}
                  />
                </Route>

                {billPayEnabled && <Route path="/bills/*" element={<BillsPage />} />}

                <Route path="/payees" element={<PayeesPage />} />
                <Route path="/payees/new" element={<CreatePayeePage />} />
                <Route path="/payees/:payeeGuid" element={<PayeeDetailsPage />} />
                <Route
                  path="/payees/:payeeGuid/edit"
                  element={
                    isAllowedToEditPayee ? (
                      <EditPayeePage />
                    ) : (
                      <Navigate
                        to="/payees" // NB(alex): we can also redirect to `/payees/:payeeGuid` but that takes more work so going to leave it like this for now.
                      />
                    )
                  }
                />
              </Routes>
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />

      <Route
        path="/cash-flow/*"
        element={
          isAllowedToNavigateToSpendRoutes ? (
            <DashboardLayout>
              <CashFlowPage />
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />

      <Route
        path="/spend/*"
        element={
          isAllowedToNavigateToSpendRoutes ? (
            <DashboardLayout>
              <InsightsRouter />
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />

      <Route
        path="/capital/*"
        element={
          isAllowedToNavigateToCapitalRoutes ? (
            <DashboardLayout>
              <Routes>
                <Route path="/" element={<CapitalOverviewPage />} />
                <Route path="/documents" element={<CapitalDocumentsPage />} />
                <Route path="/application/*" element={<CreditApplicationPage />} />
                <Route path="/:capitalAccountGuid" element={<CapitalAccountPage />}>
                  <Route
                    path="pending-drawdowns/:pendingDrawdownGuid"
                    element={<CapitalAccountPage />}
                  />
                </Route>
                <Route
                  path="/:capitalAccountGuid/cards/repay/*"
                  element={<CapitalRepayHighbeamCardsPage />}
                />
                <Route
                  path="/:capitalAccountGuid/credit/repay/*"
                  element={<CapitalRepayLineOfCreditPage />}
                />
                <Route path="/:capitalAccountGuid/credit/draw-down/*" element={<DrawdownPage />} />
                <Route
                  path="/:capitalAccountGuid/review-line-offer/*"
                  element={<ReviewLineOfferPage />}
                />
                <Route
                  path="/:capitalAccountGuid/review-updated-line-agreement"
                  element={<ReviewUpdatedLineAgreementPage />}
                />
              </Routes>
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />
      <Route
        path="/settings/*"
        element={
          <DashboardLayout>
            <Routes>
              <Route path="/" element={<SettingsPage />} />
              <Route path="/stores" element={<SettingsStoresPage />} />
              <Route
                path="/external-cards"
                element={
                  <RewriteUrl
                    rewrite={({ pathname }) =>
                      pathname.replace("/external-cards", "/financial-partners")
                    }
                  />
                }
              />
              <Route path="/financial-partners" element={<SettingsFinancialPartnersPage />} />
              <Route path="/business" element={<SettingsBusinessPage />} />
              <Route path="/accounting-software" element={<SettingsAccountingSoftwarePage />} />
              <Route path="/users" element={<SettingsUsersPage />} />
              <Route path="/security" element={<SettingsSecurityPage />} />
            </Routes>
          </DashboardLayout>
        }
      />
      <Route
        path="/send-money"
        element={
          isAllowedToNavigateToSendMoney ? (
            <DashboardLayout>
              <SendMoneyPage />
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />
      <Route
        path="/transfer-money"
        element={
          isAllowedToNavigateToTransferMoney ? (
            <DashboardLayout>
              <TransferMoneyPage />
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />
      <Route
        path="/deposit-check"
        element={
          isAllowedToNavigateToDepositCheckPage ? (
            <DashboardLayout>
              <DepositCheckPage />
            </DashboardLayout>
          ) : (
            <Navigate to={indexRoute} />
          )
        }
      />
      <Route path={PLAID_OAUTH_REDIRECT_URL} element={<PlaidOauthRedirectPage />} />
      {/* Redirects */}
      <Route path="/superuser" element={<SuperuserRedirect indexRoute={indexRoute} />} />

      <Route path="/high-yield/*" element={<Navigate replace to={indexRoute} />} />
      <Route path="*" element={<Navigate replace to={indexRoute} />} />
    </Routes>
  );
};

export default OnboardedRouter;
