import "react-toastify/dist/ReactToastify.css";
import { X } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { ToastContainer as ToastifyToastContainer, toast, Slide } from "react-toastify";
import Divider from "ui/data-display/Divider";
import InfoIcon from "ui/icons/InfoIcon";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./Toast.module.scss";

export type ToastAction = {
  text: string;
  onClick: () => void;
};

export type ToastType = "info" | "error" | "warning" | "success";

export type ToastProps = {
  content: ReactNode;
  action?: ToastAction;
  type: ToastType;
};

export const Toast: React.FC<ToastProps & { closeToast?: () => void }> = ({
  closeToast,
  content,
  action,
  type,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        {/* Parent `div` prevents flexbox from squishing the `svg` */}
        <InfoIcon variant={type} weight="fill" />
      </div>

      <div className={styles.body}>
        <div className={styles.content}>
          {typeof content === "string" ? <Text size={14}>{content}</Text> : content}
        </div>
      </div>

      {action && <Divider orientation="vertical" className={styles.divider} />}

      <div className={styles.actionsContainer}>
        {action && (
          <Button
            variant="plain"
            className={styles.action}
            onClick={(e) => {
              e.preventDefault();
              action.onClick();
            }}
          >
            {action.text}
          </Button>
        )}

        <Button variant="plain" onClick={closeToast} className={styles.close} isSquare>
          <X size={16} />
        </Button>
      </div>
    </div>
  );
};

export const notify = (
  type: ToastProps["type"],
  content: ToastProps["content"],
  options: Partial<ToastProps> = {}
) => {
  toast[type](<Toast type={type} content={content} {...options} />);
};

export const ToastContainer = () => (
  <ToastifyToastContainer
    position="bottom-center"
    autoClose={7000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    transition={Slide}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="dark"
    icon={false}
    closeButton={false}
  />
);
