import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitCoAuthorizationsQueryOptions from "resources/unit-co-transactions/queries/useUnitCoAuthorizationsQueryOptions";
import useSearchParamNumber from "utils/searchParams/useSearchParamNumber";

export const useCurrentPageSearchParam = () => useSearchParamNumber("pendingPage", 1);

export const PENDING_TRANSACTIONS_PAGE_SIZE = 10;

type Params = {
  accountId: string;
};

const useAccountAuthorizations = ({ accountId }: Params) => {
  const [currentPage] = useCurrentPageSearchParam();

  const { data } = useSuspenseQuery(
    useUnitCoAuthorizationsQueryOptions({
      accountId,
      status: "Authorized",
      offset: PENDING_TRANSACTIONS_PAGE_SIZE * (currentPage - 1),
      limit: PENDING_TRANSACTIONS_PAGE_SIZE,
      includeNonAuthorized: true,
    })
  );

  return data;
};

export default useAccountAuthorizations;
