import { Info } from "@phosphor-icons/react";
import { Suspense } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import usePlaidLinkToken from "resources/plaid-connections/queries/usePlaidLinkToken";
import useRefreshPlaidConnectionsQuery from "resources/plaid-connections/queries/useRefreshPlaidConnectionsQuery";
import duplicatePlaidConnectionGuidState from "state/plaid/duplicateConnectionGuid";
import isPlaidExistingAccountModalOpenState from "state/plaid/isExistingAccountModalOpen";
import colors from "styles/colors";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";
import useHighbeamPlaidLink from "utils/customHooks/useHighbeamPlaidLink";

const PlaidAddAccountsModal = () => (
  <Suspense fallback={null}>
    <PlaidAddAccountsModalMain />
  </Suspense>
);

const PlaidAddAccountsModalMain = () => {
  const setIsPlaidExistingAccountModalOpen = useSetRecoilState(
    isPlaidExistingAccountModalOpenState
  );
  const duplicateConnectionGuid = useRecoilValue(duplicatePlaidConnectionGuidState);
  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQuery();
  const linkToken = usePlaidLinkToken({
    connectionGuid: duplicateConnectionGuid,
    accountType: "DEPOSITORY",
    allowAccountSelectionOnUpdate: true,
  });

  const closeModal = () => {
    setIsPlaidExistingAccountModalOpen(false);
    refreshPlaidConnectionsQuery();
  };
  const { openPlaid } = useHighbeamPlaidLink({
    linkToken,
    onComplete: closeModal,
    isNewConnection: false,
  });

  return (
    <Modal
      icon={<Info weight="fill" color={colors.blue} size={24} />}
      title="Connection already exists"
      buttonText="Continue"
      focusPrimaryButton
      showCancel
      onClick={openPlaid}
      isLoading={false}
      isPrimaryButtonDisabled={false}
      onClose={closeModal}
    >
      <Text size={14}>
        You have already connected this bank. Please click continue to add any new accounts to the
        existing connection.
      </Text>
    </Modal>
  );
};

export default PlaidAddAccountsModal;
