import { Dialog, DialogBackdrop, Transition, TransitionChild } from "@headlessui/react";
import { X } from "@phosphor-icons/react";
import classNames from "classnames";
import React, { Fragment, ReactNode, useRef } from "react";
import Button, { ButtonVariant } from "ui/inputs/Button";
import Text from "ui/typography/Text";
import { transitions, useTransition } from "utils/transitions";

import styles from "./Modal.module.scss";
import ModalFooter from "./ModalFooter";

type ChildParams = {
  onClick: () => void;
  handleClose: () => void;
};

type FooterWithPropDrillingProps = {
  buttonText?: string;
  buttonVariant?: ButtonVariant;
  focusPrimaryButton?: boolean;
  fullWidthButton?: boolean;
  isCancelButtonDisabled?: boolean;
  isLoading?: boolean;
  isPrimaryButtonDisabled?: boolean;
  showCancel?: boolean;
};

type Props = FooterWithPropDrillingProps & {
  bodyClassName?: string;
  children?: ReactNode | ((args: ChildParams) => ReactNode);
  className?: string;
  footer?: ReactNode | ((args: ChildParams) => ReactNode);
  icon?: ReactNode;
  formId?: string;
  isOpen?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  small?: boolean;
  title: string;
};

const Modal: React.FC<Props> = ({
  bodyClassName,
  children,
  className,
  footer,
  icon,
  formId,
  isOpen = true,
  onClick = () => {},
  onClose = () => {},
  small = false,
  title,
  // footer props
  // TODO(alex): refactor this to use composition instead of prop drilling
  buttonText,
  buttonVariant = "primary",
  isPrimaryButtonDisabled,
  isCancelButtonDisabled = false,
  focusPrimaryButton = false,
  showCancel = false,
  isLoading,
  fullWidthButton = false,
}) => {
  const primaryButtonRef = useRef<HTMLButtonElement>(null);
  const initialFocusRef = focusPrimaryButton ? primaryButtonRef : undefined;
  const hasFooter = showCancel || buttonText;

  if (footer !== undefined && hasFooter) {
    throw new Error("Cannot have both footer and showCancel or buttonText props");
  }

  const { show, setShow, handleClose } = useTransition(isOpen, {
    initiateClose() {
      setShow(false);
    },
    onClose,
  });

  const getBody = () => (
    <div className={classNames(styles.container, small && styles.small, className)}>
      <Dialog.Title as="div" className={styles.header}>
        <div className={styles.row}>
          {icon && icon}
          <Text size={18} weight={"bold"}>
            {title}
          </Text>
        </div>
        <Button variant="tertiary" onClick={handleClose} size="sm" isSquare>
          <X />
        </Button>
      </Dialog.Title>

      <div className={classNames(styles.body, bodyClassName)}>
        {typeof children === "function" ? children({ onClick, handleClose }) : children}
      </div>

      {typeof footer === "function" ? footer({ onClick, handleClose }) : footer}

      {hasFooter && !fullWidthButton && (
        <ModalFooter>
          {showCancel && (
            <Button disabled={isCancelButtonDisabled} onClick={handleClose}>
              Close
            </Button>
          )}
          {buttonText && (
            <Button
              type="submit"
              form={formId}
              variant={buttonVariant}
              ref={primaryButtonRef}
              disabled={isPrimaryButtonDisabled}
              isLoading={isLoading}
            >
              {buttonText}
            </Button>
          )}
        </ModalFooter>
      )}

      {hasFooter && fullWidthButton && (
        <ModalFooter>
          {buttonText && (
            <Button
              type="submit"
              form={formId}
              variant={buttonVariant}
              ref={primaryButtonRef}
              disabled={isPrimaryButtonDisabled}
              className={styles.fullWidthButton}
              isLoading={isLoading}
            >
              {buttonText}
            </Button>
          )}
        </ModalFooter>
      )}
    </div>
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Transition show={show} as={Fragment}>
      <Dialog unmount onClose={handleClose} initialFocus={initialFocusRef}>
        <TransitionChild as={Fragment} {...transitions.opacity}>
          <DialogBackdrop className={styles.backdrop} onClick={handleClose} />
        </TransitionChild>
        <TransitionChild as={Fragment} {...transitions.zoom}>
          <form className={styles.form} onSubmit={handleSubmit} id={formId}>
            {getBody()}
          </form>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
};

export default Object.assign(Modal, {
  Footer: ModalFooter,
});
