import { FC, ReactNode } from "react";
import colors from "styles/colors";
import Pill, { PillColor } from "ui/data-display/Pill";
import Text from "ui/typography/Text";

import styles from "./TransactionFlexpaneStatus.module.scss";

type Props = {
  pillColor: PillColor;
  status: ReactNode;
  methodName: string;
};

const TransactionFlexpaneStatus: FC<Props> = ({ pillColor, status, methodName }) => {
  return (
    <div className={styles.container}>
      <Pill color={pillColor} bordered>
        {status}
      </Pill>
      <Text size={14} weight="medium" color={colors.grey[800]}>
        {methodName}
      </Text>
    </div>
  );
};

export default TransactionFlexpaneStatus;
