import { captureException } from "@sentry/react";
import isMfaInProgressState from "modules/mfa/isMfaInProgressState";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import auth0ClientState from "state/auth/auth0Client";
import { useRefreshJwt } from "state/auth/jwt";
import ModalV2, { ModalRefValue } from "ui/overlay/ModalV2";
import Text from "ui/typography/Text";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import { createPopup } from "utils/popup";

import handleMfaError from "./utils/handleMfaError";
import useMfaWithRedirect from "./utils/useMfaWithRedirect";

const MFA_FORM_ID = "mfa-form";

const MfaModal = () => {
  const [isMfaInProgress, setIsMfaInProgress] = useRecoilState(isMfaInProgressState);
  const auth0 = useRecoilValue(auth0ClientState);
  const { segmentTrack } = useSegment();
  const refreshJwt = useRefreshJwt();
  const mfaWithRedirect = useMfaWithRedirect();

  const [mfaPopup, setMfaPopup] = useState<Window>();

  const doMfa = async () => {
    // NOTE: Popup must be created before any async calls to not have browsers block it
    const popup = createPopup("", "auth0:authorize:popup", 400, 600);

    try {
      if (!popup) {
        // Popup was blocked for some reason so just redirect.
        // Unfortunately, any app state will be lost.
        segmentTrack(SEGMENT_EVENTS.MFA_POPUP_OPEN_FAILED);
        mfaWithRedirect();
        return;
      }

      setMfaPopup(popup);
      await auth0.loginWithPopup(
        {
          authorizationParams: {
            acr_values: "mfa_required", // eslint-disable-line camelcase
          },
        },
        { popup, timeoutInSeconds: 60 * 10 }
      );
    } catch (err) {
      handleMfaError(err);
    } finally {
      await refreshJwt().catch((err) => {
        // NOTE: If you got here after seeing an "Error: Login required” and 2FA not completing,
        // you're 1) on localhost, and 2) using a browser with Intelligent Tracking Protection (ITP).
        // This is a non-issue on staging and prod as we use the custom domains feature on Auth0. See:
        // https://auth0.com/docs/troubleshoot/authentication-issues/renew-tokens-when-using-safari#workarounds
        if (err.error === "login_required") {
          mfaWithRedirect();
        } else {
          captureException(err);
          throw err;
        }
      });
      mfaPopup?.close();
      setMfaPopup(undefined);
    }
  };

  useEffect(
    () => () => {
      mfaPopup?.close();
    },
    [mfaPopup]
  );

  const modalRef = useRef<ModalRefValue>(null);
  const handleClose = () => modalRef.current?.close();
  const isDoingMfa = Boolean(mfaPopup);

  if (!isMfaInProgress) {
    return null;
  }

  return (
    <ModalV2
      isOpen
      modalRef={modalRef}
      onClose={() => {
        mfaPopup?.close();
        setIsMfaInProgress(false);
      }}
    >
      <ModalV2.Content
        element="form"
        id={MFA_FORM_ID}
        onSubmit={(e) => {
          e.preventDefault();
          doMfa();
        }}
      >
        <ModalV2.Content.Header heading="Additional verification needed" />

        <ModalV2.Content.Body>
          <Text size={14}>
            We need to quickly make sure it’s you. Please continue to verify your identity in the
            window that opens up.
          </Text>
        </ModalV2.Content.Body>

        <ModalV2.Content.Footer>
          <ModalV2.Content.Footer.CancelButton onClick={() => handleClose()} tabIndex={-1} />
          <ModalV2.Content.Footer.SubmitButton form={MFA_FORM_ID} autoFocus isLoading={isDoingMfa}>
            Continue
          </ModalV2.Content.Footer.SubmitButton>
        </ModalV2.Content.Footer>
      </ModalV2.Content>
    </ModalV2>
  );
};

export default MfaModal;
