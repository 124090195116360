import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUserInvitationsQueryOptions from "resources/user-invitations/queries/useUserInvitationsQueryOptions";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const useRemoveUserInvitationMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const refreshUserInvitationsQuery = useRefreshQuery(useUserInvitationsQueryOptions().queryKey);

  return useMutation({
    mutationFn: async (invitationGuid: string) =>
      highbeamApi.userInvitation.delete(businessGuid, invitationGuid),
    onError: (err) => {
      notify("error", "Something went wrong! Please try again.");
      captureException(err);
    },
    onSuccess: async () => {
      await refreshUserInvitationsQuery();
      notify("success", "Invite canceled");
    },
  });
};

export default useRemoveUserInvitationMutation;
