import classNames from "classnames";
import { PropsWithChildren } from "react";

import styles from "./AccentCard.module.scss";

type Props = {
  withTopBorder?: boolean;
  className?: string;
} & PropsWithChildren;

const AccentCard: React.FC<Props> = ({ withTopBorder = false, className, children }) => (
  <div className={classNames(styles.container, { [styles.topBorder]: withTopBorder }, className)}>
    {children}
  </div>
);

export default AccentCard;
