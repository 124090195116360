import { FC } from "react";
import Divider from "ui/data-display/Divider";
import Flexpane from "ui/overlay/Flexpane";

import PendingDrawdownDetails from "./components/PendingDrawdownDetails";
import PendingDrawdownInvoice from "./components/PendingDrawdownInvoice";
import PendingDrawdownTransferDetails from "./components/PendingDrawdownTransferDetails";
import PendingDrawdownFlexpaneProvider from "./context/PendingDrawdownFlexpaneProvider";

type Props = {
  pendingDrawdownGuid?: string;
  onClose: () => void;
};

const PendingDrawdownFlexpane: FC<Props> = ({ pendingDrawdownGuid, onClose }) => {
  const isOpen = Boolean(pendingDrawdownGuid);

  return (
    <Flexpane isOpen={isOpen} onClose={onClose}>
      {pendingDrawdownGuid && (
        <PendingDrawdownFlexpaneProvider pendingDrawdownGuid={pendingDrawdownGuid} close={onClose}>
          <Flexpane.Header onClose={onClose}>Drawdown info</Flexpane.Header>

          <Flexpane.Section>
            <PendingDrawdownTransferDetails />
            <Divider />
            <PendingDrawdownDetails />
            <Divider />
            <PendingDrawdownInvoice />
          </Flexpane.Section>
        </PendingDrawdownFlexpaneProvider>
      )}
    </Flexpane>
  );
};

export default PendingDrawdownFlexpane;
