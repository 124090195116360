import classNames from "classnames";

import styles from "./Divider.module.scss";

export type DividerOrientation = "horizontal" | "vertical";

type Props = {
  className?: string;
  orientation?: DividerOrientation;
  fullWidth?: boolean;
  margin?: 0 | 24 | 32;
};

const Divider: React.FC<Props> = ({
  className,
  orientation = "horizontal",
  fullWidth,
  margin = 24,
}) => {
  const isHorizontal = orientation === "horizontal";

  return (
    <div
      className={classNames(
        styles.divider,
        styles[`margin--${margin}`],
        {
          [styles.horizontal]: isHorizontal,
          [styles.vertical]: !isHorizontal,
          [styles.fullWidth]: fullWidth,
        },
        className
      )}
    />
  );
};

export default Divider;
