import { PlusCircle } from "@phosphor-icons/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getIcon, getInstitutionNameFromId } from "components/Accounts/AccountLabel";
import OnboardingLayout from "components/layouts/Onboarding";
import AddConnectionModal from "pages/AddConnectionModal";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { EmptyExternalBankAccountState } from "pages/settings/SettingsFinancialPartnersPage/ExternalBankAccountsSection";
import PlaidConnectionCard from "pages/settings/SettingsPage/PlaidConnectionCard";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import usePlaidDepositoryConnections from "resources/plaid-connections/queries/usePlaidDepositoryConnections";
import { CREDIT_COMPARISON_SIGNUP_SOURCE } from "resources/signup-source/constants";
import useSignupSourceQueryOptions from "resources/signup-source/queries/useSignupSourceQueryOptions";
import Button from "ui/inputs/Button";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import genericStyles from "../OnboardingGeneric.module.scss";
import OnboardingStep from "../OnboardingStep";

import styles from "./OnboardingConnectPlaidAccountsPage.module.scss";

const OnboardingConnectPlaidAccountsPage = () => {
  const { segmentTrack } = useSegment();
  const navigate = useNavigate();

  const { data: signupSource } = useSuspenseQuery(useSignupSourceQueryOptions());
  const isSignupSourceCreditComparison = signupSource === CREDIT_COMPARISON_SIGNUP_SOURCE;

  // NB(alex): Need to audit this, was copied over from `ConnectBankAccountsView`. We might be able to delete it from both?
  const handleNext = ({ skippedConnectStore = false }) => {
    if (isSignupSourceCreditComparison) {
      return navigate(CREDIT_COMPARISON_PATH);
    }
    navigate("/onboarding/bank-application", {
      state: { fromConnectStore: !skippedConnectStore },
    });
  };

  const [searchParams] = useSearchParams();
  const allowSkip = searchParams.get("allowSkip");

  const [addConnectionModalVariant, setAddConnectionModalVariant] =
    useState<PlaidLinkTokenRep.AccountType>();

  const depositoryConnections = usePlaidDepositoryConnections();
  // usePlaidConnectionsQueryOptions

  return (
    <>
      {addConnectionModalVariant && (
        <AddConnectionModal
          accountType={addConnectionModalVariant}
          onClose={() => setAddConnectionModalVariant(undefined)}
          onComplete={() => setAddConnectionModalVariant(undefined)}
        />
      )}

      <OnboardingLayout>
        <OnboardingStep
          title="Connect your current accounts"
          subtitle={
            "Highbeam uses your connected accounts to provide tailored banking services and real-time insights to manage cash flow."
          }
          actions={
            <>
              {allowSkip && (
                <Button
                  className={genericStyles.button__skip}
                  type="submit"
                  onClick={() => {
                    segmentTrack(SEGMENT_EVENTS.ONBOARDING_STORES_SKIPPED);
                    handleNext({ skippedConnectStore: true });
                  }}
                >
                  Skip this step
                </Button>
              )}

              <Button
                variant="primary"
                className={genericStyles.button}
                type="submit"
                onClick={() => {
                  handleNext({ skippedConnectStore: false });
                }}
              >
                {isSignupSourceCreditComparison ? "Compare offers" : "Next"}
              </Button>
            </>
          }
        >
          {depositoryConnections.length === 0 && (
            <EmptyExternalBankAccountState
              setAddConnectionModalVariant={setAddConnectionModalVariant}
              onComplete={() => setAddConnectionModalVariant(undefined)}
            />
          )}

          <div className={styles.cardsContainer}>
            {depositoryConnections.map((connection) => (
              <PlaidConnectionCard
                key={connection.guid}
                name={
                  connection.institutionName ??
                  getInstitutionNameFromId(connection.accounts[0].institutionId, "bank")
                }
                connectionGuid={connection.guid}
                isActive={connection.isActive}
                logo={getIcon(connection.accounts[0].institutionId)}
                accounts={connection.accounts}
                accountType="DEPOSITORY"
              />
            ))}
          </div>

          <Button
            variant="tertiary"
            fullWidth
            onClick={() => setAddConnectionModalVariant("DEPOSITORY")}
          >
            <PlusCircle /> Connect another account
          </Button>
        </OnboardingStep>
      </OnboardingLayout>
    </>
  );
};

export default OnboardingConnectPlaidAccountsPage;
