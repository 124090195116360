namespace ChargeCardOfferRep {
  export enum ChargeCardCreditTerm {
    Production = "Production", // DEPRECATED
    Sandbox = "Sandbox", // DEPRECATED

    ProductionExtend = "ProductionExtend",
    ProductionFlex = "ProductionFlex",
    ProductionCash = "ProductionCash",

    SandboxExtend = "SandboxExtend",
    SandboxFlex = "SandboxFlex",
    SandboxCash = "SandboxCash",
  }
  export interface Complete {
    readonly guid: string;
    readonly maxCreditLimit: number;
    readonly minCashback: number;
    readonly maxCashback: number;
    readonly creditTerms: ChargeCardCreditTerm;
  }
}

export default ChargeCardOfferRep;
