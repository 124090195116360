import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import Flexpane from "ui/overlay/Flexpane";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

const PaymentInfoFlexpaneAmountSection = () => {
  const { payment } = usePaymentInfoFlexpaneContext();

  return (
    <Flexpane.Section>
      <TransactionFlexpaneAmount
        cents={payment.attributes.amount}
        direction={payment.attributes.direction === "Debit" ? "positive" : "negative"}
      />
    </Flexpane.Section>
  );
};

export default PaymentInfoFlexpaneAmountSection;
