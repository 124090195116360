import { useSuspenseQuery } from "@tanstack/react-query";

import getBankAccountsByUnitCoDepositAccountId from "../utils/getBankAccountsByUnitCoDepositAccountId";

import { useOpenBankAccountsQueryOptions } from "./useOpenBankAccounts";

const useOpenBankAccountsByUnitCoDepositAccountId = () => {
  const { data: openBankAccountsByUnitCoDepositAccountId } = useSuspenseQuery({
    ...useOpenBankAccountsQueryOptions(),
    select: (openBankAccounts) => {
      return getBankAccountsByUnitCoDepositAccountId(openBankAccounts);
    },
  });

  return openBankAccountsByUnitCoDepositAccountId;
};

export default useOpenBankAccountsByUnitCoDepositAccountId;
