import getSymbolFromCurrency from "currency-symbol-map";
import { ComponentProps, forwardRef, ForwardRefRenderFunction } from "react";
import CurrencyInputV3 from "ui/inputs/CurrencyInputV3";
import getCurrencyDecimalPlaces from "utils/money/getCurrencyDecimalPlaces";

import styles from "./MoneyInputsAmountInput.module.scss";

type InternallyDeterminedCurrencyInputPropKeys =
  | "startAdornment"
  | "decimalsLimit"
  | "decimalScale"
  | "allowDecimals";

type Props = Omit<
  ComponentProps<typeof CurrencyInputV3>,
  InternallyDeterminedCurrencyInputPropKeys
> & {
  currency: string | null;
};

const MoneyInputsAmountInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (props, ref) => {
  const { currency, onFocus, ...currencyInputProps } = props;

  const decimalPlaces = currency ? getCurrencyDecimalPlaces(currency) : undefined;
  const allowDecimals = Boolean(decimalPlaces !== 0);
  const currencySymbol = currency ? getSymbolFromCurrency(currency) : undefined;

  return (
    <CurrencyInputV3
      ref={ref}
      startAdornment={currencySymbol}
      className={styles.amountInput}
      decimalsLimit={decimalPlaces}
      decimalScale={decimalPlaces}
      allowDecimals={allowDecimals}
      onFocus={(e) => {
        e.target.select();
        return onFocus?.(e);
      }}
      {...currencyInputProps}
    />
  );
};

export default forwardRef(MoneyInputsAmountInput);
