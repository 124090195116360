import { Navigate, useLocation, Location } from "react-router-dom";

type Props = {
  rewrite: (location: Location) => string;
};

/**
 * Rendering this component REPLACES the URL with the one supplied by the [rewrite] prop.
 */
const RewriteUrl: React.FC<Props> = ({ rewrite }) => {
  const location = useLocation();
  return <Navigate to={rewrite(location)} replace />;
};

export default RewriteUrl;
