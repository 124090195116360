import { useQuery } from "@tanstack/react-query";
import BusinessMemberRep from "reps/BusinessMemberRep";
import UserInvitationRep from "reps/UserInvitationRep";
import UserRoleMembershipRep from "reps/UserRoleMembershipRep";
import UserRoleRep from "reps/UserRoleRep";
import useBusinessMembersQueryOptions from "resources/business-members/queries/useBusinessMembersQueryOptions";
import { useBusinessQueryOptions } from "resources/business/queries/useBusiness";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useUserInvitationsQueryOptions from "resources/user-invitations/queries/useUserInvitationsQueryOptions";
import useUserRoleMembershipsQueryOptions from "resources/user-roles/queries/useUserRoleMembershipsQueryOptions";
import useUserRolesQueryOptions from "resources/user-roles/queries/useUserRolesQueryOptions";

import getUserRoleNameForRoleGuids from "../utils/getUserRoleNameForRoleGuids";

type BaseUsersTableDatum = {
  userRoleName: string;
  userRoleGuids: string[];
  isCurrentUser?: boolean;
  isOrganizationOwner?: boolean;
};

type BusinessMemberMembership = UserRoleMembershipRep.Complete & {
  userRole: UserRoleRep.Complete;
};

export type BusinessMemberUsersTableDatum = BaseUsersTableDatum &
  BusinessMemberRep.Complete & {
    isInvited: false;
    memberships: BusinessMemberMembership[];
  };

type InvitedMemberUsersTableDatum = BaseUsersTableDatum &
  UserInvitationRep.Complete & { isInvited: true };

// Note: type can be narrowed by checking `datum.isInvited`
export type UsersTableDatum = BusinessMemberUsersTableDatum | InvitedMemberUsersTableDatum;

const useUsersTableData = () => {
  const userGuid = useUserGuid();
  const { data: business } = useQuery(useBusinessQueryOptions());
  const { data: businessMembers } = useQuery(useBusinessMembersQueryOptions());
  const { data: userInvitations } = useQuery(useUserInvitationsQueryOptions());
  const { data: userRoleMemberships } = useQuery(useUserRoleMembershipsQueryOptions());
  const { data: userRoles } = useQuery(useUserRolesQueryOptions());

  const enabled =
    business && businessMembers && userInvitations && userRoleMemberships && userRoles;

  if (!enabled) {
    return {
      data: undefined,
      isPending: true as const,
    };
  }

  // Appends the user role guids to the business members
  const businessMembersData: BusinessMemberUsersTableDatum[] = businessMembers.map(
    (businessMember) => {
      const memberships: BusinessMemberMembership[] = userRoleMemberships
        .filter((userRoleMembership) => userRoleMembership.userGuid === businessMember.userGuid)
        .map((membershipRep) => ({
          ...membershipRep,
          userRole: userRoles.find((role) => role.guid === membershipRep.userRoleGuid)!,
        }));

      const userRoleGuids = memberships.map((membership) => membership.userRoleGuid);

      const isOrganizationOwner = businessMember.userGuid === business.ownerUserGuid;
      return {
        ...businessMember,
        isInvited: false as const, // "as const" allows us to check if !isInvited, then the datum is a BusinessMemberRep.Complete, otherwise it is a UserInvitationRep.Complete
        userRoleGuids: userRoleGuids,
        memberships: memberships,
        isCurrentUser: businessMember.userGuid === userGuid,
        isOrganizationOwner: isOrganizationOwner,
        userRoleName: isOrganizationOwner
          ? "Account owner"
          : getUserRoleNameForRoleGuids(userRoleGuids, userRoles),
      };
    }
  );

  const invitedMembersData: InvitedMemberUsersTableDatum[] = userInvitations.map(
    (userInvitation) => ({
      ...userInvitation,
      isInvited: true as const,
      userRoleName: getUserRoleNameForRoleGuids(userInvitation.userRoleGuids, userRoles),
    })
  );

  const combinedData: UsersTableDatum[] = [...businessMembersData, ...invitedMembersData];

  const data = combinedData.sort((a, b) => a.displayName.localeCompare(b.displayName)); // Sort alphabetically by name;

  return {
    data: data,
    isPending: false as const,
  };
};

export default useUsersTableData;
