import LoadingPage from "pages/LoadingPage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { localStorageLinkTokenState } from "state/localStorage/linkToken";
import useHighbeamPlaidLink from "utils/customHooks/useHighbeamPlaidLink";

import { localStoragePostPlaidOauthRedirectPath } from "../../state/localStorage/postPlaidOauthRedirectPath";
import useSegment, { SEGMENT_EVENTS } from "../../utils/customHooks/useSegment";

/**
 * Completes the Plaid OAuth flow
 */
const PlaidOauthRedirectPage = () => {
  const { segmentTrack } = useSegment();
  const navigate = useNavigate();
  const existingLinkToken = useRecoilValue(localStorageLinkTokenState);
  const postPlaidOauthRedirectPath = useRecoilValue(localStoragePostPlaidOauthRedirectPath);
  const { openPlaid, isPlaidReady } = useHighbeamPlaidLink({
    linkToken: existingLinkToken,
    onComplete: () => navigate(postPlaidOauthRedirectPath),
    isNewConnection: false,
  });
  useEffect(() => {
    if (isPlaidReady) {
      segmentTrack(SEGMENT_EVENTS.PLAID_OAUTH_FLOW_USED);
      openPlaid();
    }
  }, [openPlaid, isPlaidReady, segmentTrack]);
  if (isPlaidReady) {
    openPlaid();
  }
  return (
    <LoadingPage
      location={PlaidOauthRedirectPage.name}
      text={"Connecting your bank..."}
      showLoadingAnimation={false}
    />
  );
};

export default PlaidOauthRedirectPage;
