import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const CHARGE_CARD_REPAYMENT_INFO_QUERY_KEY = "charge-card-repayment-info";

const useChargeCardRepaymentInfoQueryOptions = (chargeCardAccountGuid: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [CHARGE_CARD_REPAYMENT_INFO_QUERY_KEY, { businessGuid, chargeCardAccountGuid }],
    queryFn: () => {
      return highbeamApi.chargeCard.getRepaymentsInfo(businessGuid, chargeCardAccountGuid);
    },
  });
};

export default useChargeCardRepaymentInfoQueryOptions;
