import { FC } from "react";
import { CashBackInfoIconWithTooltip } from "resources/capital-accounts/constants";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import useChargeCardOffer from "resources/charge-cards/queries/useChargeCardOffer";
import OfferDetails from "ui/data-display/OfferDetails";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const CapitalAccountCardChargeCardCashBackOfferDetailsItem: FC<Props> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });

  return (
    <OfferDetails.Item
      label={
        <>
          Cashback
          <CashBackInfoIconWithTooltip />
        </>
      }
      value={`${chargeCardOffer.maxCashback.toFixed(1)}%`}
    />
  );
};

export default CapitalAccountCardChargeCardCashBackOfferDetailsItem;
