import useOpenBankAccounts from "resources/bank-accounts/queries/useOpenBankAccounts";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

const useShouldShowActivateThreadBanner = () => {
  const bankAccounts = useOpenBankAccounts();
  const hasThreadAccount = bankAccounts.some((account) => account.threadAccount);
  const isMigrateToThreadEnabled = useFeatureFlag("MIGRATE_TO_THREAD");

  return Boolean(isMigrateToThreadEnabled && !hasThreadAccount);
};

export default useShouldShowActivateThreadBanner;
