import { Note } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import colors from "styles/colors";
import IconTile from "ui/icons/IconTile";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./UpdatedLineOfCreditAgreementBanner.module.scss";

const UpdatedLineOfCreditAgreementBanner = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();

  return (
    <div className={styles.container}>
      <div className={styles.bannerBody}>
        <div className={styles.iconContainer}>
          <IconTile icon={<Note />} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <Text size={14} color={colors.grey[800]} weight="bold">
              Important changes to your line of credit
            </Text>
            <Text size={14} color={colors.grey[800]} weight="regular">
              To avoid any potential disruptions, kindly review and accept your updated line of
              credit terms.
            </Text>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Link to={`/capital/${capitalAccountSummary.guid}/review-updated-line-agreement`}>
          <Button variant="primary" fullWidth>
            <Text size={14} weight="medium">
              Review updates
            </Text>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default UpdatedLineOfCreditAgreementBanner;
