import { AtomEffect } from "recoil";

/**
 * https://recoiljs.org/docs/guides/atom-effects/#local-storage-persistence
 */
export const localStorageEffect: (key: string) => AtomEffect<string> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue) {
      setSelf(savedValue);
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, newValue);
      }
    });
  };
