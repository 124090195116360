import {
  Area,
  AreaChart as Chart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import colors from "styles/colors";

export type DetailedAreaChartDatum = {
  amount: number;
  date: string;
};

type Props = {
  data: DetailedAreaChartDatum[];
  ticks: string[];
  gradientFrom?: string;
  gradientTo?: string;
  strokeColor?: string;
  chartHeight?: number;
  setActiveTick?: (tick: DetailedAreaChartDatum | null) => void;
  isAnimationActive?: boolean;
};

const DetailedAreaChart: React.FC<Props> = ({
  data,
  ticks,
  gradientFrom,
  gradientTo,
  strokeColor,
  chartHeight,
  setActiveTick,
  isAnimationActive,
}) => (
  <ResponsiveContainer width="100%" height={chartHeight}>
    <Chart
      data={data}
      onMouseMove={(val: any) => setActiveTick?.(data[val.activeTooltipIndex])}
      onMouseLeave={() => setActiveTick?.(null)}
    >
      <defs>
        <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor={gradientFrom || colors.purple[300]} stopOpacity={0.13} />
          <stop offset="95%" stopColor={gradientTo || colors.purple[500]} stopOpacity={0} />
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        dataKey="amount"
        strokeWidth="1.5px"
        stroke={strokeColor || colors.purple[500]}
        fillOpacity={1}
        fill="url(#colorValue)"
        isAnimationActive={isAnimationActive}
      />
      <CartesianGrid strokeDasharray="4" vertical horizontal={false} stroke={colors.grey[200]} />
      <XAxis
        axisLine={{ stroke: colors.grey[200] }}
        tickLine={false}
        dataKey={"date"}
        interval="preserveStartEnd"
        ticks={ticks}
        tick={{ fontSize: 12 }}
        strokeDasharray="4"
        tickMargin={12}
        unit="      " // TODO: This is a hacky way of fixing the label font-size without being cut, explore a better solution
      />
      <Tooltip wrapperStyle={{ display: "none" }} />
    </Chart>
  </ResponsiveContainer>
);

export default DetailedAreaChart;
