import { useSuspenseQuery } from "@tanstack/react-query";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import { LoginRequiredParam } from "utils/react-query/require-data";

import useBusinessMemberQueryOptions from "./useBusinessMemberQueryOptions";

// NB(alex): Please note that this will return `null` when superusering.

const useBusinessMember = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  const userGuid = useUserGuid(params);
  const businessMemberQueryOptions = useBusinessMemberQueryOptions(userGuid ?? "");

  const { data } = useSuspenseQuery({
    queryKey: businessMemberQueryOptions.queryKey,
    queryFn: userGuid ? businessMemberQueryOptions.queryFn : () => null, // Just return `null` if there isn't a `userGuid`.
  });

  return data;
};

export default useBusinessMember;
