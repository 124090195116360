import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useMfa from "modules/mfa/useMfa";
import { useState } from "react";
import { useOpenBankAccountsQueryOptions } from "resources/bank-accounts/queries/useOpenBankAccounts";
import useRefreshCapitalAccountsQueries from "resources/capital-accounts/queries/useRefreshCapitalAccountsQueries";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";
import { v4 as uuidv4 } from "uuid";

type Data = {
  amountInCents: number;
  chargeCardCreditAccountGuid: string;
  transferFromBankAccountGuid: string;
};

const useCreateChargeCardsRepaymentMutation = () => {
  const { mfa } = useMfa();
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const refreshCapitalAccountsQueries = useRefreshCapitalAccountsQueries();
  const refreshOpenBankAccountsQuery = useRefreshQuery(useOpenBankAccountsQueryOptions().queryKey);

  // NB(alex): We should move this to `react-hook-form` once we get around to it. Context: https://highbeamco.slack.com/archives/C02M3GGQPHC/p1690914198052609?thread_ts=1690912209.739389&cid=C02M3GGQPHC
  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());

  return useMutation({
    mutationFn: async (data: Data) => {
      await mfa();
      return highbeamApi.chargeCardTransactions.createRepayment(
        businessGuid,
        data.chargeCardCreditAccountGuid,
        {
          amount: data.amountInCents,
          bankAccountGuid: data.transferFromBankAccountGuid,
          idempotencyKey: idempotencyKey,
        }
      );
    },
    onError: (error) => {
      notify("warning", "There was an issue making the payment. Please try again.");
      captureException(error);
    },
    onSuccess: async () => {
      await Promise.all([refreshCapitalAccountsQueries(), refreshOpenBankAccountsQuery()]);
      notify("success", "Repayment successful");
      setIdempotencyKey(uuidv4());
    },
  });
};

export default useCreateChargeCardsRepaymentMutation;
