import classNames from "classnames";
import { ComponentProps, FC } from "react";

import styles from "./Fieldset.module.scss";
import FieldsetLegend from "./FieldsetLegend";
import FieldsetRow from "./FieldsetRow";

type Props = ComponentProps<"fieldset">;

const Fieldset: FC<Props> = ({ children, className, ...fieldsetProps }) => {
  return (
    <fieldset className={classNames(className, styles.container)} {...fieldsetProps}>
      {children}
    </fieldset>
  );
};

export default Object.assign(Fieldset, {
  Legend: FieldsetLegend,
  Row: FieldsetRow,
});
