import { selector } from "recoil";
import { StepTypeV2 } from "ui/navigation/StepsV2";

// NB(alex): do not follow this pattern! This should be refactored once we develop a new multi-step form pattern.
const allStepsState = selector<StepTypeV2[]>({
  key: "capital/creditApplication/allStepsState",
  get: () => [
    {
      path: "/capital/application/connect-stores",
      title: "Stores",
    },
    {
      path: "/capital/application/connect-bank-accounts",
      title: "Bank accounts",
    },
    {
      path: "/capital/application/connect-credit-cards",
      title: "Credit cards",
    },
    {
      path: "/capital/application/upload-financial-documents",
      title: "Financials",
    },
    {
      path: "/capital/application/inventory",
      title: "Inventory",
    },
    {
      path: "/capital/application/business-info",
      title: "Business info",
    },
  ],
});

export default allStepsState;
