import {
  CreateBookPaymentRequest,
  CreateInlinePaymentRequest,
  CreateVerifiedPaymentRequest,
  CreateWirePaymentRequest,
} from "@highbeam/unit-node-sdk";
import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useMfa, { MfaCanceledError } from "modules/mfa/useMfa";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

import { Transfer } from "../TransferInfo/utils";
import { isConnectedAccountTransfer } from "../utils";

type Variables = {
  unitPayload:
    | CreateVerifiedPaymentRequest
    | CreateBookPaymentRequest
    | CreateInlinePaymentRequest
    | CreateWirePaymentRequest;
  transfer: Transfer;
};

type MutationProps = {
  onSuccess: () => void;
  onError: (transfer: Transfer) => void;
};

const useCreateTransferMutation = ({ onSuccess, onError }: MutationProps) => {
  const highbeamApi = useHighbeamApi();
  const { mfa } = useMfa();

  return useMutation({
    mutationFn: async ({ unitPayload }: Variables) => {
      await mfa();
      return highbeamApi.paymentV2.create({
        send: true,
        detail: {
          UnitTransfer: { unitPayload },
        },
      });
    },
    onError: (error, { transfer }) => {
      if (error instanceof MfaCanceledError) return;
      if (isConnectedAccountTransfer(transfer)) {
        notify(
          "error",
          "Your connected account is unable to process the transfer at this time. Please manually transfer the funds through an ACH."
        );
      } else {
        notify(
          "error",
          "Something went wrong while processing your transfer. We are looking into it."
        );
      }

      captureException(error);
      onError(transfer);
    },
    onSuccess: (payment, { transfer }) => {
      if (payment.status === "Failed") {
        notify(
          "error",
          payment.reason ? payment.reason : "Something went wrong while processing your transfer."
        );
        captureException(
          new Error(`Transfer money was rejected with payment data ${JSON.stringify(payment)}`)
        );
        onError(transfer);
      } else {
        onSuccess();
      }
    },
  });
};

export default useCreateTransferMutation;
