import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import { FC } from "react";
import { Navigate } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToNavigateToConnectedAccountingSofwarePage from "utils/permissions/navigation/useIsAllowedToNavigateToConnectedAccountingSofwarePage";

import AccountingSoftwareSection from "./AccountingSoftwareSection";

const SettingsAccountingSoftwarePage: FC = () => {
  const isAllowedToNavigateToConnectedAccountingSofwarePage =
    useIsAllowedToNavigateToConnectedAccountingSofwarePage();

  if (!isAllowedToNavigateToConnectedAccountingSofwarePage) {
    return <Navigate to="/settings" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/settings">Settings</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Accounting software</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.BackCaret to="/settings" />
          <DashboardPage.Header.Title>Accounting software</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <AccountingSoftwareSection />
      </DashboardPage>
    </>
  );
};

export default SettingsAccountingSoftwarePage;
