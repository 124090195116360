import { FC, ReactNode } from "react";

import useModalContext, { ModalContextValue } from "../context/useModalContext";

export type ModalFormOnSubmitFn = (
  e: React.FormEvent<HTMLFormElement>,
  context: ModalContextValue
) => void;

type Props = {
  children: ReactNode;
  onSubmit: ModalFormOnSubmitFn;
};

const ModalForm: FC<Props> = ({ children, onSubmit: onSubmitProp }) => {
  const modalContext = useModalContext();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await onSubmitProp(e, modalContext);
  };

  return (
    <form id={modalContext.formId} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default ModalForm;
