import React from "react";

import styles from "./Switch.module.scss";

// NB(alex): Do not follow the pattern in this component. It should use the underlying `input` component but it doesn't.

type Props = {
  checked?: boolean;
  onClick?: (evt: React.MouseEvent<HTMLInputElement>) => void; // NB(alex): Don't do this. Use `onChange` instead.
  disabled?: boolean;
};

const Switch: React.FC<Props> = ({ checked, onClick, disabled = false, ...props }) => (
  <div className={styles.switch} onClick={onClick}>
    <input type="checkbox" checked={checked} disabled={disabled} {...props} readOnly />
    <span className={styles.slider}></span>
  </div>
);

export default Switch;
