import { CalendarBlank } from "@phosphor-icons/react";
import CapitalAccountAutoPayConnectedStoresHighlightItem from "resources/capital-accounts/components/CapitalAccountAutoPayConnectedStoresHighlightItem";
import CapitalAccountInterestDueHighlightItem from "resources/capital-accounts/components/CapitalAccountInterestDueHighlightItem";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import getCapitalAccountRepaymentOptionType from "resources/capital-accounts/utils/getCapitalAccountRepaymentOptionType";
import lineOfCreditIcon from "resources/line-of-credit/assets/line-of-credit-icon.svg";
import HighlightCard from "ui/data-display/HighlightCard";
import ButtonArray from "ui/navigation/ButtonArray";

import LineOfCreditDrawdownButtonArrayButton from "../../../components/LineOfCreditDrawdownButtonArrayButton";
import LineOfCreditRepayButtonArrayButton from "../../../components/LineOfCreditRepayButtonArrayButton";

import styles from "./LineOfCreditHighlightCard.module.scss";
import LineOfCreditLineUsedHighlightItem from "./LineOfCreditLineUsedHighlightItem";

const LineOfCreditHighlightCard = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const isDrawdownEnabled = capitalAccountSummary.controls.drawdownEnabled;
  const hasOutstandingBalance = capitalAccountSummary.runningBalance !== 0;
  const isLastStatementWarning = !isDrawdownEnabled && hasOutstandingBalance;
  const repaymentOptionType = getCapitalAccountRepaymentOptionType(capitalAccountSummary);

  if (!isDrawdownEnabled && !hasOutstandingBalance) {
    return null;
  }

  return (
    <HighlightCard
      sideRuleColor={isLastStatementWarning ? "warning" : "default"}
      header={
        isLastStatementWarning ? (
          <HighlightCard.Header sideRuleColor="warning" icon={<CalendarBlank weight="light" />}>
            Overdue statement
          </HighlightCard.Header>
        ) : (
          <HighlightCard.Header
            icon={
              <img
                src={lineOfCreditIcon}
                // 28px looks better than 24px
                width={28}
                height={28}
                alt="Line of credit"
              />
            }
          >
            Cash access line
          </HighlightCard.Header>
        )
      }
      body={
        <>
          <LineOfCreditLineUsedHighlightItem
            lineUsedAmountInCents={0 - capitalAccountSummary.runningBalance}
            isLastStatementWarning={isLastStatementWarning}
          />
          {capitalAccountSummary.details.apr > 0 && (
            <CapitalAccountInterestDueHighlightItem
              capitalAccountSummary={capitalAccountSummary}
              infoIconColor="dark"
            />
          )}
          {repaymentOptionType === "PayoutPercentage" && (
            <CapitalAccountAutoPayConnectedStoresHighlightItem
              capitalAccountSummary={capitalAccountSummary}
              infoIconColor="dark"
            />
          )}
        </>
      }
      footer={
        <ButtonArray alignment="horizontal" className={styles.buttonArray}>
          <LineOfCreditRepayButtonArrayButton
            capitalAccountSummary={capitalAccountSummary}
            className={styles.buttonArrayButton}
          />

          {!isLastStatementWarning && (
            <LineOfCreditDrawdownButtonArrayButton
              capitalAccountSummary={capitalAccountSummary}
              className={styles.buttonArrayButton}
            />
          )}
        </ButtonArray>
      }
    />
  );
};

export default LineOfCreditHighlightCard;
