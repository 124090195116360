import questionFlowerImg from "assets/question-flower.svg";
import { useChatWidget } from "components/ChatWidget";
import colors from "styles/colors";
import AccentCard from "ui/data-display/AccentCard";
import Button from "ui/inputs/Button";
import Flexpane from "ui/overlay/Flexpane";
import Text from "ui/typography/Text";

import styles from "./CheckDepositFlexpaneContactSection.module.scss";

const CheckDepositFlexpaneContactSection = () => {
  const chat = useChatWidget();

  return (
    <Flexpane.Section>
      <AccentCard className={styles.container}>
        <div>
          <img src={questionFlowerImg} alt="?" className={styles.questionFlower} />
        </div>
        <div className={styles.text}>
          <Text size={14} weight="bold" color={colors.grey[900]}>
            Check stuck on processing for too long?
          </Text>
          <Text size={14} color={colors.grey[600]}>
            Check deposits take 6-10 business days to arrive on average. If it is taking too long,
            contact us.
          </Text>
        </div>
        <div>
          <Button variant="tertiary" onClick={() => chat.show()}>
            Contact support
          </Button>
        </div>
      </AccentCard>
    </Flexpane.Section>
  );
};

export default CheckDepositFlexpaneContactSection;
