import { useRecoilValue } from "recoil";
import useIsPwa from "utils/device/useIsPwa";
import { useIsTablet } from "utils/device/useMediaQuery";

import hasAddToHomeScreenBannerBeenDismissedState from "../state/hasAddToHomeScreenBannerBeenDismissedState";

const useShouldShowAddToHomeScreenBanner = () => {
  const isTablet = useIsTablet();
  const isPwa = useIsPwa();
  const hasAddToHomeScreenBannerBeenDismissed = useRecoilValue(
    hasAddToHomeScreenBannerBeenDismissedState
  );

  return isTablet && !isPwa && !hasAddToHomeScreenBannerBeenDismissed;
};

export default useShouldShowAddToHomeScreenBanner;
