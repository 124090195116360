import { initialSubcategory } from "components/SubcategoryDropdown";
import { accountTypeOptions } from "pages/SendMoneyPage";
import {
  getInternationalWireCountry,
  getInternationalWirePrefillInfo,
} from "pages/SendMoneyPage/internationalWires";
import InternationalPayeeBankingInfo from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/InternationalPayeeBankingInfo";
import InternationalPayeeEntity from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/InternationalPayeeEntity";
import InternationalPayeeLegalAddress from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/InternationalPayeeLegalAddress";
import PayeeBankingInfo from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/PayeeBankingInfo";
import PayeeLegalAddress from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/PayeeLegalAddress";
import PaymentCurrency from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/PaymentCurrency";
import { BankingInfo, WIRE_PAYMENT_OPTION, ACH_PAYMENT_OPTION } from "pages/SendMoneyPage/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState, RecoilValueReadOnly, useSetRecoilState } from "recoil";
import CategorizedResourceRep from "reps/Insights/CategorizedResourceRep";
import PayeeRep from "reps/PayeeRep";
import useCategorizedResource, {
  useRefreshCategorizedResourceQuery,
} from "resources/categorized-resources/queries/useCategorizedResource";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUpdatePayeeMutation from "resources/payees/mutations/useUpdatePayeeMutation";
import usePayee from "resources/payees/queries/usePayee";
import useTransactionSubcategoryOptions from "resources/transaction-subcategories/hooks/useTransactionSubcategoryOptions";
import { internationalAddressState } from "state/payments/international/address";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import { internationalEntityState } from "state/payments/international/entity";
import { isLocalPaymentState } from "state/payments/international/isLocalPayment";
import { isValidInternationalPaymentState } from "state/payments/international/isValidInternationalPayment";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import { selectedBankCountryOptionState } from "state/payments/international/selectedBankCountry";
import { adressState } from "state/payments/international/usAdress";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import CollapsibleFieldset from "ui/inputs/CollapsibleFieldset";
import CollapsibleFieldsetSet from "ui/inputs/CollapsibleFieldset/CollapsibleFieldsetSet";
import { Option, useDropdown } from "ui/inputs/Dropdown";
import Fieldset from "ui/inputs/Fieldset";
import TextInput from "ui/inputs/TextInput";
import Heading3 from "ui/typography/Heading3";
import Heading4 from "ui/typography/Heading4";
import { states, US_COUNTRY_OPTION } from "utils/address";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { EntityIndividual } from "utils/entity";

import PayeeCategorySection from "../CreatePayeePage/PayeeCategorySection";
import { useValidEmail, useValidName, useValidPhone } from "../PayeeValidation";

import styles from "./EditPayee.module.scss";

type Props = {
  payeeGuid: string;
};

const UpdatePayee: React.FC<Props> = ({ payeeGuid }) => {
  const payee = usePayee(payeeGuid);
  const [name, setName] = useState<string>();
  const { isNameValid, isShowingNameError, setIsShowingNameError } = useValidName(name);
  const [email, setEmail] = useState<string>();
  const { isEmailValid, isShowingEmailError, setIsShowingEmailError } = useValidEmail(email);
  const [phone, setPhone] = useState<string>();
  const { isPhonelValid, isShowingPhoneError, setIsShowingPhoneError } = useValidPhone(phone);
  const [entity, setEntity] = useRecoilState(internationalEntityState);
  const navigate = useNavigate();
  const businessGuid = useBusinessGuid();
  const subcategoryOptions = useTransactionSubcategoryOptions();
  const categorizedResource = useCategorizedResource(payeeGuid);
  const refreshCategorizedResourceQuery = useRefreshCategorizedResourceQuery(payeeGuid);

  const subcategoryState = useDropdown({
    initialValue: initialSubcategory(categorizedResource?.subcategoryGuid, subcategoryOptions),
  });
  const [selectedBankCountryOption, setSelectedBankCountryOption] = useRecoilState(
    selectedBankCountryOptionState
  );

  const [achBankingInfo, setAchBankingInfo] = useState<BankingInfo>({
    routingNumber: "",
    accountNumber: "",
    accountType: accountTypeOptions[0],
  });

  const [wireBankingInfo, setWireBankingInfo] = useState<BankingInfo>({
    routingNumber: "",
    accountNumber: "",
    accountType: accountTypeOptions[0],
  });

  const [address, setAddress] = useRecoilState(adressState);

  const [internationalAddress, setInternationalAddress] = useRecoilState(internationalAddressState);
  const [internationalBankingInfo, setInternationalBankingInfo] = useRecoilState(
    internationalBankingInfoState
  );
  const setInternationalQuoteCurrencyOption = useSetRecoilState(quoteCurrencyState);
  const currency = useRecoilValue(quoteCurrencyState);
  const isLocalPayment = useRecoilValue(isLocalPaymentState);
  const highbeamApi = useHighbeamApi();

  const { mutate: runUpdatePayeeMutation, isPending: isSubmitting } = useUpdatePayeeMutation({
    onSuccess: async () => {
      await createOrUpdateCategory();
      await refreshCategorizedResourceQuery();
      notify("success", "Payee edited");
      navigate(`/payments/payees/${payeeGuid}`);
    },
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
    },
  });

  useEffect(() => {
    const setPayeeData = (payee: PayeeRep.Complete) => {
      prefillPayeeInfo(payee);
      prefillFormWithPayeeAchInfo(payee);
      prefillFormWithPayeeWireInfo(payee);
      prefillFormWithPayeeInternationalInfo(payee);
    };
    if (payee) setPayeeData(payee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payee]);

  const prefillPayeeInfo = (payee: PayeeRep.Complete) => {
    if (payee.name) {
      setName(payee.name);
    }
    if (payee.emailAddress) {
      setEmail(payee.emailAddress);
    }
    if (payee.phone) {
      setPhone(payee.phone);
    }
  };

  const prefillFormWithPayeeAchInfo = (payee: PayeeRep.Complete) => {
    if (!payee.achTransferMethod) {
      return;
    }
    setAchBankingInfo({
      ...achBankingInfo,
      routingNumber: payee.achTransferMethod.routingNumber,
      accountNumber: payee.achTransferMethod.accountNumber,
    });
  };

  const prefillFormWithPayeeWireInfo = (payee: PayeeRep.Complete) => {
    if (!payee.wireTransferMethod || !payee.address) {
      return;
    }
    setWireBankingInfo({
      ...wireBankingInfo,
      routingNumber: payee.wireTransferMethod.routingNumber,
      accountNumber: payee.wireTransferMethod.accountNumber,
    });
    setAddress({
      addressLine1: {
        value: payee.address?.addressLine1,
        label: payee.address?.addressLine1,
      },
      addressLine2: payee.address.addressLine2 ?? "",
      city: payee.address.city,
      state: { label: states[payee.address.state], value: payee.address.state },
      zipCode: payee.address.zipCode,
      country: US_COUNTRY_OPTION,
    });
  };

  const prefillFormWithPayeeInternationalInfo = (payee: PayeeRep.Complete) => {
    const info = payee.internationalWireTransferMethod;
    if (!info) {
      return;
    }
    const { entity, address, bankingInfo, currency } = getInternationalWirePrefillInfo(info);
    setEntity(entity);
    setInternationalAddress(address);
    setInternationalBankingInfo(bankingInfo);
    setInternationalQuoteCurrencyOption(currency);
    if (info.bankCountry) {
      setSelectedBankCountryOption(getInternationalWireCountry(info.bankCountry));
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  const hasError = !(isEmailValid && isPhonelValid && isNameValid);

  const save = () => {
    const isValidEntity = Boolean(
      entity.entityType === EntityIndividual
        ? entity.firstName && entity.lastName
        : entity.companyName
    );

    const isValidAch =
      achBankingInfo.accountNumber && achBankingInfo.routingNumber && achBankingInfo.accountType;

    const isValidInternational = isValidEntity && isValidInternationalPaymentState;

    const isValidWire =
      address.addressLine1 &&
      address.city &&
      address.zipCode &&
      address.state &&
      wireBankingInfo.accountNumber &&
      wireBankingInfo.routingNumber &&
      wireBankingInfo.accountType;

    const isNextStepAllowed = isValidAch || isValidInternational || isValidWire;
    if (isNextStepAllowed) {
      updatePayee(isValidAch, isValidInternational, isValidWire);
    } else {
      notify("warning", "Please correct the errors above, then try again.");
    }
  };

  const createOrUpdateCategory = async (): Promise<CategorizedResourceRep.Complete | null> => {
    if (
      subcategoryState.value ===
      initialSubcategory(categorizedResource?.subcategoryGuid, subcategoryOptions)
    )
      return null;

    const subcategoryGuid = subcategoryState.value?.value;
    if (!subcategoryGuid) return null;

    return await highbeamApi.categorizedResource.set(businessGuid, {
      subcategoryGuid: subcategoryGuid,
      resourceGuid: payeeGuid,
      resourceType: "Payee",
    });
  };

  const updatePayee = async (
    isValidAch: string | Option | null,
    isValidInternational: boolean | RecoilValueReadOnly<boolean>,
    isValidWire: string | Option | null
  ) => {
    const achObject: PayeeRep.Update = isValidAch
      ? {
          achTransferMethod: {
            routingNumber: achBankingInfo.routingNumber,
            accountNumber: achBankingInfo.accountNumber,
          },
        }
      : {};
    // TODO Use selected country recoil state
    const country = getInternationalWireCountry(internationalAddress.country?.value ?? "");
    const paymentType = isLocalPayment ? country.local.deliveryMethod : "Priority";

    const internationalObject: PayeeRep.Update = isValidInternational
      ? {
          internationalWireTransferMethod: {
            type: paymentType,
            entity: {
              entityType: entity.entityType,
              firstName: entity.firstName,
              lastName: entity.lastName,
              companyName: entity.companyName,
              companyBankHolderName: entity.companyBankHolderName
                ? entity.companyBankHolderName
                : undefined,
            },
            address: {
              addressLine1: internationalAddress.addressLine1!.value,
              addressLine2: internationalAddress.addressLine2
                ? internationalAddress.addressLine2
                : undefined,
              city: internationalAddress.city,
              state: internationalAddress.state?.value ?? "",
              zipCode: internationalAddress.zipCode,
              country: internationalAddress.country?.value!,
            },
            bankCountry: selectedBankCountryOption?.value,
            accountNumber: internationalBankingInfo.accountNumber?.value,
            bankCode: internationalBankingInfo.bankCode?.value,
            branchCode: internationalBankingInfo.branchCode?.value,
            bsbCode: internationalBankingInfo.bsbCode?.value,
            clabe: internationalBankingInfo.clabe?.value,
            cnaps: internationalBankingInfo.cnaps?.value,
            iban: internationalBankingInfo.iban?.value,
            ifsc: internationalBankingInfo.ifsc?.value,
            sortCode: internationalBankingInfo.sortCode?.value,
            swift: internationalBankingInfo.swift?.value,
            currency: currency.value,
          },
        }
      : {};
    const wireObject: PayeeRep.Update = isValidWire
      ? {
          address: {
            addressLine1: address.addressLine1!.value,
            addressLine2: address.addressLine2 ? address.addressLine2 : undefined,
            city: address.city,
            state: address.state!.value,
            zipCode: address.zipCode,
            country: address.country?.value!,
          },
          wireTransferMethod: {
            routingNumber: wireBankingInfo.routingNumber,
            accountNumber: wireBankingInfo.accountNumber,
          },
        }
      : {};

    const requestObject: PayeeRep.Update = {
      name: name,
      emailAddress: email,
      phone: phone,
      ...achObject,
      ...wireObject,
      ...internationalObject,
    };

    if (payee) {
      const payeeGuid = payee?.guid;
      runUpdatePayeeMutation({ payeeGuid, ...requestObject });
    }
  };

  return (
    <>
      <CollapsibleFieldsetSet>
        <Fieldset width="narrow">
          <TextInput
            type="text"
            value={name}
            label="Name"
            className={styles.span2}
            maxLength={60}
            onChange={(name) => setName(name)}
            onFocus={() => {
              setIsShowingNameError(false);
            }}
            onBlur={() => {
              setIsShowingNameError(true);
            }}
            hasError={!isNameValid && isShowingNameError}
            errorMessage={"Payee name is required"}
          />

          <TextInput
            label="Email (optional)"
            className={styles.span2}
            type="text"
            value={email}
            onChange={(email) => (email === "" ? setEmail(undefined) : setEmail(email))}
            onFocus={() => {
              setIsShowingEmailError(false);
            }}
            onBlur={() => {
              setIsShowingEmailError(true);
            }}
            hasError={!isEmailValid && isShowingEmailError}
            errorMessage={"Invalid email address"}
          />
          <TextInput
            label="Phone number (optional)"
            className={styles.span2}
            type="text"
            value={phone}
            onChange={(phone) => (phone === "" ? setPhone(undefined) : setPhone(phone))}
            hasError={!isPhonelValid && isShowingPhoneError}
            onFocus={() => {
              setIsShowingPhoneError(false);
            }}
            onBlur={() => {
              setIsShowingPhoneError(true);
            }}
            errorMessage={"Invalid phone number"}
          />
        </Fieldset>
        {
          <PayeeCategorySection
            dropdownState={subcategoryState}
            sectionDescription="Highbeam will automatically categorize all existing and future transactions related to
          this payee with the subcategory selected below."
          />
        }
        <Heading3>Payment info</Heading3>
        <CollapsibleFieldset heading={<Heading4>ACH</Heading4>}>
          <div className={styles.span2}>
            <PayeeBankingInfo
              bankingInfo={achBankingInfo}
              setBankingInfo={setAchBankingInfo}
              paymentMethod={ACH_PAYMENT_OPTION}
              accountTypeOptions={accountTypeOptions}
              className={styles.bakinginfoConatiner}
            />
          </div>
        </CollapsibleFieldset>
        <CollapsibleFieldset heading={<Heading4>Wire</Heading4>}>
          <div className={styles.span2}>
            <PayeeBankingInfo
              bankingInfo={wireBankingInfo}
              setBankingInfo={setWireBankingInfo}
              paymentMethod={WIRE_PAYMENT_OPTION}
              accountTypeOptions={accountTypeOptions}
              className={styles.bakinginfoConatiner}
            />
            <PayeeLegalAddress address={address} setAddress={setAddress} />
          </div>
        </CollapsibleFieldset>
        <CollapsibleFieldset heading={<Heading4>International Wire</Heading4>}>
          <div className={styles.span2}>
            <div className={styles.span2}>
              <PaymentCurrency />
            </div>
            <div className={styles.span2}>
              <InternationalPayeeEntity />
            </div>
            <div className={styles.span2}>
              <InternationalPayeeLegalAddress />
            </div>
            <div className={styles.span2}>
              {internationalAddress?.country?.value &&
                internationalAddress?.country?.value !== "" && <InternationalPayeeBankingInfo />}
            </div>
          </div>
        </CollapsibleFieldset>
      </CollapsibleFieldsetSet>
      <div className={styles.actionsContainer}>
        <Button variant="tertiary" onClick={cancel} disabled={isSubmitting}>
          Cancel
        </Button>

        <Button variant="primary" onClick={save} disabled={isSubmitting || hasError}>
          Save
        </Button>
      </div>
    </>
  );
};

export default UpdatePayee;
