import { Warning } from "@phosphor-icons/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import chargeCardCalendar from "assets/charge-card-calendar.svg";
import { FC, useState } from "react";
import { useRecoilState } from "recoil";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { useOpenBankAccountsQueryOptions } from "resources/bank-accounts/queries/useOpenBankAccounts";
import mapTransferOption from "resources/bank-accounts/utils/mapTransferOption";
import useChargeCardAccount from "resources/charge-cards/queries/useChargeCardAccount";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Dropdown from "ui/inputs/Dropdown";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";

import useUpdateRepaymentAccountMutation from "./hooks/useUpdateRepaymentAccountMutation";
import styles from "./isUpdateRepaymentAccountModalOpen.module.scss";
import isUpdateRepaymentAccountModalOpenState from "./states/isUpdateRepaymentAccountModalOpen";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const UpdateRepaymentAccountModal: FC<Props> = ({ capitalAccountSummary }) => {
  const [isOpen, setIsOpen] = useRecoilState(isUpdateRepaymentAccountModalOpenState);
  const chargeCardAccount = useChargeCardAccount(capitalAccountSummary.guid, { required: true });

  const { data: accountOptions } = useSuspenseQuery({
    ...useOpenBankAccountsQueryOptions(),
    select: (bankAccounts) => bankAccounts.map(mapTransferOption),
  });

  const [selectedAccount, setSelectedAccount] = useState(
    accountOptions.find((option) => {
      return option.guid === chargeCardAccount.repaymentBankAccountGuid;
    }) ?? accountOptions[0]
  );
  const { mutateAsync: updateRepaymentAccount, isPending } = useUpdateRepaymentAccountMutation(
    capitalAccountSummary.guid
  );

  if (!isOpen) {
    return null;
  }

  const chargeCardBalance =
    0 - (capitalAccountSummary.cardBalance + capitalAccountSummary.cardPending);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <ModalV4 onClose={handleClose} dropdownOverflowHack>
      <ModalV4.Form
        onSubmit={async (e, { onClose }) => {
          e.preventDefault();
          await updateRepaymentAccount({
            chargeCardAccountGuid: chargeCardAccount.guid,
            repaymentAccountGuid: selectedAccount.guid,
          });
          setIsOpen(false);
          onClose();
        }}
      >
        <ModalV4.Header>Change auto-pay account</ModalV4.Header>

        <ModalV4.Body>
          <div className={styles.contentContainer}>
            <div>
              <img src={chargeCardCalendar} alt="" />
            </div>
            <div>
              <Text size={12} weight="regular">
                Current card balance
              </Text>
              <Text size={16} weight="medium" numeric>
                <MoneyAmount cents={chargeCardBalance} withCents />
              </Text>
            </div>
            <Text size={14} weight="regular" color={colors.grey[500]}>
              Highbeam will auto debit from this account on each repayment date. If the account
              balance is insufficient, we’ll draw down from your line of credit to cover the full
              amount.
            </Text>
            <div>
              <Dropdown
                label="Auto-pay account"
                onChange={(option) => {
                  setSelectedAccount(option);
                }}
                value={selectedAccount}
                options={accountOptions}
              />
              <div className={styles.balanceContainer}>
                {selectedAccount.availableBalanceInCents < chargeCardBalance ? (
                  <Text size={14} weight="regular" color={colors.grey[900]}>
                    <Warning className={styles.warningIcon} />
                    Account balance of{" "}
                    <MoneyAmount cents={selectedAccount.availableBalanceInCents} withCents /> is
                    less than the current card balance.
                  </Text>
                ) : (
                  <Text size={14} weight="regular" color={colors.grey[900]}>
                    Account balance:{" "}
                    <MoneyAmount cents={selectedAccount.availableBalanceInCents} withCents />
                  </Text>
                )}
              </div>
            </div>
          </div>
        </ModalV4.Body>
        <ModalV4.Footer>
          <ModalV4.Footer.SubmitButton
            variant="secondary"
            disabled={selectedAccount.guid === chargeCardAccount.repaymentBankAccountGuid}
            isLoading={isPending}
          >
            Save changes
          </ModalV4.Footer.SubmitButton>

          <ModalV4.Footer.CloseButton variant="ghost">Cancel</ModalV4.Footer.CloseButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </ModalV4>
  );
};

export default UpdateRepaymentAccountModal;
