import IconWithTooltip from "components/common/IconWithTooltip";
import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import MetadataList from "components/MetadataList";
import { SwiftFeeTooltipContent } from "resources/international-wires/constants";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Flexpane from "ui/overlay/Flexpane";
import Text from "ui/typography/Text";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";
import { HighbeamInternationalWireTransaction } from "utils/types/transactionsTypes";

import styles from "./InternationalTransactionInfoFlexpaneAmountSection.module.scss";

type Props = {
  transaction: HighbeamInternationalWireTransaction;
};

const UsdAmount: React.FC<Props> = ({ transaction }) => {
  const fee = getDollarsFromCents(transaction.internationalWireFeeInCents ?? 0);
  const transferredAmount = getDollarsFromCents(transaction.amountInCents) - fee;
  return (
    <MetadataList>
      <MetadataList.Item label="Transferred to payee">
        <Text size={18} weight="bold" numeric align="right">
          {formatAmount(transferredAmount, {
            withCents: true,
            currency: transaction.receivedCurrency,
            trailingCurrencyCode: true,
          })}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item
        label={
          <Text size={14} className={styles.swiftFeeInfoTitle}>
            SWIFT fee <IconWithTooltip color="light" content={<SwiftFeeTooltipContent />} />
          </Text>
        }
        hideBorder
      >
        <Text size={14} weight="medium" numeric align="right">
          {formatAmount(fee, { trailingCurrencyCode: true })}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item label="Total" boldLabel>
        <TransactionFlexpaneAmount
          cents={transaction.amountInCents}
          direction={transaction.direction === "Credit" ? "positive" : "negative"}
          currency={"USD"}
          trailingCurrencyCode
        />
      </MetadataList.Item>
    </MetadataList>
  );
};

const LocalAmount: React.FC<Props> = ({ transaction }) => {
  const fee = transaction.internationalWireFeeInCents ?? 0;
  const transferedAmountInCents = transaction.receivedAmountInCents - fee;
  const totalAmountInCents = transaction.amountInCents;
  const buyRate = (transferedAmountInCents / totalAmountInCents).toFixed(5);
  return (
    <MetadataList>
      <MetadataList.Item label="You sent">
        <MoneyAmount
          weight="medium"
          cents={totalAmountInCents}
          direction={transaction.direction === "Credit" ? "positive" : "negative"}
          currency={"USD"}
          trailingCurrencyCode
        />
      </MetadataList.Item>

      <MetadataList.Item label="Exchange rate" hideBorder>
        <Text size={14} weight="medium" numeric align="right">
          1 USD = {buyRate} {transaction.receivedCurrency}
        </Text>
      </MetadataList.Item>

      <MetadataList.Item label="Transferred to payee" boldLabel>
        <TransactionFlexpaneAmount
          cents={transferedAmountInCents}
          direction="none"
          currency={transaction.receivedCurrency}
          trailingCurrencyCode
        />
      </MetadataList.Item>
    </MetadataList>
  );
};

const InternationalTransactionInfoFlexpaneAmountSection: React.FC<Props> = ({ transaction }) => {
  const isLocalCurrency = transaction.receivedCurrency !== "USD";
  return (
    <Flexpane.Section>
      {isLocalCurrency ? (
        <LocalAmount transaction={transaction} />
      ) : (
        <UsdAmount transaction={transaction} />
      )}
    </Flexpane.Section>
  );
};

export default InternationalTransactionInfoFlexpaneAmountSection;
