import { PlusCircle } from "@phosphor-icons/react";
import emptyExternalCards from "assets/empty-external-cards.svg";
import { getIcon, getInstitutionNameFromId } from "components/Accounts/AccountLabel";
import DashboardPage from "components/layouts/DashboardPage";
import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import useHasPlaidConnections from "resources/plaid-connections/queries/useHasPlaidConnections";
import usePlaidCreditConnections from "resources/plaid-connections/queries/usePlaidCreditConnections";
import usePlaidLinkToken from "resources/plaid-connections/queries/usePlaidLinkToken";
import useRefreshPlaidConnectionsQuery from "resources/plaid-connections/queries/useRefreshPlaidConnectionsQuery";
import EmptyCardsList from "ui/data-display/EmptyCardsList";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useHighbeamPlaidLink from "utils/customHooks/useHighbeamPlaidLink";
import useIsAllowedToConnectCards from "utils/permissions/useIsAllowedToConnectCards";

import PlaidConnectionCard from "../SettingsPage/PlaidConnectionCard";

import styles from "./SettingsFinancialPartnersPage.module.scss";

type Props = {
  setAddConnectionModalVariant: (variant: PlaidLinkTokenRep.AccountType) => void;
};

const EmptyExternalCardsState: React.FC<Props> = ({ setAddConnectionModalVariant }) => {
  const isAllowedToConnectCards = useIsAllowedToConnectCards();
  const hasPlaidConnections = useHasPlaidConnections();
  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQuery();

  const { openPlaid } = useHighbeamPlaidLink({
    linkToken: usePlaidLinkToken({
      accountType: "CREDIT",
      allowAccountSelectionOnUpdate: false,
    }),
    onComplete: () => {
      refreshPlaidConnectionsQuery();
    },
  });

  return (
    <EmptyCardsList>
      <img src={emptyExternalCards} alt="No connected cards" />
      <Button
        disabled={!isAllowedToConnectCards}
        onClick={() => {
          if (!hasPlaidConnections) {
            openPlaid();
          } else {
            setAddConnectionModalVariant("CREDIT");
          }
        }}
        variant="secondary"
        tooltip={
          !isAllowedToConnectCards && (
            <Text size={14}>You don’t have permission to connect cards.</Text>
          )
        }
      >
        <PlusCircle />
        Connect a card
      </Button>
    </EmptyCardsList>
  );
};

const ExternalCardsSection: React.FC<Props> = ({ setAddConnectionModalVariant }) => {
  const creditConnections = usePlaidCreditConnections();
  const isAllowedToConnectCards = useIsAllowedToConnectCards();
  const hasPlaidConnections = useHasPlaidConnections();
  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQuery();

  const { openPlaid } = useHighbeamPlaidLink({
    linkToken: usePlaidLinkToken({
      accountType: "CREDIT",
      allowAccountSelectionOnUpdate: false,
    }),
    onComplete: () => {
      refreshPlaidConnectionsQuery();
    },
  });

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header
        heading="Connected cards"
        subheading="Connect your cards to see additional insights about transactions and expenses."
        actions={
          creditConnections.length > 0 && (
            <Button
              disabled={!isAllowedToConnectCards}
              onClick={() => {
                if (!hasPlaidConnections) {
                  openPlaid();
                } else {
                  setAddConnectionModalVariant("CREDIT");
                }
              }}
              variant="secondary"
              tooltip={
                !isAllowedToConnectCards && (
                  <Text size={14}>You don’t have permission to connect cards.</Text>
                )
              }
            >
              <PlusCircle />
              Add new connection
            </Button>
          )
        }
      />

      <DashboardPage.Section.Body>
        {creditConnections.length === 0 && (
          <EmptyExternalCardsState setAddConnectionModalVariant={setAddConnectionModalVariant} />
        )}

        <div className={styles.integrationsWrapper}>
          {creditConnections.map((connection) => (
            <PlaidConnectionCard
              key={connection.guid}
              name={
                connection.institutionName ??
                getInstitutionNameFromId(connection.accounts[0].institutionId, "bank")
              }
              connectionGuid={connection.guid}
              isActive={connection.isActive}
              logo={getIcon(connection.accounts[0].institutionId)}
              accounts={connection.accounts}
              accountType="CREDIT"
            />
          ))}
        </div>
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default ExternalCardsSection;
