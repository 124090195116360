import { ChartBar, CheckCircle, Icon, IconWeight, Info, Warning } from "@phosphor-icons/react";
import colors from "styles/colors";

export type InfoIconErrorVariant = "error" | "chartError";

export type InfoIconVariant = "info" | "success" | "warning" | InfoIconErrorVariant;

const getIcon = (variant: InfoIconVariant): Icon => {
  switch (variant) {
    case "info":
      return Info;
    case "success":
      return CheckCircle;
    case "warning":
      return Warning;
    case "error":
      return Warning;
    case "chartError":
      return ChartBar;
  }
};

const getIconColor = (variant: InfoIconVariant): string => {
  switch (variant) {
    case "info":
      return colors.purple[500];
    case "success":
      return colors.green[400];
    case "warning":
      return "#eba015"; // TODO(alex): yellow-???
    case "error":
      return colors.red[500];
    case "chartError":
      return colors.red[500];
  }
};

type Props = {
  variant: InfoIconVariant;
  size?: number;
  className?: string;
  color?: string;
  weight?: IconWeight;
};

const InfoIcon: React.FC<Props> = ({ variant, size = 20, weight }) => {
  const IconComponent = getIcon(variant);
  return <IconComponent color={getIconColor(variant)} size={size} weight={weight} />;
};

export default InfoIcon;
