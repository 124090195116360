import CheckDepositFlexpane from "dialogs/CheckDepositFlexpane";
import PaymentInfoFlexpane from "dialogs/PaymentInfoFlexpane";
import useIncomingPayments from "modules/incoming-payments/queries/useIncomingPayments";
import { IncomingPayment } from "modules/incoming-payments/types";
import { FC, useState } from "react";
import useAllBankAccountsByUnitCoDepositAccountId from "resources/bank-accounts/queries/useAllBankAccountsByUnitCoDepositAccountId";
import getPaymentMethodName from "resources/unit-co-payments/utils/getPaymentMethodName";
import BankAccount from "ui/data-display/BankAccount";
import AmountCell from "ui/table/AmountCell";
import CounterpartyCell from "ui/table/CounterpartyCell";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import TextCell from "ui/table/TextCell";
import { getAccountDisplayName } from "utils/account";
import { formatBankingDate } from "utils/date";
import { getPaymentCounterparty } from "utils/payments";

import IncomingPaymentStatusPill from "../IncomingPaymentStatusPill";

import styles from "./IncomingPaymentsTable.module.scss";

const PaymentsTableCounterpartyCell = ({ payment }: { payment: IncomingPayment }) => {
  const bankAccountsByUnitCoDepositAccountId = useAllBankAccountsByUnitCoDepositAccountId();
  const counterparty = getPaymentCounterparty(payment, bankAccountsByUnitCoDepositAccountId);

  return (
    <div className={styles.toFromStatus}>
      <CounterpartyCell>{counterparty.formattedName ?? counterparty.name}</CounterpartyCell>
      <IncomingPaymentStatusPill payment={payment} className={styles.pill} />
    </div>
  );
};

const PaymentsTableBankAccountCell = ({ payment }: { payment: IncomingPayment }) => {
  const bankAccountsByUnitCoDepositAccountId = useAllBankAccountsByUnitCoDepositAccountId();
  const account = bankAccountsByUnitCoDepositAccountId.get(payment.relationships.account.data.id);
  const accountName = account ? getAccountDisplayName(account) : "";

  return (
    <BankAccount
      accountName={accountName}
      shortMethodName={
        payment.type === "checkDeposit" ? "Check" : getPaymentMethodName(payment).shortMethodName
      }
    />
  );
};

const columns: Column<IncomingPayment>[] = [
  {
    title: "Est. date",
    key: "formattedEstimatedDate",
    cellRender: (payment) => {
      if ("settlementDate" in payment.attributes && payment.attributes.settlementDate) {
        return <TextCell>{formatBankingDate(payment.attributes.settlementDate)}</TextCell>;
      } else {
        return <NotFoundCell />;
      }
    },
    width: 120,
  },
  {
    title: "From",
    cellRender: (payment) => {
      return <PaymentsTableCounterpartyCell payment={payment} />;
    },
  },
  {
    title: "Account",
    cellTextWeight: "medium",
    key: "accountName",
    cellRender: (payment) => {
      return <PaymentsTableBankAccountCell payment={payment} />;
    },
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: (payment) => {
      if (payment.type === "checkDeposit") {
        return <AmountCell cents={payment.attributes.amount} direction="positive" />;
      } else {
        return (
          <AmountCell
            cents={payment.attributes.amount}
            direction={payment.attributes.direction === "Debit" ? "positive" : "negative"}
          />
        );
      }
    },
  },
];

type Props = {
  accountId?: string;
};

const IncomingPaymentsTable: FC<Props> = ({ accountId }) => {
  const incomingPayments = useIncomingPayments({ accountId });
  const [selectedPayment, setSelectedPayment] = useState<IncomingPayment>();

  return (
    <>
      <PaymentInfoFlexpane
        paymentId={
          selectedPayment && selectedPayment.type !== "checkDeposit"
            ? selectedPayment.id
            : undefined
        }
        onClose={() => setSelectedPayment(undefined)}
      />

      <CheckDepositFlexpane
        onClose={() => setSelectedPayment(undefined)}
        checkDepositId={
          selectedPayment && selectedPayment.type === "checkDeposit"
            ? selectedPayment.id
            : undefined
        }
      />

      <Table
        columns={columns}
        data={incomingPayments}
        rowKey={(payment) => payment.id}
        cellClassName={styles.cell}
        onRowClick={(payment) => {
          setSelectedPayment(payment);
        }}
      />
    </>
  );
};

export default IncomingPaymentsTable;
