import AccountLabel from "components/Accounts/AccountLabel";
import MultiStep from "components/layouts/MultiStep";
import { useNavigate } from "react-router-dom";
import CapitalAccountDetailsRep from "reps/CapitalAccountDetailsRep";
import CapitalAccountRep from "reps/CapitalAccountRep";
import useOpenBankAccount from "resources/bank-accounts/queries/useOpenBankAccount";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";
import CapitalAccountIcon from "resources/capital-accounts/components/CapitalAccountIcon";
import CapitalPromotionalAprLabel from "resources/capital-accounts/components/CapitalPromotionalAprLabel";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import useChargeCardAccount from "resources/charge-cards/queries/useChargeCardAccount";
import useAcceptCreditOfferMutation from "resources/line-of-credit/mutations/useAcceptCreditOfferMutation";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import ReviewLineOfferBackButton from "../components/ReviewLineOfferBackButton";

import styles from "./ReviewLineOfferViews.module.scss";

const getCapitalAccountRepaymentOptionTypeLabel = (
  option: CapitalAccountDetailsRep.CapitalRepaymentOption
) => {
  switch (option.type) {
    case CapitalAccountDetailsRep.RepaymentType.PayoutPercentage:
      return "Payout percentage";
    default:
      return "Daily installments";
  }
};

const REVIEW_FORM_ID = "review-form";

type Props = {
  label: React.ReactNode;
  value: React.ReactNode;
};

const ReviewAndAcceptContainerRow: React.FC<Props> = ({ label, value }) => (
  <>
    <div className={styles.reviewAndAcceptItem}>{label}</div>
    <div className={styles.reviewAndAcceptItem}>{value}</div>
  </>
);

const CashAccessReviewCardContent = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const chargeCardAccount = useChargeCardAccount(capitalAccountSummary.guid);
  const primaryAccount = usePrimaryBankAccount({ required: true });
  const repaymentAccount = useOpenBankAccount(
    chargeCardAccount?.repaymentBankAccountGuid ?? primaryAccount.guid,
    { required: true }
  );
  const targetRepaymentDays = capitalAccountSummary.details.targetRepaymentDays;
  const isCapitalPromotionalRateEnabled = useFeatureFlag("CAPITAL_PROMOTIONAL_RATES");

  return (
    <div className={styles.multistepContainer}>
      <div className={styles.reviewAndAcceptCard}>
        <div className={styles.reviewAndAcceptSubtext}>
          <div className={styles.subtextItem}>
            <CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} />
            <Text size={16} color={colors.grey[900]} weight="medium">
              {capitalAccountSummary.name}
            </Text>
          </div>
        </div>
        <Divider margin={0} />

        <div className={styles.reviewAndAcceptContainer}>
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Starting limit
              </Text>
            }
            value={
              <MoneyAmount
                cents={capitalAccountSummary.details.limit}
                currency={"USD"}
                weight="medium"
                withCents={capitalAccountSummary.details.limit % 100 === 0}
              />
            }
          />
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Repayment schedule
              </Text>
            }
            value={
              <Text size={14} color={colors.grey[800]} weight="medium">
                {targetRepaymentDays} days
              </Text>
            }
          />
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Auto-pay type
              </Text>
            }
            value={
              <Text size={14} color={colors.grey[800]} weight="medium">
                {getCapitalAccountRepaymentOptionTypeLabel(
                  capitalAccountSummary.details.repayment.option
                )}
              </Text>
            }
          />
          {capitalAccountSummary.details.apr > 0 && (
            <>
              {isCapitalPromotionalRateEnabled && (
                <ReviewAndAcceptContainerRow
                  label={<CapitalPromotionalAprLabel weight="regular" color={colors.grey[500]} />}
                  value={
                    <div className={styles.promotionalAprDetails}>
                      <Text size={14} weight="medium">
                        10.00%
                      </Text>
                      <Text size={14} color={colors.grey[600]} weight="medium" lineThrough>
                        {`${capitalAccountSummary.details.apr * 100}%`}
                      </Text>
                    </div>
                  }
                />
              )}
            </>
          )}
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Auto-pay account
              </Text>
            }
            value={<AccountLabel bankAccount={repaymentAccount} />}
          />
        </div>
      </div>
    </div>
  );
};

const ChargeCardReviewCardContent = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const chargeCardAccount = useChargeCardAccount(capitalAccountSummary.guid);
  const primaryAccount = usePrimaryBankAccount({ required: true });
  const repaymentAccount = useOpenBankAccount(
    chargeCardAccount?.repaymentBankAccountGuid ?? primaryAccount.guid,
    { required: true }
  );
  const targetRepaymentDays = capitalAccountSummary.details.targetRepaymentDays;

  return (
    <div className={styles.multistepContainer}>
      <div className={styles.reviewAndAcceptCard}>
        <div className={styles.reviewAndAcceptSubtext}>
          <div className={styles.subtextItem}>
            <CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} />
            <Text size={16} color={colors.grey[900]} weight="medium">
              {capitalAccountSummary.name}
            </Text>
          </div>
        </div>
        <Divider margin={0} />

        <div className={styles.reviewAndAcceptContainer}>
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Limit
              </Text>
            }
            value={
              <MoneyAmount
                cents={capitalAccountSummary.details.limit}
                currency={"USD"}
                weight="medium"
                withCents={capitalAccountSummary.details.limit % 100 === 0}
              />
            }
          />
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Repayment period
              </Text>
            }
            value={
              <Text size={14} color={colors.grey[800]} weight="medium">
                Up to {targetRepaymentDays} days
              </Text>
            }
          />
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Late fee APR
              </Text>
            }
            value={
              <Text size={14} weight="medium">{`${capitalAccountSummary.details.apr * 100}%`}</Text>
            }
          />
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Auto-pay account
              </Text>
            }
            value={<AccountLabel bankAccount={repaymentAccount} />}
          />
        </div>
      </div>
    </div>
  );
};

const ReviewView = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const navigate = useNavigate();
  const { mutateAsync: acceptCreditOffer, isPending: isAcceptCreditOfferLoading } =
    useAcceptCreditOfferMutation();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await acceptCreditOffer();
    navigate(`/capital/${capitalAccountSummary.guid}/review-line-offer/offer-accepted`);
  };

  return (
    <MultiStep.Form id={REVIEW_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Review and accept</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>
      </MultiStep.Section>

      <MultiStep.Section>
        {capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly ? (
          <ChargeCardReviewCardContent />
        ) : (
          <CashAccessReviewCardContent />
        )}
      </MultiStep.Section>

      <MultiStep.Controls>
        <ReviewLineOfferBackButton />
        <MultiStep.Controls.NextButton form={REVIEW_FORM_ID} isLoading={isAcceptCreditOfferLoading}>
          Accept offer
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default ReviewView;
