import { Archive } from "@phosphor-icons/react";
import DashboardPage from "components/layouts/DashboardPage";

const ArchivedCardsPageHeader = () => {
  return (
    <DashboardPage.Header>
      <DashboardPage.Header.BackCaret to="/cards" />
      <DashboardPage.Header.IconTile icon={<Archive />} />
      <DashboardPage.Header.Title>Archived cards</DashboardPage.Header.Title>
    </DashboardPage.Header>
  );
};

export default ArchivedCardsPageHeader;
