import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFormContext } from "react-hook-form";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import { HighbeamBankAccountTransferOption } from "utils/transfers";
import { z } from "zod";

const schema = z.object({
  amount: z.number().positive(),
  // NB(alex): Once we have a composable `Dropdown`, we should use `repaymentBankAccount: z.custom<BankAccountRep.Complete>` here.
  repaymentBankAccountTransferOption: z.custom<HighbeamBankAccountTransferOption>(
    (value) => Boolean(value),
    "Please select a bank account."
  ),
});

export type RepayLineOfCreditFormInputs = z.infer<typeof schema>;

const useRepayLineOfCreditForm = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();

  return useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: 0 - capitalAccountSummary.runningBalance,
    },
  });
};

export default useRepayLineOfCreditForm;

export const useRepayLineOfCreditFormContext = () => {
  return useFormContext<RepayLineOfCreditFormInputs>();
};
