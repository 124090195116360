import useBankAccountByUnitCoDepositAccountId from "resources/bank-accounts/queries/useBankAccountByUnitCoDepositAccountId";
import Button from "ui/inputs/Button";
import FlexpaneHeader from "ui/overlay/Flexpane/FlexpaneHeader";
import Text from "ui/typography/Text";
import useIsAllowedToCancelPayment from "utils/permissions/useIsAllowedToCancelPayment";
import { HighbeamOriginatedAchTransaction } from "utils/types/transactionsTypes";

import { useTransactionInfoFlexpaneContext } from "../context/TransactionInfoFlexpaneProvider";

import CancelPaymentModal from "./CancelPaymentModal";
import { useCancelPaymentModal } from "./CancelPaymentModal/state/cancelPaymentModalState";

type Props = {
  transaction: HighbeamOriginatedAchTransaction;
};

const OriginatedAchTransactionFlexpaneHeader: React.FC<Props> = ({ transaction }) => {
  const { refreshTransactions, onClose } = useTransactionInfoFlexpaneContext();

  const isAllowedToCancelPayment = useIsAllowedToCancelPayment();
  const { open: openCancelPaymentModal } = useCancelPaymentModal();
  const bankAccount = useBankAccountByUnitCoDepositAccountId(transaction.accountId, {
    required: true,
  });

  const canCancelPayment =
    transaction.paymentStatus === "Pending" || transaction.paymentStatus === "PendingReview";

  return (
    <>
      <CancelPaymentModal
        onSuccess={() => {
          refreshTransactions && refreshTransactions();
          onClose();
        }}
        paymentDirection={"Outgoing"}
      />
      <FlexpaneHeader
        onClose={onClose}
        right={
          canCancelPayment && transaction.paymentGuid !== undefined ? (
            <Button
              variant="danger"
              onClick={() => {
                openCancelPaymentModal({
                  account: bankAccount,
                  counterpartyName: transaction.counterpartyName,
                  paymentAmountInCents: transaction.amountInCents,
                  paymentGuid: transaction.paymentGuid!,
                });
              }}
              disabled={!isAllowedToCancelPayment}
              tooltip={
                !isAllowedToCancelPayment && (
                  <Text size={14}>You don’t have permission to cancel payments.</Text>
                )
              }
            >
              Cancel transaction
            </Button>
          ) : null
        }
      >
        Transaction info
      </FlexpaneHeader>
    </>
  );
};

export default OriginatedAchTransactionFlexpaneHeader;
