import { useSuspenseQuery } from "@tanstack/react-query";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import useBankAccountsQueryOptions from "./useBankAccountsQueryOptions";

const useBankAccountByUnitCoDepositAccountId = <TRequired extends boolean = false>(
  unitCoDepositAccountId: string,
  params?: RequiredParam<TRequired>
) => {
  const { data } = useSuspenseQuery({
    ...useBankAccountsQueryOptions(),
    select: (bankAccounts) =>
      bankAccounts.find(
        (bankAccount) => bankAccount.unitCoDepositAccountId === unitCoDepositAccountId
      ),
  });

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useBankAccountByUnitCoDepositAccountId;
