import { Statement, StatementsListParams } from "@highbeam/unit-node-sdk";
import useTemporaryFirstCapitalAccountWithChargeCard from "resources/capital-accounts/queries/useTemporaryFirstCapitalAccountWithChargeCard";
import useChargeCardAccount from "resources/charge-cards/queries/useChargeCardAccount";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const UNIT_CO_STATEMENTS_QUERY_KEY = "unitStatements";

const DEFAULT_STATEMENTS_PARAMS: Partial<StatementsListParams> = {
  sort: "-period",
};

const useUnitCoStatementsQueryOptions = (params?: StatementsListParams) => {
  const unitApi = useUnitApi();
  const firstCapitalAccountWithChargeCard = useTemporaryFirstCapitalAccountWithChargeCard();
  const chargeCardAccount = useChargeCardAccount(firstCapitalAccountWithChargeCard?.guid);

  return useQueryOptions({
    queryKey: [UNIT_CO_STATEMENTS_QUERY_KEY, params],
    queryFn: async () => {
      const { data } = await unitApi.statements.list({
        ...DEFAULT_STATEMENTS_PARAMS,
        ...params,
      });

      return data.filter((statement: Statement) => {
        return statement.relationships.account.data.id !== chargeCardAccount?.unitCoCreditAccountId;
      });
    },
  });
};

export default useUnitCoStatementsQueryOptions;
