import Flexpane from "ui/overlay/Flexpane";

import PaymentInfoFlexpaneAmountSection from "./components/PaymentInfoFlexpaneAmountSection";
import PaymentInfoFlexpaneHeaderSection from "./components/PaymentInfoFlexpaneHeaderSection";
import PaymentInfoFlexpaneToFromSection from "./components/PaymentInfoFlexpaneToFromSection";
import PaymentInfoFlexpaneProvider from "./context/PaymentInfoFlexpaneProvider";

type Props = {
  paymentId?: string;
  onClose: () => void;
};

const PaymentInfoFlexpane: React.FC<Props> = ({ paymentId, onClose }) => {
  return (
    <Flexpane isOpen={Boolean(paymentId)} onClose={onClose} disableInitialFocus>
      {paymentId && (
        <PaymentInfoFlexpaneProvider onClose={onClose} paymentId={paymentId}>
          <PaymentInfoFlexpaneHeaderSection />
          <PaymentInfoFlexpaneToFromSection />
          <PaymentInfoFlexpaneAmountSection />
        </PaymentInfoFlexpaneProvider>
      )}
    </Flexpane>
  );
};

export default PaymentInfoFlexpane;
