import {
  Bank,
  Buildings,
  Gear,
  LockKey,
  Storefront,
  UsersFour,
  Calculator,
} from "@phosphor-icons/react";
import classNames from "classnames";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import { useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import SectionLinkCard from "ui/navigation/SectionLinkCard";
import Tabs from "ui/navigation/Tabs";
import useIsAllowedToNavigateToBusinessDetailsSettingsPage from "utils/permissions/navigation/useIsAllowedToNavigateToBusinessDetailsSettingsPage";
import useIsAllowedToNavigateToBusinessMembersSettingsPage from "utils/permissions/navigation/useIsAllowedToNavigateToBusinessMembersSettingsPage";
import useIsAllowedToNavigateToConnectedAccountingSofwarePage from "utils/permissions/navigation/useIsAllowedToNavigateToConnectedAccountingSofwarePage";
import useIsAllowedToNavigateToConnectedStoresPage from "utils/permissions/navigation/useIsAllowedToNavigateToConnectedStoresPage";
import useIsAllowedToNavigateToFinancialPartnersPage from "utils/permissions/navigation/useIsAllowedToNavigateToFinancialPartnersPage";

import styles from "./SettingsPage.module.scss";

type ActiveTab = "company-and-user" | "integrations";

const titles: { [key in ActiveTab]: string } = {
  "company-and-user": "Company and user",
  integrations: "Integrations",
};

const SettingsPage = () => {
  const isAllowedToNavigateToBusinessDetailsSettingsPage =
    useIsAllowedToNavigateToBusinessDetailsSettingsPage();
  const showBusinessDetailsPage = isAllowedToNavigateToBusinessDetailsSettingsPage;
  const showUsersPage = useIsAllowedToNavigateToBusinessMembersSettingsPage();
  const showfinancialPartnersPage = useIsAllowedToNavigateToFinancialPartnersPage();
  const showStoresPage = useIsAllowedToNavigateToConnectedStoresPage();

  const isAllowedToNavigateToConnectedAccountingSofwarePage =
    useIsAllowedToNavigateToConnectedAccountingSofwarePage();

  const showAccountingSoftwarePage = isAllowedToNavigateToConnectedAccountingSofwarePage;

  const showIntegrationsSection =
    showStoresPage || showfinancialPartnersPage || showAccountingSoftwarePage;

  const [activeTab, setActiveTab] = useState<ActiveTab>("company-and-user");

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Settings</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      {showIntegrationsSection && (
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={[
            { id: "company-and-user", label: titles["company-and-user"] },
            { id: "integrations", label: titles.integrations },
          ]}
          className={styles.tabs}
        />
      )}

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.IconTile icon={<Gear />} />
          <DashboardPage.Header.Title>Settings</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <DashboardPage.Section
          className={classNames(activeTab !== "company-and-user" && styles.hideSectionOnMoble)}
        >
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>
              {titles["company-and-user"]}
            </DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>

          <DashboardPage.Section.Body className={styles.linksContainer}>
            {showBusinessDetailsPage && (
              <Link to={"/settings/business"}>
                <SectionLinkCard
                  icon={<Buildings />}
                  title={"Business information"}
                  description={"Edit your business name, address, and other details here."}
                />
              </Link>
            )}

            <Link to={"/settings/security"}>
              <SectionLinkCard
                icon={<LockKey />}
                title={"Security"}
                description={"Update your password and configure authentication."}
              />
            </Link>

            {showUsersPage && (
              <Link to={"/settings/users"}>
                <SectionLinkCard
                  icon={<UsersFour />}
                  title={"Users"}
                  description={"Add or remove users who have access to your Highbeam account."}
                />
              </Link>
            )}
          </DashboardPage.Section.Body>
        </DashboardPage.Section>

        {showIntegrationsSection && (
          <DashboardPage.Section
            className={classNames(activeTab !== "integrations" && styles.hideSectionOnMoble)}
          >
            <DashboardPage.Section.Header>
              <DashboardPage.Section.HeaderHeading>
                {titles.integrations}
              </DashboardPage.Section.HeaderHeading>
            </DashboardPage.Section.Header>

            <DashboardPage.Section.Body className={styles.linksContainer}>
              {showStoresPage && (
                <Link to={"/settings/stores"}>
                  <SectionLinkCard
                    icon={<Storefront />}
                    title={"Stores"}
                    description={"Integrate your stores to see important information and insights."}
                  />
                </Link>
              )}

              {showfinancialPartnersPage && (
                <Link to={"/settings/financial-partners"}>
                  <SectionLinkCard
                    icon={<Bank />}
                    title={"Financial partners"}
                    description={
                      "Connect your other accounts and cards to transfer funds between them and access additional insights."
                    }
                  />
                </Link>
              )}

              {showAccountingSoftwarePage && (
                <Link to={"/settings/accounting-software"}>
                  <SectionLinkCard
                    icon={<Calculator />}
                    title={"Accounting software"}
                    description={
                      "Connect your accounting software so Highbeam can give you an optimal capital offering."
                    }
                  />
                </Link>
              )}
            </DashboardPage.Section.Body>
          </DashboardPage.Section>
        )}
      </DashboardPage>
    </>
  );
};

export default SettingsPage;
