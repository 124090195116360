import MetadataList from "components/MetadataList";
import Section from "ui/data-display/Section";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import { HighbeamWireTransaction } from "utils/types/transactionsTypes";

import TraceNumberLabel from "./TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";

type Props = {
  transaction: HighbeamWireTransaction;
};

const WireTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>Wire transfer details</Heading3>
    <MetadataList>
      {transaction.description && (
        <MetadataList.Item label="Description">
          <Text size={14} weight="medium">
            {transaction.description}
          </Text>
        </MetadataList.Item>
      )}
      <MetadataList.Item label="Routing number">
        <Text size={14} weight="medium">
          {transaction.counterpartyRoutingNumber}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item label="Account number">
        <Text size={14} weight="medium">
          {transaction.counterpartyAccountNumber}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item label="Account type">
        <Text size={14} weight="medium">
          {transaction.counterpartyAccountType}
        </Text>
      </MetadataList.Item>
      {transaction.imadOmad && (
        <MetadataList.Item label={<TraceNumberLabel />}>
          <Text size={14} weight="medium">
            {transaction.imadOmad
              ? `IMAD: ${transaction.imadOmad.imad}, OMAD: ${transaction.imadOmad.omad}`
              : "Available after payment sends"}
          </Text>
        </MetadataList.Item>
      )}
    </MetadataList>
  </Section>
);

export default WireTransactionDetailsSection;
