import { useSuspenseQuery } from "@tanstack/react-query";
import { useOpenBankAccountsQueryOptions } from "resources/bank-accounts/queries/useOpenBankAccounts";
import mapTransferOption from "resources/bank-accounts/utils/mapTransferOption";

const usePaymentEnabledBankAccountOptions = () => {
  const { data: bankAccounts } = useSuspenseQuery(useOpenBankAccountsQueryOptions());

  return bankAccounts
    .map(mapTransferOption) // DEPRECATED: Do not copy this pattern. We must use this pattern for now until our `Dropdown` component is composable.
    .filter(
      (account) =>
        (account.supportsSendMoney && account.isThreadAccount) ||
        ("isPrimary" in account && account.isPrimary)
    );
};

export default usePaymentEnabledBankAccountOptions;
