import { FormProvider } from "react-hook-form";
import AvatarCard from "ui/data-display/AvatarCard";
import Divider from "ui/data-display/Divider";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/context/useModalContext";

import { useEditUserSuccessfulModal } from "../EditUserSuccessfulModal/state/editUserSuccessfulModalState";

import styles from "./EditUserRoleForm.module.scss";
import EditUserRoleSelect from "./EditUserRoleSelect";
import useEditUserRoleForm from "./hooks/useEditUserRoleForm";
import useEditUserRoleMutation from "./hooks/useEditUserRoleMutation";
import { useEditUserRoleModalOpenState } from "./state/editUserRoleModalState";

const EditUserRoleForm = () => {
  const form = useEditUserRoleForm();
  const { mutateAsync: editUserRole, isPending } = useEditUserRoleMutation();
  const { initials, fullName, userRoleName } = useEditUserRoleModalOpenState();
  const { open: openEditUserSuccessfulModal } = useEditUserSuccessfulModal();
  const modalContext = useModalContext();

  const onSubmit = form.handleSubmit(async (data) => {
    await editUserRole({
      userRoleGuid: data.userRole.guid,
    });

    openEditUserSuccessfulModal({
      displayName: fullName,
      roleName: data.userRole.name,
    });
    modalContext.onClose();
  });

  return (
    <FormProvider {...form}>
      <ModalV4.Form onSubmit={onSubmit}>
        <ModalV4.Header>Change user role</ModalV4.Header>

        <ModalV4.Body>
          <div className={styles.avatarCardContainer}>
            <AvatarCard fullName={fullName} initials={initials} userRoleName={userRoleName} />
          </div>

          <Divider className={styles.divider} />

          <EditUserRoleSelect />
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.Footer.SubmitButton
            isLoading={isPending}
            disabled={!form.formState.isValid || !form.formState.isDirty}
          >
            Change user role
          </ModalV4.Footer.SubmitButton>

          <ModalV4.Footer.CloseButton />
        </ModalV4.Footer>
      </ModalV4.Form>
    </FormProvider>
  );
};

export default EditUserRoleForm;
