import classNames from "classnames";
import { ReactNode } from "react";
import Card from "ui/data-display/Card";
import Text from "ui/typography/Text";

import styles from "./OnboardingStep.module.scss";

type Props = {
  headerContent?: ReactNode;
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  actions?: ReactNode;
  className?: string;
};

const OnboardingStep: React.FC<Props> = ({
  headerContent,
  title = undefined,
  subtitle = undefined,
  children,
  actions,
  className,
}) => (
  <Card className={classNames(className, styles.card)}>
    {headerContent}

    {title !== undefined && (
      <div className={styles.header}>
        <Text className={styles.title} weight="bold" size={20}>
          {title}
        </Text>
      </div>
    )}
    {subtitle !== undefined && (
      <Text className={styles.subtitle} size={12}>
        {subtitle}
      </Text>
    )}
    <div className={styles.body}>{children}</div>
    {actions && <div className={styles.footer}>{actions}</div>}
  </Card>
);

export default OnboardingStep;
