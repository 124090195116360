import { Icon, IconProps } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, ReactNode, useState } from "react";
import colors from "styles/colors";
import Radio from "ui/inputs/Radio";
import Text from "ui/typography/Text";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";

import styles from "./RadioCard.module.scss";

type RadioCardIconProps = IconProps & {
  Icon: Icon;
};

export const RadioCardIcon: FC<RadioCardIconProps> = ({ Icon, ...iconProps }) => {
  return (
    <div className={styles.radioCardIconContainer}>
      <Icon size={32} color={colors.grey[700]} weight="thin" {...iconProps} />
    </div>
  );
};

// These props are overwritten so we need to omit them from the base input prop types
type OverlappingInputProps = "value" | "onChange" | "checked" | "ref";
type RadioInputPropsWithoutOverlapping = Omit<
  JSX.IntrinsicElements["input"],
  OverlappingInputProps
>;

type RadioCardProps<TValue extends string> = RadioInputPropsWithoutOverlapping & {
  icon: ReactNode;
  value: TValue;
  label: ReactNode;
  description?: ReactNode;
  disabled?: boolean;
  onChange: (value: TValue) => void;
  checked: boolean;
  inputRef?: React.Ref<HTMLInputElement>; // Do not use `forwardRef` or else we cannot use a generic directly. https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
};

const RadioCard = <TValue extends string>({
  icon,
  value,
  onChange,
  label,
  checked,
  description,
  inputRef,
  disabled,
  ...inputProps
}: RadioCardProps<TValue>) => {
  const [isFocused, setIsFocused] = useState(false);

  // Selects the focused radio card when the space key is pressed
  useKeyboardEvent(
    (e) => e.code === "Space" || e.code === "Enter", // NB(alex): I actually want this to submit the form if a user presses `Enter` but I can't get it to work :/
    () => !disabled && isFocused && onChange(value),
    [disabled, isFocused, value, onChange]
  );

  return (
    <label
      htmlFor={value}
      className={classNames(styles.container, {
        [styles.checked]: checked,
        // TODO(alex): Do we need this style? Removed while adding typescript types.
        // [styles.focused]: !checked && isFocused,
        [styles.containerDisabled]: disabled,
      })}
      tabIndex={disabled ? -1 : 0}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <div className={styles.iconContainer}>{icon}</div>
      <div className={styles.textContainer}>
        <Text color={colors.grey[900]} size={14} weight="medium" className={styles.label}>
          {label}
        </Text>
        <Text color={colors.grey[500]} size={12} weight="regular">
          {description}
        </Text>
      </div>

      <div className={styles.radioContainer}>
        <Radio
          ref={inputRef}
          tabIndex={-1} // Prevents focusing the radio button, since we want the containing label to show focus instead.
          id={value}
          {...inputProps}
          checked={checked}
          disabled={disabled}
          value={value}
          onChange={() => onChange(value)}
        />
      </div>
    </label>
  );
};

export default RadioCard;
