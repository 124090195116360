import { useQuery } from "@tanstack/react-query";
import ConnectStores from "components/ConnectStores";
import MultiStep from "components/layouts/MultiStep";
import { Link, useNavigate } from "react-router-dom";
import useConnectedStoresQueryOptions from "resources/connected-stores/queries/useConnectedStoresQueryOptions";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import CreditApplicationBackButton from "../components/CreditApplicationBackButton";
import useGetNextPathname from "../hooks/useGetNextPathname";

import styles from "./CreditApplicationViews.module.scss";

const CONNECT_STORES_FORM_ID = "connect-stores-form";

const ConnectStoresView = () => {
  const navigate = useNavigate();
  const nextPathname = useGetNextPathname();
  const isSuperusering = useIsSuperusering();
  const allowSkipFeatureFlag = useFeatureFlag("CREDIT_ALLOW_SKIP_STORES");

  const { data: connectedStores = [] } = useQuery(useConnectedStoresQueryOptions());

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname && connectedStores.length > 0) {
      navigate(nextPathname);
    }
  };

  return (
    <MultiStep.Form id={CONNECT_STORES_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Connected stores</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <ConnectStores redirectPathKey="capital" />
      </MultiStep.Section>

      <MultiStep.Controls>
        <CreditApplicationBackButton />

        <MultiStep.Controls.NextButton
          form={CONNECT_STORES_FORM_ID}
          disabled={connectedStores.length === 0}
        >
          Save and continue
        </MultiStep.Controls.NextButton>

        {/* An empty div is needed here to make sure MultiStep.Controls grid gets 3 children (Back button is null for this step) */}
        <div />

        {(isSuperusering || allowSkipFeatureFlag || process.env.NODE_ENV === "development") &&
          nextPathname && (
            <Link to={nextPathname}>
              <MultiStep.Controls.SkipButton
                className={styles.skipButton}
                isSuperuserOnly={isSuperusering}
              />
            </Link>
          )}
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default ConnectStoresView;
