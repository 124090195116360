import { MagnifyingGlass, Plus, X } from "@phosphor-icons/react";
import payeesEmptyState from "assets/payees-empty-state.svg";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import { lastTransferDescription } from "pages/SendMoneyPage/utils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useActivePayeesQuery } from "resources/payees/queries/usePayees";
import Button from "ui/inputs/Button";
import TextInput from "ui/inputs/TextInput";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import EmptyState from "ui/table/EmptyState";
import Table, { TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import { useFuse } from "utils/customHooks/useFuse";

const PayeesPage = () => {
  const [search, setSearch] = useState("");

  const { data: payees = [], isLoading } = useActivePayeesQuery();

  const navigate = useNavigate();

  const sortedPayees = payees.sort((payeeA, payeeB) => (payeeA.name >= payeeB.name ? 1 : -1));

  const filteredPayees = useFuse(sortedPayees, search, {
    shouldSort: true,
    threshold: 0.3,
    keys: ["name", "emailAddress"],
  }).map((result) => result.item);

  const hasPayees = search ? filteredPayees.length > 0 : payees.length > 0;

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/payments">Payments</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Payees</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header
          actions={
            <Button variant="secondary" onClick={() => navigate("/payments/payees/new")}>
              <Plus weight="bold" />
              Add payee
            </Button>
          }
        >
          <DashboardPage.Header.Title>Payees</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <DashboardPage.Section>
          <TextInput
            id="payee-search"
            placeholder="Search for payees"
            value={search}
            onChange={setSearch}
            icon={search ? <X size={16} /> : <MagnifyingGlass size={16} />}
            onIconClick={() => setSearch("")}
          />
          <Table
            columns={[
              {
                title: "Payee",
                cellTextDarken: true,
                key: "name",
                cellTextWeight: "medium",
              },
              {
                title: "Last transfer",
                key: "lastTransferAt",
                align: TableColumnAlignment.RIGHT,
                cellRender: (payee) => (
                  <Text size={14} weight="medium">
                    {lastTransferDescription(payee) ?? "-"}{" "}
                  </Text>
                ),
              },
            ]}
            data={isLoading ? undefined : search ? filteredPayees : sortedPayees}
            rowKey="name"
            onRowClick={(payee) => navigate(`/payments/payees/${payee.guid}`)}
          />
          {!hasPayees && !isLoading && <EmptyBanner />}
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

const EmptyBanner = () => (
  <EmptyState
    image={payeesEmptyState}
    primaryText="No payees available."
    secondaryText="Once you have payees, they will be shown here."
  />
);

export default PayeesPage;
