import { DialogTitle } from "@headlessui/react";
import closeIcon from "assets/close-icon.svg";
import { FC, ReactElement, ReactNode } from "react";
import Heading2 from "ui/typography/Heading2";

import useModalContext, { ModalContextValue } from "../context/useModalContext";

import styles from "./ModalHeader.module.scss";

type Props = {
  icon?: ReactElement<{ size: number }>;
  children: ReactNode | ((renderProps: ModalContextValue) => ReactNode);
};

const ModalHeader: FC<Props> = ({ icon, children }) => {
  const modalContext = useModalContext();

  return (
    <DialogTitle as="div" className={styles.header}>
      <div className={styles.left}>
        {icon}
        <Heading2>{typeof children === "function" ? children(modalContext) : children}</Heading2>
      </div>
      <div className={styles.right}>
        <button className={styles.closeButton} onClick={modalContext.onClose} type="button">
          <img src={closeIcon} alt="Close" title="Close" />
        </button>
      </div>
    </DialogTitle>
  );
};

export default ModalHeader;
