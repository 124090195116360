import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFormContext } from "react-hook-form";
import useBusinessAddress from "resources/business-details/queries/useBusinessAddress";
import { z } from "zod";

const schema = z.object({
  line1: z.string().nonempty("Please enter an address."),
  line2: z.string(),
  city: z.string().min(2, "Please enter a valid city.").max(40, "Please enter a valid city."),
  state: z.string().length(2, "Please select a valid state."),
  postalCode: z
    .string()
    .min(5, "Please enter a valid postal code.")
    .max(10, "Please enter a valid postal code."),
  country: z.string().length(2, "Please select a valid country."),
});

type BusinessAddressFormInputs = z.infer<typeof schema>;

const useBusinessAddressForm = () => {
  const { line1, line2, city, state, postalCode, country } = useBusinessAddress();

  return useForm<BusinessAddressFormInputs>({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {
      line1,
      line2: line2 ?? "",
      city,
      state,
      postalCode,
      country,
    },
  });
};

export default useBusinessAddressForm;

export const useBusinessAddressFormContext = () => {
  return useFormContext<BusinessAddressFormInputs>();
};
