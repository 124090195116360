import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import { FC, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { selectedBusinessGuidState } from "state/auth/businessGuid";

type Props = {
  indexRoute: string;
};

const SuperuserRedirect: FC<Props> = ({ indexRoute }) => {
  const [searchParams] = useSearchParams();
  const businessGuid = searchParams.get("businessGuid");
  const canSuperuser = useCanSuperuser();
  const setSelectedBusinessGuid = useSetRecoilState(selectedBusinessGuidState);

  useEffect(() => {
    if (!businessGuid || !canSuperuser) return;
    setSelectedBusinessGuid(businessGuid);
  }, [businessGuid, setSelectedBusinessGuid, canSuperuser]);

  return <Navigate replace to={indexRoute} />;
};

export default SuperuserRedirect;
