import classNames from "classnames";
import React, { ReactNode } from "react";

import styles from "./SummaryArray.module.scss";
import SummaryItem from "./SummaryItem";

type SummaryArrayProps = {
  children: ReactNode;
  className?: string;
};
const SummaryArray: React.FC<SummaryArrayProps> = ({ children, className }) => (
  <div className={classNames(styles.container, className)}>{children}</div>
);

export default Object.assign(SummaryArray, { Item: SummaryItem });
