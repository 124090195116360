import MetadataList from "components/MetadataList";
import Section from "ui/data-display/Section";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import { HighbeamReturnedAchTransaction } from "utils/types/transactionsTypes";

type Props = {
  transaction: HighbeamReturnedAchTransaction;
};

const ReturnedAchTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>Returned ACH details</Heading3>
    <MetadataList>
      <MetadataList.Item label="Routing number">
        <Text size={14} weight="medium">
          {transaction.counterpartyRoutingNumber}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item label="Reason">
        <Text size={14} weight="medium">
          {transaction.reason}
        </Text>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default ReturnedAchTransactionDetailsSection;
