import IconWithTooltip from "components/common/IconWithTooltip";
import { FC, Suspense } from "react";
import BankAccountRep from "reps/BankAccountRep";
import useBankAccountInterestYieldYtd from "resources/bank-accounts/queries/useBankAccountInterestYieldYtd";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import ApyTooltipContent from "../ApyTooltipContent";
import SummaryArray from "../SummaryArray";
import YieldInfoTooltipContent from "../YieldEarnedTooltipContent";

import styles from "./ApySummary.module.scss";

type Props = {
  account: BankAccountRep.Complete;
};

const ApySummary: FC<Props> = ({ account }) => {
  const interestYtd = useBankAccountInterestYieldYtd(account.guid);
  const isInterestOverrideEnabled = useFeatureFlag("SPECIAL_INTEREST_CUSTOMER");

  return (
    <SummaryArray>
      <SummaryArray.Item>
        <div className={styles.interestSummaryHeader}>
          <Text size={14}>Current APY</Text>
          {!isInterestOverrideEnabled && (
            <IconWithTooltip content={<ApyTooltipContent account={account} />} />
          )}
        </div>
        <Text size={18} weight="medium">
          {(account.depositProduct.interestBps / 100).toFixed(1)}%
        </Text>
      </SummaryArray.Item>
      <SummaryArray.Item>
        <div className={styles.interestSummaryHeader}>
          <Text size={14}>Yield (YTD)</Text>
          <IconWithTooltip content={<YieldInfoTooltipContent />} />
        </div>
        <Suspense fallback={<AnimatedSpinner size={18} />}>
          <MoneyAmount
            color={interestYtd > 0 ? "green" : undefined}
            size={18}
            centsTextSize={12}
            cents={interestYtd}
            weight="medium"
          />
        </Suspense>
      </SummaryArray.Item>
    </SummaryArray>
  );
};

export default ApySummary;
