import { Bank } from "@phosphor-icons/react";
import { FC } from "react";
import { useFinancialInstitutionQuery } from "resources/financial-institutions/queries/useFinancialInstitution";
import {
  PayeeAchPaymentMethod,
  PayeeDomesticWirePaymentMethod,
  PayeePaymentMethod,
} from "resources/payee-payment-methods/queries/usePayeePaymentMethods";
import getDisplayAccountNumberForInternationalPaymentMethod from "resources/payee-payment-methods/utils/getDisplayAccountNumberForInternationalPaymentMethod";
import colors from "styles/colors";
import BankAccountV2 from "ui/data-display/BankAccountV2";
import ShimmerV2 from "ui/feedback/ShimmerV2";
import Text from "ui/typography/Text";
import { maskAccountNumber } from "utils/account";

import styles from "./PaymentMethodBankingInstitution.module.scss";

type UsaBankingInstitutionProps = {
  payeePaymentMethod: PayeeAchPaymentMethod | PayeeDomesticWirePaymentMethod;
};

const UsaBankingInstitution: FC<UsaBankingInstitutionProps> = ({ payeePaymentMethod }) => {
  const { data: financialInstitution, isLoading } = useFinancialInstitutionQuery({
    routingNumber: payeePaymentMethod.routingNumber,
  });

  if (isLoading) {
    return <ShimmerV2 className={styles.institutionShimmer} />;
  }

  if (!financialInstitution) {
    return null;
  }

  return (
    <BankAccountV2
      bankIcon={<Bank size={16} color={colors.grey[600]} />}
      accountName={financialInstitution.name}
      accountNumber={payeePaymentMethod.accountNumber}
      iconSize={16}
      textSize={14}
      textMarginTop={0}
      weight="regular"
    />
  );
};

type Props = {
  payeePaymentMethod: PayeePaymentMethod;
};

const PaymentMethodBankingInstitution: FC<Props> = ({ payeePaymentMethod }) => {
  switch (payeePaymentMethod.method) {
    case "ach":
    case "domestic-wire":
      return <UsaBankingInstitution payeePaymentMethod={payeePaymentMethod} />;
    case "international-wire":
      return (
        <Text size={14}>
          {payeePaymentMethod.currency}{" "}
          {maskAccountNumber(
            getDisplayAccountNumberForInternationalPaymentMethod(payeePaymentMethod)
          )}
        </Text>
      );
  }
};

export default PaymentMethodBankingInstitution;
