import CancelPaymentModal from "components/Transactions/TransactionInfoFlexpane/TransactionInfoFlexpaneHeader/CancelPaymentModal";
import { useCancelPaymentModal } from "components/Transactions/TransactionInfoFlexpane/TransactionInfoFlexpaneHeader/CancelPaymentModal/state/cancelPaymentModalState";
import useAllBankAccounts from "resources/bank-accounts/queries/useAllBankAccounts";
import useBankAccountByUnitCoDepositAccountId from "resources/bank-accounts/queries/useBankAccountByUnitCoDepositAccountId";
import { UNIT_PAYMENTS_QUERY_KEY } from "resources/unit-co-payments/queries/useUnitCoPaymentsQueryOptions";
import Button from "ui/inputs/Button";
import Flexpane from "ui/overlay/Flexpane";
import Text from "ui/typography/Text";
import { getPaymentCounterparty } from "utils/payments";
import useIsAllowedToCancelPayment from "utils/permissions/useIsAllowedToCancelPayment";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

const OriginatedAchTransactionFlexpaneHeader = () => {
  const { payment, onClose } = usePaymentInfoFlexpaneContext();

  const bankAccounts = useAllBankAccounts();
  const bankAccountsByUnitId = new Map(
    bankAccounts.map((account) => [account.unitCoDepositAccountId, account])
  );

  const isAllowedToCancelPayment = useIsAllowedToCancelPayment();
  const { open: openCancelPaymentModal } = useCancelPaymentModal();
  const bankAccount = useBankAccountByUnitCoDepositAccountId(
    payment.relationships.account.data.id,
    {
      required: true,
    }
  );

  const canCancelPayment =
    payment.attributes.status === "Pending" || payment.attributes.status === "PendingReview";
  const refreshPayments = useRefreshQuery([UNIT_PAYMENTS_QUERY_KEY]);

  const paymentGuid = payment.attributes.tags?.paymentGuid;
  const counterparty = getPaymentCounterparty(payment, bankAccountsByUnitId);

  return (
    <>
      <CancelPaymentModal
        onSuccess={() => {
          refreshPayments();
          onClose();
        }}
        paymentDirection={"Incoming"}
      />

      <Flexpane.Header
        onClose={onClose}
        right={
          canCancelPayment && paymentGuid ? (
            <Button
              variant="danger"
              onClick={() => {
                openCancelPaymentModal({
                  account: bankAccount,
                  paymentAmountInCents: payment.attributes.amount,
                  counterpartyName: counterparty.name,
                  paymentGuid: paymentGuid,
                });
              }}
              disabled={!isAllowedToCancelPayment}
              tooltip={
                !isAllowedToCancelPayment && (
                  <Text size={14}>You don’t have permission to cancel payments.</Text>
                )
              }
            >
              Cancel transaction
            </Button>
          ) : null
        }
      >
        Payment info
      </Flexpane.Header>
    </>
  );
};

export default OriginatedAchTransactionFlexpaneHeader;
