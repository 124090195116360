import classNames from "classnames";

import styles from "../Heading.module.scss";

type Props = React.HTMLAttributes<HTMLHeadingElement>;

const Heading4: React.FC<Props> = ({ children, className, ...props }) => (
  <h4 className={classNames(styles.heading, styles.heading4, className)} {...props}>
    {children}
  </h4>
);

export default Heading4;
