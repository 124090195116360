import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

const CHARGE_CARD_ACCOUNT_QUERY_KEY = "charge-card-account";

export const useChargeCardAccountQueryOptions = (capitalAccountGuid?: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [CHARGE_CARD_ACCOUNT_QUERY_KEY, { businessGuid, capitalAccountGuid }],
    queryFn: () => {
      if (!capitalAccountGuid) {
        return null;
      }
      return highbeamApi.chargeCard.get(businessGuid, capitalAccountGuid);
    },
  });
};

const useChargeCardAccount = <TRequired extends boolean>(
  capitalAccountGuid?: string,
  params?: RequiredParam<TRequired>
) => {
  const { data } = useSuspenseQuery(useChargeCardAccountQueryOptions(capitalAccountGuid));

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useChargeCardAccount;
