import { Link } from "react-router-dom";
import CapitalAccountCard from "resources/capital-accounts/components/CapitalAccountCard";
import EmptyCapitalAccountCard from "resources/capital-accounts/components/EmptyCapitalAccountCard";
import useEnabledCapitalAccounts from "resources/capital-accounts/queries/useEnabledCapitalAccounts";

import styles from "./CapitalAccountsList.module.scss";

const CapitalAccountsList = () => {
  const enabledCapitalAccounts = useEnabledCapitalAccounts();

  return (
    <div className={styles.container}>
      {enabledCapitalAccounts.map((capitalAccount) => (
        <Link key={capitalAccount.guid} to={`/capital/${capitalAccount.guid}`}>
          <CapitalAccountCard capitalAccountGuid={capitalAccount.guid} />
        </Link>
      ))}

      <EmptyCapitalAccountCard />
    </div>
  );
};

export default CapitalAccountsList;
