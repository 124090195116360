import { captureMessage } from "@sentry/react";
import { useCheckDepositFlexpaneContext } from "dialogs/CheckDepositFlexpane/context/CheckDepositFlexpaneProvider";
import useAllBankAccountsByUnitCoDepositAccountId from "resources/bank-accounts/queries/useAllBankAccountsByUnitCoDepositAccountId";
import BankAccount from "ui/data-display/BankAccount";

const CheckDepositFlexpaneBankAccountParty = () => {
  const { checkDeposit } = useCheckDepositFlexpaneContext();
  const bankAccountsMap = useAllBankAccountsByUnitCoDepositAccountId();
  const bankAccount = bankAccountsMap.get(checkDeposit.relationships.account.data.id);

  if (!bankAccount) {
    // TODO(alex): Required but not found error?
    captureMessage(`Bank account not found for check deposit ${checkDeposit.id}`);
    return null;
  }

  return <BankAccount accountName={bankAccount.name} />;
};

export default CheckDepositFlexpaneBankAccountParty;
