import { FC, Suspense } from "react";
import useBillEmail from "resources/bill-emails/queries/useBillEmail";
import Card from "ui/data-display/Card";
import EmptyState from "ui/data-display/EmptyState";
import ShimmerV2 from "ui/feedback/ShimmerV2";
import { formatEventTime } from "utils/date";

import styles from "./BillEmailDetailsCard.module.scss";

const BillEmailDetailsLoading: FC = () => (
  <div>
    <div className={styles.cardHeadContainer}>
      <div className={styles.primaryShimmerContainer}>
        <ShimmerV2 />
      </div>
      <div className={styles.secondaryShimmerContainer}>
        <ShimmerV2 />
      </div>
    </div>
    <div className={styles.cardBodyContainer}>
      <div className={styles.primaryShimmerContainer}>
        <ShimmerV2 />
      </div>
      <div className={styles.primaryShimmerContainer}>
        <ShimmerV2 />
      </div>
    </div>
  </div>
);

type BillEmailDetailsMainProps = {
  billEmailId: string;
};

const BillEmailDetailsMain: FC<BillEmailDetailsMainProps> = ({ billEmailId }) => {
  const billEmail = useBillEmail(billEmailId, { required: true });
  const { body, senderEmail, sentAt, subject } = billEmail;

  return (
    <div>
      <div className={styles.cardHeadContainer}>
        <p>From: {senderEmail}</p>
        <p className={styles.subInfo}>{formatEventTime(sentAt)}</p>
      </div>
      <div className={styles.cardBodyContainer}>
        <h1 className={styles.subject}>{subject}</h1>
        {body ? (
          <div className={styles.bodyTextContainer}>{body}</div>
        ) : (
          <EmptyState
            variant="card"
            className={styles.bodyEmptyState}
            body={<EmptyState.PrimaryText>This email has no body</EmptyState.PrimaryText>}
          />
        )}
      </div>
    </div>
  );
};

type Props = {
  billEmailId: string;
};

const BillEmailDetails: FC<Props> = ({ billEmailId }) => {
  return (
    <Card shadow="none" className={styles.cardContainer}>
      <Suspense fallback={<BillEmailDetailsLoading />}>
        <BillEmailDetailsMain billEmailId={billEmailId} />
      </Suspense>
    </Card>
  );
};

export default BillEmailDetails;
