import MetadataList from "components/MetadataList";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import HighbeamAccountIcon from "resources/bank-accounts/components/HighbeamAccountIcon";
import useCapitalAccountAutoPayBankAccount from "resources/capital-accounts/queries/useCapitalAccountAutoPayBankAccount";
import BankAccountV2 from "ui/data-display/BankAccountV2";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountCardAutoPayBankAccountItem: FC<Props> = ({ capitalAccountSummary }) => {
  const capitalAccountAutoPayBankAccount =
    useCapitalAccountAutoPayBankAccount(capitalAccountSummary);

  return (
    <MetadataList.Item label="Auto-pay account">
      <BankAccountV2
        accountName={capitalAccountAutoPayBankAccount.name}
        accountNumber={capitalAccountAutoPayBankAccount.accountNumber}
        bankIcon={
          <HighbeamAccountIcon
            highbeamTypeName={capitalAccountAutoPayBankAccount.highbeamType.name}
          />
        }
      />
    </MetadataList.Item>
  );
};

export default CapitalAccountCardAutoPayBankAccountItem;
