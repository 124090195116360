import { Check } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, useState } from "react";
import { SetRequired } from "type-fest";
import Pill from "ui/data-display/Pill";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import TextArea, { TextAreaProps } from "ui/inputs/TextArea";

import styles from "./TransactionFlexpaneNotes.module.scss";

export const TransactionFlexpaneNotesLoadingPill = () => {
  return (
    <Pill color="purple-dark" className={styles.pill}>
      <AnimatedSpinner />
      Loading note
    </Pill>
  );
};

type Props = SetRequired<TextAreaProps, "value" | "onChange"> & {
  isSaving: boolean;
  isLoading: boolean;
  saveNotes: (value: string) => Promise<void>;
};

const TransactionFlexpaneNotes: FC<Props> = ({
  isSaving,
  isLoading,
  onBlur: onBlurProp,
  saveNotes,
  ...textAreaProps
}) => {
  const [showSaved, setShowSaved] = useState(false);

  const onBlur = async () => {
    await saveNotes(textAreaProps.value);

    // Optional onBlur prop, just in case we want to specific some kind of additional onBlur behavior
    onBlurProp && (await onBlurProp());

    // Triggers the success pill
    setShowSaved(true);
    setTimeout(() => setShowSaved(false), 1500);
  };

  return (
    <div className={classNames(styles.container)}>
      {isSaving && (
        <Pill color="purple-dark" className={styles.pill}>
          <AnimatedSpinner />
          Saving note
        </Pill>
      )}
      {isLoading && <TransactionFlexpaneNotesLoadingPill />}
      {showSaved && (
        <Pill color="green-dark" className={styles.pill}>
          <Check />
          Note saved
        </Pill>
      )}
      <TextArea
        label="Note"
        placeholder="Add a note about this transaction"
        maxLength={10000}
        className={classNames((isLoading || isSaving || showSaved) && styles.overlay)}
        onBlur={onBlur}
        {...textAreaProps}
      />
    </div>
  );
};

export default TransactionFlexpaneNotes;
