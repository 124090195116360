import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { BANK_ACCOUNTS_QUERY_KEY } from "resources/bank-accounts/queries/useBankAccountsQueryOptions";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

type Variables = {
  accountGuid: string;
};

const useSetPrimaryAccountNameMutation = () => {
  const highbeamApi = useHighbeamApi();

  const refreshBankAccounts = useRefreshQuery([BANK_ACCOUNTS_QUERY_KEY]);

  return useMutation({
    mutationFn: async ({ accountGuid }: Variables) =>
      await highbeamApi.bankAccount.update(accountGuid, { isPrimary: true }),
    onError: (err) => {
      notify("error", "Something went wrong! Please try again.");
      captureException(err);
    },
    onSuccess: async () => {
      refreshBankAccounts();
    },
  });
};

export default useSetPrimaryAccountNameMutation;
