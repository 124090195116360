import DashboardPage from "components/layouts/DashboardPage";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import CapitalAvailableSection from "../../components/CapitalAvailableSection";
import CapitalAvailableHighlightItems from "../../components/CapitalAvailableSection/CapitalAvailableHighlightItems";
import CapitalAccountBanners from "../../sections/CapitalAccountBanners";
import CapitalAccountCreditTransactionsSection from "../../sections/CapitalAccountCreditTransactionsSection";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";
import CapitalAccountPendingDrawdownsSection from "../../sections/CapitalAccountPendingDrawdownsSection";
import PendingDrawdownFlexpane from "../../sections/CapitalAccountPendingDrawdownsSection/PendingDrawdownFlexpane";

import LineOfCreditDetailsSection from "./LineOfCreditDetailsSection";
import styles from "./LineOfCreditView.module.scss";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const LineOfCreditView: FC<Props> = ({ capitalAccountSummary }) => {
  const navigate = useNavigate();
  const { pendingDrawdownGuid } = useParams();
  return (
    <>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => {
          // NB(alex): Necessary for resetting the error boundary after navigation takes place.
          resetErrorBoundary();

          if (error instanceof RequiredButNotFoundError) {
            return (
              <Navigate
                to={`/capital/${capitalAccountSummary.guid}`}
                state={DISABLE_SCROLL_TO_TOP_STATE}
              />
            );
          }

          throw error;
        }}
      >
        <PendingDrawdownFlexpane
          pendingDrawdownGuid={pendingDrawdownGuid}
          onClose={() => {
            navigate(`/capital/${capitalAccountSummary.guid}`, {
              state: DISABLE_SCROLL_TO_TOP_STATE,
            });
          }}
        />
      </ErrorBoundary>

      <CapitalAccountBanners capitalAccountSummary={capitalAccountSummary} />

      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummary} />

      <DashboardPage.Section className={styles.section}>
        <div className={styles.sectionHalf}>
          <CapitalAvailableSection
            showAvailableOutstandingBalance
            capitalAccountSummary={capitalAccountSummary}
          />
          <CapitalAvailableHighlightItems capitalAccountSummary={capitalAccountSummary} />
        </div>

        <div className={styles.sectionHalf}>
          <LineOfCreditDetailsSection capitalAccountSummary={capitalAccountSummary} />
        </div>
      </DashboardPage.Section>

      <CapitalAccountPendingDrawdownsSection capitalAccountSummary={capitalAccountSummary} />

      <CapitalAccountCreditTransactionsSection capitalAccountSummary={capitalAccountSummary} />
    </>
  );
};

export default LineOfCreditView;
