import Table from "ui/table/Table";

import Empty from "./Empty";

type Props<T> = React.ComponentProps<typeof Table<T>> & {
  EmptyComponent?: React.ComponentType;
};

export const TransactionsTable = <T extends unknown>({
  EmptyComponent = Empty,
  data,
  ...tableProps
}: Props<T>) => {
  if (!data || data.length === 0) {
    return <EmptyComponent />;
  }
  return <Table data={data} {...tableProps} />;
};

export default TransactionsTable;
