import { FC, ReactNode } from "react";
import { useRecoilValue } from "recoil";
import BillRep from "reps/BillRep";
import BillApprovalStatusPill from "resources/bills/components/BillApprovalStatusPill";
import BillPaymentStatusPill from "resources/bills/components/BillPaymentStatusPill";
import useBill from "resources/bills/queries/useBill";
import { billDetailsAutosaveStatusState } from "resources/bills/state/billDetailsAutosaveState";
import AutosaveIndicator from "ui/feedback/AutosaveIndicator";
import FullPageModal from "ui/overlay/FullPageModal";
import useFullPageModalContext from "ui/overlay/FullPageModal/context/useFullPageModalContext";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import styles from "./BillDetailsFullPageModalHeader.module.scss";

type Props = {
  billId: string;
};

const BillDetailsFullPageModalHeader: FC<Props> = ({ billId }) => {
  const bill = useBill(billId, { required: true });
  const billPayApproversEnabled = useFeatureFlag("BILL_PAY_APPROVERS_UI");
  const { onClose } = useFullPageModalContext();
  const autosaveStatus = useRecoilValue(billDetailsAutosaveStatusState);
  const isBillDraft = bill.state === BillRep.State.Draft;

  // eslint-disable-next-line functional/no-let
  let titleTextContent: ReactNode;
  if (isBillDraft) {
    titleTextContent = "Review bill";
  } else if (bill.invoiceNumber) {
    titleTextContent = `Bill • #${bill.invoiceNumber}`;
  } else {
    titleTextContent = "Bill details";
  }

  return (
    <FullPageModal.Header
      className={styles.header}
      onBack={onClose}
      title={
        <div className={styles.container}>
          <div className={styles.titleTextContainer}>{titleTextContent}</div>

          {!isBillDraft && (
            <div className={styles.statusPillsContainer}>
              {billPayApproversEnabled && <BillApprovalStatusPill bill={bill} />}
              <BillPaymentStatusPill bill={bill} />
            </div>
          )}

          <AutosaveIndicator saveStatus={autosaveStatus} />
        </div>
      }
    />
  );
};

export default BillDetailsFullPageModalHeader;
