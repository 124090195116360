import { useSuspenseQuery } from "@tanstack/react-query";
import CapitalAccountRep from "reps/CapitalAccountRep";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useCapitalAccountsQueryOptions from "./useCapitalAccountsQueryOptions";

export type EnabledCapitalAccount = Omit<CapitalAccountRep.Complete, "state"> & {
  state: Exclude<CapitalAccountRep.State, CapitalAccountRep.State.Terminated>;
};

export const useEnabledCapitalAccountsQueryOptions = () => {
  const capitalAccountsQueryOptions = useCapitalAccountsQueryOptions();

  return useQueryOptions({
    queryKey: [...capitalAccountsQueryOptions.queryKey, "enabled-capital-accounts"],
    queryFn: async () => {
      const capitalAccounts = await capitalAccountsQueryOptions.queryFn();
      return capitalAccounts.filter(
        (capitalAccount) =>
          capitalAccount.state === CapitalAccountRep.State.Active ||
          capitalAccount.state === CapitalAccountRep.State.Offered ||
          capitalAccount.state === CapitalAccountRep.State.OfferAccepted
      ) as EnabledCapitalAccount[];
    },
  });
};

const useEnabledCapitalAccounts = () => {
  return useSuspenseQuery(useEnabledCapitalAccountsQueryOptions()).data;
};

export default useEnabledCapitalAccounts;
