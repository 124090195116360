import React from "react";
import Radio from "ui/inputs/Radio";

import WithLabel from "./WithLabel";

// NB(alex): Do not follow the pattern in this component. It should use the underlying `input` component but it doesn't.

type Props = {
  checked: boolean;
  onClick: (evt: React.MouseEvent) => void;
  disabled?: boolean;
  label: string;
  value?: string;
};

const RadioLabel: React.FC<Props> = ({ checked, onClick, disabled = false, label, ...props }) => (
  <WithLabel
    onClick={disabled ? () => {} : onClick}
    component={<Radio checked={checked} disabled={disabled} {...props} readOnly />}
    label={label}
  />
);

export default RadioLabel;
