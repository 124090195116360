import { FC, ReactElement, ReactNode, cloneElement } from "react";
import Text from "ui/typography/Text";

import styles from "../CapitalAccountCard.module.scss";

type Props = {
  icon: ReactElement<{ size: number }>;
  children: ReactNode;
  badge?: ReactNode;
};

const CapitalAccountCardHeader: FC<Props> = ({ icon, children, badge }) => {
  return (
    <div className={styles.header}>
      <div className={styles.iconContainer}>
        {cloneElement(icon, { size: icon.props.size ?? 32 })}

        {badge}
      </div>
      <Text weight="bold" size={18}>
        {children}
      </Text>
    </div>
  );
};

export default CapitalAccountCardHeader;
