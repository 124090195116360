import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { supportedStatesForCapital } from "utils/address";
import { z } from "zod";

const schema = z.object({
  addressLine1: z.string().nonempty("Please enter an address."),
  addressLine2: z.string(),
  city: z.string().min(2, "Please enter a valid city.").max(40, "Please enter a valid city."),
  state: z.string().refine((val) => val in supportedStatesForCapital, {
    message: "Please select a valid state.",
  }),
  zipCode: z.string().length(5, "Please enter a valid postal code."),
  country: z.string().length(2, "Please select a valid country."),
});

export type UpdateBusinessAddressFormInputs = z.infer<typeof schema>;

const useUpdateBusinessAddressForm = () => {
  const defaultValues = {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "US", // Hardcode US since we only accept US addresses
  };

  return useForm<UpdateBusinessAddressFormInputs>({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
  });
};

export default useUpdateBusinessAddressForm;
