import { ArrowRight, PlusCircle } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import UpdatePlaidConnectionBanners from "components/UpdatePlaidConnectionBanners";
import { useState, useEffect, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import useInactivePlaidDepositoryAccounts from "resources/plaid-connections/queries/useInactivePlaidDepositoryAccounts";
import useUnitCoAccountLimitQueryOptions from "resources/unit-co-account-limits/queries/useUnitCoAccountLimitQueryOptions";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Button from "ui/inputs/Button";
import CurrencyInput from "ui/inputs/CurrencyInput";
import Dropdown, { MenuPendProps } from "ui/inputs/Dropdown";
import Helper from "ui/inputs/Helper";
import { getDollarsFromCents, getCentsFromDollars } from "utils/money";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";
import {
  useTransferAccountOptions,
  TransferOption,
  isHighbeamBankAccount,
  isPlaidBankAccount,
} from "utils/transfers";

import ConnectPlaidBanner from "../ConnectPlaidBanner";
import { TransferLimitSectionV2 } from "../TransferLimitSectionV2";
import {
  getHighbeamAndCounterparty,
  isAchCredit,
  isAchDebit,
  isConnectedAccountTransfer,
} from "../utils";

import styles from "./TransferInfo.module.scss";
import { filterTransferOptions, Transfer } from "./utils";

type TransferInfoProps = {
  transferInfo: Transfer;
  initialFromAccountGuid?: string;
  initialToAccountGuid?: string;
  setTransferInfo: (transfer: Transfer) => void;
  onNextPress: () => void;
};
const TransferInfo: React.FC<TransferInfoProps> = ({
  transferInfo,
  initialFromAccountGuid,
  initialToAccountGuid,
  setTransferInfo,
  onNextPress,
}) => {
  const navigate = useNavigate();
  const {
    fetchTransferOptions,
    transferOptions: allTransferOptions,
    isLoading: isTransferOptionsLoading,
  } = useTransferAccountOptions();
  const supportedTransferOptions = allTransferOptions.filter((it) => it.supportsTransferMoney);
  const [isAmountMoreThanBalanceError, setIsAmountMoreThanBalanceError] = useState(false);
  const [isSameAccountError, setIsSameAccountError] = useState(false);
  const [isExternalAccountBannerDismissed, setIsExternalAccountBannerDismissed] = useState(false);
  const [amount, setAmount] = useState(
    transferInfo.amountInCents !== 0
      ? getDollarsFromCents(transferInfo.amountInCents).toString()
      : ""
  );

  const getAccountId = () => {
    if (isConnectedAccountTransfer(transferInfo) && transferInfo.from && transferInfo.to) {
      return getHighbeamAndCounterparty(transferInfo)[0].value;
    }
    return undefined;
  };

  const { data: accountLimits } = useQuery(useUnitCoAccountLimitQueryOptions(getAccountId()));

  const isTransferDailyLimitError = () => {
    if (!accountLimits) return false;

    return isAchCredit(transferInfo)
      ? accountLimits.attributes.ach.limits.dailyCredit -
          accountLimits.attributes.ach.totalsDaily.credits -
          transferInfo.amountInCents <
          0
      : accountLimits.attributes.ach.limits.dailyDebit -
          accountLimits.attributes.ach.totalsDaily.debits -
          transferInfo.amountInCents <
          0;
  };

  const isTransferMonthlyLimitError = () => {
    if (!accountLimits) return false;

    return isAchCredit(transferInfo)
      ? accountLimits.attributes.ach.limits.monthlyCredit -
          accountLimits.attributes.ach.totalsMonthly.credits -
          transferInfo.amountInCents <
          0
      : accountLimits.attributes.ach.limits.monthlyDebit -
          accountLimits.attributes.ach.totalsMonthly.debits -
          transferInfo.amountInCents <
          0;
  };

  const isTransferLimitError = () => isTransferDailyLimitError() || isTransferMonthlyLimitError();

  const amountErrorVariant = isAmountMoreThanBalanceError ? "error" : "chartError";

  const getAmountErrorMessage = () => {
    if (isAmountMoreThanBalanceError) {
      return "The amount you entered is greater than your account balance.";
    } else if (isTransferLimitError()) {
      if (isAchDebit(transferInfo)) {
        return "Amount exceeds how much you can transfer from your connected account.";
      } else {
        return "Amount exceeds how much you can send to your connected account.";
      }
    } else {
      return "";
    }
  };
  const balanceAfter = transferInfo.from
    ? transferInfo.from.availableBalanceInCents - transferInfo.amountInCents
    : 0;

  const inactivePlaidDepositoryAccounts = useInactivePlaidDepositoryAccounts();
  const hasInactivePlaidDepositoryAccounts = inactivePlaidDepositoryAccounts.length > 0;

  useEffect(() => {
    fetchTransferOptions();
  }, [fetchTransferOptions]);

  useEffect(
    () => setIsAmountMoreThanBalanceError(balanceAfter < 0),
    [balanceAfter, setIsAmountMoreThanBalanceError]
  );

  useEffect(() => {
    if (
      transferInfo.from &&
      transferInfo.to &&
      transferInfo.from?.value === transferInfo.to?.value
    ) {
      setIsSameAccountError(true);
      return;
    }
    setIsSameAccountError(false);
  }, [transferInfo]);

  const fromTransferOptions = filterTransferOptions(supportedTransferOptions, transferInfo.to);

  const toTransferOptions = filterTransferOptions(supportedTransferOptions, transferInfo.from);

  // Set the initial from account.
  useEffect(() => {
    if (!initialFromAccountGuid || isTransferOptionsLoading) return;
    const found = fromTransferOptions.find(
      (option) => isHighbeamBankAccount(option) && option.guid === initialFromAccountGuid
    );
    if (!found) return;
    setTransferInfo({ ...transferInfo, from: found });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransferOptionsLoading, initialFromAccountGuid]);

  // Set the initial to account.
  useEffect(() => {
    if (!initialToAccountGuid || isTransferOptionsLoading) return;
    const found = toTransferOptions.find(
      (option) => isHighbeamBankAccount(option) && option.guid === initialToAccountGuid
    );
    if (!found) return;
    setTransferInfo({ ...transferInfo, to: found });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransferOptionsLoading, initialToAccountGuid]);

  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();

  const showExternalAccountBanner =
    !isTransferOptionsLoading &&
    !isExternalAccountBannerDismissed &&
    !supportedTransferOptions.some(isPlaidBankAccount) &&
    !hasInactivePlaidDepositoryAccounts &&
    isAllowedToConnectBankAccounts;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNextPress();
  };

  const isNextStepAllowed =
    transferInfo.amountInCents &&
    transferInfo.from &&
    transferInfo.to &&
    !isAmountMoreThanBalanceError &&
    !isSameAccountError &&
    !isTransferLimitError();

  // The "Connect an account" option uses a "menu append" option type, but doesn't actually use the features to
  // dynamically add an option. Instead, it just redirects to the Plaid connection page.
  const connectAnAccountOptionProps: MenuPendProps = {
    icon: PlusCircle,
    onClick: () => {
      navigate("/settings/financial-partners");
    },
    rightIcon: ArrowRight,
    iconClassName: styles.menuAppendIcon,
    text: "Connect an account",
    description: "Connect your other bank accounts for easy transfers between them and Highbeam.",
  };

  return (
    <>
      <div>
        <div>
          <form id="transfer-info" className={styles.form} onSubmit={handleSubmit}>
            <div>
              <Dropdown
                isLoading={isTransferOptionsLoading}
                disabled={isTransferOptionsLoading}
                value={transferInfo.from}
                onChange={(from) =>
                  setTransferInfo({ ...transferInfo, from: from as TransferOption })
                }
                id="from"
                label="Transfer from"
                options={fromTransferOptions}
                isSearchable={false}
                menuAppend={
                  isAllowedToConnectBankAccounts ? connectAnAccountOptionProps : undefined
                }
                hasError={isSameAccountError}
                errorMessage="You cannot transfer money between the same account."
                autoFocus
              />
              {transferInfo.from && !isSameAccountError && !isAmountMoreThanBalanceError && (
                <Helper>
                  Balance after transfer: <MoneyAmount weight="medium" cents={balanceAfter} />
                </Helper>
              )}
            </div>
            <Dropdown
              isLoading={isTransferOptionsLoading}
              disabled={isTransferOptionsLoading}
              value={transferInfo.to}
              onChange={(to) => setTransferInfo({ ...transferInfo, to: to as TransferOption })}
              id="to"
              label="Transfer to"
              options={toTransferOptions}
              isSearchable={false}
              menuAppend={isAllowedToConnectBankAccounts ? connectAnAccountOptionProps : undefined}
            />
            <CurrencyInput
              value={amount}
              onChange={(value) => {
                setAmount(value);
                setTransferInfo({
                  ...transferInfo,
                  amountInCents: value ? getCentsFromDollars(value) : 0,
                });
              }}
              label="Amount"
              prefixValue="$"
              hasError={isAmountMoreThanBalanceError || isTransferLimitError()}
              errorMessage={getAmountErrorMessage()}
              errorVariant={amountErrorVariant}
            />
          </form>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              type="submit"
              form="transfer-info"
              variant="primary"
              disabled={!isNextStepAllowed}
            >
              Next
            </Button>
          </div>
        </div>
        <Suspense fallback={null}>
          <TransferLimitSectionV2 transfer={transferInfo} />
        </Suspense>
      </div>

      {showExternalAccountBanner && (
        <Suspense fallback={null}>
          <ConnectPlaidBanner onDismiss={() => setIsExternalAccountBannerDismissed(true)} />
        </Suspense>
      )}

      <Suspense fallback={null}>
        <UpdatePlaidConnectionBanners bannerWrapperAdditionalClassName={styles.bannerContainer} />
      </Suspense>
    </>
  );
};

export default TransferInfo;
