import { captureException } from "@sentry/react";
import confettiGif from "assets/onboarding-confetti.gif";
import OnboardingLayout from "components/layouts/Onboarding";
import useMfa from "modules/mfa/useMfa";
import ApplicationEvaluationPage from "pages/ApplicationEvaluationPage";
import { useEffect, useState } from "react";
import useBusiness from "resources/business/queries/useBusiness";
import { useRefreshJwt } from "state/auth/jwt";
import colors from "styles/colors";
import InfoIcon from "ui/icons/InfoIcon";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import useMarkAuthorizedWithUnit from "../hooks/useMarkAuthorizedWithUnit";

import styles from "./OnboardingGetStartedPage.module.scss";

const OnboardingGetStartedPage = () => {
  const shouldRefreshJwt = useFeatureFlag("REFRESH_JWT_BEFORE_INITIAL_AUTHORIZATION") as boolean;

  const refreshJwt = useRefreshJwt();
  const { segmentTrack } = useSegment();

  const { mfa } = useMfa();

  const { mutate: markAuthorizedWithUnit, isPending } = useMarkAuthorizedWithUnit();

  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 1800);
    return () => clearTimeout(timer);
  });

  const business = useBusiness();

  const isBusinessActive = business.status === "Active";

  if (!isBusinessActive) {
    return <ApplicationEvaluationPage />;
  }

  const getStarted = async () => {
    // We require the user to MFA first in order to ensure they have a phone number configured with Auth0.
    // This phone number will be passed through to Unit.
    if (shouldRefreshJwt) {
      try {
        await refreshJwt();
      } catch (e) {
        captureException(e);
      }
    }
    await mfa();
    segmentTrack(SEGMENT_EVENTS.ONBOARDING_COMPLETE);
    markAuthorizedWithUnit();
  };

  return (
    <OnboardingLayout showProgressBar={false} style={{ background: colors.purple[50] }}>
      <div className={styles.container}>
        {showConfetti && <img src={confettiGif} alt="confetti" />}
        {!showConfetti && (
          <>
            <InfoIcon variant="success" size={70} className={styles.successIcon} />
            <div className={styles["title-container"]}>
              <Text align="center" size={28} weight="bold">
                Your banking just got a lot more exciting!
              </Text>
              <Text className={styles.subtitle} align="center" size={16}>
                We’re glad to have you on board.
              </Text>
            </div>
            <Button
              className={styles.button}
              variant="primary"
              isLoading={isPending}
              onClick={getStarted}
            >
              Take me to Highbeam
            </Button>
          </>
        )}
      </div>
    </OnboardingLayout>
  );
};

export default OnboardingGetStartedPage;
