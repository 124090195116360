import AccountLabel from "components/Accounts/AccountLabel";
import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import useOpenBankAccount from "resources/bank-accounts/queries/useOpenBankAccount";
import CapitalAccountIcon from "resources/capital-accounts/components/CapitalAccountIcon";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import usePendingDrawdown from "resources/line-of-credit/queries/usePendingDrawdown";
import Text from "ui/typography/Text";

import { usePendingDrawdownFlexpaneContext } from "../../context/PendingDrawdownFlexpaneProvider";

import styles from "./DrawdownTransferDetails.module.scss";
import DrawdownTransferStatus from "./DrawdownTransferStatus";

const PendingDrawdownTransferDetails = () => {
  const { pendingDrawdownGuid } = usePendingDrawdownFlexpaneContext();
  const pendingDrawdown = usePendingDrawdown(pendingDrawdownGuid, { required: true });
  const bankAccount = useOpenBankAccount(pendingDrawdown.bankAccountGuid);
  const capitalAccountSummary = useCapitalAccountSummaryContext();

  if (!bankAccount) {
    return null;
  }
  return (
    <TransactionFlexpaneParties
      from={
        <div className={styles.drawdownFrom}>
          <CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} size={20} />
          <Text size={14} weight="medium">
            {capitalAccountSummary.name}
          </Text>
        </div>
      }
      status={<DrawdownTransferStatus />}
      to={<AccountLabel bankAccount={bankAccount} />}
    />
  );
};

export default PendingDrawdownTransferDetails;
