import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormProps } from "react-hook-form";
import Validator from "utils/validator";
import { z } from "zod";

const payeeDetailsFormSchema = z.object({
  payeeName: z.string().min(1, "This field is required."),
  payeeEmail: z.string().email("Please enter a valid email.").or(z.literal("")), // Allow blank string, otherwise must be a valid email.
  payeePhoneNumber: z
    .string()
    .refine(Validator.phoneNumber, "Please enter a valid phone number.")
    .or(z.literal("")),
});

export type PayeeDetailsFormInputs = z.infer<typeof payeeDetailsFormSchema>;

type UsePayeeDetailsFormParams = Pick<
  UseFormProps<PayeeDetailsFormInputs>,
  "defaultValues" | "disabled"
>;

const usePayeeDetailsForm = (params?: UsePayeeDetailsFormParams) => {
  return useForm({
    resolver: zodResolver(payeeDetailsFormSchema),
    ...params,
    defaultValues: {
      payeeName: "",
      payeeEmail: "",
      payeePhoneNumber: "",
      ...params?.defaultValues,
    },
  });
};

export default usePayeeDetailsForm;
