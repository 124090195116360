import MetadataList from "components/MetadataList";
import { FC } from "react";
import Address, { AddressProps } from "ui/data-display/Address";
import Text from "ui/typography/Text";

type Props = {
  description?: string;
  bicSwift?: string;
  iban?: string;
  accountNumber?: string;
  bankCode?: string;
  branchCode?: string;
  bsbCode?: string;
  clabe?: string;
  cnaps?: string;
  ifsc?: string;
  sortCode?: string;
  payeeType?: string;
  address?: AddressProps;
};

const InternationalWiresMetadataListItems: FC<Props> = ({
  description,
  bicSwift,
  iban,
  accountNumber,
  bankCode,
  branchCode,
  bsbCode,
  clabe,
  cnaps,
  ifsc,
  sortCode,
  payeeType,
  address,
}) => {
  return (
    <>
      {description && (
        <MetadataList.Item label="Description">
          <Text size={14} weight="medium">
            {description}
          </Text>
        </MetadataList.Item>
      )}
      {bicSwift && (
        <MetadataList.Item label="BIC Swift">
          <Text size={14} weight="medium">
            {bicSwift}
          </Text>
        </MetadataList.Item>
      )}
      {iban && (
        <MetadataList.Item label="IBAN">
          <Text size={14} weight="medium">
            {iban}
          </Text>
        </MetadataList.Item>
      )}
      {accountNumber && (
        <MetadataList.Item label="Account number">
          <Text size={14} weight="medium">
            {accountNumber}
          </Text>
        </MetadataList.Item>
      )}
      {bankCode && (
        <MetadataList.Item label="Bank code">
          <Text size={14} weight="medium">
            {bankCode}
          </Text>
        </MetadataList.Item>
      )}
      {branchCode && (
        <MetadataList.Item label="Branch code">
          <Text size={14} weight="medium">
            {branchCode}
          </Text>
        </MetadataList.Item>
      )}
      {bsbCode && (
        <MetadataList.Item label="Bank state branch code">
          <Text size={14} weight="medium">
            {bsbCode}
          </Text>
        </MetadataList.Item>
      )}
      {clabe && (
        <MetadataList.Item label="CLABE">
          <Text size={14} weight="medium">
            {clabe}
          </Text>
        </MetadataList.Item>
      )}
      {cnaps && (
        <MetadataList.Item label="CNAPS code">
          <Text size={14} weight="medium">
            {cnaps}
          </Text>
        </MetadataList.Item>
      )}
      {ifsc && (
        <MetadataList.Item label="IFSC">
          <Text size={14} weight="medium">
            {ifsc}
          </Text>
        </MetadataList.Item>
      )}
      {sortCode && (
        <MetadataList.Item label="Sort code">
          <Text size={14} weight="medium">
            {sortCode}
          </Text>
        </MetadataList.Item>
      )}
      {payeeType && (
        <MetadataList.Item label="Payee type">
          <Text size={14} weight="medium">
            {payeeType}
          </Text>
        </MetadataList.Item>
      )}
      {address && (
        <MetadataList.Item label="Address">
          <Address address={address} size={14} weight="medium" />
        </MetadataList.Item>
      )}
    </>
  );
};

export default InternationalWiresMetadataListItems;
