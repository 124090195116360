import {
  AuthorizedUser,
  BusinessCreditCard,
  BusinessDebitCard,
  BusinessVirtualCreditCard,
  BusinessVirtualDebitCard,
  Card,
  IndividualDebitCard,
} from "@highbeam/unit-node-sdk";
import chargeCardPhysicalIcon from "assets/charge-card-physical-icon.svg";
import chargeCardVirtualIcon from "assets/charge-card-virtual-icon.svg";
import debitCardPhysicalIcon from "assets/debit-card-physical-icon.svg";
import debitCardVirtualIcon from "assets/debit-card-virtual-icon.svg";
import { useEffect, useState } from "react";
import BusinessMemberRep from "reps/BusinessMemberRep";
import useBusiness from "resources/business/queries/useBusiness";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

export const CARD_EXAMPLES: string[] = [
  "Marketing card",
  "Software card",
  "Vendor card",
  "Meal card",
];

export const useAuthorizedBusinessMembersWithNames = () => {
  const [authorizedBusinessMembersWithNames, setAuthorizedBusinessMembersWithNames] = useState<
    BusinessMemberRep.Complete[]
  >([]);
  const unitApi = useUnitApi();
  const { unitCoCustomerId, ownerUserGuid } = useBusiness();
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  useEffect(() => {
    (async () => {
      const authorizedUsers =
        (await unitApi.customers.get(unitCoCustomerId!)).data.attributes.authorizedUsers ?? [];
      const businessMembers = (await highbeamApi.businessMember.getByBusiness(businessGuid)) ?? [];
      const authorizedBusinessMembersWithNames = businessMembers?.filter(
        (member: BusinessMemberRep.Complete) => {
          const isAuthorizedUser = authorizedUsers?.find(
            (user: AuthorizedUser) =>
              user.email.toLowerCase() === member.emailAddress?.toLowerCase()
          );

          const isOwner = ownerUserGuid === member.userGuid;
          return (isAuthorizedUser || isOwner) && member.firstName && member.lastName;
        }
      );
      setAuthorizedBusinessMembersWithNames(authorizedBusinessMembersWithNames);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    authorizedBusinessMembersWithNames,
  };
};

export const isVirtualDebitCard = (
  card: Card
): card is IndividualDebitCard | BusinessVirtualDebitCard => {
  return card.type === "individualDebitCard" || card.type === "businessVirtualDebitCard";
};

export const isVirtualChargeCard = (card: Card): card is BusinessVirtualCreditCard => {
  return card.type === "businessVirtualCreditCard";
};

export const isPhysicalDebitCard = (
  card: Card
): card is BusinessDebitCard | IndividualDebitCard => {
  return card.type === "businessDebitCard" || card.type === "individualDebitCard";
};

export const isPhysicalCreditCard = (card: Card): card is BusinessCreditCard => {
  return card.type === "businessCreditCard";
};

export const getCardIcon = (card: Card) => {
  if (isVirtualDebitCard(card)) return debitCardVirtualIcon;
  if (isVirtualChargeCard(card)) return chargeCardVirtualIcon;
  if (isPhysicalDebitCard(card)) return debitCardPhysicalIcon;
  if (isPhysicalCreditCard(card)) return chargeCardPhysicalIcon;

  return chargeCardVirtualIcon;
};

export const getCardLabel = (card: Card) => {
  if (isVirtualDebitCard(card)) return "Virtual debit card";
  if (isVirtualChargeCard(card)) return "Virtual Highbeam Card";
  if (isPhysicalDebitCard(card)) return "Debit card";
  if (isPhysicalCreditCard(card)) return "Highbeam Card";

  return "Card";
};

export const getCardMiniLabel = (card: Card) => {
  if (isVirtualDebitCard(card)) return "Virtual";
  if (isVirtualChargeCard(card)) return "Virtual";
  if (isPhysicalDebitCard(card)) return "Physical";
  if (isPhysicalCreditCard(card)) return "Physical";

  return "Card";
};
