import Text from "ui/typography/Text";

import styles from "./TransactionsV2.module.scss";

type Props = {
  title: string;
};

const Title: React.FC<Props> = ({ title }) => (
  <Text size={18} weight="bold" className={styles.title}>
    {title}
  </Text>
);

export default Title;
