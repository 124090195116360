import { CalendarX } from "@phosphor-icons/react";
import { FC } from "react";
import UserReferralRep from "reps/ReferralLinkRep";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./ExpiredReferralHeader.module.scss";

type Props = {
  referral: UserReferralRep;
};

const ExpiredReferralHeader: FC<Props> = ({ referral }) => {
  return (
    <div className={styles.text}>
      <div className={styles.headerSectionExpiredReferral}>
        <div className={styles.iconAvatar}>
          <CalendarX size={24} color={colors.purple[500]} />
        </div>
        <Text size={20} weight="bold" className={styles.primary}>
          {referral.senderName ? (
            `${referral.senderName}'s invitation has expired`
          ) : (
            <>Invitation has expired</>
          )}
        </Text>
        <Text size={14} className={styles.secondary}>
          This invitation has expired, but you can still sign up for Highbeam.
        </Text>
      </div>
    </div>
  );
};

export default ExpiredReferralHeader;
