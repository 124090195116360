import { useQuery } from "@tanstack/react-query";
import DashboardPage from "components/layouts/DashboardPage";
import { useEffect } from "react";
import usePlaidDepositoryAccountsQueryOptions from "resources/plaid-connections/queries/usePlaidDepositoryAccountsQueryOptions";
import { notify } from "ui/feedback/Toast";

import ExternalAccountsTable from "../../components/ExternalAccountsTable";

const notifyConnectedAccountsError = () => {
  notify(
    "error",
    "Something went wrong while loading your connected accounts. We are looking into it."
  );
};

const ExternalAccountsSection = () => {
  const { data: plaidBankAccounts, error: plaidBankAccountsError } = useQuery(
    usePlaidDepositoryAccountsQueryOptions()
  );

  // Show an error toast if one of the queries fails.
  const hasNotifiedError = plaidBankAccountsError;
  useEffect(() => {
    if (hasNotifiedError) {
      notifyConnectedAccountsError();
    }
  }, [hasNotifiedError]);

  if (!plaidBankAccounts || plaidBankAccounts.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>
          Connected bank accounts
        </DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <ExternalAccountsTable accounts={plaidBankAccounts} />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default ExternalAccountsSection;
