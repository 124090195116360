import { FC, ReactNode, createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";

import useReviewLineOfferAgreementForm from "../forms/useReviewLineOfferAgreementForm";

type Step = {
  pathname: string;
  title: string;
};

type ReviewLineOfferContextState = {
  agreementForm: ReturnType<typeof useReviewLineOfferAgreementForm>;
  agreementDownloaded: boolean;
  setAgreementDownloaded: (val: boolean) => void;
  currentStepIndex: number;
  nextPathname: string | null;
  prevPathname: string | null;
  steps: Step[];
};

const ReviewLineOfferContext = createContext<ReviewLineOfferContextState>(
  {} as ReviewLineOfferContextState
);

type Props = {
  children: ReactNode;
};

const ReviewLineOfferProvider: FC<Props> = ({ children }) => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const agreementForm = useReviewLineOfferAgreementForm();

  const { pathname } = useLocation();
  const [agreementDownloaded, setAgreementDownloaded] = useState(false);

  const steps = [
    {
      pathname: `/capital/${capitalAccountSummary.guid}/review-line-offer/how-it-works`,
      title: "How it works",
    },
    {
      pathname: `/capital/${capitalAccountSummary.guid}/review-line-offer/auto-pay`,
      title: "Auto-pay",
    },
    {
      pathname: `/capital/${capitalAccountSummary.guid}/review-line-offer/agreement`,
      title: "Agreement",
    },
    {
      pathname: `/capital/${capitalAccountSummary.guid}/review-line-offer/review`,
      title: "Review",
    },
  ] satisfies Step[];

  const currentStepIndex = steps.findIndex((step) => step.pathname === pathname);

  const nextPathname =
    currentStepIndex >= 0 && currentStepIndex < steps.length - 1
      ? steps[currentStepIndex + 1].pathname
      : null;

  const prevPathname =
    currentStepIndex > 0 && currentStepIndex < steps.length
      ? steps[currentStepIndex - 1].pathname
      : null;

  return (
    <ReviewLineOfferContext.Provider
      value={{
        agreementForm,
        agreementDownloaded,
        setAgreementDownloaded,
        currentStepIndex,
        nextPathname,
        prevPathname,
        steps,
      }}
    >
      {children}
    </ReviewLineOfferContext.Provider>
  );
};

export default ReviewLineOfferProvider;

export const useReviewLineOfferContext = () => {
  return useContext(ReviewLineOfferContext);
};

export const useReviewLineOfferAgreementFormContext = () => {
  return useReviewLineOfferContext().agreementForm;
};
