import { Coins } from "@phosphor-icons/react";
import DashboardPage from "components/layouts/DashboardPage";

const CapitalHeaderTitle = () => {
  return (
    <>
      <DashboardPage.Header.IconTile icon={<Coins />} />
      <DashboardPage.Header.Title>Capital</DashboardPage.Header.Title>
    </>
  );
};

export default CapitalHeaderTitle;
