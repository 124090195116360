import Tabs, { Tab } from "ui/navigation/Tabs";

import {
  CardsOverviewTabOption,
  useActiveTabSearchParam,
} from "../hooks/useCardsOverviewSearchParams";

import styles from "./CardTab.module.scss";

const tabs: Tab<CardsOverviewTabOption>[] = [
  { id: "All", label: "All" },
  { id: "Highbeam", label: "Highbeam Cards" },
  { id: "Debit", label: "Debit cards" },
];

const CardTabs = () => {
  const [activeTab, setActiveTab] = useActiveTabSearchParam();
  return (
    <Tabs
      className={styles.tabs}
      variant="rounded"
      tabs={tabs}
      noBorder
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};

export default CardTabs;
