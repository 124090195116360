import { captureException } from "@sentry/react";
import errorPageIcon from "assets/error-page-icon.svg";
import classNames from "classnames";
import { useDebugSettings } from "providers/DebugSettingsProvider";
import React, { useEffect } from "react";
import Text from "ui/typography/Text";

import styles from "./ErrorText.module.scss";

type Props = {
  error: Error;
  className?: string;
};

const ErrorText: React.FC<Props> = ({ error, className }) => {
  const { showDebugMessages } = useDebugSettings();
  const errorStack = showDebugMessages ? error?.stack : undefined;

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <>
      <div className={classNames(styles.container, className)}>
        <img alt="" className={styles.icon} src={errorPageIcon} />
        <Text size={14}>
          We are sorry, there is a temporary issue with this view. Please come back in a few
          minutes, or reach out to support.
        </Text>
      </div>
      {errorStack && <code className={styles.errorStack}>{errorStack}</code>}
    </>
  );
};

export default ErrorText;
