import { FC } from "react";
import Flexpane from "ui/overlay/Flexpane";

import CheckDepositFlexpaneAmountSection from "./components/CheckDepositFlexpaneAmountSection";
import CheckDepositFlexpaneContactSection from "./components/CheckDepositFlexpaneContactSection";
import CheckDepositFlexpaneToFromSection from "./components/CheckDepositFlexpaneToFromSection";
import CheckDepositFlexpaneProvider from "./context/CheckDepositFlexpaneProvider";

type Props = {
  checkDepositId?: string;
  onClose: () => void;
};

const CheckDepositFlexpane: FC<Props> = ({ checkDepositId, onClose }) => {
  return (
    <Flexpane isOpen={Boolean(checkDepositId)} onClose={onClose}>
      {checkDepositId && (
        <CheckDepositFlexpaneProvider checkDepositId={checkDepositId} onClose={onClose}>
          <Flexpane.Header onClose={onClose}>Payment info</Flexpane.Header>
          <CheckDepositFlexpaneToFromSection />
          <CheckDepositFlexpaneAmountSection />
          <CheckDepositFlexpaneContactSection />
        </CheckDepositFlexpaneProvider>
      )}
    </Flexpane>
  );
};

export default CheckDepositFlexpane;
