import classNames from "classnames";
import { ComponentProps, FC, ReactElement, cloneElement } from "react";
import colors from "styles/colors";
import InfoIcon from "ui/icons/InfoIcon";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./Helper.module.scss";

// NB(alex): This is subject to change. For now, we wrap `InfoIcon` but this may deviate.
export const HelperIcon = (props: ComponentProps<typeof InfoIcon>) => {
  return <InfoIcon {...props} />;
};

type HelperTextSize = 12 | 14;

const getDefaultIconSize = (size: HelperTextSize) => {
  switch (size) {
    case 12:
      return 16;
    case 14:
      return 20;
  }
};

type Props = TextProps & {
  icon?: ReactElement<{ size: number }>;
  size?: HelperTextSize;
};

const Helper: FC<Props> = ({ children, className, size = 14, icon, ...textProps }) => {
  return (
    <div className={classNames(className, styles.container)}>
      {icon && cloneElement(icon, { size: icon.props.size ?? getDefaultIconSize(size) })}

      <Text size={size} color={colors.grey[600]} className={classNames(styles.text)} {...textProps}>
        {children}
      </Text>
    </div>
  );
};

export default Object.assign(Helper, {
  Icon: HelperIcon,
});
