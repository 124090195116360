import { FC, ReactNode } from "react";
import Box, { BoxElementProps } from "ui/layout/Box";

import styles from "./DetailsSidebarBody.module.scss";

const DetailsSidebarBodyHeader = (props: BoxElementProps) => {
  return <Box baseClassName={styles.header} {...props} />;
};

const DetailsSidebarBodyMain = (props: BoxElementProps) => {
  return <Box baseClassName={styles.main} {...props} />;
};

const DetailsSidebarBodySection = (props: BoxElementProps) => {
  return <Box baseClassName={styles.section} {...props} />;
};

const DetailsSidebarBodyFooter = (props: BoxElementProps) => {
  return <Box as="footer" baseClassName={styles.footer} {...props} />;
};

type DetailsSidebarBodyProps = Omit<BoxElementProps, "children"> & {
  header?: ReactNode;
  main?: ReactNode;
  footer?: ReactNode;
};

const DetailsSidebarBody: FC<DetailsSidebarBodyProps> = ({ header, main, footer, ...boxProps }) => {
  return (
    <Box baseClassName={styles.container} {...boxProps}>
      <div className={styles.body}>
        {header}
        {main}
      </div>
      {footer}
    </Box>
  );
};

export default Object.assign(DetailsSidebarBody, {
  Header: DetailsSidebarBodyHeader,
  Main: DetailsSidebarBodyMain,
  Section: DetailsSidebarBodySection,
  Footer: DetailsSidebarBodyFooter,
});
