import { Coins } from "@phosphor-icons/react";
import highYieldIcon from "assets/high-yield-icon.svg";
import highbeamIcon from "assets/highbeam-logo-without-label.svg";
import { CSSProperties, FC } from "react";
import colors from "styles/colors";

// NB(alex): wip! would like to account all platforms eventually
export type PlatformName = "highbeam" | "highbeam-high-yield" | "highbeam-capital";

export type BaseIconProps = {
  className?: string;
  size?: number;
  style?: CSSProperties;
};

type IconImgProps = BaseIconProps & {
  src: string;
  alt: string;
};

const IconImg: FC<IconImgProps> = ({ src, alt, className, size, style }) => {
  return <img src={src} alt={alt} className={className} width={size} height={size} style={style} />;
};

type Props = BaseIconProps & {
  platformName: PlatformName;
};

const PlatformIcon: FC<Props> = ({ platformName, ...baseIconProps }) => {
  switch (platformName) {
    case "highbeam":
      return <IconImg src={highbeamIcon} alt="Highbeam" {...baseIconProps} />;
    case "highbeam-high-yield":
      return <IconImg src={highYieldIcon} alt="High yield" {...baseIconProps} />;
    case "highbeam-capital":
      return <Coins color={colors.purple[500]} alt="Capital" {...baseIconProps} />;
  }
};

PlatformIcon.defaultProps = {
  size: 18,
};

export default PlatformIcon;
