import questionFlowerImg from "assets/question-flower.svg";
import { useChatWidget } from "components/ChatWidget";
import colors from "styles/colors";
import AccentCard from "ui/data-display/AccentCard";
import Section from "ui/data-display/Section";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./TransactionInfoFlexpaneDisputeSection.module.scss";

const TransactionInfoFlexpaneDisputeSection = () => {
  const chat = useChatWidget();

  return (
    <Section>
      <AccentCard className={styles.container}>
        <div>
          <img src={questionFlowerImg} alt="?" className={styles.questionFlower} />
        </div>
        <div className={styles.text}>
          <Text size={14} weight="bold" color={colors.grey[900]}>
            Don’t recognize this transaction?
          </Text>
          <Text size={14} color={colors.grey[600]}>
            If you don’t recognize this transaction or believe it is fraudulent, report it to us and
            we’ll be able to lodge a dispute for you.
          </Text>
        </div>
        <div>
          <Button variant="tertiary" onClick={() => chat.show()}>
            Contact support
          </Button>
        </div>
      </AccentCard>
    </Section>
  );
};

export default TransactionInfoFlexpaneDisputeSection;
