import { useSuspenseQuery } from "@tanstack/react-query";
import { Dayjs } from "dayjs";
import CardBudgetManager from "pages/cards/CardDetailsPage/CardDetailsInfo/CardBudgetManager";
import { CARD_EXAMPLES } from "pages/cards/CardsPage/utils";
import DebitCardRep from "reps/DebitCardRep";
import useUnitCoCustomerQueryOptions from "resources/unit-co-customer/queries/useUnitCoCustomerQueryOptions";
import Pill from "ui/data-display/Pill";
import PillList from "ui/data-display/PillList";
import DateInputFieldset from "ui/inputs/DateInputFieldset";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import Helper from "ui/inputs/Helper";
import TextInput from "ui/inputs/TextInput";
import ModalV2 from "ui/overlay/ModalV2";
import Text from "ui/typography/Text";
import { isDebitCard } from "utils/cards";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import stringToReactKey from "utils/react-helpers/stringToReactKey";

import { BudgetType, ValidationErrors } from "../utils";

import styles from "./CreateCard.module.scss";

type Props = {
  cardType: DebitCardRep.CardType;
  cardName: string;
  setCardName: (cardName: string) => void;
  selectedAccount: Option | null;
  setSelectedAccount: (selectedAccount: Option | null) => void;
  selectedUser: Option | null;
  setSelectedUser: (selectedUser: Option | null) => void;
  selectedUserDateOfBirth: Dayjs | null;
  setSelectedUserDateOfBirth: (selectedUserDateOfBirth: Dayjs | null) => void;
  selectedUserHasDateOfBirth: boolean;
  accountOptions: Option[];
  userOptions: Option[];
  dailyLimit: number | null;
  setDailyLimit: (limit: number | null) => void;
  monthlyLimit: number | null;
  budgetType: BudgetType;
  setBudgetType: (budgetType: BudgetType) => void;
  updateBudgetLimit: (val: string) => void;
  setMonthlyLimit: (limit: number | null) => void;
  isLoading: boolean;
  validationErrors: ValidationErrors;
  updateValidationErrors: () => void;
};

const CreateCard: React.FC<Props> = ({
  cardType,
  cardName,
  setCardName,
  selectedAccount,
  setSelectedAccount,
  selectedUser,
  setSelectedUser,
  selectedUserDateOfBirth,
  setSelectedUserDateOfBirth,
  selectedUserHasDateOfBirth,
  accountOptions,
  userOptions,
  dailyLimit,
  setDailyLimit,
  budgetType,
  setBudgetType,
  updateBudgetLimit,
  monthlyLimit,
  setMonthlyLimit,
  isLoading,
  validationErrors,
  updateValidationErrors,
}) => {
  const chargeCardMultipleCapitalAccountsCardCreationModalEnabled = useFeatureFlag(
    "CHARGE_CARD_MULTIPLE_CAPITAL_ACCOUNTS_CARD_CREATION_MODAL"
  );
  const onlyAccountSelected = selectedAccount !== null && accountOptions.length === 1;
  const onlyUserSelected = selectedUser !== null && userOptions.length === 1;
  const { data: unitCoCustomer } = useSuspenseQuery(useUnitCoCustomerQueryOptions());

  const isBusinessCustomer = unitCoCustomer?.type === "businessCustomer";

  return (
    <>
      {isBusinessCustomer && (
        <ModalV2.Content.Body.Section>
          <ModalV2.Content.Body.Section.Header>
            <ModalV2.Content.Body.Section.Header.Heading>
              Cardholder
            </ModalV2.Content.Body.Section.Header.Heading>
          </ModalV2.Content.Body.Section.Header>

          <div className={styles.inputGroupContainer}>
            <div>
              <Dropdown
                id="card-holder-name"
                onChange={(user) => {
                  setSelectedUser(user);
                  updateValidationErrors();
                }}
                value={selectedUser}
                options={userOptions}
                disabled={isLoading || onlyUserSelected}
                isLoading={isLoading}
                hideCursor
              />
            </div>

            {!chargeCardMultipleCapitalAccountsCardCreationModalEnabled && (
              <div>
                <Text size={14} weight="medium" className={styles.inputGroupLabel}>
                  Date of birth
                </Text>

                <DateInputFieldset
                  value={selectedUserDateOfBirth}
                  onChange={(date) => {
                    setSelectedUserDateOfBirth(date);
                    updateValidationErrors();
                  }}
                  disabled={selectedUserHasDateOfBirth}
                />
              </div>
            )}
          </div>
        </ModalV2.Content.Body.Section>
      )}
      <ModalV2.Content.Body.Section>
        <ModalV2.Content.Body.Section.Header>
          <ModalV2.Content.Body.Section.Header.Heading>
            Card details
          </ModalV2.Content.Body.Section.Header.Heading>
        </ModalV2.Content.Body.Section.Header>

        <div className={styles.inputGroupContainer}>
          <div>
            <TextInput
              label={cardName ? "Card name" : undefined}
              value={cardName}
              onChange={(name) => setCardName(name)}
              hasError={Boolean(validationErrors["cardName"])}
              errorMessage={validationErrors["cardName"]}
              placeholder="Give this card a name"
            />

            {cardType === "VIRTUAL" && (
              <Helper>
                <Text size={14} weight="medium" align="center">
                  Suggestions
                </Text>
                <ul className={styles.suggestions}>
                  <PillList>
                    {CARD_EXAMPLES.map((name) => (
                      <Pill
                        key={stringToReactKey(name)}
                        color="grey-light"
                        onClick={() => setCardName(name)}
                      >
                        {name}
                      </Pill>
                    ))}
                  </PillList>
                </ul>
              </Helper>
            )}
          </div>

          {isDebitCard(cardType) && (
            <>
              <div>
                <Dropdown
                  id="associated-account"
                  onChange={(account) => {
                    setSelectedAccount(account);
                    updateValidationErrors();
                  }}
                  value={selectedAccount}
                  label="Associated account"
                  options={accountOptions}
                  disabled={isLoading || onlyAccountSelected}
                  hasError={!isLoading && Boolean(validationErrors.account)}
                  errorMessage={validationErrors.account}
                  isLoading={isLoading}
                />
                <Helper>The funds will be debited from the associated account.</Helper>
              </div>
            </>
          )}
          <CardBudgetManager
            dailyLimit={dailyLimit}
            setDailyLimit={setDailyLimit}
            dailyPurchases={null}
            monthlyLimit={monthlyLimit}
            setMonthlyLimit={setMonthlyLimit}
            monthlyPurchases={null}
            budgetType={budgetType}
            setBudgetType={setBudgetType}
            updateBudgetLimit={updateBudgetLimit}
            isLoading={isLoading}
            newLimitExceedsPurchases={false}
            setNewLimitExceedsPurchases={() => {}}
          />
        </div>
      </ModalV2.Content.Body.Section>
    </>
  );
};

export default CreateCard;
