import MultiStep from "components/layouts/MultiStep";
import PaymentSummary from "components/PaymentSummary";
import { FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import HighbeamAccountIcon from "resources/bank-accounts/components/HighbeamAccountIcon";
import useOpenBankAccount from "resources/bank-accounts/queries/useOpenBankAccount";
import CapitalAccountIcon from "resources/capital-accounts/components/CapitalAccountIcon";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import useCreateDrawdownApprovalRequestMutation from "resources/line-of-credit/mutations/useCreateDrawdownApprovalRequestMutation";
import useCreateDrawdownMutation from "resources/line-of-credit/mutations/useCreateDrawdownMutation";
import BankAccountV2 from "ui/data-display/BankAccountV2";
import InfoBox from "ui/data-display/InfoBox";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import { notify } from "ui/feedback/Toast";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import {
  useDrawdownAmountFormContext,
  useDrawdownContext,
  useDrawdownInvoiceFormContext,
} from "../context/DrawdownProvider";

import styles from "./DrawdownConfirmationView.module.scss";

const DRAW_DOWN_CONFIRMATION_FORM_ID = "draw-down-confirmation-form";

const DrawdownConfirmationView = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const { getValues: getAmountFormValues } = useDrawdownAmountFormContext();
  const { getValues: getInvoiceFormValues, reset: resetInvoiceForm } =
    useDrawdownInvoiceFormContext();
  const { prevPathname } = useDrawdownContext();
  const amount = getAmountFormValues("amount");
  const drawdownFromCapitalAccount = getAmountFormValues("drawdownFromCapitalAccount");
  const drawdownToBankAccountOption = getAmountFormValues("drawdownToBankAccountOption");
  const reasonForDrawdown = getInvoiceFormValues("reasonForDrawdown");
  const idempotencyKey = getInvoiceFormValues("idempotencyKey");
  const invoice = getInvoiceFormValues("invoice");
  const navigate = useNavigate();
  const availableAfterDrawdown = capitalAccountSummary.available - amount;
  const currentAmountOwed = 0 - capitalAccountSummary.runningBalance;
  const amountOwedAfterDrawdown = currentAmountOwed + amount;

  const isDrawdownApprovalRequired = capitalAccountSummary.controls.drawdownRequiresApproval;
  const isDrawdownApprovalFeatureFlagEnabled = useFeatureFlag("DRAW_DOWN_WITH_APPROVAL_REQUIRED");

  const { mutateAsync: createDrawdown, isPending: isCreateDrawdownPending } =
    useCreateDrawdownMutation();
  // When a drawdown approval request is successful, we need to reset the idempotency key by calling resetInvoiceForm(). Context: https://highbeamco.slack.com/archives/C02M3GGQPHC/p1690914198052609?thread_ts=1690912209.739389&cid=C02M3GGQPHC
  const {
    mutateAsync: createDrawdownApprovalRequest,
    isPending: isDrawdownApprovalRequestPending,
  } = useCreateDrawdownApprovalRequestMutation();

  const drawdownToBankAccount = useOpenBankAccount(drawdownToBankAccountOption.guid, {
    required: true,
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isDrawdownApprovalFeatureFlagEnabled && isDrawdownApprovalRequired) {
      if (!invoice) {
        notify("error", "There was an error attaching your invoice. Please try again!");
        return;
      }
      await createDrawdownApprovalRequest({
        amount,
        bankAccountGuid: drawdownToBankAccountOption.guid,
        note: reasonForDrawdown,
        idempotencyKey: idempotencyKey,
        file: invoice,
      });
      resetInvoiceForm();
    } else {
      await createDrawdown({
        lineOfCreditGuid: capitalAccountSummary.guid,
        amountInCents: amount,
        transferTo: drawdownToBankAccountOption,
      });
    }
    navigate(`/capital/${capitalAccountSummary.guid}`);
  };

  return (
    <MultiStep.Form id={DRAW_DOWN_CONFIRMATION_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Confirm details</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <PaymentSummary>
          <PaymentSummary.Header>
            <PaymentSummary.Header.Title>Drawdown amount</PaymentSummary.Header.Title>
            <PaymentSummary.Header.Amount cents={amount} />
          </PaymentSummary.Header>

          <PaymentSummary.SectionDivider />

          <PaymentSummary.Section>
            <PaymentSummary.Section.SummaryItem
              label="From"
              value={
                <div className={styles.drawdownFromSection}>
                  <CapitalAccountIcon
                    capitalAccountType={drawdownFromCapitalAccount.type}
                    size={20}
                  />
                  <Text size={14} weight="medium">
                    {drawdownFromCapitalAccount.name}
                  </Text>
                </div>
              }
            />

            <PaymentSummary.Section.SummaryItem
              label="To"
              value={
                <BankAccountV2
                  bankIcon={<HighbeamAccountIcon highbeamTypeName="DepositAccount" />}
                  accountName={drawdownToBankAccount.name}
                  accountNumber={drawdownToBankAccount.accountNumber}
                />
              }
            />
          </PaymentSummary.Section>

          <PaymentSummary.SectionDivider />

          <PaymentSummary.Section>
            <PaymentSummary.Section.SummaryItem
              label="Available after drawdown"
              value={<MoneyAmount size={14} cents={availableAfterDrawdown} weight="medium" />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Outstanding (current)"
              value={<MoneyAmount size={14} cents={currentAmountOwed} weight="medium" />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Outstanding after drawdown"
              value={<MoneyAmount size={14} cents={amountOwedAfterDrawdown} weight="medium" />}
            />
          </PaymentSummary.Section>
        </PaymentSummary>
      </MultiStep.Section>

      {isDrawdownApprovalFeatureFlagEnabled && isDrawdownApprovalRequired && (
        <MultiStep.Section>
          <InfoBox
            variant="info"
            iconSize={24}
            text={
              <>
                Please allow for up to <strong>3 business days</strong> for this drawdown to be
                reviewed.
              </>
            }
          />
        </MultiStep.Section>
      )}

      <MultiStep.Controls>
        <Link to={prevPathname || `/capital/${capitalAccountSummary.guid}`}>
          <MultiStep.Controls.BackButton />
        </Link>

        <MultiStep.Controls.NextButton
          form={DRAW_DOWN_CONFIRMATION_FORM_ID}
          autoFocus
          isLoading={isCreateDrawdownPending || isDrawdownApprovalRequestPending}
        >
          {isDrawdownApprovalFeatureFlagEnabled && isDrawdownApprovalRequired
            ? "Submit for approval"
            : "Confirm"}
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default DrawdownConfirmationView;
