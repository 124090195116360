import { useIsSuperusering } from "state/auth/isSuperusering";

import useBusinessMember from "./useBusinessMember";

const useBusinessMemberOnboarded = () => {
  const businessMember = useBusinessMember();
  const isSuperusering = useIsSuperusering();

  return businessMember?.isOnboarded || isSuperusering;
};

export default useBusinessMemberOnboarded;
