import classNames from "classnames";
import { FC, ReactNode } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";

import useModalContext, { ModalContextValue } from "../context/useModalContext";

import styles from "./ModalFooter.module.scss";

export const ModalFooterSubmitButton: FC<ButtonProps> = ({ children, ...buttonProps }) => {
  const { formId } = useModalContext();

  return (
    <Button type="submit" variant="primary" form={formId} {...buttonProps}>
      {children}
    </Button>
  );
};

export const ModalFooterCloseButton: FC<ButtonProps> = ({ children = "Close", ...buttonProps }) => {
  const { onClose } = useModalContext();

  return (
    <Button type="button" variant="default" onClick={onClose} {...buttonProps}>
      {children}
    </Button>
  );
};

type ModalFooterProps = {
  children: ReactNode | ((renderProps: ModalContextValue) => ReactNode);
  className?: string;
};

const ModalFooter: FC<ModalFooterProps> = ({ children, className }) => {
  const modalContext = useModalContext();

  return (
    <div className={classNames(styles.footer, className)}>
      {typeof children === "function" ? children(modalContext) : children}
    </div>
  );
};

export default Object.assign(ModalFooter, {
  SubmitButton: ModalFooterSubmitButton,
  CloseButton: ModalFooterCloseButton,
});
