import { useSuspenseQuery } from "@tanstack/react-query";

import usePlaidLinkTokenQueryOptions, {
  PlaidLinkTokenParams,
} from "./usePlaidLinkTokenQueryOptions";

const usePlaidLinkToken = (params: PlaidLinkTokenParams) => {
  const { data: plaidLinkToken } = useSuspenseQuery(usePlaidLinkTokenQueryOptions(params));

  return plaidLinkToken;
};

export default usePlaidLinkToken;
