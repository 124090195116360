import Tabs, { Tab } from "ui/navigation/Tabs";

import styles from "./TransactionsV2.module.scss";

type Props<TFilterTab extends string = string> = {
  tabs: readonly Tab<TFilterTab>[];
  activeTab: string;
  setActiveTab: (tabId: TFilterTab) => void;
};
const FilterTabs = <TFilterTab extends string = string>({
  tabs,
  activeTab,
  setActiveTab,
}: Props<TFilterTab>) => (
  <Tabs
    className={styles.tabs}
    variant="rounded"
    tabs={tabs}
    noBorder
    activeTab={activeTab}
    setActiveTab={setActiveTab}
  />
);

export default FilterTabs;
